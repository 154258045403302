import { RUN_STATUS } from '@bugbug/core/constants/status';
import { ClockIcon } from '@bugbug/core/theme/icons';
import dayjs from 'dayjs';
import { last } from 'ramda';
import React, { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectGroupStepsList } from '~/modules/test/test.selectors';
import { selectStepRuns, selectSingleTestRun } from '~/modules/testRun/testRun.selectors';
import { getFormattedDuration, parseTimeFromDuration } from '~/utils/dates';

import { Container, Label, MetaValue, StepStatus, Status, Loader } from './GroupMetaData.styled';

export const GroupMetaData = memo(({ group, compact, runResultEnabled }) => {
  const { t } = useTranslation();
  const testRun = useSelector(selectSingleTestRun);
  const testStepRuns = useSelector(selectStepRuns);
  const stepsList = useSelector(selectGroupStepsList(group.id, runResultEnabled));

  const stepRuns = useMemo(
    () => group.steps.map((stepId) => testStepRuns[stepId]).filter(Boolean),
    [group.steps, testStepRuns],
  );

  const stepNumberTotal = group.steps.length;
  const stepExecutedTotal = stepRuns.length;
  const lastStepRun = last(stepRuns);
  const currentStepIndex = Math.max(0, stepRuns.length - 1);
  const currentStep = stepsList[currentStepIndex];

  const isGroupRunning =
    testRun.status === RUN_STATUS.RUNNING &&
    stepRuns.length &&
    stepRuns.length !== group.steps.length;

  const groupRun = useMemo(
    () => (isGroupRunning ? { status: RUN_STATUS.RUNNING } : lastStepRun || {}),
    [isGroupRunning, lastStepRun],
  );

  const totalDuration = useMemo(() => {
    if (!stepRuns.length) {
      return getFormattedDuration();
    }

    const newDuration = stepRuns.reduce((time, { rawDuration }) => {
      const { hours, minutes, seconds, milliSeconds } = parseTimeFromDuration(rawDuration);
      const newTime = time
        .add(hours, 'h')
        .add(minutes, 'm')
        .add(seconds, 's')
        .add(milliSeconds.substring(0, 3), 'ms');

      return newTime;
    }, dayjs().startOf('day'));
    return getFormattedDuration(newDuration.format('HH:mm:ss.SSS'), true);
  }, [stepRuns]);

  const stepsNumberLabel = useMemo(() => {
    if (!groupRun.status || groupRun.status === RUN_STATUS.PASSED) {
      return t('groupMetaData.stepsNumber.common', {
        defaultValue_one: '{{ count }} step',
        defaultValue_other: '{{ count }} steps',
        count: stepNumberTotal,
      });
    }

    return t('groupMetaData.stepsNumber.running', {
      defaultValue_one: 'at {{ executed }} of {{ count }}',
      defaultValue_other: 'at {{ executed }} of {{ count }}',
      count: stepNumberTotal,
      executed: stepExecutedTotal,
    });
  }, [stepNumberTotal, stepExecutedTotal, groupRun, t]);

  return (
    <Container>
      {groupRun.status && (
        <Status visible={!compact}>
          {isGroupRunning && <Loader />}
          <StepStatus
            key={currentStep?.id}
            stepId={currentStep?.id}
            sleep={currentStep?.sleep}
            progressTooltipDisabled={compact}
          />
        </Status>
      )}

      <Label>{stepsNumberLabel}</Label>
      {totalDuration && (
        <MetaValue
          Icon={ClockIcon}
          value={totalDuration}
          tooltip={t('groupMetaData.duration.tooltip', 'Run duration')}
        />
      )}
    </Container>
  );
});

GroupMetaData.displayName = 'GroupMetaData';
