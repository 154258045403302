import Icon from '@bugbug/core/components/Icon';
import RelativeTime from '@bugbug/core/components/RelativeTime';
import { useTimezone } from '@bugbug/core/hooks/useTimezone';
import { isFailedStatus, isRunningStatus } from '@bugbug/core/types/base';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { RunMode, RunStatus } from '@bugbug/core/types/base';
import type { TestRunScreenSize } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';
import DurationTime from '~/components/DurationTime';
import { SCREEN_RESOLUTION_ICON_NAME, SCREEN_RESOLUTION_LABEL } from '~/constants/test';

import * as S from './RunMetadata.styled';

interface RunMetadataProps {
  className?: string;
  id?: number;
  status: RunStatus;
  newStepAdded?: boolean;
  runMode: RunMode;
  created: string;
  started?: Maybe<string>;
  duration?: string;
  ignoredFailed?: boolean;
  warning?: boolean;
  showMilliseconds?: boolean;
  showOnlySeconds?: boolean;
  profileName?: string;
  passedExpected?: number;
  passedCurrent?: number;
  autoRetried?: boolean;
  screenSize?: {
    type: TestRunScreenSize;
    width: number;
    height: number;
  };
  errorCode?: string;
  onErrorClick?: () => void;
  onDetailsClick?: () => void;
}

const RunMetadata = memo<RunMetadataProps>((props) => {
  const {
    className,
    status,
    created,
    started,
    passedExpected,
    passedCurrent,
    onDetailsClick,
    id,
    profileName,
    duration,
    runMode,
    onErrorClick,
    ignoredFailed,
    autoRetried,
    warning,
    showMilliseconds,
    showOnlySeconds,
    screenSize,
    errorCode,
    newStepAdded,
  } = props;
  const { t } = useTranslation();
  const { toUserTZ } = useTimezone();

  const isRunning = status === 'running';

  const renderRunId = () => (
    <S.Tooltip content={t('runMetadata.meta.id.tooltip', 'Run ID number')}>
      <S.MetaValue>
        <Icon name="hash" /> {id}
      </S.MetaValue>
    </S.Tooltip>
  );

  const renderScreenSize = () => {
    const screenSizeType = screenSize?.type ?? 'desktop';
    const iconName = SCREEN_RESOLUTION_ICON_NAME[screenSizeType];
    return (
      <S.Tooltip
        content={t('runMetadata.meta.screenSize.tooltip', 'Screen size: {{width}}x{{height}}', {
          width: screenSize?.width,
          height: screenSize?.height,
        })}
      >
        <S.MetaValue>
          <Icon name={iconName} /> {SCREEN_RESOLUTION_LABEL[screenSizeType]}
        </S.MetaValue>
      </S.Tooltip>
    );
  };

  const renderRunStart = () => (
    <S.MetaValue>
      <Icon name="calendar" />
      <RelativeTime
        date={started || created}
        tooltipTemplate={(startDate: string) =>
          t('runMetadata.meta.startDate.tooltip', 'Started on {{startDate}}', {
            startDate: toUserTZ(startDate).userFriendly,
          })
        }
      />
    </S.MetaValue>
  );

  const renderProfile = () => (
    <S.Tooltip
      content={t('runMetadata.meta.profile.tooltip', 'Run profile: {{ profileName }}', {
        profileName,
      })}
    >
      <S.MetaValue>
        <Icon name="variables" />
        <S.ShortValue>{profileName}</S.ShortValue>
      </S.MetaValue>
    </S.Tooltip>
  );

  const renderResult = () => (
    <S.Tooltip content={t('runMetadata.meta.profile.result', 'Result')}>
      <S.MetaValue>
        <Icon name="suites" />
        <S.ShortValue>
          {passedCurrent}/{passedExpected} passed
        </S.ShortValue>
      </S.MetaValue>
    </S.Tooltip>
  );

  const Duration = useMemo(
    () => (
      <S.Tooltip content={t('runMetadata.meta.duration.tooltip', 'Run duration')}>
        <S.MetaValue>
          <Icon name="clock" />
          <DurationTime
            value={duration}
            running={isRunning}
            showMilliseconds={showMilliseconds}
            showOnlySeconds={showOnlySeconds}
          />
        </S.MetaValue>
      </S.Tooltip>
    ),
    [isRunning, duration, t, showMilliseconds, showOnlySeconds],
  );

  const Environment = useMemo(() => {
    if (!runMode) {
      return null;
    }

    const isLocalRun = runMode && runMode === 'local';
    const EnvironmentIcon = isLocalRun ? <Icon name="computer" /> : <Icon name="cloud" />;
    const tooltip = isLocalRun
      ? t('runMetadata.meta.env.local.tooltip', 'Test has been run locally on your computer')
      : t('runMetadata.meta.env.cloud.tooltip', 'Test has been run in cloud server');
    const label = isLocalRun
      ? t('runMetadata.meta.env.local.label', 'Local')
      : t('runMetadata.meta.env.cloud.label', 'Cloud');

    return (
      <S.Tooltip content={tooltip}>
        <S.MetaValue>
          {EnvironmentIcon}
          {label}
        </S.MetaValue>
      </S.Tooltip>
    );
  }, [runMode, t]);

  if (!status || (!isRunningStatus(status) && newStepAdded)) {
    return (
      <S.Container className={className} data-testid="RunMetadata" aria-label="Run details">
        <S.StatusBadgeFlex status="initialized" newStepAdded={newStepAdded} />
        <S.StatusLabel status={newStepAdded ? 'just-edited' : 'initialized'}>
          {newStepAdded
            ? t('runMetadata.status.justEdited.status', 'Just edited')
            : t('runMetadata.status.neverRunning.status', 'It has not been run')}
        </S.StatusLabel>
        {onDetailsClick && (
          <S.SmallButton onClick={onDetailsClick}>
            {t('runMetadata.more.openSmallButton', 'Details')}
          </S.SmallButton>
        )}
      </S.Container>
    );
  }

  return (
    <S.Container className={className} data-testid="RunMetadata" aria-label="Run details">
      <S.StatusBadgeFlex
        status={status}
        extended
        ignoredFailed={ignoredFailed}
        autoRetried={autoRetried}
        warning={warning}
        errorCode={errorCode}
      />
      {isFailedStatus(status) && onErrorClick && (
        <S.SmallButton onClick={onErrorClick}>
          {t('runMetadata.status.failed.goToError', 'Go to failed step')}
        </S.SmallButton>
      )}
      <S.MetaData>
        {!!id && renderRunId()}
        {!!passedExpected && renderResult()}
        {!!id && renderRunStart()}
        {!!screenSize && renderScreenSize()}
        {!!profileName && renderProfile()}
        {Duration}
        {Environment}
      </S.MetaData>
      {onDetailsClick && (
        <S.SmallButton onClick={onDetailsClick}>
          {t('runMetadata.more.openSmallButton', 'Details')}
        </S.SmallButton>
      )}
    </S.Container>
  );
});

RunMetadata.displayName = 'RunMetadata';

export default RunMetadata;
