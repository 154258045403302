import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgBillingIcon = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 37, height: 37, viewBox: "0 0 37 37", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { x: 0.5, y: 10.7852, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 5.64307, y: 5.64258, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 3.07129, y: 8.21484, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 10.7856, y: 0.5, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 8.21436, y: 3.07227, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 13.3569, y: 0.5, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 15.9287, y: 0.5, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 18.5, y: 0.5, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 21.0713, y: 0.5, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 23.6431, y: 0.5, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 26.2144, y: 3.07227, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 28.7856, y: 5.64258, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 31.3569, y: 8.21484, width: 2.57143, height: 2.57143, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 33.9287, y: 10.7852, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 33.9287, y: 13.3574, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 33.9287, y: 15.9277, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 33.9287, y: 18.5, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 33.9287, y: 21.0723, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 33.9287, y: 23.6426, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 28.7856, y: 28.7852, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 31.3569, y: 26.2148, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 26.2144, y: 31.3574, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 23.6431, y: 33.9277, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 21.0713, y: 33.9277, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 18.5, y: 33.9277, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 15.9287, y: 33.9277, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 13.3569, y: 33.9277, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 10.7856, y: 33.9277, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 5.64307, y: 28.7852, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 8.21436, y: 31.3574, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 14.6431, y: 14.6426, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 14.6431, y: 27.5, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 12.0713, y: 24.9277, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3569, y: 9.5, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.7856, y: 6.92773, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 6.92773, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 4.35742, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 9.5, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 12.0723, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 14.6426, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 19.7852, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 22.3574, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 24.9277, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 30.0723, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 14.6431, y: 6.92773, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 12.0713, y: 9.5, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 12.0713, y: 12.0723, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 17.2148, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.7856, y: 19.7852, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.7856, y: 19.7852, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3569, y: 24.9277, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.7856, y: 27.5, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2144, y: 27.5, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.3569, y: 22.3574, width: 2.57143, height: 2.57143, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.57143, height: 2.57143, transform: "matrix(1 0 0 -1 3.07129 28.7852)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.57143, height: 2.57143, transform: "matrix(1 0 0 -1 0.5 23.6426)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.57143, height: 2.57143, transform: "matrix(1 0 0 -1 0.5 26.2148)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.57143, height: 2.57143, transform: "matrix(1 0 0 -1 0.5 21.0723)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.57143, height: 2.57143, transform: "matrix(1 0 0 -1 0.5 18.5)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.57143, height: 2.57143, transform: "matrix(1 0 0 -1 0.5 15.9277)", fill: "#521EC9" })
] });
const ForwardRef = forwardRef(SvgBillingIcon);
export default ForwardRef;
