import Link from '@bugbug/core/components/Link';
import LoaderComponent from '@bugbug/core/components/Loader';
import SearchInput from '@bugbug/core/components/SearchInput';
import { H1 } from '@bugbug/core/theme/typography';
import * as T from '@bugbug/core/utils/toolbox';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

import type { Sorting } from './ComponentsList.types';
import type { SortByDropdownOrderBy } from '~/components/SortByDropdown/SortByDropdown.types';
import type { RecentSort } from '~/hooks/useRecentSort';

import type { InputChangeHandler } from '@bugbug/core/components/Input/Input.types';
import type { Component } from '@bugbug/core/types/components';
import Badge, { BADGE_VARIANT } from '~/components/Badge';
import InlineTutorialMessage from '~/components/InlineTutorialMessage';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import { SortByDropdown } from '~/components/SortByDropdown/SortByDropdown';
import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import { useRecentSort } from '~/hooks/useRecentSort';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import { useGetComponentsQuery } from '~/modules/components/components.api';
import { COMPONENTS_LIST_SORT_BY_OPTIONS } from '~/views/ComponentsList/components/ComponentsTable/ComponentsTable.constants';
import urls, { reverse } from '~/views/urls';

import ComponentsTable from './components/ComponentsTable';
import SelectedComponentActions from './components/SelectedComponentsActions';
import * as S from './ComponentsList.styled';

const defaultSorting: Sorting<Component> = {
  desc: true,
  sortBy: 'testsCount',
};

const sortData = (data: Component[], sorting: RecentSort) => {
  const sortedData = T.sortBy(sorting.sortBy, data);

  return sorting.desc ? T.reverse(sortedData) : sortedData;
};

const defaultSort = {
  sortBy: 'created',
  desc: true,
};

const ComponentsList = () => {
  const { t } = useTranslation();
  const {
    params: { projectSlug, projectId, organizationId },
  } = useAppRoutes('componentsList');
  const queryString = useQueryString();
  const updateQueryString = useUpdateQueryString();

  const { data: components, isLoading, isError, refetch } = useGetComponentsQuery(projectId);

  const [selectedIds, setSelectedIds] = useState<Array<Component['id']>>([]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { updateRecentSort, ...recentSort } = useRecentSort({
    cacheName: 'componentsList',
    defaultConfig: defaultSort,
    columns: COMPONENTS_LIST_SORT_BY_OPTIONS,
  });

  const filteredData = useMemo(
    () =>
      sortData(
        components?.filter((component) =>
          queryString.query?.length
            ? component.name.toLowerCase().includes(queryString.query)
            : component,
        ) || [],
        recentSort,
      ),
    [components, queryString.query, recentSort],
  );

  const handleChangeSearchInput = useCallback<InputChangeHandler>(
    (event) => {
      updateQueryString({ query: event.target.value.toLowerCase() });
      updateRecentSort({ ...recentSort, query: event.target.value.toLowerCase() });
    },
    [updateQueryString, updateRecentSort, recentSort],
  );

  // TODO
  // After SimpleTable refactoring it's worth using the whole
  // RowSelectionInstance<TData> interface here instead of only one of its functions
  const tableRef = useRef<{ toggleAllRowsSelected: (checked?: boolean) => void }>();

  const handleDeselectAll = useCallback(() => {
    tableRef.current?.toggleAllRowsSelected(false);
  }, []);

  const handleSortChange = useCallback(
    (sortBy: string, orderBy: SortByDropdownOrderBy) => {
      updateRecentSort({ sortBy, desc: orderBy === 'desc', query: queryString.query });
    },
    [updateRecentSort, queryString.query],
  );

  const getExtraRowProps = T.memoize(
    (row) => ({
      to: reverse(urls.component, {
        projectId,
        projectSlug,
        organizationId,
        componentId: row.id,
      }),
    }),
    T.get('id'),
  );

  const Title: JSX.Element = useMemo(() => {
    const selectedComponents = filteredData.filter(({ id }) => selectedIds.includes(id));

    return (
      <S.TitleContainer data-testid="ComponentsList.Header">
        <S.Header>
          <S.HeaderTitle>
            <Badge
              value={components?.length}
              hidden={!components?.length}
              variant={BADGE_VARIANT.COUNTER}
            >
              <H1>{t('componentsList.title', 'Components')}</H1>
            </Badge>
          </S.HeaderTitle>
          <S.SortAndFilters>
            <SortByDropdown
              onChange={handleSortChange}
              configuration={recentSort}
              defaultConfiguration={defaultSort}
              items={COMPONENTS_LIST_SORT_BY_OPTIONS}
            />
          </S.SortAndFilters>
        </S.Header>

        <S.Actions>
          {selectedIds.length === 0 ? (
            <SearchInput onChange={handleChangeSearchInput} />
          ) : (
            <SelectedComponentActions
              components={selectedComponents}
              onDeselect={handleDeselectAll}
            />
          )}
        </S.Actions>
      </S.TitleContainer>
    );
  }, [
    recentSort,
    filteredData,
    components?.length,
    t,
    handleSortChange,
    selectedIds,
    handleChangeSearchInput,
    handleDeselectAll,
  ]);

  return (
    <S.Container>
      <Helmet title={t('componentsList.pageTitle', 'Components')} />
      {isError && <ServerErrorInfo isVisible={isError} onRetry={refetch} />}
      {!isError && isLoading && (
        <S.LoaderContainer>
          <LoaderComponent />
        </S.LoaderContainer>
      )}
      {!isError && !isLoading && (
        <S.ListContainer title={Title} data-testid="ComponentsList" wideContent>
          <InlineTutorialMessage
            title={t('inlineTutorial.title')}
            message={
              <Trans key="components.inlineTutorial.message">
                Components are groups of steps that are shared across multiple tests. This is useful
                if you don't want to repeat the most frequently used steps.{' '}
                <Link to={t('default.docs.components')}>Learn more</Link>
              </Trans>
            }
            storageName="componentsGettingStarted"
          />
          <ComponentsTable
            data={filteredData}
            getExtraRowProps={getExtraRowProps}
            handleSortChange={handleSortChange}
            isSearchEmpty={
              filteredData.length === 0 && !!queryString.query && queryString.query.length
            }
            onSelectionChange={setSelectedIds}
            ref={tableRef}
            sorting={defaultSorting}
          />
        </S.ListContainer>
      )}
    </S.Container>
  );
};

export default ComponentsList;
