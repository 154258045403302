import Icon from '@bugbug/core/components/Icon';
import { useCallback } from 'react';

import * as S from './DragHandler.styled';

interface DragHandlerProps {
  className?: string;
  dragHandleProps?: {
    onMouseDown: (event: React.MouseEvent) => void;
  };
}

export const DragHandler = ({ dragHandleProps, className }: DragHandlerProps) => {
  const handleClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <S.Handler
      className={className}
      {...dragHandleProps}
      onClick={handleClick}
      data-testid="DragHandler"
    >
      <Icon name="drag" />
    </S.Handler>
  );
};
