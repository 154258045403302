import Button, { ActionButton } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import Input from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import Loader, { LoaderFlexContainer } from '@bugbug/core/components/Loader';
import { Warning } from '@bugbug/core/theme/typography';
import { renderWhenTrueOtherwise } from '@bugbug/core/utils/rendering';
import { useFormik, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import { prop, omit, complement } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import FormField from '~/components/FormField';
import { Header, Footer, Content, ErrorInfo } from '~/components/modals/Modal';
import MultiValueFormTable from '~/components/MultiValueFormTable';
import { PaidFeature } from '~/components/PaidFeatureGuard';
import { VARIABLE_TYPE } from '~/constants/variables';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import { ProfileActions } from '~/modules/profile/profile.redux';
import { selectProjectSlug, selectSingleProjectId } from '~/modules/project/project.selectors';
import { VariableActions } from '~/modules/variable/variable.redux';
import { selectVariablesList } from '~/modules/variable/variable.selectors';
import urls, { reverse } from '~/views/urls';

import {
  FORM_FIELD,
  MULTI_VALUE_FORM_COLUMNS,
  MULTI_VALUE_FORM_COLUMNS_FOR_DEFAULT,
} from './EditProfileModal.constants';
import { ProfileEditSchema, ProfileCreateSchema } from './EditProfileModal.schema';
import * as S from './EditProfileModal.styled';

const EditProfileModal = ({ className, profile }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isEdit = !!profile?.id;
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(true);
  const defaultVariables = useSelector(selectVariablesList);
  const projectId = useSelector(selectSingleProjectId);
  const projectSlug = useSelector(selectProjectSlug);
  const organizationId = useSelector(selectCurrentOrganizationId);

  const initialVariables = useMemo(
    () => defaultVariables.filter(complement(prop('isSystem'))).map(omit(['value'])),
    [defaultVariables],
  );
  const { isDefault = false } = profile || {};

  const editLabels = {
    title: t('editProfileModal.edit.title.edit', 'Profile settings'),
    submitButton: t('editProfileModal.edit.submitButton', 'Save profile'),
  };
  const createLabels = {
    title: t('editProfileModal.new.title', 'New profile'),
    submitButton: t('editProfileModal.new.submitButton', 'Create profile'),
  };
  const labels = isEdit ? editLabels : createLabels;

  const initialValues = useMemo(() => {
    const currentVariables = profile?.variables ?? [];
    const variables = defaultVariables
      .filter(complement(prop('isSystem')))
      .map((variable, index) => ({
        ...variable,
        defaultValue: variable.value,
        value: currentVariables[index]?.value ?? null,
      }));
    return {
      ...(profile || {}),
      variables,
    };
  }, [profile, defaultVariables]);

  const handleSubmit = useCallback(
    (values, formik) => {
      if (isEdit) {
        dispatch(
          ProfileActions.updateRequest(
            values.id,
            ProfileEditSchema.cast(values, { stripUnknown: true }),
          ),
        );
      } else {
        dispatch(
          ProfileActions.createRequest(ProfileCreateSchema.cast(values, { stripUnknown: true })),
        );
        history.push(reverse(urls.profiles, { organizationId, projectId, projectSlug }));
      }
      formik.setSubmitting(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isEdit, organizationId, projectId, projectSlug],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: isEdit ? ProfileEditSchema : ProfileCreateSchema,
    onSubmit: handleSubmit,
  });

  const handleManageVariables = useCallback(
    () => {
      history.push(reverse(urls.variables, { organizationId, projectId, projectSlug }));
      modal.hide();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal.hide],
  );

  const handleSuccess = useCallback(() => {
    formik.setSubmitting(false);
    modal.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setSubmitting, modal.hide]);

  const handleFailure = useCallback((stateErrors) => {
    if (stateErrors) {
      formik.setErrors(stateErrors);
    }
    formik.setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValuesChange = useCallback((event, index = 0) => {
    formik.setFieldValue(`${FORM_FIELD.VARIABLES}[${index}].value`, event.target.value, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestParams = { reset: false, onSuccess: handleSuccess, onFailure: handleFailure };
  const requestState = useActionState(
    isEdit ? ProfileActions.updateRequest : ProfileActions.createRequest,
    requestParams,
  );
  const variablesRequestState = useActionState(VariableActions.getListRequest, {
    reset: false,
    onSuccess: () => setIsLoading(false),
  });

  useMount(() => {
    dispatch(VariableActions.getListRequest());
  });

  useUpdateEffect(() => {
    if (defaultVariables?.length) {
      formik.setFieldValue(FORM_FIELD.VARIABLES, initialValues.variables, false);
    }
  }, [initialValues.variables, isEdit]);

  useUnmount(() => {
    requestState.reset();
    variablesRequestState.reset();
  });

  const getValueProps = useCallback(
    (variableValue, valueKey, variable) => {
      const isEvaluateType = variable.type === VARIABLE_TYPE.EVALUATE;
      const isDefaultValue = valueKey === FORM_FIELD.DEFAULT_VALUE;

      let placeholder = t('editProfileModal.variables.default.empty', '(empty)');
      if (isDefaultValue) {
        if (isEvaluateType) {
          placeholder = t(
            'editProfileModal.variables.default.evaluate.placeholder',
            'JavaScript code',
          );
        }
      } else {
        if (!variableValue) {
          placeholder = t('editProfileModal.variables.overriden.default', '(default)');
        }
        if (isEvaluateType) {
          placeholder = t(
            'editProfileModal.variables.overriden.evaluate.placeholder',
            'You cannot override code',
          );
        }
      }

      const readOnly = isDefaultValue || isEvaluateType || isDefault;
      return { placeholder, readOnly, clearOnFocus: variable.hasSecretValue };
    },
    [t, isDefault],
  );

  const renderLoader = () => (
    <LoaderFlexContainer>
      <Loader />
    </LoaderFlexContainer>
  );

  const renderVariablesList = renderWhenTrueOtherwise(
    () => (
      <MultiValueFormTable
        {...formik.getFieldProps(FORM_FIELD.VARIABLES)}
        onChange={handleValuesChange}
        columns={isDefault ? MULTI_VALUE_FORM_COLUMNS_FOR_DEFAULT : MULTI_VALUE_FORM_COLUMNS}
        error={formik.touched[FORM_FIELD.VALUE] && formik.errors[FORM_FIELD.VALUE]}
        readOnly={isDefault}
        getValueProps={getValueProps}
      />
    ),
    () => (
      <S.EmptyListState>
        <p>{t('editProfileModal.emptyList.text', "You haven't created any variables yet")}</p>
        <Link onMouseDown={handleManageVariables}>
          {t('editProfileModal.emptyList.link', 'Manage variables')}
        </Link>
      </S.EmptyListState>
    ),
  );

  return (
    <FormikProvider value={formik}>
      <S.Form className={className} onSubmit={formik.handleSubmit} twoColumn={isDefault}>
        {formik.values.variables.length > 5 && <S.FormBackdropOverrides />}
        <Header>{labels.title}</Header>
        {isDefault && (
          <Warning>
            <Icon name="info" />
            <Trans i18nKey="editProfileModal.default.message">
              This is your default profile. To edit its default values go to{' '}
              <Link onMouseDown={handleManageVariables}>Custom variables</Link>.
            </Trans>
          </Warning>
        )}
        <Content>
          <FormField label={t('editProfileModal.nameInput.label', 'Name')}>
            <Input
              {...formik.getFieldProps(FORM_FIELD.NAME)}
              placeholder={t('editProfileModal.nameInput.placeholder', 'Enter profile name')}
              error={formik.touched[FORM_FIELD.NAME] && formik.errors[FORM_FIELD.NAME]}
              fullWidth
              autoFocus
            />
          </FormField>

          <FormField
            label={t('editProfileModal.variables.label', 'Variables overrides')}
            description={t(
              'editProfileModal.variables.description',
              'Override variables to specific value when a test is run with this profile.',
            )}
          >
            <S.VariablesList>
              {isLoading ? renderLoader() : renderVariablesList(!!initialVariables.length)}
            </S.VariablesList>
          </FormField>
        </Content>
        <Footer>
          <ErrorInfo
            isVisible={
              requestState.hasInternalServerError || variablesRequestState.hasInternalServerError
            }
          />
          <Button onClick={modal.hide} disabled={formik.isSubmitting} type="button">
            {t('default.button.cancel')}
          </Button>
          <PaidFeature feature="variables" onRedirect={modal.hide}>
            {(isDisabled) => (
              <ActionButton
                disabled={isDisabled}
                pending={formik.isSubmitting}
                type="submit"
                variant="primary"
              >
                {labels.submitButton}
              </ActionButton>
            )}
          </PaidFeature>
        </Footer>
      </S.Form>
    </FormikProvider>
  );
};

EditProfileModal.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.shape({
    id: PropTypes.string,
    variables: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
      }),
    ),
  }),
};

export default EditProfileModal;
