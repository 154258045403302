import { useRef, useState, useCallback } from 'react';

import type { Step } from '@bugbug/core/types/steps';

import * as S from './MoreActions.styled';
import { TestStepMoreActions } from './TestStepMoreActions/TestStepMoreActions';

interface MoreActionsProps {
  className?: string;
  stepId: Step['id'];
  groupId: Step['groupId'];
  readOnly?: boolean;
  active?: Step['isActive'];
  runResultEnabled?: boolean;
  onDelete?: () => void;
}

export const MoreActions = ({
  className,
  stepId,
  groupId,
  active,
  readOnly = false,
  runResultEnabled,
  onDelete,
}: MoreActionsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [areActionsLocked, setAreActionsLocked] = useState(false);
  const lockActionButtons = useCallback(() => setAreActionsLocked(true), []);
  const unlockActionButtons = useCallback(() => setAreActionsLocked(false), []);

  return (
    <S.Container
      ref={containerRef}
      className={className}
      data-testid="MoreActions"
      force={areActionsLocked}
    >
      {!readOnly && (
        <TestStepMoreActions
          stepId={stepId}
          groupId={groupId}
          active={active}
          runResultEnabled={runResultEnabled}
          onOpen={lockActionButtons}
          onClose={unlockActionButtons}
          onDelete={onDelete}
        />
      )}
    </S.Container>
  );
};
