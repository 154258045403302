import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import type { DragAndDropStep } from '@bugbug/core/types/steps';

import { ElementSelectorBuilder } from '../ElementSelectorBuilder/ElementSelectorBuilder';
import InteractionPositionField from '../InteractionPositionField';

interface DragAndDropActionFieldsProps {
  context?: 'component' | 'test' | 'testRun';
  readOnly?: boolean;
}

export const DragAndDropActionFields = ({
  context = 'test',
  readOnly = false,
}: DragAndDropActionFieldsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'stepDetails.interaction.dnd' });
  const formik = useFormikContext<DragAndDropStep>();

  return (
    <>
      <ElementSelectorBuilder
        context={context}
        label={t('dndDragOn', 'Drag element')}
        name="selectorsPresets"
        disabled={readOnly}
      />
      <InteractionPositionField
        {...formik.getFieldProps<DragAndDropStep>('interactionPosition')}
        disabled={readOnly}
        name="interactionPosition"
        nameX="dndDragX"
        nameY="dndDragY"
        label={t('dndDragInteractionPosition.regular', 'Drag element: click position')}
        helperText={t(
          'dndDragInteractionPosition.helperText',
          'Which area of the element should be interacted with. For example you can decide if you want to click the center of the button or the bottom right.',
        )}
        customCoordsLabel={t('dndDragInteractionPosition.coords', 'Drag coordinates')}
      />
      <ElementSelectorBuilder
        context={context}
        label={t('dndDropOn', 'Drop on element')}
        name="dndDropSelectorsPresets"
        disabled={readOnly}
      />
      <InteractionPositionField
        {...formik.getFieldProps<DragAndDropStep>('dndDropInteractionPosition')}
        disabled={readOnly}
        name="dndDropInteractionPosition"
        nameX="dndDropX"
        nameY="dndDropY"
        label={t('dndDropInteractionPosition.regular', 'Drop position in element')}
        customCoordsLabel={t('dndDropInteractionPosition.coords', 'Drop coordinates')}
      />
    </>
  );
};
