import { DROPDOWN_ANCHOR } from '@bugbug/core/components/Dropdown';
import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import type { FormikErrors } from 'formik';
import type { InviteUsersDto } from '~/modules/organization/organization.schemas';

import { USER_ROLE, USER_ROLE_DESCRIPTIONS, USER_ROLE_LABELS } from '~/modules/constans';
import { selectRoleInCurrentOrganization } from '~/modules/organization/organization.selectors';
import { useAppSelector } from '~/modules/store';

import * as S from './InviteUserRow.styled';

interface InviteUserRowProps {
  index: number;
  onRemove: (index: number) => void;
}

export const InviteUserRow = ({ index, onRemove }: InviteUserRowProps) => {
  const formik = useFormikContext<InviteUsersDto>();

  const { getFieldProps, touched, errors } = formik;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'inviteUserModal',
  });

  const currentUserRole = useAppSelector(selectRoleInCurrentOrganization);
  const availableRoles = Object.values(USER_ROLE).filter((role) => role <= currentUserRole);

  return (
    <S.FieldsRow>
      <S.FormField
        label={index === 0 ? t('email.label', 'Email address') : undefined}
        labelId={`users[${index}].email`}
      >
        <Input
          aria-labelledby={`users[${index}].email`}
          data-testid={`users[${index}].email`}
          {...getFieldProps(`users[${index}].email`)}
          error={
            touched.users?.[index]?.email &&
            (
              errors.users?.[index] as FormikErrors<{
                email: string;
              }>
            )?.email
          }
        />
      </S.FormField>
      <S.FormField
        label={index === 0 ? t('role.label', 'Role') : undefined}
        labelId={`users[${index}].role`}
      >
        <Select
          optionsWidth={335}
          aria-labelledby={`users[${index}].role`}
          data-testid={`users[${index}].role`}
          {...getFieldProps(`users[${index}].role`)}
          renderSelectedOption={(option) => <div>{USER_ROLE_LABELS[option.value]}</div>}
          anchor={DROPDOWN_ANCHOR.BOTTOM_START}
          error={
            touched.users?.[index]?.role &&
            (
              errors.users?.[index] as FormikErrors<{
                role: boolean;
              }>
            )?.role
          }
          value={formik.values.users?.[index]?.role?.toString()}
        >
          {availableRoles.map((role) => (
            <SelectOption key={role} value={role.toString()}>
              {USER_ROLE_LABELS[role]}
              <S.SelectOptionDescription>{USER_ROLE_DESCRIPTIONS[role]}</S.SelectOptionDescription>
            </SelectOption>
          ))}
        </Select>
      </S.FormField>
      {index > 0 ? (
        <S.RemoveRowButton type="button" iconName="close" onClick={() => onRemove(index)} />
      ) : (
        <S.RemoveRowPlaceholder />
      )}
    </S.FieldsRow>
  );
};
