import { prop, head, isEmpty } from 'ramda';
import { createSelector } from 'reselect';

import { hasAtMostVersion } from '~/utils/misc';

export const selectExtensionDomain = (state) => state.extension;

export const selectExtensionActiveConnections = createSelector(
  selectExtensionDomain,
  (state) => state?.activeConnections || [],
);

const selectExtensionSettingsStore = createSelector(selectExtensionDomain, prop('settings'));

export const selectExtensionSettings = createSelector(
  selectExtensionSettingsStore,
  selectExtensionActiveConnections,
  (extensionSettings = {}, activeConnections) => {
    /* eslint-disable-next-line no-underscore-dangle */
    const _deprecatedSettings =
      head(activeConnections.filter((connection) => connection.isActive)) || {};

    if (hasAtMostVersion(_deprecatedSettings.version, '3.24.0')) {
      // if current version is lower than / equal to requested
      // TODO: remove if all users have the required extension version
      const settings = { ..._deprecatedSettings };
      settings.isDetected = !isEmpty(settings);
      settings.isPending = !settings?.websocketId && !settings.isDetected;
      settings.isConnectedWithServer = true;
      /* eslint-disable-next-line no-underscore-dangle */
      settings._deprecated = true;
      return settings;
    }

    return extensionSettings;
  },
);

export const selectExtensionWebsocketId = createSelector(
  selectExtensionDomain,
  prop('websocketId'),
);

export const selectExtensionVersion = createSelector(selectExtensionSettings, prop('version'));

export const selectIsExtensionConnected = createSelector(
  selectExtensionSettings,
  prop('isDetected'),
);
