import Checkbox from '@bugbug/core/components/Checkbox';
import CopyButton from '@bugbug/core/components/CopyButton';
import Input from '@bugbug/core/components/Input';
import { STEP_TYPE } from '@bugbug/core/constants/steps';
import { isAssertTrueFalseStep, isStepWithSelectors } from '@bugbug/core/types/steps';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { TypeChangeHandler } from './Interaction.types';
import type { GroupedStepsParams } from '~/modules/test/test.utils';

import type { AssertionType, Step } from '@bugbug/core/types/steps';
import type { StepRun } from '@bugbug/core/types/tests';
import FileUpload from '~/components/FileUpload';
import FormField from '~/components/FormField';
import { FormikInputWithVariables } from '~/components/InputWithVariables/FormikInputWithVariables';
import SecretToggleField from '~/components/SecretToggleField';
import { StepTypePickerInput } from '~/components/StepTypePickerInput/StepTypePickerInput';
import { selectProjectHomepageUrl } from '~/modules/project/project.selectors';
import { selectDefaultStepsParams } from '~/modules/test/test.selectors';
import { pickDefaultStepParams } from '~/modules/test/test.utils';
import { selectStepRun } from '~/modules/testRun/testRun.selectors';

import { AssertActionFields } from '../AssertActionFields/AssertActionFields';
import { DragAndDropActionFields } from '../DragAndDropActionFields/DragAndDropActionFields';
import { ElementSelectorBuilder } from '../ElementSelectorBuilder/ElementSelectorBuilder';
import ExecuteActionFields from '../ExecuteActionFields';
import InteractionPositionField from '../InteractionPositionField';
import { ScrollActionFields } from '../ScrollActionFields/ScrollActionFields';
import SelectActionFields from '../SelectActionFields';
import { SetLocalVariableFields } from '../SetLocalVariableFields/SetLocalVariableFields';
import { getInitialValues } from '../StepDetails/StepDetails.helpers';

import { FIELD_NAMES } from './Interaction.constants';
import { isBasicAuthVisible } from './Interaction.helpers';
import { ComputedValue, ComputedField } from './Interaction.styled';

export interface InteractionProps<TStep extends Step = Step> {
  step: TStep;
  context?: 'component' | 'test' | 'testRun';
  readOnly?: boolean;
}

export const Interaction = <TStep extends Step>({
  context = 'test',
  readOnly = false,
  step,
}: InteractionProps<TStep>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'stepDetails.interaction' });
  const formik = useFormikContext<TStep>();
  const projectHomepageUrl = useSelector(selectProjectHomepageUrl);
  const defaultStepsParams = useSelector(selectDefaultStepsParams) as unknown as GroupedStepsParams;
  const [isAuthVisible, setBasicAuthVisible] = useState(isBasicAuthVisible(formik.values));
  const stepRun = useSelector(selectStepRun(step.id)) as unknown as StepRun;
  const [isSecretToggleDisabled, setIsSecretToggleDisabled] = useState(readOnly);

  useEffect(() => {
    setBasicAuthVisible(isBasicAuthVisible(formik.values));

    if (!readOnly && formik.values[FIELD_NAMES.VALUE]) {
      const containsVariable = /\{\{.*\}\}/.test(formik.values[FIELD_NAMES.VALUE]);
      if (containsVariable) {
        formik.setFieldValue(FIELD_NAMES.HAS_SECRET_CONTENT, false);
      }
      setIsSecretToggleDisabled(containsVariable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, readOnly]);

  const handleTypeChange = useCallback<TypeChangeHandler>(
    (event) => {
      const { value: type, assertionProperty } = event.target;

      if (
        formik.values.type === 'assert' &&
        formik.values.type === type &&
        assertionProperty === formik.values.assertionProperty
      ) {
        return;
      }

      const defaultParams = pickDefaultStepParams(
        type,
        defaultStepsParams,
        type === 'assert' ? { assertionProperty } : undefined,
      );

      const updatedStep: Partial<TStep> = {
        ...step,
        type,
        ...defaultParams,
        assertionType:
          type === 'assert' ? (defaultParams?.assertionTypes?.at(0) as AssertionType) : undefined,
        assertionExpectedValue: undefined,
        selectorsPresets: isStepWithSelectors(formik.values)
          ? formik.values.selectorsPresets
          : undefined,
      };

      formik.setValues(getInitialValues(updatedStep));

      if (type === 'goto' && projectHomepageUrl) {
        formik.setFieldValue(FIELD_NAMES.URL, projectHomepageUrl);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step, projectHomepageUrl, formik.values],
  );

  const handleBasicAuthChange = useCallback((event) => {
    formik.setFieldValue(FIELD_NAMES.USERNAME, '');
    formik.setFieldValue(FIELD_NAMES.PASSWORD, '');
    setBasicAuthVisible(event.target.checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHasSecretValueChange = useCallback((event) => {
    if (event.target.value === 'false') {
      formik.setFieldValue(FIELD_NAMES.VALUE, '');
    }
    formik.handleChange(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFieldProps = useCallback(
    (fieldName) => {
      const { value, name } = formik.getFieldProps(fieldName);
      const { touched, error } = formik.getFieldMeta(fieldName);
      return { name, value, error: touched && error, 'aria-labelledby': name };
    },
    [formik],
  );

  useEffect(() => {
    if (isAssertTrueFalseStep(formik.values)) {
      formik.setFieldValue(FIELD_NAMES.ASSERTION_EXPECTED_VALUE, 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTypeField = () => (
    <FormField label={t('type', 'Action')} labelId={FIELD_NAMES.TYPE}>
      <StepTypePickerInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getFieldProps(FIELD_NAMES.TYPE)}
        onChange={handleTypeChange}
        readOnly={readOnly}
        assertionPropertyValue={formik.values[FIELD_NAMES.ASSERTION_PROPERTY]}
        assertionVariantValue={formik.values[FIELD_NAMES.ASSERTION_TYPE]}
      />
    </FormField>
  );

  const renderGoToBasicAuth = () => (
    <>
      <FormField label={t('username', 'Username')} labelId={FIELD_NAMES.USERNAME}>
        <FormikInputWithVariables fullWidth name={FIELD_NAMES.USERNAME} readOnly={readOnly} />
      </FormField>
      {stepRun.computedUsername && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('thisComputedUsername', 'This test run username')
              : t('lastComputedUsername', 'Last test run username')
          }
        >
          <ComputedValue>{stepRun.computedUsername}</ComputedValue>
          <CopyButton value={stepRun.computedUsername} />
        </ComputedField>
      )}
      <FormField label={t('password', 'Password')} labelId={FIELD_NAMES.PASSWORD}>
        <FormikInputWithVariables
          fullWidth
          type={FIELD_NAMES.PASSWORD}
          name={FIELD_NAMES.PASSWORD}
          readOnly={readOnly}
        />
      </FormField>
      {stepRun.computedPassword && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('thisComputedPassword', 'This test run password')
              : t('lastComputedPassword', 'Last test run password')
          }
        >
          <ComputedValue>{stepRun.computedPassword}</ComputedValue>
          <CopyButton value={stepRun.computedPassword} />
        </ComputedField>
      )}
    </>
  );

  const renderGoToFields = () => (
    <>
      <FormField label={t('url', 'URL')} labelId={FIELD_NAMES.URL}>
        <FormikInputWithVariables fullWidth name={FIELD_NAMES.URL} readOnly={readOnly} />
      </FormField>
      {stepRun.computedUrl && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('thisComputedUrl', 'This test run url')
              : t('lastComputedUrl', 'Last test run url')
          }
        >
          <ComputedValue>{stepRun.computedUrl}</ComputedValue>
          <CopyButton value={stepRun.computedUrl} />
        </ComputedField>
      )}
      <FormField>
        <Checkbox checked={isAuthVisible} onChange={handleBasicAuthChange} disabled={readOnly}>
          {t('basicAuthCheckbox', 'Password protected')}
        </Checkbox>
      </FormField>
      {isAuthVisible && renderGoToBasicAuth()}
    </>
  );

  const renderScrollFields = () => <ScrollActionFields readOnly={readOnly} />;

  const renderExecuteFields = () => <ExecuteActionFields readOnly={readOnly} />;

  const renderTypeFields = () => (
    <>
      <FormField label={t('value.label', 'Value')} labelId={FIELD_NAMES.VALUE}>
        <FormikInputWithVariables
          as="textarea"
          name={FIELD_NAMES.VALUE}
          fullWidth
          clearOnFocus={!readOnly && `${formik.values[FIELD_NAMES.HAS_SECRET_CONTENT]}` === 'true'}
          readOnly={readOnly}
          autoSize
        />
        <CopyButton value={formik.values[FIELD_NAMES.VALUE]} />
      </FormField>
      {stepRun.computedValue && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('thisComputedValue', 'This test run value')
              : t('lastComputedValue', 'Last test run value')
          }
        >
          <ComputedValue>{stepRun.computedValue}</ComputedValue>
          <CopyButton value={stepRun.computedValue} />
        </ComputedField>
      )}
      <SecretToggleField
        name={FIELD_NAMES.HAS_SECRET_CONTENT}
        onChange={handleHasSecretValueChange}
        value={formik.values[FIELD_NAMES.HAS_SECRET_CONTENT]}
        tooltipDisabled={isSecretToggleDisabled}
        disabled={isSecretToggleDisabled}
      />
    </>
  );

  const renderPromptFields = () => (
    <>
      <FormField label={t('answer.label', 'Answer')} labelId={FIELD_NAMES.VALUE}>
        <FormikInputWithVariables
          as="textarea"
          fullWidth
          name={FIELD_NAMES.VALUE}
          readOnly={readOnly}
          autoSize
        />
        <CopyButton value={formik.values[FIELD_NAMES.VALUE]} />
      </FormField>
      {stepRun.computedValue && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('thisComputedAnswer', 'This test run answer')
              : t('lastComputedAnswer', 'Last test run answer')
          }
        >
          <ComputedValue>{stepRun.computedValue}</ComputedValue>
          <CopyButton value={stepRun.computedValue} />
        </ComputedField>
      )}
    </>
  );

  const renderSelectOptionFields = () => (
    <SelectActionFields context={context} stepRun={stepRun} readOnly={readOnly} />
  );

  const renderUploadFileFields = () => (
    <FormField label={t('file', 'File')} labelId={FIELD_NAMES.VALUE}>
      <FileUpload
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getFieldProps(FIELD_NAMES.VALUE)}
        key={formik.values[FIELD_NAMES.VALUE]}
        onChange={formik.handleChange}
      />
    </FormField>
  );

  const renderDragAndDropFields = () => (
    <DragAndDropActionFields context={context} readOnly={readOnly} />
  );

  const renderSwitchContextFields = () => (
    <>
      <FormField
        label={t('stepDetails.windowAndTabs.tabNumber', 'Tab number')}
        labelId={FIELD_NAMES.TAB_NO}
      >
        <Input
          fullWidth
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getFieldProps(FIELD_NAMES.TAB_NO)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          readOnly={readOnly}
        />
      </FormField>
      <ElementSelectorBuilder
        label={t('framePath', 'Frame element')}
        context={context}
        computedValue={stepRun.computedSelector}
        disabled={readOnly}
        relationDisabled
      />
    </>
  );

  const renderMouseActionFields = () => (
    <InteractionPositionField
      {...formik.getFieldProps(FIELD_NAMES.INTERACTION_POSITION)}
      disabled={readOnly}
      name={FIELD_NAMES.INTERACTION_POSITION}
      nameX={FIELD_NAMES.MOUSE_X}
      nameY={FIELD_NAMES.MOUSE_Y}
      customDisabled
    />
  );

  const renderAssertFields = () => (
    <AssertActionFields context={context} stepRun={stepRun} readOnly={readOnly} />
  );

  const renderSetLocalVariableFields = () => (
    <SetLocalVariableFields context={context} stepRun={stepRun} readOnly={readOnly} />
  );

  const renderStepTypeSpecificFields = (type: Step['type']) => {
    const fieldsRenderers: Partial<Record<Step['type'], () => React.ReactNode>> = {
      // legacy
      answerPrompt: renderPromptFields,
      change: renderTypeFields,
      click: renderMouseActionFields,
      dblClick: renderMouseActionFields,
      goto: renderGoToFields,
      hover: renderMouseActionFields,
      mouseDown: renderMouseActionFields,
      mouseUp: renderMouseActionFields,
      newTab: renderGoToFields,
      switchContext: renderSwitchContextFields,
      type: renderTypeFields,
      uploadFile: renderUploadFileFields,
      // migrated
      assert: renderAssertFields,
      dragAndDrop: renderDragAndDropFields,
      execute: renderExecuteFields,
      scroll: renderScrollFields,
      select: renderSelectOptionFields,
      setLocalVariable: renderSetLocalVariableFields,
    };

    return fieldsRenderers[type]?.();
  };

  const hasElementFieldInGenericPlace =
    isStepWithSelectors(formik.values) &&
    ![
      STEP_TYPE.DRAG_AND_DROP,
      STEP_TYPE.ASSERT,
      STEP_TYPE.SET_LOCAL_VARIABLE,
      STEP_TYPE.SWITCH_CONTEXT,
      STEP_TYPE.SCROLL,
    ].includes(formik.values[FIELD_NAMES.TYPE]);

  return (
    <>
      {renderTypeField()}
      {hasElementFieldInGenericPlace && (
        <ElementSelectorBuilder
          context={context}
          computedValue={stepRun.computedSelector}
          disabled={readOnly}
        />
      )}
      {renderStepTypeSpecificFields(formik.values.type)}
    </>
  );
};
