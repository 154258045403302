import { STEP_TYPE } from '@bugbug/core/constants/steps';
import { useEffect, useLayoutEffect, useRef } from 'react';

import type { CollapsibleSectionRef } from '~/components/CollapsibleSection/CollapsibleSection';

import type { Step } from '@bugbug/core/types/steps';
import { TRANSITION_TIME } from '~/components/CollapsibleSection/CollapsibleSection.constants';
import ScrollShadow from '~/components/ScrollShadow';
import { useFormikContextWithTabsErrors } from '~/hooks/useFormikContextWithTabsErrors';

import ErrorDetails from '../ErrorDetails';
import Execution, { FIELD_NAMES_VALUES as EXECUTION_SECTION_FIELDS } from '../Execution';
import { Interaction } from '../Interaction/Interaction';
import { FIELD_NAMES_VALUES as INTERACTION_SECTION_FIELDS } from '../Interaction/Interaction.constants';
import StepRunExecutionUrl from '../StepRunExecutionUrl';
import StepRunScreenshot from '../StepRunScreenshot';
import { StepWindowScreenshot } from '../StepWindowScreenshot/StepWindowScreenshot';
import { WaitingConditions } from '../WaitingConditions/WaitingConditions';
import {
  FIELD_NAMES_VALUES as WAITING_CONDITIONS_SECTION_FIELDS,
  // eslint-disable-next-line object-curly-newline
} from '../WaitingConditions/WaitingConditions.constants';

import { SECTION, SECTION_LABEL } from './StepDetails.constants';
import * as S from './StepDetails.styled';

interface StepDetailsEditorProps {
  step: Step;
  context: 'component' | 'test' | 'testRun';
  readOnly?: boolean;
  failedStep?: boolean;
}

const SECTION_FIELDS = {
  [SECTION.INTERACTION]: INTERACTION_SECTION_FIELDS,
  [SECTION.EXECUTION]: EXECUTION_SECTION_FIELDS,
  [SECTION.WAITING_CONDITIONS]: WAITING_CONDITIONS_SECTION_FIELDS,
};

export const StepDetailsEditor = ({
  step,
  context,
  readOnly = false,
  failedStep = false,
}: StepDetailsEditorProps) => {
  const interactionRef = useRef<CollapsibleSectionRef>(null);
  const executionRef = useRef<CollapsibleSectionRef>(null);
  const conditionsRef = useRef<CollapsibleSectionRef>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContextWithTabsErrors<Step>({ fieldGroups: SECTION_FIELDS });

  const sectionsRefs = {
    [SECTION.INTERACTION]: interactionRef,
    [SECTION.EXECUTION]: executionRef,
    [SECTION.WAITING_CONDITIONS]: conditionsRef,
  };

  const hasWaitingConditions = !(
    [STEP_TYPE.GOTO, STEP_TYPE.NEW_TAB, STEP_TYPE.CLOSE_TAB] as Step['type'][]
  ).includes(formik.values.type);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [failedStep, step.id]);

  useLayoutEffect(() => {
    if (formik.groupsWithErrors.length) {
      formik.groupsWithErrors.forEach((type) => {
        sectionsRefs[type].current?.open();
      });
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        contentRef.current
          ?.querySelector('[aria-invalid="true"]')
          ?.scrollIntoView({ behavior: 'smooth' });
      }, TRANSITION_TIME * 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.groupsWithErrors]);

  return (
    <S.Content ref={contentRef} data-testid="StepDetailsEditor">
      <ScrollShadow />
      {context !== 'component' && <ErrorDetails step={step} />}
      <S.Section
        defaultExpanded
        label={SECTION_LABEL[SECTION.SCREENSHOTS]}
        expandedCacheName={SECTION.SCREENSHOTS}
      >
        {context === 'component' ? (
          <StepWindowScreenshot step={step} />
        ) : (
          <StepRunScreenshot stepId={step.id} />
        )}
        <StepRunExecutionUrl stepId={step.id} />
      </S.Section>
      <S.Section
        ref={interactionRef}
        defaultExpanded
        label={SECTION_LABEL[SECTION.INTERACTION]}
        expandedCacheName={SECTION.INTERACTION}
      >
        <Interaction context={context} step={step} readOnly={readOnly} />
      </S.Section>
      <S.Section
        ref={executionRef}
        defaultExpanded
        label={SECTION_LABEL[SECTION.EXECUTION]}
        expandedCacheName={SECTION.EXECUTION}
      >
        <Execution readOnly={readOnly} />
      </S.Section>
      {hasWaitingConditions && (
        <S.Section
          ref={conditionsRef}
          defaultExpanded
          label={SECTION_LABEL[SECTION.WAITING_CONDITIONS]}
          expandedCacheName={SECTION.WAITING_CONDITIONS}
        >
          <WaitingConditions readOnly={readOnly} />
        </S.Section>
      )}
    </S.Content>
  );
};
