import { UnescapedValue } from '@bugbug/core/theme/typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { AnswerPromptStep } from '@bugbug/core/types/steps';

import { AnswerPromptParamsContainer, TextBlock } from './ParamsCell.styled';

interface AnswerPromptParamsProps {
  className?: string;
  step: AnswerPromptStep;
}

export const AnswerPromptParams = memo<AnswerPromptParamsProps>(({ className, step }) => {
  const { t } = useTranslation();
  const { value } = step;
  const isAccept = value && value !== 'false';
  const hasCustomValue = isAccept && value !== 'true';

  let label = isAccept
    ? t('answerPromptParams.accept', 'Click "OK"')
    : t('answerPromptParams.cancel', 'Click "Cancel"');
  if (hasCustomValue) {
    label = t('answerPromptParams.acceptWithValue', 'Answer');
  }

  return (
    <AnswerPromptParamsContainer className={className}>
      {hasCustomValue ? (
        <TextBlock>
          <UnescapedValue>{value}</UnescapedValue>
        </TextBlock>
      ) : (
        <span>{label}</span>
      )}
    </AnswerPromptParamsContainer>
  );
});

AnswerPromptParams.displayName = 'AnswerPromptParams';
