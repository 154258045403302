import { CommonActions } from '@bugbug/core/actions/actions';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';
import useTestRunner from '~/hooks/useTestRunner';
import { dispatchInExtension } from '~/modules/extension/extension.dispatch';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { useIsNewPlaybackSupported } from '~/modules/test/test.hooks';
import { selectSingleTest, selectSortedSteps } from '~/modules/test/test.selectors';

import { useRowActiveBorder } from '../RowActiveBorder/RowActiveBorderContext';

import { Container, Button } from './GroupEmptyState.styled';

const GroupEmptyState = ({ className, testId, group, getGroupOffset, index, readOnly }) => {
  const { t } = useTranslation();
  const testRunner = useTestRunner({ id: testId });
  const modal = useModal();
  const dispatch = useAppDispatch();

  const activeBorderProps = useMemo(
    () => ({
      modifiers: {
        x: 2,
      },
      props: {
        top: null,
        bottom: {
          row: group,
          index,
          disabledSplitting: true,
        },
      },
    }),
    [group, index],
  );

  const { onItemEnter, onItemLeave } = useRowActiveBorder(activeBorderProps, getGroupOffset);

  const handleAddStep = useCallback(
    () =>
      modal.showWide(MODAL_TYPE.CREATE_STEP, {
        testId,
        groupId: group.id,
        atIndex: 0,
      }),
    [modal, testId, group.id],
  );

  const isNewPlaybackSupported = useIsNewPlaybackSupported();
  const test = useAppSelector(selectSingleTest);
  const steps = useAppSelector(selectSortedSteps);

  const handleRecordFromHere = async () => {
    if (!isNewPlaybackSupported) {
      testRunner.startLocalRunner({
        unconfirmedRelatedGroupId: group.id,
        unconfirmedIndex: 0,
        withRecording: true,
        withRedirect: true,
        stopWhenUnconfirmedGroups: true,
      });
      return;
    }

    if (testRunner.isPaused) {
      const action = CommonActions.setPlaybackCursorPosition({
        afterStep: {
          id: null,
          groupId: group.id,
        },
        runningStep: null,
        nextStep: null,
      });

      dispatch(action);
      await dispatchInExtension(action);

      await dispatchInExtension(CommonActions.resumeRecording());
      return;
    }

    const groupIndex = test.groups.indexOf(group.id);
    const lastStepFromPreviousGroups = steps.findLast(
      (step) => test.groups.indexOf(step.groupId) < groupIndex,
    );

    if (lastStepFromPreviousGroups) {
      testRunner.startLocalRunner({
        startFromStepId: null,
        stopAfterStepId: lastStepFromPreviousGroups.id,
        stopAction: 'record',
        record: {
          startAfterStepId: null,
          intoGroupId: group.id,
        },
        withRedirect: true,

        // todo: remove deprecated fields after full migration to new recording
        stopWhenUnconfirmedGroups: true,
      });
      return;
    }

    testRunner.record({
      url: '',
      groupId: group.id,
    });
  };

  const renderActions = renderWhenTrue(() => (
    <>
      <Button onClick={handleRecordFromHere}>
        {t('groupEmptyState.recordFromHere', 'Record from here')}
      </Button>
      {t('groupEmptyState.actionsConnector', 'or')}
      <Button onClick={handleAddStep}>{t('groupEmptyState.addStep', 'add new step')}</Button>
    </>
  ));

  return (
    <Container
      className={className}
      data-testid="GroupEmptyState"
      onMouseEnter={onItemEnter}
      onMouseLeave={onItemLeave}
    >
      {t('groupEmptyState.label', 'This group is empty.')}
      {renderActions(!readOnly)}
    </Container>
  );
};

GroupEmptyState.defaultProps = {
  className: null,
  testId: null,
  readOnly: false,
  groupOffset: 0,
};

GroupEmptyState.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  groupOffset: PropTypes.number,
  index: PropTypes.number.isRequired,
  testId: PropTypes.string,
  group: PropTypes.shape({}).isRequired,
};

export default GroupEmptyState;
