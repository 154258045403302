import IconButton from '@bugbug/core/components/Icon';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useFormikContext } from 'formik';

import type { CSSProperties } from 'react';

import type { SelectorsGroup } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';

import { SelectorsGroup as SelectorsGroupComponent } from '../ElementSelectorBuilder.styled';
import { SelectorsGroupField } from '../SelectorsGroupField/SelectorsGroupField';

import * as S from './CustomSelectorsPreset.styled';

interface DraggableCustomGroupProps {
  id: string;
  value: SelectorsGroup;
  first: boolean;
  name?: string;
  onChange?: SideEffect<SelectorsGroup>;
  onDelete?: SideEffect<void>;
  disabled?: boolean;
  relationDisabled?: boolean;
}

export const DraggableCustomGroup = ({
  id,
  name,
  value: group,
  first,
  onChange,
  onDelete,
  disabled,
  relationDisabled,
}: DraggableCustomGroupProps) => {
  const formik = useFormikContext();
  const { touched, error } = name ? formik.getFieldMeta(name) : { touched: false, error: '' };

  const { attributes, listeners, setNodeRef, isDragging, transform } = useSortable({
    id,
    disabled,
  });

  const draggingStyle: CSSProperties = {
    opacity: 0.1,
    pointerEvents: 'auto',
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    ...(!disabled && isDragging ? draggingStyle : {}),
  };

  return (
    <div ref={setNodeRef} style={style}>
      <SelectorsGroupComponent first={first}>
        {!disabled && (
          <S.DragHandle {...listeners} {...attributes}>
            <IconButton name="drag" />
          </S.DragHandle>
        )}
        <SelectorsGroupField
          name={name}
          value={group}
          onChange={onChange}
          onDelete={onDelete}
          first={first}
          touched={touched}
          error={error}
          selectOnly={relationDisabled}
          disabled={disabled}
        />
      </SelectorsGroupComponent>
    </div>
  );
};
