import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { has } from 'ramda';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormField from '~/components/FormField';
import { selectProjectSettings } from '~/modules/project/project.selectors';

import { FIELD_NAMES } from './Execution.constants';
import { SmallContainer, Input, Checkbox } from './Execution.styled';

const Execution = ({ readOnly }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const projectSettings = useSelector(selectProjectSettings);

  const getFieldProps = useCallback(
    (fieldName) => {
      const { value, name } = formik.getFieldProps(fieldName);
      const { touched, error } = formik.getFieldMeta(fieldName);
      return { name, value, error: touched && error };
    },
    [formik],
  );

  return (
    <>
      <FormField>
        <Checkbox
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getFieldProps(FIELD_NAMES.IS_ACTIVE)}
          onChange={formik.handleChange}
          checked={!!formik.values[FIELD_NAMES.IS_ACTIVE]}
          disabled={readOnly}
        >
          {t('execution.stepEnabled', 'Step enabled')}
        </Checkbox>
      </FormField>
      {has(FIELD_NAMES.CONTINUE_ON_FAILURE, formik.initialValues) && (
        <FormField>
          <Checkbox
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFieldProps(FIELD_NAMES.CONTINUE_ON_FAILURE)}
            onChange={formik.handleChange}
            checked={!!formik.values[FIELD_NAMES.CONTINUE_ON_FAILURE]}
            disabled={readOnly}
          >
            {t('execution.continueOnFailure', 'Continue on failure')}
          </Checkbox>
        </FormField>
      )}
      <SmallContainer>
        <FormField
          label={t('execution.timeout.label', 'Running timeout')}
          helperText={t(
            'execution.timeout.description',
            'By default inherited from global project settings. You can override this parameter for this step. For example in global settings you can set lower timeout, but for specific test steps you can increase this value.',
          )}
        >
          <Input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFieldProps(FIELD_NAMES.RUN_TIMEOUT)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            min="0"
            placeholder={t('execution.timeout.fieldPlaceholder', 'Default: {{ value }}', {
              value: projectSettings.runTimeoutLocal,
            })}
            endAdornment={t('default.units.timeout')}
            readOnly={readOnly}
            aria-label={t('execution.timeout.label')}
          />
        </FormField>
        <FormField
          label={t('execution.delaySleep.label', 'Delay / Sleep')}
          helperText={t(
            'execution.delaySleep.description',
            'Not recommended! Adding delay before the step is run should be used only in special situations, after you tried other waiting conditions.',
          )}
        >
          <Input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFieldProps(FIELD_NAMES.SLEEP)}
            type="number"
            min="0"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t('execution.delaySleep.fieldPlaceholder', 'Default: {{ value }}', {
              value: projectSettings.sleep,
            })}
            endAdornment={t('default.units.sleep')}
            readOnly={readOnly}
            aria-label={t('execution.delaySleep.label')}
          />
        </FormField>
      </SmallContainer>
    </>
  );
};

Execution.defaultProps = {
  readOnly: false,
};

Execution.propTypes = {
  readOnly: PropTypes.bool,
};

export default Execution;
