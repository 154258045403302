import { useMemo, useRef } from 'react';

import type { Route, RouteParams } from '@bugbug/core/types/routes';

import useAppRoutes from './useAppRoutes';

export const useBackUrl = <T extends Route>(
  defaultRouteName: T,
  defaultUrlParams?: Partial<RouteParams<T>>,
) => {
  const appRoutes = useAppRoutes(defaultRouteName);
  const locationStateBackUrl = appRoutes.state?.backUrl as string | undefined;
  const extraParams = useRef(defaultUrlParams);

  return useMemo(() => {
    if (locationStateBackUrl) {
      return locationStateBackUrl;
    }

    if (defaultRouteName) {
      return appRoutes.getRouteUrl(defaultRouteName, extraParams.current);
    }

    return appRoutes.getRouteUrl('testsList', extraParams.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationStateBackUrl, defaultRouteName]);
};
