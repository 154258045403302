import { COLOR } from '@bugbug/core/theme';
import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${COLOR.WHITE};
  max-width: 360px;
  margin: 90px auto;
  pointer-events: all;
`;

export const TipWrapper = styled(animated.div)`
  margin-top: 13px;
  padding-right: 16px;
  padding-left: 18px;
`;
