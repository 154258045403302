import { CommonActions } from '@bugbug/core/actions/actions';
import { RUN_STATUS } from '@bugbug/core/constants/status';
import { isFinishedStatus } from '@bugbug/core/types/base';
import { all, call, put, putResolve, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import { selectSingleProject, selectSingleProjectId } from '~/modules/project/project.selectors';
import { TestRunActions, TestRunTypes } from '~/modules/testRun/testRun.redux';
import { selectUserSettings, selectUserId } from '~/modules/user/user.selectors';
import selectWebsocketChannelName from '~/modules/websocket/websocket.selectors';
import analytics, { TRACK_EVENT_TYPE, TRACK_EVENT_ARG_TYPE } from '~/services/analytics';
import api from '~/services/api';
import toasts from '~/services/toasts';

import { setCurrentTestRun } from '../test/test.sagas';
import { selectSortedSteps } from '../test/test.selectors';
import { UIStateActions } from '../uiState/uiState.redux';

import {
  selectSingleTestRun,
  selectIsFirstTestRun,
  selectTestRunListPagination,
} from './testRun.selectors';

export function* getSingle({ id }) {
  try {
    const { data: responseData } = yield call(api.testRuns.get, id);
    yield put(TestRunActions.getSingleSuccess(responseData));
  } catch (error) {
    yield put(TestRunActions.getSingleFailure(error));
  }
}

export function* getList({ page = 1, query, sortBy, descOrder = false }) {
  try {
    const projectId = yield select(selectSingleProjectId);
    const pagination = yield select(selectTestRunListPagination);
    let ordering = sortBy;

    if (descOrder) {
      ordering = `-${ordering}`;
    }

    const params = {
      project_id: projectId,
      page_size: pagination.pageSize,
      page,
      query,
      ordering,
    };

    const { data: responseData } = yield call(api.testRuns.getList, params);

    yield put(TestRunActions.getListSuccess(responseData, query, sortBy, descOrder));
  } catch (error) {
    yield put(TestRunActions.getListFailure(error));
  }
}

export function* remove({ ids }) {
  try {
    if (ids) {
      yield call(api.testRuns.removeSelected, { testRunsIds: ids });
    } else {
      const project = yield select(selectSingleProject);
      yield call(api.testRuns.removeAll, { projectId: project.id });
    }

    yield put(TestRunActions.removeSuccess(ids));
  } catch (error) {
    yield put(TestRunActions.removeFailure(error));
  }
}

export function* stop({ ids }) {
  try {
    const userSettings = yield select(selectUserSettings);
    const channelName = yield select(selectWebsocketChannelName);
    const data = {
      channelName,
      runMode: userSettings.runMode,
    };

    if (ids) {
      data.testRunsIds = ids;
      yield call(api.testRuns.stopSelected, data);
    } else {
      const project = yield select(selectSingleProject);
      data.projectId = project.id;
      yield call(api.testRuns.stopAll, data);
    }
    yield put(TestRunActions.stopSuccess(ids));
    yield call(toasts.tests.showTestsStopped, ids);
  } catch (error) {
    yield put(TestRunActions.stopFailure(error));
  }
}

export function* updated({ data }) {
  const projectId = yield select(selectSingleProjectId);

  const testProjectId = data.projectId;
  if (!data.suiteRun) {
    if (testProjectId !== projectId) {
      return;
    }

    const toastFuncByStatus = {
      [RUN_STATUS.PASSED]: toasts.tests.showTestPassed,
      [RUN_STATUS.FAILED]: toasts.tests.showTestFailed,
      [RUN_STATUS.ERROR]: toasts.tests.showTestError,
    };

    const currentUserId = yield select(selectUserId);
    const currentTestRun = yield select(selectSingleTestRun);

    if (toastFuncByStatus[data.status] && data.user?.id === currentUserId) {
      const organizationId = yield select(selectCurrentOrganizationId);
      const shouldAutoClose =
        ![RUN_STATUS.FAILED, RUN_STATUS.ERROR].includes(data.status) ||
        currentTestRun.id === data.id;
      if (currentTestRun.status !== data.status) {
        yield call(toastFuncByStatus[data.status], data, organizationId, shouldAutoClose);
      }
    }

    if (data.id === currentTestRun.id && window.location.pathname.includes(data.test.id)) {
      yield call(setCurrentTestRun, { testRun: data, redirect: false });
      if (
        [RUN_STATUS.PASSED, RUN_STATUS.FAILED, RUN_STATUS.ERROR, RUN_STATUS.STOPPED].includes(
          data.status,
        )
      ) {
        yield put(
          CommonActions.setIsSingleLoading({
            isLoading: false,
            testId: data.test.id,
          }),
        );
        yield put(UIStateActions.disablePlaybackSelectedPositions());
      }
    }
  }

  if (testProjectId === projectId) {
    const isFirstTestRun = yield select(selectIsFirstTestRun);
    if (isFirstTestRun && isFinishedStatus(data.status)) {
      yield call(analytics.trackEvent, TRACK_EVENT_TYPE.FIRST_RUN_EVER_FINISHED, {
        [TRACK_EVENT_ARG_TYPE.TEST_RUN_ID]: data.id,
        [TRACK_EVENT_ARG_TYPE.TEST_RUN_STATUS]: data.status,
        [TRACK_EVENT_ARG_TYPE.TEST_RUN_METHOD]: data.runMode,
      });
    }
    yield put(TestRunActions.addUpdated(data));
  }
}

export function* refreshPlaybackCursorPosition({ data }) {
  const currentTestRun = yield select(selectSingleTestRun);
  if (data.testRunId === currentTestRun?.id && window.location.pathname.includes(data.testId)) {
    if (data.status === RUN_STATUS.RUNNING) {
      const { stepId, groupId } = data;
      const steps = yield select(selectSortedSteps);
      const stepIndex = steps.findIndex((step) => step.id === stepId);
      const prevStep = steps[stepIndex - 1];
      const nextStep = steps[stepIndex + 1];
      yield putResolve(
        CommonActions.setPlaybackCursorPosition({
          afterStep: prevStep
            ? {
                id: prevStep.id,
                groupId: prevStep.groupId,
              }
            : null,
          runningStep: {
            id: stepId,
            groupId,
          },
          nextStep: nextStep
            ? {
                id: nextStep.id,
                groupId: nextStep.groupId,
              }
            : null,
        }),
      );
    }

    if (data.status === RUN_STATUS.PASSED) {
      const { stepId, groupId } = data;
      const steps = yield select(selectSortedSteps);
      const stepIndex = steps.findIndex((step) => step.id === stepId);
      const nextStep = steps[stepIndex + 1];
      yield putResolve(
        CommonActions.setPlaybackCursorPosition({
          afterStep: { id: stepId, groupId },
          runningStep: null,
          nextStep: nextStep ? { id: nextStep.id, groupId: nextStep.groupId } : null,
        }),
      );
    }
  }
}

export default function* testRunSagas() {
  yield all([
    yield takeLatest(TestRunTypes.GET_SINGLE_REQUEST, getSingle),
    yield takeLatest(TestRunTypes.GET_LIST_REQUEST, getList),
    yield takeLatest(TestRunTypes.UPDATED, updated),
    yield takeLatest(TestRunTypes.REMOVE_REQUEST, remove),
    yield takeLatest(TestRunTypes.STOP_REQUEST, stop),
    yield takeLatest(TestRunTypes.STOP_REQUEST, stop),
    yield takeEvery(TestRunTypes.STEP_RUN_RESULT_UPDATED, refreshPlaybackCursorPosition),
  ]);
}
