import { forwardRef, useCallback } from 'react';

import type React from 'react';

import { noop } from '../../utils/toolbox';

import { Container, Input, Checkmark, Label } from './RadioButton.styled';

interface RadioButtonProps
  extends Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'name' | 'value' | 'disabled' | 'checked' | 'className'
  > {
  children?: React.ReactNode;
}

interface RadioButtonRef extends HTMLInputElement {}

export const RadioButton = forwardRef<RadioButtonRef, RadioButtonProps>((props, ref) => {
  const {
    className,
    checked = false,
    children,
    onChange = noop,
    disabled = false,
    name,
    value,
  } = props;
  const handleChange = useCallback(onChange, [onChange]);

  return (
    <Container className={className} data-testid="RadioButton">
      <Input
        name={name}
        onChange={handleChange}
        ref={ref}
        checked={!!checked}
        disabled={disabled}
        value={value}
        aria-labelledby={name}
        id={name}
      />
      <Checkmark />
      {children && <Label htmlFor={name}>{children}</Label>}
    </Container>
  );
});

RadioButton.displayName = 'RadioButton';
