import IconButton from '@bugbug/core/components/IconButton';
import mixins from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

import { Code, Line, Snippet } from '~/components/Code/Code';
import { Editor } from '~/components/Code/Code.styled';

export const FullScreenCode = styled(Code)`
  height: 100%;

  ${Editor} {
    display: flex;
    flex: 1;
    height: 100%;

    .CodeMirror {
      min-height: 100%;
      flex: 1;
    }
  }

  ${Snippet} {
    ${mixins.layout.flexColumnCenter};
    align-items: stretch;
    height: 100%;
  }

  ${Line} {
    flex: none;
  }

  ${Line}:nth-child(2) {
    flex: 1;
  }
`;

export const MinimizeIconButton = styled(IconButton).attrs(() => ({ iconName: 'minus' }))`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.text.normal} !important;

  svg {
    width: 20px;
    height: 20px;
  }
`;
