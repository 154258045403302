import Link from '@bugbug/core/components/Link';
import { SelectGroup } from '@bugbug/core/components/Select';
import { useTranslation } from 'react-i18next';

import type { SelectorsGroup } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';

import { RELATION_NAMES } from './ElementRelationField.constants';
import * as S from './ElementRelationField.styled';

interface ElementRelationFieldProps {
  value: SelectorsGroup['relation'];
  disabled?: boolean;
  onChange?: SideEffect<SelectorsGroup['relation']>;
}

export const ElementRelationField = ({ value, disabled, onChange }: ElementRelationFieldProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'elementQuery.elementRelation' });

  return (
    <S.Select
      anchor="bottom-end"
      value={value}
      variant="primary"
      disabled={disabled}
      onChange={onChange}
      renderSelectedOption={(option) => option.label}
    >
      <S.GlobalSelectOverride />
      <S.Help>
        <h3>{t('help.title', 'HELP')}</h3>
        <p>
          {t(
            'help.description',
            'Find related element among parents or children of the previously selected element. Useful when it is not possible to directly create a reliable selector.',
          )}{' '}
          <Link
            to={t('help.link', 'https://docs.bugbug.io/troubleshooting/common-selectors-issues')}
            openNewTab
          >
            {t('help.linkText', 'More examples')}
          </Link>
        </p>
        <S.ExampleSvg />
      </S.Help>
      <SelectGroup>
        <S.RelationOption value="ancestor" label={RELATION_NAMES.ancestor}>
          <S.Relation>
            <span>{RELATION_NAMES.ancestor}</span>
            <span>{t('ancestor.description', 'any ancestor')}</span>
          </S.Relation>
        </S.RelationOption>
        <S.RelationOption value="descendant" label={RELATION_NAMES.descendant}>
          <S.Relation>
            <span>{RELATION_NAMES.descendant}</span>
            <span>{t('descendant.description', 'any descendant')}</span>
          </S.Relation>
        </S.RelationOption>
        <S.RelationOption value="sibling" label={RELATION_NAMES.sibling}>
          <S.Relation>
            <span>{RELATION_NAMES.sibling}</span>
            <span> {t('sibling.description', 'same level')}</span>
          </S.Relation>
        </S.RelationOption>
      </SelectGroup>
    </S.Select>
  );
};
