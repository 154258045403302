import Icon from '@bugbug/core/components/Icon';
import { STEP_RUN_ERROR_CODE } from '@bugbug/core/constants/errors';
import { isFailedStatus } from '@bugbug/core/types/base';
import { getExtendedRunStatus } from '@bugbug/core/utils/status';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { ISODate } from '@bugbug/core/types/aliases';
import type { ScrollStep, Step } from '@bugbug/core/types/steps';
import type { StepRun, TestRun } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';
import { TutorialTooltip } from '~/components/TutorialTooltip/TutorialTooltip';
import useModal from '~/hooks/useModal';
import useTestFailedStateDetector from '~/hooks/useTestFailedStateDetector';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { selectSingleTestRun, selectStepRun } from '~/modules/testRun/testRun.selectors';
import { useSetUserFlagsMutation } from '~/modules/user/user.api';
import { selectHasTooltipAfterFailShown, selectUserId } from '~/modules/user/user.selectors';
import { OnboardingTitle } from '~/views/Onboarding/Onboarding.styled';

import AssertFailedDetails from './AssertFailedDetails';
import * as S from './ErrorDetails.styled';
import { GenericErrorDescription } from './GenericErrorDescription';
import HelpBox from './HelpBox';
import { PageLoadingFailedDetails } from './PageLoadingFailedDetails/PageLoadingFailedDetails';
import { ScrollFailedDetails } from './ScrollFailedDetails/ScrollFailedDetails';
import WaitingConditionsFailedDetails from './WaitingConditionsFailedDetails';

interface ErrorDetailsProps {
  className?: string;
  step: Step;
}
const ErrorDetails = ({ className, step }: ErrorDetailsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(undefined, { keyPrefix: 'errorDetails' });
  const stepRun = useAppSelector(selectStepRun(step.id)) as unknown as StepRun;
  const hasStepFailed = isFailedStatus(stepRun.status);
  const extendedStatus = getExtendedRunStatus(stepRun);
  const testRun = useAppSelector(selectSingleTestRun) as unknown as Maybe<TestRun>;
  const userId = useAppSelector(selectUserId);
  const hasStepPassedWithIssues = extendedStatus === 'passed-with-issues';
  const hasStepFailedButIgnored = extendedStatus === 'failed-ignored';
  const shouldShowMessage = hasStepFailed || hasStepPassedWithIssues;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [setUserFlags] = useSetUserFlagsMutation();
  const modal = useModal();

  const dispatch = useAppDispatch();
  const hasTooltipAfterFailShown = useAppSelector(selectHasTooltipAfterFailShown) as Maybe<ISODate>;

  const hideTooltip = () => {
    setIsTooltipVisible(false);
  };

  const handleTestFailure = useCallback(
    ({ stepId }) => {
      if (step.id === stepId && containerRef.current) {
        containerRef.current.parentElement?.scrollTo?.({ top: 0, behavior: 'smooth' });
      }
    },
    [step.id],
  );

  useTestFailedStateDetector(handleTestFailure, true);
  const [highlightedElement, setHighlightedElement] = useState<Maybe<HTMLElement>>(null);

  useEffect(() => {
    if (
      hasTooltipAfterFailShown !== null ||
      !shouldShowMessage ||
      !userId ||
      !testRun?.user?.id ||
      testRun.user.id !== userId
    ) {
      return;
    }

    const enterAnimationDuration = 300;
    const id = setTimeout(() => {
      setUserFlags({ docsTooltipAfterFailShown: new Date().toISOString() });
      setIsTooltipVisible(true);
    }, enterAnimationDuration);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(id);
  }, [
    dispatch,
    hasTooltipAfterFailShown,
    setUserFlags,
    shouldShowMessage,
    testRun?.user?.id,
    userId,
  ]);

  if (!shouldShowMessage) {
    return null;
  }

  const renderContent = () => {
    const hasFailedConditions = stepRun.errorCode === 'FAILED_WAITING_CONDITIONS';
    const isElementMissing = stepRun.errorCode === 'ELEMENT_DOES_NOT_EXIST';
    const meetsInitialConditions = !isElementMissing && !hasFailedConditions;

    switch (true) {
      case stepRun.errorCode === 'ASSERT_FAILED' && meetsInitialConditions:
        return <AssertFailedDetails stepRun={stepRun} />;
      case stepRun.type === 'scroll' && stepRun.errorCode === 'SCROLL_FAILED':
        return <ScrollFailedDetails step={step as ScrollStep} stepRun={stepRun} />;
      case stepRun.errorCode === STEP_RUN_ERROR_CODE.PAGE_LOADING_ERROR && meetsInitialConditions:
        return <PageLoadingFailedDetails error={stepRun.error} />;
      case hasFailedConditions || hasStepPassedWithIssues:
        return <WaitingConditionsFailedDetails stepRun={stepRun} />;
      default:
        return <GenericErrorDescription stepRun={stepRun} step={step} />;
    }
  };

  const renderFooter = () => {
    const errorLabel =
      stepRun.errorCode === 'PAGE_LOADING_ERROR' ? stepRun.error : stepRun.errorCode;
    const { runTimeout } = stepRun;

    if (stepRun.timeout && stepRun.status !== 'error') {
      return (
        <S.Footer>
          <Trans i18nKey="errorDetails.footer.timeoutLabel">
            Timeout after {{ runTimeout }}s<br />
            {{ errorLabel }}
          </Trans>
        </S.Footer>
      );
    }

    return (
      <S.Footer>
        {t('footer.error.code', '{{ code }}', { code: errorLabel })}
        {stepRun.errorId && (
          <S.ErrorId>
            {t('footer.error.id', '(id: {{errorId}})', { errorId: stepRun.errorId })}
          </S.ErrorId>
        )}
      </S.Footer>
    );
  };

  return (
    <>
      {isTooltipVisible && !modal.isOpen && (
        <TutorialTooltip
          highlightedElement={highlightedElement}
          primaryPopoverPlacement="left-start"
        >
          <S.TooltipContainer>
            <div>
              <S.FixLogo />
              <OnboardingTitle>{t('error.tooltip.title', "Let's fix your test!")}</OnboardingTitle>
            </div>
            <S.TooltipParagraph>
              {t('error.tooltip.subtitle', 'Here you can find the reasons why your test failed.')}
            </S.TooltipParagraph>
            <div>
              <S.TooltipParagraph>
                {t(
                  'error.tooltip.description',
                  'BugBug offers many features to help you fix failed tests:',
                )}
              </S.TooltipParagraph>
              <S.TooltipLinksContainer>
                <S.TooltipLinkItem>
                  <Icon name="dot" height={6} width={6} />
                  <S.TooltipLinkText
                    href="https://docs.bugbug.io/recording-tests-steps/re-recording-steps"
                    target="_blank"
                    rel="noopener"
                  >
                    {t('error.tooltip.link.reRecordSteps', 'Re-record steps')}
                  </S.TooltipLinkText>
                </S.TooltipLinkItem>
                <S.TooltipLinkItem>
                  <Icon name="dot" height={6} width={6} />
                  <S.TooltipLinkText
                    href="https://docs.bugbug.io/debugging-tests/breakpoint-run-step-by-step"
                    target="_blank"
                    rel="noopener"
                  >
                    {t('error.tooltip.link.pauseTest', 'Pause tests and debug')}
                  </S.TooltipLinkText>
                </S.TooltipLinkItem>
                <S.TooltipLinkItem>
                  <Icon name="dot" height={6} width={6} />
                  <S.TooltipLinkText
                    href="https://docs.bugbug.io/preventing-failed-tests/selectors#manual-selectors"
                    target="_blank"
                    rel="noopener"
                  >
                    {t('error.tooltip.link.useOwnSelectors', 'Use your own selectors')}
                  </S.TooltipLinkText>
                </S.TooltipLinkItem>
              </S.TooltipLinksContainer>
            </div>
            <S.DismissButton variant="primary" onClick={hideTooltip}>
              {t('error.tooltip.button', 'OK, got it')}
            </S.DismissButton>
          </S.TooltipContainer>
        </TutorialTooltip>
      )}
      <S.TooltipRectSource ref={setHighlightedElement}>
        <S.Container
          className={className}
          data-testid="ErrorDetails"
          warning={hasStepPassedWithIssues || hasStepFailedButIgnored}
          ref={containerRef}
        >
          {renderContent()}
          <HelpBox />
          {renderFooter()}
        </S.Container>
      </S.TooltipRectSource>
    </>
  );
};

export default ErrorDetails;
