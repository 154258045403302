import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useConfetti } from '~/hooks/useConfetti';
import useModal from '~/hooks/useModal';
import { OnboardingTitle } from '~/views/Onboarding/Onboarding.styled';

import { ExternalLink } from './ExternalLink';
import { externalLinks } from './FirstSuccessfulRunModal.constants';
import * as S from './FirstSuccessfulRunModal.styled';

export const FirstSuccessfulRunModal = () => {
  const modal = useModal();
  const { t } = useTranslation(undefined, { keyPrefix: 'firstSuccessfulRunModal' });
  const { show } = useConfetti();

  useEffect(() => {
    show();
  }, [show]);

  return (
    <S.Wrapper>
      <S.ConfettiIcon />
      <OnboardingTitle>{t('title', 'Achievement unlocked!')}</OnboardingTitle>
      <S.Subtitle>
        {t(
          'subtitle',
          'Your first automated test passed! Ready to level up? Read our guides and test like a pro.',
        )}
      </S.Subtitle>
      <S.ExternalLinksContainer>
        {externalLinks.map((details) => (
          <ExternalLink key={details.title} {...details} />
        ))}
      </S.ExternalLinksContainer>
      <S.ProceedButton onClick={modal.hide}>{t('awesomeButton', 'Awesome!')}</S.ProceedButton>
    </S.Wrapper>
  );
};
