import { StatusBadge } from '@bugbug/core/components/StatusBadge/StatusBadge';
import Tooltip from '@bugbug/core/components/Tooltip';
import { isRunningStatus } from '@bugbug/core/types/base';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Step } from '@bugbug/core/types/steps';
import type { StepRun } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';
import { useAppSelector } from '~/modules/store';
import { selectStepRun } from '~/modules/testRun/testRun.selectors';

import { PendingStatus } from './PendingStatus';
import * as S from './StepStatusCell.styled';

interface StepStatusCellProps {
  className?: string;
  stepId: Step['id'];
  sleep: Maybe<number>;
  progressTooltipDisabled?: boolean;
}

export const StepStatusCell = ({
  className,
  stepId,
  sleep,
  progressTooltipDisabled = false,
}: StepStatusCellProps) => {
  const stepRun = useAppSelector(selectStepRun(stepId)) as unknown as StepRun;
  const { t } = useTranslation();
  const isRunning = isRunningStatus(stepRun.status);

  const PendingState = useMemo(
    () => (
      <PendingStatus
        stepRunId={stepId}
        sleep={sleep}
        progressTooltipDisabled={progressTooltipDisabled}
      />
    ),
    [stepId, sleep, progressTooltipDisabled],
  );

  const IdleState = useMemo(() => {
    let label = '';
    if (stepRun.warning) {
      label = t('stepStatusCell.passedWithWarningLabel', 'Passed but with some issues');
    }

    if (stepRun.status !== 'passed' && stepRun.ignoredFailed) {
      label = t('stepStatusCell.continueOnFailureLabel', 'Step failed but the issue was ignored');
    }

    return (
      <Tooltip content={label}>
        <span>
          <StatusBadge
            status={stepRun.status}
            warning={stepRun.warning}
            ignoredFailed={stepRun.ignoredFailed}
          />
        </span>
      </Tooltip>
    );
  }, [t, stepRun.warning, stepRun.status, stepRun.ignoredFailed]);

  return (
    <S.Container className={className} data-testid="StepStatusCell">
      {isRunning && PendingState}
      {!isRunning && IdleState}
    </S.Container>
  );
};
