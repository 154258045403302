import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ExpandableCodeProps } from '../Code/ExpandableCode';

import FormField from '~/components/FormField';

import { Snippet, Function, Argument, Line } from '../Code/Code';
import { ExpandableCode } from '../Code/ExpandableCode';

interface CodeFieldProps extends ExpandableCodeProps {
  functionArguments?: string[];
  description?: string;
}

export const CodeField = ({ functionArguments = [], description, ...props }: CodeFieldProps) => {
  const { t } = useTranslation();

  const CodeWrapper = useMemo(
    () =>
      /* eslint-disable-next-line react/no-unstable-nested-components, react/display-name */
      ({ children }) =>
        (
          <Snippet>
            <Line>
              <Function>async function</Function>(
              {functionArguments.map((arg) => (
                <Argument key={arg}>{arg}</Argument>
              ))}
              {') {'}
            </Line>
            <Line>{children}</Line>
            <Line>{'}'}</Line>
          </Snippet>
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FormField
      label={t('codeField.label', 'JavaScript code')}
      labelId="codeField"
      description={description}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ExpandableCode {...props} Wrapper={CodeWrapper} aria-labelledby="codeField" />
    </FormField>
  );
};
