import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgTrialPerkCheck = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 61, height: 61, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { x: 0.5, y: 0.0214844, width: 60, height: 60, rx: 30, fill: "white" }),
  /* @__PURE__ */ jsx("rect", { x: 14.5, y: 23.1641, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.0703, y: 18.5938, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 16.7852, y: 20.8789, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 23.6445, y: 14.0215, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 21.3555, y: 16.3066, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 25.9297, y: 14.0215, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 28.2148, y: 14.0215, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 30.5, y: 14.0215, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 32.7852, y: 14.0215, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 35.0703, y: 14.0215, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 37.3555, y: 16.3066, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 39.6445, y: 18.5938, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 41.9297, y: 20.8789, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 44.2148, y: 23.1641, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 44.2148, y: 25.4492, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 44.2148, y: 27.7363, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 44.2148, y: 30.0215, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 44.2148, y: 32.3066, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 44.2148, y: 34.5938, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 39.6445, y: 39.1641, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 41.9297, y: 36.8789, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 37.3555, y: 41.4492, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 35.0703, y: 43.7363, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 32.7852, y: 43.7363, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 30.5, y: 43.7363, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 28.2148, y: 43.7363, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 25.9297, y: 43.7363, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 23.6445, y: 43.7363, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.0703, y: 39.1641, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 21.3555, y: 41.4492, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 25.9297, y: 34.5938, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 28.2148, y: 32.3066, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 30.5, y: 30.0215, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 32.7852, y: 27.7363, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 35.0703, y: 25.4492, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 37.3555, y: 23.1641, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 23.6445, y: 32.3066, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 21.3555, y: 30.0215, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 16.7852 39.1641)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 14.5 34.5938)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 14.5 36.8789)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 14.5 32.3066)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 14.5 30.0215)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 14.5 27.7363)", fill: "#521EC9" })
] });
const ForwardRef = forwardRef(SvgTrialPerkCheck);
export default ForwardRef;
