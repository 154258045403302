export const SOCIAL_AUTH_STATE = 'socialAuthState';

export const SOCIAL_AUTH_RETURN_URL = 'socialAuthReturnUrl';

export const AUTH_PROVIDER = {
  GITHUB: 'github',
  GOOGLE: 'google',
};

export const BROADCAST_EVENTS = {
  LOGOUT: 'logout',
};

export const USER_SOURCE = {
  SEARCH_ENGINES: 'searchEngines',
  INTERNET_ADS: 'internetAds',
  TOOLS_RANKINGS: 'toolsRankings',
  BUGBUG_BLOG: 'bugbugBlog',
  CUSTOMER_REVIEW_SITES: 'customerReviewSites',
  ONLINE_COMMUNITIES_FORUMS: 'onlineCommunitiesForums',
  FRIEND_REFERRAL: 'friendReferral',
  SOCIAL_MEDIA: 'socialMedia',
  OTHER: 'other',
} as const;

export const USER_SOURCES = [
  USER_SOURCE.SEARCH_ENGINES,
  USER_SOURCE.INTERNET_ADS,
  USER_SOURCE.TOOLS_RANKINGS,
  USER_SOURCE.BUGBUG_BLOG,
  USER_SOURCE.CUSTOMER_REVIEW_SITES,
  USER_SOURCE.ONLINE_COMMUNITIES_FORUMS,
  USER_SOURCE.FRIEND_REFERRAL,
  USER_SOURCE.SOCIAL_MEDIA,
  USER_SOURCE.OTHER,
];

export const COMPANY_ROLE = {
  QA_ENGINEER: 'qa',
  FRONTEND_DEVELOPER: 'frontend-developer',
  BACKEND_DEVELOPER: 'backend-developer',
  MOBILE_DEVELOPER: 'mobile-developer',
  DEV_OPS: 'devops',
  PRODUCT_OWNER: 'product-owner',
  PROJECT_MANAGER: 'project-manager',
  CTO_TECH_LEAD: 'cto-cio-tech-lead',
  CEO_EXECUTIVE: 'ceo-executive',
  CUSTOM_SUPPORT: 'customer-support',
  FINANCE_ACCOUNTS: 'finance-accounts',
  OTHER: 'other',
};

export const COMPANY_ROLES = [
  COMPANY_ROLE.QA_ENGINEER,
  COMPANY_ROLE.FRONTEND_DEVELOPER,
  COMPANY_ROLE.BACKEND_DEVELOPER,
  COMPANY_ROLE.MOBILE_DEVELOPER,
  COMPANY_ROLE.DEV_OPS,
  COMPANY_ROLE.PRODUCT_OWNER,
  COMPANY_ROLE.PROJECT_MANAGER,
  COMPANY_ROLE.CTO_TECH_LEAD,
  COMPANY_ROLE.CEO_EXECUTIVE,
  COMPANY_ROLE.CUSTOM_SUPPORT,
  COMPANY_ROLE.FINANCE_ACCOUNTS,
  COMPANY_ROLE.OTHER,
];
