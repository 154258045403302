import CheckboxBase from '@bugbug/core/components/Checkbox';
import styled from 'styled-components';

import WelcomeSVG from '~/images/welcome-logo.svg?react';

export const Checkbox = styled(CheckboxBase)`
  border: 1px solid ${({ theme }) => theme.colors.border.medium};
  border-radius: 5px;
  width: 100%;
  padding: 12px 16px;
  gap: 6px;
`;

export const WelcomeLogo = styled(WelcomeSVG)`
  height: 32px;
  width: 32px;
  display: block;
  margin: 0 auto 20px auto;
`;
