import Icon from '@bugbug/core/components/Icon';
import Link from '@bugbug/core/components/Link';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import localStorage from '~/services/localStorage';

import * as S from './InlineTutorialMessage.styled';

const storagePrefix = 'inline-tutorial';

const InlineTutorialMessage = ({ className, storageName, message, docsUrl, docsText, title }) => {
  const { t } = useTranslation();
  const storageKey = `${storagePrefix}.${storageName}`;
  const [isHidden, setIsHidden] = useState(localStorage.getUserItem(storageKey));

  if (isHidden) {
    return null;
  }

  const handleClose = () => {
    localStorage.setUserItem(storageKey);
    setIsHidden(true);
  };

  return (
    <S.Container className={className}>
      <S.GettingStarted alt={t('inlineTutorialMessage.image.altText', 'Inline tutorial')} />
      <S.Content>
        {title && <S.Title>{title}</S.Title>}
        <p>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          {message} {docsUrl && <Link url={docsUrl}>{docsText || docsUrl}</Link>}
        </p>
      </S.Content>
      {storageName && (
        <S.CloseButton onClick={handleClose} data-testid="CloseButton">
          <Icon name="close" />
        </S.CloseButton>
      )}
    </S.Container>
  );
};

InlineTutorialMessage.defaultProps = {
  className: null,
  title: '',
  docsUrl: '',
  docsText: '',
  storageName: '',
};

InlineTutorialMessage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  docsUrl: PropTypes.string,
  docsText: PropTypes.string,
  storageName: PropTypes.string,
};

export default InlineTutorialMessage;
