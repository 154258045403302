import type { RunStatus } from '@bugbug/core/types/base';

export const getDuration = ({
  status,
  duration,
  // TODO: Use this after BE implements firstRunDuration
  firstRunStatus: _,
}: {
  status: RunStatus;
  firstRunStatus?: RunStatus;
  duration: string;
}) => {
  const statusesWithDuration: RunStatus[] = ['passed', 'failed'];
  if (statusesWithDuration.includes(status)) {
    return duration;
  }
  return null;
};
