import type { SortByDropdownItemType } from '~/components/SortByDropdown/SortByDropdown.types';

import * as TablePartials from '~/components/Table';
import i18n from '~/translations';

import UserRowActions from './components/UserRowActions';

export const USERS_LIST_COLUMN_ID = {
  EMAIL: 'email',
  ROLE: 'role',
  ACTIONS: 'actions',
};

export const USERS_LIST_COLUMNS = [
  {
    id: USERS_LIST_COLUMN_ID.EMAIL,
    Header: i18n.t('projectSettings.users.table.columns.email', 'E-mail'),
    Cell: TablePartials.UserCell,
    accessor: 'email',
  },
  {
    id: USERS_LIST_COLUMN_ID.ROLE,
    Header: i18n.t('projectSettings.users.table.columns.role', 'Role'),
    Cell: TablePartials.TextCell,
    accessor: 'roleName',
  },
  {
    id: USERS_LIST_COLUMN_ID.ACTIONS,
    Cell: UserRowActions,
    maxWidth: 56,
  },
];
export const USERS_LIST_SORT_BY_OPTIONS: SortByDropdownItemType[] = [
  {
    id: USERS_LIST_COLUMN_ID.EMAIL,
    name: i18n.t('projectSettings.users.table.columns.email', 'Email'),
  },
  {
    id: USERS_LIST_COLUMN_ID.ROLE,
    name: i18n.t('projectSettings.users.table.columns.role', 'Role'),
  },
];
