import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgTrusted = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 15, height: 14, viewBox: "0 0 15 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0_9182_20101)", children: [
    /* @__PURE__ */ jsx("path", { d: "M13.6771 6.99612L12.2538 5.36862L12.4521 3.21612L10.3463 2.73779L9.24375 0.876953L7.26042 1.72862L5.27708 0.876953L4.17458 2.73779L2.06875 3.21029L2.26708 5.36862L0.84375 6.99612L2.26708 8.62362L2.06875 10.782L4.17458 11.2603L5.27708 13.127L7.26042 12.2695L9.24375 13.1211L10.3463 11.2603L12.4521 10.782L12.2538 8.62945L13.6771 6.99612ZM11.3729 7.85945L11.0463 8.23862L11.0929 8.73445L11.1979 9.87195L10.0896 10.1228L9.59958 10.2336L9.34292 10.6653L8.76542 11.6453L7.72708 11.1961L7.26042 10.9978L6.79958 11.1961L5.76125 11.6453L5.18375 10.6711L4.92708 10.2395L4.43708 10.1286L3.32875 9.87779L3.43375 8.73445L3.48042 8.23862L3.15375 7.85945L2.40125 7.00195L3.15375 6.13862L3.48042 5.75945L3.42792 5.25779L3.32292 4.12612L4.43125 3.87529L4.92125 3.76445L5.17792 3.33279L5.75542 2.35279L6.79375 2.80195L7.26042 3.00029L7.72125 2.80195L8.75958 2.35279L9.33708 3.33279L9.59375 3.76445L10.0838 3.87529L11.1921 4.12612L11.0871 5.26362L11.0404 5.75945L11.3671 6.13862L12.1196 6.99612L11.3729 7.85945Z", fill: "currentColor" }),
    /* @__PURE__ */ jsx("path", { d: "M6.14635 8.0218L4.79302 6.66263L3.92969 7.5318L6.14635 9.7543L10.428 5.46096L9.56469 4.5918L6.14635 8.0218Z", fill: "currentColor" })
  ] }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "clip0_9182_20101", children: /* @__PURE__ */ jsx("rect", { width: 14, height: 14, fill: "white", transform: "translate(0.257812)" }) }) })
] });
const ForwardRef = forwardRef(SvgTrusted);
export default ForwardRef;
