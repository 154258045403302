import { colors } from './colors';
import { fontWeight } from './fonts';
import { media } from './media';
import { shadows } from './shadows';

export * from './animations';
export * from './colors';
export * from './fonts';
export * from './global';
export * from './modes';
export * from './icons';
export * from './mixins';

export const theme = {
  media,
  colors,
  fontWeight,
  shadows,
} as const;
