import { ActionButton } from '@bugbug/core/components/Button';
import styled from 'styled-components';

import Card from '~/components/Card';
import FormFieldBase from '~/components/FormField';

export const OnboardingBox = styled.div`
  max-width: 380px;
`;

export const ContinueButton = styled(ActionButton).attrs(() => ({
  variant: 'primary',
  iconName: 'arrowRight',
  iconPosition: 'check',
}))`
  gap: 6px;

  svg {
    height: 18px;
    width: 18px;
  }

  span {
    margin-right: 0;
  }
`;

export const OnboardingCard = styled(Card)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const OnboardingTitle = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.normal};
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 0px;
  line-height: 27px;
  margin-bottom: 4px;
`;

export const OnboardingSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  width: 100%;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
  padding: 0;
  margin: 0;
`;

export const OnboardingParagraph = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 100%;
  text-align: center;
  margin: 0;
`;

export const FormField = styled(FormFieldBase)`
  padding-bottom: 0;
  margin: 0;
`;

export const OnboardingForm = styled.form`
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
`;
