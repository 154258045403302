import type { ProjectSelectorMethod } from '@bugbug/core/types/projects';
import i18n from '~/translations';

export const SELECTOR_PARAMS: Record<
  Exclude<ProjectSelectorMethod['name'], 'parentElementCustomAttributes'>,
  { label: string; extraValuePlaceholder: string; tooltip?: string; isExtraValueEditable?: boolean }
> = {
  elementId: {
    label: i18n.t('projectSettings.selectorsSettings.elementId.label', 'id'),
    extraValuePlaceholder: "id='example'",
  },
  elementName: {
    label: i18n.t('projectSettings.selectorsSettings.elementName.label', 'Input or form name'),
    extraValuePlaceholder: "name='example'",
  },
  elementHref: {
    label: i18n.t('projectSettings.selectorsSettings.elementHref.label', 'href'),
    extraValuePlaceholder: "href='/example'",
  },
  elementPlaceholder: {
    label: i18n.t(
      'projectSettings.selectorsSettings.elementPlaceholder.label',
      'Input placeholder',
    ),
    extraValuePlaceholder: "placeholder='example'",
  },
  elementClassName: {
    label: i18n.t('projectSettings.selectorsSettings.elementClassName.label', 'class name'),
    extraValuePlaceholder: "class='example'",
  },
  elementText: {
    label: i18n.t('projectSettings.selectorsSettings.elementText.label', 'Text content'),
    extraValuePlaceholder: 'HTML element.textContent',
  },
  elementFullXPath: {
    label: i18n.t('projectSettings.selectorsSettings.elementFullXPath.label', 'XPath'),
    extraValuePlaceholder: 'element full XPath',
  },
  elementCustomAttributes: {
    label: i18n.t(
      'projectSettings.selectorsSettings.elementCustomAttributes.label',
      'Custom attributes',
    ),
    tooltip: i18n.t(
      'projectSettings.selectorsSettings.elementCustomAttributes.tooltip',
      'List of extraValue and their values, separated by comma. For example data-test="value", data-bugbug="value"',
    ),
    isExtraValueEditable: true,
    extraValuePlaceholder: 'data-testid, data-test',
  },
};
