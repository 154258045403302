import api from './common';

export const getList = (params) => api.get(`/tests/`, { params });

export const get = (id, params) => api.get(`/tests/${id}/`, { params });

export const create = (data) => api.post('/tests/', data);

export const remove = (id) => api.delete(`/tests/${id}/`);

export const removeSelected = (data) => api.delete('/tests/delete/', { data });

export const update = (id, data) => api.patch(`/tests/${id}/`, data);

export const clone = (id) => api.post(`/tests/${id}/clone/`);

export const stop = (id, data) => api.post(`/tests/${id}/stop-running/`, data);

export const removeGroupRelation = (id, groupId, data) =>
  api.delete(`/tests/${id}/group-relations/${groupId}/`, { data });

export const insertGroup = (id, data) => api.post(`/tests/${id}/insert-group/`, data);

// Extension
export const startRecording = (id, data) => api.post(`/tests/${id}/start-recording/`, data);

export const stopRecording = (id, data) => api.post(`/tests/${id}/stop-recording/`, data);

export const debugRunNextStep = (id, data) => api.post(`/tests/${id}/debug/run-next-step/`, data);

export const debugPauseTest = (id, data) => api.post(`/tests/${id}/debug/pause/`, data);

export const debugResumeTest = (id, data) => api.post(`/tests/${id}/debug/resume/`, data);
//

export const runSelected = (data) => api.post('/tests/run-selected/', data); // ?

export const cleanSteps = (id) => api.delete(`/tests/${id}/clean-steps/`);

export const updateStepsPosition = (id, data) =>
  api.post(`/tests/${id}/update-steps-position/`, data);

export const removeSteps = (data) => api.delete(`/steps/delete/`, { data });

export const removePartials = (id, data) => api.post(`/tests/${id}/partial-delete/`, data);

export const updateStepSettings = (id, groupId, data) =>
  api.put(`/groups/${groupId}/steps/${id}/`, data);

export const updateStep = (id, groupId, data) => api.patch(`/groups/${groupId}/steps/${id}/`, data);

export const cloneStep = (id, groupId) => api.post(`/groups/${groupId}/steps/${id}/clone/`);

/**
 * @deprecated For new features use `useUnlinkComponentMutation` instead
 */
export const unlinkComponent = (id, data) => api.post(`/tests/${id}/unlink-component/`, data);

export const updateGroupsOrder = (id, data) =>
  api.post(`/tests/${id}/update-groups-position/`, data);
