import * as T from '@bugbug/core/utils/toolbox';
import { useCallback } from 'react';
import { useUnmount, useUpdateEffect } from 'react-use';

import type { Step } from '@bugbug/core/types/steps';
import type { Test, TestRun } from '@bugbug/core/types/tests';
import type { SideEffect } from '@bugbug/core/types/utils';
import EmptySidebar from '~/components/EmptySidebar';
import { StepsActions } from '~/modules/steps/steps.redux';
import { selectCurrentStepId } from '~/modules/steps/steps.selectors';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { selectSingleTest } from '~/modules/test/test.selectors';

import { StepDetails } from '../StepDetails/StepDetails';

import * as S from './TestSidebar.styled';
import TestSidebarNotes from './TestSidebarNotes';

interface TestSidebarProps {
  context?: 'component' | 'test' | 'testRun';
  readOnly?: boolean;
  onToggle?: SideEffect<boolean | undefined>;
  collapsed?: boolean;
  testRunId?: TestRun['id'];
}

const TestSidebar = ({
  context = 'test',
  readOnly,
  onToggle = T.noop,
  collapsed = false,
  testRunId,
}: TestSidebarProps) => {
  const currentTest = useAppSelector(selectSingleTest) as Test;
  const currentStepId = useAppSelector(selectCurrentStepId) as Step['id'];
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(StepsActions.resetCurrentStepId());
  }, [dispatch]);

  const handleToggle = () => onToggle(undefined);

  useUpdateEffect(() => {
    if (currentStepId) {
      onToggle(false);
    }
  }, [currentStepId]);

  useUnmount(() => {
    handleClose();
  });

  if (currentStepId) {
    return (
      <S.Container>
        <StepDetails
          context={context}
          stepId={currentStepId}
          testRunId={testRunId}
          onClose={handleClose}
          readOnly={readOnly}
        />
      </S.Container>
    );
  }

  return (
    <S.CollapsibleContainer collapsed={collapsed}>
      <S.ExpandButton onClick={handleToggle} />
      <S.Container>
        <S.CollapseButton onClick={handleToggle} />

        {!!currentTest.id && <TestSidebarNotes />}
        <EmptySidebar />
      </S.Container>
    </S.CollapsibleContainer>
  );
};

export default TestSidebar;
