import Checkbox from '@bugbug/core/components/Checkbox';
import Icon from '@bugbug/core/components/Icon';
import IconButton from '@bugbug/core/components/IconButton';
import { memo, useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import { ExpanderButton } from '~/components/ExpanderButton/ExpanderButton';
import useActionState from '~/hooks/useActionState';
import { useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectCheckedSteps } from '~/modules/uiState/uiState.selectors';

import GroupMetaData from '../GroupMetaData';
import { PlaybackCursorSlot } from '../PlaybackCursor/PlaybackCursor';
import ToggleComponent from '../ToggleComponent';

import {
  GroupHeader,
  GroupNameInput,
  GroupActions,
  ExpanderContainer,
  SelectionContainer,
  DragHandler,
  GroupNameContainer,
  GroupNameOverlay,
  GroupMetaWrapper,
  IntersectionDetection,
} from './StepsGroup.styled';

export const StepsGroupHeader = memo((props) => {
  const {
    testId,
    group,
    readOnly,
    dragHandleProps,
    expanded,
    onSelectionChange,
    onExpandClick,
    runResultEnabled,
    onEnter,
    onLeave,
  } = props;

  const { t } = useTranslation();
  const animationFrameRequestId = useRef();
  const dispatch = useDispatch();
  const headerRef = useRef();
  const detectorRef = useRef();
  const fieldRef = useRef();
  const checkboxRef = useRef();
  const pinObserver = useRef();
  const expandedRef = useRef();
  const { isComponent, isNameFocused } = group;
  const [isPinned, setIsPinned] = useState(false);
  const [isNameEditable, setIsNameEditable] = useState(false);
  const checkedSteps = useAppSelector(selectCheckedSteps);
  const checkedStepsWithinGroup = useMemo(
    () => group.steps.filter((stepId) => checkedSteps.some((step) => step.id === stepId)),
    [checkedSteps, group.steps],
  );
  const hasCheckedWholeGroup = group.steps.length === checkedStepsWithinGroup.length;

  const { errors } = useActionState(TestActions.renameGroupRequest, {
    reqId: group.id,
  });

  expandedRef.current = expanded;
  const groupName = group.name || t('default.group.name');

  const focusNameField = useCallback((event) => {
    event?.stopPropagation();
    setIsNameEditable(true);

    animationFrameRequestId.current = requestAnimationFrame(() => {
      fieldRef.current?.input.focus();
      fieldRef.current?.input.select();
    });
  }, []);

  const handleFieldBlur = useCallback(() => {
    setIsNameEditable(false);
  }, []);

  useMount(() => {
    if (!isComponent && isNameFocused) {
      focusNameField();
    }

    pinObserver.current = new IntersectionObserver(
      ([entry]) => {
        const isBottomVisible =
          entry.boundingClientRect.bottom < window.innerHeight && entry.boundingClientRect.bottom;

        const newPinnedState =
          entry.intersectionRatio < 1 && expandedRef.current && isBottomVisible;
        headerRef.current?.classList?.toggle('is-pinned', newPinnedState);
        setIsPinned(newPinnedState);
      },
      { threshold: [1] },
    );

    pinObserver.current.observe(detectorRef.current);
  });

  useUnmount(() => {
    cancelAnimationFrame(animationFrameRequestId.current);
    pinObserver.current.disconnect();
  });

  useUpdateEffect(() => {
    setIsNameEditable(false);
  }, [groupName]);

  useEffect(() => {
    if (!expanded) {
      setIsPinned(false);
      pinObserver.current?.classList?.remove('is-pinned');
    }
  }, [expanded]);

  useEffect(() => {
    if (errors?.name) {
      fieldRef.current.setError(errors.name);
      focusNameField();
    }
  }, [errors, focusNameField]);

  const handleNameChange = useCallback(
    (newName) => {
      dispatch(TestActions.renameGroupRequest(group.id, newName, { reqId: group.id }));
    },
    [dispatch, group.id],
  );

  const handleSelectionChange = useCallback(
    (event) => {
      onSelectionChange(event.target.checked);
    },
    [onSelectionChange],
  );

  const onGroupDelete = useCallback(() => {
    onSelectionChange(false);
  }, [onSelectionChange]);

  return (
    <>
      <IntersectionDetection ref={detectorRef} />
      <GroupHeader
        ref={headerRef}
        data-testid="GroupHeader"
        id={group.id}
        onMouseEnter={readOnly || isPinned ? null : onEnter}
        onMouseLeave={readOnly || isPinned ? null : onLeave}
        onClick={onExpandClick}
      >
        {!runResultEnabled && (
          <>
            <DragHandler dragHandleProps={dragHandleProps} />
            <SelectionContainer>
              <Checkbox
                ref={checkboxRef}
                data-testid="GroupSelectionCell"
                onChange={handleSelectionChange}
                disabled={readOnly}
                indeterminate={checkedStepsWithinGroup.length > 0 && !hasCheckedWholeGroup}
                checked={hasCheckedWholeGroup}
                small
              />
            </SelectionContainer>
          </>
        )}
        <ExpanderContainer>
          <ExpanderButton active={expanded} onClick={onExpandClick} />
        </ExpanderContainer>
        <GroupNameContainer>
          <GroupNameOverlay hidden={isNameEditable}>
            <div>{groupName}</div>
            {!runResultEnabled && (
              <IconButton onClick={focusNameField}>
                <Icon name="edit" />
              </IconButton>
            )}
          </GroupNameOverlay>
          <GroupNameInput
            ref={fieldRef}
            type="text"
            name="value"
            value={groupName}
            onChange={handleNameChange}
            readOnly={readOnly}
            hidden={!isNameEditable}
            onBlur={handleFieldBlur}
          />
        </GroupNameContainer>
        <GroupMetaWrapper>
          <GroupMetaData group={group} compact={expanded} runResultEnabled={runResultEnabled} />
          <ToggleComponent testId={testId} group={group} readOnly={readOnly} />
        </GroupMetaWrapper>
        <GroupActions
          group={group}
          readOnly={readOnly}
          onDelete={onGroupDelete}
          onRename={focusNameField}
        />
        <PlaybackCursorSlot groupId={group.id} afterStepId={null} />
      </GroupHeader>
    </>
  );
});

StepsGroupHeader.displayName = 'StepsGroupHeader';
