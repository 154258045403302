import * as T from '@bugbug/core/utils/toolbox';
import produce from 'immer';
import { normalize } from 'normalizr';
import { pick } from 'ramda';
import { createActions, createReducer } from 'reduxsauce';

import testSchema from '~/modules/test/test.schema';

import { projectSchema, projectsListSchema } from './project.schema';

export const { Types: ProjectTypes, Creators: ProjectActions } = createActions(
  {
    addRequest: ['data'],
    addFailure: ['error'],
    addSuccess: ['data'],
    cloneRequest: ['id'],
    cloneFailure: ['error'],
    cloneSuccess: ['id', 'clonedProject'],
    cleanClipboard: ['slug'],
    cleanClipboardFailure: ['error'],
    cleanClipboardSuccess: ['data'],
    clipboardNewStepsSet: ['data'],
    getClipboard: [],
    getClipboardFailure: ['error'],
    getClipboardSuccess: ['data'],
    getListRequest: [],
    getListSuccess: ['data'],
    getListFailure: ['error'],
    getSingleFailure: ['error'],
    getSingleRequest: ['id'],
    getSingleSuccess: ['project'],
    getSingleReset: [],
    removeRequest: ['id'],
    removeClipboardGroup: ['slug'],
    removeClipboardGroupFailure: ['error'],
    removeClipboardGroupSuccess: ['id'],
    removeClipboardSteps: ['data'],
    removeFailure: ['error'],
    removeSuccess: ['id'],
    reset: [],
    updateRequest: ['id', 'data'],
    updateFailure: ['error'],
    updateSuccess: ['payload'],
    updatePartialFailure: ['error'],
    updatePartialRequest: ['id', 'data'],
    updatePartialSuccess: ['data'],
    updated: ['data'],
    updateSettingsRequest: ['payload'],
    updateSettingsSuccess: ['payload'],
    updateSettingsFailure: ['error'],
    updateGeneralSettingsRequest: ['name', 'settings'],
    updateGeneralSettingsSuccess: ['name', 'settings'],
    updateGeneralSettingsFailure: ['error'],
    updateSelectorsSettingsRequest: ['settings'],
    updateSelectorsSettingsSuccess: ['settings'],
    updateSelectorsSettingsFailure: ['error'],
    updateWindowAndBrowserSettingsRequest: ['settings'],
    updateWindowAndBrowserSettingsSuccess: ['settings'],
    updateWindowAndBrowserSettingsFailure: ['error'],
    updateWaitingConditionsSettingsRequest: ['settings'],
    updateWaitingConditionsSettingsSuccess: ['settings'],
    updateWaitingConditionsSettingsFailure: ['error'],
    //
    cloned: ['data'],
  },
  { prefix: 'PROJECT/' },
);

export const INITIAL_STATE = {
  single: {},
  list: {
    projects: {},
    order: [],
  },
  clipboard: {},
};

const getListSuccess = (state, action) =>
  produce(state, (draftState) => {
    const data = normalize(action.data.results, projectsListSchema);
    draftState.list.projects = data.entities.projects;
    draftState.list.order = data.result;
  });
const getSingleReset = (state) =>
  produce(state, (draftState) => {
    draftState.single = {};
  });
const getSingleSuccess = (state, { project }) =>
  produce(state, (draftState) => {
    const data = normalize(project, projectSchema);
    draftState.single = data.entities;
    draftState.single.id = data.result;
  });

const removeSuccess = (state, action) =>
  produce(state, (draftState) => {
    draftState.list.projects = T.omit(action.id, draftState.list.projects);
    draftState.list.order = T.pull(action.id, draftState.list.order);
  });

const updateSettingsSuccess = (state, action) =>
  produce(state, (draftState) => {
    const { payload } = action;
    if (draftState.single.id) {
      draftState.single.projects[draftState.single.id].settings = {
        ...state.single.projects[draftState.single.id].settings,
        ...payload,
      };
    }
  });

const updateWindowsAndBrowserSettingsSuccess = (state, { settings }) =>
  produce(state, (draftState) => {
    if (draftState.single.id) {
      draftState.single.projects[draftState.single.id].settings = {
        ...state.single.projects[draftState.single.id].settings,
        ...settings,
      };
    }
  });

const updateSelectorsSettingsSuccess = (state, { settings: { selectorMethods } }) =>
  produce(state, (draftState) => {
    if (draftState.single.id) {
      draftState.single.projects[draftState.single.id].settings.selectorMethods = selectorMethods;
    }
  });

const updateWaitingConditionsSettingsSuccess = (state, { settings: { waitingConditions } }) =>
  produce(state, (draftState) => {
    if (draftState.single.id) {
      draftState.single.projects[draftState.single.id].settings.waitingConditions =
        waitingConditions;
    }
  });

const updateGeneralSettingsSuccess = (state, { name, settings }) =>
  produce(state, (draftState) => {
    if (draftState.single.id) {
      draftState.single.projects[draftState.single.id].name = name;
      draftState.single.projects[draftState.single.id].settings = {
        ...state.single.projects[draftState.single.id].settings,
        ...settings,
      };
    }
  });

const updated = (state, action) =>
  produce(state, (draftState) => {
    const { data } = action;
    if (draftState.single.id) {
      draftState.single.projects[draftState.single.id] = data;
    }
  });

const updateSuccess = (state, action) =>
  produce(state, (draftState) => {
    const data = normalize(action.payload, projectSchema);
    const projectData = data.entities.projects[action.payload.id];
    if (draftState.single.id && draftState.single.id === action.payload.id) {
      draftState.single.projects[action.payload.id] = projectData;
    }
    draftState.list.projects[action.payload.id] = projectData;
  });

const removeClipboardSteps = (state, action) =>
  produce(state, (draftState) => {
    const steps = action.data;
    steps.forEach((step) => {
      draftState.clipboard.steps = T.omit(step.id, draftState.clipboard.steps);
      draftState.clipboard.groups[step.groupId].steps = T.pull(
        step.id,
        draftState.clipboard.groups[step.groupId].steps,
      );
    });
  });

const getClipboardSuccess = (state, action) =>
  produce(state, (draftState) => {
    const data = normalize(action.data, testSchema);
    draftState.clipboard = data.entities;
    draftState.clipboard.id = data.result;
  });

const clipboardNewStepsSet = (state, action) =>
  produce(state, (draftState) => {
    const data = normalize(action.data, testSchema);
    draftState.clipboard = data.entities;
    draftState.clipboard.id = data.result;
  });

const cleanClipboardSuccess = (state, action) =>
  produce(state, (draftState) => {
    const data = normalize(action.data, testSchema);
    draftState.clipboard = data.entities;
    draftState.clipboard.id = data.result;
  });

const removeClipboardGroupSuccess = (state, { id }) =>
  produce(state, (draftState) => {
    const testId = draftState.clipboard.id;
    draftState.clipboard.groups = T.unset(id, draftState.clipboard.groups);
    draftState.clipboard.tests[testId].groups = T.pull(
      id,
      draftState.clipboard.tests[testId].groups,
    );
  });

const cloneSuccess = (state, { id, clonedProject }) =>
  produce(state, (draftState) => {
    const data = normalize(clonedProject, projectSchema);
    const projectData = data.entities.projects[clonedProject.id];
    draftState.list.projects[clonedProject.id] = {
      ...draftState.list.projects[id],
      ...pick(['id', 'slug', 'name'], projectData),
      isDraft: true,
      isActive: true,
    };
    draftState.list.order = [clonedProject.id, ...draftState.list.order];
  });

const cloned = (state, { data }) =>
  produce(state, (draftState) => {
    if (draftState.list.projects[data.projectId]) {
      draftState.list.projects[data.projectId].isDraft = false;
      draftState.list.projects[data.projectId].isActive = true;
    }
  });

export const reducer = createReducer(INITIAL_STATE, {
  [ProjectTypes.CLEAN_CLIPBOARD_SUCCESS]: cleanClipboardSuccess,
  [ProjectTypes.CLIPBOARD_NEW_STEPS_SET]: clipboardNewStepsSet,
  [ProjectTypes.GET_CLIPBOARD_SUCCESS]: getClipboardSuccess,
  [ProjectTypes.GET_LIST_SUCCESS]: getListSuccess,
  [ProjectTypes.GET_SINGLE_RESET]: getSingleReset,
  [ProjectTypes.GET_SINGLE_SUCCESS]: getSingleSuccess,
  [ProjectTypes.REMOVE_CLIPBOARD_GROUP_SUCCESS]: removeClipboardGroupSuccess,
  [ProjectTypes.REMOVE_CLIPBOARD_STEPS]: removeClipboardSteps,
  [ProjectTypes.REMOVE_SUCCESS]: removeSuccess,
  [ProjectTypes.UPDATED]: updated,
  [ProjectTypes.UPDATE_SUCCESS]: updateSuccess,
  [ProjectTypes.UPDATE_SETTINGS_SUCCESS]: updateSettingsSuccess,
  [ProjectTypes.UPDATE_GENERAL_SETTINGS_SUCCESS]: updateGeneralSettingsSuccess,
  [ProjectTypes.UPDATE_WINDOW_AND_BROWSER_SETTINGS_SUCCESS]: updateWindowsAndBrowserSettingsSuccess,
  [ProjectTypes.UPDATE_SELECTORS_SETTINGS_SUCCESS]: updateSelectorsSettingsSuccess,
  [ProjectTypes.UPDATE_WAITING_CONDITIONS_SETTINGS_SUCCESS]: updateWaitingConditionsSettingsSuccess,
  [ProjectTypes.CLONE_SUCCESS]: cloneSuccess,
  //
  [ProjectTypes.CLONED]: cloned,
});
