import { createApi } from '@reduxjs/toolkit/query/react';

import type { getAnalyticsCookiesData } from '~/utils/cookies';

import type { URLString } from '@bugbug/core/types/aliases';
import type { UserFlags } from '@bugbug/core/types/users';
import type { Maybe } from '@bugbug/core/types/utils';
import { axiosBaseQuery } from '~/services/api/baseQuery';
import urls from '~/views/urls';

import config from '../config';

const TAG = 'User' as const;
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/auth' }),
  tagTypes: [TAG],

  endpoints: (builder) => ({
    register: builder.mutation<
      {
        email: string;
        firstName: string;
        lastName: string;
        token: string;
        username: string;
        pk: string;
      },
      {
        email: string;
        password1: string;
        isAllowedEmailMarketing: boolean;
        key?: string;
        analytics: ReturnType<typeof getAnalyticsCookiesData>;
      }
    >({
      query: ({ email, password1, ...payload }) => ({
        url: '/registration/',
        method: 'POST',
        data: {
          ...payload,
          email,
          password1,
          password2: password1,
          username: email,
        },
      }),
    }),

    setUserFlags: builder.mutation<
      void,
      Partial<Pick<UserFlags, 'expiringTrialModalShown' | 'docsTooltipAfterFailShown'>>
    >({
      query: (data) => ({
        url: '/user/flags/set/',
        method: 'PATCH',
        data,
      }),
    }),

    onboardingRedirect: builder.mutation<{ url: URLString }, void>({
      query: () => ({
        url: '/user/onboarding/redirect/',
        method: 'GET',
      }),
      transformResponse: ({ url }: { url: Maybe<URLString> }) => ({
        url: url ? url.replace(config.WEBAPP_URL, '') : urls.organizations,
      }),
    }),
  }),
});

export const { useRegisterMutation, useSetUserFlagsMutation, useOnboardingRedirectMutation } =
  userApi;
