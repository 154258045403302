import EmptyState from '@bugbug/core/components/EmptyState';
import { useTranslation } from 'react-i18next';

import useUpdateQueryString from '~/hooks/useUpdateQueryString';

interface EmptySearchStateProps {
  collection: string;
}

export const EmptySearchState = ({ collection }: EmptySearchStateProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'emptySearchState' });
  const updateQuerystring = useUpdateQueryString();

  return (
    <EmptyState
      isVisible
      inline
      text={t('description', 'No {{ collection }} were found that match your search criteria.', {
        collection,
      })}
      action={{
        text: t('clearSearch', 'Clear search'),
        onClick: () => {
          updateQuerystring({ query: '' });
        },
      }}
    />
  );
};
