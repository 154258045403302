export const camelToConstantCase = (str: string): string =>
  str
    .replace(/([A-Z])/g, '_$1')
    .replace(/^_/, '')
    .toUpperCase();

export const decodeHTML = (html: string): string => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  const decodedValue = txt.value;
  txt.remove();

  return decodedValue;
};

// Useful to track differences in strings
export const inspectHiddenChars = (str1: string, str2: string) => {
  // eslint-disable-next-line no-console
  console.log(
    'String 1 (hex):',
    str1.split('').map((c) => c.charCodeAt(0).toString(16)),
  );
  // eslint-disable-next-line no-console
  console.log(
    'String 2 (hex):',
    str2.split('').map((c) => c.charCodeAt(0).toString(16)),
  );
};
