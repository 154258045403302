import { useTranslation } from 'react-i18next';

import type { Test, TestRun } from '@bugbug/core/types/tests';
import useTestRunner from '~/hooks/useTestRunner';
import { useIsNewPlaybackSupported } from '~/modules/test/test.hooks';

import { useTestRunnerActionsState } from './TestHeaderActions.hooks';
import * as S from './TestHeaderActions.styled';

interface RunningButtonsProps {
  testId: Test['id'];
  testRunId?: TestRun['id'];
}

export const RunningButtons = ({ testId, testRunId }: RunningButtonsProps) => {
  const { t } = useTranslation();
  const testRunner = useTestRunner({ id: testId }, { testRunId });
  const isNewPlaybackSupported = useIsNewPlaybackSupported();
  const runnerActionsState = useTestRunnerActionsState();

  const handleStop = async () => {
    if (isNewPlaybackSupported) {
      testRunner.stopWithConfirmation();
    } else {
      testRunner.stop();
    }
  };

  const handlePause = async () => {
    try {
      runnerActionsState.setPendingAction('pause');
      await testRunner.pause();
    } catch (error) {
      console.error('Error while pausing', error);
      runnerActionsState.resetPendingAction();
    }
  };

  const handleRunNextStep = async () => {
    try {
      runnerActionsState.setPendingAction('runNextStep');
      await testRunner.runNextStep();
    } catch (error) {
      console.error('Error while running next step', error);
      runnerActionsState.resetPendingAction();
    }
  };

  return (
    <S.CombinedButtonsContainer>
      <S.CombinedButton
        {...runnerActionsState.state.stop}
        onClick={handleStop}
        iconName="stop"
        roundCorners="left"
      >
        {t('testDetails.testActions.stopButton', 'Stop')}
      </S.CombinedButton>
      <S.CombinedButtonsSeparator disabled={runnerActionsState.isLoading} />
      <S.RecordButton
        {...runnerActionsState.state.pause}
        disabled={runnerActionsState.state.pause.disabled || testRunner.isCloudRun}
        onClick={handlePause}
        iconName="pause"
        roundCorners={isNewPlaybackSupported ? 'right' : undefined}
      >
        {t('testDetails.testActions.pauseButton', 'Pause')}
      </S.RecordButton>
      {!isNewPlaybackSupported && (
        <>
          <S.CombinedButtonsSeparator disabled={runnerActionsState.isLoading} />
          <S.CombinedButton
            {...runnerActionsState.state.runNextStep}
            disabled={runnerActionsState.state.runNextStep.disabled || !testRunner.isDebugging}
            onClick={handleRunNextStep}
            iconName="next"
            roundCorners="right"
          >
            {t('testDetails.testActions.nextStepButton', 'Next step')}
          </S.CombinedButton>
        </>
      )}
    </S.CombinedButtonsContainer>
  );
};
