import * as T from '@bugbug/core/utils/toolbox';
import memoize from 'lodash.memoize';
import { filter, mapObjIndexed, prop, propOr, values } from 'ramda';
import { createSelector } from 'reselect';

import type { RootState } from '../store';

import type {
  Organization,
  OrganizationSubscription,
  UserCurrentRole,
} from '@bugbug/core/types/organizations';
import type { PlanPeriod, PlanType } from '@bugbug/core/types/subscriptions';
import { SUBSCRIPTION_PERIOD, SUBSCRIPTION_TYPE } from '~/constants/subscription';
import { USER_ROLE } from '~/modules/constans';

import { formatPriceData } from './organization.helpers';

export const selectOrganizationDomain = (state: RootState) => state.organization;

export const selectCurrentOrganizationId = createSelector(
  selectOrganizationDomain,
  (state) => state.currentOrganizationId,
);

export const selectOrganizations = createSelector(
  selectOrganizationDomain,
  (state) => state.organizations,
);

export const selectOrganizationsList = createSelector(selectOrganizations, (organizations) =>
  values(organizations),
);

export const selectCurrentOrganization = createSelector(
  selectCurrentOrganizationId,
  selectOrganizations,
  (organizationId, organizations) => organizations[organizationId]!,
);

export const selectRoleInCurrentOrganization = createSelector(
  selectCurrentOrganization,
  (organization: Organization) => organization.currentUserRole,
);

export const selectIsOrganizationAdmin = createSelector(
  selectRoleInCurrentOrganization,
  (currentUserRole) =>
    ([USER_ROLE.ADMIN, USER_ROLE.IMPERSONATE] as UserCurrentRole[]).includes(currentUserRole),
);

export const selectIsOrganizationCreator = memoize((userId) =>
  createSelector(selectCurrentOrganization, T.propEq('createdBy', userId)),
);

export const selectInvoices = createSelector(
  selectOrganizationDomain,
  T.pathOr([], ['invoices', 'results']),
);

export const selectSubscription = createSelector(selectCurrentOrganization, (organization) =>
  organization ? formatPriceData<OrganizationSubscription>(organization.subscription) : null,
);

export const selectSubscriptions = createSelector(
  selectOrganizationDomain,
  T.pipe(
    T.pathOr({}, ['subscriptionsList', 'subscriptions']),
    T.mapValues<OrganizationSubscription, OrganizationSubscription>(formatPriceData),
  ),
);

export const selectSubscriptionByTypeAndPeriod = memoize(
  (type: PlanType, period: PlanPeriod) =>
    createSelector(selectSubscriptions, (subscriptions) =>
      Object.values<OrganizationSubscription>(subscriptions).find(
        (subscription) => subscription.planType === type && subscription.planPeriod === period,
      ),
    ),
  T.joinAllArgs,
);

export const selectSubscriptionsOrder = createSelector(
  selectOrganizationDomain,
  T.pathOr([], ['subscriptionsList', 'order']),
);

export const selectSubscriptionsList = createSelector(
  selectSubscriptions,
  selectSubscriptionsOrder,
  (subscriptions, order) => order.map((plan) => subscriptions[plan]),
);

export const selectMonthlySubscriptions = createSelector(
  selectSubscriptionsList,
  filter(({ plan }) => !plan.includes(SUBSCRIPTION_PERIOD.YEARLY)),
);

export const selectYearlySubscriptions = createSelector(
  selectSubscriptionsList,
  filter(({ plan }) => !plan.includes(SUBSCRIPTION_PERIOD.MONTHLY)),
);

export const selectScheduledSubscription = createSelector(
  selectCurrentOrganization,
  (organization: Organization) =>
    organization.subscriptionSchedule ? formatPriceData(organization.subscriptionSchedule) : null,
);

export const selectHasPendingSubscriptionChange = createSelector(
  selectScheduledSubscription,
  Boolean,
);

export const selectActivePaymentMethod = createSelector(selectSubscription, (subscription) => {
  if (!subscription?.defaultPaymentMethod) {
    return {};
  }
  return {
    id: subscription.defaultPaymentMethod.id,
    ...subscription.defaultPaymentMethod.card,
    brand: subscription.defaultPaymentMethod.card.brand.toUpperCase(),
  };
});

export const selectPlanUsage = createSelector(selectSubscription, (subscription) =>
  !subscription || subscription.usage === null ? null : mapObjIndexed(Number, subscription.usage),
);

export const selectPlanLimits = createSelector(selectSubscription, (subscription) =>
  subscription ? mapObjIndexed(Number, subscription.limits) : null,
);

export const selectIsProjectsLimitReached = createSelector(
  selectPlanUsage,
  selectPlanLimits,
  (usage, limits) =>
    usage !== null && limits !== null && limits.projectLimit === usage.projectLimit,
);

export const selectIsCloudRunsLimitReached = createSelector(
  selectPlanUsage,
  selectPlanLimits,
  (usage, limits) =>
    usage !== null && limits !== null && limits.testRunLimit === usage.testRunLimit,
);

export const selectIsTrialEnded = createSelector(selectCurrentOrganization, prop('wasTrialUsed'));

export const selectIsTrialAvailable = createSelector(
  selectSubscription,
  selectIsTrialEnded,
  (subscription, wasTrialUsed) =>
    !wasTrialUsed && subscription?.planType === SUBSCRIPTION_TYPE.FREE,
);

export const selectIsTrialActive = createSelector(selectSubscription, (s) => s?.isTrial ?? false);

export const selectIsFreePlan = createSelector(selectSubscription, propOr(false, 'isFreePlan'));

// @ts-expect-error missing types
export const selectParallelTestsLimit = createSelector(
  selectPlanLimits,
  prop('parallelTestsLimit'),
);
