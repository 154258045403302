import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import type { InviteUsersDto } from '~/modules/organization/organization.schemas';

import { selectRoleInCurrentOrganization } from '~/modules/organization/organization.selectors';
import { useAppSelector } from '~/modules/store';

import { InviteUserRow } from './InviteUserRow';
import * as S from './InviteUsersRowList.styled';

export const InviteUsersRowsList = () => {
  const formik = useFormikContext<InviteUsersDto>();
  const currentUserRole = useAppSelector(selectRoleInCurrentOrganization);

  const { values } = formik;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'inviteUserModal',
  });

  return (
    <S.RowsList>
      <FieldArray
        name="users"
        render={(arrayHelpers) => (
          <>
            {values.users?.map((_, index) => (
              <InviteUserRow
                // eslint-disable-next-line react/no-array-index-key
                key={`invite-user-row-${index}`}
                index={index}
                onRemove={arrayHelpers.remove}
              />
            ))}
            <S.AddRowButton
              type="button"
              iconName="add"
              onClick={() =>
                arrayHelpers.push({
                  email: '',
                  role: currentUserRole,
                })
              }
            >
              {t('addAnotherButton.label', 'Add another')}
            </S.AddRowButton>
          </>
        )}
      />
    </S.RowsList>
  );
};
