import Button from '@bugbug/core/components/Button';
import styled from 'styled-components';

import {
  OnboardingBox,
  OnboardingTitle,
  OnboardingCard,
} from '~/views/Onboarding/Onboarding.styled';

import ArrowDownSVG from '~/images/arrow-down-icon.svg?react';

export const EmptyStateBox = styled(OnboardingBox)`
  margin: 90px auto;
`;

export const EmptyStateCard = styled(OnboardingCard)`
  box-shadow: none;
`;

export const EmptyStateTitle = styled(OnboardingTitle)`
  margin-bottom: 4px;
`;

export const ArrowDownIcon = styled(ArrowDownSVG)`
  margin: 0 auto 12px auto;
  display: block;
`;

export const CreateTestButton = styled(Button)`
  margin: 0 auto;
`;
