import Button from '@bugbug/core/components/Button';
import mixins from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

export const Header = styled.div`
  ${mixins.layout.flexRowMiddle};
  justify-content: flex-end;
  margin-top: -40px;
  margin-bottom: 12px;
  width: 100%;
`;

export const ThenFindButton = styled(Button).attrs(() => ({
  iconName: 'add',
  variant: 'default',
}))`
  flex: none;
  width: min-content;
  margin-top: 4px;
  margin-left: -3px;
  padding: 8px;
  height: 30px;

  span {
    margin-right: 7px;
  }
`;

export const ClearButton = styled(Button).attrs(() => ({
  variant: 'default',
}))`
  flex: none;
  width: min-content;
  padding: 8px;
  height: 28px;

  > div {
    margin-bottom: 0;
  }

  span {
    margin-right: 7px;
  }
`;

export const DragHandle = styled.div`
  cursor: grab;
  margin-right: 8px;
  position: absolute;
  left: 3px;
  top: 6px;
  height: 22px;
  overflow: hidden;
`;
