import { Portal } from '@ariakit/react';
import Icon from '@bugbug/core/components/Icon';
import { useTranslation } from 'react-i18next';

import useModal from '~/hooks/useModal';

import * as S from './TutorialVideo.styled';

export const TutorialVideo = () => {
  const modal = useModal();
  const { t } = useTranslation();

  return (
    <Portal>
      <S.VideoContainer>
        <S.CloseVideoButton onClick={modal.hide}>
          <Icon name="close" />
        </S.CloseVideoButton>
        <S.VideoFrame
          src={t('default.docs.tutorialVideo')}
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="BugBug Product Demo"
        />
        <script src="https://player.vimeo.com/api/player.js" />
      </S.VideoContainer>
    </Portal>
  );
};
