import { CommonActions } from '@bugbug/core/actions/actions';
import { all, delay, put, takeLatest } from 'redux-saga/effects';

import { EDIT_AND_REWIND_HIGHLIGHT_DURATION_MS } from '~/views/TestDetails/components/TestContent/TestContent.constants';

import { UIStateActions } from './uiState.redux';

export function* highlightEditAndRewindMode() {
  yield put(UIStateActions.setHighlightEditAndRewindMode({ highlight: true }));
  yield delay(EDIT_AND_REWIND_HIGHLIGHT_DURATION_MS);
  yield put(UIStateActions.setHighlightEditAndRewindMode({ highlight: false }));
}

export function* uiSagas() {
  yield all([
    yield takeLatest(CommonActions.highlightEditAndRewindMode.type, highlightEditAndRewindMode),
  ]);
}
