import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { FormikProvider, useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import type { ScheduleFormValues } from './EditScheduleModal.types';

import type { Profile } from '@bugbug/core/types/profiles';
import type { Project } from '@bugbug/core/types/projects';
import type { RouteModalWithOptional } from '@bugbug/core/types/routes';
import type { Schedule } from '@bugbug/core/types/schedules';
import * as Modal from '~/components/modals/Modal';
import { PaidFeature } from '~/components/PaidFeatureGuard';
import useModal from '~/hooks/useModal';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import { selectProfile } from '~/modules/profile/profile.selectors';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { useCreateOrUpdateScheduleMutation } from '~/modules/schedules/schedules.api';
import { useAppSelector } from '~/modules/store';
import analytics from '~/services/analytics';
import { getExtractedErrorMessages, isInternalServerError } from '~/services/api/utils';
import toasts from '~/services/toasts';

import { getScheduleFormValues, getScheduleRequestData } from './EditScheduleModal.helpers';
import { ScheduleSchema } from './EditScheduleModal.schema';
import * as S from './EditScheduleModal.styled';
import ScheduleSettings from './ScheduleSettings';
import ScheduleSuitesList from './ScheduleSuitesList';

const EditScheduleModal: RouteModalWithOptional<Schedule> = ({ data: schedule }) => {
  const { t } = useTranslation();
  const modal = useModal();
  // TODO: remove casting after selectors migrated to TS
  const project = useAppSelector(selectSingleProject) as Project;
  const updateQuerystring = useUpdateQueryString();
  const currentProfile = useAppSelector(
    selectProfile(schedule?.runProfileId),
  ) as unknown as Profile; // TODO: migrate profile.selectors to TS

  const [createOrDeleteSchedule, { isError, isSuccess, error }] =
    useCreateOrUpdateScheduleMutation();

  const isEdit = !!schedule?.id;

  const labels = useMemo(() => {
    if (isEdit) {
      return {
        title: t('editScheduleModal.edit.title.edit', 'Schedule settings'),
        submitButton: t('editScheduleModal.edit.submitButton', 'Save schedule'),
      };
    }
    return {
      title: t('editScheduleModal.new.title', 'New schedule'),
      submitButton: t('editScheduleModal.new.submitButton', 'Create schedule'),
    };
  }, [t, isEdit]);

  const initialValues = useMemo<ScheduleFormValues>(
    () => getScheduleFormValues(project, currentProfile, schedule),
    [schedule, currentProfile, project],
  );

  const handleSubmit = useCallback(
    (values: ScheduleFormValues) => {
      if (values.id) {
        analytics.trackEvent('schedule_created');
      }

      const updatedSchedule = getScheduleRequestData(ScheduleSchema.cast(values));
      createOrDeleteSchedule({
        id: values?.id,
        data: updatedSchedule,
      });
    },
    [createOrDeleteSchedule],
  );

  const handleQueryChange = useCallback(
    (event) => {
      updateQuerystring({ query: event.target.value });
    },
    [updateQuerystring],
  );

  const formik = useFormik<ScheduleFormValues>({
    initialValues,
    validationSchema: ScheduleSchema,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    if (isSuccess) {
      toasts.showSuccess({
        content: isEdit
          ? t('editScheduleModal.updateSubmit.success', 'Schedule updated')
          : t('editScheduleModal.createSubmit.success', 'Schedule created'),
      });
      modal.hide();
    }
  }, [isSuccess, modal, t, isEdit]);

  useEffect(() => {
    if (formik.isSubmitting && isError) {
      formik.setErrors(getExtractedErrorMessages(error));
      formik.setSubmitting(false);
    }
  }, [isError, modal, t, isEdit, formik, error]);

  return (
    <FormikProvider value={formik}>
      <S.Form onSubmit={formik.handleSubmit}>
        <Helmet title={t('scheduleDetails.pageTitle', 'Schedules')} />
        <Modal.Header>
          {labels.title}
          <S.SearchInput
            placeholder={t('scheduleDetails.search.placeholder', 'Search suites...')}
            onChange={handleQueryChange}
            aria-label={t('scheduleDetails.search.ariaLabel', 'search suites')}
          />
        </Modal.Header>
        <S.Content>
          <ScheduleSettings />
          <ScheduleSuitesList />
        </S.Content>

        <S.Footer>
          <Modal.ErrorInfo isVisible={isInternalServerError(error)} />
          <Button onClick={modal.hide} disabled={formik.isSubmitting}>
            {t('default.button.cancel')}
          </Button>
          {/*  @ts-expect-error `hide` type issue in case of passing extra redirect route */}
          <PaidFeature feature="schedules" onUpgradeClick={() => modal.hide('subscription')}>
            {(isDisabled) => (
              <ActionButton
                disabled={isDisabled}
                pending={formik.isSubmitting}
                type="submit"
                variant={BUTTON_VARIANT.PRIMARY}
              >
                {labels.submitButton}
              </ActionButton>
            )}
          </PaidFeature>
        </S.Footer>
      </S.Form>
    </FormikProvider>
  );
};

export default EditScheduleModal;
