import InputComponent, { InputBase } from '@bugbug/core/components/Input';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  position: relative;
`;

export const Input = styled(InputComponent)`
  margin-left: 0px;
  background: none;
  border-color: ${COLOR.TRANSPARENT};
  width: 100%;

  &:hover {
    border-color: ${COLOR.GRAY_3};
  }

  &&:not([readonly]):focus-within {
    border-color: ${({ theme }) => theme.colors.text.subtle};
  }

  &[readonly] {
    opacity: 1;
    color: ${COLOR.DARK_GRAY};
    border-color: ${COLOR.TRANSPARENT};
    background-color: ${COLOR.TRANSPARENT};
  }

  ${InputBase} {
    padding: 0;
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  z-index: 3;
  background: ${COLOR.ERROR};
  color: ${COLOR.WHITE};
  font-size: 11px;
  font-weight: ${FONT_WEIGHT.NORMAL};
  padding: 0 10px 2px;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  height: 19px;
  white-space: nowrap;

  &:after {
    content: '';
    height: 4px;
    width: 3px;
    position: absolute;
    top: -2px;
    background: ${COLOR.ERROR};
    left: 0;
  }
`;
