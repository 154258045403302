import EmptyState from '@bugbug/core/components/EmptyState';
import Link from '@bugbug/core/components/Link';
import Loader from '@bugbug/core/components/Loader';
import { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useMount } from 'react-use';

import type { Variable } from '@bugbug/core/types/variables';
import { EmptySearchState } from '~/components/EmptySearchState/EmptySearchState';
import InlineTutorialMessage from '~/components/InlineTutorialMessage';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useQueryString from '~/hooks/useQueryString';
import { ProfileActions } from '~/modules/profile/profile.redux';
import { useAppSelector } from '~/modules/store';
import { VariableActions } from '~/modules/variable/variable.redux';
import { selectCustomVariablesList } from '~/modules/variable/variable.selectors';
import urls from '~/views/urls';

import { LoaderContainer, VariablesList, VariablesSection } from '../../Variables.styled';
import VariableItem from '../VariableItem';
import { CustomVariablesModalRoute } from '../VariablesModalRoute';

const CustomVariablesList = () => {
  const { t } = useTranslation();
  const queryString = useQueryString();
  const dispatch = useDispatch();
  const variables = useAppSelector(selectCustomVariablesList) as Variable[];
  const [isLoading, setIsLoading] = useState(true);

  const { hasInternalServerError } = useActionState(VariableActions.getListRequest, {
    onSuccess: () => setIsLoading(false),
  });

  const filteredVariables = useMemo(
    () =>
      variables.filter(({ key, description }) => {
        if (!queryString.query) return true;
        const query = queryString.query.toLowerCase();
        return key.toLowerCase().includes(query) || description?.toLowerCase().includes(query);
      }),
    [variables, queryString.query],
  );

  const fetchData = useCallback(() => {
    dispatch(VariableActions.getListRequest());
    dispatch(ProfileActions.getListRequest());
  }, [dispatch]);

  useMount(() => {
    fetchData();
  });

  return (
    <>
      <Helmet title={t('customVariables.pageTitle', 'Custom variables')} />
      <InlineTutorialMessage
        title={t('inlineTutorial.title')}
        message={
          <Trans key="customVariables.inlineTutorial.description">
            Create custom variables and use them in tests. Efficiently work with different
            environment using variables profiles.{' '}
            <Link to={t('default.docs.profilesCreate')}>Learn more</Link>
          </Trans>
        }
        storageName="customVariablesGettingStarted"
      />
      {isLoading && (
        <LoaderContainer>
          <Loader size="large" />
        </LoaderContainer>
      )}
      {hasInternalServerError && (
        <ServerErrorInfo isVisible={hasInternalServerError} onRetry={fetchData} />
      )}
      {!isLoading && variables.length === 0 && (
        <EmptyState
          isVisible
          inline
          text={t('customVariables.emptyList.text', "You haven't created any variables yet")}
        />
      )}
      {!isLoading && variables.length > 0 && filteredVariables.length === 0 && (
        <EmptySearchState collection={t('emptySearchState.collection.variables', 'variables')} />
      )}
      {filteredVariables.length > 0 && (
        <VariablesSection>
          <VariablesList role="grid">
            {filteredVariables.map((variable) => (
              <VariableItem key={variable.id} variable={variable} />
            ))}
          </VariablesList>
        </VariablesSection>
      )}
      <Switch>
        <Route path={urls.customVariable} component={CustomVariablesModalRoute} />
      </Switch>
    </>
  );
};

export default CustomVariablesList;
