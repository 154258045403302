import { Skeleton } from '@bugbug/core/components/Skeleton/Skeleton';

import { StepPlaceholder } from './StepsGroup.styled';

export const StepsListPlaceholder = ({ rowsNumber }) =>
  Array.from({ length: rowsNumber }, (_, i) => (
    <StepPlaceholder key={i} style={{ height: 52 }} aria-rowindex={i}>
      <Skeleton height={18} width={18} />
      <Skeleton height={18} width={27} />
    </StepPlaceholder>
  ));
