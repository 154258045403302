import Button from '@bugbug/core/components/Button';
import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { Component } from '@bugbug/core/types/components';
import type { SideEffect } from '@bugbug/core/types/utils';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { useRenameComponentMutation } from '~/modules/components/components.api';
import toasts from '~/services/toasts';

import ComponentActionsDropdown from '../ComponentActions';
import ComponentMetadata from '../ComponentMetadata';

import * as S from './ComponentHeader.styled';

interface ComponentHeader {
  component: Component;
}

const ComponentHeader = ({ component }) => {
  const { t } = useTranslation();
  const { params } = useAppRoutes('component');
  const modal = useModal();

  const [handleNameChangeMutation, { isError: isRenameError, isSuccess: isRenameSuccess }] =
    useRenameComponentMutation();

  const nameFieldRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (isRenameError) {
      toasts.showError({
        id: component.id,
        content: t('componentDetails.rename.errorMessage', 'Renaming the component failed'),
      });
    }
  }, [component.id, isRenameError, t]);

  useEffect(() => {
    if (isRenameSuccess) {
      toasts.showSuccess({
        id: component.id,
        content: t(
          'ComponentDetails.rename.successMessage',
          'Component name has been updated successfully.',
        ),
      });
    }
  }, [component.id, isRenameSuccess, t]);

  const handleNameChange = useCallback<SideEffect<Component['name']>>(
    (name) => {
      handleNameChangeMutation({ id: component.id, name });
    },
    [component, handleNameChangeMutation],
  );

  const handleShowOrEditInRelatedTests = useCallback<(forEditing?: boolean) => SideEffect>(
    (forEditing = false) =>
      () => {
        modal.show('show_component_related_tests', {
          component,
          forEditing,
          params,
        });
      },
    [component, modal, params],
  );

  return (
    <S.Header>
      <S.Container data-testid="ComponentHeader">
        <S.Title>
          <S.NameContainer>
            <S.NameInput
              data-hj-allow
              max={DATA_RESTRICTIONS.COMPONENT_NAME_MAX_LENGTH}
              onChange={handleNameChange}
              ref={nameFieldRef}
              value={component.name}
            />
          </S.NameContainer>

          <S.Actions>
            {/* TODO add Tooltip when editing disabled and tooltip component is fixed (see: DEV-2767) */}
            <Button
              data-testid="ComponentDetails.EditComponentButton"
              disabled={component.testsCount === 0}
              iconName="edit"
              onClick={handleShowOrEditInRelatedTests(true)}
              variant="primary"
            >
              {t('componentDetails.editInTests', 'Edit component')}
            </Button>
            <ComponentActionsDropdown component={component} />
          </S.Actions>
        </S.Title>
      </S.Container>

      <S.MetadataContainer>
        <S.ComponentBadge>{t('componentDetails.badge', '❖ Component')}</S.ComponentBadge>
        <div>
          <ComponentMetadata
            component={component}
            onShowRelatedTests={handleShowOrEditInRelatedTests()}
          />
        </div>
      </S.MetadataContainer>
    </S.Header>
  );
};

export default ComponentHeader;
