import Loader from '@bugbug/core/components/Loader';
import { KEY_CODES_BINDINGS } from '@bugbug/core/constants/keyBindings';
import * as T from '@bugbug/core/utils/toolbox';
import {
  useContext,
  createContext,
  useCallback,
  useMemo,
  useState,
  Suspense,
  useRef,
  useEffect,
} from 'react';
import { useHistory } from 'react-router';
import { useEvent } from 'react-use';
import { v4 as uuid } from 'uuid';

import type {
  CustomModalRenderer,
  ActiveModal,
  ModalSize,
  ModalRenderer,
  ModalContextProps,
} from './useModal.types';
import type { ReactNode } from 'react';
import type { Modal } from '~/components/modals/modals.types';

import type { Route } from '@bugbug/core/types/routes';
import ModalComponent from '~/components/modals/Modal';
import { MODAL_COMPONENTS } from '~/components/modals/modals.import';
import analytics from '~/services/analytics';

import { LoaderContainer } from './ModalsProvider.styled';
import { isMouseEvent } from './useModal.types';

export const defaultModalContext: ModalContextProps = {
  show: T.noop,
  showCustom: T.noop,
  showWide: T.noop,
  showFullscreen: T.noop,
  showTutorial: T.noop,
  hide: T.noop,
  isOpen: false,
};

const ModalContext = createContext<ModalContextProps>(defaultModalContext);

export const ModalContextProvider = ModalContext.Provider;

// Context provider
interface ModalsProviderProps {
  children: ReactNode;
}

export const ModalsProvider = ({ children }: ModalsProviderProps) => {
  const [activeModalStack, setActiveModalStack] = useState<ActiveModal[]>([]);
  const currentModal = useRef<ActiveModal | null>(null);

  const show = useCallback<ModalRenderer>((type: Modal, props = {}, modalConfig = {}) => {
    const modal = { id: uuid(), type, props, config: modalConfig };
    analytics.trackModalView(type);

    setActiveModalStack((prev) => {
      if (prev.some((activeModal) => activeModal.type === modal.type)) return prev;
      return modal.config?.stacked ? [...prev, modal] : [modal];
    });
  }, []);

  const showCustom = useCallback<CustomModalRenderer>(
    (render, modalConfig = {}) =>
      setActiveModalStack((prev) => [
        ...prev,
        { id: uuid(), render, props: {}, config: modalConfig },
      ]),
    [],
  );

  const getShowWithSize = useCallback<(size: ModalSize) => ModalRenderer>(
    (size: ModalSize) =>
      (type: Modal, props = {}, modalConfig = {}) =>
        show(type, props, { ...modalConfig, size }),
    [show],
  );

  const showWide = useCallback<ModalRenderer>(
    (...args) => getShowWithSize('wide')(...args),
    [getShowWithSize],
  );

  const showFullscreen = useCallback<ModalRenderer>(
    (...args) => getShowWithSize('full-screen')(...args),
    [getShowWithSize],
  );

  const showTutorial = useCallback<ModalRenderer>(
    (...args) => getShowWithSize('tutorial')(...args),
    [getShowWithSize],
  );

  type MaybeEventRouteCallback = (payload?: MouseEvent | Route) => void;

  const hide = useCallback<MaybeEventRouteCallback>(
    (payload) => {
      // intentionally we're dropping the mouse event object
      const args = !isMouseEvent(payload) ? payload : undefined;

      const latestModal = currentModal.current;
      latestModal?.config?.onHide?.(args!);

      setActiveModalStack((prev) => prev.slice(0, -1));
    },
    [setActiveModalStack],
  );

  const handleKeyEvent = (event) => {
    if ([KEY_CODES_BINDINGS.ESC].includes(event.keyCode)) {
      hide();
    }
  };

  useEvent('keydown', handleKeyEvent);

  useEffect(() => {
    currentModal.current = activeModalStack[activeModalStack.length - 1];
  }, [activeModalStack]);

  const history = useHistory();
  useEffect(() => {
    const unregister = history.listen(() => {
      if (history.action === 'POP') {
        setActiveModalStack([]);
      }
    });

    return () => unregister();
  }, [history]);

  const Fallback = useMemo<NonNullable<ReactNode>>(
    () => (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    ),
    [],
  );

  const contextValue = useMemo<ModalContextProps>(
    () => ({
      show,
      showCustom,
      showWide,
      showFullscreen,
      showTutorial,
      hide,
      isOpen: activeModalStack.length > 0,
    }),
    [show, showCustom, showWide, showFullscreen, showTutorial, hide, activeModalStack.length],
  );

  const renderModal = (activeModal: ActiveModal) => {
    if (!activeModal) {
      return null;
    }

    const Content = activeModal.render || MODAL_COMPONENTS[activeModal.type];

    return (
      <ModalComponent
        key={activeModal.id}
        size={activeModal.config?.size}
        closeButtonHidden={activeModal.config?.closeButtonHidden}
        onCloseButtonClick={activeModal.config?.onCloseButtonClick}
      >
        <Suspense fallback={Fallback}>
          <Content {...activeModal.props} />
        </Suspense>
      </ModalComponent>
    );
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {activeModalStack.map(renderModal)}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalsProvider');
  }

  return context;
};

export default useModal;
