import { createSlice } from '@reduxjs/toolkit';

import type { TemporaryStep } from './steps.types';
import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';

import type { Step } from '@bugbug/core/types/steps';

import { TestTypes } from '../test/test.redux';
import { testsApi } from '../tests/tests.api';

interface StepsState {
  currentStepId?: Step['id'];
  temporaryStep?: TemporaryStep;
}

const initialState: StepsState = {};

const StepsSlice = createSlice({
  name: 'steps',
  initialState,

  reducers: {
    resetCurrentStepId(state) {
      state.currentStepId = undefined;
    },

    setCurrentStepId(state, action: PayloadAction<Step['id']>) {
      state.currentStepId = action.payload;
    },

    toggleCurrentStepId(state, action: PayloadAction<Step['id']>) {
      state.currentStepId = state.currentStepId === action.payload ? undefined : action.payload;
    },

    setTemporaryStep(state, action: PayloadAction<StepsState['temporaryStep']>) {
      state.temporaryStep = action.payload;
    },

    removeTemporaryStep(state) {
      state.temporaryStep = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isCreateTempStepSuccess, (state, action) => {
        state.currentStepId = action.step.id;
        state.temporaryStep = action.step;
      })
      .addMatcher(isCreateTempStepRemoved, (state, action) => {
        if (state.currentStepId === action.temporaryStepId) {
          state.currentStepId = action.newStepId;
          state.temporaryStep = undefined;
        }
      })
      .addMatcher(testsApi.endpoints.getTest.matchFulfilled, (state) => {
        state.currentStepId = undefined;
      });
  },
});

export const StepsActions = StepsSlice.actions;

export default StepsSlice.reducer;

// Helpers
const isCreateTempStepSuccess = (action: AnyAction): boolean =>
  [TestTypes.CREATE_TEMPORARY_STEP_SUCCESS].includes(action.type);

const isCreateTempStepRemoved = (action: AnyAction): boolean =>
  [TestTypes.CANCEL_TEMPORARY_STEP, TestTypes.SAVE_TEMPORARY_STEP_SUCCESS].includes(action.type);
