import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

import type { EditAlertFormValues } from '../../EditAlertFormValues.types';

import type { Project } from '@bugbug/core/types/projects';
import type { User } from '@bugbug/core/types/users';
import FormField from '~/components/FormField';
import { OrganizationUsersActions } from '~/modules/organizationUsers/organizationUsers.redux';
import { selectOrganizationUsersList } from '~/modules/organizationUsers/organizationUsers.selectors';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { useAppDispatch, useAppSelector } from '~/modules/store';

const EditAlertModalThenActionEmail = () => {
  const { t } = useTranslation();
  const { getFieldProps, getFieldMeta } = useFormikContext<EditAlertFormValues>();
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectSingleProject) as Project;

  // @TODO remove unknown and migration organization selector into ts
  const users = useAppSelector(selectOrganizationUsersList) as unknown as User[];

  useMount(() => {
    dispatch(OrganizationUsersActions.getListRequest());
  });

  return (
    <>
      <FormField
        label={t('editAlertModal.fields.emailSendTo.label', 'Send to')}
        labelId="emailSendTo"
      >
        <Select
          {...getFieldMeta('users')}
          {...getFieldProps('users')}
          multiple
          aria-labelledby="emailSendTo"
          selectAllOptionVisible
          selectAllOptionLabel={t(
            'editAlertModal.fields.emailSendTo.all',
            'All people in "{{ projectName }}"',
            {
              interpolation: { escapeValue: false },
              projectName: project.name,
            },
          )}
        >
          {users.map((user) => (
            <SelectOption key={user.id} value={user.id} multiple>
              {user.email}
            </SelectOption>
          ))}
        </Select>
      </FormField>
      <FormField
        label={t(
          'editAlertModal.fields.extraEmails.label',
          'Notify additional emails who are not members of "{{ projectName }}"',
          {
            projectName: project.name,
            interpolation: { escapeValue: false },
          },
        )}
        description={t(
          'editAlertModal.fields.extraEmails.description',
          'Enter email addresses separated by comma',
        )}
      >
        <Input
          data-testid="extraEmails"
          {...getFieldMeta('extraEmails')}
          {...getFieldProps('extraEmails')}
        />
      </FormField>
    </>
  );
};
export default EditAlertModalThenActionEmail;
