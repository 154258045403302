import ButtonBase, { ActionButton } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import styled from 'styled-components';

import {
  OnboardingBox,
  OnboardingCard,
  OnboardingTitle,
} from '~/views/Onboarding/Onboarding.styled';

import ArrowDownSVG from '~/images/arrow-down-icon.svg?react';

export const DotIcon = styled(Icon).attrs(() => ({
  name: 'dot',
}))`
  && {
    width: 14px;
    height: 14px;
  }
`;

export const PasteIcon = styled(Icon).attrs(() => ({
  name: 'paste',
}))`
  && {
    max-width: 17px;
  }
`;

export const AddIcon = styled(Icon).attrs(() => ({
  name: 'add',
}))`
  && {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const AddComponentIcon = styled(Icon).attrs(() => ({
  name: 'addComponent',
}))`
  width: 24px;
  height: 24px;
  left: 4px;

  rect {
    fill: transparent;
  }

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const TestActionButton = styled(ButtonBase).attrs(() => ({
  variant: 'primary',
}))`
  width: 100%;

  div {
    flex: none;
  }

  & + button {
    margin: 0;
  }
`;

export const RecordButton = styled(ActionButton).attrs(() => ({
  variant: 'primary',
  type: 'submit',
}))`
  width: 100%;
  margin-top: 32px;
`;

export const EmptyStateCard = styled(OnboardingCard)`
  box-shadow: none;
`;

export const EmptyStateBox = styled(OnboardingBox)`
  margin: 90px auto;
`;

export const EmptyStateTitle = styled(OnboardingTitle)`
  margin-bottom: 4px;
`;

export const ArrowDownIcon = styled(ArrowDownSVG)`
  margin: 0 auto 12px auto;
  display: block;
`;

export const OtherActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
