import Loader, { LoaderFlexContainer } from '@bugbug/core/components/Loader';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { EditAlertFormValues } from '../../EditAlertFormValues.types';

import type { SlackIntegrationMember } from '@bugbug/core/types/integrations';
import FormField from '~/components/FormField';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useGetMembersListQuery } from '~/modules/integrations/integrations.api';
import { isBadRequestError, isInternalServerError } from '~/services/api/utils';

import EditAlertModalActionTrigger from '../EditAlertModalActionTrigger';

import { SLACK_RECEIVER } from './EditAlertModalActionSlack.constants';
import * as S from './EditAlertModalActionSlack.styled';

const EditAlertModalActionSlackForm = () => {
  const { t } = useTranslation();
  const { getFieldProps, getFieldMeta, setFieldValue } = useFormikContext<EditAlertFormValues>();
  const { params } = useAppRoutes('alert');

  const {
    data = [],
    error,
    refetch,
    isLoading,
    isSuccess,
  } = useGetMembersListQuery({
    type: 'slack',
    projectId: params.projectId,
  });
  const members = data as SlackIntegrationMember[];

  const methodFieldProps = getFieldProps('method');
  const nameFieldProps = getFieldProps('name');
  const nameFieldContent = SLACK_RECEIVER[methodFieldProps.value];

  const membersList = useMemo(
    () =>
      members.filter(({ isUser }) =>
        methodFieldProps.value === 'directMessage' ? isUser : !isUser,
      ),
    [members, methodFieldProps.value],
  );

  const handleMemberChange = useCallback(
    (event) => {
      const memberOnTheList = membersList.find(({ name }) => name === event.target.value);

      // It's safe to use ! here because we are sure that the member is on the list.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setFieldValue('channelId', memberOnTheList!.id, true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setFieldValue('name', memberOnTheList!.name, true);
      nameFieldProps.onChange(event);
    },
    [setFieldValue, membersList, nameFieldProps],
  );

  return (
    <>
      {(isLoading || error) && (
        <S.PendingStateContainer>
          {/* Loading state */}
          {isLoading && (
            <LoaderFlexContainer>
              <Loader />
              <p>{t('editAlertModal.slack.loadingData', 'Loading Slack data...')}</p>
            </LoaderFlexContainer>
          )}
          {/* Error state */}
          <ServerErrorInfo
            isVisible={isInternalServerError(error) || isBadRequestError(error)}
            onRetry={refetch}
          />
        </S.PendingStateContainer>
      )}

      {/* Loaded without errors */}
      {isSuccess && (
        <>
          <FormField label={t('editAlertModal.slack.fields.send.label', 'Send')} labelId="method">
            <Select {...getFieldMeta('method')} {...methodFieldProps} aria-labelledby="method">
              <SelectOption value="directMessage">
                {t('editAlertModal.slack.fields.send.directMessage', 'Direct message')}
              </SelectOption>
              <SelectOption value="channelMessage">
                {t('editAlertModal.slack.fields.send.channelMessage', 'Channel message')}
              </SelectOption>
            </Select>
          </FormField>
          <FormField label={nameFieldContent.label} labelId="name">
            <Select
              {...getFieldMeta('name')}
              {...nameFieldProps}
              onChange={handleMemberChange}
              aria-labelledby="name"
              placeholder={nameFieldContent.placeholder}
              fullWidth
              showSearch
            >
              {membersList.map(({ name, id }) => (
                <SelectOption
                  key={id}
                  value={name}
                  label={[nameFieldContent.leadingAdornment, name].join('')}
                />
              ))}
            </Select>
          </FormField>
          <EditAlertModalActionTrigger />
        </>
      )}
    </>
  );
};
export default EditAlertModalActionSlackForm;
