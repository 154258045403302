import { useTranslation } from 'react-i18next';

import * as S from './ExtensionBadge.styled';

interface ExtensionBadgeProps {
  installed?: boolean;
}
export const ExtensionBadge = ({ installed }: ExtensionBadgeProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'extensionBadge' });

  return (
    <S.Container>
      <S.StoreIconContainer>
        <S.StoreIcon />
        {installed && (
          <>
            <S.InstalledCheckMark />
            <S.CheckMarkSpacer />
          </>
        )}
      </S.StoreIconContainer>
      <S.InfoBox>
        <S.BugBugLogo />
        <S.ExtensionDescription>
          {t('description', 'Automation Testing Extension')}
        </S.ExtensionDescription>
        <S.ExtensionDetails>
          {installed ? (
            <S.SuccessMessage>{t('installed', 'Installed')}</S.SuccessMessage>
          ) : (
            <>
              <S.TrustedSection>
                <S.DetailsIcon name="trusted" />
                <S.DetailsParagraph>{t('domain', 'bugbug.io')}</S.DetailsParagraph>
              </S.TrustedSection>
              <S.RatingSection>
                <S.DetailsIcon name="star" />
                <S.DetailsParagraph>4.7</S.DetailsParagraph>
              </S.RatingSection>
            </>
          )}
        </S.ExtensionDetails>
      </S.InfoBox>
      {!installed && <S.ExternalLinkIcon />}
    </S.Container>
  );
};
