import { keyframes } from 'styled-components';

export const spinAnimation = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;

export const pulseAnimation = (endScale: number) => keyframes`
  0% {
    transform: scale(1);
    opacity:1;
  }

  25% {
    opacity: 0.7;
  }

  80% {
    transform: scale(${endScale});
    opacity: 0;
  }

  100% {
    transform: scale(${endScale});
    opacity: 0;
  }
`;
