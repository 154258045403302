import DropdownBase, { DROPDOWN_ANCHOR, ExpandIcon } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Dropdown = styled(DropdownBase).attrs(() => ({
  anchor: DROPDOWN_ANCHOR.BOTTOM_START,
}))`
  max-width: 210px;
  height: 100%;

  ${ExpandIcon} {
    margin-left: 1px;
    font-size: 20px;
    margin-right: -3px;
    color: ${COLOR.PRIMARY};
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow: auto;
`;

export const IconPlaceholder = styled.div`
  min-width: 20px;
  height: 20px;
  margin-right: 6px;
  float: left;
`;

export const ItemCheckIcon = styled(Icon).attrs(() => ({
  name: 'check',
}))`
  color: ${({ theme }) => theme.colors.text.subtle};
  top: 0;
  margin-right: 6px !important;
`;

export const OrganizationIcon = styled(Icon).attrs(() => ({
  name: 'organization',
}))`
  width: 18px !important;
  color: ${COLOR.DARK_GRAY} !important;
  top: -1px !important;
`;

export const CreateIcon = styled(Icon).attrs(() => ({
  name: 'add',
}))`
  font-size: 23px;
  color: ${COLOR.PRIMARY_LIGHT};
  width: 23px;
  max-height: 23px;
  margin-right: 2px;
  top: 1px;
  position: relative;
`;

export const CreateButton = styled.button`
  outline: none;
  border: none;
  white-space: nowrap;
  padding: 10px 33px 14px;
  width: 100%;
  color: ${COLOR.PRIMARY_LIGHT};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-top: 1px solid ${COLOR.GRAY_9};
  background-color: ${COLOR.GRAY_21};

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    outline: none;
    background: ${({ theme }) => theme.colors.neutrals[96]};
    text-transform: none !important;
  }
`;

export const EditButton = styled.div`
  height: 25px;
  width: 25px;
  margin-right: -3px;
  display: flex;
  border-radius: 5px;
  float: right;
  margin-top: -1px;
  align-items: center;
  margin-bottom: -5px;
  flex: none;
  justify-content: center;
  color: ${COLOR.PRIMARY_LIGHT};

  svg {
    margin: 0;
  }

  &:hover {
    background-color: ${COLOR.SAMPLE_GRAY};
  }
`;
