import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${COLOR.ERROR};

  svg {
    width: 60px;
    height: 60px;
    margin-bottom: 18px;
    line-height: 16px;
  }
`;

export const Message = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 16px;

  button {
    margin-top: 18px;
  }
`;

export const ErrorId = styled.p`
  font-size: 11px;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
