import Dropdown, { DropdownItem, DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { groupBy } from '@bugbug/core/utils/toolbox';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useModal from '~/hooks/useModal';
import { useAppSelector } from '~/modules/store';
import { selectTestGroups, selectTestSteps } from '~/modules/test/test.selectors';
import { selectCheckedSteps } from '~/modules/uiState/uiState.selectors';
import { getSelectedPartialsLabel } from '~/utils/misc';

import { Container, Button } from './SelectedRowsActions.styled';

export const SelectedRowsActions = ({
  className,
  testId,
  stepsActivation,
  onDeselect,
  onActivationChange,
  onDelete,
  onCopySteps,
}) => {
  const { t } = useTranslation();
  const modal = useModal();
  const checkedSteps = useAppSelector(selectCheckedSteps);
  const steps = useAppSelector(selectTestSteps);
  const groups = useAppSelector(selectTestGroups);

  const { wholeCheckedGroups, stepsFromPartiallyCheckedGroups } = useMemo(() => {
    const result = {
      wholeCheckedGroups: [],
      stepsFromPartiallyCheckedGroups: [],
    };

    const checkedStepsByGroup = groupBy(
      (step) => step.groupId,
      checkedSteps.map((step) => steps[step.id]),
    );

    Object.entries(checkedStepsByGroup).forEach(([groupId, checkedGroupSteps]) => {
      if (checkedGroupSteps.length === groups[groupId].steps.length) {
        result.wholeCheckedGroups.push(groupId);
      } else {
        result.stepsFromPartiallyCheckedGroups.push(...checkedGroupSteps);
      }
    });

    return result;
  }, [checkedSteps, steps, groups]);

  const canActivateSteps = useMemo(
    () => !!checkedSteps.find((step) => !stepsActivation[step.id]),
    [checkedSteps, stepsActivation],
  );

  const handleStepsActiveChange = useCallback(() => {
    onActivationChange(
      checkedSteps.map((step) => step.id),
      canActivateSteps,
    );
  }, [checkedSteps, canActivateSteps, onActivationChange]);

  const handleCopySteps = useCallback(() => {
    onCopySteps(checkedSteps.map((step) => step.id));
  }, [checkedSteps, onCopySteps]);

  const partialsLabel = useMemo(
    () =>
      getSelectedPartialsLabel(stepsFromPartiallyCheckedGroups.length, wholeCheckedGroups.length),
    [stepsFromPartiallyCheckedGroups, wholeCheckedGroups],
  );

  const handleDelete = useCallback(
    () =>
      modal.show('delete_test_partials', {
        testId,
        steps: stepsFromPartiallyCheckedGroups.map((step) => step.id),
        groups: wholeCheckedGroups,
        onSuccess: onDelete,
      }),
    [modal, testId, stepsFromPartiallyCheckedGroups, wholeCheckedGroups, onDelete],
  );

  return (
    <Container className={className} data-testid="TestDetails.SelectedRowsActions">
      <Dropdown
        rounded
        data-testid="TestDetails.BulkActions"
        variant={DROPDOWN_VARIANT.PRIMARY}
        label={t('testsList.header.bulkActionsButton.main', '{{partialsLabel}} selected', {
          partialsLabel,
        })}
      >
        <DropdownItem onClick={handleStepsActiveChange}>
          {canActivateSteps ? <Icon name="layers" /> : <Icon name="layersClear" />}
          {canActivateSteps
            ? t('testDetails.selectedRowsActions.activateSteps', 'Activate')
            : t('testDetails.selectedRowsActions.deactivateSteps', 'Deactivate')}
        </DropdownItem>
        <DropdownItem onClick={handleCopySteps}>
          <Icon name="copy" />
          {t('testDetails.selectedRowsActions.copySteps', 'Copy steps')}
        </DropdownItem>
        <DropdownItem onClick={handleDelete}>
          <Icon name="delete" />
          {t('testDetails.selectedRowsActions.deleteButton', 'Delete')}
        </DropdownItem>
      </Dropdown>
      <Button
        data-testid="TestDetails.DeselectButton"
        iconName="close"
        onClick={onDeselect}
        bordered
      >
        {t('testDetails.selectedRowsActions.deselectButton', 'Deselect all')}
      </Button>
    </Container>
  );
};

SelectedRowsActions.defaultProps = {
  className: null,
};

SelectedRowsActions.propTypes = {
  className: PropTypes.string,
  onDeselect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onActivationChange: PropTypes.func.isRequired,
  stepsActivation: PropTypes.shape({}).isRequired,
  testId: PropTypes.string.isRequired,
};
