import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgCryingFace = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 33, height: 32, viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { x: 13.675, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 15.8083, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 17.9417, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 20.075, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 22.2083, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 24.3416, y: 4.26758, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 11.5417, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 9.40845, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 7.2749, y: 4.26758, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 5.1416, y: 6.40039, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 26.4751, y: 6.40039, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 28.6084, y: 8.5332, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 28.6084, y: 10.666, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 3.0083, y: 8.5332, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 3.0083, y: 10.666, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 0.875, y: 12.8008, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 0.875, y: 14.9336, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 0.875, y: 17.0664, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 3.0083, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 3.0083, y: 21.334, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 5.1416, y: 23.4668, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 7.2749, y: 25.5996, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 9.40845, y: 27.7324, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 11.5417, y: 27.7324, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 13.675, y: 29.8672, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 15.8083, y: 29.8672, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.9417, y: 29.8672, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 20.075, y: 27.7324, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.2083, y: 27.7324, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 24.3416, y: 25.5996, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 26.4751, y: 23.4668, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 28.6084, y: 21.334, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 28.6084, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 30.7417, y: 17.0664, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 30.7417, y: 14.9336, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 30.7417, y: 12.8008, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 20.075, y: 10.666, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 20.075, y: 12.8008, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.2083, y: 14.9336, width: 2.13333, height: 2.13333, fill: "#00D1FF" }),
  /* @__PURE__ */ jsx("rect", { x: 24.3416, y: 17.0664, width: 2.13333, height: 2.13333, fill: "#00D1FF" }),
  /* @__PURE__ */ jsx("rect", { x: 20.075, y: 17.0664, width: 2.13333, height: 2.13333, fill: "#00D1FF" }),
  /* @__PURE__ */ jsx("rect", { x: 22.2083, y: 14.9336, width: 2.13333, height: 2.13333, fill: "#00D1FF" }),
  /* @__PURE__ */ jsx("rect", { x: 20.075, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#62E3FF" }),
  /* @__PURE__ */ jsx("rect", { x: 22.2083, y: 17.0664, width: 2.13333, height: 2.13333, fill: "#00D1FF" }),
  /* @__PURE__ */ jsx("rect", { x: 24.3416, y: 17.0664, width: 2.13333, height: 2.13333, fill: "#00D1FF" }),
  /* @__PURE__ */ jsx("rect", { x: 22.2083, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#00D1FF" }),
  /* @__PURE__ */ jsx("rect", { x: 20.075, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#00D1FF" }),
  /* @__PURE__ */ jsx("rect", { x: 24.3416, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#00D1FF" }),
  /* @__PURE__ */ jsx("rect", { x: 11.5417, y: 10.666, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 11.5417, y: 12.8008, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 13.675, y: 23.4668, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 15.8083, y: 23.4668, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.9417, y: 23.4668, width: 2.13333, height: 2.13333, fill: "#521EC9" })
] });
const ForwardRef = forwardRef(SvgCryingFace);
export default ForwardRef;
