import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import memoize from 'lodash.memoize';
import { prop } from 'ramda';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import ServerErrorInfo from '~/components/ServerErrorInfo';
import { Pagination, SimpleTable } from '~/components/Table';
import useActionState from '~/hooks/useActionState';
import useQueryString from '~/hooks/useQueryString';
import { useRecentSort } from '~/hooks/useRecentSort';
import { SuiteRunActions } from '~/modules/suiteRun/suiteRun.redux';
import {
  selectCurrentPageParams,
  selectSuiteRunsList,
} from '~/modules/suiteRun/suiteRun.selectors';
import { RUNS_HISTORY_DEFAULT_SORT } from '~/views/RunsHistory/RunsHistory.constants';
import urls, { reverse } from '~/views/urls';

import { SUITES_HISTORY_COLUMNS } from './SuitesHistory.constants';
import { Container, LoaderContainer, TableEmptyState } from './SuitesHistory.styled';

const SuitesHistory = forwardRef(({ className, onSelectionChange, sortByConfiguration }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const queryString = useQueryString();
  const urlParams = useParams();

  const location = useLocation();
  const suiteRuns = useSelector(selectSuiteRunsList);

  const { pagination } = useSelector(selectCurrentPageParams);
  const { isLoading, hasInternalServerError, reset, hasBadRequestError } = useActionState(
    SuiteRunActions.getListRequest,
    {
      reset: false,
    },
  );

  const { updateRecentSort, ...recentSort } = useRecentSort({
    cacheName: 'suitesHistoryList',
    defaultConfig: RUNS_HISTORY_DEFAULT_SORT,
    columns: SUITES_HISTORY_COLUMNS,
  });

  const handleDataUpdate = useCallback(
    (tableData, page = 1) => {
      dispatch(
        SuiteRunActions.getListRequest(
          page,
          queryString.query,
          tableData.sortConfig.sortBy,
          tableData.sortConfig.desc,
        ),
      );
      updateRecentSort({ ...tableData.sortConfig, query: queryString.query, page });
    },
    [dispatch, updateRecentSort, queryString.query],
  );

  useUnmount(() => {
    reset();
  });

  const handleDeselectAll = useCallback(() => {
    tableRef.current.toggleAllRowsSelected(false);
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      handleDataUpdate(tableRef.current.state, page);
    },
    [handleDataUpdate],
  );

  const refresh = useCallback(() => {
    handleDataUpdate(tableRef.current.state, queryString.page);
  }, [handleDataUpdate, queryString.page]);

  const fetchInitialData = useCallback(() => {
    handleDataUpdate({ sortConfig: recentSort }, queryString.page);
  }, [handleDataUpdate, recentSort, queryString.page]);

  useMount(() => {
    fetchInitialData();
  });

  useUpdateEffect(() => {
    handleDataUpdate({ sortConfig: sortByConfiguration });
  }, [sortByConfiguration, handleDataUpdate]);

  useImperativeHandle(
    ref,
    () => ({
      deselectAll: handleDeselectAll,
      refresh,
    }),
    [refresh, handleDeselectAll],
  );

  const renderLoader = renderWhenTrue(() => (
    <LoaderContainer>
      <Loader size="large" />
    </LoaderContainer>
  ));

  /* eslint-disable-next-line react/no-unstable-nested-components, react/function-component-definition, react/display-name */
  const renderEmptyState = renderWhenTrue(() => () => {
    if (hasInternalServerError || hasBadRequestError) {
      return (
        <ServerErrorInfo
          isVisible={hasInternalServerError || hasBadRequestError}
          onRetry={handleDataUpdate}
          hideRetryButton={hasBadRequestError}
        />
      );
    }
    return <TableEmptyState>{renderLoader(true)}</TableEmptyState>;
  });

  const getExtraRowProp = memoize((row) => {
    const pathname = reverse(urls.suiteRun, {
      projectId: urlParams.projectId,
      projectSlug: urlParams.projectSlug,
      organizationId: urlParams.organizationId,
      suiteRunId: row.id,
    });

    return {
      to: { pathname, state: { backUrl: `${location.pathname}${location.search}` } },
    };
  }, prop('id'));

  return (
    <Container className={className} data-testid="SuitesHistory">
      <Helmet title={t('suitesHistory.pageTitle', 'Suite runs history')} />
      <ServerErrorInfo
        isVisible={hasInternalServerError || hasBadRequestError}
        onRetry={refresh}
        hideRetryButton={hasBadRequestError}
      />
      <Pagination
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={pagination.pageSize}
        onChange={handlePageChange}
      >
        <SimpleTable
          ref={tableRef}
          columns={SUITES_HISTORY_COLUMNS}
          data={suiteRuns}
          onSelectionChange={onSelectionChange}
          getRowProps={getExtraRowProp}
          emptyStateText={t('suitesHistory.placeholder', "You haven't run any suites yet")}
          renderEmptyState={renderEmptyState(
            isLoading || hasInternalServerError || hasBadRequestError,
          )}
          disabledWindowing
        />
      </Pagination>
    </Container>
  );
});

SuitesHistory.displayName = 'SuitesHistory';

export default SuitesHistory;
