import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgTestPlusIcon = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 41, height: 41, viewBox: "0 0 41 41", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { x: 17.0664, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 19.1992, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 21.332, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 23.4648, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 25.6016, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 27.7344, y: 4.26758, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 29.8672, y: 4.26758, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 12.8008, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 14.9336, y: 2.13281, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 8.53516, y: 4.26758, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 10.668, y: 4.26758, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 6.39844, y: 6.40039, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 32, y: 6.40039, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 34.1328, y: 8.5332, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 34.1328, y: 10.666, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 36.2656, y: 12.8008, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 4.26562, y: 10.666, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 4.26562, y: 8.5332, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 2.13281, y: 12.8008, width: 2.13333, height: 2.13333, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 2.13281, y: 14.9336, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 2.13281, y: 17.0664, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 2.13281, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 2.13281, y: 21.334, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 2.13281, y: 23.4668, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 2.13281, y: 25.5996, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 4.26562, y: 29.8672, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 4.26562, y: 27.7324, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 6.39844, y: 32, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 10.668, y: 34.1328, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 8.53516, y: 34.1328, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 12.8008, y: 36.2676, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 14.9336, y: 36.2676, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.0664, y: 36.2676, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.1992, y: 36.2676, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 21.332, y: 36.2676, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 23.4648, y: 36.2676, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 25.6016, y: 36.2676, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 27.7344, y: 34.1328, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 32, y: 32, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 29.8672, y: 34.1328, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 34.1328, y: 29.8672, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 34.1328, y: 27.7324, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 36.2656, y: 21.334, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 36.2656, y: 23.4668, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 36.2656, y: 25.5996, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 36.2656, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 36.2656, y: 17.0664, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 36.2656, y: 14.9336, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.1992, y: 14.9336, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.0664, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 14.9336, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 21.332, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 23.4648, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.1992, y: 17.0664, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.1992, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.1992, y: 21.334, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.1992, y: 23.4668, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.1992, y: 12.8008, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.1992, y: 25.5996, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 25.6016, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 12.8008, y: 19.1992, width: 2.13333, height: 2.13333, fill: "#521EC9" })
] });
const ForwardRef = forwardRef(SvgTestPlusIcon);
export default ForwardRef;
