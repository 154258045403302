import * as Yup from 'yup';

import * as validators from '~/utils/validators';

export const InviteUsersSchema = Yup.object().shape({
  users: Yup.array().of(
    Yup.object().shape({
      email: validators.emailValidator,
      role: validators.userRoleValidator,
    }),
  ),
});

export type InviteUsersDto = Yup.InferType<typeof InviteUsersSchema>;
