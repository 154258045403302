import { CommonActions } from '@bugbug/core/actions/actions';
import { RUN_STATUS } from '@bugbug/core/constants/status';
import { isFailedStatus, isRunningStatus } from '@bugbug/core/types/base';
import throttle from 'lodash.throttle';
import { path } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { MODAL_TYPE } from '~/components/modals';
import useExtensionState from '~/hooks/useExtensionState';
import useModal from '~/hooks/useModal';
import { RUN_ENV } from '~/modules/constans';
import { useSendEventMutation } from '~/modules/events/events.api';
import { dispatchInExtension } from '~/modules/extension/extension.dispatch';
import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import { selectHasMoreThanOneProfile } from '~/modules/profile/profile.selectors';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { useIsNewPlaybackSupported } from '~/modules/test/test.hooks';
import { TestActions } from '~/modules/test/test.redux';
import {
  selectTestIsRecording,
  selectHasUnconfirmedGroups,
  selectIsTestRunPaused,
  selectSortedSteps,
} from '~/modules/test/test.selectors';
import { selectTestRun } from '~/modules/testRun/testRun.selectors';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import { selectIgnoreStopTestAlert, selectSessionsIds } from '~/modules/uiState/uiState.selectors';
import { selectUserId } from '~/modules/user/user.selectors';
import analytics, { TRACK_EVENT_TYPE, TRACK_EVENT_ARG_TYPE } from '~/services/analytics';
import localStorage from '~/services/localStorage';
import { isTriggeredByCurrentUser, isCloudRun as isCloudRunUtil } from '~/utils/runs';

const START_THROTTLE_TIME = 1000;

const useTestRunner = (test = {}, config = {}) => {
  const dispatch = useDispatch();
  const lastUnfinishedRunId = path(['lastUnfinishedUserRun', 'id'], test);
  const testRunId = config.testRunId || lastUnfinishedRunId;
  const testRunResult = useSelector(selectTestRun(testRunId, true));
  const status = testRunResult.status || path(['lastUnfinishedUserRun', 'status'], test);
  const extension = useExtensionState();
  const modal = useModal();
  const project = useSelector(selectSingleProject);
  const organizationId = useSelector(selectCurrentOrganizationId);
  const isRecording = useSelector(selectTestIsRecording);
  const isPaused = useSelector(selectIsTestRunPaused);
  const hasMoreThanOneProfile = useSelector(selectHasMoreThanOneProfile);
  const hasUnconfirmedGroups = useSelector(selectHasUnconfirmedGroups);
  const currentUserId = useSelector(selectUserId);
  const sessionIds = useSelector(selectSessionsIds);
  const isNewPlaybackSupported = useIsNewPlaybackSupported();
  const sortedSteps = useSelector(selectSortedSteps);
  const [firstStep] = sortedSteps;
  const ignoreStopTestAlert = useSelector(selectIgnoreStopTestAlert);
  const [sendEvent] = useSendEventMutation();
  const eventData = useMemo(
    () => ({
      organizationId,
      userId: currentUserId,
      testId: test?.id,
      projectId: project?.id,
    }),
    [organizationId, currentUserId, test?.id, project?.id],
  );

  const isRunning =
    isRunningStatus(status) &&
    isTriggeredByCurrentUser(testRunResult, currentUserId, !!lastUnfinishedRunId);
  const isQueued = status === RUN_STATUS.QUEUED;
  const isCloudRun = isCloudRunUtil(testRunResult);

  const isDebugging = testRunResult.status === RUN_STATUS.DEBUGGING;
  const isIdle = !isRecording && !isRunning && !isDebugging && !isPaused;

  const openExtensionModal = useCallback(
    () => modal.showTutorial(MODAL_TYPE.INSTALL_EXTENSION),
    [modal],
  );

  const openUnconfirmedStepsModal = useCallback(
    () => modal.show(MODAL_TYPE.UNCONFIRMED_STEPS),
    [modal],
  );

  const openIncognitoTabsModal = useCallback(
    (onConfirm) => modal.show(MODAL_TYPE.INCOGNITO_TABS_CLOSE, { onConfirm }),
    [modal],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const start = useCallback(
    throttle(
      async ({ withProfileSelection, ...options } = {}) => {
        if (!options.withRecording && !options.runMode) {
          analytics.trackEvent(TRACK_EVENT_TYPE.START_TEST_RUN, {
            [TRACK_EVENT_ARG_TYPE.TEST_RUN_METHOD]: RUN_ENV.SERVER,
          });
        }
        const continueRun = (profileId = options.runProfileId) => {
          dispatch(
            TestActions.startRunningRequest(test.id, {
              runMode: RUN_ENV.SERVER,
              startFromStepId: null,
              stopAfterStepId: null,
              stopAction: 'finish',
              sessionId: uuid(),
              ...options,
              runProfileId: profileId,
              redirect: options.withRedirect,
            }),
          );

          const startStepIdIndex = Math.max(
            sortedSteps.findIndex((step) => step.id === options.startFromStepId),
            0,
          );
          const afterStep = sortedSteps[startStepIdIndex - 1];
          const nextStep = sortedSteps[startStepIdIndex];

          dispatch(
            CommonActions.setPlaybackCursorPosition({
              afterStep: afterStep
                ? {
                    id: afterStep.id,
                    groupId: afterStep.groupId,
                  }
                : null,
              runningStep: null,
              nextStep: nextStep
                ? {
                    id: nextStep.id,
                    groupId: nextStep.groupId,
                  }
                : null,
            }),
          );

          if (options.pause) {
            dispatch(CommonActions.setPausePosition(options.pause));
          }
          if (options.record) {
            dispatch(
              CommonActions.setRecordingStartPosition({
                afterStepId: options.record.startAfterStepId,
                atGroupId: options.record.intoGroupId,
              }),
            );
          }
        };

        if (options.stopWhenUnconfirmedGroups && hasUnconfirmedGroups) {
          openUnconfirmedStepsModal();
          return;
        }

        const showIncognitoWindowsModalIfNeeded = async (profileId) => {
          if (options.runMode === RUN_ENV.LOCAL) {
            const hasActiveIncognitoWindows = await extension.hasActiveIncognitoWindows();
            if (hasActiveIncognitoWindows) {
              openIncognitoTabsModal(continueRun);
              return;
            }
          }
          continueRun(profileId);
        };

        if (withProfileSelection && hasMoreThanOneProfile) {
          modal.show(MODAL_TYPE.RUN_WITH_PROFILE, {
            initialProfileId: test.runProfileId,
            onSuccess: showIncognitoWindowsModalIfNeeded,
          });
          return;
        }

        await showIncognitoWindowsModalIfNeeded();
      },
      START_THROTTLE_TIME,
      { trailing: false },
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      test.id,
      test.runProfileId,
      dispatch,
      hasMoreThanOneProfile,
      hasUnconfirmedGroups,
      modal.show,
      openUnconfirmedStepsModal,
      firstStep?.id,
      firstStep?.groupId,
      sortedSteps,
    ],
  );

  const startLocalRunner = useCallback(
    (options) => {
      if (options.withRecording) {
        analytics.trackEvent(TRACK_EVENT_TYPE.CLICK_RECORD_FROM_HERE);
      } else {
        analytics.trackEvent(TRACK_EVENT_TYPE.START_TEST_RUN, {
          [TRACK_EVENT_ARG_TYPE.TEST_RUN_METHOD]: RUN_ENV.LOCAL,
        });
      }

      if (!extension.isActive) {
        openExtensionModal();
        return;
      }

      const sessionId = uuid();
      dispatch(
        UIStateActions.setSessionId({
          testId: test.id,
          sessionId,
        }),
      );

      start({
        ...options,
        runMode: RUN_ENV.LOCAL,
        sessionId,
      });
    },
    [extension.isActive, dispatch, test.id, start, openExtensionModal],
  );

  const resumeLocalRunner = useCallback(
    (options) => {
      analytics.trackEvent(TRACK_EVENT_TYPE.START_TEST_RUN, {
        [TRACK_EVENT_ARG_TYPE.TEST_RUN_METHOD]: RUN_ENV.LOCAL,
      });

      if (!extension.isActive) {
        openExtensionModal();
        return null;
      }

      return start({
        ...options,
        runMode: RUN_ENV.LOCAL,
        sessionId: sessionIds[test.id],
      });
    },
    [extension.isActive, start, sessionIds, test.id, openExtensionModal],
  );

  const stop = useCallback(() => {
    if (isRecording) {
      dispatch(TestActions.stopRecordingRequest(test.id, testRunId));
    } else {
      dispatch(TestActions.stopRunningRequest(test.id, { testRunId }));
    }
    dispatch(UIStateActions.disablePlaybackSelectedPositions());
  }, [test.id, dispatch, testRunId, isRecording]);

  const stopWithConfirmation = useCallback(() => {
    if (isCloudRun || ignoreStopTestAlert) {
      stop();
    } else {
      modal.show(MODAL_TYPE.STOP_TEST_SESSION, { onConfirm: stop });
    }
  }, [ignoreStopTestAlert, isCloudRun, modal, stop]);

  const runNextStep = useCallback(async () => {
    if (isNewPlaybackSupported) {
      if (!extension.isActive) {
        openExtensionModal();
        return;
      }

      await dispatchInExtension(
        CommonActions.resumeRunning({
          pauseAfterStep: true,
        }),
      );

      await sendEvent({
        type: 'runNextStepAndPause',
        eventData,
      });
    } else {
      dispatch(TestActions.debugRunNextStepRequest(test.id));
    }
  }, [
    isNewPlaybackSupported,
    extension.isActive,
    openExtensionModal,
    dispatch,
    test.id,
    sendEvent,
    eventData,
  ]);

  const pause = useCallback(async () => {
    if (isNewPlaybackSupported) {
      if (!extension.isActive) {
        openExtensionModal();
        return;
      }

      await dispatchInExtension(CommonActions.triggerPause());
      await sendEvent({
        type: 'pauseRecording',
        eventData,
      });
    } else {
      dispatch(TestActions.debugPauseTestRequest(test.id, testRunId));
    }
  }, [
    isNewPlaybackSupported,
    extension.isActive,
    sendEvent,
    eventData,
    openExtensionModal,
    dispatch,
    test.id,
    testRunId,
  ]);

  const resume = useCallback(async () => {
    if (isNewPlaybackSupported) {
      if (!extension.isActive) {
        openExtensionModal();
        return;
      }

      await dispatchInExtension(CommonActions.resumeRunning({}));
    } else {
      dispatch(TestActions.debugResumeTestRequest(test.id, testRunId));
    }
  }, [
    isNewPlaybackSupported,
    extension.isActive,
    openExtensionModal,
    dispatch,
    test.id,
    testRunId,
  ]);

  const resumeRecording = useCallback(async () => {
    await dispatchInExtension(CommonActions.resumeRecording());
  }, []);

  const record = useCallback(
    async ({ url, groupId }) => {
      analytics.trackEvent(TRACK_EVENT_TYPE.RECORDING_STARTED);

      if (!extension.isActive) {
        // TODO: Move this to UIState.
        localStorage.setUserItem(`${test.id}.initialUrl`, url);
        openExtensionModal();
        return;
      }

      const continueRecording = () => {
        // TODO: Move this to UIState.
        localStorage.removeUserItem(`${test.id}.initialUrl`);
        const sessionId = uuid();
        dispatch(
          UIStateActions.setSessionId({
            testId: test.id,
            sessionId,
          }),
        );

        dispatch(TestActions.startRecordingRequest(test.id, url, groupId, sessionId));
      };

      const hasActiveIncognitoWindows = await extension.hasActiveIncognitoWindows();
      if (hasActiveIncognitoWindows) {
        openIncognitoTabsModal(continueRecording);
        return;
      }

      continueRecording();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      extension.isActive,
      dispatch,
      test.id,
      openExtensionModal,
      openIncognitoTabsModal,
      extension.hasActiveIncognitoWindows,
    ],
  );

  const getRunnerStatus = () => {
    if (isDebugging || isPaused) {
      return 'paused';
    }

    if (isRecording) {
      return 'recording';
    }

    if (isRunning) {
      return 'running';
    }

    return 'idle';
  };

  return {
    start,
    startLocalRunner,
    resumeLocalRunner,
    stop,
    stopWithConfirmation,
    runNextStep,
    pause,
    resumeRecording,
    resume,
    record,
    incognitoMode: project.settings.incognitoMode,
    isExtensionActive: extension.isActive,
    isExtensionConnected: extension.isConnected,
    isExtensionAllowedIncognitoAccess: extension.hasIncognitoAccess,
    isIncognitoModeRequired: extension.isIncognitoModeRequired,
    isIdle,
    isRunning,
    isRecording,
    isPaused,
    isDebugging,
    isQueued,
    isCloudRun,
    isFailed: isFailedStatus(status),
    status: getRunnerStatus(),
  };
};

export default useTestRunner;
