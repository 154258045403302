import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';

import type { ActionButtonStyledProps } from './ActionButton.types';

import { CheckIcon } from '../../../../theme';
import LoaderBase from '../../../Loader';
import Button from '../../Button';
import { Content as ContentBase, IconContainer } from '../../Button.styled';

export const Container = styled(Button)<ActionButtonStyledProps>`
  position: relative;
  ${(props) =>
    !props.iconVisible &&
    css`
      padding: 0 20px;
    `}

  ${IconContainer} {
    ${(props) =>
      props.pending &&
      css`
        opacity: 0;
      `}
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.status.success};
    z-index: 0;
    opacity: 0;
    transition: opacity 300ms ease;

    ${(props) =>
      props.bordered &&
      css`
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
      `}

    ${(props) =>
      props.success &&
      css`
        opacity: 1;
      `}
  }
`;

export const Loader = styled(LoaderBase).attrs(() => ({
  size: 'small',
}))``;

const animatedWrapperStyles = css<ActionButtonStyledProps>`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  left: -14px;

  ${(props) =>
    props.iconVisible &&
    css`
      left: -28px;
      transform: none;
    `}
`;

const animatedWrapperHiddenStyles = css<ActionButtonStyledProps>`
  transition: opacity 400ms ease-in, transform 500ms ease-out;
  opacity: 0;
  transform-origin: center;
  transform: ${(props) => (props.iconVisible ? 'scale(0)' : 'translateY(-50%) scale(0)')};
`;

const animatedWrapperVisibleStyles = css<ActionButtonStyledProps>`
  opacity: 1;
  transform: translateY(-50%) scale(1);
`;

export const LoaderWrapper = styled.div<ActionButtonStyledProps>`
  ${animatedWrapperStyles};
  ${animatedWrapperHiddenStyles};

  ${(props) =>
    props.pending &&
    css`
      ${animatedWrapperVisibleStyles};
    `}
`;

export const IconWrapper = styled.div<ActionButtonStyledProps>`
  ${animatedWrapperStyles};
  top: calc(50% - 1px);

  path {
    ${animatedWrapperHiddenStyles};
    transform: scale(0);
  }

  ${(props) =>
    props.success &&
    css`
      path {
        ${animatedWrapperVisibleStyles}
        transform: scale(1);
      }
    `}
`;

export const SuccessIcon = styled(CheckIcon)`
  && {
    position: relative;
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.common.white};
  }
`;

export const Content = styled(animated(ContentBase))<ActionButtonStyledProps>`
  z-index: 1;
  transition: transform 300ms ease;

  ${({ success, pending, iconVisible }) =>
    (success || pending) &&
    !iconVisible &&
    css`
      transform: translateX(9px);
    `}

  ${({ success, theme }) =>
    success &&
    css`
      color: ${theme.colors.common.white};
    `};
`;

export const SuccessBackground = styled.i<ActionButtonStyledProps>``;

export const Wrapper = styled.div`
  position: relative;
`;
