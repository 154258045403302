import { UnescapedValue } from '@bugbug/core/theme/typography';
import { isAssertTrueFalseStep } from '@bugbug/core/types/steps';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { AssertStep } from '@bugbug/core/types/steps';
import {
  ASSERTION_TYPE_PARAMS,
  STEP_TYPE_PARAMS_LABEL,
  ASSERTION_PROPERTY,
} from '~/constants/step';
import { ellipsis } from '~/utils/text';

import { AssertParamsContainer, TextBlock } from './ParamsCell.styled';

interface AssertParamsProps {
  className?: string;
  step: AssertStep;
}

export const AssertParams = memo<AssertParamsProps>(({ className, step }) => {
  const { t } = useTranslation();
  const property = step.assertionProperty;
  const type = step.assertionType;
  const expectedValue = ellipsis(step.assertionExpectedValue?.toString());

  const shouldHideOperator =
    isAssertTrueFalseStep(step) || ASSERTION_PROPERTY[property].hiddenOperator;

  let expectedValueLabel = expectedValue || t('assertParams.empty', '(empty)');

  if (type === 'any') {
    expectedValueLabel = `(${ASSERTION_TYPE_PARAMS[type].label})`;
  }

  return (
    <AssertParamsContainer className={className}>
      <span>
        {STEP_TYPE_PARAMS_LABEL[property]}{' '}
        {shouldHideOperator ? '' : ASSERTION_TYPE_PARAMS[type]?.label}
      </span>
      {!isAssertTrueFalseStep(step) && (
        <TextBlock>
          <UnescapedValue>{expectedValueLabel}</UnescapedValue>
        </TextBlock>
      )}
    </AssertParamsContainer>
  );
});

AssertParams.displayName = 'AssertParams';
