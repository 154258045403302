import LoaderBase from '@bugbug/core/components/Loader';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import MetaValueBase from '~/components/MetaValue';

import { StepStatusCell as StepStatusBase } from '../StepStatusCell/StepStatusCell';

export const Container = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-end;
  max-width: 230px;
  width: fit-content;
  padding-left: 10px;
  flex: none;
  color: ${COLOR.GRAY_13};

  svg {
    fill: currentColor;
  }

  ${({ theme }) => theme.media.desktopWide`
    display: flex;
  `}
`;

export const Label = styled.div`
  font-weight: ${FONT_WEIGHT.MEDIUM};
  margin-right: 8px;
  padding-bottom: 1px;
`;

export const Loader = styled(LoaderBase).attrs(() => ({
  size: 'small',
}))`
  position: absolute;
  background: ${COLOR.GRAY_21};
  z-index: 1;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 8px;
  opacity: 0;

  ${styleWhenTrue(
    prop('visible'),
    css`
      opacity: 1;
    `,
  )}
`;

export const StepStatus = styled(StepStatusBase)`
  margin-right: 0;

  div > span > div {
    display: flex;
    align-items: center;
  }
`;

export const MetaValue = styled(MetaValueBase)`
  color: ${COLOR.DARK_GRAY};
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1365px) {
    display: none;
  }
`;
