import DropdownBase, {
  DROPDOWN_ANCHOR,
  ExpandIcon,
  DropdownItem as DropdownItemBase,
} from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import * as T from '@bugbug/core/utils/toolbox';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  font-weight: ${FONT_WEIGHT.MEDIUM};
  color: ${COLOR.DARK_GRAY};
  font-size: 16px;
  margin: 0 0 24px 0;
  display: flex;
`;

export const Label = styled.div`
  height: 100%;
`;

export const DropdownItem = styled(DropdownItemBase)`
  display: flex;
  align-items: center;
`;

export const Dropdown = styled(DropdownBase).attrs(() => ({
  anchor: DROPDOWN_ANCHOR.BOTTOM_START,
}))`
  max-width: 310px;
  max-height: 450px;
  height: 100%;

  button {
    border: none;
    color: ${COLOR.PRIMARY_LIGHT};
    height: 100%;
    padding-left: 8px;
    font-weight: ${FONT_WEIGHT.MEDIUM};

    svg {
      margin-top: 0;
    }
  }

  ${ExpandIcon} {
    margin-left: 1px;
    font-size: 20px;
    margin-right: -3px;
    color: ${COLOR.PRIMARY_LIGHT};
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 450px;
  overflow: auto;
`;

export const IconPlaceholder = styled.div`
  min-width: 20px;
  height: 20px;
  margin-right: 8px;
  float: left;
`;

export const ItemCheckIcon = styled(Icon).attrs(() => ({
  name: 'check',
}))`
  color: ${({ theme }) => theme.colors.text.subtle};
  top: 0;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div<{ disabled?: boolean }>`
  width: 24px;
  text-align: center;
  margin: 0 8px 0 0;
  svg {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    ${styleWhenTrue(
      T.prop('disabled'),
      css`
        filter: saturate(0);
      `,
    )}
  }
`;
