import { COLOR } from '@bugbug/core/theme/colors';
import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Title = styled(animated.span)`
  font-size: 21px;
  line-height: 23px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  color: ${COLOR.GRAY_24};

  span {
    display: block;
  }
`;

export const StateWrapper = styled(animated.div)`
  margin: 0 auto;
  width: 100%;
`;
