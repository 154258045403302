import api from './common';

export const redirect = () => api.get('/auth/user/onboarding/redirect/');

export const setMarketingConsent = (isAllowedEmailMarketing) =>
  api.patch('/auth/user/onboarding/marketing-consent/', {
    isAllowedEmailMarketing,
  });

export const setProfile = (profile) => api.patch('/auth/user/onboarding/profile/', profile);

export const setOrganization = (organization) =>
  api.patch('/auth/user/onboarding/organization/', organization);

export const skipStep = () => api.patch('/auth/user/onboarding/skip/');

export const startTrial = () => api.patch('/auth/user/onboarding/trial-start/');

export const setInitialModalOption = () => api.patch('/auth/user/onboarding/initial-modal/');
