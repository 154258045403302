import { useCallback } from 'react';

import type { Step } from '@bugbug/core/types/steps';
import { ImageWithPreview } from '~/components/ImageWithPreview/ImageWithPreview';
import * as S from '~/components/ImageWithPreview/ImageWithPreview.styled';
import { useAppSelector } from '~/modules/store';
import { selectElementScreenshot as selectStepElementScreenshot } from '~/modules/test/test.selectors';
import { selectElementScreenshot as selectStepRunElementScreenshot } from '~/modules/testRun/testRun.selectors';
import { getScreenshotUrl } from '~/utils/misc';

interface StepElementScreenshotProps {
  stepId: Step['id'];
  stepType: Step['type'];
  clickPropagationPrevented?: boolean;
  runResultEnabled;
}

export const StepElementScreenshot = ({
  stepId,
  stepType,
  clickPropagationPrevented,
  runResultEnabled,
}: StepElementScreenshotProps) => {
  const selectElementScreenshot = runResultEnabled
    ? selectStepRunElementScreenshot
    : selectStepElementScreenshot;

  const sourceUrl = useAppSelector(selectElementScreenshot(stepId)) as string | undefined;
  const imageUrl = getScreenshotUrl(sourceUrl);

  const handleImageClick = useCallback(
    (event) => {
      if (clickPropagationPrevented) {
        event.stopPropagation();
      }
    },
    [clickPropagationPrevented],
  );

  return imageUrl ? (
    <ImageWithPreview src={imageUrl} alt={stepType} onClick={handleImageClick} />
  ) : (
    <S.TextContainer>-</S.TextContainer>
  );
};
