import { UnescapedValue } from '@bugbug/core/theme/typography';
import {
  getActiveSelectorsPreset,
  getFullSelectorPathFromPreset,
} from '@bugbug/core/utils/selectors';
import { memo } from 'react';
import { Trans } from 'react-i18next';

import type { SwitchContextStep } from '@bugbug/core/types/steps';

import { SwitchContextParamsContainer, TextBlock } from './ParamsCell.styled';

interface SwitchContextParamsProps {
  className?: string;
  step: SwitchContextStep;
}

export const SwitchContextParams = memo<SwitchContextParamsProps>(({ className, step }) => {
  const tab = step.tabNo;
  const activePreset = getActiveSelectorsPreset(step.selectorsPresets);
  const framePath = activePreset ? getFullSelectorPathFromPreset(activePreset) : '';

  return (
    <SwitchContextParamsContainer className={className}>
      <Trans i18nKey="stepParams.switchContext.content">
        <div>tab:</div>
        <TextBlock>{{ tab }}</TextBlock>
        <div>frame:</div>
        <TextBlock>
          <UnescapedValue value={framePath} />
        </TextBlock>
      </Trans>
    </SwitchContextParamsContainer>
  );
});

SwitchContextParams.displayName = 'SwitchContextParams';
