import Button, { ActionButton, LinkButton } from '@bugbug/core/components/Button';
import { useTranslation } from 'react-i18next';

import { SCREEN_RESOLUTION_TYPE } from '~/constants/test';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { ONBOARDING_STEP } from '~/modules/constans';
import {
  selectCurrentOrganizationId,
  selectIsOrganizationAdmin,
} from '~/modules/organization/organization.selectors';
import { selectDefaultProfile } from '~/modules/profile/profile.selectors';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { UserActions } from '~/modules/user/user.redux';
import { selectIsInvited } from '~/modules/user/user.selectors';
import analytics, { TRACK_EVENT_ARG_TYPE, TRACK_EVENT_ARG_VALUE } from '~/services/analytics';
import { OnboardingTitle } from '~/views/Onboarding/Onboarding.styled';

import * as S from './OnboardingModal.styled';
import { TutorialSection } from './TutorialSection';
import { TutorialVideo } from './TutorialVideo';

import BillingIcon from '~/images/billing-icon.svg?react';
import DocsIcon from '~/images/docs-icon.svg?react';
import TestPlusIcon from '~/images/test-plus-icon.svg?react';

const OnboardingModal = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'onboardingModal' });
  const isInvited = useAppSelector(selectIsInvited);
  const isAdmin = useAppSelector(selectIsOrganizationAdmin);
  const modal = useModal();
  const defaultProfile = useAppSelector(selectDefaultProfile);
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector(selectCurrentOrganizationId);

  const createFirstTest = () => {
    trackButtonClick('record_test');
    dispatch(
      TestActions.createRequest({
        name: t('firstTestName', 'My first test'),
        profileId: defaultProfile?.id,
        screenSizeType: SCREEN_RESOLUTION_TYPE.DESKTOP,
      }),
    );
    dispatch(UserActions.skipOnboardingStepSuccess(ONBOARDING_STEP.SELECTED_INITIAL_MODAL_OPTION));
  };

  const { isLoading: isCreatingTest } = useActionState(TestActions.createRequest, {
    onSuccess: modal.hide,
  });

  const { isLoading: isSelectingModalOption } = useActionState(
    UserActions.selectInitialModalOptionRequest,
    {
      onSuccess: modal.hide,
    },
  );

  const trackButtonClick = (
    placement: 'watch_tutorial' | 'manage_billing' | 'record_test' | 'not_now' | 'read_docs',
  ) => {
    analytics.trackEvent('app_entry_modal_clicked', {
      [TRACK_EVENT_ARG_TYPE.ORGANIZATION_ID]: organizationId,
      [TRACK_EVENT_ARG_TYPE.CONTEXT]: 'onboarding',
      [TRACK_EVENT_ARG_TYPE.USER_TYPE]: isInvited
        ? TRACK_EVENT_ARG_VALUE.USER_TYPE_INVITED
        : TRACK_EVENT_ARG_VALUE.USER_TYPE_OWNER,
      [TRACK_EVENT_ARG_TYPE.BUTTON_CLICK]: placement,
    });
  };

  const goToSubscriptions = () => {
    trackButtonClick('manage_billing');
    dispatch(UserActions.selectInitialModalOptionRequest({ option: 'manage_billing' }));
  };

  const showVideo = () => {
    trackButtonClick('watch_tutorial');
    modal.showCustom(TutorialVideo, {
      stacked: true,
      closeButtonHidden: true,
    });
  };

  const hide = () => {
    trackButtonClick('not_now');
    dispatch(UserActions.selectInitialModalOptionRequest({ option: 'not_now' }));
  };

  return (
    <S.Container>
      <OnboardingTitle>{t('title', 'Where would you like to start?')}</OnboardingTitle>
      <S.SectionsContainer>
        <TutorialSection
          description={t(
            'tutorial.description',
            'See how Paul records a simple user registration test.',
          )}
          icon={
            <S.ThumbnailTutorial
              alt={t('tutorial.imgAlt', 'Tutorial thumbnail')}
              onClick={showVideo}
            />
          }
          button={
            <Button variant="primary" bordered onClick={showVideo}>
              {t('tutorial.watchTutorial', 'Watch the tutorial')}
            </Button>
          }
        />
        {isAdmin && isInvited && (
          <TutorialSection
            description={t(
              'billing.description',
              'Are you here to upgrade BugBug to the paid plan?',
            )}
            icon={<BillingIcon />}
            button={
              <Button variant="primary" disabled={isCreatingTest} onClick={goToSubscriptions}>
                {t('billing.manageBilling', 'Manage billing')}
              </Button>
            }
          />
        )}
        {!isAdmin && isInvited && (
          <TutorialSection
            description={t(
              'docs.description',
              "Read our ultimate beginner's guide to automation testing.",
            )}
            icon={<DocsIcon />}
            button={
              <LinkButton
                to={t(
                  'beginnersTutorial',
                  'https://docs.bugbug.io/in-depth-guides/beginners-tutorial-to-automation-testing',
                )}
                openNewTab
                onClick={() => trackButtonClick('read_docs')}
                button={{
                  variant: 'primary',
                  disabled: isCreatingTest,
                }}
              >
                {t('docs.readOurGuide', 'Read our guide')}
              </LinkButton>
            }
          />
        )}
        {isAdmin && !isInvited && (
          <TutorialSection
            description={t(
              'record.description',
              'Create a new test by recording, replay and see if your test passed.',
            )}
            icon={<TestPlusIcon />}
            button={
              <ActionButton
                variant="primary"
                onClick={createFirstTest}
                pending={isCreatingTest}
                disabled={isSelectingModalOption}
              >
                {t('record.recordTest', 'Record a test')}
              </ActionButton>
            }
          />
        )}
      </S.SectionsContainer>
      <Button variant="default" onClick={hide} disabled={isCreatingTest || isSelectingModalOption}>
        {t('close', 'Not now, I want to explore on my own.')}
      </Button>
    </S.Container>
  );
};

export default OnboardingModal;
