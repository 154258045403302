import brand24 from '~/images/brands/brand24.svg';
import innential from '~/images/brands/innential.svg';
import weglot from '~/images/brands/weglot.png';
import zahara from '~/images/brands/zahara.png';
import i18n from '~/translations';

export const WEGLOT_LOGO = {
  logoUrl: weglot,
  alt: i18n.t('brands.weglot.alt', 'WeGlot'),
};

export const BRAND24_LOGO = {
  logoUrl: brand24,
  alt: i18n.t('brands.brand24.alt', 'Brand24'),
};

export const ZAHARA_LOGO = {
  logoUrl: zahara,
  alt: i18n.t('brands.zahara.alt', 'Zahara'),
};

export const INNENTIAL_LOGO = {
  logoUrl: innential,
  alt: i18n.t('brands.innential.alt', 'Innential'),
};

export const BRANDS_LIST = [WEGLOT_LOGO, BRAND24_LOGO, ZAHARA_LOGO, INNENTIAL_LOGO];
