import Button from '@bugbug/core/components/Button';
import { H3 } from '@bugbug/core/theme/typography';
import * as T from '@bugbug/core/utils/toolbox';
import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import type { MouseEventHandler } from 'react';

import type { PaidFeatureName } from '@bugbug/core/types/paidFeatures';
import type { SideEffect } from '@bugbug/core/types/utils';
import useAppRoutes from '~/hooks/useAppRoutes';
import {
  selectCurrentOrganizationId,
  selectIsFreePlan,
} from '~/modules/organization/organization.selectors';
import { useAppSelector } from '~/modules/store';
import analytics, { TRACK_EVENT_ARG_TYPE } from '~/services/analytics';

import * as S from './PaidFeatureGuard.styled';

interface PaidFeatureBannerProps {
  className?: string;
  fullInfo?: boolean;
  messageFor?: string;
  onRedirect?: SideEffect;
  onUpgradeClick?: MouseEventHandler;
}

export const PaidFeatureBanner = ({
  className,
  fullInfo = false,
  messageFor,
  onUpgradeClick,
  onRedirect = T.noop,
}: PaidFeatureBannerProps) => {
  const { t } = useTranslation();

  const organizationId = useAppSelector(selectCurrentOrganizationId);

  const featureLabels: Record<PaidFeatureName, string> = {
    alerts: t('paidFeatureBanner.messageIntro.alerts', 'Alerts are'),
    execute: t('paidFeatureBanner.messageIntro.execute', 'Custom JavaScript steps are'),
    integrations: t('paidFeatureBanner.messageIntro.integrations', 'Integrations are'),
    'run test via cli': t('paidFeatureBanner.messageIntro.runTestViaCli', 'Run Test via CLI is'),
    'run suite via cli': t('paidFeatureBanner.messageIntro.runSuiteViaCli', 'Run Suite via CLI is'),
    schedules: t('paidFeatureBanner.messageIntro.schedules', 'Schedules are'),
    setLocalVariable: t(
      'paidFeatureBanner.messageIntro.setLocalVariable',
      'Set Variable steps are',
    ),
    variables: t('paidFeatureBanner.messageIntro.variables', 'Variables are'),
  };

  const isFreePlan = useAppSelector(selectIsFreePlan);
  const isPaidFeature = messageFor && messageFor in featureLabels;

  const { push } = useAppRoutes('subscription');

  const handleOnClick = useCallback<MouseEventHandler>(
    (event) => {
      if (isPaidFeature) {
        analytics.trackEvent('upgrade_button_clicked', {
          [TRACK_EVENT_ARG_TYPE.ORGANIZATION_ID]: organizationId,
          [TRACK_EVENT_ARG_TYPE.CONTEXT]: messageFor,
        });
      }

      if (onUpgradeClick) {
        onUpgradeClick(event);
      } else {
        onRedirect();
        push('subscription', { organizationId });
      }
    },
    [isPaidFeature, messageFor, onRedirect, onUpgradeClick, organizationId, push],
  );

  const feature = featureLabels[messageFor as PaidFeatureName];

  return isFreePlan && isPaidFeature ? (
    <S.Container fullInfo={fullInfo} className={className}>
      {fullInfo && <S.MoneyIcon />}

      <S.TextContainer>
        <H3>{t('paidFeatureBanner.header.label', 'Upgrade to use this feature')}</H3>

        <S.RegularParagraph>
          <Trans i18nKey="paidFeatureBanner.mainContent" values={{ feature }}>
            {{ feature }} only available in the PRO BugBug plan. Starting from
            <S.NonBreakingLink onClick={handleOnClick}>$99 / month</S.NonBreakingLink>.
          </Trans>
          <br />
          {fullInfo && (
            <Trans i18nKey="paidFeatureBanner.additionalContent" values={{ feature }}>
              Multiple productivity features included - feel free to
              <S.NonBreakingLink to={t('default.urls.bookDemoMeeting')}>
                book a demo
              </S.NonBreakingLink>
              .
            </Trans>
          )}
        </S.RegularParagraph>
      </S.TextContainer>

      <S.ButtonContainer>
        <Button iconName="upgrade" onClick={handleOnClick} variant="primary">
          {t('paidFeatureBanner.upgradeButtonLabel', 'Upgrade')}
        </Button>
      </S.ButtonContainer>
    </S.Container>
  ) : null;
};
