import { memo, useCallback } from 'react';

import * as S from './ExpanderButton.styled';

interface ExpanderButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

export const ExpanderButton = memo(
  ({ className, active, onClick, ...buttonProps }: ExpanderButtonProps) => {
    const handleClick = useCallback(
      (event) => {
        if (onClick) {
          event.stopPropagation();
          onClick(event);
        }
      },
      [onClick],
    );

    return (
      <S.ExpanderContainer
        className={className}
        active={active}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
        onClick={handleClick}
        data-testid="ExpanderButton"
      >
        <S.Expander />
      </S.ExpanderContainer>
    );
  },
);

ExpanderButton.displayName = 'ExpanderButton';
