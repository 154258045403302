import { isError } from '@bugbug/core/utils/toolbox';
import {
  INTERNAL_SERVER_ERROR,
  FORBIDDEN,
  NOT_FOUND,
  PAYMENT_REQUIRED,
  BAD_REQUEST,
} from 'http-status';
import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { AxiosError } from 'axios';
import type { ActionCreator } from 'redux';
import type { ActionTypes } from 'reduxsauce';

import type { APIError } from '@bugbug/core/types/api';
import type { Maybe, SideEffect } from '@bugbug/core/types/utils';
import { getBaseActionName } from '~/modules/actionState/actionState.redux';
import selectActionState from '~/modules/actionState/actionState.selectors';
import { extractErrors } from '~/utils/apiErrorHandler';

const SHOW_SUCCESS_TIMEOUT = 3000;

interface ActionState {
  isSuccess: boolean;
  isFailure: boolean;
  errors: AxiosError<APIError[] | APIError>;
  isLoading: boolean;
}

export interface UseActionStateParams {
  reset?: boolean;
  reqId?: string;
  onSuccess?: SideEffect;
  onFailure?: (
    extractedErrors: Record<string, string>,
    errorStatus: number | null,
    flags: {
      hasInternalServerError: boolean;
      isForbidden: boolean;
      isNotFound: boolean;
      isPaymentRequired: boolean;
    },
    rawErrors?: AxiosError<APIError[] | APIError>,
  ) => void;
}

interface UseActionStateParamsReturn {
  reset: SideEffect;
  isSuccess: boolean;
  isFailure: boolean;
  errors: Record<string, string>;
  isLoading: boolean;
  hasInternalServerError: boolean;
  isPaymentRequired: boolean;
  isForbidden: boolean;
  isNotFound: boolean;
  hasBadRequestError: boolean;
  rawErrors: Maybe<Record<string, unknown> | Record<string, unknown>[]>;
}

export default (
  actionCreator: ActionCreator<ActionTypes>,
  params: UseActionStateParams = {},
): UseActionStateParamsReturn => {
  if (!actionCreator) {
    throw new Error('actionCreator is required');
  }

  const dispatch = useDispatch();
  const { reset = true, reqId = null, onSuccess, onFailure } = params;
  const actionState = useSelector(
    selectActionState(actionCreator, { reqId }),
  ) as unknown as ActionState; // TODO: fix after migration to TS

  const { isSuccess, isFailure, errors, isLoading } = actionState;
  const baseActionName = useMemo(
    () => getBaseActionName(actionCreator().type, { reqId }),
    [actionCreator, reqId],
  );
  const resetHandler = useCallback(
    () => dispatch({ type: `${baseActionName}_RESET` }),
    [dispatch, baseActionName],
  );
  const extractedErrors = useMemo(() => (errors ? extractErrors(errors) : errors), [errors]);
  const errorStatus = errors && errors.response ? errors.response.status : null;
  const hasInternalServerError =
    !isLoading && !!errorStatus && errorStatus >= INTERNAL_SERVER_ERROR;
  const hasBadRequestError = !isLoading && !!errorStatus && errorStatus >= BAD_REQUEST;
  const isForbidden = !isLoading && errorStatus === FORBIDDEN;
  const isNotFound = !isLoading && errorStatus === NOT_FOUND;
  const isPaymentRequired = !isLoading && errorStatus === PAYMENT_REQUIRED;

  useEffect(() => {
    let timeoutSuccessFunc;

    if (isSuccess && onSuccess) {
      onSuccess();
    }
    if (isFailure && onFailure) {
      onFailure(extractedErrors, errorStatus, {
        hasInternalServerError,
        isForbidden,
        isNotFound,
        isPaymentRequired,
      });
    }
    if ((isSuccess || isFailure) && reset) {
      timeoutSuccessFunc = setTimeout(() => {
        resetHandler();
      }, SHOW_SUCCESS_TIMEOUT);
    }
    return () => {
      clearTimeout(timeoutSuccessFunc);
    };
  }, [
    isSuccess,
    isFailure,
    isLoading,
    dispatch,
    baseActionName,
    reset,
    onFailure,
    onSuccess,
    extractedErrors,
    resetHandler,
    errorStatus,
    hasInternalServerError,
    isForbidden,
    isNotFound,
    isPaymentRequired,
  ]);

  useEffect(
    () => () => {
      if (reset) {
        resetHandler();
      }
    },
    [reset, resetHandler],
  );

  const rawErrors = useMemo(() => {
    if (!errors || !isError(errors) || !errors.response) return null;
    return errors.response.data as unknown as Maybe<
      Record<string, unknown> | Record<string, unknown>[]
    >;
  }, [errors]);

  return {
    isSuccess,
    isFailure,
    errors: extractedErrors,
    rawErrors,
    isLoading,
    hasInternalServerError,
    isPaymentRequired,
    isForbidden,
    isNotFound,
    hasBadRequestError,
    reset: resetHandler,
  };
};
