import styled from 'styled-components';

export const SeparatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const SeparatorLine = styled.div`
  width: 100%;
  flex: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border.medium};
`;

export const SeparatorText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.secondary};
  text-transform: uppercase;
`;
