import { ActionButton } from '@bugbug/core/components/Button';
import CheckboxBase from '@bugbug/core/components/Checkbox';
import { ErrorMessage as ErrorMessageBase } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

export const SubmitButton = styled(ActionButton)`
  margin: 0;
  justify-content: center;

  div {
    flex: initial;
  }
`;

export const Checkbox = styled(CheckboxBase)`
  margin: 0;
  width: 100%;
`;

export const ErrorMessage = styled(ErrorMessageBase)`
  a {
    text-decoration: underline;
    color: inherit;
  }
`;
