import { pick } from '@bugbug/core/utils/toolbox';
import { useMemo } from 'react';

import type { Group } from '@bugbug/core/types/groups';
import type { Step } from '@bugbug/core/types/steps';

import { useRowActiveBorder } from '../RowActiveBorder/RowActiveBorderContext';

import * as S from './Step.styled';

interface ActiveBorderWrapperProps {
  children: React.ReactNode;
  active: boolean;
  step: Step;
  group?: Group;
  stepIndex: number;
  groupIndex: number;
  getGroupOffset: () => number;
  last: boolean;
}

export const ActiveBorderWrapper = ({
  children,
  active,
  getGroupOffset,
  step,
  group,
  stepIndex,
  groupIndex,
  last,
}: ActiveBorderWrapperProps) => {
  const config = useMemo(() => {
    if (!group) {
      return {
        props: {},
      };
    }

    const commonProps = {
      row: pick(['id', 'groupId', 'type', 'isActive'], step),
      testsCount: group.testsCount,
      isComponent: group.isComponent,
      disabledGroupOptions: true,
    };

    return {
      modifiers: { x: 2 },
      props: {
        top: {
          ...commonProps,
          index: stepIndex,
          disabledRunAndStop: stepIndex === 0,
        },
        bottom: !last
          ? {
              ...commonProps,
              index: stepIndex + 1,
            }
          : {
              row: group,
              index: groupIndex,
              disabledSplitting: true,
            },
      },
    };
  }, [group, stepIndex, last, step, groupIndex]);

  const { onItemEnter, onItemLeave } = useRowActiveBorder(config, getGroupOffset);

  return (
    <S.Wrapper
      data-testid="Step.Wrapper"
      onMouseEnter={active ? onItemEnter : undefined}
      onMouseLeave={active ? onItemLeave : undefined}
    >
      {children}
    </S.Wrapper>
  );
};
