import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { PrintContent } from './PrintableDocument.types';

import { selectCurrentOrganization } from '~/modules/organization/organization.selectors';

import { PrintContextProvider } from './PrintableDocument.helpers';
import * as S from './PrintableDocument.styled';

interface PrintableDocumentProps {
  className?: string;
  children?: React.ReactNode;
}

const PrintableDocument = ({ className, children }: PrintableDocumentProps) => {
  const organization = useSelector(selectCurrentOrganization);
  const [content, setContent] = useState<PrintContent>({});

  const handleSetContent = (newContent: PrintContent) => {
    setContent((currentContent) => ({
      title: newContent.title ?? currentContent.title,
      subTitle: newContent.subTitle ?? currentContent.subTitle,
      description: newContent.description ?? currentContent.description,
    }));
  };

  const context = useMemo(
    () => ({
      print: () => window.print(),
      setContent: handleSetContent,
    }),
    [],
  );

  return (
    <PrintContextProvider value={context}>
      <S.Document className={className}>
        <S.PrintStyles />
        <S.DocumentHeader>
          <S.Meta>
            <S.Author>
              {organization?.name && <span>{organization.name}</span>}
              <span>{dayjs().format('DD MMMM YYYY')}</span>
            </S.Author>
            <S.Copyright>
              <span>
                <Trans i18nKey="printableDocument.copyRight">
                  Testing tool
                  <br />
                  bugbug.io
                </Trans>
              </span>
              <S.Logo />
            </S.Copyright>
          </S.Meta>
          <h1>
            {content.title ?? document.title}
            {content.subTitle && <span>{content.subTitle}</span>}
          </h1>
          {content.description && <p>{content.description}</p>}
        </S.DocumentHeader>
        {children}
      </S.Document>
    </PrintContextProvider>
  );
};

export default PrintableDocument;
