import Select, { SelectOption } from '@bugbug/core/components/Select';
import * as T from '@bugbug/core/utils/toolbox';
import * as msTeams from '@microsoft/teams-js';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ConfigurationStepData } from '../Teams.types';
import type { SelectChangeEvent } from '@mui/material';

import type { ConnectTeamsAPIIntegration } from '@bugbug/core/types/integrations';
import type { SideEffect } from '@bugbug/core/types/utils';
import FormField from '~/components/FormField';
import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import { useConnectMutation, useDisconnectMutation } from '~/modules/integrations/integrations.api';
import { useGetProjectsInAllOrgsQuery } from '~/modules/projects/projects.api';
import { useAppSelector } from '~/modules/store';
import { selectIntegrationTrigger } from '~/modules/uiState/uiState.selectors';
import { getExtractedErrorMessages, isApiError } from '~/services/api/utils';

import { CUSTOM_ERROR_MESSAGE } from './TeamsConfig.constants';
import { getProjectItemName, getTeamsAppData } from './TeamsConfig.helpers';
import * as S from './TeamsConfig.styled';

interface ConfigurationStepProps {
  onValidityChange: (isValid: boolean) => void;
  onSaveHandlerRegister: SideEffect<msTeams.pages.saveEventType>;
  onRemoveHandlerRegister: SideEffect<msTeams.pages.removeEventType>;
}

export const ConfigurationStep = ({
  onValidityChange,
  onSaveHandlerRegister,
  onRemoveHandlerRegister,
}: ConfigurationStepProps) => {
  const { t } = useTranslation();
  const appRoute = useAppRoutes('integrationConfig');
  const queryParams = useQueryString();
  const integrationTrigger = useAppSelector(selectIntegrationTrigger);
  const [connect] = useConnectMutation();
  const [disconnect] = useDisconnectMutation();
  const { data: projects = [] } = useGetProjectsInAllOrgsQuery();
  const [connectionData, setConnectionData] = useState<ConfigurationStepData>({
    type: 'teams',
    projectId: queryParams.projectId ?? integrationTrigger?.params?.projectId ?? '',
    error: '',
  });

  const handleRequestError = useCallback((appEvent, error) => {
    Sentry.captureException(error);

    let errorMessage = error instanceof Error ? error.message : undefined;
    if (isApiError(error)) {
      const errorMessages = getExtractedErrorMessages(error);
      [errorMessage] = Object.values(errorMessages);

      if (errorMessages.teamsIntegrationAlreadyExists) {
        setConnectionData((prev) => ({
          ...prev,
          error: CUSTOM_ERROR_MESSAGE.teamsIntegrationAlreadyExists,
        }));
      }
    }
    appEvent.notifyFailure(errorMessage);
  }, []);

  const handleMissingProject = useCallback(
    (appEvent) => {
      setConnectionData((prev) => ({
        ...prev,
        error: t(
          'integrationProcess.teams.config.project.noProjectSelected',
          'This field is required',
        ),
      }));
      appEvent.notifyFailure();
    },
    [t],
  );

  const handleSave = useCallback<msTeams.pages.saveEventType>(
    async (saveEvent) => {
      const { projectId } = connectionData;
      if (!projectId) {
        handleMissingProject(saveEvent);
        return;
      }

      const project = projects.find(T.propEq('id', connectionData.projectId))!;

      if (!project.hasPaidSubscription) {
        handleRequestError(
          saveEvent,
          new Error(
            t(
              'integrationProcess.teams.config.project.paidPlan',
              'This project belongs to organization with free plan, so it cannot be integrated with Microsoft Teams.',
            ),
          ),
        );
        return;
      }
      const configName = getProjectItemName(project);
      const contentUrl = appRoute.getAbsoluteRouteUrl(
        'integrationConfig',
        { type: 'teams' },
        { action: 'edit', projectId },
      );
      const removeUrl = appRoute.getAbsoluteRouteUrl(
        'integrationConfig',
        { type: 'teams' },
        { action: 'remove', projectId },
      );

      try {
        await msTeams.pages.config.setConfig({
          entityId: projectId,
          // @ts-expect-error - TS doesn't know about this field
          configName,
          contentUrl,
          removeUrl,
        });

        const teamsAppData = await getTeamsAppData();
        const connectConfig: ConnectTeamsAPIIntegration = {
          ...teamsAppData,
          ...T.omit(['error'], connectionData),
        };

        await connect(connectConfig).unwrap();
        saveEvent.notifySuccess();
      } catch (error) {
        handleRequestError(saveEvent, error);
      }
    },
    [connectionData, projects, appRoute, handleMissingProject, handleRequestError, t, connect],
  );

  const handleRemove = useCallback<msTeams.pages.removeEventType>(
    async (removeEvent) => {
      if (!connectionData.projectId) {
        handleMissingProject(removeEvent);
        return;
      }

      try {
        await disconnect(connectionData).unwrap();
        removeEvent.notifySuccess();
      } catch (error) {
        handleRequestError(removeEvent, error);
      }
    },
    [connectionData, handleMissingProject, disconnect, handleRequestError],
  );

  const handleProjectChange: SideEffect<SelectChangeEvent> = (event) => {
    setConnectionData((prev) => ({ ...prev, error: '', projectId: event.target.value }));
  };

  useEffect(() => {
    // Set the first project as default if there is no project selected
    if (!connectionData.projectId && projects?.length) {
      setConnectionData((prev) => ({ ...prev, projectId: projects[0].id }));
    }
  }, [connectionData.projectId, onValidityChange, projects]);

  useEffect(() => {
    if (queryParams.action === 'edit') {
      onValidityChange(queryParams.projectId !== connectionData.projectId);
      return;
    }
    onValidityChange(!!connectionData.projectId);
  }, [connectionData.projectId, onValidityChange, queryParams.action, queryParams.projectId]);

  useEffect(() => {
    // Setup new event handlers for the Teams app on each project change
    onSaveHandlerRegister(handleSave);
    onRemoveHandlerRegister(handleRemove);
  }, [handleRemove, handleSave, onRemoveHandlerRegister, onSaveHandlerRegister]);

  return (
    <FormField
      counter="2"
      label={t('integrationProcess.teams.config.project.label', 'Select project')}
      description={t(
        'integrationProcess.teams.config.project.description',
        'Choose a BugBug project from organization with paid plan to integrate with Microsoft Teams.',
      )}
    >
      <S.Step>
        <Select
          onChange={handleProjectChange}
          value={connectionData.projectId}
          disabled={!projects.length}
          placeholder={t('integrationProcess.teams.config.project.placeholder', 'Select a project')}
          error={connectionData.error}
        >
          {projects.map((project) => (
            <SelectOption
              key={project.id}
              value={project.id}
              disabled={!project.hasPaidSubscription}
            >
              {getProjectItemName(project)}
            </SelectOption>
          ))}
        </Select>
      </S.Step>
    </FormField>
  );
};
