import Link from '@bugbug/core/components/Link';
import { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import PublicPage from '~/components/PublicPage';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useAppSelector } from '~/modules/store';
import { selectIsUserLoggedIn } from '~/modules/user/user.selectors';

import { AuthLayout } from '../AuthLayout/AuthLayout';

import { ForgotAccountParagraph } from './Login.styled';
import { LoginForm } from './LoginForm/LoginForm';

export const Login = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'auth.login' });
  const appRoutes = useAppRoutes('login');
  const isLoggedIn = useAppSelector(selectIsUserLoggedIn);

  useLayoutEffect(() => {
    if (isLoggedIn) {
      appRoutes.push('home');
    }
  }, [isLoggedIn, appRoutes]);

  if (isLoggedIn) {
    return null;
  }

  return (
    <PublicPage>
      <Helmet
        title={t('pageTitle', 'BugBug login')}
        link={[{ rel: 'canonical', href: appRoutes.getAbsoluteRouteUrl('login') }]}
      />
      <AuthLayout
        title={t('title', 'Log in')}
        subTitle={t('subTitle', 'Sign in to your BugBug account')}
        redirectInfo={
          <>
            {t('registerAccountQuestion', "Don't have an account? ")}
            <Link to={appRoutes.getRouteUrl('signUp')}>{t('signUpLink', 'Sign up')}</Link>
          </>
        }
        form={
          <div>
            <LoginForm />
            <ForgotAccountParagraph>
              <Link to={appRoutes.getRouteUrl('passwordReset')}>
                {t('passwordResetLink', 'Forgot password?')}
              </Link>
            </ForgotAccountParagraph>
          </div>
        }
      />
    </PublicPage>
  );
};
