import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import memoize from 'lodash.memoize';
import { prop } from 'ramda';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import ServerErrorInfo from '~/components/ServerErrorInfo';
import { Pagination, SimpleTable } from '~/components/Table';
import useActionState from '~/hooks/useActionState';
import useQueryString from '~/hooks/useQueryString';
import { useRecentSort } from '~/hooks/useRecentSort';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import { selectCurrentPageParams, selectTestRunList } from '~/modules/testRun/testRun.selectors';
import { RUNS_HISTORY_DEFAULT_SORT } from '~/views/RunsHistory/RunsHistory.constants';
import urls, { reverse } from '~/views/urls';

import { TESTS_HISTORY_COLUMNS } from './TestsHistory.constants';
import { Container, LoaderContainer, TableEmptyState } from './TestsHistory.styled';

const TestsHistory = forwardRef(({ className, onSelectionChange, sortByConfiguration }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef();

  const testRuns = useSelector(selectTestRunList);
  const urlParams = useParams();
  const queryString = useQueryString();
  const location = useLocation();
  const { pagination } = useSelector(selectCurrentPageParams);

  const { isLoading, hasInternalServerError, reset, hasBadRequestError } = useActionState(
    TestRunActions.getListRequest,
    {
      reset: false,
    },
  );

  useUnmount(() => {
    reset();
  });

  const { updateRecentSort, ...recentSort } = useRecentSort({
    cacheName: 'testsHistoryList',
    defaultConfig: RUNS_HISTORY_DEFAULT_SORT,
    columns: TESTS_HISTORY_COLUMNS,
  });

  const handleDataUpdate = useCallback(
    (sortConfig, page = 1) => {
      dispatch(
        TestRunActions.getListRequest(page, queryString.query, sortConfig.sortBy, sortConfig.desc),
      );
      updateRecentSort({ ...sortConfig, query: queryString.query, page });
    },
    [dispatch, updateRecentSort, queryString.query],
  );

  const handleDeselectAll = useCallback(() => {
    tableRef.current.toggleAllRowsSelected(false);
  }, []);

  useUpdateEffect(() => {
    handleDataUpdate(sortByConfiguration);
  }, [sortByConfiguration, handleDataUpdate]);

  const handlePageChange = useCallback(
    (page) => {
      handleDataUpdate(recentSort, page);
      handleDeselectAll();
    },
    [handleDataUpdate, recentSort, handleDeselectAll],
  );

  const refresh = useCallback(() => {
    handleDataUpdate(recentSort, queryString.page);
  }, [handleDataUpdate, recentSort, queryString.page]);

  const fetchInitialData = useCallback(() => {
    handleDataUpdate(recentSort, queryString.page);
  }, [handleDataUpdate, recentSort, queryString.page]);

  useMount(() => {
    fetchInitialData();
  });

  useImperativeHandle(
    ref,
    () => ({
      deselectAll: handleDeselectAll,
      refresh,
    }),
    [refresh, handleDeselectAll],
  );

  const renderLoader = renderWhenTrue(() => (
    <LoaderContainer>
      <Loader size="large" />
    </LoaderContainer>
  ));

  /* eslint-disable-next-line react/no-unstable-nested-components, react/function-component-definition, react/display-name */
  const renderEmptyState = renderWhenTrue(() => () => {
    if (hasInternalServerError || hasBadRequestError) {
      return (
        <ServerErrorInfo
          isVisible={hasInternalServerError || hasBadRequestError}
          onRetry={handleDataUpdate}
          hideRetryButton={hasBadRequestError}
        />
      );
    }

    return <TableEmptyState>{renderLoader(true)}</TableEmptyState>;
  });

  const getExtraRowProp = memoize((row) => {
    const pathname = reverse(urls.testRun, {
      projectId: urlParams.projectId,
      projectSlug: urlParams.projectSlug,
      organizationId: urlParams.organizationId,
      testRunId: row.id,
    });

    return {
      to: { pathname, state: { backUrl: `${location.pathname}${location.search}` } },
    };
  }, prop('id'));

  return (
    <Container className={className} data-testid="TestsHistory">
      <Helmet title={t('testsHistory.pageTitle', 'Test runs history')} />
      <ServerErrorInfo
        isVisible={hasInternalServerError || hasBadRequestError}
        onRetry={refresh}
        hideRetryButton={hasBadRequestError}
      />
      <Pagination
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={pagination.pageSize}
        onChange={handlePageChange}
      >
        <SimpleTable
          ref={tableRef}
          columns={TESTS_HISTORY_COLUMNS}
          data={testRuns}
          onSelectionChange={onSelectionChange}
          getRowProps={getExtraRowProp}
          emptyStateText={t('testsHistory.placeholder', "You haven't run any tests yet")}
          renderEmptyState={renderEmptyState(
            isLoading || hasInternalServerError || hasBadRequestError,
          )}
          disabledWindowing
        />
      </Pagination>
    </Container>
  );
});

TestsHistory.displayName = 'TestsHistory';

export default TestsHistory;
