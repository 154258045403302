import { useTranslation } from 'react-i18next';

import Beta from '~/components/Beta';
import { EXPERIMENTAL_STEPS, STEP_TYPE_LABEL } from '~/constants/step';

import { Container, Badge } from './StepName.styled';

// TODO: migrate to TS
const StepName = ({ className = '', type = '', assertType = '', added = false }) => {
  const { t } = useTranslation();

  const label = STEP_TYPE_LABEL[assertType || type];

  return (
    <Container className={className}>
      {added && <Badge>{t('stepBadge.new', 'new')}</Badge>}
      {label} {EXPERIMENTAL_STEPS.includes(type) && <Beta />}
    </Container>
  );
};

export default StepName;
