import { UnescapedValue } from '@bugbug/core/theme/typography';
import { memo } from 'react';
import { Trans } from 'react-i18next';

import type { SelectStep } from '@bugbug/core/types/steps';

import { SelectParamsContainer, TextBlock } from './ParamsCell.styled';

interface SelectParamsProps {
  className?: string;
  step: SelectStep;
}

export const SelectParams = memo<SelectParamsProps>(({ className, step }) => {
  const value = step.value?.replaceAll('\n', ', ');
  const type = step.selectType;

  return (
    <SelectParamsContainer className={className}>
      <Trans i18nKey="stepParams.select.content">
        <span>by {{ type }}</span>
        <TextBlock>
          <UnescapedValue value={value} />
        </TextBlock>
      </Trans>
    </SelectParamsContainer>
  );
});

SelectParams.displayName = 'SelectParams';
