import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PaidFeature } from '~/components/PaidFeatureGuard';
import useAppRoutes from '~/hooks/useAppRoutes';

const AlertActions = () => {
  const { t } = useTranslation();
  const appRoutes = useAppRoutes('alerts');

  const handleNewAlertClick = useCallback(() => {
    appRoutes.push('newAlert');
  }, [appRoutes]);

  return (
    <PaidFeature feature="alerts">
      {(isDisabled) => (
        <Button
          data-testid="AlertActions"
          disabled={isDisabled}
          iconName="addCircle"
          onClick={handleNewAlertClick}
          variant={BUTTON_VARIANT.PRIMARY}
        >
          {t('alertsList.header.createButton', 'New alert')}
        </Button>
      )}
    </PaidFeature>
  );
};

export default AlertActions;
