import { isStepWithSelectors } from '@bugbug/core/types/steps';
import {
  createEmptyCustomSelectorsPreset,
  normalizeSelectorsPreset,
} from '@bugbug/core/utils/selectors';
import { pick } from '@bugbug/core/utils/toolbox';
import memoize from 'lodash.memoize';
import { mergeDeepRight } from 'ramda';

import type { Step } from '@bugbug/core/types/steps';

import { STEP_SCHEMA } from './StepDetails.constants';

export const getInitialValues = memoize(
  <TStep extends Step>(step: Partial<TStep>, currentValues: Partial<TStep> = {}) => {
    const currentType = step.type as TStep['type'];
    const currentStep = {
      ...(mergeDeepRight(step, currentValues) as Partial<TStep>),
      type: step.type,
      isActive: step.isActive,
    };
    const schema = STEP_SCHEMA[currentType] || STEP_SCHEMA.DEFAULT;
    const requiredFields = Object.keys(schema.describe().fields);

    const initialValues = pick(requiredFields, currentStep) as Step;

    if (isStepWithSelectors(initialValues)) {
      initialValues.selectorsPresets = initialValues.selectorsPresets?.length
        ? initialValues.selectorsPresets.map((preset) =>
            preset.isCustom ? normalizeSelectorsPreset(preset) : preset,
          )
        : [createEmptyCustomSelectorsPreset()];

      if (initialValues.type === 'dragAndDrop' && initialValues.dndDragOn === 'element') {
        initialValues.dndDropSelectorsPresets = initialValues.dndDropSelectorsPresets?.length
          ? initialValues.dndDropSelectorsPresets.map((preset) =>
              preset.isCustom ? normalizeSelectorsPreset(preset) : preset,
            )
          : [createEmptyCustomSelectorsPreset()];
      }
    }

    return schema.cast(initialValues) as TStep;
  },
);
