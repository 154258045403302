import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useFirstMountState, useMount } from 'react-use';

import PrivateRoute from '~/components/PrivateRoute';
import { selectCurrentOrganization } from '~/modules/organization/organization.selectors';
import { useAppSelector } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import AppLoader from '~/views/AppLoader';
import NotFound from '~/views/NotFound';
import { ProfileSetup } from '~/views/ProfileSetup/ProfileSetup';
import { TrialStart } from '~/views/TrialStart/TrialStart';
import urls from '~/views/urls';
import { Welcome } from '~/views/Welcome/Welcome';

import { OrganizationSetup } from '../OrganizationSetup/OrganizationSetup';

const Redirect = () => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(UserActions.redirectOnboardingRequest());
  });

  return <AppLoader />;
};

export const Onboarding = () => {
  const currentOrganization = useAppSelector(selectCurrentOrganization);
  const isFirstMount = useFirstMountState();

  if (!currentOrganization) {
    return isFirstMount ? <AppLoader /> : null;
  }

  return (
    <Switch>
      <PrivateRoute path={urls.welcome} component={Welcome} exact />
      <PrivateRoute path={urls.welcomeInvited} component={Welcome} exact />
      <PrivateRoute path={urls.setupProfile} component={ProfileSetup} exact />
      <PrivateRoute path={urls.setupOrganization} component={OrganizationSetup} exact />
      <PrivateRoute path={urls.trialStart} component={TrialStart} exact />
      <PrivateRoute path={urls.onboardingRedirect} component={Redirect} exact />
      <Route path={urls.notFound} component={NotFound} />
    </Switch>
  );
};
