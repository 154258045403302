import ButtonBase from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import { COLOR, hexToRgba } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import mixins from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

import Page, { Content as PageContent } from '~/components/Page';

export const Container = styled(Page)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;

  ${PageContent} {
    padding-bottom: 0;
    padding-right: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: 38px;
`;

export const Content = styled.div`
  flex: 1;
  overflow: auto;
`;

export const CopyTextSection = styled.div`
  margin-bottom: 40px;
`;

export const Header = styled.h2`
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: ${FONT_WEIGHT.BOLD};
`;

export const Description = styled.p`
  margin-bottom: 10px;
`;

export const IntegrationsHeader = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: -15px;
    max-width: 740px;
  }
`;

export const IntegrationsList = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 56px;
  flex-direction: column;
  max-width: 740px;
`;

export const Integration = styled.div`
  ${mixins.layout.flexCenter};
  width: 100%;
  height: 100px;
  border: 2px solid ${({ theme }) => theme.colors.border.medium};
  box-shadow: 2px 2px 0 0 ${hexToRgba(COLOR.GRAY_26, 0.06)};
  border-radius: 6px;
  transition: border-color 0.3s;
  padding: 20px 20px 20px 30px;
  gap: 40px;

  button {
    text-decoration: unset;
  }

  p {
    ${mixins.lineClamp({ lines: 3 })};
    flex: 1;
    margin: 0;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const IntegrationActions = styled.div`
  ${mixins.layout.flexCenter};
  justify-content: flex-end;
  gap: 6px;
  width: 215px;
`;

export const IntegrationButton = styled(ButtonBase)`
  width: 100px;

  > div {
    flex: none;
  }
`;

export const KeyIcon = styled(Icon).attrs(() => ({
  name: 'keyBold',
}))`
  color: ${COLOR.PRIMARY_LIGHT};
`;
