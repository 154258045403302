import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { useTranslation } from 'react-i18next';

import type { Test, TestRun } from '@bugbug/core/types/tests';
import useTestRunner from '~/hooks/useTestRunner';
import { useIsNewPlaybackSupported } from '~/modules/test/test.hooks';

import { useTestRunnerActionsState } from './TestHeaderActions.hooks';
import * as S from './TestHeaderActions.styled';

interface RecordingButtonsProps {
  testId: Test['id'];
  testRunId?: TestRun['id'];
}

export const RecordingButtons = ({ testId, testRunId }: RecordingButtonsProps) => {
  const { t } = useTranslation();
  const testRunner = useTestRunner({ id: testId }, { testRunId });
  const isNewPlaybackSupported = useIsNewPlaybackSupported();
  const runnerActionsState = useTestRunnerActionsState();

  if (!isNewPlaybackSupported) {
    return (
      <S.CombinedButtonsContainer>
        <Button
          {...runnerActionsState.state.stop}
          onClick={testRunner.stop}
          iconName="stop"
          variant={BUTTON_VARIANT.PRIMARY}
          bordered
        >
          {t('testDetails.testActions.stopButton', 'Stop')}
        </Button>
      </S.CombinedButtonsContainer>
    );
  }

  return (
    <S.CombinedButtonsContainer>
      <S.CombinedButton
        {...runnerActionsState.state.stop}
        onClick={testRunner.stopWithConfirmation}
        iconName="stop"
        roundCorners="left"
      >
        {t('testDetails.testActions.stopButton', 'Stop')}
      </S.CombinedButton>
      <S.CombinedButtonsSeparator disabled={runnerActionsState.isLoading} />
      <S.RecordButton
        {...runnerActionsState.state.pause}
        disabled={testRunner.isCloudRun}
        onClick={testRunner.pause}
        iconName="pause"
        roundCorners="right"
      >
        {t('testDetails.testActions.pauseButton', 'Pause')}
      </S.RecordButton>
    </S.CombinedButtonsContainer>
  );
};
