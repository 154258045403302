/* eslint-disable no-param-reassign */

import { CORE_ERROR_CODE } from '@bugbug/core/constants/errors';
import status from 'http-status';

import type { AxiosResponse } from 'axios';

import { CoreActions } from '~/modules/core/core.redux';
import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import storeRegistry from '~/modules/storeRegistry';
import { selectIsUserImpersonated, selectUserToken } from '~/modules/user/user.selectors';
import { idValidator } from '~/utils/validators';

export const requestInterceptor = (request) => {
  const state = storeRegistry.getState();
  const isImpersonated = selectIsUserImpersonated(state);
  const authToken = selectUserToken(state);
  const organizationId = selectCurrentOrganizationId(state);

  // TODO: only for smooth migration from custom ids to UUIDs
  // To remove, if all users sessions migrated.
  const isValidOrgId =
    organizationId && organizationId.length > 8 && idValidator.isValidSync(organizationId);

  if (organizationId && isValidOrgId) {
    request.headers.common['BugBug-Organization-Id'] = organizationId;
  }
  if (authToken) {
    if (isImpersonated) {
      request.headers.common.Authorization = `Impersonate ${authToken}`;
    } else {
      request.headers.common.Authorization = `Token ${authToken}`;
    }
  }

  return request;
};

export const errorHandlers = (error) => {
  if (!error.response) {
    storeRegistry.dispatch(CoreActions.error({ errorCode: CORE_ERROR_CODE.DISCONNECTED_OR_CORS }));
  } else if (error.response.status === status.UNAUTHORIZED) {
    storeRegistry.dispatch({ type: status['401_NAME'] });
  }
  throw error;
};

export const responseInterceptor = (response: AxiosResponse) => response;
