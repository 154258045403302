import Icon from '@bugbug/core/components/Icon';
import { SelectOption } from '@bugbug/core/components/Select';
import { isEmpty } from '@bugbug/core/utils/toolbox';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import type { StepDetailsDto, WaitingConditionWithIndex } from './WaitingConditions.types';

import type { InputProps } from '@bugbug/core/components/Input/Input';
import type { WaitingCondition as WaitingConditionType } from '@bugbug/core/types/waitingConditions';
import FormField from '~/components/FormField';
import { CONDITION_PARAMS } from '~/modules/constans';

import { WAITING_CONDITION_STATE } from '../StepDetails/StepDetails.constants';

import * as S from './WaitingConditions.styled';

export interface WaitingConditionProps {
  condition: WaitingConditionWithIndex;
  index: number;
  defaultConditionState?: WaitingConditionType;
  readOnly?: boolean;
  onChange: (index: number, condition: Partial<WaitingConditionType>) => void;
  onRemove: (index: number) => void;
}

export const WaitingCondition = ({
  condition,
  defaultConditionState,
  readOnly,
  onChange,
  onRemove,
}: WaitingConditionProps) => {
  const { type, expected: value, isActive, isGlobal, isOverridden, index } = condition;
  const { label, hasValue, valueOptions } = CONDITION_PARAMS[type] as unknown as {
    label: string;
    hasValue?: boolean;
    valueOptions?: Partial<InputProps> & { label: string; tooltip: string };
  };
  const { t } = useTranslation(undefined, { keyPrefix: 'waitingConditions' });
  const labelId = `waiting-condition-label-${index}`;
  const formik = useFormikContext<StepDetailsDto>();
  const error = formik.errors.waitingConditions?.[index] as
    | Partial<WaitingConditionType>
    | undefined;

  const defaultActivityState = isActive
    ? WAITING_CONDITION_STATE.ENABLED
    : WAITING_CONDITION_STATE.DISABLED;
  const state = isGlobal && !isOverridden ? WAITING_CONDITION_STATE.DEFAULT : defaultActivityState;

  const handleStateChange = (event) => {
    const updatedFields: Partial<WaitingConditionProps['condition']> = {
      isActive: event.target.value !== WAITING_CONDITION_STATE.DISABLED,
    };
    if (formik.values.waitingConditions[index].isGlobal) {
      updatedFields.isOverridden = event.target.value !== WAITING_CONDITION_STATE.DEFAULT;
    }
    onChange(index, updatedFields);
  };

  const handleValueChange = (event) => {
    onChange(index, { expected: isEmpty(event.target.value) ? null : event.target.value });
  };

  let placeholder = valueOptions?.placeholder ?? '';
  if (!placeholder && defaultConditionState?.expected) {
    placeholder = t('expected.default', 'Default: {{ value }}', {
      value: defaultConditionState.expected,
    });
  }

  return (
    <>
      <FormField key={labelId} label={label} labelId={labelId}>
        <S.Select
          name={`waitingConditions[${index}].isActive`}
          value={state}
          onChange={handleStateChange}
          disabled={readOnly}
          aria-labelledby={labelId}
        >
          {isGlobal && (
            <SelectOption value={WAITING_CONDITION_STATE.DEFAULT}>
              <S.CustomSelectOption>
                <S.ConditionState enabled={defaultConditionState?.isActive} />
                {t('state.default.label', 'Use project settings')}
                <span>
                  ({defaultConditionState?.isActive ? t('state.enabled') : t('state.disabled')})
                </span>
              </S.CustomSelectOption>
            </SelectOption>
          )}
          <SelectOption value={WAITING_CONDITION_STATE.ENABLED}>
            <S.CustomSelectOption>
              <S.ConditionState enabled /> {t('state.enabled', 'Enabled')}
            </S.CustomSelectOption>
          </SelectOption>
          <SelectOption value={WAITING_CONDITION_STATE.DISABLED}>
            <S.CustomSelectOption>
              <S.ConditionState /> {t('state.disabled', 'Disabled')}
            </S.CustomSelectOption>
          </SelectOption>
          {!isGlobal && !readOnly && (
            <S.ActionButton aria-label="Delete" onClick={() => onRemove(index)}>
              <Icon name="delete" /> {t('delete', 'Delete')}
            </S.ActionButton>
          )}
        </S.Select>
      </FormField>
      {hasValue &&
        WAITING_CONDITION_STATE.ENABLED === state &&
        (!isGlobal || (isGlobal && isOverridden)) && (
          <FormField label={valueOptions?.label} helperText={valueOptions?.tooltip}>
            <S.Input
              name={`waitingConditions[${index}].expected`}
              type={valueOptions?.type}
              min={valueOptions?.min}
              fullWidth
              value={value ?? ''}
              onChange={handleValueChange}
              placeholder={placeholder}
              endAdornment={valueOptions?.endAdornment}
              error={error?.expected ?? false}
            />
          </FormField>
        )}
    </>
  );
};
