import Checkbox from '@bugbug/core/components/Checkbox';
import { useCallback, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useUpdateEffect } from 'react-use';

import type { ChangeEventHandler } from 'react';
import type { TemporaryStep } from '~/modules/steps/steps.types';
import type { SelectedStep } from '~/modules/uiState/uiState.types';

import type { Group } from '@bugbug/core/types/groups';
import type { Step } from '@bugbug/core/types/steps';
import type { Maybe } from '@bugbug/core/types/utils';
import { selectCurrentStepId } from '~/modules/steps/steps.selectors';
import { isTemporaryStep } from '~/modules/steps/steps.types';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import {
  selectStep,
  selectOrderedGroupPartialsIndices,
  selectGroup,
  selectSortedSteps,
} from '~/modules/test/test.selectors';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import {
  selectIsStepChecked,
  selectLastFocusedStepCheckbox,
} from '~/modules/uiState/uiState.selectors';

import { PlaybackCursorSlot } from '../PlaybackCursor/PlaybackCursor';
import { StepNameWithParams } from '../StepNameWithParams/StepNameWithParams';
import { StepStatusCell } from '../StepStatusCell/StepStatusCell';

import { ActiveBorderWrapper } from './ActiveBorderWrapper';
import { MoreActions } from './MoreActions/MoreActions';
import * as S from './Step.styled';
import { StepElementScreenshot } from './StepElementScreenshot';

interface StepProps {
  stepId: Step['id'];
  groupId: Group['id'];
  readOnly?: boolean;
  runResultEnabled?: boolean;
  index: number;
  groupIndex: number;
  getGroupOffset: () => number;
  onClick: (stepId: string) => void;
  onHover: (stepId: string) => void;
  onDelete: () => void;
}

const StepComponent = ({
  stepId,
  groupId,
  readOnly = false,
  runResultEnabled = false,
  index,
  groupIndex,
  getGroupOffset,
  onClick,
  onHover,
  onDelete,
}: StepProps) => {
  const dispatch = useAppDispatch();
  const currentStepId = useAppSelector(selectCurrentStepId);
  const step = useAppSelector(selectStep(stepId, runResultEnabled)) as Maybe<Step | TemporaryStep>;
  const group = useAppSelector(selectGroup(runResultEnabled, groupId)) as Maybe<
    Group & { steps: string[] } // TODO: Consider creating a new types for normalized data
  >;
  const sortedSteps = useAppSelector(selectSortedSteps);
  const isChecked = useAppSelector(selectIsStepChecked(stepId, groupId));
  const lastFocusedStepCheckbox = useAppSelector(selectLastFocusedStepCheckbox);
  const isTemporary = !!step && isTemporaryStep(step);

  // TODO: Should be deleted while removing unconfirmed groups
  const partialsIndices = useAppSelector(
    selectOrderedGroupPartialsIndices(step?.groupId, runResultEnabled),
  );
  //

  const dragIndex = partialsIndices[`step.${stepId}`];
  const isLastStep = !!step && group?.steps?.indexOf?.(step.id) === (group?.steps?.length ?? 0) - 1;

  // TODO: Probably it should be moved to PlaybackCursorSlot
  const isNextStepActive = useMemo(() => {
    const stepIndex = sortedSteps.findIndex(({ id }) => id === stepId);
    const nextStep = sortedSteps[stepIndex + 1];
    return nextStep?.isActive;
  }, [sortedSteps, stepId]);

  const isSelected = currentStepId === stepId;

  useUpdateEffect(() => {
    if (isTemporary && !isSelected) {
      dispatch(TestActions.cancelTemporaryStep(stepId, step?.groupId, currentStepId));
    }
  }, [currentStepId, isTemporary]);

  const handleStepClick = useCallback(() => {
    onClick(stepId);
  }, [onClick, stepId]);

  const handleMouseEnter = useCallback(() => {
    if (isTemporary) return;

    onHover(stepId);
  }, [onHover, stepId, isTemporary]);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const modifiedSteps: SelectedStep[] = [];
      if ((event.nativeEvent as PointerEvent).shiftKey) {
        const stepIndex = sortedSteps.findIndex(({ id }) => id === stepId);
        const lastFocusedStepIndex = sortedSteps.findIndex(
          ({ id }) => id === lastFocusedStepCheckbox?.id,
        );
        const rangeStartIndex = Math.min(stepIndex, lastFocusedStepIndex);
        const rangeEndIndex = Math.max(stepIndex, lastFocusedStepIndex);
        modifiedSteps.push(
          ...sortedSteps.slice(rangeStartIndex, rangeEndIndex + 1).map((sortedStep) => ({
            id: sortedStep.id,
            groupId: sortedStep.groupId,
          })),
        );
      } else {
        modifiedSteps.push({ id: stepId, groupId });
      }
      dispatch(UIStateActions.setCheckedSteps({ steps: modifiedSteps, checked: !isChecked }));
      dispatch(UIStateActions.setLastFocusedStepCheckbox({ step: { id: stepId, groupId } }));
    },
    [dispatch, groupId, isChecked, lastFocusedStepCheckbox, sortedSteps, stepId],
  );

  if (!step) {
    return null;
  }

  return (
    <Draggable
      disableInteractiveElementBlocking
      draggableId={stepId}
      key={stepId}
      index={runResultEnabled ? 0 : dragIndex}
      isDragDisabled={readOnly || runResultEnabled}
    >
      {({ dragHandleProps, innerRef, draggableProps }) => (
        /* eslint-disable-next-line react/jsx-no-constructed-context-values */
        <ActiveBorderWrapper
          getGroupOffset={getGroupOffset}
          step={step}
          stepIndex={index}
          group={group as Group}
          groupIndex={groupIndex}
          active={!!group}
          last={isLastStep}
        >
          <S.Container
            role="row"
            onMouseEnter={handleMouseEnter}
            onClick={handleStepClick}
            active={step.isActive}
            aria-rowindex={index}
            aria-selected={currentStepId === stepId || isTemporary}
            ref={innerRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...draggableProps}
          >
            {!readOnly && !runResultEnabled ? (
              <S.DragHandler dragHandleProps={dragHandleProps} />
            ) : (
              <S.DragPlaceholder />
            )}
            {!runResultEnabled && (
              <S.CheckboxSection>
                <Checkbox
                  aria-label={`Step ${index} selection`}
                  name={stepId}
                  checked={isChecked}
                  onChange={handleChange}
                />
              </S.CheckboxSection>
            )}
            <S.NameSection>
              <StepNameWithParams step={step} runResultEnabled={runResultEnabled} />
            </S.NameSection>
            <S.ScreenshotSection>
              <StepElementScreenshot
                stepId={step.id}
                stepType={step.type}
                clickPropagationPrevented={isSelected}
                runResultEnabled={runResultEnabled}
              />
            </S.ScreenshotSection>
            <S.StatusSection>
              <StepStatusCell stepId={step.id} sleep={step.sleep} />
            </S.StatusSection>
            <S.MoreSection>
              <MoreActions
                stepId={step.id}
                groupId={groupId}
                active={step.isActive}
                runResultEnabled={runResultEnabled}
                readOnly={isTemporary}
                onDelete={onDelete}
              />
            </S.MoreSection>
          </S.Container>
          <PlaybackCursorSlot
            afterStepId={step.id}
            groupId={step.groupId}
            disabled={!isNextStepActive && !step.isActive}
          />
        </ActiveBorderWrapper>
      )}
    </Draggable>
  );
};

export default StepComponent;
