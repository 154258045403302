import { H1 } from '@bugbug/core/theme/typography';
import { VARIABLES_TAB } from '@bugbug/core/types/variables';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { SideEffect } from '@bugbug/core/types/utils';
import type { VariablesTab } from '@bugbug/core/types/variables';
import ScrollShadow from '~/components/ScrollShadow';
import { Tab } from '~/components/Tabs';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import { selectVariablesTab } from '~/modules/uiState/uiState.selectors';

import BuiltInVariablesList from './components/BuiltInVariablesList';
import CustomVariablesList from './components/CustomVariablesList';
import Profiles from './components/Profiles';
import VariablesActions from './components/VariablesActions';
import { TAB_LABEL } from './Variables.constants';
import * as S from './Variables.styled';

const Variables = () => {
  const { t } = useTranslation();
  const routes = useAppRoutes('variables');

  const lastTab = useAppSelector(selectVariablesTab);
  const selectedTab = VARIABLES_TAB.find((path) => routes.isRoute(path)) ?? lastTab;

  const handleTabChange = useCallback<SideEffect<unknown, VariablesTab>>(
    (__, value) => {
      if (selectedTab === value) return;
      routes.push(value);
    },
    [selectedTab, routes],
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(UIStateActions.setVariablesTab(selectedTab));
  }, [dispatch, selectedTab]);

  return (
    <S.Container
      title={
        <S.TitleContainer data-testid="Variables.Header">
          <H1>{t('variables.title', 'Variables')}</H1>
          <VariablesActions />
        </S.TitleContainer>
      }
      HeaderContent={
        <S.Tabs value={selectedTab} onChange={handleTabChange}>
          {VARIABLES_TAB.map((tab) => (
            <Tab key={tab} label={TAB_LABEL[tab]} value={tab} />
          ))}
        </S.Tabs>
      }
      paidFeatureBanner="variables"
    >
      <S.Content>
        <ScrollShadow />
        <S.TabContent>
          {selectedTab === 'customVariables' && <CustomVariablesList />}
          {selectedTab === 'builtInVariables' && <BuiltInVariablesList />}
          {selectedTab === 'profiles' && <Profiles />}
        </S.TabContent>
      </S.Content>
    </S.Container>
  );
};

export default Variables;
