import { useTranslation } from 'react-i18next';

import { BRANDS_LIST } from '~/constants/brands';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import { useAppSelector } from '~/modules/store';
import analytics, { TRACK_EVENT_ARG_TYPE } from '~/services/analytics';
import { OnboardingSubtitle, OnboardingTitle } from '~/views/Onboarding/Onboarding.styled';

import * as S from './TrialExpiredModal.styled';

export const TrialExpiredModal = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'trialExpiredModal' });
  const modal = useModal();
  const appRoute = useAppRoutes('organizations');
  const organizationId = useAppSelector(selectCurrentOrganizationId);

  const goToSubscription = () => {
    analytics.trackEvent('trial_ended_modal_button_clicked', {
      [TRACK_EVENT_ARG_TYPE.ORGANIZATION_ID]: organizationId,
      [TRACK_EVENT_ARG_TYPE.CONTEXT]: 'onboarding',
      [TRACK_EVENT_ARG_TYPE.BUTTON_CLICK]: 'upgrade',
    });
    modal.hide();
    appRoute.push('subscription', { organizationId });
  };

  const hide = () => {
    analytics.trackEvent('trial_ended_modal_button_clicked', {
      [TRACK_EVENT_ARG_TYPE.ORGANIZATION_ID]: organizationId,
      [TRACK_EVENT_ARG_TYPE.CONTEXT]: 'onboarding',
      [TRACK_EVENT_ARG_TYPE.BUTTON_CLICK]: 'free_plan',
    });
    modal.hide();
  };

  return (
    <S.Container>
      <S.BackdropOverrides />
      <S.TrialExpiredLogo />
      <OnboardingTitle>{t('title', 'Your trial has expired')}</OnboardingTitle>
      <OnboardingSubtitle>{t('subtitle', "Now you're on the FREE plan")}</OnboardingSubtitle>
      <S.PlansContainer>
        <S.Plan>
          <S.PlanTitle>{t('plan.free.title', 'Stay with locked features')}</S.PlanTitle>
          <S.PlanSubtitle>{t('plan.free.subtitle', 'Free forever')}</S.PlanSubtitle>
          <S.PlanPrice>
            <S.PlanPriceAmount>
              $0 <S.PlanPriceSufix>/ {t('month', 'mo')}</S.PlanPriceSufix>
            </S.PlanPriceAmount>
          </S.PlanPrice>
          <S.PlanPerksContainer>
            <S.PlanPerk>
              <S.PerkIncludedIcon />
              {t('plan.free.perks.localTesting', 'Local testing')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkIncludedIcon />
              {t('plan.free.perks.collaboration', 'Collaboration')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkLockedIcon />
              {t('plan.free.perks.cloudRuns', 'No cloud runs')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkLockedIcon />
              {t('plan.free.perks.schedules', 'No schedules')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkLockedIcon />
              {t('plan.free.perks.notifications', 'No notifications')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkLockedIcon />
              {t('plan.free.perks.variables', 'No variables')}
            </S.PlanPerk>
          </S.PlanPerksContainer>
          <S.PlanButton variant="primary" bordered onClick={hide}>
            {t('plan.free.button', 'Stay on free plan')}
          </S.PlanButton>
        </S.Plan>
        <S.Plan>
          <S.PlanTitle>{t('plan.pro.title', 'Upgrade for full access')}</S.PlanTitle>
          <S.PlanSubtitle>{t('plan.pro.subtitle', 'Starting from')}</S.PlanSubtitle>
          <S.PlanPrice>
            <S.PlanPriceAmount>
              $99 <S.PlanPriceSufix>/ {t('month', 'mo')}</S.PlanPriceSufix>
            </S.PlanPriceAmount>
          </S.PlanPrice>
          <S.PlanPerksContainer>
            <S.PlanPerk>
              <S.PerkIncludedIcon />
              {t('plan.pro.perks.unlimitedCloudRuns', 'Unlimited cloud runs')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkIncludedIcon />
              {t('plan.pro.perks.unlimitedUsers', 'Unlimited users')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkIncludedIcon />
              {t('plan.pro.perks.notifications', 'Notifications')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkIncludedIcon />
              {t('plan.pro.perks.schedules', 'Schedules')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkIncludedIcon />
              {t('plan.pro.perks.variables', 'Variables')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkIncludedIcon />
              {t('plan.pro.perks.reporting', 'Reporting')}
            </S.PlanPerk>
            <S.PlanPerk>
              <S.PerkIncludedIcon />
              {t('plan.pro.perks.andMuchMore', 'And much more!')}
            </S.PlanPerk>
          </S.PlanPerksContainer>
          <S.PlanButton variant="primary" onClick={goToSubscription} iconName="upgrade">
            {t('plan.pro.button', 'Upgrade now')}
          </S.PlanButton>
        </S.Plan>
      </S.PlansContainer>
      <p>{t('joinCompanies', 'Join 100+ companies that already upgraded')}</p>
      <S.BrandsList>
        {BRANDS_LIST.map(({ logoUrl, alt }) => (
          <S.BrandLogo key={alt} src={logoUrl} alt={alt} />
        ))}
      </S.BrandsList>
    </S.Container>
  );
};

export default TrialExpiredModal;
