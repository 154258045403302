import * as Ariakit from '@ariakit/react';
import { useMemo, useState } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';

import type { ComponentProps, ReactNode } from 'react';

import type { Maybe } from '@bugbug/core/types/utils';

import { calculateBackdrop } from './TutorialTooltip.helpers';
import * as S from './TutorialTooltip.styled';

export interface TutorialBackdrop {
  readonly clickTraps: [
    {
      // Top
      height: number;
    },
    {
      // Right
      left: number;
    },
    {
      // Bottom
      top: number;
    },
    {
      // Left
      width: number;
    },
  ];
  readonly circle: {
    position: { x: number; y: number };
    anchorRect: Required<
      ReturnType<Required<ComponentProps<typeof Ariakit.Popover>>['getAnchorRect']>
    >;
    radius: number;
  };
}

export interface TutorialTooltipProps {
  highlightedElement: Maybe<HTMLElement>;
  children: ReactNode;
  primaryPopoverPlacement?: Ariakit.PopoverStoreProps['placement'];
  allowOutsideClick?: boolean;
  dismissOnOutsideClick?: boolean;
}

export const TutorialTooltip = ({
  highlightedElement,
  children,
  primaryPopoverPlacement,
  allowOutsideClick,
  dismissOnOutsideClick,
}: TutorialTooltipProps) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const elementRect = highlightedElement?.getBoundingClientRect();

  // Trigger a re-render when the window size changes
  useWindowSize();

  const backdrop = useMemo(
    () => (elementRect ? calculateBackdrop(elementRect) : null),
    [elementRect],
  );

  const popover = Ariakit.usePopoverStore({
    open: true,
    placement: primaryPopoverPlacement ?? 'left',
  });

  if (!backdrop) return null;

  const handleClickOutside = () => {
    if (!allowOutsideClick && !dismissOnOutsideClick) return;

    setIsDismissed(true);
  };

  const { circle, clickTraps } = backdrop;

  if (isDismissed) return null;

  return (
    <Ariakit.Portal>
      <S.OverlayWithHole {...circle}>
        <S.HoleRing {...circle} />
        {clickTraps.map((clickTrap, index) => (
          <S.ClickTrap
            // eslint-disable-next-line react/no-array-index-key
            key={`tutorial-overlay-click-trap-${index}`}
            data-testid={`TutorialOverlayClickTrap.${index}`}
            style={clickTrap}
            onClick={handleClickOutside}
            allowOutsideClick={allowOutsideClick}
            dismissOnOutsideClick={dismissOnOutsideClick}
          />
        ))}
      </S.OverlayWithHole>
      <S.TooltipPopover store={popover} gutter={6} getAnchorRect={() => backdrop.circle.anchorRect}>
        <Ariakit.PopoverArrow size={56} />
        {children}
      </S.TooltipPopover>
    </Ariakit.Portal>
  );
};
