import Link from '@bugbug/core/components/Link';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

import type { AuthProvidersRef } from '~/components/AuthProviders/AuthProviders';

import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import urls from '~/views/urls';

import { AuthLayout } from '../AuthLayout/AuthLayout';

import Benefits from './Benefits';
import * as S from './SignUp.styled';
import { SignUpForm } from './SignUpForm/SignUpForm';

export const SignUp = () => {
  const { key } = useQueryString();
  const appRoutes = useAppRoutes('signUp');

  if (key) {
    appRoutes.push('signUpByInvitation', {}, { key });
  }

  return <SignUpContent />;
};

const SignUpContent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'auth.signUp' });
  const appRoutes = useAppRoutes('signUp');

  const {
    email,
    source_url: referrer,
    'continue-with-google': continueWithGoogle,
    'continue-with-github': continueWithGithub,
  } = useQueryString();

  const decodedUrlEmail = email ? decodeURI(email) : email;
  const decodedReferrer = referrer ? decodeURI(referrer) : referrer;
  const authProviders = useRef<AuthProvidersRef>(null);

  useMount(() => {
    if (decodedReferrer && document.referrer) {
      analytics.trackEvent(TRACK_EVENT_TYPE.CLICK_SIGN_UP_ON_LANDING);
    } else {
      analytics.trackEvent(TRACK_EVENT_TYPE.VIEW_SIGN_UP);
    }

    if (continueWithGoogle === 'true') {
      authProviders.current?.loginUsingGoogle();
    }
    if (continueWithGithub === 'true') {
      authProviders.current?.loginUsingGithub();
    }
  });

  return (
    <S.Container>
      <Helmet
        title={t('pageTitle', 'Start testing for free')}
        link={[{ rel: 'canonical', href: appRoutes.getAbsoluteRouteUrl('signUp') }]}
      />
      <S.PageLayout>
        <div>
          <AuthLayout
            title={t('title', 'Start testing for free')}
            subTitle={
              <>
                {t('subTitle.part1', 'Automate faster. No credit card needed.')}
                <br />
                {t('subTitle.part2', 'Free forever.')}
              </>
            }
            form={<SignUpForm email={decodedUrlEmail} />}
            redirectInfo={
              <>
                {t('signInQuestion', 'Got an account? ')}
                <Link to={urls.login}>{t('logInLink', 'Log in')}</Link>
              </>
            }
            ref={authProviders}
          />
        </div>
        <div>
          <Benefits />
        </div>
      </S.PageLayout>
    </S.Container>
  );
};
