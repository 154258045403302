import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Step } from '@bugbug/core/types/steps';
import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { usePasteStepsMutation } from '~/modules/tests/tests.api';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import toasts from '~/services/toasts';
import { ENTITIES, showPendingRequestToast } from '~/services/toasts/internalServerError';

import useLocalStorageValue from './useLocalStorageValue';

export const showPendingStep = showPendingRequestToast(ENTITIES.STEP);
export const showPendingSteps = showPendingRequestToast(ENTITIES.STEPS);

export const useCopyPasteSteps = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentOrganizationId = useAppSelector(selectCurrentOrganizationId);
  const clipboardKey = `${currentOrganizationId}.stepsClipboard`;
  // TODO: This should be a part of persisted ui state
  const { value: copiedStepsIds, set: setClipboard } =
    useLocalStorageValue<Step['id'][]>(clipboardKey);

  const [pasteSteps, { isLoading }] = usePasteStepsMutation();

  const handleCopy = useCallback(
    (stepsIds = []) => {
      analytics.trackEvent(TRACK_EVENT_TYPE.COPY_STEP);
      setClipboard(stepsIds);
      toasts.showSuccess({
        content: t('copyPasteSteps.copied', {
          defaultValue_one: 'Step copied',
          defaultValue_other: 'Steps copied',
          count: stepsIds.length,
        }),
      });
    },
    [t, setClipboard],
  );

  const handlePaste = useCallback(
    async (testId, groupId, atIndex = 0) => {
      analytics.trackEvent(TRACK_EVENT_TYPE.PASTE_STEP);
      const pendingToast =
        copiedStepsIds.length === 1 ? showPendingStep('paste') : showPendingSteps('paste');

      try {
        const data = await pasteSteps({
          testId,
          groupId,
          atIndex,
          stepsIds: copiedStepsIds,
        }).unwrap();

        dispatch(TestActions.pasteStepsSuccess(testId, data.group, atIndex));
        dispatch(UIStateActions.addRecentlyAddedStepsIds({ stepsIds: data.newStepsIds }));
        dispatch(TestActions.refreshPlaybackCursorPosition());
        pendingToast.success();
      } catch (error) {
        console.error('Error while pasting steps', error);
        // @ts-expect-error Remove this comment after migrating `internalServerError` to TypeScript
        const shouldShowRequestError = pendingToast.shouldShowRequestError(error, true);
        if (shouldShowRequestError) {
          pendingToast.error();
        } else {
          pendingToast.dismiss();
        }
      }
    },
    [copiedStepsIds, pasteSteps, dispatch],
  );

  return {
    copySteps: handleCopy,
    pasteSteps: handlePaste,
    hasCopiedSteps: !!copiedStepsIds?.length,
    isPending: isLoading,
  };
};
