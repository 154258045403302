import { noop } from '@bugbug/core/utils/toolbox';
import { Formik } from 'formik';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { Step } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';
import { StepDetailsEditor } from '~/views/TestDetails/components/StepDetails/StepDetailsEditor';

import * as S from './StepDetails.styled';

interface StepDetailsProps {
  onClose: SideEffect;
  step: Step;
}

// TODO refactor this component and similar one in TestDetails to make it one to serve all situations
const StepDetails = ({ onClose, step }: StepDetailsProps) => (
  <Formik
    initialValues={step}
    onSubmit={noop}
    validateOnMount={false}
    validateOnBlur={false}
    enableReinitialize
  >
    <StepDetailsContent step={step} onClose={onClose} />
  </Formik>
);

const StepDetailsContent = ({ step, onClose }: StepDetailsProps) => {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLFormElement>(null);

  return (
    <S.Container data-testid="StepDetails" ref={containerRef}>
      <S.Header>
        <S.TitleContainer>
          <S.Title as="h2">{t('stepDetails.header.title', 'Step details')}</S.Title>
          <S.ActionButton onClick={onClose} bordered>
            {t('stepDetails.readOnly.button.close', 'Close')}
          </S.ActionButton>
        </S.TitleContainer>
      </S.Header>

      <StepDetailsEditor step={step} context="component" readOnly />
    </S.Container>
  );
};

export default StepDetails;
