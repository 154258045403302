import Button from '@bugbug/core/components/Button';
import styled from 'styled-components';

import Confetti from '~/images/confetti.svg?react';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 350px;
  padding: 0 6px;
`;

export const Subtitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin: 4px 0 0 0;
  padding: 0 8px;
`;

export const ConfettiIcon = styled(Confetti)`
  width: 52px;
  margin-bottom: 8px;
`;

export const ExternalLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const ProceedButton = styled(Button).attrs({ variant: 'primary' })`
  width: 100%;
  margin-bottom: 8px;

  div {
    display: flex;
    justify-content: center;
  }
`;
