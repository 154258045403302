import { createAction } from '@reduxjs/toolkit';

import type { Group } from '../types/groups';
import type { PlaybackCursorPosition } from '../types/playback';
import type { Step } from '../types/steps';
import type { Test } from '../types/tests';
import type { Maybe } from '../types/utils';

export const CommonActions = {
  setPlaybackCursorPosition: createAction<PlaybackCursorPosition>(
    'common/setPlaybackCursorPosition',
  ),

  setPlaybackCursorPositionIfPausedOrIdle: createAction<PlaybackCursorPosition>(
    'common/setPlaybackCursorPositionIfPausedOrIdle',
  ),

  setPausePosition: createAction<{
    afterStepId: Maybe<Step['id']>;
    atGroupId: Group['id'];
  }>('common/setPausePosition'),

  setRecordingStartPosition: createAction<{
    afterStepId: Maybe<Step['id']>;
    atGroupId: Group['id'];
  }>('common/setRecordingStartPosition'),

  triggerPause: createAction('common/pauseSession'),

  resumeRecording: createAction('common/resumeRecording'),

  resumeRunning: createAction<{ pauseAfterStep?: boolean }>('common/resumeRunning'),

  setRecordingCursorPosition: createAction<{
    afterStepId: Maybe<Step['id']>;
    groupId: Group['id'];
  }>('common/setRecordingCursorPosition'),

  setIsSingleLoading: createAction<{
    isLoading: boolean;
    testId: Test['id'];
  }>('common/setIsSingleLoading'),

  logoutFromExtension: createAction('common/logoutFromExtension'),

  highlightEditAndRewindMode: createAction('common/highlightEditAndRewindMode'),
} as const;

export type CommonAction = typeof CommonActions;
