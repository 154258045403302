import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Schedule } from '@bugbug/core/types/schedules';
import { ConfirmModal } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import { useDeleteScheduleMutation } from '~/modules/schedules/schedules.api';
import analytics from '~/services/analytics';
import toasts from '~/services/toasts';

interface DeleteScheduleModalProps {
  id: Schedule['id'];
}

const DeleteScheduleModal = ({ id }: DeleteScheduleModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const [deleteSchedule, { isSuccess, isLoading, isError }] = useDeleteScheduleMutation();

  const handleDeleteConfirm = useCallback(() => {
    analytics.trackEvent('schedule_deleted');
    deleteSchedule(id);
  }, [deleteSchedule, id]);

  useEffect(() => {
    if (isSuccess) {
      toasts.showSuccess({
        content: t('deleteScheduleModal.deletedToast', 'Schedule has been deleted successfully.'),
      });
      modal.hide();
    }
  }, [isSuccess, modal, t]);

  return (
    <ConfirmModal
      title={t('deleteScheduleModal.title', 'Do you want to remove this schedule?')}
      pending={isLoading}
      hasInternalServerError={isError}
      internalServerErrorMessage={t('deleteScheduleModal.errorMessage', 'Deleting failed')}
      onConfirm={handleDeleteConfirm}
      onCancel={modal.hide}
    />
  );
};

export default DeleteScheduleModal;
