import IconButton from '@bugbug/core/components/IconButton';
import SelectBase, { SelectOption as SelectOptionBase } from '@bugbug/core/components/Select';
import mixins from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

export const Select = styled(SelectBase)`
  button {
    height: auto;
    min-height: 38px;

    span {
      padding: 5px 0;
      word-break: break-all;
      white-space: initial;
    }
  }
`;

export const SelectOption = styled(SelectOptionBase)`
  > div > span {
    display: flex;
    flex-direction: column;
  }
`;
SelectOption.displayName = 'SelectOption';

export const Relation = styled.div``;

export const Fields = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr;
  flex: 1;
`;

export const Header = styled.div`
  ${mixins.layout.flexRowStretch};
  gap: 10px;
`;

export const Title = styled.div`
  ${mixins.layout.flexRowStretch};
  align-items: center;
  flex: 1;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const Actions = styled.div`
  ${mixins.layout.flexRowStretch};

  && button {
    width: 25px;
    height: 25px;

    &:not(:disabled) {
      color: ${({ theme }) => theme.colors.text.lighter} !important;
    }
  }

  button + button {
    margin-left: 2px !important;
  }
`;

export const DeleteButton = styled(IconButton).attrs(() => ({
  iconName: 'close',
}))`
  svg {
    color: inherit;
    min-width: 12px;
    min-height: 12px;
  }
`;

export const CustomizeButton = styled.button`
  outline: none;
  border: none;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.primary};
  border-top: 1px solid ${({ theme }) => theme.colors.neutrals['92']};
  background-color: ${({ theme }) => theme.colors.background.neutral};
  padding: 10px 33px 14px;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: none;
  display: flex;
  cursor: pointer;
  align-items: center;

  > svg {
    margin-right: 7px;
    top: 1px;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background: ${({ theme }) => theme.colors.neutrals['96']};
    outline: none;
    text-transform: none !important;
  }
`;
