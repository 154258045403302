import { normalize } from 'normalizr';

import type { Step } from '@bugbug/core/types/steps';

import { elementScreenshotSchema } from './test.schema';

// TODO: A type valid with BE response should be defined and replaced here
type DefaultParams = Record<string, string | string[]>;

export interface DefaultStepParams<T extends Step['type']> {
  type: T;
  params: DefaultParams;
}

export type DefaultStepsParamsList = DefaultStepParams<Step['type']>[];

export type GroupedStepsParams = {
  [TStepType in Step['type']]?: Partial<DefaultParams>[];
};

export const pickDefaultStepParams = <TStepType extends Step['type']>(
  stepType: TStepType,
  stepsParams: GroupedStepsParams,
  matchingParams: DefaultParams = {},
): DefaultParams => {
  const stepsParamsForType = stepsParams[stepType] ?? [];

  let selectedParams;

  if (stepsParamsForType.length === 1) {
    selectedParams = { ...stepsParamsForType[0] };
  } else {
    const matchingStepParams = stepsParamsForType.find((step) =>
      Object.keys(matchingParams).every((key) => step[key] === matchingParams[key]),
    );
    selectedParams = { ...matchingStepParams };
  }

  if ('waitingConditions' in selectedParams && selectedParams.waitingConditions) {
    selectedParams.waitingConditions = selectedParams.waitingConditions.filter(
      (condition) => condition.isGlobal || condition.isActive,
    );
  }

  return selectedParams || {};
};

export const groupDefaultStepsParams = (stepsParams: DefaultStepsParamsList): GroupedStepsParams =>
  stepsParams.reduce((grouped, stepParams) => {
    grouped[stepParams.type] = [...(grouped[stepParams.type] || []), stepParams.params];
    return grouped;
  }, {});

export const getElementsScreenshotsFromSteps = (
  steps: Record<Step['id'], Step> = {},
): Partial<Record<Step['id'], string>> => {
  const elementsData = normalize(Object.values(steps), [elementScreenshotSchema]);
  return elementsData.entities.elementsScreenshots || {};
};
