import TooltipBase, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

import type { Component } from '@bugbug/core/types/components';
import type { SideEffect } from '@bugbug/core/types/utils';
import EditableText from '~/components/EditableText';

export const Header = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  top: -4px;
  width: 100%;
  justify-content: space-between;
`;

export const MetadataContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 13px;
  margin: 16px 0;
  border-top: 1px solid ${COLOR.GRAY_29};
`;

export const Title = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 15px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
`;

export const Tooltip = styled(TooltipBase).attrs(() => ({
  anchor: TOOLTIP_ANCHOR.BOTTOM_CENTER,
  offset: 5,
}))``;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 50%;
`;

export const NameInput = styled(EditableText)<{
  max: number;
  onChange: SideEffect<Component['name']>;
  value: string;
}>`
  padding: 0 8px 4px;
  color: ${COLOR.BLACK};
  border-color: ${COLOR.TRANSPARENT};
  font-size: 20px;
  line-height: 24px;
  font-weight: ${FONT_WEIGHT.MEDIUM};

  &:hover {
    border-color: ${COLOR.GRAY_3};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.text.subtle};
  }
`;

export const ComponentBadge = styled.div`
  background: ${COLOR.YELLOW_2} !important;
  border: 2px solid ${COLOR.YELLOW_2};
  border-radius: 100px;
  color: ${COLOR.YELLOW_3};
  cursor: default;
  font-size: 12px;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  height: 23px;
  letter-spacing: -0.13px;
  line-height: 13px;
  padding: 2px 8px 4px 8px;
  text-transform: uppercase;
`;
