import { H1 } from '@bugbug/core/theme/typography';
import { PROJECT_SETTINGS_TAB } from '@bugbug/core/types/projects';
import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import type { Project, ProjectSettingsTab } from '@bugbug/core/types/projects';
import type { SideEffect } from '@bugbug/core/types/utils';
import Tabs, { Tab } from '~/components/Tabs';
import useAppRoutes from '~/hooks/useAppRoutes';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UIStateActions } from '~/modules/uiState/uiState.redux';

import BrowserSettings from './BrowserSettings';
import GeneralSettings from './GeneralSettings';
import { PROJECT_SETTINGS_TAB_LABEL as LABEL } from './ProjectSettings.constants';
import { Container, TabContent, TitleContainer, Actions } from './ProjectSettings.styled';
import ProjectSettingsActions from './ProjectSettingsActions';
import ScreenSizes from './ScreenSizes';
import SelectorsSettings from './SelectorsSettings';
import WaitingCondtitionsSettings from './WaitingCondtitionsSettings';

const ProjectSettings = () => {
  const { t } = useTranslation();

  const project = useAppSelector(selectSingleProject) as Project;
  const routes = useAppRoutes('projectSettings');

  const tabSlug = routes.params.settingsTabSlug;
  const isProperTab = tabSlug && PROJECT_SETTINGS_TAB.includes(tabSlug);
  const selectedTab: ProjectSettingsTab = isProperTab ? tabSlug : 'general';

  const dispatch = useAppDispatch();

  const handleTabChange = useCallback<SideEffect<unknown, ProjectSettingsTab>>(
    (_, tab) => {
      routes.push('projectSettings', {
        settingsTabSlug: tab,
      });
    },
    [routes],
  );

  useEffect(() => {
    dispatch(UIStateActions.setProjectSettingsTab(selectedTab));
  }, [dispatch, selectedTab]);

  useEffect(() => {
    if (isProperTab) return;
    routes.replace('projectSettings', {
      settingsTabSlug: selectedTab,
    });
  }, [isProperTab, routes, selectedTab]);

  return (
    <>
      <Helmet
        title={t('projectSettings.pageTitle', 'Project settings / {{- tab }}', {
          tab: LABEL[selectedTab],
        })}
      />
      <Container
        data-testid="ProjectSettings"
        title={
          <TitleContainer data-testid="ProjectSettings.Header">
            <H1>{t('projectSettings.title', 'Project settings')}</H1>
            <Actions>
              <ProjectSettingsActions project={project} />
            </Actions>
          </TitleContainer>
        }
        HeaderContent={
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {PROJECT_SETTINGS_TAB.map((tab) => (
              <Tab key={tab} label={LABEL[tab]} value={tab} />
            ))}
          </Tabs>
        }
      >
        <TabContent>
          {selectedTab === 'general' && <GeneralSettings />}
          {selectedTab === 'browser' && <BrowserSettings />}
          {selectedTab === 'screen-sizes' && <ScreenSizes />}
          {selectedTab === 'selectors' && <SelectorsSettings />}
          {selectedTab === 'waiting-conditions' && <WaitingCondtitionsSettings />}
        </TabContent>
      </Container>
    </>
  );
};

export default ProjectSettings;
