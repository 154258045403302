import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import PublicPage from '~/components/PublicPage';
import useActionState from '~/hooks/useActionState';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import { selectIsSocialLogin, selectIsAllowedEmailMarketing } from '~/modules/user/user.selectors';
import analytics from '~/services/analytics';
import urls from '~/views/urls';

import {
  OnboardingBox,
  ContinueButton,
  OnboardingSubtitle,
  OnboardingParagraph,
  OnboardingTitle,
  OnboardingCard,
} from '../Onboarding/Onboarding.styled';

import { Checkbox, WelcomeLogo } from './Welcome.styled';

export const Welcome = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(undefined, { keyPrefix: 'welcome' });
  const location = useLocation();
  const defaultMarketingConsent = useAppSelector(selectIsAllowedEmailMarketing) as boolean;
  const [marketingConsent, setMarketingConsent] = useState(defaultMarketingConsent);
  const isInvited = location.pathname === urls.welcomeInvited;

  useEffect(() => {
    setMarketingConsent(defaultMarketingConsent);
  }, [defaultMarketingConsent]);

  const isSocialLogin = useAppSelector(selectIsSocialLogin);
  const { isEmailConfirmed } = (location.state as { isEmailConfirmed: boolean }) || {};

  const handleMarketingConsentChange = useCallback((event) => {
    setMarketingConsent(event.target.checked);
  }, []);

  const handleContinue = useCallback(() => {
    dispatch(UserActions.updateMarketingConsentRequest(marketingConsent));
  }, [dispatch, marketingConsent]);

  const { isLoading, reset } = useActionState(UserActions.updateSettingsRequest, {
    reset: false,
  });

  useEffect(() => {
    if (isSocialLogin || isEmailConfirmed || isInvited) {
      analytics.trackEvent('sign_up_confirmed');
    }
  }, [isSocialLogin, isEmailConfirmed, isInvited]);

  useEffect(() => () => reset(), [reset]);

  return (
    <PublicPage>
      <Helmet>
        <title>{t('pageTitle', 'Welcome!')}</title>
      </Helmet>
      <OnboardingBox>
        <OnboardingCard>
          <div>
            <WelcomeLogo />
            <OnboardingTitle>{t('title', 'High five!')}</OnboardingTitle>
            <OnboardingSubtitle>
              {isSocialLogin || isInvited
                ? t('subTitleSocial', 'Thanks for signing up!')
                : t('subTitle', "You've confirmed your email address!")}
            </OnboardingSubtitle>
          </div>
          {isSocialLogin ? (
            <Checkbox checked={marketingConsent} onChange={handleMarketingConsentChange}>
              {t(
                'marketingConsentInfo',
                'I want to receive information about BugBug product updates.',
              )}
            </Checkbox>
          ) : (
            <OnboardingParagraph>
              {isInvited ? (
                t('descriptionInvited', 'Get ready for ultra-simple automated testing.')
              ) : (
                <Trans prefix="welcome" i18nKey="description">
                  Thanks for signing up.
                  <br />
                  Get ready for ultra-simple automated testing.
                </Trans>
              )}
            </OnboardingParagraph>
          )}
          <ContinueButton onClick={handleContinue} pending={isLoading}>
            {t('continueButton', 'Continue')}
          </ContinueButton>
        </OnboardingCard>
      </OnboardingBox>
    </PublicPage>
  );
};
