import { t } from 'i18next';

import type { SelectorsGroup } from '@bugbug/core/types/steps';

export const FIELD_NAMES = {
  SELECTORS: 'selectorsPresets',
};

export const RELATION_NAMES: Record<SelectorsGroup['relation'], string> = {
  descendant: t('elementQuery.elementRelation.descendant.label', 'child'),
  ancestor: t('elementQuery.elementRelation.ancestor.label', 'parent'),
  sibling: t('elementQuery.elementRelation.sibling.label', 'sibling'),
};
