import styled from 'styled-components';

import type { ExtendedRunStatus } from '../types/base';

import { RUN_STATUS_COLOR } from '../constants/status';

import { COLOR } from './colors';
import { FONT_WEIGHT } from './fonts';
import mixins from './mixins';

export const H1 = styled.h1`
  font-size: 20px;
  line-height: 31px;
  margin: 0;
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;

export const H3 = styled.h3`
  font-size: 16px;
  line-height: 21px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;

export const H6 = styled.h6`
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`;

export const RegularParagraph = styled.p`
  font-weight: ${FONT_WEIGHT.MEDIUM};
  font-size: 13px;
  line-height: 20px;
  margin: 0;
`;

export const ErrorMessage = styled(RegularParagraph)`
  color: ${COLOR.ERROR};
  margin: 12px 0;
  white-space: pre-wrap;
`;

export const TextWithExtraSpaces = styled.pre`
  font-size: inherit;
  color: inherit;
  margin: auto;
  overflow: initial;
  white-space: pre-line;
  overflow-wrap: anywhere;
`;

const getColor = ({ status }) => RUN_STATUS_COLOR[status] || 'inherit';

export const TextWithStatusColor = styled.span<{ status: ExtendedRunStatus }>`
  color: ${getColor};
  word-break: break-word;
  display: flex;
  flex-direction: column;
`;

export const Warning = styled.div`
  padding: 10px 10px 12px;
  background: ${COLOR.GRAY_21};
  color: ${COLOR.DARK_GRAY};
  border-radius: 5px;
  margin: 5px 0 14px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  a {
    margin-left: 4px;
  }
`;

// We have to use that helper because of issue in Trans component related to unescaped values
export const UnescapedValue = ({
  children = '',
  value = '',
}: {
  children?: string;
  value?: string;
}) => (
  // eslint-disable-next-line react/prop-types, react/no-danger
  <span dangerouslySetInnerHTML={{ __html: value || children }} />
);

export const Tag = styled.span`
  padding: 0 6px;
  padding-bottom: 1px;
  border-radius: 5px;
  background-color: ${COLOR.GRAY_35};
  color: ${COLOR.GRAY_12};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-right: 12px;
`;

export const TextBlock = styled.span`
  ${mixins.textEllipsis};
  display: block;
  word-break: break-word;
  padding: 2px 5px 3px;
  line-height: 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: rgba(0, 0, 0, 0.04);
`;

export const Field = styled.div`
  ${mixins.layout.flexRowStretch}
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.neutrals['92']};
  border-radius: 5px;
  transition: all 150ms ease-in-out;
  padding: 0 29px 2px 10px;
  height: 38px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;

  &:hover {
    // TODO: Should be migrated to new palette, but there is no color for this yet.
    border-color: ${COLOR.GRAY_10};
  }

  &:active,
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &[disabled],
  &[readonly] {
    border-color: ${({ theme }) => theme.colors.neutrals['92']};
    background-color: ${({ theme }) => theme.colors.neutrals['97']};
    resize: none;
    opacity: 0.8;
  }

  &[disabled] {
    pointer-events: none;
  }

  &[aria-invalid='true'] {
    border-color: ${({ theme }) => theme.colors.status.failure} !important;
  }
`;
