import * as T from '@bugbug/core/utils/toolbox';
import { isEmpty, prop, propEq, pick, propOr, both, complement } from 'ramda';
import { createSelector } from 'reselect';

import { RUN_ENV, SIGN_UP_ORIGIN } from '~/modules/constans';
import {
  selectCurrentOrganization,
  selectSubscription,
} from '~/modules/organization/organization.selectors';

const selectUserDomain = (state) => state.user;

export const selectUserToken = createSelector(selectUserDomain, prop('token'));

export const selectIsUserImpersonated = createSelector(
  selectUserDomain,
  (state) => state?.isImpersonated,
);

export const selectRegistrationEmail = createSelector(selectUserDomain, prop('registrationEmail'));

export const selectRegistrationOrganizationName = createSelector(
  selectUserDomain,
  prop('registrationOrganizationName'),
);

export const selectOnBoardingStep = createSelector(selectUserDomain, prop('onboardingStep'));

export const selectIsUserLoggedIn = createSelector(selectUserToken, Boolean);

export const selectUserSettings = createSelector(selectUserDomain, prop('settings'));

export const selectUserFlags = createSelector(selectUserDomain, propOr({}, 'flags'));

export const selectHasNotStartedTest = createSelector(
  selectUserFlags,
  both(complement(prop('testStartedLocal')), complement(prop('testStartedServer'))),
);

export const selectHasNotCreatedProject = createSelector(
  selectUserFlags,
  complement(prop('projectCreated')),
);

export const selectHasNotCreatedTest = createSelector(
  selectUserFlags,
  complement(prop('testCreated')),
);

export const selectHasNotStartedRecording = createSelector(
  selectUserFlags,
  complement(prop('testRecorded')),
);

export const selectFirstTestRunPassed = createSelector(
  selectUserFlags,
  T.prop('firstTestRunPassed'),
);

export const selectSecondTestRunPassed = createSelector(
  selectUserFlags,
  T.prop('secondTestRunPassed'),
);

export const selectHasTooltipAfterFailShown = createSelector(
  selectUserFlags,
  T.prop('docsTooltipAfterFailShown'),
);

export const selectHasExpiringTrialModalShown = createSelector(
  selectUserFlags,
  T.prop('expiringTrialModalShown'),
);

export const selectIsAllowedEmailMarketing = createSelector(
  selectUserSettings,
  propOr(false, 'isAllowedEmailMarketing'),
);

export const selectIsTutorialEnabled = createSelector(
  selectUserSettings,
  (settings) => !(settings?.hideTutorial ?? true),
);

export const selectIsLocalEnv = createSelector(
  selectUserSettings,
  propEq('runMode', RUN_ENV.LOCAL),
);

export const selectUserData = createSelector(selectUserDomain, prop('single'));

export const selectIsSocialLogin = createSelector(selectUserData, (user) =>
  [SIGN_UP_ORIGIN.GITHUB, SIGN_UP_ORIGIN.GOOGLE].includes(user.origin),
);

export const selectIsInvited = createSelector(selectUserData, T.prop('isInvited'));

export const selectCurrentOnboardingStep = createSelector(selectUserData, prop('onboardingStep'));

export const selectOnboardingData = createSelector(
  selectUserData,
  selectCurrentOrganization,
  (user, organization) => ({
    ...pick(['firstName', 'lastName', 'source'], user),
    organization: pick(['companyName'], organization || {}),
  }),
);

export const selectUserProfile = createSelector(
  selectUserData,
  selectCurrentOrganization,
  selectSubscription,
  (user = {}, organization = {}, subscription = {}) => ({
    user,
    organization,
    subscription,
  }),
);

export const selectUserName = createSelector(selectUserData, (user) => {
  if (isEmpty(user)) {
    return '';
  }
  return user.email;
});

export const selectUserId = createSelector(selectUserData, prop('id'));
