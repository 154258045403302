import styled from 'styled-components';

import TriangleOutlineIcon from '~/images/playback-triangle-outline.svg?react';
import TriangleIcon from '~/images/playback-triangle.svg?react';

export const Line = styled.div<{ $paused: boolean }>`
  width: 100%;
  background-color: ${({ $paused, theme }) =>
    $paused ? theme.colors.status.paused : theme.colors.status.recording};
  height: 2px;
  position: relative;
  z-index: 900;
`;

export const LinePlaceholder = styled.div<{ $paused: boolean }>`
  height: 2px;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(
    to left,
    ${({ theme, $paused }) => ($paused ? theme.colors.accent : theme.colors.status.recording)} 4px,
    transparent 0%
  );
  background-position: bottom;
  background-size: 8px 2px;
  background-repeat: repeat-x;
  opacity: 0.6;
`;

export const DragHandle = styled.div`
  position: absolute;
  left: -24px;
  top: -6.5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  cursor: grab;
  pointer-events: auto;
  width: 26px;
`;

export const DropZone = styled.div<{ visible: boolean; addStepDragOffset: boolean }>`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  position: absolute;
  bottom: calc(-50% - ${(props) => (props.addStepDragOffset ? '52px' : '0px')});
  left: -2px;
  right: -2px;
  height: 100%;
  z-index: 800;
  display: flex;
  align-items: center;
  pointer-events: none;
`;

export const CursorTriangleIcon = styled(TriangleIcon)<{ $paused: boolean }>`
  color: ${({ $paused, theme }) =>
    $paused ? theme.colors.status.paused : theme.colors.status.recording};
`;

export const RunningCursorTriangleIcon = styled(TriangleIcon)<{
  $index: number;
  $visible: boolean;
}>`
  color: ${({ theme }) => theme.colors.text.secondaryAllCaps};
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  position: absolute;
  top: 0;
  transform: translateY(calc(-7.5px + calc(52px * ${(props) => props.$index})));
  left: -19px;
  z-index: 100;
  transition: all 0.2s;
  cursor: default;
`;

export const CursorTriangleIconPlaceholder = styled(TriangleIcon)<{
  $appearsAfterStep: boolean;
  $paused: boolean;
}>`
  position: absolute;
  left: -17px;
  top: ${(props) => (props.$appearsAfterStep ? '18.5px' : '14.5px')};
  color: ${({ theme, $paused }) => ($paused ? theme.colors.accent : theme.colors.status.recording)};
  opacity: 0.6;
`;

const TargetLine = styled.div<{
  $visible: boolean;
}>`
  height: 2px;
  width: 100%;
  position: absolute;
  background-position: bottom;
  background-size: 8px 2px;
  background-repeat: repeat-x;
  pointer-events: none;
  bottom: 0px;
  left: 0;
  bottom: -1px;
  z-index: 700;
  transition: opacity 0.2s;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
`;

const CursorTriangleOutlineIcon = styled(TriangleOutlineIcon)`
  top: -6px;
  position: absolute;
  left: -19px;
  z-index: 700;
  transition: all 0.2s;
`;

export const CursorPauseTriangleOutlineIcon = styled(CursorTriangleOutlineIcon)`
  color: ${({ theme }) => theme.colors.text.secondaryAllCaps};
`;

export const PauseTargetLine = styled(TargetLine)`
  background-image: linear-gradient(
    to left,
    ${({ theme }) => theme.colors.border.dark} 4px,
    transparent 0%
  );
`;

export const CursorRecordingTriangleOutlineIcon = styled(CursorTriangleOutlineIcon)`
  color: ${({ theme }) => theme.colors.status.recording};
`;

export const RecordingTargetLine = styled(TargetLine)`
  background-image: linear-gradient(
    to left,
    ${({ theme }) => theme.colors.status.recording} 4px,
    transparent 0%
  );
`;
