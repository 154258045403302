import { omit } from 'ramda';
import { useCallback, useMemo, useRef } from 'react';

import type { SortByDropdownItemType } from '~/components/SortByDropdown/SortByDropdown.types';

import useQueryString from '~/hooks/useQueryString';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import { selectUIStateForComponent } from '~/modules/uiState/uiState.selectors';

interface QueryParamsRecentSort {
  sortBy?: string;
  desc?: string;
}

export interface RecentSort {
  sortBy: string;
  desc: boolean;
}

const emptySortState: RecentSort = {
  sortBy: '',
  desc: false,
};

export const useRecentSort = ({
  cacheName = 'cached',
  defaultConfig = emptySortState,
  columns,
}: {
  cacheName?: string;
  defaultConfig?: RecentSort;
  columns?: SortByDropdownItemType[];
}) => {
  const queryParams = useRef<QueryParamsRecentSort>({ sortBy: '', desc: '' });
  queryParams.current = useQueryString();
  const cachedSortByKey = `table.${cacheName}.sortBy`;
  const updateQueryParams = useUpdateQueryString();
  const dispatch = useAppDispatch();

  const recentSort = useAppSelector(selectUIStateForComponent(cachedSortByKey)) as RecentSort;

  const initialSortConfig = useMemo(
    () => ({
      sortBy: queryParams.current.sortBy ?? recentSort.sortBy ?? defaultConfig.sortBy,
      desc: Boolean(
        queryParams.current.desc
          ? queryParams.current.desc === 'true'
          : recentSort.desc ?? defaultConfig.desc,
      ),
    }),
    [recentSort.sortBy, recentSort.desc, defaultConfig.sortBy, defaultConfig.desc],
  );

  const availableOptions = useMemo(
    () => (columns ? columns.map(({ id }) => id) : undefined),
    [columns],
  );

  const updateRecentSort = useCallback(
    (sortSettings) => {
      if (
        !availableOptions ||
        availableOptions.includes(sortSettings.sortBy) ||
        defaultConfig.sortBy === sortSettings.sortBy
      ) {
        updateQueryParams(sortSettings);
        dispatch(
          UIStateActions.setUIState({
            componentName: cachedSortByKey,
            data: omit(['query'], sortSettings),
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      recentSort.sortBy,
      recentSort.desc,
      availableOptions,
      defaultConfig.sortBy,
      updateQueryParams,
      cachedSortByKey,
    ],
  );

  return {
    ...initialSortConfig,
    updateRecentSort,
  };
};
