import Button from '@bugbug/core/components/Button';
import Link from '@bugbug/core/components/Link';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { EditAlertFormValues } from '../../EditAlertFormValues.types';

import useAppRoutes from '~/hooks/useAppRoutes';
import { useLazyGetAuthUrlQuery } from '~/modules/integrations/integrations.api';
import { useAppDispatch } from '~/modules/store';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import toasts from '~/services/toasts';

import { Description } from '../EditAlertModalThenContent.styled';

const EditAlertModalActionSlackAuth = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<EditAlertFormValues>();
  const { params, routeName } = useAppRoutes('alert');
  const dispatch = useAppDispatch();

  const [getAuthUrl, getAuthUrlState] = useLazyGetAuthUrlQuery();

  const handleConnectSlack = async () => {
    const authUrl = await getAuthUrl({ type: 'slack', projectId: params.projectId });

    if (authUrl.data) {
      dispatch(
        UIStateActions.setIntegrationTrigger({
          routeName,
          params: {
            ...params,
            alertId: values.id ?? 'new',
          },
          queryParams: { action: values.action },
        }),
      );
      window.location.replace(authUrl.data);
    }
  };

  useEffect(() => {
    if (getAuthUrlState.isError) {
      toasts.showError({
        content: t(
          'editAlertModal.slack.getAuthError',
          'Unexpected error occurred while getting auth url',
        ),
      });
    }
  }, [getAuthUrlState.isError, t]);

  return (
    <>
      <Description>
        <Trans i18nKey="editAlertModal.slack.integration">
          You need to set up your{' '}
          <Link to={t('default.docs.slack.webhooks')}>Slack integration</Link> first.
        </Trans>
      </Description>
      <Button
        variant="primary"
        bordered
        onClick={handleConnectSlack}
        iconName="openWindow"
        iconPosition="right"
      >
        {t('editAlertModal.slack.connectButton', 'Connect your Slack account')}
      </Button>
    </>
  );
};
export default EditAlertModalActionSlackAuth;
