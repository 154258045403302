import { is, pathEq, path } from 'ramda';
import { call, put, takeLatest, select, all, take } from 'redux-saga/effects';

import { RUN_ENV } from '~/modules/constans';
import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import { selectSingleProject, selectSingleProjectId } from '~/modules/project/project.selectors';
import { SuiteRunActions, SuiteRunTypes } from '~/modules/suiteRun/suiteRun.redux';
import { selectUserId } from '~/modules/user/user.selectors';
import selectWebsocketChannelName from '~/modules/websocket/websocket.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import api from '~/services/api';
import toasts from '~/services/toasts';
import {
  ACTIONS,
  ENTITIES,
  showInternalServerError,
  showPendingRequestToast,
} from '~/services/toasts/internalServerError';
import i18n from '~/translations';
import { isTriggeredByCurrentUser } from '~/utils/runs';

import { schedulesApi } from '../schedules/schedules.api';
import { suitesApi } from '../suites/suites.api';

import { ERROR_CODE } from './suite.constants';
import { SuiteActions, SuiteTypes } from './suite.redux';
import { selectLastRunsBySuitesIds } from './suite.selectors';

export const showPendingSuite = showPendingRequestToast(ENTITIES.SUITE);

export const showSuiteError = showInternalServerError(ENTITIES.SUITE);
export const showSuitesError = showInternalServerError(ENTITIES.SUITES);
export const showAllSuitesError = showInternalServerError(ENTITIES.ALL_SUITES);

// TODO: Temp solution; It will be removed after migration to rtk-query
function* refreshRtkQuerySuitesAndSchedules() {
  const project = yield select(selectSingleProject);
  yield put(
    suitesApi.endpoints.getSuites.initiate(
      {
        projectId: project.id,
        ordering: 'name',
        pagination: 'off',
      },
      { forceRefetch: true },
    ),
  );
  yield put(
    schedulesApi.endpoints.getSchedules.initiate(
      {
        projectId: project.id,
        ordering: 'name',
      },
      { forceRefetch: true },
    ),
  );
}

export function* getSingle({ id }) {
  try {
    yield put(SuiteActions.resetSingle());
    const { data: responseData } = yield call(api.suites.get, id);
    yield put(SuiteActions.getSingleSuccess(responseData));
  } catch (error) {
    yield put(SuiteActions.getSingleFailure(error));
  }
}

// TODO: Migrate related views to new rtk-query implementation
export function* getList({ query, sortBy, descOrder = false }) {
  try {
    const projectId = yield select(selectSingleProjectId);
    let ordering = sortBy;

    if (descOrder) {
      ordering = `-${ordering}`;
    }

    const params = {
      project_id: projectId,
      query,
      ordering,
      pagination: 'off',
    };
    const { data: responseData } = yield call(api.suites.getList, params);
    yield put(SuiteActions.getListSuccess(responseData));
  } catch (error) {
    yield put(SuiteActions.getListFailure(error));
  }
}

export function* create({ data }) {
  try {
    const project = yield select(selectSingleProject);

    const suiteData = {
      projectId: project.id,
      ...data,
    };
    const { data: createdSuite } = yield call(api.suites.create, suiteData);

    yield call(toasts.showSuccess, {
      content: i18n.t('suite.createSuccessInfo', 'Suite has been created successfully.'),
    });
    yield put(SuiteActions.createSuccess(createdSuite));
  } catch (error) {
    yield put(SuiteActions.createFailure(error));
  }
}

export function* remove({ id }) {
  try {
    yield call(api.suites.remove, id);
    yield put(SuiteActions.removeSuccess(id));
    yield call(toasts.showSuccess, {
      content: i18n.t('suite.deleteSuccessInfo', 'Suite has been deleted successfully.'),
    });
  } catch (error) {
    yield call(showSuiteError, ACTIONS.DELETE, error);
    yield put(SuiteActions.removeFailure(error));
  }
}

export function* removeSelected({ suitesIds }) {
  try {
    yield call(api.suites.removeSelected, { suitesIds });
    yield put(SuiteActions.removeSelectedSuccess(suitesIds));
    yield call(toasts.showSuccess, {
      content: i18n.t('suite.selectedDeleteSuccessInfo', 'Suites have been deleted successfully.'),
    });
  } catch (error) {
    yield call(showSuitesError, ACTIONS.DELETE, error);
    yield put(SuiteActions.removeSelectedFailure(error));
  }
}

export function* removeAll() {
  try {
    const project = yield select(selectSingleProject);
    yield call(api.suites.removeAll, { projectId: project.id });
    yield put(SuiteActions.removeAllSuccess());
    yield call(toasts.showSuccess, {
      content: i18n.t('suite.allDeleteSuccessInfo', 'All suites have been deleted successfully.'),
    });
  } catch (error) {
    yield call(showAllSuitesError, ACTIONS.DELETE, error);
    yield put(SuiteActions.removeAllFailure(error));
  }
}

export function* update({ id, data }) {
  try {
    const { data: responseData } = yield call(api.suites.update, id, data);

    yield call(toasts.showSuccess, {
      content: i18n.t('suite.updateSuccessInfo', 'Suite has been updated successfully.'),
    });

    yield put(SuiteActions.updateSuccess(responseData));
  } catch (error) {
    yield call(showSuiteError, ACTIONS.UPDATE, error);
    yield put(SuiteActions.updateFailure(error));
  }
}

export function* startRunning({ id, options }) {
  const pendingToast = yield call(showPendingSuite, ACTIONS.START);
  try {
    const channelName = yield select(selectWebsocketChannelName);
    const { data: startedSuiteRun } = yield call(api.suites.run, id, {
      channelName,
      ...options,
    });
    const organizationId = yield select(selectCurrentOrganizationId);
    yield put(SuiteActions.runSuccess(startedSuiteRun.id));
    yield call(pendingToast.dismiss);
    yield call(toasts.suites.showSuiteStarted, startedSuiteRun, organizationId);
  } catch (error) {
    const errorMessage = path(['response', 'data', 0, 'message'], error);

    if (errorMessage) {
      yield call(pendingToast.error, errorMessage);
    } else {
      yield call(showSuiteError, ACTIONS.START, error, false);
      yield call(pendingToast.dismiss);
    }

    yield put(SuiteActions.runFailure(error));
  }
}

export function* clone({ id }) {
  try {
    yield call(analytics.trackEvent, TRACK_EVENT_TYPE.DUPLICATE_SUITE);
    const { data } = yield call(api.suites.clone, id);
    yield put(SuiteActions.cloneSuccess(data));
  } catch (error) {
    yield put(SuiteActions.cloneFailure(error));
  }
}
export function* runSelected({ suitesIds, params = {} }) {
  try {
    const channelName = yield select(selectWebsocketChannelName);
    yield call(api.suites.runSelected, {
      suitesIds,
      channelName,
      runMode: RUN_ENV.LOCAL,
      ...params,
    });

    yield call(toasts.suites.showSuitesStarted, suitesIds);
    yield put(SuiteActions.runSelectedSuccess());
  } catch (error) {
    const shouldShowClientError = pathEq(
      ['response', 'data', 0, 'code'],
      ERROR_CODE.CHANNEL_NOT_FOUND,
    )(error);
    yield call(showSuiteError, ACTIONS.START, error, shouldShowClientError);
    yield put(SuiteActions.runSelectedFailure(error));
  }
}

export function* stop({ ids }) {
  try {
    let suiteRunsIds;
    if (ids) {
      suiteRunsIds = yield select(selectLastRunsBySuitesIds(ids));
    }

    yield put(SuiteRunActions.stopRequest(suiteRunsIds));
    const result = yield take([SuiteRunTypes.STOP_SUCCESS, SuiteRunTypes.STOP_FAILURE]);

    if (result.type === SuiteRunTypes.STOP_FAILURE) {
      throw result.error;
    }

    yield put(SuiteActions.stopSuccess(suiteRunsIds));
  } catch (error) {
    yield call(showSuiteError, ACTIONS.STOP, error);
    yield put(SuiteActions.stopFailure(error));
  }
}

export function* getTests() {
  try {
    const projectId = yield select(selectSingleProjectId);
    const { data } = yield call(api.suites.getTests, {
      project_id: projectId,
      fields: ['id', 'name'],
      pagination: 'off',
    });
    yield put(SuiteActions.getTestsSuccess(data));
  } catch (error) {
    yield put(SuiteActions.getTestsFailure(error));
  }
}

export function* updated({ data }) {
  const currentUserId = yield select(selectUserId);
  const results = is(Array, data) ? data : [data];
  const modifiedResult = results.map((result) => {
    const extraData = {
      isTriggeredByCurrentUser: isTriggeredByCurrentUser(result, currentUserId),
    };
    return { ...result, ...extraData };
  });

  yield put(SuiteActions.updatedSuccess(modifiedResult));
}

export default function* suiteSagas() {
  yield all([
    yield takeLatest(SuiteTypes.GET_SINGLE_REQUEST, getSingle),
    yield takeLatest(SuiteTypes.GET_LIST_REQUEST, getList),
    yield takeLatest(SuiteTypes.REMOVE_REQUEST, remove),
    yield takeLatest(SuiteTypes.REMOVE_SELECTED_REQUEST, removeSelected),
    yield takeLatest(SuiteTypes.REMOVE_ALL_REQUEST, removeAll),
    yield takeLatest(SuiteTypes.CREATE_REQUEST, create),
    yield takeLatest(SuiteTypes.UPDATE_REQUEST, update),
    yield takeLatest(SuiteTypes.CLONE_REQUEST, clone),
    yield takeLatest(SuiteTypes.RUN_REQUEST, startRunning),
    yield takeLatest(SuiteTypes.RUN_SELECTED_REQUEST, runSelected),
    yield takeLatest(SuiteTypes.STOP_REQUEST, stop),
    yield takeLatest(SuiteTypes.GET_TESTS_REQUEST, getTests),

    yield takeLatest(
      [
        SuiteTypes.CREATE_SUCCESS,
        SuiteTypes.CLONE_SUCCESS,
        SuiteTypes.UPDATE_SUCCESS,
        SuiteTypes.REMOVE_SUCCESS,
        SuiteTypes.REMOVE_SELECTED_SUCCESS,
        SuiteTypes.REMOVE_ALL_SUCCESS,
      ],
      refreshRtkQuerySuitesAndSchedules,
    ),
    //
    yield takeLatest(SuiteRunTypes.UPDATED, updated),
  ]);
}
