import { CommonActions } from '@bugbug/core/actions/actions';
import { DropdownHeader, DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { noop } from '@bugbug/core/utils/toolbox';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type useTestRunner from '~/hooks/useTestRunner';

import type { Test } from '@bugbug/core/types/tests';
import { dispatchInExtension } from '~/modules/extension/extension.dispatch';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { selectSingleTest, selectSortedSteps } from '~/modules/test/test.selectors';
import { selectPlaybackCursorPosition } from '~/modules/uiState/uiState.selectors';

import * as S from '../RowActiveBorder.styled';

interface RecordingAndPauseMenuProps {
  groupId: string;
  groupIndex: number;
  paused: boolean;
  running: boolean;
  nextStepIndex: number;
  afterStepId: string;
  disabledStepOptions?: boolean;
  disablePlaybackOptions?: boolean;
  onResumeLocalRunner?: ReturnType<typeof useTestRunner>['resumeLocalRunner'];
}

export const RecordingAndPauseMenu = ({
  groupId,
  groupIndex,
  paused: isPaused,
  running: isRunning,
  nextStepIndex,
  afterStepId,
  disablePlaybackOptions,
  disabledStepOptions,
  onResumeLocalRunner = noop,
}: RecordingAndPauseMenuProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const test = useAppSelector(selectSingleTest) as Test & { groups: string[] };
  const playbackCursorPosition = useAppSelector(selectPlaybackCursorPosition);
  const sortedSteps = useAppSelector(selectSortedSteps);

  const nextActiveStep = sortedSteps.slice(nextStepIndex).find((s) => s.isActive) ?? null;

  const handleSetPlaybackHere = async () => {
    const action = CommonActions.setPlaybackCursorPosition({
      afterStep: {
        id: afterStepId,
        groupId,
      },
      runningStep: null,
      nextStep: nextActiveStep
        ? {
            id: nextActiveStep.id,
            groupId: nextActiveStep.groupId,
          }
        : null,
    });

    dispatch(action);
    await dispatchInExtension(action);

    if (!isPaused) {
      await dispatchInExtension(
        CommonActions.setRecordingCursorPosition({
          afterStepId,
          groupId,
        }),
      );
    }
  };

  const isResumeAndPauseHereAvailable = useMemo(
    () =>
      afterStepId
        ? sortedSteps.findIndex((s) => s.id === afterStepId) >
          sortedSteps.findIndex((s) => s.id === playbackCursorPosition?.afterStep?.id)
        : groupIndex > test.groups.indexOf(playbackCursorPosition?.afterStep?.groupId ?? ''),
    [
      afterStepId,
      groupIndex,
      playbackCursorPosition?.afterStep?.groupId,
      playbackCursorPosition?.afterStep?.id,
      sortedSteps,
      test.groups,
    ],
  );

  const handleRecordFromPaused = useCallback(async () => {
    const action = CommonActions.setPlaybackCursorPosition({
      afterStep: {
        id: afterStepId,
        groupId,
      },
      runningStep: null,
      nextStep: nextActiveStep
        ? {
            id: nextActiveStep.id,
            groupId: nextActiveStep.groupId,
          }
        : null,
    });

    dispatch(action);
    await dispatchInExtension(action);

    if (!isPaused) {
      await dispatchInExtension(
        CommonActions.setRecordingCursorPosition({
          afterStepId,
          groupId,
        }),
      );
    } else {
      await dispatchInExtension(CommonActions.resumeRecording());
    }
  }, [afterStepId, dispatch, groupId, isPaused, nextActiveStep]);

  const handleResumeFromHere = useCallback(() => {
    if (!nextActiveStep?.id) return;
    onResumeLocalRunner({
      startFromStepId: nextActiveStep.id,
      stopAfterStepId: null,
      stopAction: 'finish',
      withRedirect: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextActiveStep?.id, onResumeLocalRunner]);

  const handleResumeAndPauseHere = useCallback(() => {
    if (playbackCursorPosition?.nextStep?.id) {
      onResumeLocalRunner({
        startFromStepId: playbackCursorPosition?.nextStep?.id,
        stopAfterStepId: afterStepId,
        stopAction: 'pause',
        pause: {
          afterStepId,
          atGroupId: groupId,
        },
        withRedirect: true,
      });
      dispatch(CommonActions.setPausePosition({ afterStepId, atGroupId: groupId }));
    } else {
      handleSetPlaybackHere();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onResumeLocalRunner, dispatch]);

  return (
    <>
      {isPaused && (
        <DropdownHeader>{t('rowActiveBorder.header.pausedMode', 'Paused mode')}</DropdownHeader>
      )}
      <DropdownItem
        data-testid="RowActiveBorder.RecordFromHere"
        onClick={handleRecordFromPaused}
        disabled={disabledStepOptions || isRunning || disablePlaybackOptions}
      >
        <S.Dot />
        {t('rowActiveBorder.option.recordFromHere', 'Record from here')}
      </DropdownItem>
      <DropdownItem
        data-testid="RowActiveBorder.SetPlaybackHere"
        onClick={handleSetPlaybackHere}
        disabled={disablePlaybackOptions}
      >
        <S.PlaybackIcon />
        {t('rowActiveBorder.option.setPlaybackHere', 'Set playback here')}
      </DropdownItem>
      <DropdownItem
        data-testid="RowActiveBorder.ResumeFromHere"
        disabled={!isPaused || disablePlaybackOptions || !nextActiveStep?.id}
        onClick={handleResumeFromHere}
      >
        <Icon name="play" />
        {t('rowActiveBorder.option.resumeFromHere', 'Resume from here')}
      </DropdownItem>
      <DropdownItem
        data-testid="RowActiveBorder.ResumeAndPauseHere"
        disabled={!isPaused || !isResumeAndPauseHereAvailable || disablePlaybackOptions}
        onClick={handleResumeAndPauseHere}
      >
        <Icon name="play" />
        {t('rowActiveBorder.option.resumeAndPauseHere', 'Resume and pause here')}
      </DropdownItem>
    </>
  );
};
