import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { lineClamp } from '@bugbug/core/theme/mixins';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import * as R from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 10px 10px 16px 16px;
  border: 2px solid ${COLOR.GRAY_1};
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 200ms ease;

  ${styleWhenTrue(
    R.prop('onClick'),
    css`
      cursor: pointer;
    `,
  )}

  :hover {
    border-color: ${COLOR.PRIMARY_LIGHT};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Headline = styled.h3`
  font-size: 15px;
  font-weight: ${FONT_WEIGHT.BOLD};
  margin: 0;
  padding-right: 12px;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Description = styled.div`
  color: ${COLOR.GRAY_12};
  margin: 0;
  ${lineClamp({ lines: 2 })}
  word-break: break-word;
  display: flex;
  flex-direction: column;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;
