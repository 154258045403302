import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgPerkLocked = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 25, viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M13.802 5.30263C13.6496 5.29558 13.0097 5.23656 12.9263 5.30209C12.8906 5.37956 12.928 5.82298 12.8831 6.00485C13.1868 6.00516 13.4993 5.99039 13.802 6.01241V5.30263Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M8.36187 11.4766L7.51978 11.4774L7.51865 12.3218L8.33993 12.3227C8.33903 12.3836 8.32117 12.509 8.34925 12.5624C8.35134 12.4878 8.33736 12.4012 8.35994 12.33L8.3938 12.3184L8.37048 12.3121C8.30265 12.2151 8.34854 11.6304 8.36187 11.4766Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M16.4991 18.6953C16.4897 18.6784 16.4932 18.6867 16.4871 18.6651C16.4856 18.6596 16.4842 18.654 16.4827 18.6484L16.4721 18.6721C16.1985 18.7194 15.9114 18.6859 15.6354 18.678C15.6085 18.9728 15.6468 19.2795 15.6296 19.5774C15.9191 19.5737 16.2087 19.5757 16.4983 19.5762C16.5044 19.2831 16.4991 18.9886 16.4991 18.6953Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M8.3508 18.6795C8.07436 18.6608 7.79534 18.6644 7.51831 18.6621C7.5189 18.7863 7.52802 18.9172 7.50934 19.0401L7.50667 19.5868L8.35479 19.5841C8.3497 19.2873 8.33634 18.9852 8.3606 18.6892L8.3508 18.6795Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M10.1874 6.01953C9.88901 6.03003 9.58706 6.02276 9.28832 6.02389C9.29285 6.27854 9.25591 6.66933 9.29019 6.90258L10.1887 6.90278C10.197 6.60935 10.19 6.3131 10.1874 6.01953Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M13.7966 6.01172L13.8068 6.9015L14.7141 6.90216L14.7141 6.02273C14.4089 6.01727 14.1011 6.03318 13.7966 6.01172Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M15.6204 6.90901C15.3213 6.87618 15.0153 6.90178 14.7156 6.9071C14.7573 7.52776 14.7305 8.18595 14.7289 8.8109L14.7351 11.451C15.0191 11.4693 15.3439 11.5011 15.6243 11.4418L15.6204 6.90901Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M13.8019 5.10742L10.1951 5.10754C10.1946 5.37772 10.1875 5.64969 10.1969 5.91968C10.1956 5.95812 10.1875 5.97385 10.212 6.00567C10.4584 6.01999 10.7105 6.00743 10.9574 6.00612C11.3194 6.00423 11.6822 6.00907 12.0441 6.00126L12.8831 6.00253C12.928 5.82066 12.8906 5.37724 12.9263 5.29976C13.0097 5.23424 13.6496 5.29325 13.802 5.3003L13.8019 5.10742Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M11.9912 5.29042C11.3883 5.33648 10.8011 5.28116 10.1989 5.28765C10.199 5.49689 10.2106 5.71309 10.1931 5.9214C10.1919 5.95984 10.1837 5.97558 10.2082 6.00739C10.4546 6.02172 10.7067 6.00915 10.9536 6.00785C11.3156 6.00595 11.6784 6.01079 12.0403 6.00298C11.9685 5.77511 11.9914 5.52678 11.9912 5.29042Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M9.25122 6.92188L8.37734 6.9223L8.37834 9.869C8.37819 10.3969 8.39888 10.9363 8.36769 11.4628C8.43715 11.4377 8.58508 11.4634 8.66307 11.4666C8.85886 11.4747 9.05418 11.4632 9.24966 11.4525V7.93465C9.25142 7.59735 9.24375 7.259 9.25122 6.92188Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M7.51393 12.3223L7.51552 16.1634C7.51529 16.7161 7.50037 17.2702 7.50779 17.8226C7.77984 17.7837 8.065 17.8059 8.33861 17.8154C8.32293 17.2832 8.33575 16.7472 8.33573 16.2146C8.33573 15.9164 8.30878 15.5696 8.3458 15.2758C8.35633 14.9733 8.34705 14.6673 8.34708 14.3644L8.34453 12.5629C8.31645 12.5095 8.33431 12.3841 8.33522 12.3231L7.51393 12.3223Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M14.7213 11.4494L11.0819 11.4499L9.90832 11.4499C9.69076 11.4498 9.47076 11.4425 9.25351 11.4527C9.05803 11.4633 8.86271 11.4749 8.66692 11.4668C8.58893 11.4635 8.441 11.4379 8.37154 11.463L8.35908 11.4782C8.34575 11.632 8.29987 12.2167 8.36769 12.3138L8.39101 12.32C8.72492 12.3026 9.06213 12.3117 9.39654 12.3114L11.4368 12.3222C12.5336 12.329 13.6359 12.2919 14.7317 12.3275C14.6883 12.1538 14.7199 11.6544 14.7213 11.4494Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M15.7819 12.3597L11.4787 12.3629L10.091 12.3633C9.83379 12.3626 9.57366 12.3084 9.31678 12.3835C9.08564 12.4621 8.8547 12.5473 8.62319 12.4874C8.53099 12.4636 8.35607 12.2744 8.27394 12.4594L8.25921 12.5717C8.24345 13.705 8.18919 18.014 8.26939 18.7293L8.29697 18.7754C8.69177 18.6467 9.0905 18.714 9.48591 18.7118L11.8983 18.7912C13.1951 18.8415 14.4986 18.568 15.7942 18.8302C15.7429 17.5501 15.7803 13.8702 15.7819 12.3597Z", fill: "white" }),
  /* @__PURE__ */ jsx("path", { d: "M15.636 18.6787C15.3321 18.6815 15.022 18.6679 14.719 18.6831C14.4494 18.7096 14.1645 18.693 13.8932 18.6933L12.3818 18.6944L9.78671 18.6954C9.31068 18.6953 8.83242 18.7046 8.35664 18.6913C8.33238 18.9873 8.34574 19.2893 8.35084 19.5862L13.0759 19.5778C13.927 19.5766 14.7792 19.5668 15.6302 19.5781C15.6474 19.2802 15.609 18.9735 15.636 18.6787Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M16.4956 11.4453L14.7196 11.4481C14.7183 11.6531 14.6866 12.1524 14.73 12.3262L14.7358 12.3429C14.7654 12.742 14.734 13.1388 14.7334 13.5378L14.7334 16.0046L14.7353 17.7303C14.7367 18.0371 14.7764 18.3811 14.7185 18.6822C15.0216 18.667 15.3317 18.6806 15.6355 18.6778C15.9115 18.6857 16.1986 18.7192 16.4722 18.6719L16.4828 18.6482C16.4843 18.6538 16.4857 18.6594 16.4872 18.6649C16.4933 18.6865 16.4898 18.6782 16.4992 18.6951C16.4907 18.4481 16.5008 18.1982 16.5012 17.951L16.4988 16.556L16.4956 11.4453Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M15.6393 12.3387C15.4666 12.3418 15.2935 12.3461 15.1208 12.3439C14.9951 12.3424 14.8608 12.3276 14.7361 12.3426C14.7657 12.7416 14.7343 13.1385 14.7337 13.5374L14.7337 16.0042L14.7356 17.7299C14.737 18.0367 14.7767 18.3807 14.7188 18.6818C15.0219 18.6666 15.332 18.6802 15.6358 18.6775C15.6531 18.4864 15.638 18.2829 15.64 18.0904L15.6458 16.6678L15.6393 12.3387Z", fill: "#CEC2C1" }),
  /* @__PURE__ */ jsx("path", { d: "M8.34679 15.2754C8.30976 15.5692 8.33671 15.916 8.33671 16.2142C8.33674 16.7468 8.32392 17.2828 8.3396 17.815C8.06599 17.8055 7.78083 17.7833 7.50878 17.8222C7.50963 18.228 7.49584 18.6356 7.50563 19.041C7.52432 18.9181 7.5152 18.7872 7.51461 18.663C7.79164 18.6653 8.07066 18.6617 8.3471 18.6804C8.36355 18.273 8.34826 17.8586 8.34854 17.4504L8.34679 15.2754Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M12.002 15.9675C11.704 15.9562 11.4084 15.9855 11.1115 15.9772L11.1207 16.8443C11.4155 16.8462 11.7104 16.8463 12.0051 16.853C11.9953 16.5583 12.0015 16.2623 12.002 15.9675Z", fill: "#587BFE" }),
  /* @__PURE__ */ jsx("path", { d: "M12.9051 15.9492C12.772 16.0329 12.1781 15.9847 12.0033 15.9669C12.0029 16.2617 11.9967 16.5578 12.0065 16.8524L12.8971 16.8548C12.9056 16.5528 12.9062 16.2513 12.9051 15.9492Z", fill: "#0549E8" }),
  /* @__PURE__ */ jsx("path", { d: "M12.0011 14.1699C11.716 14.1701 11.3889 14.1931 11.11 14.1731L11.1108 15.073L12.0012 15.0731L12.0011 14.1699Z", fill: "#9AAFFE" }),
  /* @__PURE__ */ jsx("path", { d: "M12.9055 14.1724C12.605 14.1669 12.3046 14.1761 12.0042 14.1699L12.0044 15.0731C12.2275 15.0724 12.651 15.044 12.8507 15.0852C12.8547 15.086 12.8586 15.0869 12.8625 15.0878L12.8669 15.0827L12.8899 15.094C12.9038 15.062 12.8993 15.0059 12.9006 14.9699L12.9055 14.1724Z", fill: "#587BFE" }),
  /* @__PURE__ */ jsx("path", { d: "M12.9053 15.9488C12.8975 15.6225 12.9179 15.2949 12.8995 14.9688C12.8982 15.0048 12.9027 15.0609 12.8888 15.0929L12.8658 15.0816L12.8614 15.0867C12.8574 15.0858 12.8535 15.0848 12.8496 15.084C12.6499 15.0428 12.2264 15.0713 12.0032 15.072L11.1128 15.0718C11.113 15.3731 11.1171 15.675 11.1131 15.9763C11.41 15.9845 11.7056 15.9552 12.0035 15.9665C12.1783 15.9842 12.7722 16.0325 12.9053 15.9488Z", fill: "#511ECA" })
] });
const ForwardRef = forwardRef(SvgPerkLocked);
export default ForwardRef;
