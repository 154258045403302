import BasePaidFeature from '@bugbug/core/components/PaidFeature';
import { useCallback } from 'react';

import type { MouseEvent } from 'react';

import type { PaidFeatureName } from '@bugbug/core/types/paidFeatures';
import type { SideEffect } from '@bugbug/core/types/utils';
import useAppRoutes from '~/hooks/useAppRoutes';
import {
  selectCurrentOrganizationId,
  selectIsFreePlan,
} from '~/modules/organization/organization.selectors';
import { useAppSelector } from '~/modules/store';
import analytics, { TRACK_EVENT_ARG_TYPE } from '~/services/analytics';

interface PaidFeatureProps {
  children: (isDisabled: boolean) => JSX.Element;
  onUpgradeClick?: SideEffect;
  onRedirect?: SideEffect;
  feature?: PaidFeatureName;
}

export const PaidFeature = (props: PaidFeatureProps) => {
  const { push } = useAppRoutes('subscription');
  const organizationId = useAppSelector(selectCurrentOrganizationId);
  const isFreePlan = useAppSelector(selectIsFreePlan) as boolean;
  const { children, feature } = props;

  const handleUpgradeClick = useCallback<SideEffect<MouseEvent>>(
    (event: MouseEvent) => {
      event.stopPropagation();

      const onUpgradeClick =
        props?.onUpgradeClick || (() => push('subscription', { organizationId }));

      if (feature) {
        analytics.trackEvent('upgrade_button_clicked', {
          [TRACK_EVENT_ARG_TYPE.ORGANIZATION_ID]: organizationId,
          [TRACK_EVENT_ARG_TYPE.CONTEXT]: feature,
        });
      }

      onUpgradeClick();
      props?.onRedirect?.();
    },
    [feature, organizationId, props, push],
  );

  return (
    <BasePaidFeature onUpgradeClick={handleUpgradeClick} freePlan={isFreePlan}>
      {children}
    </BasePaidFeature>
  );
};
