import Input from '@bugbug/core/components/Input';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import type { SideEffect } from '@bugbug/core/types/utils';
import { Separator } from '~/components/Separator/Separator';
import useModal from '~/hooks/useModal';
import { selectExtensionSettings } from '~/modules/extension/extension.selectors';
import { useAppSelector } from '~/modules/store';
import { addProtocolWhenNotExists } from '~/utils/url';
import { urlValidator } from '~/utils/validators';
import { FormField, OnboardingSubtitle } from '~/views/Onboarding/Onboarding.styled';

import { TestActionButton, RecordButton, EmptyStateBox } from './EmptyStateNoRecording.styled';
import * as S from './EmptyStateNoRecording.styled';

const schema = Yup.object().shape({
  url: urlValidator,
});

interface EmptyStateNoRecordingProps {
  isFirstRecording: boolean;
  onRecordClick: SideEffect<string>;
  onNewStepClick: SideEffect;
  onPasteStepsClick: SideEffect;
  onInsertComponentClick: SideEffect;
  initialRecordingUrl: string;
  disabledPasting: boolean;
}

export const EmptyStateNoRecording = ({
  isFirstRecording,
  onRecordClick,
  onNewStepClick,
  onPasteStepsClick,
  onInsertComponentClick,
  initialRecordingUrl,
  disabledPasting,
}: EmptyStateNoRecordingProps) => {
  const extensionSettings = useAppSelector(selectExtensionSettings) as {
    isActive: boolean;
    version?: string;
  };

  const { t } = useTranslation(undefined, { keyPrefix: 'recordingEmptyState' });
  const modal = useModal();

  const handleSubmit = useCallback(
    (values, actions) => {
      onRecordClick(addProtocolWhenNotExists(values.url || null));
      if (!extensionSettings.isActive) {
        actions.setSubmitting(false);
      }
    },
    [onRecordClick, extensionSettings.isActive],
  );

  const formik = useFormik({
    initialValues: { url: initialRecordingUrl || '' },
    validationSchema: schema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  return (
    <EmptyStateBox data-testid="TestDetailsEmptyStateNoRecording">
      <S.EmptyStateCard>
        <div>
          <S.ArrowDownIcon />
          <S.EmptyStateTitle>
            {isFirstRecording
              ? t('firstRecording.title', 'Record your first test')
              : t('title', 'Record your test')}
          </S.EmptyStateTitle>
          <OnboardingSubtitle>
            {isFirstRecording
              ? t(
                  'firstRecording.subtitle',
                  'Create your first automated test by recording your clicks on the website.',
                )
              : t(
                  'subtitle',
                  'Create your automated test by recording your clicks on the website.',
                )}
          </OnboardingSubtitle>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <FormField label={t('url', 'Enter the URL of a website you want to record')}>
            <Input
              {...formik.getFieldProps('url')}
              autoFocus={!modal.isOpen}
              data-hj-allow
              error={formik.touched.url && formik.errors.url?.toString()}
            />
          </FormField>
          <RecordButton type="submit" pending={formik.isSubmitting}>
            {t('testDetailsEmptyState.firstRecording.button', 'Start recording')}
          </RecordButton>
        </form>

        {!isFirstRecording && (
          <>
            <Separator>{t('separator', 'or')}</Separator>
            <S.OtherActionsContainer>
              <TestActionButton bordered Icon={S.AddIcon} onClick={onNewStepClick}>
                {t('actions.newStep', 'Add a new step manually')}
              </TestActionButton>
              <TestActionButton bordered Icon={S.AddComponentIcon} onClick={onInsertComponentClick}>
                {t('actions.existingComponent', 'Insert an existing component')}
              </TestActionButton>
              <TestActionButton
                bordered
                Icon={S.PasteIcon}
                onClick={onPasteStepsClick}
                disabled={disabledPasting}
              >
                {t('actions.pasteSteps', 'Paste step(s)')}
              </TestActionButton>
            </S.OtherActionsContainer>
          </>
        )}
      </S.EmptyStateCard>
    </EmptyStateBox>
  );
};
