import Link from '@bugbug/core/components/Link';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Step } from '@bugbug/core/types/steps';
import analytics from '~/services/analytics';

import * as S from './StepWindowScreenshot.styled';

interface StepWindowScreenshotProps {
  step: Step;
}

export const StepWindowScreenshot = ({ step }: StepWindowScreenshotProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'stepDetails' });

  const trackScreenshotClick = useCallback(() => {
    analytics.trackEvent('screenshot_fullpage_clicked');
  }, []);

  return step.screenshot ? (
    <Link to={step.screenshot} openNewTab onClick={trackScreenshotClick}>
      <S.Image src={step.screenshot} loading="lazy" />
    </Link>
  ) : (
    <S.EmptyStateLabel>
      {t('stepWindowScreenshot.emptyState.label', 'Screenshot is not available for this step.')}
    </S.EmptyStateLabel>
  );
};
