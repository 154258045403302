import * as Ariakit from '@ariakit/react';
import Button from '@bugbug/core/components/Button';
import mixins from '@bugbug/core/theme/mixins';
import styled, { css } from 'styled-components';

import FormField from '~/components/FormField';

export const Container = styled.div`
  ${mixins.layout.gridSingleColumn};
  margin-top: 4px;
`;

export const HeaderActions = styled.div`
  position: absolute;
  top: -5px;
  right: -2px;
`;

export const SelectorsGroup = styled.div<{ first?: boolean }>`
  ${mixins.layout.gridSingleColumn};
  gap: 10px;
  background: ${({ theme }) => theme.colors.background.neutral};
  border: 1px solid ${({ theme }) => theme.colors.border.semi};
  padding: 4px 12px 12px;
  border-radius: 5px;
  position: relative;

  ${({ first, theme }) =>
    !first &&
    css`
      margin-top: 10px;

      &:before {
        background: ${theme.colors.border.dark};
        height: 10px;
        width: 1px;
        content: '';
        height: 10px;
        position: absolute;
        width: 1px;
        top: -10px;
        left: calc((100% - 1px) / 2);
      }
    `}
`;

export const DropdownContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.common.white};
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  max-height: calc(var(--popover-available-height) + var(--popover-overflow-padding));
  width: var(--popover-anchor-width);
  min-width: 254px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  z-index: 12;
  display: flex;
  flex-direction: column;
`;

export const TabList = styled(Ariakit.TabList)`
  ${mixins.layout.flexRowStretch};
  margin: 0 12px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.border.light};
  gap: 20px;
`;

export const TabPanel = styled(Ariakit.TabPanel)`
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 12px;
  overscroll-behavior: none;
`;

export const Tab = styled(Ariakit.Tab)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  background-color: ${({ theme }) => theme.colors.common.transparent};
  color: ${({ theme }) => theme.colors.text.normal};
  opacity: 1;
  padding: 0;
  min-width: 0;
  text-transform: none;
  height: 48px;
  letter-spacing: 0.65px;
  font-size: 13px;
  padding-top: 3px;
  transition: all 300ms ease;
  border: none;
  border-bottom: 3px transparent solid;
  margin-bottom: -2px;
  user-select: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &[data-active-item='true'] {
    color: ${({ theme }) => theme.colors.primary};
    border-color: currentColor;
  }
`;

export const TabContent = styled.div`
  width: 100%;
  height: 1px;
  box-shadow: 0 1px 3px 0px rgba(47, 46, 55, 0);
  margin-top: -1px;
  top: 0;
  flex: none;
  z-index: 1;
  transition: box-shadow 250ms ease-in-out;
  pointer-events: none;
`;

export const ComputedSelectorValue = styled(FormField)`
  margin-top: 20px;
  padding-bottom: 0;
`;

export const PresetTypes = styled.div`
  ${mixins.layout.flexRowStretch};
  justify-content: space-between;
  margin: 4px 0 12px;
  height: 28px;
`;

export const Options = styled.div`
  ${mixins.layout.flexCenter};
  gap: 12px;
  flex: 1;
`;

export const ClearButton = styled(Button).attrs(() => ({
  variant: 'default',
}))`
  flex: none;
  width: min-content;
  padding: 8px;
  height: 28px;
  justify-self: flex-end;

  > div {
    margin-bottom: 0;
  }

  span {
    margin-right: 7px;
  }
`;
