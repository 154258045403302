import type { Step } from '@bugbug/core/types/steps';
import { StepNameWithParams } from '~/views/TestDetails/components/StepNameWithParams/StepNameWithParams';

import * as S from './ComponentStepName.styled';

interface ComponentStepNameProps {
  value: Step;
}

/*
  TODO:
  Remove this proxy component while removing table fo
*/
export const ComponentStepName = ({ value: step }: ComponentStepNameProps) => (
  <S.Container>
    <StepNameWithParams step={step} />
  </S.Container>
);
