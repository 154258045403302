// This component is used as an 'loose-all-types' interface between ComponentsList and SimpleTable
// letting us build ComponentsList without falling into TS issues in SimpleTable This component is
// temporary and will be removed after SimpleTable types are refactore
import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleTable } from '~/components/Table';

import { COMPONENTS_LIST_COLUMNS } from './ComponentsTable.constants';

const ComponentsTable = forwardRef(
  ({ data, getExtraRowProps, onSelectionChange, isSearchEmpty }, tableRef) => {
    const { t } = useTranslation();

    const handleLearnMore = useCallback(() => {
      window.open(t('default.component.docs.create'), '_blank');
    }, [t]);

    return (
      <SimpleTable
        columns={COMPONENTS_LIST_COLUMNS}
        data={data}
        emptyStateText={
          isSearchEmpty
            ? t('componentsList.noSearchResults.label', 'No components match the query')
            : t(
                'componentsList.emptyState.label',
                'Components let you reuse the same steps across multiple tests. To create a new component, edit any test and click “Make component”.',
              )
        }
        emptyStateAction={{
          onClick: handleLearnMore,
          text: 'Learn more',
        }}
        getRowProps={getExtraRowProps}
        onSelectionChange={onSelectionChange}
        ref={tableRef}
      />
    );
  },
);

ComponentsTable.displayName = 'ComponentsTable';

export default ComponentsTable;
