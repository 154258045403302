import Icon from '@bugbug/core/components/Icon';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Step } from '@bugbug/core/types/steps';
import { ParamsCell } from '~/components/Table';
import { STEP_TYPE_ICON_NAME } from '~/constants/step';
import { useAppSelector } from '~/modules/store';
import { selectRecentlyAddedStepsIds } from '~/modules/uiState/uiState.selectors';

import * as S from './StepNameWithParams.styled';

const MemoizedIcon = memo(Icon);
const precomputedIconsByStepType = Object.entries(STEP_TYPE_ICON_NAME).reduce(
  (acc, [stepType, iconName]) => {
    acc[stepType] = <MemoizedIcon name={iconName} />;
    return acc;
  },
  {},
);

interface StepNameWithParamsProps {
  step: Step;
  runResultEnabled?: boolean;
}

export const StepNameWithParams = ({ step, runResultEnabled = false }: StepNameWithParamsProps) => {
  const { t } = useTranslation();
  const StepIcon =
    precomputedIconsByStepType[step.type === 'assert' ? step.assertionProperty : step.type];

  const recentlyAddedStepsIds = useAppSelector(selectRecentlyAddedStepsIds);

  return (
    <S.Container>
      <S.IconWrapper>{StepIcon}</S.IconWrapper>
      <S.StepName type={step.type} added={recentlyAddedStepsIds.includes(step.id)} />
      {/* @ts-expect-error Remove after migrating ParamsCell to TS */}
      <ParamsCell step={step} type={step.type} runResultEnabled={runResultEnabled} />

      {step.sleep ? (
        <S.Tooltip
          content={t(
            'stepName.sleep.tooltip',
            'This step has Delay / Sleep set for {{seconds}} seconds',
            {
              seconds: step.sleep,
            },
          )}
        >
          <S.SleepIcon />
        </S.Tooltip>
      ) : null}
    </S.Container>
  );
};
