import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { GridItemAction } from '~/components/GridItem/GridItem.types';

import type { Schedule } from '@bugbug/core/types/schedules';
import GridItem from '~/components/GridItem';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { selectProjectTimezone } from '~/modules/project/project.selectors';
import {
  usePartialUpdateScheduleMutation,
  useRunScheduleMutation,
} from '~/modules/schedules/schedules.api';
import { useAppSelector } from '~/modules/store';
import analytics from '~/services/analytics';
import { getExtractedErrorMessages } from '~/services/api/utils';
import toasts from '~/services/toasts';

import ScheduleItemDescription from './ScheduleItemDescription';
import ScheduleItemTitle from './ScheduleItemTitle';

interface ScheduleItemProps {
  data: Schedule;
}

const ScheduleItem = ({ data }: ScheduleItemProps) => {
  const modal = useModal();
  const appRoutes = useAppRoutes('schedulesList');
  const { t } = useTranslation();
  const [updateSchedule, activateScheduleState] = usePartialUpdateScheduleMutation();
  const [runSchedule, runScheduleState] = useRunScheduleMutation();
  const timezone = useAppSelector(selectProjectTimezone);

  const handleSwitchChange = useCallback(
    (event) => {
      analytics.trackEvent(event.target.checked ? 'schedule_activated' : 'schedule_deactivated');
      updateSchedule({ id: data.id, data: { enabled: event.target.checked } });
    },
    [data.id, updateSchedule],
  );

  const handleClone = useCallback(
    () =>
      modal.show('clone_schedule', {
        id: data.id,
      }),
    [modal, data.id],
  );

  const handleDelete = useCallback(() => {
    modal.show('delete_schedule', {
      id: data.id,
    });
  }, [modal, data.id]);

  const handleRunNow = useCallback(() => {
    runSchedule(data);
  }, [runSchedule, data]);

  const handleClick = useCallback(() => {
    appRoutes.push('schedule', { scheduleId: data.id });
  }, [appRoutes, data.id]);

  const actions = useMemo(
    () =>
      [
        data?.suites?.length > 0
          ? {
              label: t('scheduleItem.actions.runInCloud.label', 'Run in cloud'),
              onClick: handleRunNow,
              iconName: 'cloudRun',
            }
          : null,
        {
          label: t('scheduleItem.actions.rename.label', 'Rename'),
          onClick: handleClick,
          iconName: 'edit',
        },
        {
          label: t('scheduleItem.actions.clone.label', 'Duplicate'),
          onClick: handleClone,
          iconName: 'clone',
        },
        {
          label: t('scheduleItem.actions.remove.label', 'Delete'),
          onClick: handleDelete,
          iconName: 'delete',
          danger: true,
        },
      ].filter(Boolean) as NonNullable<GridItemAction>[],
    [data.suites, t, handleRunNow, handleClone, handleDelete, handleClick],
  );

  // Update request handlers
  useEffect(() => {
    if (activateScheduleState.isSuccess) {
      const { enabled = false } = activateScheduleState?.originalArgs?.data ?? {};
      toasts.showSuccess({
        content: enabled
          ? t('scheduleItem.toggle.success.enabled', 'Schedule enabled')
          : t('scheduleItem.toggle.success.disabled', 'Schedule disabled'),
      });
    }

    if (activateScheduleState.isError) {
      toasts.showError({
        content: t('scheduleItem.toggle.error', 'Updating schedule failed'),
      });
    }
  }, [t, activateScheduleState]);

  // Run schedule request handlers
  useEffect(() => {
    if (runScheduleState.isSuccess) {
      toasts.showSuccess({
        content: t(
          'scheduleItem.runNow.success',
          'All suites from this schedule started. See details in runs history',
        ),
      });
    }

    if (runScheduleState.isError) {
      const schedulerIsDisabledOccurs = getExtractedErrorMessages(
        runScheduleState.error,
      )?.schedulerIsDisabled;

      if (schedulerIsDisabledOccurs) {
        toasts.showError({
          content: t('scheduleItem.runNow.disabledError', 'Schedule is currently disabled'),
        });
        return;
      }

      toasts.showError({
        content: t('scheduleItem.runNow.error', 'Starting schedule failed'),
      });
    }
  }, [runScheduleState, t]);

  return (
    <GridItem
      title={<ScheduleItemTitle data={data} onToggle={handleSwitchChange} />}
      description={<ScheduleItemDescription data={data} timezone={timezone} />}
      onClick={handleClick}
      actions={actions}
    />
  );
};

export default ScheduleItem;
