import Button from '@bugbug/core/components/Button';
import { FormikProvider, useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { InviteUsersDto } from '~/modules/organization/organization.schemas';

import { ErrorInfo } from '~/components/modals/Modal';
import useActionState from '~/hooks/useActionState';
import { useConfetti } from '~/hooks/useConfetti';
import useModal from '~/hooks/useModal';
import { InviteUsersSchema } from '~/modules/organization/organization.schemas';
import {
  selectCurrentOrganizationId,
  selectRoleInCurrentOrganization,
} from '~/modules/organization/organization.selectors';
import { OrganizationUsersActions } from '~/modules/organizationUsers/organizationUsers.redux';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import analytics, { TRACK_EVENT_ARG_TYPE } from '~/services/analytics';
import { OnboardingTitle } from '~/views/Onboarding/Onboarding.styled';

import * as S from './InviteUserModal.styled';
import { InviteUsersRowsList } from './InviteUsersRowsList';

interface InviteUserModalProps {
  onboardingInProgress?: boolean;
}

const InviteUserModal = ({ onboardingInProgress }: InviteUserModalProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'inviteUserModal',
  });
  const dispatch = useAppDispatch();
  const modal = useModal();
  const organizationId = useAppSelector(selectCurrentOrganizationId);
  const currentUserRole = useAppSelector(selectRoleInCurrentOrganization);
  const { show } = useConfetti();

  const handleSubmit = useCallback(
    (payload: InviteUsersDto) => {
      if (!payload.users?.length) return;
      dispatch(
        OrganizationUsersActions.addRequest({
          ...payload,
          actionContext: onboardingInProgress ? 'onboarding' : 'normal',
        }),
      );
    },
    [dispatch, onboardingInProgress],
  );

  const formik = useFormik({
    initialValues: {
      users: [
        {
          email: '',
          role: currentUserRole,
        },
      ],
    },
    validationSchema: InviteUsersSchema,
    onSubmit: handleSubmit,
  });

  const { setSubmitting, setFieldError } = formik;

  const handleFailure = useCallback(
    () => {
      setSubmitting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSubmitting],
  );

  const handleSuccess = useCallback(async () => {
    analytics.trackEvent('user_invitation_sent', {
      [TRACK_EVENT_ARG_TYPE.ORGANIZATION_ID]: organizationId,
      [TRACK_EVENT_ARG_TYPE.CONTEXT]: onboardingInProgress ? 'onboarding' : 'normal',
      [TRACK_EVENT_ARG_TYPE.INVITED_USERS_NUMBER]: formik.values.users.length,
    });
    modal.hide();
  }, [organizationId, onboardingInProgress, formik.values.users.length, modal]);

  useEffect(() => {
    if (!onboardingInProgress) return;
    show();
  }, [onboardingInProgress, show]);

  const { hasInternalServerError, isLoading, rawErrors } = useActionState(
    OrganizationUsersActions.addRequest,
    {
      onSuccess: handleSuccess,
      onFailure: handleFailure,
    },
  );

  useEffect(() => {
    if (!rawErrors?.[0]) return;
    const errors = rawErrors?.[0] as {
      users: {
        email?: {
          code: string;
          message: string;
        };
      }[];
    };
    if (!errors.users) return;
    errors.users.forEach((error, index) => {
      if (!error.email?.[0]) return;
      setFieldError(`users.${index}.email`, error.email[0].message);
    });
  }, [rawErrors, setFieldError]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <S.Container data-testid="InviteUserModal">
          <S.ConfettiIcon />
          <OnboardingTitle>{t('title', 'Invite your team for free')}</OnboardingTitle>
          <S.Description>
            <Trans i18nKey="inviteUserModal.description">
              Ready to collaborate with your colleagues?
              <br />
              Invite <b>as many as you want for free.</b>
            </Trans>
          </S.Description>
          <InviteUsersRowsList />
          <ErrorInfo isVisible={hasInternalServerError} />
          <S.Separator />
          <S.ButtonsRow>
            <Button
              data-testid="InviteUserModal.CancelButton"
              variant="primary"
              type="button"
              bordered
              onClick={modal.hide}
              disabled={isLoading}
            >
              {onboardingInProgress
                ? t('cancelButton.onboardingLabel', 'Not now')
                : t('cancelButton.label', 'Cancel')}
            </Button>
            <S.SendButton
              data-testid="InviteUserModal.SubmitButton"
              variant="primary"
              type="submit"
              disabled={isLoading}
              pending={isLoading}
              iconName="send"
            >
              {t('submitButton.label', 'Send invitation')}
            </S.SendButton>
          </S.ButtonsRow>
        </S.Container>
      </form>
    </FormikProvider>
  );
};

export default InviteUserModal;
