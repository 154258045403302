import i18n from 'i18next';

import type { ExtendedRunStatus, RunStatus } from '../types/base';
import type { IconName } from '../types/icons';

import { colors } from '../theme/colors';

export const RUN_STATUS: Record<string, RunStatus> = {
  INITIALIZED: 'initialized',
  RUNNING: 'running',
  PASSED: 'passed',
  FAILED: 'failed',
  STOPPED: 'stopped',
  ERROR: 'error',
  DEBUGGING: 'debugging',
  QUEUED: 'queued',
  RECORDING: 'recording',
  PAUSED: 'paused',
} as const;

export const RUN_STATUS_COLOR: Record<ExtendedRunStatus, string> = {
  passed: colors.status.success,
  failed: colors.status.failure,
  error: colors.status.failure,
  stopped: colors.status.stopped,
  running: colors.status.info,
  queued: colors.status.info,
  debugging: colors.accent,
  initialized: colors.status.initialized,
  'passed-with-issues': colors.status.success,
  'failed-ignored': colors.status.ignored,
  'failed-retried': colors.status.failure,
  'just-edited': colors.status.initialized,
  recording: colors.status.recording,
  paused: colors.status.paused,
} as const;

export const RUN_STATUS_ICON: Record<ExtendedRunStatus, IconName> = {
  passed: 'smallDot',
  failed: 'thumbDown',
  error: 'error',
  stopped: 'stop',
  running: 'smallDot',
  queued: 'circle',
  debugging: 'circle',
  initialized: 'circle',
  'passed-with-issues': 'circle',
  'failed-ignored': 'circle',
  'failed-retried': 'autoRetry',
  'just-edited': 'circle',
  recording: 'smallDot',
  paused: 'smallDot',
} as const;

export const RUN_STATUS_LABEL: Record<ExtendedRunStatus, string> = {
  initialized: i18n.t('default.run.status.initialized'),
  queued: i18n.t('default.run.status.queued'),
  running: i18n.t('default.run.status.running'),
  debugging: i18n.t('default.run.status.debugging'),
  passed: i18n.t('default.run.status.passed'),
  failed: i18n.t('default.run.status.failed'),
  error: i18n.t('default.run.status.error'),
  stopped: i18n.t('default.run.status.stopped'),
  'passed-with-issues': i18n.t('default.run.status.passedWithIssues'),
  'failed-ignored': i18n.t('default.run.status.ignoredFailed'),
  'failed-retried': i18n.t('default.run.status.retriedFailed'),
  'just-edited': i18n.t('default.run.status.justEdited'),
  recording: i18n.t('default.recording.status.recording'),
  paused: i18n.t('default.recording.status.paused'),
} as const;

export const TEST_STATUS = {
  ...RUN_STATUS,
} as const;

export const TEST_STATUS_COLOR = {
  ...RUN_STATUS_COLOR,
} as const;

export const TEST_STATUS_ICON = {
  ...RUN_STATUS_ICON,
} as const;

export const TEST_STATUS_LABEL = {
  ...RUN_STATUS_LABEL,
} as const;
