import type { TrackEvent } from './types';

export const TRACK_EVENT_ARG_TYPE = {
  SCHEDULE_FREQUENCY: 'scheduleFrequency',
  SUBSCRIPTION_TYPE: 'subscriptionType',
  SUBSCRIPTION_PERIOD: 'subscriptionPeriod',
  SUBSCRIPTION_STATUS: 'subscriptionStatus',
  SUBSCRIPTION_PAID: 'paidSubscription',
  SCHEDULED_SUBSCRIPTION_TYPE: 'scheduledSubscriptionType',
  SIGN_UP_METHOD: 'method',
  SIGN_UP_DATE: 'signUpDate',
  ORGANIZATION_NAME: 'organizationName',
  ORGANIZATION_ID: 'organizationId',
  PROJECT_ID: 'projectId',
  NEXT_PROJECT_NAME: 'nextProjectName',
  NEXT_ORGANIZATION_NAME: 'nextOrganizationName',
  PROFILE_NAME: 'profileName',
  NEXT_PROFILE_NAME: 'nextProfileName',
  COMPANY: 'company',
  USER_ROLE: 'userRole',
  USER_NAME: 'userName',
  USER_ID: 'userId',
  USER_EMAIL: 'userEmail',
  TEST_NAME: 'testName',
  TESTS_COUNT: 'testsCount',
  TEST_RUN_METHOD: 'testRunMethod',
  TEST_RUN_ID: 'testRunId',
  TEST_RUN_STATUS: 'testRunStatus',
  STEP_TYPE: 'stepType',
  USER_TYPE: 'userType',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  POSITION: 'position',
  CONTEXT: 'context',
  BUTTON_CLICK: 'buttonClick',
  COMPANY_NAME: 'companyName',
  USER_SOURCE: 'userSource',
  INVITED_USERS_NUMBER: 'invitedUsersNumber',
};

export const TRACK_EVENT_ARG_VALUE = {
  YES: 'yes',
  NO: 'no',
  SUBSCRIPTION_TRIAL: 'on-trial',
  SUBSCRIPTION_FREE: 'free',
  SUBSCRIPTION_PRO: 'on-subscription-pro',
  SUBSCRIPTION_BUSINESS: 'on-subscription-business',
  SUBSCRIPTION_BASIC: 'on-subscription-basic',
  USER_TYPE_OWNER: 'user',
  USER_TYPE_INVITED: 'invited_user',
};

export const TRACK_EVENT_TYPE: Record<string, TrackEvent> = {
  VIEW_SIGN_UP: 'sign_up_viewed',
  START_SIGN_UP: 'sign_up_started',
  NOT_CONFIRM_SIGN_UP: 'sign_up_not_confirmed',
  CONFIRM_SIGN_UP: 'sign_up_confirmed',
  VIEW_PROFILE_SIGN_UP: 'sign_up_profile_viewed',
  CREATE_PROFILE_SIGN_UP: 'sign_up_profile_created',
  APP_ENTRY_MODAL_CLICKED: 'app_entry_modal_clicked',
  VIEW_ORGANIZATION_SIGN_UP: 'sign_up_organization_viewed',
  CREATE_ORGANIZATION_SIGN_UP: 'sign_up_organization_created',
  CLICK_SIGN_UP_ON_LANDING: 'click_sign_up_cta',
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
  SIGN_OUT: 'sign_out',
  CREATE_ORGANIZATION: 'organization_created',
  CHANGE_ORGANIZATION: 'organization_changed',
  FIRST_NEW_PROJECT: 'first_project_created',
  NEW_PROJECT: 'project_created',
  OPEN_PROJECT: 'project_opened',
  DUPLICATE_PROJECT: 'project_duplicated',
  DELETE_PROJECT: 'project_deleted',
  OPEN_TESTS_MENU: 'tests_menu_opened',
  OPEN_TEST: 'test_opened',
  FIRST_NEW_TEST: 'first_test_created',
  CREATE_TEST: 'test_created',
  RUN_TEST: 'run_test', // on backend
  START_TEST_RUN: 'run_test_clicked',
  DUPLICATE_TEST: 'test_duplicated',
  DELETE_TEST: 'test_deleted',
  BULK_DELETE_TESTS: 'bulk_tests_deleted',
  OPEN_SUITES_MENU: 'suites_menu_opened',
  OPEN_SUITE: 'suite_opened',
  CREATE_SUITE: 'suite_created',
  RUN_SUITE: 'run_suite', // on backend
  DUPLICATE_SUITE: 'suite_duplicated',
  DELETE_SUITE: 'suite_deleted',
  BULK_DELETE_SUITES: 'bulk_suites_deleted',
  FIRST_RECORDING_STARTED: 'first_recording_started',
  START_TEST_RECORD: 'start_test_record', // on backend
  END_TEST_RECORD: 'end_test_record', // on backend
  START_STEP_CREATION: 'new_step_creation_started',
  STEP_MANUALLY_ADDED: 'new_step_manually_added',
  DUPLICATE_STEP: 'step_duplicated',
  COPY_STEP: 'step_copied',
  PASTE_STEP: 'step_pasted',
  DELETE_STEP: 'step_deleted',
  BULK_DELETE_STEPS: 'bulk_steps_deleted',
  OPEN_SCHEDULES_MENU: 'schedules_menu_opened',
  OPEN_SCHEDULE: 'schedule_opened',
  CREATE_SCHEDULE: 'schedule_created',
  DUPLICATE_SCHEDULE: 'schedule_duplicated',
  DELETE_SCHEDULE: 'schedule_deleted',
  CHANGE_SCHEDULE_FREQUENCY: 'schedule_frequency_changed',
  TOGGLE_ACTIVATE_SCHEDULE: 'schedule_activated',
  TOGGLE_DEACTIVATE_SCHEDULE: 'schedule_deactivated',
  UPGRADE_SUBSCRIPTION: 'subscription_upgraded',
  DOWNGRADE_SUBSCRIPTION: 'subscription_downgraded',
  CANCEL_SUBSCRIPTION: 'cancel_subscription', // on backend
  INITIATE_CHECKOUT: 'checkout_initiated',
  INITIATE_PAYMENT: 'payment_initiated',
  COMPLETE_PAYMENT: 'payment_completed',
  PAYMENT_SUCCESS_VIEWED: 'payment_success_viewed', // on backend
  OPEN_PROFILES_MENU: 'open_profiles_menu',
  OPEN_VARAIBLES_MENU: 'open_variables_menu',
  CHANGE_PROFILE: 'change_profile',
  MODAL_VIEW: 'modal_viewed',
  FIRST_RUN_EVER_FINISHED: 'first_run_ever_finished',
  COMPONENT_MADE: 'component_made',
  EXTENSION_INSTALLED: 'extension_installed',
  EXTENSION_INCOGNITO_ENABLED: 'extension_incognito_enabled',
  MODAL_VIEW_EXT_INSTALL: 'extension_modal_info_please_install_viewed',
  MODAL_VIEW_EXT_INCOGNITO: 'extension_modal_info_enable_incognito_viewed',
  EXTENSION_SUCCESS: 'extension_modal_success_viewed',
  CLICK_RECORD_FROM_HERE: 'record_from_here_clicked',
  SCREENSHOT_ELEMENT_CLICKED: 'screenshot_element_clicked',
  SCREENSHOT_FULLPAGE_CLICKED: 'screenshot_fullpage_clicked',
  EXECUTION_URL_CLICKED: 'execution_url_clicked',
  NEW_STEP_MANUALLY_ADDED: 'new_step_manually_added',
  RECORDING_STARTED: 'recording_started',
  RECORDING_STOPPED: 'recording_stopped',
  RECORDING_STEP_CREATED: 'recording_step_created',
  USER_INVITATION_SENT: 'user_invitation_sent',
  INVITED_USER_SIGN_UP: 'invited_user_sign_up',
  OPEN_COMPONENTS_MENU: 'open_components_menu',
};

export const MIXPANEL_ALLOWED_EVENTS: TrackEvent[] = [
  'run_command_copied',
  'new_step_manually_added',
  'new_assertion_added',
  'report_downloaded',
  'slack_connect_clicked',
  'teams_connect_clicked',
  'documentation_clicked',
  'business_plan_contact_clicked',
  'cli_copied',
  'api_copied',
  'page_viewed',
  'app_entry_modal_clicked',
  'trial_ended_modal_button_clicked',
  'upgrade_button_clicked',
  'schedule_activated',
  'schedule_deactivated',
];
