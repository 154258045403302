import { colors } from '@bugbug/core/theme';
import { memo, useCallback, useEffect, useRef } from 'react';
import { useEvent } from 'react-use';

import type { ChartConfig } from './SemiCircleProgressChart.helpers';

import { renderChart } from './SemiCircleProgressChart.helpers';

type SemiCircleProgressChartProps = Omit<ChartConfig, 'svgElement'>;

const SemiCircleProgressChart = memo(
  ({
    value,
    color = colors.brand,
    backgroundColor = colors.neutrals['92'],
    strokeWidth = 16,
  }: SemiCircleProgressChartProps) => {
    const svgRef = useRef<SVGSVGElement>(null);

    const refreshChart = useCallback(() => {
      renderChart({
        svgElement: svgRef.current,
        color,
        backgroundColor,
        strokeWidth,
        value,
      });
    }, [backgroundColor, color, strokeWidth, value]);

    useEffect(() => {
      refreshChart();
    }, [refreshChart]);

    useEvent('resize', refreshChart, window, { passive: true });

    return (
      <svg ref={svgRef} role="presentation">
        <path
          shapeRendering="geometricPrecision"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
          stroke={backgroundColor}
        />
        <path
          shapeRendering="geometricPrecision"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
          stroke={color}
        />
        <text textAnchor="middle" />
      </svg>
    );
  },
);

SemiCircleProgressChart.displayName = 'SemiCircleProgressChart';

export default SemiCircleProgressChart;
