import { useTranslation } from 'react-i18next';

import * as S from './StepsGroup.styled';

export const DropState = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'stepsGroup' });

  return (
    <S.EmptyState data-testid="StepsGroup.DropState">
      {t('dropState.message', 'Drop step here.')}
    </S.EmptyState>
  );
};
