import { noop } from '@bugbug/core/utils/toolbox';
import { memo } from 'react';

import type { Test, TestRun } from '@bugbug/core/types/tests';
import type { SideEffect } from '@bugbug/core/types/utils';
import TestRunMetadata from '~/components/TestRunMetadata';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { useAppSelector } from '~/modules/store';
import { selectFailedStepRunWithStep } from '~/modules/testRun/testRun.selectors';

import DownloadLogsButton from '../DownloadLogsButton';
import TestRunActions from '../TestRunActions';

import * as S from './TestRunHeader.styled';

interface TestRunHeaderProps {
  test: Test;
  testRun: Omit<TestRun, 'test'> & { test: string };
  onErrorClick: SideEffect;
}

export const TestRunHeader = memo<TestRunHeaderProps>(({ test, testRun, onErrorClick = noop }) => {
  const featureFlags = useFeatureFlags();
  const failedStepRunWithStep = useAppSelector(selectFailedStepRunWithStep);
  const failedStepId = failedStepRunWithStep?.failedStepRun?.stepId;

  return (
    <S.Container data-testid="TestRunHeader">
      <S.Title>
        <S.NameContainer data-testid="TestRunHeader.Name">
          {/*  @ts-expect-error Invalid type. Remove this comment after migrating EditableText to TS */}
          <S.NameInput value={test?.name} readOnly />
        </S.NameContainer>
        <S.Actions>
          {featureFlags.runLogs && <DownloadLogsButton url={testRun.logsArtifactUrl} />}
          <TestRunActions testRun={testRun} />
        </S.Actions>
      </S.Title>
      <TestRunMetadata
        testRunId={testRun.id}
        onErrorClick={failedStepId ? onErrorClick : undefined}
      />
    </S.Container>
  );
});

TestRunHeader.displayName = 'TestRunHeader';
