import LinkBase from '@bugbug/core/components/Link';
import styled from 'styled-components';

export const TrialTopBar = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.common.white};
  padding: 8px;
`;

export const TrialParagraph = styled.p`
  margin: 0;
  text-align: center;

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

export const TrialRedirect = styled(LinkBase)`
  color: inherit;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;
