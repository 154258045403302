import SelectBase, { SelectOption } from '@bugbug/core/components/Select';
import mixins from '@bugbug/core/theme/mixins';
import styled, { createGlobalStyle } from 'styled-components';

import Example from './example.svg?react';

export const Select = styled(SelectBase).attrs(() => ({
  listClassName: 'relations-container',
}))`
  background: none;
  margin-left: 4px;
  flex: none;

  && button {
    height: 22px !important;
    min-height: unset !important;
    padding: 0;
    border: none;
    font-weight: inherit;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary};

    & > div {
      margin-bottom: 0;
    }

    svg {
      margin-left: -2px;
      height: 20px;
    }
  }
`;

export const GlobalSelectOverride = createGlobalStyle`
  .relations-container {
    max-height: unset;
    width: auto !important;

    [role='listbox'] {
      max-height: unset;
      display: flex;
      flex-direction: row;
    }
  }
`;

export const RelationOption = styled(SelectOption)`
  ${mixins.layout.flexCenter};
`;
RelationOption.displayName = 'SelectOption';

export const Relation = styled.div`
  ${mixins.layout.gridSingleColumn};

  span:last-child {
    color: ${({ theme }) => theme.colors.text.lighter};
  }
`;

export const Help = styled.div`
  ${mixins.layout.gridSingleColumn};
  align-items: center;
  gap: 8px;
  padding: 20px 24px;
  background: ${({ theme }) => theme.colors.background.neutral};
  width: 430px;
  font-size: 12px;

  h3 {
    font-weight: 700;
    margin: 0;
    color: ${({ theme }) => theme.colors.text.secondaryAllCaps};
  }

  p {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const ExampleSvg = styled(Example)`
  && {
    max-width: 100%;
    height: 274px;
    margin-right: 0;
  }
`;
