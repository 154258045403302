import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { is, prop } from 'ramda';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import type { TableBodyStyledProps, VisibleOnRowHoverProps } from './Table.types';
import type { TableBodyProps, TableHeaderProps } from 'react-table';
import type { DefaultTheme, ThemeProps } from 'styled-components';

import { ExpanderButton } from '~/components/ExpanderButton/ExpanderButton';

import SelectionCell from './components/SelectionCell';
import { TextCellContainer } from './components/TextCell';
import { ROW_HEIGHT } from './Table.constants';

export const TableBase = styled.div<TableBodyProps>`
  line-height: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  ${styleWhenTrue(
    prop('disabled'),
    css`
      user-select: none;
      pointer-events: none;
    `,
  )}
`;

export const TableEventCatcher = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const tableRowStyles = css`
  display: flex;
  flex: 1 0 auto;
  color: ${COLOR.DARK_GRAY};
  font-weight: ${FONT_WEIGHT.NORMAL};
  background: ${COLOR.WHITE};
  border-bottom: 1px solid ${COLOR.GRAY_18};
  border-top: 1px solid ${COLOR.TRANSPARENT};

  &:hover {
    color: ${COLOR.DARK_GRAY};
    border-top-color: ${COLOR.BORDER_GRAY};
    border-bottom-color: ${COLOR.BORDER_GRAY};
  }

  ${styleWhenTrue(
    prop('inactive'),
    css`
      background-image: repeating-linear-gradient(
        135deg,
        #f2f2f2 9%,
        #f2f2f2 11%,
        ${COLOR.WHITE} 0,
        ${COLOR.WHITE} 13%
      );
    `,
  )}

  ${styleWhenTrue(
    prop('faded'),
    css`
      background-image: none;
      ${TextCellContainer} {
        opacity: 0.5;
      }
    `,
  )}
`;

export const TableRow = styled.div<TableHeaderProps>`
  ${tableRowStyles};

  ${styleWhenTrue(
    prop('data-clickable'),
    css`
      cursor: pointer;
    `,
  )};
`;

export const TableLinkRow = styled(Link)`
  ${tableRowStyles};

  &:hover {
    text-decoration: none;
  }
`;

type WidthProps = {
  width: string | number;
  maxWidth: string | number;
  minWidth: string | number;
};

const getWidth = (propName) => (props: ThemeProps<DefaultTheme> & WidthProps) => {
  const witdhProp = props[propName];
  if (!witdhProp) {
    return 'auto';
  }
  if (is(String, witdhProp)) {
    return witdhProp;
  }
  return `${witdhProp}px`;
};

const cellStyles = css`
  position: relative;
  padding: 2px 6px;
  display: flex !important;
  align-items: center;
  min-width: ${getWidth('minWidth')};
  max-width: ${getWidth('maxWidth')};
  width: ${getWidth('width')};
  box-sizing: border-box;
  flex: ${prop('width')} 0 auto;
`;

export const RowCell = styled.div`
  ${cellStyles};
  word-break: break-all;
`;

export const HeaderCell = styled.div`
  ${cellStyles};
  font-size: 12px;
  padding-bottom: 1px;
`;

export const HeaderSelectionCell = styled(SelectionCell)``;

export const TableHeader = styled.div`
  flex: none;

  ${TableRow}, ${TableLinkRow} {
    height: 42px;
    border-top: none;
  }
`;

export const TableBody = styled.div<TableBodyStyledProps>`
  overflow: hidden;

  ${styleWhenTrue(
    prop('disabledWindowing'),
    css`
      overflow-y: auto;
    `,
  )}
  border-bottom: 1px solid #dee2e6;
  position: relative;
  flex: 1;

  ${TableRow}, ${TableLinkRow} {
    height: ${ROW_HEIGHT}px;
    &:hover {
      background-color: ${COLOR.PRIMARY_5};
    }
  }
`;

export const PaginationContainer = styled.div`
  margin-top: 13px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
  width: 100%;
`;

export const Expander = styled(ExpanderButton)<{ depth: number }>`
  margin-left: calc(-2px + ${prop('depth')} * 4px);
`;

export const VisibleOnRowHover = styled.div<VisibleOnRowHoverProps>`
  display: none;
  align-items: center;

  ${TableRow}:hover > ${RowCell} & {
    display: flex;
  }

  ${TableLinkRow}:hover > ${RowCell} & {
    display: flex;
  }

  ${styleWhenTrue(
    prop('force'),
    css`
      display: flex;
    `,
  )}
`;
