import { isModalRoute } from '@bugbug/core/types/routes';
import { useParams } from 'react-router';

import type { Route, RouteParams } from '@bugbug/core/types/routes';
import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import { selectProjectSlug, selectSingleProjectId } from '~/modules/project/project.selectors';
import { useAppSelector } from '~/modules/store';

const useRouteParams = <T extends Route>(routeName: T): RouteParams<T> => {
  const routeParams = useParams<RouteParams<T>>();
  const projectId = useAppSelector(selectSingleProjectId);
  const projectSlug = useAppSelector(selectProjectSlug);
  const organizationId = useAppSelector(selectCurrentOrganizationId);

  const params: RouteParams<T> = { ...routeParams };

  if (isModalRoute(params, routeName)) {
    params.organizationId = organizationId;
    params.projectId = projectId;
    params.projectSlug = projectSlug;
  }

  return params;
};

export default useRouteParams;
