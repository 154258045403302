import styled from 'styled-components';

import CheckSVG from '~/images/trial-perk-check.svg?react';
import FreeSVG from '~/images/trial-perk-free.svg?react';
import LockSVG from '~/images/trial-perk-lock.svg?react';

export const PerkContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const PerkText = styled.p`
  margin: 0;
`;

export const PerksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TrialCheckIcon = styled(CheckSVG)`
  display: block;
  flex: 1 0 60px;
`;

export const TrialLockIcon = styled(LockSVG)`
  display: block;
  flex: 1 0 60px;
`;

export const TrialFreeIcon = styled(FreeSVG)`
  display: block;
  flex: 1 0 60px;
`;
