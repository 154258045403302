import styled, { css } from 'styled-components';

import type { ExtendedRunStatus } from '@bugbug/core/types/base';

import { RUN_STATUS_COLOR } from '../../constants/status';
import { COLOR } from '../../theme/colors';
import { isFailedStatus, isRecordingOrPausedStatus } from '../../types/base';
import RelativeTimeBase from '../RelativeTime';
import TooltipBase, { TOOLTIP_ANCHOR } from '../Tooltip';

const getStatusColor = ({ status }: { status: ExtendedRunStatus }) => RUN_STATUS_COLOR[status];

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
`;

export const Container = styled.div<{ status: ExtendedRunStatus }>`
  display: inline-block;
  align-items: center;
  color: ${COLOR.GRAY_12};

  ${({ status }) =>
    !isRecordingOrPausedStatus(status) &&
    isFailedStatus(status) &&
    css`
      color: ${getStatusColor};
    `}

  ${IconContainer} {
    color: ${getStatusColor};
  }
`;

export const RelativeTime = styled(RelativeTimeBase)`
  padding-top: 1px;
  text-decoration: none;
  font-size: inherit;
  float: left;
  margin-left: 10px;

  @media print {
    display: none;
  }
`;

export const Label = styled.label<{ visible?: boolean }>`
  float: left;
  margin-bottom: 1px;
  line-height: 13px;
  margin-left: 10px;
  color: inherit;
  display: none;

  ${({ visible }) =>
    visible &&
    css`
      display: inline-block;
    `}

  @media print {
    display: inline-block;
  }
`;

export const Tooltip = styled(TooltipBase).attrs(() => ({
  anchor: TOOLTIP_ANCHOR.BOTTOM_CENTER,
  offset: 5,
}))``;
