import * as Sentry from '@sentry/react';
import { reverse as reverseBase } from 'named-urls';
import queryString from 'query-string';

import type { ReverseParams } from 'named-urls';

import type { Route } from '@bugbug/core/types/routes';

const idPattern = '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}';

export const organizationUrl = '/organizations/:organizationId';
const projectUrl = `${organizationUrl}/projects/:projectSlug(.*)-:projectId(${idPattern})`;

const urls: Record<Route, string> = {
  home: '/',
  signUp: '/sign-up/',
  signUpSuccess: '/sign-up/success/',
  signUpConfirm: '/sign-up/confirmation/',
  signUpByInvitation: '/invitation/',
  onboarding: '/onboarding/',
  welcome: '/onboarding/welcome/',
  welcomeInvited: '/onboarding/welcome-invited/',
  setupProfile: '/onboarding/profile/',
  setupOrganization: '/onboarding/organization/',
  trialStart: '/onboarding/trial-start/',
  onboardingRedirect: '/onboarding/redirect/',
  passwordReset: '/password-reset/',
  passwordResetSuccess: '/password-reset/success/',
  passwordResetConfirmation: '/password-reset/confirmation/',
  login: '/sign-in/',
  account: '/account/',
  accountSettings: '/account/:settingsTabSlug/',
  accountGeneral: '/account/general/',
  accountChangePassword: '/account/password-change/',
  accountNotifications: '/account/notifications/',

  organizations: '/organizations/',
  newOrganization: `/organizations/new/`,
  authProviderRedirect: '/auth/:provider/',
  authGoogleRedirect: '/auth/google/',
  authGithubRedirect: '/auth/github/',

  // integration process
  integrationProcess: '/integrations/',
  integrationAuthRedirect: '/integrations/auth/:type/',
  integrationConfig: '/integrations/config/:type/',

  // organization
  organization: `${organizationUrl}/`,
  organizationSettings: `${organizationUrl}/settings/:settingsTabSlug?/`,
  organizationGeneral: `${organizationUrl}/settings/general/`,
  users: `${organizationUrl}/settings/users/`,
  billingAddress: `${organizationUrl}/settings/billing-address/`,
  subscription: `${organizationUrl}/settings/subscription/`,
  checkout: `${organizationUrl}/settings/subscription/checkout/steps/:stepId/`,
  checkoutBillingAddress: `${organizationUrl}/settings/subscription/checkout/steps/1/`,
  checkoutPayment: `${organizationUrl}/settings/subscription/checkout/steps/2/`,
  checkoutConfirmation: `${organizationUrl}/settings/subscription/checkout/steps/3/`,
  creditCard: `${organizationUrl}/settings/credit-card/`,
  invoices: `${organizationUrl}/settings/invoices/`,
  planLimits: `${organizationUrl}/settings/plan-limits/`,
  projectsList: `${organizationUrl}/projects/`,
  projectsNew: `${organizationUrl}/projects/new/`,
  projectsEdit: `${organizationUrl}/projects/edit/:projectId/`,

  // project
  project: `${projectUrl}/`,
  runsHistory: `${projectUrl}/runs-history/`,
  testRunsList: `${projectUrl}/runs-history/tests/`,
  testRun: `${projectUrl}/runs-history/tests/:testRunId/`,
  testsList: `${projectUrl}/tests/`,
  testsListEdit: `${projectUrl}/tests/edit/:testId`,
  newTest: `${projectUrl}/tests/edit/new/`,
  test: `${projectUrl}/tests/:testId/`,
  suitesList: `${projectUrl}/suites/`,
  suite: `${projectUrl}/suites/:suiteId/`,
  newSuite: `${projectUrl}/suites/new/`,
  suiteRunsList: `${projectUrl}/runs-history/suites/`,
  suiteRun: `${projectUrl}/runs-history/suites/:suiteRunId/`,
  schedulesList: `${projectUrl}/schedules/`,
  schedule: `${projectUrl}/schedules/:scheduleId/`,
  newSchedule: `${projectUrl}/schedules/new/`,
  notFound: '/not-found/',
  termsAndConditions: 'https://bugbug.io/rules/',
  alerts: `${projectUrl}/alerts/`,
  alert: `${projectUrl}/alerts/:alertId/`,
  newAlert: `${projectUrl}/alerts/new/`,
  integrations: `${projectUrl}/integrations/`,
  integration: `${projectUrl}/integrations/:type/`,
  variables: `${projectUrl}/variables/`,
  variable: `${projectUrl}/variables/:variableId/`,
  customVariables: `${projectUrl}/variables/custom/`,
  customVariable: `${projectUrl}/variables/custom/:variableId/`,
  newCustomVariable: `${projectUrl}/variables/custom/new/`,
  builtInVariables: `${projectUrl}/variables/built-in/`,
  builtInVariable: `${projectUrl}/variables/built-in/:variableId/`,
  profiles: `${projectUrl}/variables/profiles/`,
  profile: `${projectUrl}/variables/profiles/:profileId/`,
  newProfile: `${projectUrl}/variables/profiles/new/`,
  componentsList: `${projectUrl}/components/`,
  component: `${projectUrl}/components/:componentId/`,

  // Project settings
  projectSettings: `${projectUrl}/settings/:settingsTabSlug?/`,
  projectSettingsIndex: `${projectUrl}/settings/`,
  projectGeneralSettings: `${projectUrl}/settings/general/`,
  projectScreenSizes: `${projectUrl}/settings/screen-sizes/`,
  projectBrowser: `${projectUrl}/settings/browser/`,
  projectSelectors: `${projectUrl}/settings/selectors/`,
  projectWaitingConditions: `${projectUrl}/settings/waiting-conditions/`,
} as const;

/**
 * @deprecated use useAppRoutes hook instead if it is possible
 */
export const reverse = (
  template: string,
  urlParams: Record<string, string>,
  queryParams?: Record<string, string>,
): string => {
  const url = reverseBase(
    template.replace('([^/]*)', ''),
    (urlParams as unknown as ReverseParams) ?? {},
  );
  const parseQueryParams = queryParams ? `?${queryString.stringify(queryParams)}` : '';

  if (url.includes('/:')) {
    Sentry.captureMessage('Invalid URL params', {
      level: 'debug',
      contexts: {
        urlCreator: {
          url,
          params: urlParams,
        },
      },
    });
  }

  return `${url}${parseQueryParams}`;
};

const publicUrls = [
  urls.signUp,
  urls.signUpSuccess,
  urls.signUpConfirm,
  urls.passwordReset,
  urls.passwordResetSuccess,
  urls.passwordResetConfirmation,
  urls.login,
  urls.authGoogleRedirect,
  urls.authGithubRedirect,
  urls.notFound,
  urls.termsAndConditions,
  urls.welcome,
  urls.welcomeInvited,
  urls.setupOrganization,
  urls.setupProfile,
  urls.trialStart,
  urls.newOrganization,
];

export const shouldHideNavigation = (url) => publicUrls.some((route) => url.startsWith(route));

export default urls;
