import Icon from '@bugbug/core/components/Icon';
import { RegularParagraph } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 550px;
`;

export const InfoMessage = styled(RegularParagraph)`
  margin-bottom: 25px;
`;

export const LinkIcon = styled(Icon)`
  visibility: hidden;
  color: ${({ theme }) => theme.colors.brand};
  width: 16px;
  height: 16px;
`;

export const ListEntryContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;

  li:hover & ${LinkIcon}, li:focus & ${LinkIcon}, &:hover ${LinkIcon}, &:focus ${LinkIcon} {
    visibility: visible;
  }
`;
