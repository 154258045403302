import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import { PREDEFINED_COLUMN } from '~/components/Table/Table.predefinedColumns';

import { ColumnHeaderContainer, Content, HeaderContainer } from './ColumnHeader.styled';

const ColumnHeader = ({ className, column }) => {
  const sortProps = column.getSortByToggleProps();

  const theme = useMemo(
    () => ({ isSelectionColumn: column.id === PREDEFINED_COLUMN.SELECTION.id }),
    [column.id],
  );

  return (
    <ThemeProvider theme={theme}>
      <ColumnHeaderContainer
        // eslint-disable-next-line react/jsx-props-no-spreading
        minWidth={column.minWidth}
        maxWidth={column.maxWidth}
        width={column.width}
        className={className}
        role="columnheader"
        data-testid="ColumnHeader"
        onClick={sortProps.onClick}
      >
        <Content>
          <HeaderContainer>{column.render('Header')}</HeaderContainer>
        </Content>
      </ColumnHeaderContainer>
    </ThemeProvider>
  );
};

ColumnHeader.propTypes = {
  className: PropTypes.string,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
    getSortByToggleProps: PropTypes.func.isRequired,
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    canSort: PropTypes.bool,
  }).isRequired,
};

export default ColumnHeader;
