import { useMemo } from 'react';

import type { PendingActionName } from '~/modules/uiState/uiState.types';

import type { Maybe } from '@bugbug/core/types/utils';
import { usePendingAction } from '~/hooks/usePendingAction';

const testRunnerActions: PendingActionName[] = [
  'stop',
  'record',
  'resume',
  'runNextStep',
  'pause',
  'runLocal',
  'runCloud',
];

interface ActionState {
  pending: boolean;
  disabled: boolean;
}

interface UseTestRunnerActionsState {
  isLoading: boolean;
  pendingAction: Maybe<PendingActionName>;
  setPendingAction: ReturnType<typeof usePendingAction>['setPendingAction'];
  resetPendingAction: () => void;
  state: Record<PendingActionName, ActionState>;
}

export const useTestRunnerActionsState = (): UseTestRunnerActionsState => {
  const { pendingAction, resetPendingAction, setPendingAction } = usePendingAction();

  const state = useMemo(
    () =>
      testRunnerActions.reduce(
        (acc, action) => ({
          ...acc,
          [action]: {
            pending: action === pendingAction,
            disabled: !!pendingAction && action !== pendingAction,
          },
        }),
        {} as UseTestRunnerActionsState['state'],
      ),
    [pendingAction],
  );

  return useMemo(
    () => ({
      isLoading: !!pendingAction,
      pendingAction,
      setPendingAction,
      resetPendingAction,
      state,
    }),
    [pendingAction, setPendingAction, resetPendingAction, state],
  );
};
