import type { Step } from '../types/steps';

export const STEP_TYPE: Record<string, Step['type']> = {
  ANSWER_PROMPT: 'answerPrompt',
  ASSERT: 'assert',
  CHANGE: 'change',
  CLEAR: 'clear',
  CLICK: 'click',
  CLOSE_TAB: 'closeTab',
  DOUBLE_CLICK: 'dblClick',
  DRAG_AND_DROP: 'dragAndDrop',
  EXECUTE: 'execute',
  GOTO: 'goto',
  HOVER: 'hover',
  MOUSEDOWN: 'mouseDown',
  MOUSEMOVE: 'mouseMove',
  MOUSEUP: 'mouseUp',
  NEW_TAB: 'newTab',
  RELOAD_PAGE: 'reloadPage',
  RIGHT_CLICK: 'rightClick',
  SCROLL: 'scroll',
  SELECT: 'select',
  SET_LOCAL_VARIABLE: 'setLocalVariable',
  SWITCH_CONTEXT: 'switchContext',
  TYPE: 'type',
  UPLOAD_FILE: 'uploadFile',
};
