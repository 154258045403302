import type { Step } from '@bugbug/core/types/steps';
import { selectCurrentStep } from '~/modules/components/components.selectors';
import { useAppSelector } from '~/modules/store';
import { Container } from '~/views/TestDetails/components/Step/Step.styled';

interface StepRowProps {
  stepId: Step['id'];
}

export const StepRow = (props: StepRowProps) => {
  const currentStep = useAppSelector(selectCurrentStep);
  const { stepId } = props;

  return (
    <Container
      key={stepId}
      aria-selected={currentStep?.id === stepId}
      // @ts-expect-error Missing types
      active
      {...props}
    />
  );
};
