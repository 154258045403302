import { DropdownItem } from '@bugbug/core/components/Dropdown';
import { useFormikContext } from 'formik';
import { remove, update, prop } from 'ramda';
import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { StepDetailsDto, WaitingConditionWithIndex } from './WaitingConditions.types';

import useAppRoutes from '~/hooks/useAppRoutes';
import { WAITING_CONDITIONS_LIST } from '~/modules/constans';
import { selectSingleProjectConditionByType } from '~/modules/project/project.selectors';

import { WaitingCondition } from './WaitingCondition';
import { FIELD_NAMES } from './WaitingConditions.constants';
import { Container, Url, Dropdown } from './WaitingConditions.styled';

interface WaitingConditionsProps {
  className?: string;
  readOnly?: boolean;
}

export const WaitingConditions = ({ className, readOnly }: WaitingConditionsProps) => {
  const { t } = useTranslation();
  const formik = useFormikContext<StepDetailsDto>();
  const conditionsByType = useSelector(selectSingleProjectConditionByType);
  const appRoute = useAppRoutes('project');
  const { projectSlug, projectId, organizationId } = appRoute.params;

  const conditions = useMemo(
    () =>
      formik.values.waitingConditions.reduce(
        (groupedConditions, condition, index) => {
          const conditionWithIndex = { ...condition, index };
          if (condition.isGlobal) {
            groupedConditions.global.push(conditionWithIndex);
          } else {
            groupedConditions.additional.push(conditionWithIndex);
          }
          return groupedConditions;
        },
        { global: [], additional: [] } as Record<
          'global' | 'additional',
          WaitingConditionWithIndex[]
        >,
      ),
    [formik.values],
  );

  const availableConditionsList = useMemo(() => {
    const notAvailableTypes = conditions.global.map(prop('type'));
    return WAITING_CONDITIONS_LIST.filter(({ type }) => !notAvailableTypes.includes(type));
  }, [conditions.global]);

  const handleDelete = (index: number) => {
    formik.setFieldValue(
      FIELD_NAMES.WAITING_CONDITIONS,
      remove(index, 1, formik.values[FIELD_NAMES.WAITING_CONDITIONS]),
    );
  };

  const handleConditionChange = (index: number, updatedFields) => {
    const updatedCondition = {
      ...formik.values[FIELD_NAMES.WAITING_CONDITIONS][index],
      ...updatedFields,
    };
    formik.setFieldValue(
      FIELD_NAMES.WAITING_CONDITIONS,
      update(index, updatedCondition, formik.values[FIELD_NAMES.WAITING_CONDITIONS]),
    );
  };

  const handleAdd = (type) => () => {
    const waitingConditions = [
      ...formik.values[FIELD_NAMES.WAITING_CONDITIONS],
      { type, isActive: true, isOverridden: false },
    ];
    formik.setFieldValue(FIELD_NAMES.WAITING_CONDITIONS, waitingConditions);
  };

  const renderCondition = (condition: WaitingConditionWithIndex) => (
    <WaitingCondition
      key={condition.type}
      index={condition.index}
      condition={condition}
      defaultConditionState={conditionsByType[condition.type]}
      readOnly={readOnly}
      onChange={handleConditionChange}
      onRemove={handleDelete}
    />
  );

  return (
    <Container className={className} data-testid="WaitingConditions">
      <p>
        <Trans key="waitingConditions.section.global.description">
          By default inherited from{' '}
          <Url
            to={appRoute.getRouteUrl('projectSettings', {
              projectSlug,
              projectId,
              organizationId,
              settingsTabSlug: 'waiting-conditions',
            })}
          >
            global project settings.
          </Url>{' '}
          You can override the global settings for each step.
        </Trans>
      </p>
      {conditions.global.map(renderCondition)}
      {conditions.additional.map(renderCondition)}
      {!readOnly && (
        <Dropdown
          label={t('waitingConditions.section.additional.button', 'Add condition')}
          iconName="addCircle"
        >
          {availableConditionsList.map(({ label, type }) => (
            <DropdownItem small key={type} onClick={handleAdd(type)}>
              {label}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};
