import JSConfetti from 'js-confetti';
import { useCallback, useMemo } from 'react';

export const useConfetti = () => {
  const show = useCallback(async () => {
    const confetti = new JSConfetti();
    await confetti.addConfetti({
      confettiRadius: 3.5,
      confettiNumber: 800,
    });
    confetti.destroyCanvas();
  }, []);

  const result = useMemo(() => ({ show }), [show]);

  return result;
};
