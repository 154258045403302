import styled from 'styled-components';

import Page from '~/components/Page';

export const Container = styled(Page)``;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
`;

export const SortAndFilters = styled.div`
  margin: 0 auto 0 18px;
`;
