import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Badge = styled.div`
  background: ${({ theme }) => theme.colors.primarySoft};
  color: ${({ theme }) => theme.colors.background.surface};
  padding: 0 2px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 11px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding-bottom: 1px;
  margin-top: 1px;
`;
