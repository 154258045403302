import Icon from '@bugbug/core/components/Icon';
import styled from 'styled-components';

import InstalledSVG from '~/images/installed-checkmark.svg?react';
import BugBugSVG from '~/images/logo.svg?react';

export const Container = styled.div`
  width: 308px;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.colors.border.medium};
  padding: 16px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
`;

export const BugBugLogo = styled(BugBugSVG)`
  width: 52px;
  height: auto;
`;

export const InfoBox = styled.div<{ $installed?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 16px;
`;

export const ExtensionDescription = styled.p`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin: 0;
`;

export const ExtensionDetails = styled.div`
  display: flex;
  gap: 8px;
`;

export const TrustedSection = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  color: #0b57d0;
`;

export const StoreIcon = styled(Icon).attrs(() => ({
  name: 'chromeStore',
}))`
  min-width: 64px;
`;

export const StoreIconContainer = styled.div`
  position: relative;
  display: flex;
`;

export const DetailsParagraph = styled.p`
  margin: 0;
`;

export const ExternalLinkIcon = styled(Icon).attrs(() => ({
  name: 'externalLink',
}))`
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.colors.text.secondaryAllCaps};
  margin-bottom: auto;
  margin-left: auto;
`;

export const RatingSection = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const SuccessMessage = styled.p`
  margin: 0;
  font-size: 11px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.status.success};
`;

export const DetailsIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  fill: inherit;
`;

export const InstalledCheckMark = styled(InstalledSVG)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -4px;
  z-index: 10;
`;

export const CheckMarkSpacer = styled.div`
  width: 20px;
  height: 1px;
`;
