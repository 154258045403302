import * as T from '@bugbug/core/utils/toolbox';
import * as Sentry from '@sentry/react';
import {
  pathOr,
  propOr,
  path,
  pick,
  isEmpty,
  pipe,
  values,
  filter,
  prop,
  compose,
  map,
  find,
  complement,
  groupBy,
  flatten,
  props,
  sortBy,
  reverse,
} from 'ramda';
import { createSelector } from 'reselect';

import { SCREEN_RESOLUTION_TYPE } from '~/constants/test';
import {
  selectTestRunGroups,
  selectTestRunStep,
  selectTestRunSteps,
  selectTestRunGroup,
} from '~/modules/testRun/testRun.selectors';

import { selectCurrentStepId } from '../steps/steps.selectors';

const selectTestDomain = (state) => state.test;

export const selectSingleTest = createSelector(selectTestDomain, (state) =>
  pathOr({}, ['single', 'tests', path(['single', 'id'], state)], state),
);

export const selectIsTestRunPaused = createSelector(
  selectSingleTest,
  (test) => test?.testRun?.status === 'paused',
);

export const selectSingleTestHasAnySavedStep = createSelector(
  selectTestDomain,
  pipe(pathOr({}, ['single', 'steps']), values, find(complement(prop('isTemporary'))), Boolean),
);

export const selectDefaultStepsParams = createSelector(
  selectTestDomain,
  prop('defaultStepsParams'),
);

export const selectDefaultStepParamsByType = T.memoize((stepType) =>
  createSelector(selectDefaultStepsParams, prop(stepType)),
);

export const selectIsSingleTestLoading = createSelector(selectTestDomain, prop('isSingleLoading'));

export const selectTestGroups = createSelector(selectTestDomain, pathOr({}, ['single', 'groups']));

export const selectTests = createSelector(selectTestDomain, pathOr({}, ['list', 'tests']));

export const selectTestsIdsList = createSelector(selectTestDomain, pathOr([], ['list', 'order']));

export const selectTestsList = createSelector(selectTestsIdsList, selectTests, (testsIds, tests) =>
  props(testsIds, tests),
);

export const selectHasUsedVariousScreenSizes = createSelector(selectTestsList, (tests) =>
  tests.some((test) => test.screenSizeType === SCREEN_RESOLUTION_TYPE.MOBILE),
);

export const selectIsComponent = T.memoize((groupId) =>
  createSelector(selectTestGroups, pathOr(false, [groupId, 'isComponent'])),
);

export const selectTest = T.memoize((testId) => createSelector(selectTests, propOr(null, testId)));

export const selectTestGroupsList = createSelector(
  selectSingleTest,
  selectTestGroups,
  (test, groups) => props(test.groups || [], groups),
);

export const selectTestSteps = createSelector(selectTestDomain, pathOr({}, ['single', 'steps']));

export const selectSortedTestStepIds = createSelector(selectTestDomain, (state) => {
  const { single = {} } = state;
  if (isEmpty(single)) {
    return [];
  }
  const test = state.single.tests?.[single.id];

  return test?.groups.flatMap((groupId) => state.single.groups[groupId].steps) ?? [];
});

export const selectTestStepsActivation = createSelector(selectTestSteps, map(prop('isActive')));

export const selectTestStepsTemporaryIds = createSelector(
  selectTestSteps,
  compose(map(prop('id')), filter(prop('isTemporary')), values),
);

export const selectIsStepTemporary = T.memoize((stepId) =>
  createSelector(selectTestSteps, path([stepId, 'isTemporary'])),
);

export const selectTestStep = T.memoize((stepId) =>
  createSelector(selectTestSteps, propOr(null, stepId)),
);

export const selectTestGroup = T.memoize((groupId) =>
  createSelector(selectTestGroups, propOr(null, groupId)),
);

export const selectHasSteps = createSelector(
  selectTestSteps,
  selectTestGroups,
  (steps, groups) => !!Object.keys(steps).length && !!Object.keys(groups).length,
);

export const selectTestIsRecording = createSelector(selectTestDomain, (state) =>
  !isEmpty(state.single)
    ? state.single.tests?.[state.single.id]?.testRun?.status === 'recording'
    : false,
);

export const selectTestProfileId = createSelector(selectTestDomain, (state) =>
  !isEmpty(state.single) ? state.single.tests[state.single.id].runProfileId : null,
);

export const selectSearchComponentsData = createSelector(
  selectTestDomain,
  pathOr([], ['search', 'components']),
);

export const selectElementsScreenshots = createSelector(
  selectTestDomain,
  pathOr({}, ['elementsScreenshots']),
);

export const selectElementScreenshot = T.memoize((id) =>
  createSelector(selectElementsScreenshots, prop(id)),
);

export const selectStep = T.memoize((stepId, isTestRun) => {
  const selectData = isTestRun ? selectTestRunStep : selectTestStep;
  return selectData(stepId);
}, T.joinAllArgs);

export const selectGroups = (isTestRun) => (state) =>
  isTestRun ? selectTestRunGroups(state) : selectTestGroups(state);

export const selectTestGroupsIds = createSelector(selectTestGroups, (testGroups) =>
  Object.keys(testGroups),
);

export const selectGroup = T.memoize(
  (isTestRun, groupId) => (isTestRun ? selectTestRunGroup(groupId) : selectTestGroup(groupId)),
  T.joinAllArgs,
);

const getStepCacheId = (step) =>
  `${step.id || step.frontId}.${step.type}.${step.isActive}.${step.sleep}`;

const getGroupStepsList = T.memoize(
  (group, steps) => props(group?.steps || [], steps).filter(Boolean),
  (group, steps) =>
    group
      ? [
          group.id,
          ...group.steps
            .filter((stepId) => steps[stepId])
            .map((stepId) => getStepCacheId(steps[stepId])),
        ].join()
      : 'undefined',
);

export const deleteGroupsStepsListCache = (groupId, steps) => {
  const key = [groupId, ...steps.map(getStepCacheId)].join();
  getGroupStepsList.cache.delete(key);
};

export const selectGroupStepsList = T.memoize(
  (groupId, isTestRun) =>
    createSelector(
      selectGroup(isTestRun, groupId),
      isTestRun ? selectTestRunSteps : selectTestSteps,
      getGroupStepsList,
    ),
  T.joinAllArgs,
);

export const selectUnconfirmedGroupsMap = createSelector(
  selectTestDomain,
  pathOr({}, ['single', 'unconfirmedGroups']),
);

export const selectHasUnconfirmedGroups = createSelector(
  selectSingleTest,
  pipe(path(['unconfirmedGroups', 'length']), Boolean),
);

export const selectUnconfirmedGroup = (groupId) =>
  createSelector(selectUnconfirmedGroupsMap, propOr({}, groupId));

export const selectRecentUnconfirmedGroup = createSelector(
  selectUnconfirmedGroupsMap,
  pipe(values, sortBy(prop('created')), reverse, prop(0)),
);

export const selectUnconfirmedGroupsByRelatedGroups = createSelector(
  selectUnconfirmedGroupsMap,
  pipe(values, groupBy(prop('unconfirmedRelatedGroup'))),
);

export const selectUnconfirmedGroups = T.memoize(
  (relatedGroupId, index, includeOutOfRange = false) =>
    createSelector(
      selectUnconfirmedGroupsByRelatedGroups,
      pipe(
        propOr([], relatedGroupId),
        filter((group) =>
          includeOutOfRange ? group.unconfirmedIndex >= index : group.unconfirmedIndex === index,
        ),
      ),
    ),
  T.joinAllArgs,
);

export const selectOrderedGroupPartials = T.memoize(
  (groupId, isTestRun = false) =>
    createSelector(
      selectGroup(isTestRun, groupId),
      isTestRun ? selectTestRunSteps : selectTestSteps,
      selectUnconfirmedGroupsByRelatedGroups,
      (group, steps, unconfirmedGroupsMap) => {
        if (!group) {
          return [];
        }
        const unconfirmedGroups = isTestRun ? [] : propOr([], groupId, unconfirmedGroupsMap);
        const unconfirmedGroupsByIndex = groupBy(prop('unconfirmedIndex'), unconfirmedGroups);

        const partials = group.steps.map((stepId, index) => {
          const chunks = [];

          if (unconfirmedGroupsByIndex[index]) {
            chunks.push(unconfirmedGroupsByIndex[index]);
            delete unconfirmedGroupsByIndex[index];
          }

          if (steps[stepId]) {
            chunks.push(steps[stepId]);
          } else {
            // TODO: Remove after Sentry WEBAPP-1CY is resolved
            Sentry.captureMessage('Missing step details', {
              level: 'debug',
              contexts: {
                debugInfo: {
                  isTestRun,
                  groupSteps: group.steps,
                  stepsWithDetails: Object.values(steps).map((s) => s.id),
                  groupId,
                  stepId,
                },
              },
            });
          }

          return chunks;
        });
        const outOfRangeGroups = flatten(Object.values(unconfirmedGroupsByIndex));
        partials.push(outOfRangeGroups);

        return flatten(partials).map((partial) => ({
          ...partial,
          partialId: partial.steps
            ? `group.${partial.id}`
            : `step.${partial.id || partial.frontId}`,
        }));
      },
    ),
  T.joinAllArgs,
);

export const selectOrderedGroupPartialsIndices = T.memoize(
  (groupId, isTestRun = false) =>
    createSelector(selectOrderedGroupPartials(groupId, isTestRun), (partials) =>
      partials.reduce((indicesMap, partial, index) => {
        // eslint-disable-next-line no-param-reassign
        indicesMap[partial.partialId] = index;
        return indicesMap;
      }, {}),
    ),
  T.joinAllArgs,
);

export const selectChangedNotificationByTestIdMap = createSelector(
  selectTestDomain,
  prop('changeNotificationByTestId'),
);

export const selectChangedNotificationByTestId = T.memoize((testId) =>
  createSelector(selectChangedNotificationByTestIdMap, propOr(null, testId)),
);

export const selectLastRunsByTestsIds = T.memoize(
  (ids = []) =>
    createSelector(selectTests, (testsMap) =>
      Object.values(pick(ids, testsMap))
        .map(path(['lastUnfinishedUserRun', 'id']))
        .filter(Boolean),
    ),
  (ids = []) => ids.join('.'),
);

export const selectComponentsUsedInTest = createSelector(
  selectSingleTest,
  selectGroups(false),
  (test, groups) => test.groups.filter((id) => groups[id].isComponent),
);

export const selectCurrentStepIndex = createSelector(
  selectSortedTestStepIds,
  selectCurrentStepId,
  (stepsIds, currentStepId) => {
    const index = stepsIds.indexOf(currentStepId);
    return index < 0 ? null : index;
  },
);

export const selectSortedSteps = createSelector(
  selectSingleTest,
  selectTestGroups,
  selectTestSteps,
  (test, groups, steps) =>
    !T.isEmpty(test ?? {})
      ? Object.values(steps)
          .filter((step) => test.groups.indexOf(step.groupId) >= 0)
          .sort((a, b) => {
            const aGroupIndex = test.groups.indexOf(a.groupId);
            const bGroupIndex = test.groups.indexOf(b.groupId);

            if (aGroupIndex === bGroupIndex) {
              const aStepIndex = groups[a.groupId]?.steps.indexOf(a.id) ?? -1;
              const bStepIndex = groups[b.groupId]?.steps.indexOf(b.id) ?? -1;
              return aStepIndex - bStepIndex;
            }

            return aGroupIndex - bGroupIndex;
          })
      : [],
);
