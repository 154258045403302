import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import type { ScrollToCoordsStep, ScrollStep } from '@bugbug/core/types/steps';
import FormField from '~/components/FormField';
import {
  STEP_SCROLL_TARGET,
  STEP_SCROLL_DIRECTION,
  STEP_SCROLL_EDGE,
  STEP_SCROLL_TO,
  STEP_SCROLL_TO_TYPE,
} from '~/constants/step';

import { ElementSelectorBuilder } from '../ElementSelectorBuilder/ElementSelectorBuilder';
import { getInitialValues } from '../StepDetails/StepDetails.helpers';

import { CoordsValues, EdgeType } from './ScrollActionFields.styled';

interface ScrollActionFieldsProps {
  context?: 'component' | 'test' | 'testRun';
  readOnly?: boolean;
}

export const ScrollActionFields = ({ context = 'test', readOnly }: ScrollActionFieldsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'stepDetails.interaction.scroll' });
  const formik = useFormikContext<ScrollStep>();

  const getFieldProps = <T extends ScrollStep>(fieldName: keyof T) => {
    const { value, name, onBlur, onChange } = formik.getFieldProps(fieldName as string);
    const { touched, error } = formik.getFieldMeta(fieldName as string);
    return { name, value, error: touched && error, onBlur, onChange };
  };

  const handleUpdateScrollToType = (event) => {
    const { value: scrollTo } = event.target;

    formik.setValues(
      getInitialValues({
        ...formik.initialValues,
        scrollTo,
      }),
    );
  };

  const { onBlur: _, ...scrollToProps } = getFieldProps('scrollTo');

  return (
    <>
      <FormField label={t('scrollInside', 'Scroll inside')}>
        <Select
          {...getFieldProps('scrollInside')}
          onChange={formik.handleChange}
          disabled={readOnly}
          fullWidth
        >
          {Object.values(STEP_SCROLL_TARGET).map(({ value, label }) => (
            <SelectOption key={value} value={value}>
              {label}
            </SelectOption>
          ))}
        </Select>
      </FormField>
      {formik.values.scrollInside === 'element' && (
        <ElementSelectorBuilder context={context} disabled={readOnly} />
      )}
      <FormField label={t('position', 'Scroll to')}>
        <Select
          fullWidth
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...scrollToProps}
          onChange={handleUpdateScrollToType}
          disabled={readOnly}
        >
          {Object.values(STEP_SCROLL_TO).map(({ value, label }) => (
            <SelectOption key={value} value={value}>
              {label}
            </SelectOption>
          ))}
        </Select>
      </FormField>
      {scrollToProps.value === STEP_SCROLL_TO_TYPE.COORDS && (
        <CoordsValues>
          <Input
            fullWidth
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFieldProps<ScrollToCoordsStep>('scrollX')}
            readOnly={readOnly}
            startAdornment={t('scrollX.label', 'X')}
            endAdornment={t('unit', 'px')}
            aria-label={t('scrollX.ariaLabel', 'Position X')}
          />
          <Input
            fullWidth
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFieldProps<ScrollToCoordsStep>('scrollY')}
            readOnly={readOnly}
            startAdornment={t('scrollY.label', 'Y')}
            endAdornment={t('unit')}
            aria-label={t('scrollY.ariaLabel', 'Position Y')}
          />
        </CoordsValues>
      )}
      {scrollToProps.value === STEP_SCROLL_TO_TYPE.EDGE && (
        <EdgeType>
          <Select
            fullWidth
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFieldProps('scrollEdge')}
            disabled={readOnly}
          >
            {Object.values(STEP_SCROLL_EDGE).map(({ value, label }) => (
              <SelectOption key={value} value={value}>
                {label}
              </SelectOption>
            ))}
          </Select>
        </EdgeType>
      )}
      {formik.values.scrollTo === 'untilNextStepElementIsVisible' && (
        <FormField label={t('direction', 'Direction')}>
          <Select
            fullWidth
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFieldProps('scrollDirection')}
            onChange={formik.handleChange}
            disabled={readOnly}
          >
            {Object.values(STEP_SCROLL_DIRECTION).map(({ value, label }) => (
              <SelectOption key={value} value={value}>
                {label}
              </SelectOption>
            ))}
          </Select>
        </FormField>
      )}
    </>
  );
};
