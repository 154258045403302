import Button from '@bugbug/core/components/Button';
import { noop } from '@bugbug/core/utils/toolbox';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import type { CodeProps } from '~/components/Code/Code';

import useModal from '~/hooks/useModal';

import { Footer, Header } from '../Modal';

import * as S from './CodeEditorModal.styled';

const CodeEditorModal = (props: CodeProps) => {
  const { value, onChange = noop, onBlur = noop, readOnly, name = 'code', error } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'codeEditorModal' });
  const modal = useModal();
  const formik = useFormik({
    initialValues: {
      code: value,
    },
    initialErrors: {
      code: error,
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: noop,
  });

  const handleChange = (event) => {
    formik.handleChange(event);
    onChange({ target: { name, value: event.target.value } });
  };

  const handleBlur = (event) => {
    formik.handleBlur(event);
    onBlur({ target: { name, value: event.target.value } });
  };

  return (
    <>
      <Header>
        {t('title', 'Code editor')}
        <S.MinimizeIconButton onClick={modal.hide} />
      </Header>
      <S.FullScreenCode
        {...props}
        {...formik.getFieldProps('code')}
        {...formik.getFieldMeta('code')}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Footer>
        <Button type="submit" variant="primary" onClick={modal.hide}>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {readOnly ? t('close', 'Close') : t('submit', 'Update')}
        </Button>
      </Footer>
    </>
  );
};

export default CodeEditorModal;
