import { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Input from '@bugbug/core/components/Input';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import useActionState from '~/hooks/useActionState';
import { useAppDispatch } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import { SignInSchema } from '~/modules/user/user.schemas';
import { FormField } from '~/views/Onboarding/Onboarding.styled';
import urls from '~/views/urls';

import * as S from './LoginForm.styled';

interface SignInFormErrors {
  email?: string;
  password?: string;
  emailIsNotVerified?: boolean;
  invalid?: string;
}

export const LoginForm = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(undefined, { keyPrefix: 'auth.login' });

  const { errors, handleBlur, handleChange, handleSubmit, touched, values, setErrors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignInSchema,
    onSubmit: () => dispatch(UserActions.loginRequest(values)),
  });

  const customErrors: SignInFormErrors = errors;

  const handleResendSuccess = useCallback(() => {
    history.push(urls.signUpSuccess, { email: values.email, redirected: true });
  }, [values.email, history]);

  const actionState = useActionState(UserActions.loginRequest, { onFailure: setErrors });
  useActionState(UserActions.resendActivationEmailRequest, {
    onSuccess: handleResendSuccess,
  });

  const handleResendEmail = useCallback(() => {
    dispatch(UserActions.resendActivationEmailRequest(values.email));
  }, [values.email, dispatch]);

  return (
    <S.Form onSubmit={handleSubmit} noValidate data-testid="LoginForm">
      {customErrors.invalid && <S.ErrorMessage>{customErrors.invalid}</S.ErrorMessage>}
      {values.email && customErrors.emailIsNotVerified && (
        <S.ErrorMessage>
          <Trans i18nKey="auth.login.notActiveEmail">
            You need to confirm your e-mail address first.
            <br />
            Didn't get the verification e-mail?{' '}
            <S.ResendEmailButton onClick={handleResendEmail}>Send again</S.ResendEmailButton>
          </Trans>
        </S.ErrorMessage>
      )}
      <FormField label={t('emailPlaceholder', 'E-mail')}>
        <Input
          type="email"
          name="email"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          autoComplete="email"
          error={touched.email && errors.email}
        />
      </FormField>
      <FormField label={t('passwordPlaceholder', 'Password')}>
        <Input
          type="password"
          name="password"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          autoComplete="current-password"
          error={touched.password && errors.password}
        />
      </FormField>
      <S.SubmitButton
        type="submit"
        variant={BUTTON_VARIANT.PRIMARY}
        pending={actionState.isLoading}
      >
        {t('submitButton', 'Log in')}
      </S.SubmitButton>
    </S.Form>
  );
};
