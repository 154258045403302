import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import { isRecordingOrPausedStatus } from '@bugbug/core/types/base';
import { useCallback, useRef } from 'react';

import type EditableText from '~/components/EditableText';

import type { Test } from '@bugbug/core/types/tests';
import { StatusBadgeFlex } from '~/components/RunMetadata/RunMetadata.styled';
import TestRunMetadata from '~/components/TestRunMetadata';
import useActionState from '~/hooks/useActionState';
import { useCopyPasteSteps } from '~/hooks/useCopyPasteSteps';
import useQueryString from '~/hooks/useQueryString';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectSingleTest, selectTestStepsActivation } from '~/modules/test/test.selectors';
import { selectFailedStepRunWithStep } from '~/modules/testRun/testRun.selectors';
import { selectCheckedSteps } from '~/modules/uiState/uiState.selectors';

import { SelectedRowsActions as SelectedRowsActionsHeader } from '../SelectedRowsActions/SelectedRowsActions';
import { TestHeaderActions } from '../TestActions/TestHeaderActions';

import * as S from './TestHeader.styled';

interface TestHeaderProps {
  readOnly: boolean;
  onDeselect: () => void;
  onErrorClick?: () => void;
}

export const TestHeader = ({ onDeselect, onErrorClick, readOnly }: TestHeaderProps) => {
  const nameFieldRef = useRef<typeof EditableText>();
  const dispatch = useAppDispatch();
  const test = useAppSelector(selectSingleTest) as Test;
  const stepsActivation = useAppSelector(selectTestStepsActivation);
  const checkedSteps = useAppSelector(selectCheckedSteps);
  const failedStepRunWithStep = useAppSelector(selectFailedStepRunWithStep);
  const failedStepId = failedStepRunWithStep?.failedStepRun?.stepId;
  const query = useQueryString();
  const { copySteps } = useCopyPasteSteps();

  const testRun = test?.testRun;
  const hasGroups = !!test?.groups?.length;

  const handleNameChange = useCallback(
    (value) => {
      dispatch(TestActions.updateRequest(test.id, { name: value }, { reqId: test.id }));
    },
    [dispatch, test.id],
  );

  const handleNameChangeFailure = useCallback((errors) => {
    // @ts-expect-error missing types
    nameFieldRef.current?.setError(errors.name);
  }, []);

  useActionState(TestActions.updateRequest, {
    onFailure: handleNameChangeFailure,
    reqId: test.id,
  });

  const handleStepsActivationChange = useCallback(
    (steps, value) => {
      dispatch(TestActions.updateStepsActivationRequest(test.id, steps, value));
      onDeselect();
    },
    [dispatch, test.id, onDeselect],
  );

  const handleStepsDelete = useCallback(() => {
    onDeselect();
  }, [onDeselect]);

  const handleCopySteps = useCallback(
    (stepIds) => {
      copySteps(stepIds);
      onDeselect();
    },
    [onDeselect, copySteps],
  );

  const isRecordingOrPaused = testRun && isRecordingOrPausedStatus(testRun.status);

  return (
    <S.Container data-testid="TestHeader">
      <S.Title>
        <S.NameContainer>
          {test?.name && (
            <S.NameInput
              data-hj-allow
              ref={nameFieldRef}
              // @ts-expect-error missing types
              value={test?.name}
              onChange={handleNameChange}
              readOnly={readOnly}
              max={DATA_RESTRICTIONS.TEST_NAME_MAX_LENGTH}
            />
          )}
        </S.NameContainer>
        <S.Actions>
          {checkedSteps.length > 0 ? (
            <SelectedRowsActionsHeader
              testId={test.id}
              onDeselect={onDeselect}
              stepsActivation={stepsActivation}
              onDelete={handleStepsDelete}
              onActivationChange={handleStepsActivationChange}
              onCopySteps={handleCopySteps}
            />
          ) : (
            <TestHeaderActions testId={test.id} testRunId={query.testRunId || testRun?.id} />
          )}
        </S.Actions>
      </S.Title>
      {hasGroups && isRecordingOrPaused && (
        <S.RecordingStatusWrapper>
          <StatusBadgeFlex status={testRun.status} />
        </S.RecordingStatusWrapper>
      )}
      {hasGroups && !isRecordingOrPaused && (
        <TestRunMetadata
          testRunId={testRun?.id}
          onErrorClick={failedStepId ? onErrorClick : undefined}
          variant="testDetails"
        />
      )}
    </S.Container>
  );
};
