import mixins from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

import PublicPage from '~/components/PublicPage';

export const Container = styled(PublicPage)`
  ${({ theme }) => theme.media.desktop`
    padding: 0;

    > a {
      position: absolute;
      top: 25px;
      left: 25px;
    }
  `}
`;

export const PageLayout = styled.div`
  ${mixins.layout.gridSingleColumn};
  flex: 1;

  > div:nth-child(1) {
    ${mixins.layout.flexColumnCenter};
  }

  > div:nth-child(2) {
    display: none;
  }

  ${({ theme }) => theme.media.desktop`
    ${mixins.layout.gridTwoColumns};

    > div:nth-child(1) {
      padding: 50px;
    }

    > div:nth-child(2) {
      padding: 0;
      ${mixins.layout.flexRowStretch};
    }
  `}

  ${({ theme }) => theme.media.desktopWide`
    grid-template-columns: 1fr 700px;
  `}
`;

export const Email = styled.span`
  overflow-wrap: anywhere;
`;

export const ByInvitationPageLayout = styled.div`
  ${mixins.layout.gridSingleColumn};
  ${mixins.layout.flexColumnCenter};

  flex: 1;
`;
