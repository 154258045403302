import { noop } from '@bugbug/core/utils/toolbox';
import { useRef, useCallback } from 'react';

import type { Test, TestRun } from '@bugbug/core/types/tests';
import type { SideEffect } from '@bugbug/core/types/utils';
import useTestRunner from '~/hooks/useTestRunner';
import { useAppSelector } from '~/modules/store';
import { selectHasSteps } from '~/modules/test/test.selectors';

import IdleButtons from './IdleButtons';
import MoreActions from './MoreActions';
import { PauseButtons } from './PauseButtons';
import { RecordingButtons } from './RecordingButtons';
import { RunningButtons } from './RunningButtons';
import * as S from './TestHeaderActions.styled';

interface TestHeaderActionsProps {
  className?: string;
  testId: Test['id'];
  testRunId?: TestRun['id'];
  onClearTest?: SideEffect;
}

export const TestHeaderActions = ({
  className,
  onClearTest = noop,
  testId,
  testRunId,
}: TestHeaderActionsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const hasSteps = useAppSelector(selectHasSteps);

  const testRunner = useTestRunner({ id: testId }, { testRunId });

  const handleRename = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
    if (containerRef.current) {
      const input = containerRef.current.closest('header')?.querySelector('input');
      input?.focus();
      input?.select();
    }
  }, []);

  return (
    <S.Container
      ref={containerRef}
      className={className}
      data-testid="TestDetails.TestHeaderActions"
      id="testHeader"
    >
      {hasSteps && testRunner.status === 'idle' && <IdleButtons testId={testId} />}
      {testRunner.status === 'running' && <RunningButtons testId={testId} testRunId={testRunId} />}
      {testRunner.status === 'paused' && <PauseButtons testId={testId} testRunId={testRunId} />}
      {testRunner.status === 'recording' && (
        <RecordingButtons testId={testId} testRunId={testRunId} />
      )}
      {testRunner.status === 'idle' && (
        <MoreActions
          testId={testId}
          onClearTest={onClearTest}
          onRename={handleRename}
          hasSteps={hasSteps}
        />
      )}
    </S.Container>
  );
};
