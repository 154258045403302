import styled from 'styled-components';

import tutorialThumbnailSrc from '~/images/tutorial-thumbnail.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 28px;
  width: 580px;
`;

export const SectionsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const ThumbnailTutorial = styled.img.attrs(() => ({
  src: tutorialThumbnailSrc,
}))`
  width: 76px;
  height: 54px;
  cursor: pointer;
`;
