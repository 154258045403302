import LoaderComponent, { LoaderFlexContainer } from '@bugbug/core/components/Loader';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type React from 'react';
import type { SearchableListEntryProps } from '~/components/SearchableList/SearchableList.types';

import type { Component } from '@bugbug/core/types/components';
import type { RouteParams } from '@bugbug/core/types/routes';
import type { Test } from '@bugbug/core/types/tests';
import type { SideEffect } from '@bugbug/core/types/utils';
import { Content, Header } from '~/components/modals/Modal/Modal.styled';
import SearchableList from '~/components/SearchableList';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { useGetComponentQuery, useGetRelatedTestsQuery } from '~/modules/components/components.api';

import * as S from './ShowComponentRelatedTestsModal.styled';

export interface ShowComponentRelatedTestsProps {
  component: Component;
  forEditing?: boolean;
  params: RouteParams<'component'>;
}

const ShowComponentRelatedTestsModal = ({
  component,
  forEditing = false,
  params,
}: ShowComponentRelatedTestsProps) => {
  const {
    data: componentDetails,
    isLoading: areComponentDetailsLoading,
    isError: haveComponentDetailsError,
    refetch: refetchComponentDetails,
  } = useGetComponentQuery(component.id);

  const {
    data: relatedTests,
    isLoading: areRelatedTestsLoading,
    isError: haveRelatedTestsError,
    refetch: refetchRelatedTests,
  } = useGetRelatedTestsQuery(component.id);

  const hasErrors = useMemo(
    () => haveComponentDetailsError || haveRelatedTestsError,
    [haveComponentDetailsError, haveRelatedTestsError],
  );

  const isLoading = useMemo(
    () => areComponentDetailsLoading || areRelatedTestsLoading,
    [areComponentDetailsLoading, areRelatedTestsLoading],
  );

  return (
    <>
      {hasErrors &&
        (haveComponentDetailsError ? (
          <ServerErrorInfo
            isVisible={haveComponentDetailsError}
            onRetry={refetchComponentDetails}
          />
        ) : (
          <ServerErrorInfo isVisible={haveRelatedTestsError} onRetry={refetchRelatedTests} />
        ))}

      {isLoading && (
        <LoaderFlexContainer>
          <LoaderComponent size="large" />
        </LoaderFlexContainer>
      )}

      {!isLoading && !hasErrors && (
        <ShowComponentRelatedTestsContent
          component={componentDetails as Component}
          forEditing={forEditing}
          params={params}
          relatedTests={relatedTests as Test[]}
        />
      )}
    </>
  );
};

export interface ShowComponentRelatedTestsContentProps extends ShowComponentRelatedTestsProps {
  forEditing: boolean;
  relatedTests: Test[];
}

const ShowComponentRelatedTestsContent = ({
  component,
  forEditing,
  params,
  relatedTests,
}: ShowComponentRelatedTestsContentProps) => {
  const { t } = useTranslation();
  const { getRouteUrl, push } = useAppRoutes('component');
  const modal = useModal();

  const handleOnListEntryClick = useCallback<SideEffect<Test['id']>>(
    (testId) => {
      const stepId = component.steps[0]?.id;

      if (forEditing) {
        push('test', { ...params, testId }, { groupId: component.id, stepId });
        modal.hide();
      } else {
        window.open(
          getRouteUrl('test', { ...params, testId }, { groupId: component.id, stepId }),
          '_blank',
        );
      }
    },
    [component, getRouteUrl, forEditing, modal, params, push],
  );

  const ListEntry = useCallback<React.FC<SearchableListEntryProps<Test>>>(
    ({ entry }) => (
      <S.ListEntryContainer>
        <S.Container>{entry.name}</S.Container>
        <S.LinkIcon name={forEditing ? 'cornerDownRight' : 'externalLink'} />
      </S.ListEntryContainer>
    ),
    [forEditing],
  );

  return (
    <S.Container data-testid="ShowComponentRelatedTestsModal">
      <Header>
        {forEditing
          ? t('ShowComponentRelatedTestsModal.title.forEditing', 'Edit "{{name}}" component', {
              name: component.name,
            })
          : t('ShowComponentRelatedTestsModal.title.forViewing', '"{{name}}" component', {
              name: component.name,
            })}
      </Header>

      <S.InfoMessage>
        {forEditing
          ? t(
              'ShowComponentRelatedTestsModal.info.forEditing',
              'You can edit this component in the context of a test. In which test would you like to edit this component?',
            )
          : t('ShowComponentRelatedTestsModal.info.forEditing', {
              count: component.testsCount,
              defaultValue_one: '{{count}} related test',
              defaultValue_other: '{{count}} related tests',
            })}
      </S.InfoMessage>

      <Content>
        <SearchableList
          onEntryClick={handleOnListEntryClick}
          data={relatedTests}
          labelField="name"
          ListEntry={ListEntry}
        />
      </Content>
    </S.Container>
  );
};

export default ShowComponentRelatedTestsModal;
