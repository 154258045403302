import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { STEP_TYPE_PICKER_TAB } from '~/components/StepTypePicker';

import { useCopyPasteSteps } from '~/hooks/useCopyPasteSteps';
import useModal from '~/hooks/useModal';
import { useAppDispatch } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';

import * as S from '../RowActiveBorder.styled';

interface CommonActionsMenuProps {
  testId: string;
  groupId: string;
  index: number;
  atIndex: number;
  testsCount: number;
  nextGroupIndex: number;
  componentMode?: boolean;
  disabledStepOptions?: boolean;
  disabledGroupOptions?: boolean;
  disabledSplitting?: boolean;
}

export const CommonActionsMenu = ({
  testId,
  groupId,
  index,
  atIndex,
  nextGroupIndex,
  testsCount,
  componentMode: isComponent,
  disabledStepOptions,
  disabledGroupOptions,
  disabledSplitting,
}: CommonActionsMenuProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pasteSteps, hasCopiedSteps } = useCopyPasteSteps();
  const modal = useModal();

  const addStep = useCallback(
    (initialTab: (typeof STEP_TYPE_PICKER_TAB)[string]) =>
      modal.showWide('create_step', {
        testId,
        groupId,
        atIndex,
        initialTab,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [testId, groupId, atIndex],
  );

  const handleInsertGroup = useCallback(() => {
    dispatch(TestActions.createStepsGroupRequest(testId, null, [], nextGroupIndex));
  }, [dispatch, testId, nextGroupIndex]);

  const handleInsertComponent = useCallback(
    () =>
      modal.show('insert_component', {
        testId,
        atIndex: nextGroupIndex,
      }),
    [modal, testId, nextGroupIndex],
  );

  const handleInsertStep = useCallback(() => addStep('actions'), [addStep]);

  const handleInsertAssertion = useCallback(() => addStep('assertions'), [addStep]);

  const handlePasteSteps = useCallback(
    () =>
      pasteSteps(
        testId,
        disabledStepOptions ? null : groupId,
        disabledStepOptions ? nextGroupIndex : atIndex,
      ),
    [testId, groupId, atIndex, pasteSteps, nextGroupIndex, disabledStepOptions],
  );

  const handleSplitGroup = useCallback(() => {
    if (isComponent && testsCount > 1) {
      modal.show('group_split_confirmation', {
        testId,
        groupId,
        index,
        testsCount,
      });
      return;
    }

    dispatch(TestActions.splitGroupRequest(testId, groupId, index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testsCount, dispatch, testId, groupId, index, modal.show, isComponent]);

  return (
    <>
      <DropdownItem
        data-testid="RowActiveBorder.NewStep"
        onClick={handleInsertStep}
        disabled={disabledStepOptions}
      >
        <Icon name="add" />
        {t('rowActiveBorder.option.newStep', 'New step')}
      </DropdownItem>
      <DropdownItem
        data-testid="RowActiveBorder.NewAssertion"
        onClick={handleInsertAssertion}
        disabled={disabledStepOptions}
      >
        <Icon name="add" />
        {t('rowActiveBorder.option.newAssertion', 'New assertion')}
      </DropdownItem>
      <DropdownItem
        data-testid="RowActiveBorder.PasteSteps"
        onClick={handlePasteSteps}
        disabled={!hasCopiedSteps}
      >
        <S.Paste />
        {t('rowActiveBorder.option.pasteSteps', 'Paste step(s)')}
      </DropdownItem>
      <S.OptionsSeparator />
      <DropdownItem
        data-testid="RowActiveBorder.NewGroup"
        onClick={handleInsertGroup}
        disabled={disabledGroupOptions}
      >
        <Icon name="add" />
        {t('rowActiveBorder.option.newGroup', 'New group')}
      </DropdownItem>
      <DropdownItem
        onClick={handleInsertComponent}
        data-testid="RowActiveBorder.InsertExistingComponent"
        disabled={disabledGroupOptions}
      >
        <Icon name="add" />
        {t('rowActiveBorder.option.insertExistingComponent', 'Insert existing component')}
      </DropdownItem>
      <DropdownItem
        onClick={handleSplitGroup}
        data-testid="RowActiveBorder.SplitHere"
        disabled={disabledSplitting}
      >
        <Icon name="split" />
        {isComponent
          ? t('rowActiveBorder.option.splitComponent', 'Split component')
          : t('rowActiveBorder.option.splitHere', 'Split group here')}
      </DropdownItem>
    </>
  );
};
