import { ErrorMessage } from '@bugbug/core/theme/typography';
import { is } from 'ramda';
import { Fragment, memo, useCallback, useMemo } from 'react';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/edit/closebrackets';
import { useToggle } from 'react-use';

import type { CodeStyledProps } from './Code.types';
import type React from 'react';
import type { JSXElementConstructor } from 'react';

import type { CustomEventHandler } from '@bugbug/core/types/forms';

import * as S from './Code.styled';

export { Snippet, Function, Argument, Line } from './Code.styled';

export interface CodeProps extends Pick<CodeStyledProps, 'readOnly'> {
  className?: string;
  'aria-labelledby'?: string;
  value?: string;
  name?: string;
  error?: string;
  onChange: (param: CustomEventHandler) => void;
  onBlur: (param: CustomEventHandler) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Wrapper?: JSXElementConstructor<any>;
  language?: 'json' | 'javascript';
  children?: React.ReactNode;
}

export const Code = memo(
  ({
    'aria-labelledby': ariaLabelledBy,
    className,
    value = '',
    onChange,
    onBlur,
    name = 'code',
    Wrapper = Fragment,
    error,
    readOnly = false,
    language = 'javascript',
    children,
  }: CodeProps) => {
    const [isFocused, setIsFocused] = useToggle(false);
    const handleChange = useCallback(
      (editor, data, newValue) => {
        onChange({ target: { value: newValue, name } });
      },
      [onChange, name],
    );

    const handleBlur = useCallback(
      (editor, event) => {
        setIsFocused(false);
        onBlur({ target: { value: event.target.value, name } });
      },
      [onBlur, setIsFocused, name],
    );

    const options = useMemo(
      () => ({
        readOnly: readOnly && 'nocursor',
        theme: 'material',
        smartIndent: true,
        language,
        // Addons
        autoCloseBrackets: true,
      }),
      [readOnly, language],
    );

    return (
      <>
        <S.Container
          aria-labelledby={ariaLabelledBy}
          className={className}
          data-testid="Code"
          readOnly={readOnly}
          focused={isFocused}
          invalid={!!error}
        >
          <Wrapper>
            <S.Editor
              value={value}
              options={options}
              onBeforeChange={handleChange}
              onBlur={handleBlur}
              onFocus={setIsFocused}
              autoCursor
              autoScroll
            />
          </Wrapper>
          {children}
        </S.Container>
        {is(String, error) ? <ErrorMessage>{error}</ErrorMessage> : null}
      </>
    );
  },
);

Code.displayName = 'Code';
