import type { ModalProps } from '~/components/modals/Modal/Modal';
import type { Modal } from '~/components/modals/modals.types';

import type { Route } from '@bugbug/core/types/routes';
import type { SideEffect } from '@bugbug/core/types/utils';

export type ModalSize = 'normal' | 'wide' | 'full-screen' | 'tutorial';

export interface ModalContextProps {
  show: ModalRenderer;
  showWide: ModalRenderer;
  showFullscreen: ModalRenderer;
  showTutorial: ModalRenderer;
  showCustom: CustomModalRenderer;
  hide: SideEffect;
  isOpen: boolean;
}

export interface ModalConfig extends Omit<ModalProps, 'className' | 'children'> {
  onHide?: SideEffect<Route>;
  stacked?: boolean;
}

export interface ActiveModal {
  id?: string;
  type?: Modal;
  props?: Record<string, unknown>;
  render?: () => React.ReactNode;
  config?: ModalConfig;
}

export type ModalRenderer = <T extends Modal>(
  type: T,
  props?: ActiveModal['props'],
  config?: ActiveModal['config'],
) => void;

export type CustomModalRenderer = SideEffect<ActiveModal['render'], ActiveModal['config']>;

export function isMouseEvent(arg?: MouseEvent | Route): arg is MouseEvent {
  return !!arg && typeof arg === 'object' && 'button' in arg;
}
