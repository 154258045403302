import Button, { ActionButton } from '@bugbug/core/components/Button';
import Checkbox from '@bugbug/core/components/Checkbox';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Header, Footer, HelpIcon } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import { selectIgnoreStopTestAlert } from '~/modules/uiState/uiState.selectors';

import * as S from './StopTestSessionModal.styled';

interface StopTestSessionModalProps {
  onConfirm: () => void;
}

export const StopTestSessionModal = ({ onConfirm }: StopTestSessionModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const dispatch = useAppDispatch();
  const ignoreStopTestAlert = useAppSelector(selectIgnoreStopTestAlert);

  const handleConfirm = useCallback(() => {
    modal.hide();
    onConfirm();
  }, [modal, onConfirm]);

  return (
    <S.Container>
      <S.Content>
        <HelpIcon />
        <S.ModalInfo>
          <Header>{t('stopTestSessionModal.title', 'Stop the test?')}</Header>
          <p>
            <Trans i18nKey="stopTestSessionModal.description" shouldUnescape>
              Just to be sure, do you want to finish the test run session?{' '}
              <b>This will close the browser window that runs the test.</b> All your recorded steps
              will be saved.
            </Trans>
          </p>
          <Checkbox
            name="doNotShowAgain"
            checked={ignoreStopTestAlert}
            onChange={() => {
              dispatch(
                UIStateActions.setIgnoreStopTestAlert({
                  ignore: !ignoreStopTestAlert,
                }),
              );
            }}
          >
            {t('stopTestSessionModal.doNotShowAgain', "Don't show again")}
          </Checkbox>
        </S.ModalInfo>
      </S.Content>
      <Footer>
        <Button onClick={() => modal.hide()} variant="primary" bordered>
          {t('stopTestSessionModal.cancel', 'No, go back')}
        </Button>
        <ActionButton onClick={handleConfirm} variant="primary">
          {t('stopTestSessionModal.confirm', 'Yes, stop the test')}
        </ActionButton>
      </Footer>
    </S.Container>
  );
};
