import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Alert } from '@bugbug/core/types/alerts';
import { ConfirmModal } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import { useCloneAlertMutation } from '~/modules/alerts/alerts.api';
import toasts from '~/services/toasts';

interface CloneAlertModalProps {
  alert: Alert;
}
const CloneAlertModal = ({ alert }: CloneAlertModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();

  const [handleClone, { isLoading, isError, isSuccess }] = useCloneAlertMutation();

  const handleCloneConfirm = async () => {
    await handleClone(alert.id);
  };

  useEffect(() => {
    if (isSuccess) {
      toasts.showSuccess({
        content: t('cloneAlertModal.success', 'Alert has been cloned.'),
        id: 'cloneAlertModal',
      });
      modal.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, t]);

  return (
    <ConfirmModal
      hasInternalServerError={isError}
      title={t('cloneAlertModal.title', 'Do you want to duplicate this alert?')}
      pending={isLoading}
      onConfirm={handleCloneConfirm}
      onCancel={modal.hide}
    />
  );
};

export default CloneAlertModal;
