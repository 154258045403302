import Input from '@bugbug/core/components/Input';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import type { FormikErrors } from 'formik';
import type { SignUpDto } from '~/modules/user/user.schemas';

import { useAppDispatch } from '~/modules/store';
import { useRegisterMutation } from '~/modules/user/user.api';
import { UserActions } from '~/modules/user/user.redux';
import { SignUpSchema } from '~/modules/user/user.schemas';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import { getExtractedErrorMessages, isInternalServerError } from '~/services/api/utils';
import toasts from '~/services/toasts';
import * as cookies from '~/utils/cookies';
import { OnboardingForm, FormField } from '~/views/Onboarding/Onboarding.styled';
import urls from '~/views/urls';

import { SubmitButton, Checkbox, ErrorMessage } from './SignUpForm.styled';

export interface SignUpFormErrors extends Partial<SignUpDto> {
  invalid?: string;
  emailIsNotVerified?: boolean;
}

interface SignUpFormProps {
  email?: string;
  registrationKey?: string;
}
export const SignUpForm = ({ email = '', registrationKey }: SignUpFormProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'auth.signUp' });
  const isInvitation = !!registrationKey;
  const [register] = useRegisterMutation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const onSubmit = async (values: SignUpDto) => {
    analytics.trackEvent(TRACK_EVENT_TYPE.START_SIGN_UP, { provider: 'regular' });
    const analyticsCookies = cookies.getAnalyticsCookiesData();
    try {
      const response = await register({
        ...values,
        isAllowedEmailMarketing: values.isAllowedEmailMarketing ?? false,
        key: registrationKey,
        analytics: analyticsCookies,
      }).unwrap();
      analytics.initUserSession(response.pk);

      if (!registrationKey) {
        history.push(urls.signUpSuccess, { redirected: true, email: values.email });
      } else {
        analytics.trackEvent(TRACK_EVENT_TYPE.INVITED_USER_SIGN_UP);
        dispatch(UserActions.loginSuccess({ token: response.token }));
        history.push(urls.welcomeInvited);
      }
    } catch (error) {
      if (isInternalServerError(error)) {
        toasts.user.showSignUpError();
      }
      const messages = getExtractedErrorMessages(error);
      setErrors(messages);
      setSubmitting(false);
    }
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isSubmitting,
    setErrors,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues: {
      email,
      password1: '',
      isAllowedEmailMarketing: false,
    },
    validationSchema: SignUpSchema,
    onSubmit,
  });
  const customErrors: FormikErrors<SignUpFormErrors> = errors;

  useEffect(() => {
    setFieldValue('email', email);
  }, [email, setFieldValue]);

  return (
    <OnboardingForm aria-label="form" onSubmit={handleSubmit} noValidate data-testid="SignUpForm">
      {customErrors.invalid && <ErrorMessage>{customErrors.invalid}</ErrorMessage>}
      {!isInvitation && (
        <FormField label={t('email', 'Work email')}>
          <Input
            type="email"
            name="email"
            readOnly={isInvitation}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            autoComplete="off"
            error={
              (touched.email && errors.email) ||
              (customErrors.emailIsNotVerified &&
                t(
                  'notRelatedEmail',
                  'Provided email address is not associated with the invitation.',
                ))
            }
          />
        </FormField>
      )}
      <FormField
        label={isInvitation ? t('createPassword', 'Create password') : t('password', 'Password')}
      >
        <Input
          type="password"
          name="password1"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password1}
          autoComplete="off"
          error={touched.password1 && errors.password1}
        />
      </FormField>
      <Checkbox
        name="isAllowedEmailMarketing"
        checked={values.isAllowedEmailMarketing}
        onChange={handleChange}
      >
        {t(
          'isAllowedEmailMarketing',
          'I want to receive information about BugBug product updates.',
        )}
      </Checkbox>
      <SubmitButton type="submit" variant="primary" pending={isSubmitting}>
        {isInvitation
          ? t('byInvitationSubmitButton', 'Continue')
          : t('submitButton', 'Create account')}
      </SubmitButton>
    </OnboardingForm>
  );
};
