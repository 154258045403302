import { useCallback, useMemo } from 'react';

import type { PendingActionName } from '~/modules/uiState/uiState.types';

import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import { selectPendingAction } from '~/modules/uiState/uiState.selectors';

export const usePendingAction = () => {
  const pendingAction = useAppSelector(selectPendingAction);
  const dispatch = useAppDispatch();

  const setPendingAction = useCallback(
    (actionType: PendingActionName) => {
      dispatch(UIStateActions.setPendingAction({ name: actionType }));
    },
    [dispatch],
  );

  const resetPendingAction = useCallback(() => {
    dispatch(UIStateActions.resetPendingAction());
  }, [dispatch]);

  return useMemo(
    () => ({
      pendingAction,
      setPendingAction,
      resetPendingAction,
    }),
    [pendingAction, resetPendingAction, setPendingAction],
  );
};
