import { DropdownItem } from '@bugbug/core/components/Dropdown';

import type { ReactNode } from 'react';

import * as S from './SortByDropdown.styled';

interface SortByDropdownItemProps {
  name: ReactNode;
  checked: boolean;
  onClick: (id: string) => void;
  id: string;
}

export const SortByDropdownItem = ({ name, id, onClick, checked }: SortByDropdownItemProps) => (
  <DropdownItem onClick={() => onClick(id)}>
    {checked ? <S.ItemCheckIcon /> : <S.IconPlaceholder />}
    {name}
  </DropdownItem>
);
