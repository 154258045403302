import { AddIcon as AddIconBase, COLOR } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { RowActiveBorder } from '../RowActiveBorder/RowActiveBorder';

export const Container = styled.div`
  position: relative;

  ${styleWhenTrue(
    prop('empty'),
    css`
      height: 100%;
    `,
  )}

  ${(props) =>
    props.isDraggingPlaybackCursor &&
    css`
      pointer-events: none;
    `}
`;

export const ScrollContainer = styled.div`
  overflow-x: hidden;
  padding-right: 32px;
  /* Hack to allow recording cursor overflow */
  padding-left: 48px;
  margin-left: -48px;
  height: 100%;

  ${(props) =>
    props.isDraggingPlaybackCursor &&
    css`
      cursor: grab;
    `}
`;

export const AddIconsWrapperContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  pointer-events: none;
`;

export const AddIcon = styled(AddIconBase)`
  font-size: 14px !important;
  fill: ${COLOR.DARK_GRAY} !important;
`;

export const AddIconWrapper = styled.div`
  position: absolute;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  right: 50%;
  transform: translateX(50%);
  top: -9px;
  padding: 0;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.WHITE};
  border: 1px solid ${COLOR.DARK_GRAY};
  z-index: 1;
  pointer-events: auto;

  ${styleWhenTrue(
    prop('bottom'),
    css`
      top: unset;
      bottom: -7px;
    `,
  )}
`;

export const ActiveBorder = styled(RowActiveBorder)`
  pointer-events: auto;

  ${styleWhenTrue(
    prop('bottom'),
    css`
      margin-top: 0;
      bottom: -6px;
      top: unset;
    `,
  )}
`;
