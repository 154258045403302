import Input from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import VariableName from '@bugbug/core/components/VariableName';
import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import type { EditAlertFormValues } from '../../EditAlertFormValues.types';

import { ExpandableCode } from '~/components/Code/ExpandableCode';
import FormField from '~/components/FormField';

import { REQUEST_METHODS_OPTIONS } from '../../EditAlertModal.constants';
import EditAlertModalActionTrigger from '../EditAlertModalActionTrigger';
import { Description } from '../EditAlertModalThenContent.styled';

const EditAlertModalThenActionEmail = () => {
  const { t } = useTranslation();
  const { getFieldProps, getFieldMeta } = useFormikContext<EditAlertFormValues>();

  const methodFieldProps = getFieldProps('method');

  return (
    <>
      <Description>
        <Trans i18nKey="editAlertModal.webhook.description">
          You can use built-in variables in any field, e.g. <VariableName>testRunId</VariableName>.{' '}
          <Link to={t('default.docs.variables')}>More about variables</Link>
        </Trans>
      </Description>
      <FormField label={t('editAlertModal.webhook.fields.method.label', 'Method')} labelId="method">
        <Select {...getFieldMeta('method')} {...methodFieldProps} aria-labelledby="method">
          {REQUEST_METHODS_OPTIONS.map((method) => (
            <SelectOption key={method.value} value={method.value}>
              {method.label}
            </SelectOption>
          ))}
        </Select>
      </FormField>
      <FormField label={t('editAlertModal.webhook.fields.url.label', 'Webhook URL')} labelId="url">
        <Input {...getFieldMeta('url')} {...getFieldProps('url')} aria-labelledby="url" />
      </FormField>
      {methodFieldProps.value === 'POST' && (
        <FormField
          label={t('editAlertModal.webhook.fields.body.label', 'Body')}
          description={t('editAlertModal.webhook.fields.body.description', 'Optional')}
          labelId="body"
        >
          <ExpandableCode
            {...getFieldMeta('body')}
            {...getFieldProps('body')}
            language="json"
            aria-labelledby="body"
          />
        </FormField>
      )}
      <EditAlertModalActionTrigger />
    </>
  );
};
export default EditAlertModalThenActionEmail;
