import mixpanel from 'mixpanel-browser';
import { identity } from 'ramda';

import type { TrackEvent } from '~/services/analytics/types';

import config from '~/modules/config';
import envs from '~/modules/envs';

import { MIXPANEL_ALLOWED_EVENTS, TRACK_EVENT_ARG_TYPE } from './constants';

const isEnabled = !!config.MIXPANEL_TOKEN;

const setup = () => {
  mixpanel.init(config.MIXPANEL_TOKEN, {
    api_host: config.MIXPANEL_URL,
    ignore_dnt: true,
    ...(config.ENV === envs.localhost.ENV ? { debug: true } : {}),
  });
  // To avoid copy location from previous bugbug.io to app.bugbug.io
  // Related issue: DEV-3410
  mixpanel.register({ location: undefined });
};

const setGlobalEventData = ({ organizationId, projectId }) => {
  mixpanel.register({ organization_id: organizationId, project_id: projectId });
};

const initUserSession = (userId) => {
  mixpanel.identify(userId);
};

const getDistinctId = (): string => mixpanel.get_property('distinct_id');

const setUserProfile = (profile, forceUpdate = false) => {
  mixpanel.people[forceUpdate ? 'set' : 'set_once']({
    $name: `${profile.user.firstName} ${profile.user.lastName}`,
    $email: profile.user.email,
    [TRACK_EVENT_ARG_TYPE.SIGN_UP_METHOD]: profile.user.origin,
    [TRACK_EVENT_ARG_TYPE.SIGN_UP_DATE]: profile.user.dateJoined,
    [TRACK_EVENT_ARG_TYPE.COMPANY]: profile.organization.companyName,
    [TRACK_EVENT_ARG_TYPE.USER_SOURCE]: profile.user.source,
    [TRACK_EVENT_ARG_TYPE.USER_ROLE]: profile.user.role,
  });
};

const trackEvent = (eventName: TrackEvent, data) => {
  if (MIXPANEL_ALLOWED_EVENTS.includes(eventName) && config.MIXPANEL_TOKEN) {
    mixpanel.track(
      eventName,
      { ...data, created: new Date().toISOString() },
      { send_immediately: true },
    );
  }
};

const clearSession = () => {
  mixpanel.reset();
};

export default {
  isEnabled,
  setup,
  setUserProfile,
  initUserSession,
  setGlobalEventData,
  trackEvent,
  trackModalView: identity,
  clearSession,
  getDistinctId,
};
