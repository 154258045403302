import { isRunningStatus } from '@bugbug/core/types/base';
import { groupBy, propEq, prop } from 'ramda';
import { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUnmount, useUpdateEffect } from 'react-use';

import {
  WAITING_CONDITION_TYPE,
  CUSTOM_WAITING_CONDITION,
  CONDITION_PARAMS,
} from '~/modules/constans';
import { selectStepRun } from '~/modules/testRun/testRun.selectors';

import { CONDITION_MESSAGE_DELAY } from './StepStatusCell.constants';

const splitConditions = (condition) => {
  if (
    [WAITING_CONDITION_TYPE.NETWORK_IDLE, WAITING_CONDITION_TYPE.DOCUMENT_COMPLETE].includes(
      condition.type,
    )
  ) {
    return 'initial';
  }
  if ([WAITING_CONDITION_TYPE.PAGE_NAVIGATION_AFTER_EXECUTION].includes(condition.type)) {
    return 'final';
  }
  return 'regular';
};

const isFailure = propEq('isSuccess', false);

export const useUnresolvedCondition = (stepRunId, timeout = CONDITION_MESSAGE_DELAY) => {
  const stepRun = useSelector(selectStepRun(stepRunId));
  const unresolvedConditionTimeout = useRef();
  const [condition, setCondition] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const { waitingConditions, elementExists, status } = stepRun || {};
  const isRunning = isRunningStatus(status);

  const hideCondition = useCallback(() => {
    clearTimeout(unresolvedConditionTimeout.current);
    unresolvedConditionTimeout.current = null;
    setCondition({});
    setIsVisible(false);
  }, []);

  useEffect(() => {
    if (isRunning) {
      const {
        initial = [],
        regular = [],
        final = [],
      } = groupBy(splitConditions, waitingConditions || []);

      let activeCondition = initial.find(isFailure);
      if (!activeCondition && !elementExists && waitingConditions.length) {
        activeCondition = CUSTOM_WAITING_CONDITION.ELEMENT_EXISTS;
      } else if (elementExists) {
        regular.push(...final);
        activeCondition = regular.find(isFailure);
      }

      if (!activeCondition) {
        hideCondition();
      } else {
        const pendingCondition = { ...activeCondition };
        pendingCondition.label =
          pendingCondition.label || prop('label', CONDITION_PARAMS[pendingCondition.type]);

        setCondition(pendingCondition);

        if (!unresolvedConditionTimeout.current) {
          unresolvedConditionTimeout.current = setTimeout(() => {
            setIsVisible(true);
          }, timeout);
        }
      }
    }
  }, [waitingConditions, hideCondition, timeout, elementExists, isRunning, stepRunId]);

  useUpdateEffect(() => {
    if (!isRunning) {
      hideCondition();
    }
  }, [isRunning, hideCondition]);

  useUnmount(() => {
    clearTimeout(unresolvedConditionTimeout.current);
  });

  return {
    value: condition,
    isVisible: !!(isVisible && condition.label),
  };
};
