import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import type { TextAreaStyledProps } from './TextArea.types';

export const Container = styled.div`
  position: relative;
`;

export const TextAreaWrapper = styled.div<Partial<TextAreaStyledProps>>`
  border: 2px solid ${COLOR.GRAY_9};
  background-color: ${COLOR.WHITE};
  border-radius: 5px;
  transition: all 150ms ease-in-out;
  display: flex;
  position: relative;
  width: auto;

  &[disabled] {
    pointer-events: none;
    resize: none;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.text.subtle};
  }

  &:active,
  &:focus-within {
    border-color: ${COLOR.PRIMARY_LIGHT};
  }

  &[readonly] {
    border-color: ${COLOR.GRAY_9};
    background-color: ${COLOR.GRAY_28};
    opacity: 0.8;
    resize: none;
  }

  ${styleWhenTrue(
    prop('fullWidth'),
    css`
      width: 100%;
    `,
  )}

  ${styleWhenTrue(
    prop('invalid'),
    css`
      border-color: ${COLOR.ERROR} !important;
    `,
  )}
`;

export const TextAreaBase = styled.textarea<{ rightAdornment: boolean }>`
  outline: none;
  min-height: 34px;
  height: 1px;
  flex: 1;
  border: none;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  background: transparent;
  padding: 8px 10px;
  min-width: 0;
  border-radius: 3px;

  ${styleWhenTrue(
    prop('rightAdornment'),
    css`
      padding-right: 35px;
    `,
  )}

  ::placeholder {
    color: ${COLOR.DARK_GRAY};
    font-weight: ${FONT_WEIGHT.MEDIUM};
    opacity: 0.33;
  }
`;

export const ErrorMessage = styled.div`
  color: ${COLOR.ERROR};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  font-size: 13px;
`;

export const RightAdornment = styled.span`
  align-items: start;
  color: #c5c5c5;
  display: flex;
  flex: none;
  font-weight: ${FONT_WEIGHT.BOLD};
  justify-content: center;
  padding-left: 0;
  padding: 0 11px 3px;
  position: absolute;
  right: 0px;
  top: 9px;
`;
