import { COLOR } from '@bugbug/core/theme/colors';
import { cond, equals, T, always } from 'ramda';
import styled, { css } from 'styled-components';

import type { LayerSide } from 'react-laag';

interface PreviewImageProps {
  initialHeight?: number;
  initialWidth?: number;
  side: LayerSide;
}

const getPreviewOffset = (props: PreviewImageProps) =>
  cond([
    [
      equals('top'),
      always(css`
        transform: translateY(${props.initialHeight}px);
      `),
    ],
    [
      T,
      always(css`
        transform: translateY(-${props.initialHeight}px);
      `),
    ],
  ])(props.side);

const containerStyles = css`
  height: 30px;
  width: auto;
  max-width: 160px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.neutrals[80]};
`;

export const Container = styled.div`
  ${containerStyles}
`;

export const TextContainer = styled.div`
  ${containerStyles}
`;

export const Image = styled.img`
  max-height: 100%;
  cursor: zoom-in;
  flex: none;
`;

export const PreviewImage = styled.img<PreviewImageProps>`
  z-index: 2;
  box-shadow: 0 3px 12px 0 ${COLOR.DARK_GRAY_5};
  cursor: zoom-out;
  ${getPreviewOffset};
`;
