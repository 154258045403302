import CopyButton from '@bugbug/core/components/CopyButton';
import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { SetLocalVariableStep } from '@bugbug/core/types/steps';
import type { StepRun } from '@bugbug/core/types/tests';
import { CodeField } from '~/components/CodeField/CodeField';
import FormField from '~/components/FormField';
import { FormikInputWithVariables } from '~/components/InputWithVariables/FormikInputWithVariables';
import { SET_LOCAL_VARIABLE_SOURCE_LABEL } from '~/constants/step';
import { VARIABLE_TYPE } from '~/constants/variables';

import { ElementSelectorBuilder } from '../ElementSelectorBuilder/ElementSelectorBuilder';
import { ComputedField, ComputedValue } from '../Interaction/Interaction.styled';
import { getInitialValues } from '../StepDetails/StepDetails.helpers';

interface SetLocalVariableFieldsProps {
  stepRun: StepRun;
  readOnly?: boolean;
  context?: 'component' | 'test' | 'testRun';
}

export const SetLocalVariableFields = ({
  context = 'test',
  stepRun,
  readOnly = false,
}: SetLocalVariableFieldsProps) => {
  const { t } = useTranslation();
  const formik = useFormikContext<SetLocalVariableStep>();

  const getFieldProps = useCallback(
    (fieldName) => {
      const { value, name, ...restProps } = formik.getFieldProps(fieldName);
      const { touched, error } = formik.getFieldMeta(fieldName);
      return { ...restProps, name, value, error: touched && error };
    },
    [formik],
  );

  const handleUpdateVariableSource = (event) => {
    const { value: localVariableSource } = event.target;

    formik.setValues(
      getInitialValues({
        ...formik.initialValues,
        localVariableSource,
      }),
    );
  };

  const renderVariableSource = () => {
    const scrollToProps = getFieldProps('localVariableSource');

    return (
      <FormField label={t('stepDetails.localVariable.source', 'Value')}>
        <Select
          fullWidth
          {...scrollToProps}
          onChange={handleUpdateVariableSource}
          disabled={readOnly}
        >
          {Object.values(VARIABLE_TYPE).map((type) => (
            <SelectOption key={type} value={type}>
              {SET_LOCAL_VARIABLE_SOURCE_LABEL[type]}
            </SelectOption>
          ))}
        </Select>
      </FormField>
    );
  };

  return (
    <>
      <FormField label={t('stepDetails.localVariable.name', 'Variable name')}>
        <Input {...getFieldProps('localVariableName')} readOnly={readOnly} />
        <CopyButton value={formik.values.localVariableName} />
      </FormField>
      {renderVariableSource()}
      {formik.values.localVariableSource === 'element' && (
        <ElementSelectorBuilder context={context} disabled={readOnly} />
      )}
      {formik.values.localVariableSource === 'value' && (
        <FormField label={t('stepDetails.localVariable.value', 'Set value')}>
          <FormikInputWithVariables name="value" readOnly={readOnly} />
        </FormField>
      )}
      {formik.values.localVariableSource === 'evaluate' && (
        <CodeField
          {...getFieldProps('code')}
          // @ts-expect-error TS doesn't narrow the type of the error
          error={formik.errors.code?.toString()}
          functionArguments={['variables']}
          readOnly={readOnly}
        />
      )}
      {stepRun.computedValue && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('stepDetails.localVariable.thisComputedValue', 'This test run value')
              : t('stepDetails.localVariable.lastComputedValue', 'Last test run value')
          }
        >
          <ComputedValue>{stepRun.computedValue}</ComputedValue>
          <CopyButton value={stepRun.computedValue} />
        </ComputedField>
      )}
    </>
  );
};
