import i18n from '~/translations';

export const FORM_FIELD = {
  NAME: 'name',
  KEY: 'key',
  TYPE: 'type',
  VALUE: 'value',
  PROFILE: 'profile',
  HAS_SECRET_CONTENT: 'hasSecretValue',
  DESCRIPTION: 'description',
};

export const DEFAULT_CUSTOM_JS_VALUE = `/*
    this function is executed every time
    when the variable is used
    learn more at: https://docs.bugbug.io/editing-tests/variables
  */

  return 'example value';
`;

export const MULTI_VALUE_FORM_COLUMNS = [
  {
    label: i18n.t('editVariableModal.value.column.name', 'Profile'),
    key: FORM_FIELD.PROFILE,
    isLabel: true,
  },
  {
    label: i18n.t('editVariableModal.value.column.value', 'Value'),
    key: FORM_FIELD.VALUE,
  },
];

export const MULTI_VALUE_FORM_COLUMNS_FOR_DEFAULT = MULTI_VALUE_FORM_COLUMNS.slice(0, -1);
