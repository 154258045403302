import DropdownBase, { DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import styled from 'styled-components';

export const Dropdown = styled(DropdownBase).attrs<{ bordered?: boolean }>(({ bordered }) => ({
  variant: bordered ? DROPDOWN_VARIANT.PRIMARY : DROPDOWN_VARIANT.ICON,
}))<{ bordered?: boolean }>`
  ${({ bordered }) => bordered && `margin-left: 9px`};
`;

export const Content = styled.div`
  width: 100%;
`;
