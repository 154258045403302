import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgArrowDownIcon = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 33, height: 32, viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { x: 17.5078, y: 28.5723, width: 2.28571, height: 2.28571, transform: "rotate(90 17.5078 28.5723)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 19.793, y: 26.2852, width: 2.28571, height: 2.28571, transform: "rotate(90 19.793 26.2852)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.0781, y: 24, width: 2.28571, height: 2.28571, transform: "rotate(90 22.0781 24)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 24.3633, y: 21.7148, width: 2.28571, height: 2.28571, transform: "rotate(90 24.3633 21.7148)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 26.6484, y: 19.4277, width: 2.28571, height: 2.28571, transform: "rotate(90 26.6484 19.4277)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 15.2188, y: 26.2852, width: 2.28571, height: 2.28571, transform: "rotate(90 15.2188 26.2852)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(4.37114e-08 1 1 -4.37114e-08 17.5078 28.5723)", fill: "#CEC2C1" }),
  /* @__PURE__ */ jsx("rect", { x: 12.9336, y: 24, width: 2.28571, height: 2.28571, transform: "rotate(90 12.9336 24)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(4.37114e-08 1 1 -4.37114e-08 19.793 26.2852)", fill: "#CEC2C1" }),
  /* @__PURE__ */ jsx("rect", { x: 10.6484, y: 21.7148, width: 2.28571, height: 2.28571, transform: "rotate(90 10.6484 21.7148)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(4.37114e-08 1 1 -4.37114e-08 22.0781 24)", fill: "#CEC2C1" }),
  /* @__PURE__ */ jsx("rect", { x: 8.36328, y: 19.4277, width: 2.28571, height: 2.28571, transform: "rotate(90 8.36328 19.4277)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(4.37114e-08 1 1 -4.37114e-08 24.3633 21.7148)", fill: "#CEC2C1" }),
  /* @__PURE__ */ jsx("rect", { x: 6.07812, y: 17.1426, width: 2.28571, height: 2.28571, transform: "rotate(90 6.07812 17.1426)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(4.37114e-08 1 1 -4.37114e-08 26.6484 19.4277)", fill: "#CEC2C1" }),
  /* @__PURE__ */ jsx("rect", { x: 28.9336, y: 17.1426, width: 2.28571, height: 2.28571, transform: "rotate(90 28.9336 17.1426)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 28.9336, y: 14.8574, width: 2.28571, height: 2.28571, transform: "rotate(90 28.9336 14.8574)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 26.6484, y: 14.8574, width: 2.28571, height: 2.28571, transform: "rotate(90 26.6484 14.8574)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 24.3633, y: 14.8574, width: 2.28571, height: 2.28571, transform: "rotate(90 24.3633 14.8574)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.0781, y: 12.5723, width: 2.28571, height: 2.28571, transform: "rotate(90 22.0781 12.5723)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.0781, y: 14.8574, width: 2.28571, height: 2.28571, transform: "rotate(90 22.0781 14.8574)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 12.9336, y: 14.8574, width: 2.28571, height: 2.28571, transform: "rotate(90 12.9336 14.8574)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 22.0781, y: 5.71484, width: 2.28571, height: 2.28571, transform: "rotate(90 22.0781 5.71484)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 12.9336, y: 12.5723, width: 2.28571, height: 2.28571, transform: "rotate(90 12.9336 12.5723)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 12.9336, y: 5.71484, width: 2.28571, height: 2.28571, transform: "rotate(90 12.9336 5.71484)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 22.0781, y: 8, width: 2.28571, height: 2.28571, transform: "rotate(90 22.0781 8)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 12.9336, y: 8, width: 2.28571, height: 2.28571, transform: "rotate(90 12.9336 8)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 15.2188, y: 1.14258, width: 2.28571, height: 2.28571, transform: "rotate(90 15.2188 1.14258)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 17.5078, y: 1.14258, width: 2.28571, height: 2.28571, transform: "rotate(90 17.5078 1.14258)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 19.793, y: 1.14258, width: 2.28571, height: 2.28571, transform: "rotate(90 19.793 1.14258)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 22.0781, y: 10.2852, width: 2.28571, height: 2.28571, transform: "rotate(90 22.0781 10.2852)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.0781, y: 3.42773, width: 2.28571, height: 2.28571, transform: "rotate(90 22.0781 3.42773)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 12.9336, y: 10.2852, width: 2.28571, height: 2.28571, transform: "rotate(90 12.9336 10.2852)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 12.9336, y: 3.42773, width: 2.28571, height: 2.28571, transform: "rotate(90 12.9336 3.42773)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 6.07812, y: 14.8574, width: 2.28571, height: 2.28571, transform: "rotate(90 6.07812 14.8574)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 8.36328, y: 14.8574, width: 2.28571, height: 2.28571, transform: "rotate(90 8.36328 14.8574)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 10.6484, y: 14.8574, width: 2.28571, height: 2.28571, transform: "rotate(90 10.6484 14.8574)", fill: "#9A32F2" })
] });
const ForwardRef = forwardRef(SvgArrowDownIcon);
export default ForwardRef;
