import styled, { keyframes } from 'styled-components';

import Page, { Content, Header, ContentWrapper } from '~/components/Page';
import RouteNotification from '~/components/RouteNotification';

import { EDIT_AND_REWIND_HIGHLIGHT_DURATION_MS } from './TestContent.constants';

export const Container = styled(Page)`
  flex: 1;
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.common.white};
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  height: 100%;

  ${Header} {
    margin-bottom: 0;
  }

  ${Content} {
    padding-right: 0;
    padding-bottom: 0;
  }

  ${ContentWrapper} {
    min-width: 735px;
    overflow: hidden;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: ${({ theme }) => theme.colors.common.white};
  z-index: 1;

  > * {
    position: relative;
    bottom: calc(50vh - 32px);
  }
`;

export const Notification = styled(RouteNotification)`
  top: 135px;
  left: 34px;
  right: 0;
`;

export const highlightAnimation = keyframes`
  0% {
      box-shadow: inset 0 0 0px 0px rgba(253, 206, 28, 1);
  }
  10% {
      box-shadow: inset 0 0 0px 4px rgba(253, 206, 28, 1);
  }
  20% {
      box-shadow: inset 0 0 0px 4px rgba(253, 206, 28, 0.3);
  }
  30% {
      box-shadow: inset 0 0 0px 4px rgba(253, 206, 28, 1);
  }
  50% {
      box-shadow: inset 0 0 0px 0px rgba(253, 206, 28, 1);
  }
`;

export const EditAndRewindHighlight = styled.div`
  position: absolute;
  inset: 0;
  z-index: 800;
  box-shadow: inset 0 0 0 0 rgba(253, 206, 28, 0);
  pointer-events: none;
  border-radius: 20px;
  animation: ${highlightAnimation} ${EDIT_AND_REWIND_HIGHLIGHT_DURATION_MS}ms ease-in-out;
`;

export const headerAnimation = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const EditAndRewindHighlightHeader = styled.div`
  position: absolute;
  background-color: rgba(253, 206, 28, 1);
  color: black;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: 6px 14px;
  width: fit-content;
  z-index: 1000;
  border-radius: 0 0 8px 8px;
  opacity: 0;
  animation: ${headerAnimation} ${EDIT_AND_REWIND_HIGHLIGHT_DURATION_MS}ms ease-in-out;
`;
