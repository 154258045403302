import { CommonActions } from '@bugbug/core/actions/actions';
import { last } from '@bugbug/core/utils/toolbox';
import { DndContext, DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { useCallback, useEffect, useMemo } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import type { ReactNode } from 'react';

import type { Group, GroupWithStepsIds } from '@bugbug/core/types/groups';
import type { Step } from '@bugbug/core/types/steps';
import type { Test, TestRun, TestWithGroupsIds } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';
import useTestRunner from '~/hooks/useTestRunner';
import { dispatchInExtension } from '~/modules/extension/extension.dispatch';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { useIsNewPlaybackSupported } from '~/modules/test/test.hooks';
import {
  selectGroup,
  selectIsTestRunPaused,
  selectSingleTest,
  selectSortedSteps,
  selectTestGroups,
  selectTestIsRecording,
} from '~/modules/test/test.selectors';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import {
  selectDraggedStepId,
  selectIsDraggingPlaybackCursor,
  selectPlaybackSelectedPositions,
  selectPlaybackState,
} from '~/modules/uiState/uiState.selectors';

import * as S from './PlaybackCursor.styled';

import DragLinesIcon from '~/images/drag-lines.svg?react';

interface PlaybackCursorDndContextProps {
  children: ReactNode;
}

export const usePlaybackCursorDnd = () => {
  const dispatch = useAppDispatch();
  const test = useAppSelector(selectSingleTest) as Maybe<TestWithGroupsIds>;
  const groups = useAppSelector(selectTestGroups) as Record<Group['id'], GroupWithStepsIds>;
  const isPaused = useAppSelector(selectIsTestRunPaused);
  const sortedSteps = useAppSelector(selectSortedSteps);

  useEffect(
    () => () => {
      dispatch(UIStateActions.setPlaybackDrag({ isDragging: false }));
    },
    [dispatch],
  );
  const handleDragStart = useCallback(() => {
    dispatch(UIStateActions.setPlaybackDrag({ isDragging: true }));
  }, [dispatch]);

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      dispatch(UIStateActions.setPlaybackDrag({ isDragging: false }));

      if (!event.over?.data?.current || !test) return;
      const { afterStepId, groupId } = event.over.data.current as {
        afterStepId: Maybe<Step['id']>;
        groupId: Group['id'];
      };

      const groupIndex = test.groups.indexOf(groupId);
      const prevGroupId = test.groups[groupIndex - 1];
      const lastStepIdFromPreviousGroup = last(groups[prevGroupId]?.steps) ?? null;
      const afterStepIdOrLastStepIdFromPreviousGroup =
        afterStepId ?? lastStepIdFromPreviousGroup ?? null;

      const nextStepIndex =
        sortedSteps.findIndex((s) => s.id === afterStepIdOrLastStepIdFromPreviousGroup) + 1;
      const nextActiveStep = sortedSteps.slice(nextStepIndex).find((step) => step.isActive);

      const action = CommonActions.setPlaybackCursorPosition({
        afterStep: {
          id: afterStepId,
          groupId,
        },
        runningStep: null,
        nextStep: nextActiveStep
          ? { id: nextActiveStep.id, groupId: nextActiveStep.groupId }
          : null,
      });

      dispatch(action);
      await dispatchInExtension(action);

      if (!isPaused) {
        await dispatchInExtension(
          CommonActions.setRecordingCursorPosition({
            afterStepId,
            groupId,
          }),
        );
      }
    },
    [dispatch, test, groups, sortedSteps, isPaused],
  );

  const handleDragCancel = useCallback(() => {
    dispatch(UIStateActions.setPlaybackDrag({ isDragging: false }));
  }, [dispatch]);

  const handlers = useMemo(
    () => ({
      handleDragStart,
      handleDragEnd,
      handleDragCancel,
    }),
    [handleDragStart, handleDragEnd, handleDragCancel],
  );

  return handlers;
};

export const PlaybackCursorDndContext = ({ children }: PlaybackCursorDndContextProps) => {
  const isDraggingPlaybackCursor = useAppSelector(selectIsDraggingPlaybackCursor);

  const { handleDragStart, handleDragEnd, handleDragCancel } = usePlaybackCursorDnd();

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      modifiers={[restrictToVerticalAxis]}
    >
      <DragOverlay modifiers={[restrictToVerticalAxis]}>
        {isDraggingPlaybackCursor ? <PlaybackCursor /> : null}
      </DragOverlay>
      {children}
    </DndContext>
  );
};

export const PlaybackCursorDraggable = () => {
  const isPaused = useAppSelector(selectIsTestRunPaused) as boolean;
  const isRecording = useAppSelector(selectTestIsRecording);

  if (!isRecording && !isPaused) return null;

  return (
    <Draggable id="playback-position-draggable">
      <PlaybackCursor />
    </Draggable>
  );
};

export const PlaybackCursorSlot = ({
  groupId,
  afterStepId,
  disabled,
}: {
  groupId: Group['id'];
  afterStepId: Maybe<Step['id']>;
  disabled?: boolean;
}) => {
  const playback = useAppSelector(selectPlaybackState);
  const isNewPlaybackSupported = useIsNewPlaybackSupported();

  const isCurrentPosition =
    playback.cursorPosition?.afterStep?.groupId === groupId &&
    playback.cursorPosition?.afterStep?.id === afterStepId;

  const selectedPositions = useAppSelector(selectPlaybackSelectedPositions);
  const isPausePosition =
    selectedPositions.pause?.atGroupId === groupId &&
    selectedPositions.pause?.afterStepId === afterStepId;
  const isRecorderStartPosition =
    selectedPositions.recorderStart?.atGroupId === groupId &&
    selectedPositions.recorderStart?.afterStepId === afterStepId;

  const isPaused = useAppSelector(selectIsTestRunPaused) as boolean;

  const sortedSteps: Step[] = useAppSelector(selectSortedSteps);
  const draggedStepId = useAppSelector(selectDraggedStepId);
  const cursorStepIndex = useMemo(
    () => sortedSteps.findIndex((step) => step.id === playback.cursorPosition?.afterStep?.id),
    [playback.cursorPosition?.afterStep?.id, sortedSteps],
  );
  const draggedStepIndex = useMemo(
    () => sortedSteps.findIndex((step) => step.id === draggedStepId),
    [draggedStepId, sortedSteps],
  );
  const draggedStep = sortedSteps[draggedStepIndex];
  const isDraggingStepWithinGroup = draggedStep?.groupId === groupId;

  const { setNodeRef, isOver } = useDroppable({
    id: groupId + (afterStepId ?? ''),
    data: {
      afterStepId,
      groupId,
    },
    disabled,
  });

  if (!isNewPlaybackSupported) return null;

  return (
    <>
      <S.DropZone
        ref={setNodeRef}
        visible={isOver || isCurrentPosition}
        addStepDragOffset={
          draggedStepId !== null && isDraggingStepWithinGroup && draggedStepIndex <= cursorStepIndex
        }
      >
        {isOver && (
          <>
            <S.CursorTriangleIconPlaceholder
              $appearsAfterStep={afterStepId !== null}
              $paused={isPaused}
            />
            <S.LinePlaceholder $paused={isPaused} />
          </>
        )}
        {isCurrentPosition && !playback.isDraggingCursor && <PlaybackCursorDraggable />}
      </S.DropZone>
      <S.PauseTargetLine $visible={isPausePosition} data-testid="PauseTargetLine">
        <S.CursorPauseTriangleOutlineIcon />
      </S.PauseTargetLine>
      <S.RecordingTargetLine $visible={isRecorderStartPosition} data-testid="RecordingTargetLine">
        <S.CursorRecordingTriangleOutlineIcon />
      </S.RecordingTargetLine>
    </>
  );
};

export const RunningGroupPlaybackCursor = ({
  testId,
  testRunId,
  groupId,
}: {
  testId: Test['id'];
  testRunId: TestRun['id'];
  groupId: Group['id'];
}) => {
  const { isRunning, isPaused } = useTestRunner(
    { id: testId },
    {
      testRunId,
    },
  );
  const playback = useAppSelector(selectPlaybackState);
  const group = useAppSelector(selectGroup(true, groupId)) as GroupWithStepsIds;
  const test = useAppSelector(selectSingleTest) as TestWithGroupsIds;

  if (!playback.cursorPosition || !group?.steps || !test?.groups) return null;
  const { afterStep, runningStep, nextStep } = playback.cursorPosition;

  const activeStep = runningStep ?? afterStep;

  const stepIndexInGroup = group.steps.indexOf(activeStep?.id ?? '');
  const isStepWithinGroup = stepIndexInGroup >= 0;
  const isPreviousStepWithinGroup = group.steps.indexOf(afterStep?.id ?? '') >= 0;

  const isStepRunning = isStepWithinGroup && !!runningStep;

  const isCursorAtTheEndOfGroup =
    afterStep && nextStep && !isStepWithinGroup && isPreviousStepWithinGroup;

  const isCursorAtPausedStep =
    playback.selectedPositions.pause &&
    playback.selectedPositions.pause.afterStepId === afterStep?.id;

  const shouldApplyRunningOffset = isStepRunning && !isPaused && !isCursorAtPausedStep;

  const playbackIndex =
    (isCursorAtTheEndOfGroup ? group.steps.length : stepIndexInGroup + 1) +
    (shouldApplyRunningOffset ? -0.5 : 0);

  const isBeforeFirstStep =
    test.groups.indexOf(groupId) === 0 && nextStep?.groupId === groupId && afterStep === null;

  const isActiveGroup =
    runningStep?.groupId === groupId ||
    (afterStep?.groupId === groupId && nextStep?.groupId === groupId) ||
    isBeforeFirstStep;

  const isVisible = isRunning && isActiveGroup;

  return (
    <S.RunningCursorTriangleIcon
      $index={playbackIndex}
      $visible={isVisible}
      data-testid="RunningCursorTriangleIcon"
    />
  );
};

const PlaybackCursor = () => {
  const isPaused = useAppSelector(selectIsTestRunPaused) as boolean;

  return (
    <S.Line $paused={isPaused} data-testid="PlaybackCursor">
      <S.DragHandle>
        <DragLinesIcon />
        <S.CursorTriangleIcon $paused={isPaused} />
      </S.DragHandle>
    </S.Line>
  );
};

const Draggable = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        width: '100%',
        transform: CSS.Translate.toString(transform),
      }}
      {...listeners}
      {...attributes}
    >
      {children}
    </div>
  );
};
