import styled from 'styled-components';

import Card from '~/components/Card';

export const AuthCard = styled(Card)`
  max-width: 380px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TermsAndConditions = styled.p`
  margin: 0;
  padding: 0;
  font-size: 11px;
  text-align: center;
  line-height: 16px;
  margin-top: 24px;
`;

export const RedirectText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
`;
