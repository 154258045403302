import { useEffect } from 'react';
import { usePrevious } from 'react-use';

import type { Maybe } from '@bugbug/core/types/utils';
import SidebarView from '~/components/SidebarView';
import useModal from '~/hooks/useModal';
import { selectCurrentStepId } from '~/modules/steps/steps.selectors';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import {
  selectFirstTestRunPassed,
  selectIsInvited,
  selectSecondTestRunPassed,
} from '~/modules/user/user.selectors';

import { TestContent } from './components/TestContent/TestContent';
import Sidebar from './components/TestSidebar';

const TestDetails = () => {
  const currentStepId = useAppSelector(selectCurrentStepId);
  const modal = useModal();

  const firstTestRunPassed = useAppSelector(selectFirstTestRunPassed) as Maybe<boolean>;
  const prevTestRunPassed = usePrevious(firstTestRunPassed);

  const secondTestRunPassed = useAppSelector(selectSecondTestRunPassed) as Maybe<boolean>;
  const prevSecondTestRunPassed = usePrevious(secondTestRunPassed);
  const isInvitedUser = useAppSelector(selectIsInvited);

  useEffect(() => {
    if (prevTestRunPassed !== null || !firstTestRunPassed) return;

    modal.show('first_successful_run');
  }, [firstTestRunPassed, modal, prevTestRunPassed]);

  useEffect(() => {
    if (prevSecondTestRunPassed !== null || !secondTestRunPassed || isInvitedUser) {
      return;
    }

    modal.show(
      'invite_user',
      { onboardingInProgress: true },
      {
        stacked: true,
        closeButtonHidden: true,
      },
    );
  }, [secondTestRunPassed, modal, prevSecondTestRunPassed, isInvitedUser]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(UIStateActions.resetRecentlyAddedStepsIds());
    dispatch(UIStateActions.setPlaybackDrag({ isDragging: false }));

    return () => {
      dispatch(UIStateActions.resetRecentlyAddedStepsIds());
    };
  }, [dispatch]);

  return (
    <SidebarView
      prefix="testDetails"
      sidebarAlwaysVisible={!!currentStepId}
      Content={TestContent}
      Sidebar={Sidebar}
    />
  );
};

export default TestDetails;
