import i18n from '~/translations';

export const BENEFITS_LIST = [
  {
    label: i18n.t('signUp.benefits.list.unlimitedLocalTestRuns', 'Unlimited local test runs'),
  },
  {
    label: i18n.t('signUp.benefits.list.unlimitedUserSeats', 'Unlimited user seats'),
  },
  {
    label: i18n.t('signUp.benefits.list.simpleButPowerful', 'Simple but powerful'),
  },
];
