import { useCallback } from 'react';

import type { Step } from '@bugbug/core/types/steps';
import { ImageWithPreview } from '~/components/ImageWithPreview/ImageWithPreview';
import { TextCell } from '~/components/Table';
import { selectCurrentStep } from '~/modules/components/components.selectors';
import { useAppSelector } from '~/modules/store';
import { getScreenshotUrl } from '~/utils/misc';

import * as S from './ImageCell.styled';

interface ImageCellProps {
  value: Step;
}

const ImageCell = ({ value }: ImageCellProps) => {
  const currentStep = useAppSelector(selectCurrentStep);
  const isRelatedStepActive = currentStep?.id === value.id;
  const imageUrl = getScreenshotUrl(value.screenshot);

  const handleClick = useCallback(
    (event) => {
      if (isRelatedStepActive) {
        event.stopPropagation();
      }
    },
    [isRelatedStepActive],
  );

  return imageUrl ? (
    <ImageWithPreview src={imageUrl} alt={value.type} onClick={handleClick} />
  ) : (
    <S.Container>
      <TextCell />
    </S.Container>
  );
};

export default ImageCell;
