import styled from 'styled-components';

export const OptionThenFind = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: center;

  svg {
    width: 14px;
    margin-right: 4px;
    stroke: ${({ theme }) => theme.colors.primary};
    top: 1px;
  }
`;
