import type { TutorialBackdrop } from './TutorialTooltip';

export const calculateBackdrop = (elementRect: DOMRect) => {
  const position = {
    x: elementRect.left + elementRect.width / 2,
    y: elementRect.top + elementRect.height / 2,
  };
  const longerSide = Math.max(elementRect.width, elementRect.height);
  const circlePadding = longerSide * 0.1;
  const radius = Math.sqrt(elementRect.width ** 2 + elementRect.height ** 2) / 2 + circlePadding;

  const params: TutorialBackdrop = {
    clickTraps: [
      { height: elementRect.top }, // Top
      { left: elementRect.right }, // Right
      { top: elementRect.bottom }, // Bottom
      { width: elementRect.left }, // Left
    ],
    circle: {
      position,
      radius,
      anchorRect: {
        x: position.x - radius,
        y: elementRect.top,
        width: elementRect.width,
        height: elementRect.height,
      },
    },
  };
  return params;
};
