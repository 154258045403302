import { StatusBadge } from '@bugbug/core/components/StatusBadge/StatusBadge';
import { isEndStatus } from '@bugbug/core/types/base';
import { useSelector } from 'react-redux';

import { selectUserData } from '~/modules/user/user.selectors';

const StatusCell = ({ value }) => {
  const {
    firstRunStatus,
    status,
    started,
    extended,
    errorCode,
    isAutoRetried,
    newStepAdded,
    user: authorId,
  } = value ?? {};

  const user = useSelector(selectUserData);

  const shouldRenderLatestStatus =
    (authorId && user?.id === authorId) || !isEndStatus(firstRunStatus);
  const visibleStatus = shouldRenderLatestStatus ? status : firstRunStatus;

  return (
    <StatusBadge
      status={visibleStatus ?? status}
      date={started}
      extended={extended}
      autoRetried={isAutoRetried}
      hiddenStatusLabel={!isAutoRetried}
      errorCode={errorCode}
      newStepAdded={newStepAdded}
    />
  );
};

export default StatusCell;
