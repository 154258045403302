import * as Ariakit from '@ariakit/react';
import { pulseAnimation } from '@bugbug/core/theme';
import styled from 'styled-components';

import type { TutorialBackdrop, TutorialTooltipProps } from './TutorialTooltip';

import TutorialArrowSVG from '~/images/tutorial-arrow.svg?react';

export const OverlayWithHole = styled.div<TutorialBackdrop['circle']>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: radial-gradient(
    circle at ${({ position }) => `${position.x}px ${position.y}px`},
    transparent ${({ radius }) => radius - 1}px,
    rgba(0, 0, 0, 0.3) ${({ radius }) => radius}px
  );
  background-size: 100% 100%;
  pointer-events: none;
`;

export const ClickTrap = styled.div<
  Pick<TutorialTooltipProps, 'allowOutsideClick' | 'dismissOnOutsideClick'>
>`
  position: absolute;
  z-index: 110;
  pointer-events: ${(props) =>
    props.allowOutsideClick && !props.dismissOnOutsideClick ? 'none' : 'all'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const HoleRing = styled.div<TutorialBackdrop['circle']>`
  position: absolute;
  pointer-events: none;
  z-index: 125;
  top: ${({ position, radius }) => position.y - radius}px;
  left: ${({ position, radius }) => position.x - radius}px;
  width: ${({ radius }) => radius * 2}px;
  height: ${({ radius }) => radius * 2}px;
  border-radius: 50%;
  outline: 2px solid ${({ theme }) => theme.colors.common.white};
  outline-offset: -2px;
  animation: ${({ radius }) => pulseAnimation((radius + 12) / radius)} 1.5s ease-out infinite;
`;

export const TooltipPopover = styled(Ariakit.Popover)`
  min-height: 52px;
  min-width: 52px;
  z-index: 150;
  background-color: ${({ theme }) => theme.colors.common.white};
  border-radius: 5px;
  box-shadow: 0 7px 50px -3px rgba(47, 46, 55, 0.08), 0 1px 2px 0 rgba(30, 32, 59, 0.11);
`;

export const TooltipArrow = styled(TutorialArrowSVG)`
  color: ${({ theme }) => theme.colors.common.white};
  position: absolute;
  top: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
`;
