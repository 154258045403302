import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

import useActionState from '~/hooks/useActionState';
import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import {
  selectRegistrationEmail,
  selectRegistrationOrganizationName,
} from '~/modules/user/user.selectors';

import { AuthLayout } from '../AuthLayout/AuthLayout';
import { OnboardingBox } from '../Onboarding/Onboarding.styled';

import * as S from './SignUp.styled';
import { SignUpForm } from './SignUpForm/SignUpForm';

export const SignUpByInvitation = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'auth.signUpInvitation' });
  const appRoute = useAppRoutes('signUpByInvitation');
  const dispatch = useAppDispatch();

  const { key } = useQueryString();

  const email = useAppSelector(selectRegistrationEmail) as string;
  const organization = useAppSelector(selectRegistrationOrganizationName) as string;

  const getRegistrationDataState = useActionState(UserActions.getRegistrationDataRequest);

  useEffect(() => {
    if (key) {
      dispatch(UserActions.getRegistrationDataRequest(key));
    }
  }, [dispatch, key]);

  useEffect(() => {
    if (getRegistrationDataState.isFailure) {
      appRoute.push('notFound');
    }
  }, [getRegistrationDataState, appRoute]);

  useUnmount(() => {
    dispatch(UserActions.clearRegistrationData());
  });

  return (
    <S.Container>
      <Helmet
        title={t('pageTitle', 'Start testing for free')}
        link={[{ rel: 'canonical', href: appRoute.getAbsoluteRouteUrl('signUpByInvitation') }]}
      />
      <S.ByInvitationPageLayout>
        <OnboardingBox>
          <AuthLayout
            title={
              <>
                {t('title', 'Join')} {organization}
              </>
            }
            subTitle={email}
            form={<SignUpForm email={email} registrationKey={key} />}
          />
        </OnboardingBox>
      </S.ByInvitationPageLayout>
    </S.Container>
  );
};
