import EmptyState from '@bugbug/core/components/EmptyState';
import Link from '@bugbug/core/components/Link';
import LoaderComponent from '@bugbug/core/components/Loader';
import { H1 } from '@bugbug/core/theme/typography';
import { renderWhenTrueOtherwise } from '@bugbug/core/utils/rendering';
import { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import type { MouseEvent } from 'react';

import type { Alert } from '@bugbug/core/types/alerts';
import type { SideEffect } from '@bugbug/core/types/utils';
import InlineTutorialMessage from '~/components/InlineTutorialMessage';
import ScrollShadow from '~/components/ScrollShadow';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useGetAlertsQuery } from '~/modules/alerts/alerts.api';
import { useGetActiveIntegrationsQuery } from '~/modules/integrations/integrations.api';

import AlertDetails from '../AlertDetails';
import urls from '../urls';

import * as S from './AlertsList.styled';
import AlertActions from './components/AlertActions';
import AlertItem from './components/AlertItem';

const AlertsList = () => {
  const { t } = useTranslation();
  const appRoute = useAppRoutes('alerts');
  const { projectId } = appRoute.params;
  const { data: alerts, isLoading, isError, refetch } = useGetAlertsQuery(projectId);

  useGetActiveIntegrationsQuery(projectId, { refetchOnFocus: true });

  const Title = useMemo(
    () => (
      <S.TitleContainer data-testid="Variables.Header">
        <H1>{t('alertsList.title', 'Alerts')}</H1>
        <AlertActions />
      </S.TitleContainer>
    ),
    [t],
  );

  const handleAlertItemClick = useCallback<SideEffect<MouseEvent<HTMLDivElement>, Alert['id']>>(
    (__, alertId) => appRoute.push('alert', { alertId }),
    [appRoute],
  );

  const renderData = renderWhenTrueOtherwise(
    () => (
      <S.AlertsSection>
        {alerts?.map((alert: Alert) => (
          <AlertItem key={alert.id} alert={alert} onClick={handleAlertItemClick} />
        ))}
      </S.AlertsSection>
    ),
    () => (
      <EmptyState
        isVisible
        inline
        text={t('alertsList.emptyList.text', "You haven't created any alerts yet")}
      />
    ),
  );

  return (
    <S.Container title={Title} paidFeatureBanner="alerts">
      <Helmet title={t('alertsList.pageTitle', 'Alerts')} />
      <InlineTutorialMessage
        title={t('inlineTutorial.title')}
        message={
          <Trans key="alertsList.inlineTutorial.message">
            Alerts allow you to send additional notifications to selected people inside or outside
            your organization. You can choose whether the notification is sent as an email, webhook,
            or Slack message. <Link to={t('default.docs.alertsList')}>Learn more</Link>
          </Trans>
        }
        storageName="alertsListGettingStarted"
      />
      <S.Content>
        <ScrollShadow />
        {isError && <ServerErrorInfo isVisible={isError} onRetry={refetch} />}
        {isLoading && (
          <S.LoaderContainer>
            <LoaderComponent size="regular" />
          </S.LoaderContainer>
        )}
        {!isError && !isLoading && renderData(!!alerts?.length)}
      </S.Content>
      <Switch>
        <Route path={urls.alert} component={AlertDetails} exact />
      </Switch>
    </S.Container>
  );
};
export default AlertsList;
