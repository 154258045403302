import styled from 'styled-components';

import Card from '~/components/Card';

import EmailSVG from '~/images/email-logo.svg?react';

export const Email = styled.strong`
  overflow-wrap: anywhere;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
`;

export const Icon = styled(EmailSVG)`
  width: 32px;
  height: 32px;
`;

export const EmailVerificationCard = styled(Card)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
`;

export const FooterText = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 13px;
  line-height: 19px;
`;

export const ResendLinkButton = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;
