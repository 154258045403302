import { noop } from 'lodash';
import { useCallback, useRef } from 'react';
import { useMousePositionAsTrigger, useLayer } from 'react-laag';
import { useUnmount } from 'react-use';

import type { MouseEvent, MouseEventHandler } from 'react';
import type { IBounds } from 'react-laag';

import type { URLString } from '@bugbug/core/types/aliases';
import type { SideEffect } from '@bugbug/core/types/utils';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

import * as S from './ImageWithPreview.styled';

const HIDE_PREVIEW_DELAY_MS = 1000;

interface ImageWithPreviewProps {
  src: URLString;
  alt?: string;
  onClick?: SideEffect<MouseEvent<HTMLDivElement>>;
}

export const ImageWithPreview = ({ src, alt, onClick = noop }: ImageWithPreviewProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const mouseTimeout = useRef<NodeJS.Timeout>();

  const {
    hasMousePosition: isOpen,
    handleMouseEvent,
    resetMousePosition,
  } = useMousePositionAsTrigger();

  const { layerProps, layerSide, renderLayer, triggerBounds } = useLayer({
    isOpen,
    placement: 'top-center',
    onOutsideClick: resetMousePosition,
    overflowContainer: true,
    auto: true,
    onDisappear: resetMousePosition,
    trigger: {
      getBounds() {
        return containerRef.current?.getBoundingClientRect() as IBounds;
      },
      getParent() {
        return containerRef.current?.parentElement as HTMLElement;
      },
    },
  });

  useUnmount(() => {
    clearTimeout(mouseTimeout.current);
  });

  const handleClick = useCallback<
    SideEffect<MouseEvent<HTMLDivElement>, MouseEventHandler<HTMLDivElement>>
  >(
    (event, callback) => {
      onClick(event);
      callback(event);
    },
    [onClick],
  );

  const handlePreviewMouseLeave = useCallback<SideEffect<MouseEvent<HTMLDivElement>>>(
    (event) => {
      event.persist();
      const timeoutId = setTimeout(() => {
        resetMousePosition();
      }, HIDE_PREVIEW_DELAY_MS);

      mouseTimeout.current = timeoutId;
    },
    [resetMousePosition],
  );

  const handlePreviewMouseEnter = useCallback<SideEffect>(() => {
    clearTimeout(mouseTimeout.current);
  }, [mouseTimeout]);

  const trackScreenshotClick = useCallback<SideEffect>(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.SCREENSHOT_ELEMENT_CLICKED);
  }, []);

  const handleImageClick = useCallback<SideEffect<MouseEvent<HTMLDivElement>>>(
    (event) => {
      trackScreenshotClick();
      handleClick(event, handleMouseEvent);
    },
    [handleClick, handleMouseEvent, trackScreenshotClick],
  );

  return (
    <S.Container ref={containerRef} onClick={handleImageClick} data-testid="ImageWithPreview">
      <S.Image loading="lazy" src={src} alt={alt} />
      {isOpen &&
        renderLayer(
          <S.PreviewImage
            loading="lazy"
            onMouseEnter={handlePreviewMouseEnter}
            onMouseLeave={handlePreviewMouseLeave}
            onMouseDown={resetMousePosition}
            data-testid="ImageWithPreview.Preview"
            {...layerProps}
            src={src}
            side={layerSide}
            initialHeight={triggerBounds?.height}
            initialWidth={triggerBounds?.width}
          />,
        )}
    </S.Container>
  );
};
