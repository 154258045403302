import type { ReactNode } from 'react';

import * as S from './Separator.styled';

interface SeparatorProps {
  children: ReactNode;
}
export const Separator = ({ children }: SeparatorProps) => (
  <S.SeparatorContainer>
    <S.SeparatorLine />
    <S.SeparatorText>{children}</S.SeparatorText>
    <S.SeparatorLine />
  </S.SeparatorContainer>
);
