import { isEmpty } from 'lodash';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import type { StepsBaseListRef } from '../StepsBaseList/StepsBaseList.types';
import type { MutableRefObject } from 'react';

import type { Step } from '@bugbug/core/types/steps';
import type { Maybe } from '@bugbug/core/types/utils';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import useTestFailedStateDetector from '~/hooks/useTestFailedStateDetector';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import { StepsActions } from '~/modules/steps/steps.redux';
import { selectCurrentStepId } from '~/modules/steps/steps.selectors';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { selectFailedStepRunWithStep } from '~/modules/testRun/testRun.selectors';

interface StepsScrollBehaviorProps {
  containerRef: MutableRefObject<Maybe<StepsBaseListRef>>;
  allTestSteps: Record<string, Step>;
}

export interface StepsScrollBehaviorRef {
  scrollToFailedStep: () => void;
}

export const StepsScrollBehavior = forwardRef<StepsScrollBehaviorRef, StepsScrollBehaviorProps>(
  ({ containerRef, allTestSteps }, ref) => {
    const { groupId: groupIdFromUrl, stepId: stepIdFromUrl } = useQueryString();
    const recentlyFocusedFailedStepRunId = useRef(null);
    const dispatch = useAppDispatch();
    const currentStepId = useAppSelector(selectCurrentStepId);
    const failedStepRunWithStep = useAppSelector(selectFailedStepRunWithStep);
    const failedStepId = failedStepRunWithStep?.failedStepRun?.stepId;
    const failedStepRun = useMemo(() => {
      if (!failedStepId) return {};

      // Filter out steps that were just removed
      const failedStep = allTestSteps[failedStepId];
      if (!failedStep) return {};

      return failedStepRunWithStep.failedStepRun;
    }, [allTestSteps, failedStepRunWithStep.failedStepRun, failedStepId]);

    const updateQueryString = useUpdateQueryString();
    const modal = useModal();
    const openUnavailableStepModal = useCallback(
      () => {
        modal.show('unavailable_step');
        updateQueryString({ stepId: undefined, groupId: undefined });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const selectAndScrollToStep = useCallback(
      (groupId, stepId, currentSteps, immediate = false) => {
        if (containerRef.current && groupId && stepId && currentSteps) {
          const stepAvailable = Object.keys(currentSteps).includes(stepId);
          if (stepAvailable) {
            containerRef.current?.goToStep(groupId, stepId, 'center', immediate);
            dispatch(StepsActions.setCurrentStepId(stepId));
          } else {
            openUnavailableStepModal();
          }
        }
      },
      [containerRef, dispatch, openUnavailableStepModal],
    );

    const handleGoToError = useCallback(() => {
      if (recentlyFocusedFailedStepRunId.current === failedStepRun.id) return;

      recentlyFocusedFailedStepRunId.current = failedStepRun.id;
      selectAndScrollToStep(failedStepRun.groupId, failedStepRun.stepId, allTestSteps);
    }, [allTestSteps, failedStepRun, selectAndScrollToStep]);

    const scrollToFailedStep = useCallback(() => {
      if (isEmpty(failedStepRun)) {
        openUnavailableStepModal();
        return;
      }

      recentlyFocusedFailedStepRunId.current = null;
      handleGoToError();
    }, [failedStepRun, handleGoToError, openUnavailableStepModal]);

    useImperativeHandle(ref, () => ({
      scrollToFailedStep,
    }));

    useTestFailedStateDetector(handleGoToError);

    useEffect(() => {
      if (!currentStepId) {
        selectAndScrollToStep(groupIdFromUrl, stepIdFromUrl, allTestSteps, true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupIdFromUrl, stepIdFromUrl]);

    return null;
  },
);

StepsScrollBehavior.displayName = 'StepsScrollBehavior';
