import type { UserSource } from '@bugbug/core/types/users';
import { COMPANY_ROLE, USER_SOURCE } from '~/modules/user/user.constants';
import i18n from '~/translations';

export const COMPANY_ROLE_LABEL = {
  [COMPANY_ROLE.QA_ENGINEER]: i18n.t('profileSetup.role.qa', 'QA Engineer'),
  [COMPANY_ROLE.FRONTEND_DEVELOPER]: i18n.t('profileSetup.role.frontendDev', 'Frontend Developer'),
  [COMPANY_ROLE.BACKEND_DEVELOPER]: i18n.t('profileSetup.role.backendDev', 'Backend Developer'),
  [COMPANY_ROLE.MOBILE_DEVELOPER]: i18n.t('profileSetup.role.mobileDev', 'Mobile Developer'),
  [COMPANY_ROLE.DEV_OPS]: i18n.t('profileSetup.role.devOps', 'DevOps / Operations'),
  [COMPANY_ROLE.PRODUCT_OWNER]: i18n.t('profileSetup.role.productOwner', 'Product Owner / Manager'),
  [COMPANY_ROLE.PROJECT_MANAGER]: i18n.t('profileSetup.role.projectManager', 'Project Manager'),
  [COMPANY_ROLE.CTO_TECH_LEAD]: i18n.t('profileSetup.role.cto', 'CTO / CIO / Tech Lead'),
  [COMPANY_ROLE.CEO_EXECUTIVE]: i18n.t('profileSetup.role.ceo', 'CEO / Executive'),
  [COMPANY_ROLE.CUSTOM_SUPPORT]: i18n.t('profileSetup.role.customerSupport', 'Customer Support'),
  [COMPANY_ROLE.FINANCE_ACCOUNTS]: i18n.t('profileSetup.role.finance', 'Finance / Accounts'),
  [COMPANY_ROLE.OTHER]: i18n.t('profileSetup.role.other', 'Other'),
};

export const USER_SOURCE_LABEL: Record<UserSource, string> = {
  [USER_SOURCE.SEARCH_ENGINES]: i18n.t(
    'profileSetup.userSource.searchEngines',
    'Search engines (Google, Bing, etc)',
  ),
  [USER_SOURCE.INTERNET_ADS]: i18n.t('profileSetup.userSource.internetAds', 'Internet ads'),
  [USER_SOURCE.TOOLS_RANKINGS]: i18n.t('profileSetup.userSource.toolsRankings', 'Tools rankings'),
  [USER_SOURCE.BUGBUG_BLOG]: i18n.t('profileSetup.userSource.bugbugBlog', 'BugBug’s blog'),
  [USER_SOURCE.CUSTOMER_REVIEW_SITES]: i18n.t(
    'profileSetup.userSource.customerReviewSites',
    'Customer review sites (G2, Capterra, etc)',
  ),
  [USER_SOURCE.ONLINE_COMMUNITIES_FORUMS]: i18n.t(
    'profileSetup.userSource.onlineCommunitiesForums',
    'Online communities and forums',
  ),
  [USER_SOURCE.FRIEND_REFERRAL]: i18n.t(
    'profileSetup.userSource.friendReferral',
    "Friend's referral",
  ),
  [USER_SOURCE.SOCIAL_MEDIA]: i18n.t('profileSetup.userSource.socialMedia', 'Social media'),
  [USER_SOURCE.OTHER]: i18n.t('profileSetup.userSource.other', 'Other'),
};
