import { SelectOption } from '@bugbug/core/components/Select';
import Tooltip, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import { useFormik } from 'formik';
import { move } from 'ramda';
import React, { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { DragHandler } from '~/components/DragHandler/DragHandler';
import useActionState from '~/hooks/useActionState';
import { SELECT_STATE } from '~/modules/constans';
import { ProjectActions } from '~/modules/project/project.redux';
import { selectProjectSelectorMethods } from '~/modules/project/project.selectors';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import {
  SettingsDescriptionWrapper,
  SettingsDescription,
  SubmitButton,
  ButtonsWrapper,
  ErrorInfo,
} from '~/views/ProjectSettings/ProjectSettings.styled';

import { SELECTOR_PARAMS } from './SelectorSettings.constants';
import {
  Container,
  Table,
  TableRow,
  Label,
  Select,
  Input,
  Placeholder,
} from './SelectorsSettings.styled';

const SelectorsSettings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectSelectorMethods = useAppSelector(selectProjectSelectorMethods);

  const handleOnSubmit = ({ selectorMethods }) => {
    dispatch(
      ProjectActions.updateSelectorsSettingsRequest({
        selectorMethods,
      }),
    );
  };

  const {
    submitForm,
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    setValues,
    isSubmitting,
    setSubmitting,
    setErrors,
  } = useFormik({
    initialValues: {
      selectorMethods: projectSelectorMethods,
    },
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const { isLoading, isSuccess, hasInternalServerError } = useActionState(
    ProjectActions.updateSelectorsSettingsRequest,
    {
      reset: false,
      onFailure: handleFailure,
      onSuccess: handleSuccess,
    },
  );

  const handleDragEnd = ({ source, destination }) => {
    if (destination) {
      const reorderedValues = move(source.index, destination.index, values.selectorMethods);
      setValues({ selectorMethods: reorderedValues });
    }
  };

  // eslint-disable-next-line react/prop-types
  const renderSelector = ({ name, isActive: state, extraValue }, index) => {
    const { label, isExtraValueEditable, extraValuePlaceholder, tooltip } = SELECTOR_PARAMS[name];

    return (
      <Draggable disableInteractiveElementBlocking draggableId={name} key={name} index={index}>
        {({ draggableProps, innerRef, dragHandleProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TableRow {...draggableProps} ref={innerRef} data-testid="SelectorsSettings.TableRow">
            {tooltip ? (
              <Tooltip content={tooltip} anchor={TOOLTIP_ANCHOR.LEFT_CENTER}>
                <Label>{label}</Label>
              </Tooltip>
            ) : (
              <Label>{label}</Label>
            )}
            <Select
              name={`selectorMethods[${index}].isActive`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={state.toString()}
            >
              <SelectOption value={SELECT_STATE.ENABLED}>
                {t('default.select.enabled')}
              </SelectOption>
              <SelectOption value={SELECT_STATE.DISABLED}>
                {t('default.select.disabled')}
              </SelectOption>
            </Select>
            {isExtraValueEditable ? (
              <Input
                type="text"
                name={`selectorMethods[${index}].extraValue`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={extraValue}
                placeholder={extraValuePlaceholder}
                readOnly={!isExtraValueEditable}
              />
            ) : (
              <Placeholder>{extraValuePlaceholder}</Placeholder>
            )}
            <DragHandler dragHandleProps={dragHandleProps} />
          </TableRow>
        )}
      </Draggable>
    );
  };

  return (
    <Container data-testid="SelectorsSettings">
      <SettingsDescriptionWrapper>
        <SettingsDescription data-testid="SettingsDescription">
          {t(
            'projectSettings.selectorsSettings.description',
            'When you\'re recording a test, the elements that you are clicking are recorded as "selectors". There are several ways to create such selectors - you can choose which of these approaches should be used and decide about their priority',
          )}
        </SettingsDescription>
      </SettingsDescriptionWrapper>

      <form noValidate data-testid="SelectorsSettings.Form" onSubmit={handleSubmit}>
        <DragDropContext onDragEnd={handleDragEnd} enableDefaultSensors>
          <Droppable droppableId="selectorsSettings">
            {({ innerRef, placeholder }) => (
              <Table ref={innerRef} data-testid="SelectorsSettings.table">
                {values.selectorMethods.map(renderSelector)}
                {placeholder}
              </Table>
            )}
          </Droppable>
        </DragDropContext>

        <ButtonsWrapper>
          <SubmitButton succeeded={isSuccess} disabled={isSubmitting} pending={isLoading}>
            {t('default.button.save')}
          </SubmitButton>
          <ErrorInfo isVisible={hasInternalServerError} onRetry={submitForm} />
        </ButtonsWrapper>
      </form>
    </Container>
  );
};

export default SelectorsSettings;
