import { isStepWithSecretValue, isStepWithValue } from '@bugbug/core/types/steps';
import { getFullSelectorPathFromPresets } from '@bugbug/core/utils/selectors';
import { useTranslation } from 'react-i18next';

import type { ScrollStep, Step } from '@bugbug/core/types/steps';
import FilePreview from '~/components/FilePreview';
import { TextCell } from '~/components/Table';
import i18n from '~/translations';
import { getFileNameFromUrl } from '~/utils/misc';

import { AnswerPromptParams } from './AnswerPromptParams';
import { AssertParams } from './AssertParams';
import * as S from './ParamsCell.styled';
import { SelectParams } from './SelectParams';
import { SwitchContextParams } from './SwitchContextParams';

const getScrollParams = (scrollStep: ScrollStep) => {
  switch (scrollStep.scrollTo) {
    case 'edge':
      return i18n.t('stepParams.scroll.edge', '{{ target }} to {{ edge }} position', {
        edge: scrollStep.scrollEdge,
        target: scrollStep.scrollInside,
      });
    case 'untilNextStepElementIsVisible':
      return i18n.t(
        'stepParams.scroll.untilNextElementIsVisible',
        '{{ target }} until element from next step is visible',
        {
          target: scrollStep.scrollInside,
        },
      );
    case 'coords':
      return i18n.t('stepParams.scroll.coords', '{{ target }} to x: {{ x }}, y: {{ y }}', {
        x: scrollStep.scrollX,
        y: scrollStep.scrollY,
        target: scrollStep.scrollInside,
      });
    case 'elementIntoView':
      return i18n.t('stepParams.scroll.elementIntoView', 'element into view: {{ selector }}', {
        selector: getFullSelectorPathFromPresets(scrollStep.selectorsPresets),
      });
    default:
      return null;
  }
};

interface ParamsCellProps {
  step: Step;
}

export const ParamsCell = ({ step }: ParamsCellProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'stepParams' });

  switch (step?.type) {
    case 'answerPrompt':
      return <AnswerPromptParams step={step} />;

    case 'assert':
      return <AssertParams step={step} />;

    case 'select':
      return <SelectParams step={step} />;

    case 'switchContext':
      return <SwitchContextParams step={step} />;

    case 'scroll':
      return <S.TextBlock>{getScrollParams(step)}</S.TextBlock>;

    case 'uploadFile':
      return (
        <S.TextBlock>
          <FilePreview name={getFileNameFromUrl(step.value)} hideDownload />
        </S.TextBlock>
      );

    case 'change':
    case 'type': {
      if (isStepWithSecretValue(step)) {
        return <S.TextBlock>{t('type.protectedContent', 'Protected content')}</S.TextBlock>;
      }
      return <S.TextBlock>{isStepWithValue(step) ? step.value : ''}</S.TextBlock>;
    }

    case 'setLocalVariable':
      return <S.TextBlock>{step.localVariableName}</S.TextBlock>;

    case 'goto':
    case 'newTab':
      // @ts-expect-error TODO: Remove this comment if TextCell is migrated to TypeScript
      return <TextCell value={step.url} parsedUrls />;

    default:
      return null;
  }
};
