import { ActionButton } from '@bugbug/core/components/Button';
import { Trans, useTranslation } from 'react-i18next';

import type { User } from '@bugbug/core/types/users';
import PublicPage from '~/components/PublicPage';
import useActionState from '~/hooks/useActionState';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import { selectUserData } from '~/modules/user/user.selectors';

import {
  OnboardingBox,
  OnboardingCard,
  OnboardingSubtitle,
  OnboardingTitle,
} from '../Onboarding/Onboarding.styled';

import {
  PerkContainer,
  PerkText,
  PerksList,
  TrialCheckIcon,
  TrialFreeIcon,
  TrialLockIcon,
} from './TrialStart.styled';

export const TrialStart = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'onboarding.trialStart',
  });

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserData) as User;

  const start = () => {
    dispatch(UserActions.startOnboardingTrialRequest());
  };

  const { isLoading } = useActionState(UserActions.startOnboardingTrialRequest);

  return (
    <PublicPage>
      <OnboardingBox>
        <OnboardingCard>
          <div>
            <OnboardingTitle>
              {user?.firstName ? (
                <Trans i18nKey="onboarding.trialStart.title">Hi {user?.firstName}!</Trans>
              ) : (
                <Trans i18nKey="onboarding.trialStart.titleGeneric">Hi there!</Trans>
              )}
            </OnboardingTitle>
            <OnboardingSubtitle>{t('subtitle', 'Welcome to BugBug!')}</OnboardingSubtitle>
          </div>
          <PerksList>
            <PerkContainer>
              <TrialCheckIcon />
              <PerkText>
                <Trans i18nKey="onboarding.trialStart.perks.proPlan">
                  You've just started a <strong>14-day free trial of the PRO plan.</strong>
                </Trans>
              </PerkText>
            </PerkContainer>
            <PerkContainer>
              <TrialLockIcon />
              <PerkText>
                {t(
                  'perks.premiumFeatures',
                  'Try all the premium features before you decide which plan is right for you.',
                )}
              </PerkText>
            </PerkContainer>
            <PerkContainer>
              <TrialFreeIcon />
              <PerkText>
                {t(
                  'perks.freePlan',
                  "When your trial ends, we'll automatically move you to the FREE plan.",
                )}
              </PerkText>
            </PerkContainer>
          </PerksList>
          <ActionButton variant="primary" onClick={start} pending={isLoading}>
            {t('button', "Awesome, let's start")}
          </ActionButton>
        </OnboardingCard>
      </OnboardingBox>
    </PublicPage>
  );
};
