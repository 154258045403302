import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgGoogleLogo = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M30.08 16.334C30.08 15.294 29.9867 14.294 29.8133 13.334H16V19.014H23.8933C23.5467 20.8407 22.5067 22.3873 20.9467 23.4273V27.1207H25.7067C28.48 24.5607 30.08 20.8007 30.08 16.334Z", fill: "#4285F4" }),
  /* @__PURE__ */ jsx("path", { d: "M15.9996 30.6655C19.9596 30.6655 23.2796 29.3588 25.7063 27.1188L20.9463 23.4255C19.6396 24.3055 17.9729 24.8388 15.9996 24.8388C12.1862 24.8388 8.94625 22.2655 7.78625 18.7988H2.90625V22.5855C5.31958 27.3722 10.2662 30.6655 15.9996 30.6655Z", fill: "#34A853" }),
  /* @__PURE__ */ jsx("path", { d: "M7.78536 18.7877C7.49203 17.9077 7.3187 16.9744 7.3187 16.0011C7.3187 15.0277 7.49203 14.0944 7.78536 13.2144V9.42773H2.90536C1.90536 11.4011 1.33203 13.6277 1.33203 16.0011C1.33203 18.3744 1.90536 20.6011 2.90536 22.5744L6.70536 19.6144L7.78536 18.7877Z", fill: "#FBBC05" }),
  /* @__PURE__ */ jsx("path", { d: "M15.9996 7.17398C18.1596 7.17398 20.0796 7.92065 21.6129 9.36065L25.8129 5.16065C23.2663 2.78732 19.9596 1.33398 15.9996 1.33398C10.2662 1.33398 5.31958 4.62732 2.90625 9.42732L7.78625 13.214C8.94625 9.74732 12.1862 7.17398 15.9996 7.17398Z", fill: "#EA4335" })
] });
const ForwardRef = forwardRef(SvgGoogleLogo);
export default ForwardRef;
