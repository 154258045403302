import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Route } from '@bugbug/core/types/routes';
import type { Test } from '@bugbug/core/types/tests';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { useAppSelector } from '~/modules/store';
import { selectTest } from '~/modules/test/test.selectors';

const TestsModalRoute = () => {
  const { t } = useTranslation();

  const modal = useModal();
  const { params, push } = useAppRoutes('testsListEdit');
  const { testId } = params;

  const testDetails = useAppSelector(selectTest(testId)) as unknown as Test | undefined;

  const handleHide = useCallback(
    (route: Route = 'testsList') => {
      push(route);
    },
    [push],
  );

  useEffect(() => {
    if (!testId) return;
    if (testId === 'new') {
      modal.show('edit_test', {}, { onHide: handleHide });
      return;
    }

    if (!testDetails) return;
    modal.show('edit_test', { ...testDetails }, { onHide: handleHide });
  }, [handleHide, modal, t, testDetails, testId]);

  return null;
};

export { TestsModalRoute };
