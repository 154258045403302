import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';

import PublicPage from '~/components/PublicPage';
import useActionState from '~/hooks/useActionState';
import { useAppDispatch } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import analytics from '~/services/analytics';
import urls from '~/views/urls';

import { OnboardingBox, OnboardingTitle } from '../Onboarding/Onboarding.styled';

import * as S from './SignUpSuccess.styled';

export const SignUpSuccess = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'emailVerification' });
  const history = useHistory<{ email?: string; redirected?: boolean }>();
  const dispatch = useAppDispatch();
  const { email, redirected = false } = history?.location?.state ?? {};

  useMount(() => {
    analytics.trackEvent('sign_up_not_confirmed', { provider: 'regular' });
  });

  useEffect(() => {
    if (!redirected) {
      history.push(urls.signUp);
    }
  }, [history, redirected]);

  const handleResendSuccess = useCallback(() => {
    history.push(urls.signUpSuccess, { email, redirected: true });
  }, [email, history]);

  useActionState(UserActions.resendActivationEmailRequest, {
    onSuccess: handleResendSuccess,
  });

  const handleResendEmail = () => {
    dispatch(UserActions.resendActivationEmailRequest(email, true));
  };

  return (
    <PublicPage>
      <Helmet>
        <title>{t('pageTitle', 'Account created!')}</title>
        <meta name="robots" content="none" />
      </Helmet>
      <OnboardingBox>
        <S.EmailVerificationCard>
          <S.Icon />
          <S.InfoContainer>
            <OnboardingTitle>{t('title', 'Check your email')}</OnboardingTitle>
            <S.Text>
              <Trans i18nKey="emailVerification.description">
                We've sent a verification link to <S.Email>{{ email }}</S.Email>
              </Trans>
            </S.Text>
            <S.Text>
              <Trans i18nKey="emailVerification.instructions">
                Please check your email and <strong>click the link</strong> to activate your
                account.
              </Trans>
            </S.Text>
            <S.FooterText>
              <Trans i18nKey="emailVerification.footerText">
                Didn't get the email?{' '}
                <S.ResendLinkButton onClick={handleResendEmail}>Send again</S.ResendLinkButton>
              </Trans>
            </S.FooterText>
          </S.InfoContainer>
        </S.EmailVerificationCard>
      </OnboardingBox>
    </PublicPage>
  );
};
