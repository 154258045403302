import SidebarView from '~/components/SidebarView';
import { selectCurrentStep } from '~/modules/components/components.selectors';
import { useAppSelector } from '~/modules/store';

import Sidebar from './components/ComponentSidebar';
import Content from './components/Content';

const ComponentDetails = () => {
  const currentStepId = useAppSelector(selectCurrentStep)?.id;

  return (
    <SidebarView
      prefix="componentDetails"
      sidebarAlwaysVisible={!!currentStepId}
      Content={Content}
      Sidebar={Sidebar}
    />
  );
};

export default ComponentDetails;
