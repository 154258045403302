import Loader from '@bugbug/core/components/Loader';
import { noop } from '@bugbug/core/utils/toolbox';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { AssertionProperty, AssertStep, Step } from '@bugbug/core/types/steps';
import type { Maybe } from '@bugbug/core/types/utils';
import { Header } from '~/components/modals/Modal';
import StepTypePicker, { STEP_TYPE_PICKER_TAB } from '~/components/StepTypePicker';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';

import { Content, LoaderWrapper } from '../CreateStepModal/CreateStepModal.styled';

import { Container } from './EditStepModal.styled';

interface EditCommandStepModalProps {
  className?: string;
  stepType: Step['type'];
  assertionProperty?: Maybe<AssertionProperty>;
  onStepChange?: <TStep extends Step['type']>(
    stepType: TStep,
    assertionProperty?: TStep extends AssertStep['type'] ? AssertionProperty : never,
  ) => void;
}

interface EditAssertStepModalProps extends EditCommandStepModalProps {
  stepType: AssertStep['type'];
  assertionProperty: AssertionProperty;
}

export type EditStepModalProps = EditCommandStepModalProps | EditAssertStepModalProps;

const EditStepModal = ({
  className,
  stepType,
  assertionProperty,
  onStepChange = noop,
}: EditStepModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const initialTab =
    stepType === 'assert' ? STEP_TYPE_PICKER_TAB.ASSERTIONS : STEP_TYPE_PICKER_TAB.ACTIONS;

  const { isLoading } = useActionState(TestActions.getDefaultStepsParamsRequest);

  const handleChange = useCallback(
    <TStep extends Step['type']>(
      groupType,
      newStepType: TStep,
      newAssertionProperty: TStep extends AssertStep['type'] ? AssertionProperty : never,
    ) => {
      onStepChange(newStepType, newAssertionProperty);
      modal.hide();
    },
    [modal, onStepChange],
  );

  return (
    <Container className={className} data-testid="EditStepModal">
      <Header>{t('editStepModal.title', 'Change step')}</Header>
      <Content>
        {isLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        <StepTypePicker
          //  @ts-expect-error Remove this line after migrating StepTypePickerInput to TS
          stepType={stepType}
          //  @ts-expect-error Remove this line after migrating StepTypePickerInput to TS
          assertionProperty={stepType === 'assert' ? assertionProperty : null}
          initialTab={initialTab}
          onChange={handleChange}
        />
      </Content>
    </Container>
  );
};

export default EditStepModal;
