import EmptyState from '@bugbug/core/components/EmptyState';
import Icon from '@bugbug/core/components/Icon';
import Link from '@bugbug/core/components/Link';
import SearchInput from '@bugbug/core/components/SearchInput';
import { H1 } from '@bugbug/core/theme/typography';
import { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';

import type { IntegrationItem } from './Integrations.types';

import type { InputChangeHandler } from '@bugbug/core/components/Input/Input.types';
import type { Project } from '@bugbug/core/types/projects';
import { CopyText } from '~/components/CopyText/CopyText';
import InlineTutorialMessage from '~/components/InlineTutorialMessage';
import { INTEGRATIONS_LIST } from '~/constants/integrations';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { useGetActiveIntegrationsQuery } from '~/modules/integrations/integrations.api';
import { selectIsFreePlan } from '~/modules/organization/organization.selectors';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { useAppSelector } from '~/modules/store';
import analytics from '~/services/analytics';
import { isFeatureFlagSetToFalse } from '~/utils/projects';

import IntegrationDetails from '../IntegrationDetails';
import urls from '../urls';

import Integration from './Integration';
import * as S from './Integrations.styled';

const Integrations = () => {
  const { t } = useTranslation();
  const project = useAppSelector(selectSingleProject) as Project;
  const [integrationsQuery, setIntegrationsQuery] = useState('');
  const featureFlags = useFeatureFlags();
  const isFreePlan = useAppSelector(selectIsFreePlan) as boolean;
  useGetActiveIntegrationsQuery(project.id, { refetchOnFocus: true });

  const handleIntegrationsQueryChange = useCallback<InputChangeHandler>((event) => {
    setIntegrationsQuery(event.target.value);
  }, []);

  const filteredIntegrations = useMemo(
    () =>
      INTEGRATIONS_LIST.filter((integration) => {
        if (
          integration.isNative &&
          isFeatureFlagSetToFalse(featureFlags, integration.featureFlag)
        ) {
          return false;
        }
        return integration.type.toLowerCase().includes(integrationsQuery.toLowerCase());
      }),
    [integrationsQuery, featureFlags],
  );

  const handleCopyCli = useCallback(() => {
    analytics.trackEvent('cli_copied', {
      location: 'integrations',
    });
  }, []);

  const handleCopyApi = useCallback(() => {
    analytics.trackEvent('api_copied', {});
  }, []);

  const Title = (
    <S.TitleContainer>
      <H1>{t('integrations.title', 'Integrations')}</H1>
    </S.TitleContainer>
  );

  const isDisabledDueToPlan = (integration: IntegrationItem) => isFreePlan && integration.isPaid;

  return (
    <S.Container title={Title} data-testid="Integrations" paidFeatureBanner="integrations">
      <Helmet title={t('integrations.pageTitle', 'Integrations')} />
      <InlineTutorialMessage
        title={t('inlineTutorial.title')}
        message={
          <Trans key="integrations.inlineTutorial.message">
            Easily integrate with your CI/CD pipelines, Github, Gitlab, and more with our CLI. You
            can also natively connect your BugBug account to Slack or Zapier.{' '}
            <Link to={t('default.docs.integrations')}>Learn more</Link>
          </Trans>
        }
        storageName="integrationsGettingStarted"
      />
      <S.Content>
        <S.CopyTextSection>
          <S.Header>{t('integrations.cli.title', 'Command Line Interface')}</S.Header>
          <p>
            <span>
              {t(
                'integrations.cli.description',
                'Use BugBug Command Line Interface (CLI) to easily integrate with your Continuous Integration system.',
              )}{' '}
            </span>
            <Link to={t('integrations.documentationUrl', 'https://docs.bugbug.io/')}>
              {t('integrations.documentationLinkLabel', 'Show documentation')}
            </Link>
          </p>
          <CopyText
            onCopy={handleCopyCli}
            text={t('integrations.cli.command', 'npm install -g @testrevolution/bugbug-cli')}
            icon={<Icon name="npm" width="29" height="29" />}
          />
        </S.CopyTextSection>
        <S.CopyTextSection>
          <S.Header>{t('integrations.api.title', 'API Key')}</S.Header>
          <p>{t('integrations.api.description', 'Project authentication token for API access')}</p>
          <CopyText
            text={project.settings.apiToken}
            onCopy={handleCopyApi}
            icon={<S.KeyIcon width="24" height="24" />}
          />
        </S.CopyTextSection>
        <div>
          <S.IntegrationsHeader>
            <S.Header>{t('integrations.list.title', 'Featured integrations')}</S.Header>
            <div>
              {t(
                'integrations.list.description',
                'Learn how to integrate with the most popular platforms and tools',
              )}
              <SearchInput
                placeholder={t('integrations.list.searchPlaceholder', 'Search')}
                onChange={handleIntegrationsQueryChange}
              />
            </div>
          </S.IntegrationsHeader>
          <S.IntegrationsList role="list">
            {filteredIntegrations.length ? (
              filteredIntegrations.map((integrationProps) => (
                <Integration
                  key={integrationProps.type}
                  {...integrationProps}
                  disabledDueToPlan={isDisabledDueToPlan(integrationProps)}
                />
              ))
            ) : (
              <EmptyState
                isVisible
                inline
                text={t(
                  'integrations.list.noResult',
                  'There is no integrations matching search query.',
                )}
              />
            )}
          </S.IntegrationsList>
        </div>
      </S.Content>
      <Switch>
        <Route path={urls.integration} component={IntegrationDetails} exact />
      </Switch>
    </S.Container>
  );
};

export default Integrations;
