import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { CodeField } from '~/components/CodeField/CodeField';

import { FIELD_NAMES } from './ExecuteActionFields.constants';

const ExecuteActionFields = ({ readOnly }) => {
  const formik = useFormikContext();
  const { name, value } = formik.getFieldProps(FIELD_NAMES.CODE);
  const { error } = formik.getFieldMeta(FIELD_NAMES.CODE);

  return (
    <CodeField
      name={name}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={value}
      readOnly={readOnly}
      error={error}
      functionArguments={['variables']}
    />
  );
};

ExecuteActionFields.defaultProps = {
  readOnly: false,
};

ExecuteActionFields.propTypes = {
  readOnly: PropTypes.bool,
};

export default ExecuteActionFields;
