import styled, { css } from 'styled-components';

import ButtonBase from '../Button';
import IconButtonBase from '../IconButton';

const buttonStyles = css`
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme.colors.text.subtle};
  flex: none;

  &:hover {
    color: ${({ theme }) => theme.colors.text.normal};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    height: 16px;
  }
`;

export const Button = styled(ButtonBase)`
  && {
    ${buttonStyles}
    background-color: ${({ theme }) => theme.colors.common.transparent};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    width: 140px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.common.transparent};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      margin-right: 5px;
    }
  }
`;

export const IconButton = styled(IconButtonBase)`
  && {
    ${buttonStyles}
  }
`;
