import { path, prop } from 'ramda';
import styled, { css } from 'styled-components';

import { COLOR } from '../../../../theme';
import * as Icons from '../../../../theme/icons';
import { styleWhenTrue } from '../../../../utils/rendering';
import { CheckmarkContainer as CheckmarkContainerBase } from '../../../Checkbox/Checkbox.styled';
import { DropdownItem } from '../../../Dropdown';

export const IconPlaceholder = styled.div`
  min-width: 20px;
  height: 20px;
  margin-right: 6px;
  float: left;
`;

const linkOption = css`
  text-overflow: ellipsis;
  overflow: hidden;
  flex: none;
  display: flex;
  align-items: center;
  white-space: unset;
  word-break: break-word;

  && svg {
    color: ${({ theme }) => theme.colors.text.subtle};
    top: 0;
    flex: none;
  }

  span {
    min-height: 20px;
    padding-bottom: 1px;
  }

  ${styleWhenTrue(
    path(['theme', 'small']),
    css`
      padding: 6px 21px 7px 10px;
    `,
  )}
`;

export const CheckmarkContainer = styled(CheckmarkContainerBase)`
  margin-right: 6px;
`;

export const CheckBoxIcon = styled(Icons.CheckIcon)`
  color: ${COLOR.PRIMARY_LIGHT} !important;
  display: block;
  margin-right: auto !important;
  margin-left: auto !important;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Option = styled(DropdownItem).attrs(() => ({
  role: 'option',
}))`
  ${linkOption}

  ${styleWhenTrue(
    prop('borderBottom'),
    css`
      border-bottom: 1px solid ${COLOR.GRAY_40};
    `,
  )}

  align-items: flex-start;
`;
