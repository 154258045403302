import { CommonActions } from '@bugbug/core/actions/actions';
import * as Sentry from '@sentry/react';
import { push } from 'connected-react-router';
import status from 'http-status';
import queryString from 'query-string';
import { eventChannel } from 'redux-saga';
import { all, call, put, select, takeLatest, fork, take, putResolve } from 'redux-saga/effects';

import { SUBSCRIPTION_TYPE } from '~/constants/subscription';
import config from '~/modules/config';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { selectLocation } from '~/modules/router.selectors';
import { WebsocketActions } from '~/modules/websocket/websocket.redux';
import selectWebsocketChannelName from '~/modules/websocket/websocket.selectors';
import analytics, {
  TRACK_EVENT_TYPE,
  TRACK_EVENT_ARG_TYPE,
  TRACK_EVENT_ARG_VALUE,
} from '~/services/analytics';
import api from '~/services/api';
import localStorage from '~/services/localStorage';
import toasts from '~/services/toasts';
import i18n from '~/translations';
import { LogoutBroadcaster } from '~/utils/broadcaster';
import * as cookies from '~/utils/cookies';
import urls, { reverse } from '~/views/urls';

import { dispatchInExtension } from '../extension/extension.dispatch';
import { OrganizationActions } from '../organization/organization.redux';
import { selectCurrentOrganizationId } from '../organization/organization.selectors';

import { SOCIAL_AUTH_STATE, BROADCAST_EVENTS, SOCIAL_AUTH_RETURN_URL } from './user.constants';
import { UserActions, UserTypes } from './user.redux';
import { selectIsSocialLogin, selectUserProfile, selectIsUserLoggedIn } from './user.selectors';

export function* refreshAnalyticsGlobals() {
  const isLoggedIn = yield select(selectIsUserLoggedIn);
  if (!isLoggedIn) {
    return;
  }

  const profileData = yield select(selectUserProfile);
  const project = yield select(selectSingleProject);

  const subscriptionStatus = {
    [SUBSCRIPTION_TYPE.FREE]: TRACK_EVENT_ARG_VALUE.SUBSCRIPTION_FREE,
    [SUBSCRIPTION_TYPE.PRO]: TRACK_EVENT_ARG_VALUE.SUBSCRIPTION_PRO,
    [SUBSCRIPTION_TYPE.BASIC]: TRACK_EVENT_ARG_VALUE.SUBSCRIPTION_BASIC,
    [SUBSCRIPTION_TYPE.BUSINESS]: TRACK_EVENT_ARG_VALUE.SUBSCRIPTION_BUSINESS,
  };

  yield call(Sentry.setTag, 'organizationId', profileData?.organization?.id);
  yield call(Sentry.setTag, 'projectId', project?.id);
  yield call(
    Sentry.setTag,
    'plan',
    profileData?.subscription?.isTrial ? 'trial' : profileData?.subscription?.planType,
  );
  yield call(Sentry.setTag, 'userId', profileData?.user?.id);
  yield call(
    Sentry.setTag,
    'userUrl',
    `${config.WEBAPP_URL}/starship-admin/user/user/?id__icontains=${profileData?.user?.id}`,
  );

  yield call(Sentry.setUser, {
    id: profileData.user?.id,
  });

  yield call(analytics.setGlobalEventData, {
    [TRACK_EVENT_ARG_TYPE.PROJECT_ID]: project.id,
    [TRACK_EVENT_ARG_TYPE.ORGANIZATION_ID]: profileData.organization.id,
    [TRACK_EVENT_ARG_TYPE.SUBSCRIPTION_STATUS]: profileData?.subscription?.isTrial
      ? TRACK_EVENT_ARG_VALUE.SUBSCRIPTION_TRIAL
      : subscriptionStatus[profileData.subscription.planType],
    [TRACK_EVENT_ARG_TYPE.SUBSCRIPTION_PAID]:
      !profileData?.subscription?.isTrial && !profileData?.subscription?.isFreePlan
        ? TRACK_EVENT_ARG_VALUE.YES
        : TRACK_EVENT_ARG_VALUE.NO,
    [TRACK_EVENT_ARG_TYPE.SIGN_UP_METHOD]: profileData.user.origin,
    [TRACK_EVENT_ARG_TYPE.SIGN_UP_DATE]: profileData.user.dateJoined,
  });
}

export function* getSingle() {
  try {
    const [{ data: user }, { data: flags }] = yield all([
      call(api.auth.getUser),
      call(api.auth.getUserFlags),
    ]);

    const userId = user?.id;
    yield call(Sentry.setUser, { id: userId });
    yield call(localStorage.setUser, userId);
    yield put(UserActions.getSingleSuccess(user));
    yield put(UserActions.getFlagsSuccess(flags));

    /*
      Previously it was dispatched in the App component, but it was causing a bug
      after introducing the new Upgrade banners.
      A backend started to return taxPercentage on subscriptions list,
      but it needs a BugBug-Organization-Id header (taxPercentage is related with organization's country).
      The header is set after UserActions.getSingleSuccess.

      Related issue: DEV-3170
    */
    yield put(OrganizationActions.getSubscriptionsRequest());

    yield call(analytics.initUserSession, userId);
    yield putResolve(UserActions.refreshAnalyticsGlobals());
    if (yield select(selectIsSocialLogin)) {
      const profileData = yield select(selectUserProfile);
      yield call(analytics.setUserProfile, profileData);
      yield call(analytics.trackEvent, TRACK_EVENT_TYPE.LOGIN);
    }
  } catch (error) {
    yield put(WebsocketActions.closeRequested());
    yield put(UserActions.getSingleFailure(error));
  }
}

function* handleLoginSuccessNavigation(returnUrl) {
  yield put(push(returnUrl ? decodeURIComponent(returnUrl) : urls.home));
}

export function* loginRequest({ payload }) {
  try {
    const urlParams = queryString.parse(window.location.search);
    const { data } = yield call(api.auth.login, payload);
    yield put(UserActions.loginSuccess({ token: data.key }));
    yield call(analytics.initUserSession, data.userPk);
    yield call(analytics.trackEvent, TRACK_EVENT_TYPE.LOGIN);
    yield handleLoginSuccessNavigation(urlParams.return_url);
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      yield call(toasts.user.showLoginError);
    }
    yield put(UserActions.loginFailure(error));
  }
}

export function* logout() {
  try {
    yield call(api.auth.logout);
    yield put(UserActions.logoutSuccess());
    yield call(dispatchInExtension, CommonActions.logoutFromExtension());
    yield call(LogoutBroadcaster.emit, BROADCAST_EVENTS.LOGOUT);
  } catch (error) {
    yield put(UserActions.logoutFailure(error));
  }
}

export function* logoutSuccess() {
  yield put(UserActions.stopListeningForLogout());
  yield put(WebsocketActions.closeRequested());
  yield call(Sentry.setUser, {});
  yield call(analytics.clearSession);
}

export function* loggedOut() {
  yield put(push(urls.login));
}

export function* confirmEmailRequest({ data }) {
  try {
    const { data: responseData } = yield call(api.auth.confirmEmail, data);
    yield call(analytics.initUserSession, responseData.userPk);

    yield put(UserActions.confirmEmailSuccess());
    yield put(UserActions.loginSuccess({ token: responseData.key }));
  } catch (error) {
    yield put(UserActions.confirmEmailFailure(error));
  }
}

export function* resendActivationEmailRequest({ email, shouldShowSuccessToast = false }) {
  try {
    yield call(api.auth.resendActivationEmail, email);
    yield put(UserActions.resendActivationEmailSuccess());

    if (shouldShowSuccessToast) {
      yield call(toasts.user.showResendSuccess);
    }
  } catch (error) {
    yield call(toasts.user.showResendError);
    yield put(UserActions.resendActivationEmailFailure(error));
  }
}

export function* passwordReset({ data }) {
  try {
    yield call(api.auth.resetPassword, data);
    yield put(UserActions.passwordResetSuccess());
    yield put(push(urls.passwordResetSuccess));
  } catch (error) {
    yield put(UserActions.passwordResetFailure(error));
  }
}

export function* passwordResetConfirm({ data }) {
  try {
    yield call(api.auth.confirmPasswordReset, data);
    yield put(UserActions.passwordResetConfirmSuccess());
  } catch (error) {
    yield put(UserActions.passwordResetConfirmFailure(error));
  }
}

export function* passwordChange({ data }) {
  try {
    yield call(api.auth.changePassword, data);
    yield put(UserActions.passwordChangeSuccess());
  } catch (error) {
    yield put(UserActions.passwordChangeFailure(error.response.data));
  }
}

export function* updateData({ data }) {
  try {
    const { data: updatedData } = yield call(api.auth.updateUserData, data);
    yield put(UserActions.updateDataSuccess(updatedData));
  } catch (error) {
    yield put(UserActions.updateDataFailure(error));
  }
}

function* redirectToUrl(url = '/') {
  yield put(push(url.replace(config.WEBAPP_URL, '')));
}

export function* updateOnboardingProfileRequest({ data }) {
  try {
    const {
      isInvitedUser,
      organization: { companyName },
      ...payload
    } = data;
    const organizationName = companyName ?? i18n.t('organization.defaultName', 'Organization');
    const { data: updatedData } = yield call(api.onboarding.setProfile, {
      ...payload,
      organization: isInvitedUser
        ? {}
        : {
            companyName: organizationName,
          },
    });
    if (!isInvitedUser) {
      yield put(OrganizationActions.updateOrganizationNameSuccess(organizationName));
    }
    yield put(UserActions.updateOnboardingProfileSuccess(updatedData));
    yield put(UserActions.skipOnboardingStepSuccess(updatedData.onboardingStep));

    yield call(redirectToUrl, updatedData.url);
  } catch (error) {
    yield put(UserActions.updateOnboardingProfileFailure(error));
    yield put(OrganizationActions.updateOrganizationNameFailure(error));
  }
}

export function* startOnboardingTrialRequest() {
  try {
    const { data } = yield call(api.onboarding.startTrial);
    yield put(UserActions.startOnboardingTrialSuccess());
    yield put(UserActions.skipOnboardingStepSuccess(data.onboardingStep));
    yield call(redirectToUrl, data.url);
  } catch (error) {
    yield call(toasts.showError, {
      content: i18n.t('errors.unexpectedServerError', 'Unexpected error occurred. Try again.'),
    });
    yield put(UserActions.startOnboardingTrialFailure(error));
  }
}

export function* updateOnboardingOrganizationRequest({ name }) {
  try {
    const { data: updatedData } = yield call(api.onboarding.setOrganization, { name });
    yield put(OrganizationActions.updateOrganizationNameSuccess(name));
    yield put(UserActions.skipOnboardingStepSuccess(updatedData.onboardingStep));
    yield call(redirectToUrl, updatedData.url);
    yield call(analytics.trackEvent, TRACK_EVENT_TYPE.SIGN_UP);
  } catch (error) {
    yield put(UserActions.updateOnboardingOrganizationFailure(error));
  }
}

export function* updateSettings({ settings, meta }) {
  try {
    const channelName = yield select(selectWebsocketChannelName);
    const data = { ...settings, channelName };
    yield call(api.user.updateUserSettings, data);
    yield put(UserActions.updateSettingsSuccess(settings, meta));
  } catch (error) {
    yield put(UserActions.updateSettingsFailure(error, meta));
  }
}

export function* updateMarketingConsent({ isAllowedEmailMarketing }) {
  try {
    const {
      data: { onboardingStep, url },
    } = yield call(api.onboarding.setMarketingConsent, isAllowedEmailMarketing);

    yield put(UserActions.skipOnboardingStepSuccess(onboardingStep));
    yield put(UserActions.updateMarketingConsentSuccess());
    yield call(redirectToUrl, url);
  } catch (error) {
    yield put(UserActions.updateMarketingConsentFailure());
  }
}

export const handleLocationAssign = (url) => window.location.assign(url);

export function* getSocialAuthUrl({ data: { provider } }) {
  try {
    const { data: responseData } = yield call(api.auth.getSocialAuthUrl, provider);
    const queryParams = yield call(queryString.parse, responseData.url);
    yield call(localStorage.setItem, SOCIAL_AUTH_STATE, queryParams.state);

    const urlParams = queryString.parse(window.location.search);
    if (urlParams.return_url) {
      yield call(localStorage.setItem, SOCIAL_AUTH_RETURN_URL, urlParams.return_url || '');
    }
    yield call(handleLocationAssign, responseData.url);
  } catch (error) {
    yield put(UserActions.getSocialAuthUrlFailure(error));
  }
}

export function* getSocialAccessToken({ data: { provider, onboardingStep, ...params } }) {
  try {
    const visitAnalytics = yield call(cookies.getAnalyticsCookiesData);
    const { data: responseData } = yield call(api.auth.getSocialAccessToken, provider, {
      ...params,
      analytics: visitAnalytics,
    });
    yield put(UserActions.loginSuccess({ token: responseData.key }));

    const returnUrl = yield call(localStorage.getItem, SOCIAL_AUTH_RETURN_URL);
    yield call(localStorage.removeItem, SOCIAL_AUTH_RETURN_URL);

    if (responseData.url) {
      yield call(redirectToUrl, responseData.url);
    } else {
      yield handleLoginSuccessNavigation(returnUrl);
    }
  } catch (error) {
    yield put(UserActions.getSocialAccessTokenFailure(error.response.data));
  }
}

export function* http401Error() {
  const location = yield select(selectLocation);
  if (location.pathname === urls.login) {
    return;
  }

  yield put(UserActions.loggedOut());
}

export function createLogoutChannel(broadcaster) {
  return eventChannel((emit) => {
    broadcaster.onMessage(emit);

    return () => broadcaster.close();
  });
}

export function* closeLogoutChannelOnNewListenOrStop(channel) {
  yield take([UserTypes.START_LISTENING_FOR_LOGOUT, UserTypes.STOP_LISTENING_FOR_LOGOUT]);
  channel.close();
}

export function* startListeningForLogout() {
  const broadcaster = yield call(LogoutBroadcaster.connect);
  const channel = yield call(createLogoutChannel, broadcaster);

  yield fork(closeLogoutChannelOnNewListenOrStop, channel);

  while (true) {
    yield take(channel);
    yield put(UserActions.logoutSuccess());
  }
}

export function* getRegistrationData({ key }) {
  try {
    const { data } = yield call(api.auth.getRegistrationData, { key });
    yield put(UserActions.getRegistrationDataSuccess(data));
  } catch (error) {
    yield put(UserActions.getRegistrationDataFailure(error));
  }
}

export function* setTutorialVisibilityRequest({ visible, meta }) {
  try {
    yield put(UserActions.setTutorialVisibilitySuccess(visible, meta));
    yield call(api.user.updateUserSettings, { hideTutorial: !visible });
  } catch (error) {
    yield put(UserActions.setTutorialVisibilityFailure(error, !visible, meta));
  }
}

export function* selectInitialModalOptionRequest({ data: { option } }) {
  try {
    const {
      data: { onboardingStep },
    } = yield call(api.onboarding.setInitialModalOption);
    yield put(UserActions.selectInitialModalOptionSuccess());
    yield put(UserActions.skipOnboardingStepSuccess(onboardingStep));
    if (option === 'manage_billing') {
      const organizationId = yield select(selectCurrentOrganizationId);
      yield put(push(reverse(urls.subscription, { organizationId })));
    }
  } catch (error) {
    yield call(toasts.showError, {
      content: i18n.t('errors.unexpectedServerError', 'Unexpected error occurred. Try again.'),
    });
    yield put(UserActions.selectInitialModalOptionFailure(error));
  }
}

export function* redirectOnboarding() {
  try {
    const { data } = yield call(api.onboarding.redirect);
    yield call(redirectToUrl, data.url || urls.organizations);
    yield put(UserActions.redirectOnboardingSuccess());
  } catch (error) {
    yield put(UserActions.redirectOnboardingFailure(error));
  }
}

export default function* userSagas() {
  yield all([
    yield takeLatest(UserTypes.LOGIN_REQUEST, loginRequest),
    yield takeLatest(UserTypes.GET_SINGLE_REQUEST, getSingle),
    yield takeLatest(UserTypes.LOGOUT_REQUEST, logout),
    yield takeLatest(UserTypes.LOGOUT_SUCCESS, logoutSuccess),
    yield takeLatest(UserTypes.CONFIRM_EMAIL_REQUEST, confirmEmailRequest),
    yield takeLatest(UserTypes.PASSWORD_RESET_REQUEST, passwordReset),
    yield takeLatest(UserTypes.PASSWORD_RESET_CONFIRM_REQUEST, passwordResetConfirm),
    yield takeLatest(UserTypes.PASSWORD_CHANGE_REQUEST, passwordChange),
    yield takeLatest(UserTypes.UPDATE_SETTINGS_REQUEST, updateSettings),
    yield takeLatest(UserTypes.UPDATE_DATA_REQUEST, updateData),
    yield takeLatest(UserTypes.GET_SOCIAL_AUTH_URL_REQUEST, getSocialAuthUrl),
    yield takeLatest(UserTypes.GET_SOCIAL_ACCESS_TOKEN_REQUEST, getSocialAccessToken),
    yield takeLatest(UserTypes.LOGGED_OUT, loggedOut),
    yield takeLatest(UserTypes.START_LISTENING_FOR_LOGOUT, startListeningForLogout),
    yield takeLatest(UserTypes.GET_REGISTRATION_DATA_REQUEST, getRegistrationData),
    yield takeLatest(UserTypes.UPDATE_ONBOARDING_PROFILE_REQUEST, updateOnboardingProfileRequest),
    yield takeLatest(UserTypes.SET_TUTORIAL_VISIBILITY_REQUEST, setTutorialVisibilityRequest),
    yield takeLatest(UserTypes.REFRESH_ANALYTICS_GLOBALS, refreshAnalyticsGlobals),
    yield takeLatest(UserTypes.REDIRECT_ONBOARDING_REQUEST, redirectOnboarding),
    yield takeLatest(UserTypes.UPDATE_MARKETING_CONSENT_REQUEST, updateMarketingConsent),
    yield takeLatest(UserTypes.RESEND_ACTIVATION_EMAIL_REQUEST, resendActivationEmailRequest),
    yield takeLatest(UserTypes.START_ONBOARDING_TRIAL_REQUEST, startOnboardingTrialRequest),
    yield takeLatest(
      UserTypes.SELECT_INITIAL_MODAL_OPTION_REQUEST,
      selectInitialModalOptionRequest,
    ),
    yield takeLatest(
      UserTypes.UPDATE_ONBOARDING_ORGANIZATION_REQUEST,
      updateOnboardingOrganizationRequest,
    ),
    //
    yield takeLatest(status['401_NAME'], http401Error),
  ]);
}
