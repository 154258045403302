import type { RunMode } from '@bugbug/core/types/base';
import type { Group } from '@bugbug/core/types/groups';
import type { Profile } from '@bugbug/core/types/profiles';
import type { Step } from '@bugbug/core/types/steps';
import type { Test, TestRun } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';

import api from './common';

export const closeWindows = () => api.post('/extension/windows/close/');

export interface StartRecorderBody {
  testId: Test['id'];
  startAfterStepId: Maybe<Step['id']>;
  intoGroupId: Group['id'];
  url: Maybe<string>;
  sessionId: string;
}

interface StopRecorderBody {
  testId: Test['id'];
}

const recorder = {
  start: (body: StartRecorderBody) =>
    api.post(`/extension/recorder/start/`, {
      ...body,
      websocketId: null,
    }),
  stop: (body: StopRecorderBody) => api.post(`/extension/recorder/stop/`, body),
};

interface StartRunnerBody {
  runMode: RunMode;
  sessionId: string;
  testId: Test['id'];
  runProfileId?: Maybe<Profile['id']>;
  stopAction: 'finish' | 'pause' | 'record';
  startFromStepId: Maybe<Step['id']>;
  stopAfterStepId: Maybe<Step['id']>;
}

interface StartRunnerAndFinishBody extends StartRunnerBody {
  stopAction: 'finish';
}

interface StartRunnerAndRecordBody extends StartRunnerBody {
  stopAction: 'record';
  record: {
    startAfterStepId: Maybe<Step['id']>;
    intoGroupId: Group['id'];
  };
}

interface StartRunnerAndPauseBody extends StartRunnerBody {
  stopAction: 'pause';
  pause: {
    afterStepId: Maybe<Step['id']>;
    atGroupId: Group['id'];
  };
}

interface StopRunnerBody {
  testId: Test['id'];
  testRunId: TestRun['id'];
}

const runner = {
  start: ({
    startFromStepId,
    stopAfterStepId,
    ...body
  }: StartRunnerAndFinishBody | StartRunnerAndRecordBody | StartRunnerAndPauseBody) =>
    api.post(`/extension/runner/start/`, {
      ...body,
      websocketId: null,
      startFromStepId,
      stopAfterStepId,
    }),
  stop: (body: StopRunnerBody) => api.post(`extension/runner/stop/`, body),
};

export const extension = {
  recorder,
  runner,
};
