import { decodeHTML } from '@bugbug/core/utils/text';
import { useTranslation } from 'react-i18next';

import type { Step } from '@bugbug/core/types/steps';
import type { StepRun } from '@bugbug/core/types/tests';

import { getDescription } from './ErrorDetails.helpers';
import * as S from './ErrorDetails.styled';

interface GenericErrorDescriptionProps {
  stepRun: StepRun;
  step: Step;
  title?: string;
}
export const GenericErrorDescription = ({ title, stepRun, step }: GenericErrorDescriptionProps) => {
  const { t } = useTranslation();
  const customErrorMessage = getDescription(stepRun, step);

  return (
    <>
      <S.Title>{title || t('errorDetails.title.failed', 'Step failed!')}</S.Title>
      {customErrorMessage && <S.Description>{customErrorMessage}</S.Description>}
      {stepRun.error &&
        (!customErrorMessage ||
          stepRun.status === 'error' ||
          stepRun.errorCode === 'RUNTIME_ERROR') && (
          <S.ErrorMessage>
            {t('errorDetails.errorMessage.capturedError.label', 'Captured error:')}
            <br />
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('errorDetails.errorMessage.capturedError.message', '"{{message}}"', {
                  message: decodeHTML(stepRun.error),
                }),
              }}
            />
          </S.ErrorMessage>
        )}
    </>
  );
};
