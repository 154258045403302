import { isRecordingOrPausedStatus, isRunningStatus } from '@bugbug/core/types/base';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { last } from 'lodash';
import PropTypes from 'prop-types';
import { memo, useCallback, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';
import { v4 as uuid } from 'uuid';

import { MODAL_TYPE } from '~/components/modals';
import ScrollShadow from '~/components/ScrollShadow';
import { ScrollToStep } from '~/components/ScrollToStep/ScrollToStep';
import { DRAG_END_REASON, DRAG_DROP_ITEM_TYPE } from '~/components/Table';
import { useCopyPasteSteps } from '~/hooks/useCopyPasteSteps';
import useLocalStorageValue from '~/hooks/useLocalStorageValue';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import useTestRunner from '~/hooks/useTestRunner';
import { useTrackCustomPageView } from '~/hooks/useTrackCustomPageView';
import { selectProjectHomepageUrl } from '~/modules/project/project.selectors';
import { useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectSingleTest, selectSortedSteps } from '~/modules/test/test.selectors';
import { selectTestRunStatus } from '~/modules/testRun/testRun.selectors';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import { selectIsDraggingPlaybackCursor } from '~/modules/uiState/uiState.selectors';
import { selectUserFlags } from '~/modules/user/user.selectors';

import { EmptyState } from '../EmptyState/EmptyState';
import EndTestActions from '../EndTestActions';
import { PlaybackCursorDndContext } from '../PlaybackCursor/PlaybackCursor';
import { RowActiveBorderContextProvider } from '../RowActiveBorder/RowActiveBorderContext';
import StepsGroup from '../StepsGroup';

import { getDropData } from './StepsBaseList.helpers';
import { Container, ScrollContainer } from './StepsBaseList.styled';

const Content = memo(
  forwardRef(
    (
      {
        disabledPasting,
        hasGroups,
        initialRecordingUrl,
        isFirstRecording,
        onInsertComponentClick,
        onNewStep,
        onPasteStepsClick,
        onSelectionChange,
        onStartRecording,
        providedMain,
        readOnly,
        renderTestEndActions,
        runResultEnabled,
        test,
      },
      ref,
    ) => {
      const shouldRenderEmptyState = !hasGroups && !runResultEnabled;
      const scrollContainerRef = useRef();
      const groupsRefs = useRef({});
      const refCallback = useCallback(
        (el) => {
          providedMain.innerRef(el);
          scrollContainerRef.current = el;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [providedMain.innerRef],
      );

      const setGroupRef = useCallback((groupRef) => {
        if (groupRef) {
          groupsRefs.current[groupRef.groupId] = groupRef;
        }
      }, []);

      useImperativeHandle(
        ref,
        () => ({
          goToStep: (groupId, stepId, position, immediate) => {
            if (groupId && stepId) {
              groupsRefs.current[groupId]?.goToStep(stepId, position, true, immediate);
            }
          },
          scrollContainer: scrollContainerRef.current,
        }),
        [],
      );

      useUnmount(() => {
        groupsRefs.current = {};
      });

      const trackCustomPageView = useTrackCustomPageView();
      const { testRunId } = useQueryString();
      const singleTest = useAppSelector(selectSingleTest);

      useEffect(() => {
        if (testRunId) {
          trackCustomPageView('Finished Test Page');
          return;
        }
        trackCustomPageView(shouldRenderEmptyState ? 'Record Test Page' : 'Test Steps Page');
      }, [shouldRenderEmptyState, testRunId, trackCustomPageView]);

      const isDraggingPlaybackCursor = useAppSelector(selectIsDraggingPlaybackCursor);

      return (
        <ScrollContainer
          data-testid="StepsBaseList.ScrollContainer"
          isDraggingPlaybackCursor={isDraggingPlaybackCursor}
          ref={refCallback}
        >
          <ScrollShadow />
          <ScrollToStep groupsRefs={groupsRefs.current} />

          <Container
            data-testid="StepsBaseList.Container"
            empty={shouldRenderEmptyState}
            isDraggingPlaybackCursor={isDraggingPlaybackCursor}
          >
            <PlaybackCursorDndContext>
              <RowActiveBorderContextProvider
                containerRef={scrollContainerRef}
                hidden={!hasGroups || isDraggingPlaybackCursor || !singleTest?.id}
              >
                {hasGroups &&
                  test.groups.map((groupId, index) => (
                    <StepsGroup
                      key={groupId}
                      ref={setGroupRef}
                      index={index}
                      testId={test.id}
                      groupId={groupId}
                      onSelectionChange={onSelectionChange}
                      readOnly={readOnly}
                      runResultEnabled={runResultEnabled}
                    />
                  ))}
                {providedMain.placeholder}
                {renderTestEndActions(hasGroups && !readOnly && !runResultEnabled)}
                {shouldRenderEmptyState && (
                  <EmptyState
                    isVisible
                    isFirstRecording={isFirstRecording}
                    isRecording={readOnly}
                    onRecordClick={onStartRecording}
                    onNewStepClick={onNewStep}
                    onInsertComponentClick={onInsertComponentClick}
                    initialRecordingUrl={initialRecordingUrl}
                    onPasteStepsClick={onPasteStepsClick}
                    disabledPasting={disabledPasting}
                  />
                )}
              </RowActiveBorderContextProvider>
            </PlaybackCursorDndContext>
          </Container>
        </ScrollContainer>
      );
    },
  ),
);

const StepsBaseList = forwardRef(
  ({ test, testRunId, onSelectionChange, readOnly, runResultEnabled }, ref) => {
    const dispatch = useDispatch();
    const modal = useModal();

    const testRunner = useTestRunner({ id: test.id }, { testRunId });
    const activeTestRunStatus = useSelector(selectTestRunStatus(testRunId));
    const userFlags = useSelector(selectUserFlags);
    const projectUrl = useSelector(selectProjectHomepageUrl);
    const { value: cachedInitialUrl } = useLocalStorageValue(`${test.id}.initialUrl`);
    const { pasteSteps, hasCopiedSteps, isPending: isPastingPending } = useCopyPasteSteps();
    const sortedSteps = useAppSelector(selectSortedSteps);

    const numberOfGroups = (test.groups || []).length;
    const hasGroups = !!numberOfGroups;

    const handleInsertComponentClick = useCallback(
      () => {
        modal.show(MODAL_TYPE.INSERT_COMPONENT, {
          testId: test.id,
          atIndex: numberOfGroups,
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [modal.show, test.id, numberOfGroups],
    );

    const handleStepOrderChange = useCallback(
      (result) => {
        const stepId = result.rowId;
        dispatch(
          TestActions.updateStepsPosition(
            test.id,
            stepId,
            result.from.rowId,
            result.from.index,
            result.to.rowId,
            result.to.index,
          ),
        );
      },
      [dispatch, test.id],
    );

    const handleGroupOrderChange = useCallback(
      (result) => {
        const groupId = result.rowId;
        dispatch(TestActions.updateGroupPosition(test.id, groupId, result.to.index));
      },
      [dispatch, test.id],
    );

    const handleDragStart = (dragInfo) => {
      if (dragInfo.type !== DRAG_DROP_ITEM_TYPE.STEP) return;
      dispatch(UIStateActions.setDraggedStepId({ stepId: dragInfo.draggableId }));
    };

    const handleDragEnd = useCallback(
      (result) => {
        if (!result.destination || result.reason !== DRAG_END_REASON.DROP) {
          return;
        }

        const dropData = getDropData(result);

        if (dropData.type === DRAG_DROP_ITEM_TYPE.STEP) {
          dispatch(UIStateActions.setDraggedStepId({ stepId: null }));
          handleStepOrderChange(dropData);
        }
        if (dropData.type === DRAG_DROP_ITEM_TYPE.GROUP) {
          handleGroupOrderChange(dropData);
        }
      },
      [dispatch, handleGroupOrderChange, handleStepOrderChange],
    );

    const handleNewGroupClick = useCallback(() => {
      dispatch(TestActions.createStepsGroupRequest(test.id, null, [], numberOfGroups));
    }, [dispatch, test.id, numberOfGroups]);

    const handlePasteSteps = useCallback(() => {
      pasteSteps(test.id, null, numberOfGroups);
    }, [pasteSteps, test.id, numberOfGroups]);

    const handleStartRecording = useCallback(
      (url = '') => {
        testRunner.record({ url, groupId: uuid() });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [testRunner.record],
    );

    const handleStartRunAndRecord = useCallback(() => {
      const lastStep = last(sortedSteps);
      if (lastStep) {
        testRunner.startLocalRunner({
          startFromStepId: null,
          stopAfterStepId: lastStep.id,
          stopAction: 'record',
          record: {
            startAfterStepId: null,
            intoGroupId: uuid(),
          },
          withRecording: true,
          withRedirect: true,
          stopWhenUnconfirmedGroups: true,
        });
      } else {
        testRunner.record({
          url: '',
          groupId: uuid(),
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedSteps, testRunner.startLocalRunner]);

    const handleNewStep = useCallback(() => {
      modal.showWide(MODAL_TYPE.CREATE_STEP, { testId: test.id });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [test.id]);

    const renderTestEndActions = renderWhenTrue(() => (
      <EndTestActions
        onRecordClick={handleStartRunAndRecord}
        onNewGroupClick={handleNewGroupClick}
        onInsertComponentClick={handleInsertComponentClick}
        disabledRecording={
          isRunningStatus(activeTestRunStatus) || isRecordingOrPausedStatus(activeTestRunStatus)
        }
        onPasteStepsClick={handlePasteSteps}
        disabledPasting={!hasCopiedSteps}
        pendingPasting={isPastingPending}
      />
    ));

    return (
      <DragDropContext
        onBeforeDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        enableDefaultSensors
      >
        <Droppable
          droppableId="groups"
          type={DRAG_DROP_ITEM_TYPE.GROUP}
          isDropDisabled={readOnly || runResultEnabled}
        >
          {(providedMain) => (
            <Content
              ref={ref}
              providedMain={providedMain}
              test={test}
              hasGroups={hasGroups}
              readOnly={readOnly}
              onStartRecording={handleStartRecording}
              onSelectionChange={onSelectionChange}
              onNewStep={handleNewStep}
              onInsertComponentClick={handleInsertComponentClick}
              runResultEnabled={runResultEnabled}
              renderTestEndActions={renderTestEndActions}
              isFirstRecording={!userFlags.testRecorded}
              initialRecordingUrl={projectUrl || cachedInitialUrl}
              onPasteStepsClick={handlePasteSteps}
              disabledPasting={!hasCopiedSteps || isPastingPending}
            />
          )}
        </Droppable>
      </DragDropContext>
    );
  },
);

StepsBaseList.defaultProps = {
  readOnly: false,
  runResultEnabled: false,
  onSelectionChange: Function.prototype,
  testRunId: null,
};

StepsBaseList.propTypes = {
  testRunId: PropTypes.string,
  onSelectionChange: PropTypes.func,
  readOnly: PropTypes.bool,
  runResultEnabled: PropTypes.bool,
  test: PropTypes.shape({
    id: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

StepsBaseList.displayName = 'StepsBaseList';

export default StepsBaseList;
