import { ActionButton } from '@bugbug/core/components/Button';
import { ErrorMessage as ErrorMessageBase } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 18px;
  gap: 24px;
`;

export const SubmitButton = styled(ActionButton)`
  justify-content: center;
  margin: 0;

  div {
    flex: initial;
  }
`;

export const ErrorMessage = styled(ErrorMessageBase)`
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

export const ResendEmailButton = styled.button`
  text-decoration: underline;
  color: inherit;
  font-weight: inherit;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
