import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { omit } from 'ramda';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useMount } from 'react-use';

import ServerErrorInfo from '~/components/ServerErrorInfo';
import { SortByDropdown } from '~/components/SortByDropdown/SortByDropdown';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import { useRecentSort } from '~/hooks/useRecentSort';
import { QUERY_ACTION, USER_ROLE_LABELS } from '~/modules/constans';
import { OrganizationUsersActions } from '~/modules/organizationUsers/organizationUsers.redux';
import { selectOrganizationUsersList } from '~/modules/organizationUsers/organizationUsers.selectors';
import { reverse } from '~/views/urls';

import { USERS_LIST_COLUMNS, USERS_LIST_SORT_BY_OPTIONS } from './UsersSettings.constants';
import * as S from './UsersSettings.styled';

const defaultSort = {
  sortBy: 'email',
  desc: true,
};

const UsersSettings = ({ className }) => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryString();
  const modal = useModal();
  const { isLoading, hasInternalServerError, hasBadRequestError } = useActionState(
    OrganizationUsersActions.getListRequest,
  );
  const users = useSelector(selectOrganizationUsersList);

  const { updateRecentSort, ...recentSort } = useRecentSort({
    cacheName: 'usersList',
    defaultConfig: defaultSort,
    columns: USERS_LIST_SORT_BY_OPTIONS,
  });

  const handleDataUpdate = useCallback(() => {
    dispatch(OrganizationUsersActions.getListRequest(recentSort.sortBy, recentSort.desc));
    updateRecentSort({ ...recentSort });
  }, [dispatch, updateRecentSort, recentSort]);

  const handleSortChange = useCallback(
    (sortBy, orderBy) => {
      dispatch(OrganizationUsersActions.getListRequest(sortBy, orderBy === 'desc'));
      updateRecentSort({ sortBy, desc: orderBy === 'desc' });
    },
    [dispatch, updateRecentSort],
  );

  const fetchInitialData = useCallback(() => {
    handleDataUpdate({ ...tableRef.current.state });
  }, [handleDataUpdate]);

  const showInviteUserModal = useCallback(
    () =>
      modal.show(
        'invite_user',
        {},
        {
          onHide: () => {
            history.replace(reverse(history.location.pathname, {}, omit(['action'], queryParams)));
          },
        },
      ),
    [history, modal, queryParams],
  );

  useMount(() => {
    fetchInitialData();
  });

  useEffect(() => {
    if (queryParams.action === QUERY_ACTION.SHOW_USER_INVITATION) {
      showInviteUserModal();
    }
  }, [queryParams.action, showInviteUserModal]);

  const renderLoader = renderWhenTrue(() => {
    if (hasInternalServerError || hasBadRequestError) {
      return (
        <ServerErrorInfo
          isVisible={hasInternalServerError || hasBadRequestError}
          onRetry={handleDataUpdate}
          hideRetryButton={hasBadRequestError}
        />
      );
    }
    return (
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
    );
  });

  return (
    <S.Container className={className} data-testid="UsersSettings">
      <S.Header>
        <S.Description>
          {t(
            'usersSettings.description',
            'Invite teammates to collaborate on all your projects. Below you can see who already has access.',
          )}
        </S.Description>
        <S.InviteButton onClick={showInviteUserModal}>
          {t('usersSettings.inviteButtonLabel', 'Invite new user')}
        </S.InviteButton>
      </S.Header>
      <S.ListContainer>
        <S.SortAndFilterContainer>
          <SortByDropdown
            onChange={handleSortChange}
            configuration={recentSort}
            defaultConfiguration={defaultSort}
            items={USERS_LIST_SORT_BY_OPTIONS}
          />
        </S.SortAndFilterContainer>
        {renderLoader(isLoading || hasInternalServerError || hasBadRequestError)}
        <S.Table
          ref={tableRef}
          columns={USERS_LIST_COLUMNS}
          data={users.map((user) => ({
            ...user,
            roleName: USER_ROLE_LABELS[user.role],
          }))}
          disabledWindowing
        />
      </S.ListContainer>
    </S.Container>
  );
};

export default UsersSettings;
