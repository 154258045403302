import CopyButton from '@bugbug/core/components/CopyButton';
import Icon from '@bugbug/core/components/Icon';
import Tooltip from '@bugbug/core/components/Tooltip';
import { isCustomSelector } from '@bugbug/core/types/steps';
import { isString } from '@bugbug/core/utils/toolbox';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import type { FormikErrors } from 'formik';
import type { MouseEventHandler } from 'react';

import type { Selector, SelectorsGroup } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';
import { InputWithVariables } from '~/components/InputWithVariables/InputWithVariables';

import { ElementRelationField } from '../ElementRelationField/ElementRelationField';
import { RELATION_NAMES } from '../ElementRelationField/ElementRelationField.constants';

import { getSelectorOptionName } from './SelectorsGroupField.helpers';
import * as S from './SelectorsGroupField.styled';

interface SelectorsGroupFieldProps {
  value: SelectorsGroup;
  context?: 'component' | 'test' | 'testRun';
  name?: string;
  disabled?: boolean;
  error?: string | FormikErrors<SelectorsGroup>;
  touched?: boolean;
  onChange?: SideEffect<SelectorsGroup>;
  onDelete?: SideEffect;
  onCustomize?: SideEffect<SelectorsGroup>;
  renderSelector?: (selectorName: string, selector: Selector) => React.ReactNode;
  autoFocus?: boolean;
  selectOnly?: boolean;
  first?: boolean;
  last?: boolean;
}

export const SelectorsGroupField = ({
  context = 'test',
  name = '',
  value,
  disabled = false,
  onChange,
  onDelete,
  onCustomize,
  renderSelector,
  first,
  error,
  touched,
  autoFocus,
  selectOnly,
}: SelectorsGroupFieldProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'elementSelectorBuilder.selectorsGroup' });
  const theme = useTheme();
  const { relation, selectors } = value;
  const getActiveSelectorIndex = useCallback(
    () =>
      Math.max(
        value.selectors.findIndex(({ isActive }) => isActive),
        0,
      ),
    [value],
  );

  const activeSelectorIndex = getActiveSelectorIndex();
  const activeSelector = selectors[activeSelectorIndex];
  const activeSelectorValue = activeSelector?.selector ?? '';
  const isEditableSelector = isCustomSelector(activeSelector);
  const selectorsErrors = isString(error)
    ? undefined
    : (error?.selectors as FormikErrors<Selector>[] | undefined);

  const setSelectorUserValue = useCallback(
    (newSelector) => {
      const updatedValue: SelectorsGroup = {
        ...value,
        selectors: value.selectors.toSpliced(activeSelectorIndex, 1, {
          ...activeSelector,
          isActive: true,
          selector: newSelector,
        }),
      };

      onChange?.(updatedValue);
    },
    [activeSelector, activeSelectorIndex, onChange, value],
  );

  const handleSelectorUserValueChange = useCallback(
    (event) => setSelectorUserValue(event.target.value),
    [setSelectorUserValue],
  );

  const handleSelectorChange = useCallback(
    (event) => {
      const selectedSelectorId = event.target.value;

      const updatedValue: SelectorsGroup = {
        ...value,
        selectors: value.selectors.map((selector, _, selectorsList) => {
          const isActive = selector.id === selectedSelectorId;
          const updatedSelector = { ...selector, isActive };
          if (isCustomSelector(updatedSelector)) {
            updatedSelector.selector =
              updatedSelector.selector || selectorsList[activeSelectorIndex].selector;
          }
          return updatedSelector;
        }),
      };

      onChange?.(updatedValue);
    },
    [onChange, value, activeSelectorIndex],
  );

  const handleRelationChange = (event) => {
    const updatedValue: SelectorsGroup = {
      ...value,
      relation: event.target.value,
      selectors,
    };
    onChange?.(updatedValue);
  };

  const handleCustomizeClick: MouseEventHandler<HTMLButtonElement> = () => onCustomize?.(value);

  const renderSelectorItem = renderSelector ?? ((selectorName) => selectorName);

  return (
    <S.Fields>
      <S.Header>
        {first ? (
          <S.Title>{t('header.label.first', 'Find')}</S.Title>
        ) : (
          <S.Title>
            {t('header.label.then', 'Then find its {{relation}}', {
              relation: selectOnly ? RELATION_NAMES[relation] : undefined,
            })}{' '}
            {!selectOnly && (
              <ElementRelationField
                value={relation}
                disabled={disabled}
                onChange={handleRelationChange}
              />
            )}
          </S.Title>
        )}
        <S.Actions>
          <Tooltip
            content={t('copyButton.tooltip', 'Copy this selector to clipboard')}
            anchor="top-end"
          >
            <CopyButton value={activeSelectorValue} small />
          </Tooltip>
          {context === 'test' && onDelete && <S.DeleteButton onClick={onDelete} />}
        </S.Actions>
      </S.Header>
      <S.Select
        fullWidth
        aria-labelledby={name}
        name={name}
        onChange={handleSelectorChange}
        value={activeSelector ? activeSelector.id : null}
        disabled={context !== 'test' || disabled}
        renderSelectedOption={() => getSelectorOptionName(activeSelector)}
      >
        {selectors.map((selector) => (
          <S.SelectOption key={selector.id} value={selector.id}>
            {renderSelectorItem(getSelectorOptionName(selector), selector)}
          </S.SelectOption>
        ))}
        {context === 'test' && onCustomize && (
          <S.CustomizeButton onClick={handleCustomizeClick}>
            <Icon name="edit" color={theme.colors.primary} size={12} />
            {t('customizeButton', 'Customize')}
          </S.CustomizeButton>
        )}
      </S.Select>
      {isEditableSelector && (
        <InputWithVariables
          as="textarea"
          value={selectors[activeSelectorIndex].selector}
          onChange={handleSelectorUserValueChange}
          error={touched && selectorsErrors?.[activeSelectorIndex]?.selector}
          readOnly={context !== 'test' || disabled}
          initialHeight={55}
          maxInitialHeight={120}
          lineBreakDisabled
          fullWidth
          autoSize
          placeholder={t('placeholder', 'Enter a custom selector')}
          autoFocus={autoFocus}
        />
      )}
    </S.Fields>
  );
};
