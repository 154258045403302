import { matchSorter } from 'match-sorter';
import { useMemo } from 'react';

import type { Step } from '@bugbug/core/types/steps';
import type { Variable, VariableListItem } from '@bugbug/core/types/variables';
import { useAppSelector } from '~/modules/store';
import {
  selectTestSteps,
  selectSortedTestStepIds,
  selectCurrentStepIndex,
} from '~/modules/test/test.selectors';
import {
  selectBuiltInVariablesList,
  selectCustomVariablesList,
} from '~/modules/variable/variable.selectors';

const filterVariables = ({
  searchValue,
  variables,
}: {
  searchValue?: string;
  variables: VariableListItem[];
}) =>
  searchValue
    ? matchSorter(variables, searchValue ?? '', {
        keys: ['key'],
      })
    : variables;

export const useVariables = (searchValue?: string) => {
  const buildInVariables = useAppSelector(selectBuiltInVariablesList) as Variable[];
  const customVariables = useAppSelector(selectCustomVariablesList) as Variable[];
  const currentTestSteps = useAppSelector(selectTestSteps) as Record<string, Step>;
  const currentlySelectedStepIndex = useAppSelector(selectCurrentStepIndex);
  const sortedStepIds = useAppSelector(selectSortedTestStepIds);

  const variables = useMemo(
    () => ({
      buildIn: filterVariables({ searchValue, variables: buildInVariables }),
      custom: filterVariables({ searchValue, variables: customVariables }),
      local: filterVariables({
        searchValue,
        variables: sortedStepIds
          .map((stepId, variableStepIndex) => ({
            ...currentTestSteps[stepId],
            uninitialized:
              currentlySelectedStepIndex !== null &&
              currentlySelectedStepIndex <= variableStepIndex,
          }))
          .filter((s) => s.type === 'setLocalVariable' && s.localVariableName.length > 0)
          .map((step) => {
            // TODO: Can be simplified when TS 5.5 ships
            const key = 'localVariableName' in step ? step.localVariableName : '';
            const type = 'localVariableSource' in step ? step.localVariableSource : 'value';
            return {
              key,
              type,
              value: type === 'value' && 'value' in step ? step.value : key,
              id: step.id,
              description: null,
              uninitialized: step.uninitialized,
              hasSecretValue: false,
            };
          }),
      }),
    }),
    [
      buildInVariables,
      currentlySelectedStepIndex,
      sortedStepIds,
      currentTestSteps,
      customVariables,
      searchValue,
    ],
  );

  return variables;
};
