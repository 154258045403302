import { v4 as uuid } from 'uuid';

import type { CommonAction } from '@bugbug/core/actions/actions';
import { waitForPostMessage } from '~/utils/messages';

export const dispatchInExtension = async (action: ReturnType<CommonAction[keyof CommonAction]>) => {
  const reqId = uuid();
  const message = {
    type: 'DISPATCH_ACTION_FROM_WEBAPP_IN_BACKGROUND',
    action,
    reqId,
  };
  window.postMessage(message, '*');
  return waitForPostMessage({ type: 'EXTENSION_ACTION_DISPATCHED', reqId });
};
