import { useCallback } from 'react';

import type { SidebarViewProps } from '~/components/SidebarView';

import type { Step } from '@bugbug/core/types/steps';
import SidebarView from '~/components/SidebarView';
import { selectCurrentStepId } from '~/modules/steps/steps.selectors';
import { useAppSelector } from '~/modules/store';
import { selectSingleTestRunId } from '~/modules/testRun/testRun.selectors';
import Sidebar from '~/views/TestDetails/components/TestSidebar';

import { TestRunContent } from './components/Content/TestRunContent';

const TestRunDetails = () => {
  const currentStepId = useAppSelector(selectCurrentStepId) as Step['id'];
  const testRunId = useAppSelector(selectSingleTestRunId);

  const renderSidebar = useCallback<SidebarViewProps['Sidebar']>(
    ({ onToggle, collapsed }) => (
      <Sidebar
        onToggle={onToggle}
        context="testRun"
        readOnly
        collapsed={collapsed}
        testRunId={testRunId}
      />
    ),
    [testRunId],
  );

  return (
    <SidebarView
      prefix="testRunDetails"
      sidebarAlwaysVisible={!!currentStepId}
      Content={TestRunContent}
      Sidebar={renderSidebar}
    />
  );
};

export default TestRunDetails;
