import Loader from '@bugbug/core/components/Loader';
import { useCallback, useRef, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMount, useUnmount } from 'react-use';

import type { StepsBaseListRef } from '../StepsBaseList/StepsBaseList.types';
import type { StepsScrollBehaviorRef } from '../StepsScrollBehavior/StepsScrollBehavior';

import type { Step } from '@bugbug/core/types/steps';
import type { Test } from '@bugbug/core/types/tests';
import type { UserFlags } from '@bugbug/core/types/users';
import type { Maybe } from '@bugbug/core/types/utils';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useBackUrl } from '~/hooks/useBackUrl';
import useExtensionState from '~/hooks/useExtensionState';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import { StepsActions } from '~/modules/steps/steps.redux';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import {
  selectTestIsRecording,
  selectIsSingleTestLoading,
  selectSingleTest,
  selectChangedNotificationByTestId,
  selectTestSteps,
} from '~/modules/test/test.selectors';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import { useLazyGetTestQuery } from '~/modules/tests/tests.api';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import { selectHighlightEditAndRewindMode } from '~/modules/uiState/uiState.selectors';
import { selectUserFlags } from '~/modules/user/user.selectors';

import { StepsList } from '../StepsList/StepsList';
import { StepsScrollBehavior } from '../StepsScrollBehavior/StepsScrollBehavior';
import { TestHeader } from '../TestHeader/TestHeader';

import * as S from './TestContent.styled';

export const TestContent = memo(() => {
  const stepsListRef = useRef<Maybe<StepsBaseListRef>>(null);
  const scrollStepBehavior = useRef<Maybe<StepsScrollBehaviorRef>>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { params } = useAppRoutes('test');
  const { testId } = params;
  const { testRunId } = useQueryString();
  const allTestSteps = useAppSelector(selectTestSteps) as Record<string, Step>;
  const modal = useModal();
  const backUrl = useBackUrl('testsList');
  const test = useAppSelector(selectSingleTest) as Test;
  const isSingleTestLoading = useAppSelector(selectIsSingleTestLoading) as boolean;
  const isReadOnly = useAppSelector(selectTestIsRecording) as boolean;
  const changedNotification = useAppSelector(selectChangedNotificationByTestId(testId)) as Maybe<{
    email: string;
  }>;
  const userFlags = useAppSelector(selectUserFlags) as UserFlags;
  const extension = useExtensionState();
  const highlightEditAndRewindMode = useAppSelector(selectHighlightEditAndRewindMode);

  const [getTest, { isLoading: isTestLoading, isError: isTestError }] = useLazyGetTestQuery();

  const stopRecordingRequestState = useActionState(TestActions.stopRecordingRequest, {
    reset: false,
  });

  const isLoading =
    isTestLoading || stopRecordingRequestState.isLoading || !test.groups || isSingleTestLoading;

  const handleDeselectAll = useCallback(() => {
    dispatch(UIStateActions.resetCheckedSteps());
    dispatch(StepsActions.resetCurrentStepId());
  }, [dispatch]);

  const refresh = useCallback(() => {
    dispatch(TestActions.clearChangedNotification(testId));
    getTest({
      id: testId,
      testRunId,
    });
  }, [dispatch, getTest, testId, testRunId]);

  useMount(() => {
    if (!userFlags.testRecorded && !extension.isActive) {
      modal.showTutorial('install_extension');
    }
  });

  useEffect(() => {
    getTest({
      id: testId,
    });
  }, [getTest, testId]);

  useEffect(() => {
    if (!testRunId) {
      dispatch(TestRunActions.clearSingle());
    }
  }, [dispatch, testRunId]);

  useUnmount(() => {
    dispatch(TestActions.clearSingleRequest());
    dispatch(TestActions.clearChangedNotification(testId));
    dispatch(TestRunActions.clearSingle());
    dispatch(StepsActions.resetCurrentStepId());
    stopRecordingRequestState.reset();
  });

  const handleNotificationClose = useCallback(() => {
    dispatch(TestActions.clearChangedNotification(testId));
  }, [dispatch, testId]);

  const handleErrorClick = useCallback(() => {
    scrollStepBehavior.current?.scrollToFailedStep();
  }, []);

  if (isTestError) {
    return <ServerErrorInfo isVisible onRetry={refresh} />;
  }

  if (isLoading) {
    return (
      <S.LoaderContainer>
        <Loader size="large" />
      </S.LoaderContainer>
    );
  }

  return (
    <S.Container
      title={
        <TestHeader
          readOnly={isReadOnly}
          onErrorClick={handleErrorClick}
          onDeselect={handleDeselectAll}
        />
      }
      data-testid="TestContent"
      withBackButton
      backUrl={backUrl}
    >
      <Helmet
        title={t('testDetails.pageTitle', 'Tests / {{ name }}', {
          name: test.name,
          interpolation: { escapeValue: false },
        })}
      />
      <S.Notification
        text={
          changedNotification
            ? t(
                'testDetails.changedNotification.text',
                'This test has just been updated by another user ({{email}}).',
                { email: changedNotification.email },
              )
            : null
        }
        buttonLabel={t(
          'testDetails.changedNotification.refreshButtonLabel',
          'Refresh to see changes',
        )}
        onClick={refresh}
        onClose={handleNotificationClose}
      />
      <StepsScrollBehavior
        ref={scrollStepBehavior}
        containerRef={stepsListRef}
        allTestSteps={allTestSteps}
      />
      <StepsList ref={stepsListRef} testRunId={testRunId} />
      {highlightEditAndRewindMode && (
        <S.EditAndRewindHighlight>
          <S.EditAndRewindHighlightHeader>
            {t('testDetails.editAndRewindMode', 'Edit & rewind mode')}
          </S.EditAndRewindHighlightHeader>
        </S.EditAndRewindHighlight>
      )}
    </S.Container>
  );
});

TestContent.displayName = 'TestContent';
