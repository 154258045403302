import { animated, useTransition } from '@react-spring/web';
import { useState, useCallback, useRef, memo, useEffect } from 'react';

import type { RowActiveBorderMenuRef } from './RowActiveBorderMenu/RowActiveBorderMenu';

import { useAppSelector } from '~/modules/store';
import { selectIsTestRunPaused } from '~/modules/test/test.selectors';

import * as S from './RowActiveBorder.styled';
import { RowActiveBorderMenu } from './RowActiveBorderMenu/RowActiveBorderMenu';

interface RowActiveBorderProps {
  className?: string;
  row: {
    id: string;
    groupId?: string;
    steps?: string[];
  };
  index: number;
  disabledGroupOptions?: boolean;
  disabledStepOptions?: boolean;
  disabledSplitting?: boolean;
  disabledRunAndStop?: boolean;
  testsCount: number;
  isComponent: boolean;
  opened: boolean;
  onClick: () => void;
  onClose: () => void;
}

export const RowActiveBorder = memo((props: RowActiveBorderProps) => {
  const {
    className,
    row = {
      id: '',
      groupId: '',
      steps: [],
    },
    index = 0,
    disabledGroupOptions = false,
    disabledSplitting = false,
    disabledStepOptions = false,
    disabledRunAndStop = false,
    testsCount = 0,
    isComponent,
    onClick: handleClick,
    onClose,
    opened,
  } = props;
  const menuRef = useRef<RowActiveBorderMenuRef>(null);
  const placeKeeperRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [forcedVisible, setForcedVisible] = useState(false);
  const isPaused = useAppSelector(selectIsTestRunPaused);

  const menuTransitions = useTransition(forcedVisible || visible, {
    from: { opacity: 0 },
    enter: { opacity: 1, immediate: true },
    leave: { opacity: 0 },
  });

  const lineTransitions = useTransition(forcedVisible || visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const handleDropdownToggle = useCallback(() => {
    setForcedVisible((current) => !current);
  }, []);

  useEffect(() => {
    if (!forcedVisible) {
      onClose();
    }
  }, [onClose, forcedVisible]);

  const hide = useCallback(() => setVisible(false), []);

  const show = useCallback(() => setVisible(true), []);

  useEffect(() => {
    if (opened && menuRef.current) {
      menuRef.current.open();
    }
  }, [opened]);

  return (
    <S.PlaceKeeper
      ref={placeKeeperRef}
      className={className}
      data-testid="RowActiveBorder"
      onMouseLeave={hide}
      onClick={handleClick}
    >
      <S.Container data-testid="RowActiveBorder.Container">
        <S.LineWrapper onMouseEnter={show} data-testid="RowActiveBorder.LineWrapper">
          {lineTransitions(
            (styles, isVisible) =>
              isVisible &&
              (isPaused ? (
                <S.PausedRecordingLine
                  style={styles}
                  data-testid="RowActiveBorder.PausedRecordingLine"
                />
              ) : (
                <S.Line style={styles} data-testid="RowActiveBorder.Line" />
              )),
          )}
        </S.LineWrapper>
        {menuTransitions(
          (styles, isVisible) =>
            isVisible && (
              <animated.div style={styles} onMouseEnter={show}>
                <RowActiveBorderMenu
                  ref={menuRef}
                  row={row}
                  index={index}
                  disabledGroupOptions={disabledGroupOptions}
                  disabledSplitting={disabledSplitting}
                  disabledStepOptions={disabledStepOptions}
                  disabledRunAndStop={disabledRunAndStop}
                  testsCount={testsCount}
                  componentMode={isComponent}
                  onToggle={handleDropdownToggle}
                />
              </animated.div>
            ),
        )}
      </S.Container>
    </S.PlaceKeeper>
  );
});

RowActiveBorder.displayName = 'RowActiveBorder';
