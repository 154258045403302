import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDocsIcon = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 46, height: 44, viewBox: "0 0 46 44", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M1.05029 0.142578H17.2866V3.85767H20.4513L23.0657 6.3344V35.6423H1.05029V0.142578Z", fill: "white" }),
  /* @__PURE__ */ jsx("rect", { x: 0.5, y: 0.142578, width: 2.20154, height: 35.4998, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 2.70093, y: 0.142578, width: 14.5852, height: 1.92634, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2866, y: 2.06836, width: 3.3023, height: 1.92634, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 20.5894, y: 3.99414, width: 3.3023, height: 1.92634, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 6.55396, y: 7.84766, width: 10.7325, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 6.55396, y: 11.1504, width: 10.7325, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 6.05422, height: 1.92634, transform: "matrix(-1 0 0 1 12.6082 7.84766)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 6.05422, height: 1.92634, transform: "matrix(-1 0 0 1 12.6082 11.1504)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 6.55396, y: 14.4531, width: 10.7325, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 6.55396, y: 17.4785, width: 10.7325, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 10.6821, y: 20.7812, width: 6.60461, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 20.5894, y: 31.7891, width: 3.3023, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 17.2866, y: 29.8633, width: 3.3023, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 2.70093, y: 27.9375, width: 14.5852, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 2.70093, y: 33.7168, width: 21.1898, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 23.8909, y: 5.92188, width: 25.868, height: 1.92634, transform: "rotate(90 23.8909 5.92188)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("path", { d: "M44.8059 0.142578H28.5696V3.85767H25.4049L22.7905 6.3344V35.6423H44.8059V0.142578Z", fill: "white" }),
  /* @__PURE__ */ jsx("rect", { width: 2.20154, height: 35.4998, transform: "matrix(-1 0 0 1 45.356 0.142578)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 14.5852, height: 1.92634, transform: "matrix(-1 0 0 1 43.155 0.142578)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { width: 3.3023, height: 1.92634, transform: "matrix(-1 0 0 1 28.5696 2.06836)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { width: 3.3023, height: 1.92634, transform: "matrix(-1 0 0 1 25.2666 3.99414)", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 27.1938, y: 7.84766, width: 4.12788, height: 5.22865, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 33.248, y: 7.84766, width: 6.05422, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 33.248, y: 11.1504, width: 6.05422, height: 1.92634, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 10.7325, height: 1.92634, transform: "matrix(-1 0 0 1 39.302 14.4531)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 10.7325, height: 1.92634, transform: "matrix(-1 0 0 1 39.302 17.4785)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 6.60461, height: 1.92634, transform: "matrix(-1 0 0 1 35.1738 20.7812)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 3.3023, height: 1.92634, transform: "matrix(-1 0 0 1 25.2666 31.7891)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 3.3023, height: 1.92634, transform: "matrix(-1 0 0 1 28.5696 29.8633)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 14.5852, height: 1.92634, transform: "matrix(-1 0 0 1 43.155 27.9375)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 21.1898, height: 1.92634, transform: "matrix(-1 0 0 1 43.155 33.7168)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 25.868, height: 1.92634, transform: "matrix(4.37114e-08 1 1 -4.37114e-08 21.9653 5.92188)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 11.2829, height: 1.92634, transform: "matrix(-4.37114e-08 1 1 4.37114e-08 32.6975 31.7891)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 9.35653, height: 1.92634, transform: "matrix(-4.37114e-08 1 1 4.37114e-08 37.376 31.7891)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.75192, height: 1.92634, transform: "matrix(1 0 0 -1 34.6233 43.0723)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 3.85269, height: 1.92634, transform: "matrix(1 0 0 -1 34.073 33.7168)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.75192, height: 7.43018, transform: "matrix(1 0 0 -1 34.6233 41.1465)", fill: "#FDCE1C" })
] });
const ForwardRef = forwardRef(SvgDocsIcon);
export default ForwardRef;
