import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.border.semi};
  border-radius: 8px;
  padding: 24px 32px 24px 24px;
  flex: 1;
  min-height: 216px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const Description = styled.p`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.normal};
  line-height: 1.5;
  margin: auto 0 16px 0;
`;
