import IconButton from '@bugbug/core/components/IconButton';
import styled from 'styled-components';

export const FullScreenIconButton = styled(IconButton).attrs(() => ({ iconName: 'fullScreen' }))`
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${({ theme }) => theme.colors.neutrals[80]};
  fill: none;

  &:hover {
    color: ${({ theme }) => theme.colors.text.normal};
  }
`;
