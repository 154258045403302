import { values } from 'ramda';

export const FIELD_NAMES = {
  RUN_TIMEOUT: 'runTimeout',
  SLEEP: 'sleep',
  CONTINUE_ON_FAILURE: 'continueOnFailure',
  IS_ACTIVE: 'isActive',
};

export const FIELD_NAMES_VALUES = values(FIELD_NAMES);
