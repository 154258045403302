import styled from 'styled-components';

import { Content as ModalContent } from '~/components/modals/Modal';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled(ModalContent)`
  position: relative;
  padding-bottom: 33px;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.common.white};
  z-index: 2;
`;
