import Tooltip from '@bugbug/core/components/Tooltip';

import type { ReactNode } from 'react';

import { Container, Label, Description, HelperIcon, LabelWithHelper } from './FormField.styled';

interface FormFieldProps {
  className?: string;
  children: ReactNode;
  label?: string;
  labelId?: string;
  description?: string;
  helperText?: string;
  counter?: string;
  disabled?: boolean;
  'data-testid'?: string;
}

const FormField = ({
  className,
  children,
  counter,
  label = '',
  description = '',
  helperText = '',
  labelId = label,
  disabled = false,
  'data-testid': dataTestId = 'FormField',
}: FormFieldProps) => (
  <Container className={className} data-testid={dataTestId} data-disabled={disabled}>
    {!!label && !helperText && (
      <Label id={labelId}>
        {counter && <div>{counter}</div>}
        {label}
      </Label>
    )}
    {!!label && !!helperText && (
      <Tooltip content={disabled ? '' : helperText}>
        <LabelWithHelper>
          <Label id={labelId}>
            {counter && <div>{counter}</div>}
            {label}
          </Label>
          <HelperIcon />
        </LabelWithHelper>
      </Tooltip>
    )}
    {description && <Description>{description}</Description>}
    {children}
  </Container>
);

export default FormField;
