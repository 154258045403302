import type { ISODate, UUID } from './aliases';
import type { RunErrorFlags, RunStatusFlags } from './tests';
import type { User } from './users';
import type { Maybe } from './utils';

export type RunMode = 'local' | 'server';

export type RecordingOrPausedStatus = 'recording' | 'paused';
export type RunStatus = RunFinishedStatus | RunPendingStatus;

type RunFinishedStatus = RunSuccessStatus | RunErrorStatus;
type RunPendingStatus = 'running' | 'queued' | 'initialized' | RecordingOrPausedStatus;
type RunErrorStatus = 'error' | 'failed';
type RunSuccessStatus = 'passed' | 'debugging' | 'stopped';

// BE statuses extended with statuses for specific cases
export type ExtendedRunStatus =
  | RunStatus
  | 'passed-with-issues'
  | 'failed-ignored'
  | 'failed-retried'
  | 'just-edited';

export type RunTriggeredBy = 'api' | 'scheduler' | 'user';

export interface RunResult {
  id: UUID;
  started: Maybe<ISODate>;
  sequence: number;
  status: RunStatus;
  user: User['id'];
}

export type StepRunFinalStatusFlags =
  | PassedStatusFlags
  | PassedWithIssuesStatusFlags
  | FailedStatusFlags
  | IgnoredFailedStatusFlags
  | CommonStatusFlags;

export interface PassedWithIssuesStatusFlags extends CommonStatusFlags {
  status: 'passed';
  warning: true;
  ignoredFailed: false;
}

interface PassedStatusFlags extends CommonStatusFlags {
  status: 'passed';
  warning: false;
  ignoredFailed: false;
}

interface FailedStatusFlags extends CommonStatusFlags {
  status: 'failed';
  warning: false;
  ignoredFailed: false;
}

export interface IgnoredFailedStatusFlags extends CommonStatusFlags {
  status: 'failed';
  warning: false;
  ignoredFailed: true;
}

type CommonStatusFlags = RunStatusFlags & RunErrorFlags;

export const isRunningStatus = (status: ExtendedRunStatus): status is RunPendingStatus =>
  ['running', 'queued', 'initialized'].includes(status);

export const isFailedStatus = (status: ExtendedRunStatus): status is RunErrorStatus =>
  ['error', 'failed'].includes(status);

export const isFinishedStatus = (status: ExtendedRunStatus): status is RunFinishedStatus =>
  !isRunningStatus(status);

export const isEndStatus = (status: ExtendedRunStatus): status is RunFinishedStatus =>
  !isRunningStatus(status) && !isRecordingOrPausedStatus(status);

export const isRecordingOrPausedStatus = (
  status: ExtendedRunStatus | RecordingOrPausedStatus,
): status is RecordingOrPausedStatus => ['recording', 'paused'].includes(status);
