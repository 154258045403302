import mixins from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

import { Content as ContentBase } from '../Modal';

export const Content = styled(ContentBase)`
  overflow: hidden;
  max-width: 100ch;
  max-height: 60vh;
  display: flex;
  flex-direction: column;

  header {
    ${mixins.layout.flexCenter};
    justify-content: space-between;
    padding-right: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.medium};

    > div {
      ${mixins.layout.flexCenter};
      gap: 16px;
    }

    button {
      min-width: 100px;

      > div {
        flex: none;
      }
    }
  }

  section {
    flex: 1;
    overflow: auto;

    h2 {
      font-size: 14px;
      margin-bottom: 16px;
    }
  }

  article {
    padding-right: 50px;
  }

  aside {
    div {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
`;

export const ScrollableContent = styled.div`
  padding-top: 25px;
  overflow: auto;
`;

export const TwoColumnContent = styled.div`
  ${mixins.layout.gridTwoColumns};
  grid-template-columns: 1fr 200px;
`;
