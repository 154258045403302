import * as Yup from 'yup';

import * as validators from '~/utils/validators';

import { COMPANY_ROLES, USER_SOURCES } from './user.constants';

export const SignInSchema = Yup.object().shape({
  email: validators.emailValidator,
  password: validators.passwordValidator,
});

export const SignUpSchema = Yup.object().shape({
  email: validators.emailValidator,
  password1: validators.passwordValidator,
  isAllowedEmailMarketing: Yup.bool().optional(),
});

export type SignUpDto = Yup.InferType<typeof SignUpSchema>;

export const ProfileSetupSchema = Yup.object().shape({
  firstName: validators.nameValidator.optional(),
  lastName: validators.nameValidator.optional(),
  organization: Yup.object().shape({
    companyName: validators.nameValidator.nullable().optional(),
  }),
  role: Yup.string()
    .nullable()
    .oneOf([...COMPANY_ROLES, null])
    .optional(),
  source: Yup.string()
    .nullable()
    .oneOf([...USER_SOURCES, null])
    .optional(),
});
