import { equals } from 'ramda';
import * as Yup from 'yup';

import { VARIABLE_TYPE } from '~/constants/variables';
import { valueNameValidator, idValidator, VALIDATION_MESSAGE } from '~/utils/validators';

const baseValueSchema = Yup.object().shape({
  profileId: Yup.string().required(),
  profile: Yup.string().required(),
  placeholder: Yup.string().nullable(),
  value: Yup.string().nullable().max(5000, VALIDATION_MESSAGE.MAX_LENGTH),
});

const customJSSchema = Yup.string()
  .nullable()
  .when('isDefault', {
    is: true,
    then: (s) => s.required(VALIDATION_MESSAGE.REQUIRED),
  });

const BaseSchema = Yup.object().shape({
  name: Yup.string().optional(),
  key: valueNameValidator,
  type: Yup.string().oneOf(Object.values(VARIABLE_TYPE)).default(VARIABLE_TYPE.VALUE),
  hasSecretValue: Yup.boolean().nullable().default(false),
  description: Yup.string().max(256, VALIDATION_MESSAGE.MAX_LENGTH).nullable().default(null),
  value: Yup.array().when('type', {
    is: equals(VARIABLE_TYPE.EVALUATE),
    then: (s) =>
      s.of(
        baseValueSchema.shape({
          value: customJSSchema,
        }),
      ),
    otherwise: (s) => s.of(baseValueSchema),
  }),
});

export const VariableEditSchema = BaseSchema.shape({
  id: idValidator,
});
export const VariableCreateSchema = BaseSchema.shape({});
