import { BUTTON_VARIANT, ActionButton } from '@bugbug/core/components/Button';
import Dropdown, { DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { PointerEventsCatcher } from '@bugbug/core/components/Tooltip';
import { COLOR } from '@bugbug/core/theme';
import styled, { css } from 'styled-components';

import { OnboardingTitle } from '~/views/Onboarding/Onboarding.styled';

export const Container = styled.div`
  display: flex;

  ${PointerEventsCatcher} + ${PointerEventsCatcher} {
    margin-left: 9px;
  }
`;

export const CombinedButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CombinedButtonsSeparator = styled.div<{ disabled?: boolean }>`
  width: 2px;
  height: 100%;
  background-color: ${({ theme, disabled }) => (disabled ? COLOR.GRAY_7 : theme.colors.primary)};
`;

export const CombinedButton = styled(ActionButton).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  bordered: true,
}))<{ roundCorners?: 'left' | 'right' }>`
  padding: 10px 12px;
  justify-content: center;
  margin: 0;

  ${(props) =>
    !props.roundCorners &&
    css`
      border-radius: 0;
      border-right-width: 0;
      border-left-width: 0;
    `}

  ${(props) =>
    props.roundCorners === 'left' &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    `}

  ${(props) =>
    props.roundCorners === 'right' &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
    `}
`;

export const NextStepButton = styled(CombinedButton)`
  width: 42px;
  padding-left: 21px;
`;

export const RecordButton = styled(CombinedButton)`
  svg {
    font-size: 16px;
  }
`;

export const Menu = styled(Dropdown).attrs(() => ({
  variant: DROPDOWN_VARIANT.PRIMARY,
}))`
  margin-left: 9px;
`;

export const CloudRunIcon = styled(Icon).attrs(() => ({
  name: 'cloudRun',
}))`
  width: 100%;
  height: 100%;
`;

export const TooltipContainer = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 320px;
`;

export const TooltipTitle = styled(OnboardingTitle)`
  text-align: left;
`;

export const TooltipSubtitle = styled.p`
  margin: 0;
`;
