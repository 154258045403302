import * as CopyButton from '@bugbug/core/components/CopyButton';
import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20px;
  position: relative;

  &[data-disabled='true'] {
    opacity: 0.5;
  }

  > ${CopyButton.Button}, > ${CopyButton.IconButton} {
    margin-left: 0;
    margin-bottom: -8px;
  }
`;

export const Label = styled.label`
  line-height: 16px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  color: ${COLOR.DARK_GRAY};
  width: 100%;
  margin: 0;
  padding-bottom: 5px;

  div {
    background: ${COLOR.GRAY_9};
    color: ${COLOR.GRAY_36};
    border-radius: 100%;
    flex: none;
    height: auto;
    padding: 0px 5px 1px;
    margin-right: 6px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 8px;
  }
`;

export const Description = styled.p`
  padding-top: 1px;
  color: ${COLOR.GRAY_19};
  line-height: 16px;
  margin: 0;
  padding-bottom: 8px;
`;

export const HelperIcon = styled(Icon).attrs(() => ({
  name: 'help',
}))`
  margin-left: 6px;
  color: #858585;
  width: 14px;
  top: -2px;
  position: relative;
`;

export const LabelWithHelper = styled.div`
  display: flex;
  align-items: center;

  ${Label} {
    width: auto;
  }
`;
