import { joinAllArgs } from '@bugbug/core/utils/toolbox';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { Group } from '@bugbug/core/types/groups';
import type { Step } from '@bugbug/core/types/steps';
import type { Test, TestRun } from '@bugbug/core/types/tests';
import { axiosBaseQuery } from '~/services/api/baseQuery';

export const TAG = 'Step' as const;

export const stepsApi = createApi({
  reducerPath: 'stepsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/steps/' }),
  tagTypes: [TAG],

  endpoints: (builder) => ({
    // GET /steps/<stepId>/
    getStepDetails: builder.query<Step, { id: Step['id']; testRunId?: TestRun['id'] }>({
      query: ({ id, testRunId }) => ({
        url: `${id}/`,
        method: 'GET',
        params: testRunId ? { testRunId } : undefined,
      }),
      keepUnusedDataFor: 30,
      providesTags: (result, error, arg) =>
        result
          ? [{ type: TAG, id: joinAllArgs(arg.id, arg.testRunId) }]
          : [{ type: TAG, id: 'ELEMENT' }],
    }),

    // It's related to the group, so maybe in groups?
    // POST /steps/<stepId>/
    createStep: builder.mutation<
      Step,
      { step: Step; testId: Test['id']; groupId: Group['id']; atIndex: number }
    >({
      query: ({ step }) => ({
        url: `${step.id}/`,
        method: 'POST',
      }),
      invalidatesTags: [TAG],
    }),

    // saveStepSettings in test.sagas
    // PUT /steps/<stepId>/
    updateStep: builder.mutation<Step, { id: Step['id']; data: Partial<Step> }>({
      query: ({ id }) => ({
        url: `${id}/`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAG, id }],
    }),

    // DELETE /steps/<stepId>/
    deleteStep: builder.mutation<Step, { id: Step['id'] }>({
      query: ({ id }) => ({
        url: `${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG],
    }),

    // POST /steps/<stepId>/clone/
    cloneStep: builder.mutation<Step, { id: Step['id'] }>({
      query: ({ id }) => ({
        url: `${id}/clone/`,
        method: 'POST',
      }),
      invalidatesTags: [TAG],
    }),
  }),
});

export const {
  useGetStepDetailsQuery,
  useCreateStepMutation,
  usePrefetch: usePrefetchSteps,
} = stepsApi;
