/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { CommonActions } from '@bugbug/core/actions/actions';
import * as T from '@bugbug/core/utils/toolbox';
import { createSlice } from '@reduxjs/toolkit';

import type { PendingActionName, SelectedStep } from './uiState.types';
import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';

import type { Group } from '@bugbug/core/types/groups';
import type { IntegrationTrigger } from '@bugbug/core/types/integrations';
import type { PlaybackCursorPosition } from '@bugbug/core/types/playback';
import type { ProjectSettingsTab } from '@bugbug/core/types/projects';
import type { Step } from '@bugbug/core/types/steps';
import type { RunsHistoryTab, Test } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';
import type { VariablesTab } from '@bugbug/core/types/variables';

import { RUN_ENV } from '../constans';
import { TestTypes } from '../test/test.redux';
import { TestRunTypes } from '../testRun/testRun.redux';
import { BackendActions } from '../websocket/backend.actions';

export interface UIState {
  components: Record<string, object>;
  collapsedGroups: Record<Test['id'], Group['id'][]>;
  draggedStepId: Maybe<Step['id']>;
  ignoreStopTestAlert: boolean;
  playback: {
    cursorPosition: Maybe<PlaybackCursorPosition>;
    selectedPositions: {
      pause: Maybe<{
        afterStepId: Maybe<Step['id']>;
        atGroupId: Group['id'];
      }>;
      recorderStart: Maybe<{
        afterStepId: Maybe<Step['id']>;
        atGroupId: Group['id'];
      }>;
    };
    isDraggingCursor: boolean;
  };
  sessionsIds: Record<Test['id'], string>;
  integrationTrigger?: IntegrationTrigger;
  recentlySelectedTabs: {
    projectSettings: ProjectSettingsTab;
    runsHistory: RunsHistoryTab;
    variables: VariablesTab;
  };
  recentlyAddedStepsIds: Step['id'][];
  pendingAction: Maybe<PendingActionName>;
  lastFocusedStepIdCheckbox: Maybe<SelectedStep>;
  checkedSteps: SelectedStep[];
  highlightEditAndRewindMode: boolean;
}

export const initialUIState: UIState = {
  components: {},
  collapsedGroups: {},
  recentlySelectedTabs: {
    projectSettings: 'general',
    runsHistory: 'testRunsList',
    variables: 'builtInVariables',
  },
  lastFocusedStepIdCheckbox: null,
  checkedSteps: [],
  ignoreStopTestAlert: false,
  draggedStepId: null,
  playback: {
    cursorPosition: null,
    selectedPositions: {
      pause: null,
      recorderStart: null,
    },
    isDraggingCursor: false,
  },
  recentlyAddedStepsIds: [],
  sessionsIds: {},
  pendingAction: null,
  highlightEditAndRewindMode: false,
};

const uiStateSlice = createSlice({
  name: 'uiState',
  initialState: initialUIState,
  reducers: {
    setUIState(state, action: PayloadAction<{ componentName: string; data: object }>) {
      state.components[action.payload.componentName] = {
        ...state.components[action.payload.componentName],
        ...action.payload.data,
      };
    },

    forgetCollapsedGroup(
      state,
      action: PayloadAction<{ collapsedGroupId: Group['id']; testId: Test['id'] }>,
    ) {
      state.collapsedGroups[action.payload.testId] = T.removeArrayItem(
        state.collapsedGroups[action.payload.testId],
        action.payload.collapsedGroupId,
      );
    },

    setCollapsedGroups(
      state,
      action: PayloadAction<{ collapsedGroupsIds: Group['id'][]; testId: Test['id'] }>,
    ) {
      state.collapsedGroups[action.payload.testId] = action.payload.collapsedGroupsIds;
    },

    toggleCollapsedGroup(
      state,
      action: PayloadAction<{ collapsedGroupId: Group['id']; testId: Test['id'] }>,
    ) {
      state.collapsedGroups[action.payload.testId] = T.toggleArrayItem(
        state.collapsedGroups[action.payload.testId],
        action.payload.collapsedGroupId,
      );
    },

    setIntegrationTrigger(state, action: PayloadAction<UIState['integrationTrigger']>) {
      state.integrationTrigger = action.payload;
    },

    removeIntegrationTrigger(state) {
      delete state.integrationTrigger;
    },

    setProjectSettingsTab(state, action: PayloadAction<ProjectSettingsTab>) {
      state.recentlySelectedTabs.projectSettings = action.payload;
    },

    setRunsHistoryTab(state, action: PayloadAction<RunsHistoryTab>) {
      state.recentlySelectedTabs.runsHistory = action.payload;
    },

    setVariablesTab(state, action: PayloadAction<VariablesTab>) {
      state.recentlySelectedTabs.variables = action.payload;
    },

    setPlaybackDrag(state, action: PayloadAction<{ isDragging: boolean }>) {
      state.playback.isDraggingCursor = action.payload.isDragging;
    },

    setSessionId(state, action: PayloadAction<{ testId: Test['id']; sessionId: string }>) {
      const { testId, sessionId } = action.payload;
      state.sessionsIds[testId] = sessionId;
    },

    addRecentlyAddedStepsIds(state, action: PayloadAction<{ stepsIds: Step['id'][] }>) {
      state.recentlyAddedStepsIds.push(...action.payload.stepsIds);
    },

    resetRecentlyAddedStepsIds(state) {
      state.recentlyAddedStepsIds = [];
    },

    setDraggedStepId(state, action: PayloadAction<{ stepId: Maybe<Step['id']> }>) {
      state.draggedStepId = action.payload.stepId;
    },

    setIgnoreStopTestAlert(state, action: PayloadAction<{ ignore: boolean }>) {
      state.ignoreStopTestAlert = action.payload.ignore;
    },

    disablePlaybackSelectedPositions(state) {
      state.playback.selectedPositions.pause = null;
      state.playback.selectedPositions.recorderStart = null;
    },

    setPendingAction: (state, action: PayloadAction<{ name: UIState['pendingAction'] }>) => {
      state.pendingAction = action.payload.name;
    },

    resetPendingAction: (state) => {
      state.pendingAction = null;
    },

    setCheckedSteps(
      state,
      action: PayloadAction<{
        steps: SelectedStep[];
        checked: boolean;
      }>,
    ) {
      const { steps, checked } = action.payload;
      if (checked) {
        const newSteps = steps.filter(
          ({ id, groupId }) =>
            !state.checkedSteps.some((s) => s.id === id && s.groupId === groupId),
        );
        state.checkedSteps.push(...newSteps);
      } else {
        state.checkedSteps = state.checkedSteps.filter(
          (checkedStep) =>
            !steps.some(
              (payloadStep) =>
                payloadStep.id === checkedStep.id && payloadStep.groupId === checkedStep.groupId,
            ),
        );
      }
    },

    setLastFocusedStepCheckbox(state, action: PayloadAction<{ step: Maybe<SelectedStep> }>) {
      state.lastFocusedStepIdCheckbox = action.payload.step;
    },

    resetCheckedSteps(state) {
      state.checkedSteps = [];
    },

    setHighlightEditAndRewindMode(state, action: PayloadAction<{ highlight: boolean }>) {
      state.highlightEditAndRewindMode = action.payload.highlight;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(BackendActions.testUpdated, (state, action) => {
        const { steps } = action.payload;
        state.recentlyAddedStepsIds.push(...steps.added.map((step) => step.id));

        const latestStep = steps.added[steps.added.length - 1];
        if (latestStep) {
          state.playback.cursorPosition = {
            afterStep: {
              id: latestStep.id,
              groupId: latestStep.groupId,
            },
            runningStep: null,
            nextStep: null,
          };
        }
      })
      .addCase(CommonActions.setPlaybackCursorPosition, (state, action) => {
        state.playback.cursorPosition = action.payload;
      })
      .addCase(BackendActions.sessionPauseRequested, (state, action) => {
        state.playback.cursorPosition = {
          ...action.payload,
          runningStep: null,
        };
        state.playback.selectedPositions.pause = null;
      })
      .addCase(CommonActions.setPausePosition, (state, action) => {
        state.playback.selectedPositions.pause = action.payload;
      })
      .addCase(CommonActions.setRecordingStartPosition, (state, action) => {
        state.playback.selectedPositions.recorderStart = action.payload;
      })
      .addCase(BackendActions.recordingStarted, (state, action) => {
        const { afterStepId, intoGroupId } = action.payload;
        state.playback.cursorPosition = {
          afterStep: {
            id: afterStepId,
            groupId: intoGroupId,
          },
          runningStep: null,
          nextStep: null,
        };
        state.playback.selectedPositions.recorderStart = null;
      })
      .addMatcher(isPendingActionType, (state, action) => {
        state.pendingAction = getPendingActionName(action);
      })
      .addMatcher(isResetPendingActionType, (state) => {
        state.pendingAction = null;
      });
  },
});

export const UIStateActions = uiStateSlice.actions;

export default uiStateSlice.reducer;

// Helpers
const isPendingActionType = (action: AnyAction): boolean =>
  [
    TestTypes.START_RECORDING_REQUEST,
    TestTypes.START_RUNNING_REQUEST,
    TestTypes.UPDATE_REQUEST,
    TestTypes.STOP_RUNNING_REQUEST,
    TestTypes.STOP_RECORDING_REQUEST,
  ].includes(action.type);

const isResetPendingActionType = (action: AnyAction): boolean =>
  [
    TestTypes.STOP_RECORDING_SUCCESS,
    TestTypes.STOP_RECORDING_FAILURE,
    TestTypes.START_RUNNING_SUCCESS,
    TestTypes.START_RUNNING_FAILURE,
    TestTypes.UPDATE_SUCCESS,
    TestTypes.UPDATE_FAILURE,
    TestTypes.SET_RECORDING_STATE_SUCCESS,
    TestRunTypes.STOPPED,
  ].includes(action.type);

const getPendingActionName = (action: AnyAction): PendingActionName => {
  const actionMap: Record<string, PendingActionName> = {
    [TestTypes.START_RECORDING_REQUEST]: 'record',
    [TestTypes.START_RUNNING_REQUEST]:
      action.params?.runMode === RUN_ENV.SERVER ? 'runCloud' : 'runLocal',
    [TestTypes.STOP_RECORDING_REQUEST]: 'stop',
    [TestTypes.STOP_RUNNING_REQUEST]: 'stop',
    [TestTypes.UPDATE_REQUEST]: 'updateProfile',
  };

  return actionMap[action.type];
};
