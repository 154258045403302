import Loader from '@bugbug/core/components/Loader';
import { isEmpty } from '@bugbug/core/utils/toolbox';
import { useCallback, useRef, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMount, useUnmount } from 'react-use';

import type { StepsBaseListRef } from '~/views/TestDetails/components/StepsBaseList/StepsBaseList.types';
import type { StepsScrollBehaviorRef } from '~/views/TestDetails/components/StepsScrollBehavior/StepsScrollBehavior';

import type { Step } from '@bugbug/core/types/steps';
import type { TestRun } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useBackUrl } from '~/hooks/useBackUrl';
import { StepsActions } from '~/modules/steps/steps.redux';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import {
  selectSingleTestRun,
  selectSingleTestRunTestArchive,
  selectTestRunSteps,
} from '~/modules/testRun/testRun.selectors';
import StepsBaseList from '~/views/TestDetails/components/StepsBaseList';
import { StepsScrollBehavior } from '~/views/TestDetails/components/StepsScrollBehavior/StepsScrollBehavior';
import {
  Container,
  LoaderContainer,
} from '~/views/TestDetails/components/TestContent/TestContent.styled';

import { TestRunHeader } from '../TestRunHeader/TestRunHeader';

export const TestRunContent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    params: { testRunId },
  } = useAppRoutes('testRun');
  const backUrl = useBackUrl('testRunsList');

  const stepsListRef = useRef<Maybe<StepsBaseListRef>>(null);
  const scrollStepBehavior = useRef<Maybe<StepsScrollBehaviorRef>>(null);
  const testRun = useAppSelector(selectSingleTestRun) as unknown as TestRun & { test: string };
  const test = useAppSelector(selectSingleTestRunTestArchive);
  const allTestRunSteps = useAppSelector(selectTestRunSteps) as unknown as Record<string, Step>;
  const getSingleRequestState = useActionState(TestRunActions.getSingleRequest, { reset: false });
  const isLoading = getSingleRequestState.isLoading || !test || !testRun;

  useMount(() => {
    dispatch(TestRunActions.getSingleRequest(testRunId));
  });

  useUnmount(() => {
    dispatch(StepsActions.resetCurrentStepId());
    dispatch(TestRunActions.clearSingle());
  });

  const refresh = useCallback(() => {
    getSingleRequestState.reset();
    dispatch(TestRunActions.getSingleRequest(testRunId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, testRunId]);

  const handleErrorClick = useCallback(() => {
    scrollStepBehavior.current?.scrollToFailedStep();
  }, []);

  const Header = useMemo(
    () =>
      !isEmpty(test) &&
      !isEmpty(testRun) && (
        <TestRunHeader test={test} testRun={testRun} onErrorClick={handleErrorClick} />
      ),
    [handleErrorClick, test, testRun],
  );

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader size="large" />
      </LoaderContainer>
    );
  }

  if (getSingleRequestState.hasInternalServerError) {
    return <ServerErrorInfo isVisible onRetry={refresh} />;
  }

  return (
    <Container title={Header} data-testid="TestRunDetails" withBackButton backUrl={backUrl}>
      <Helmet
        title={t('testRunDetails.pageTitle', 'Test runs / {{- name }}', { name: test?.name })}
      />
      <StepsScrollBehavior
        ref={scrollStepBehavior}
        containerRef={stepsListRef}
        allTestSteps={allTestRunSteps}
      />
      <StepsBaseList
        ref={stepsListRef}
        // @ts-expect-error Invalid type. Remove this comment after migrating StepsBaseList to TS
        test={test}
        testRunId={testRun.id}
        readOnly
        runResultEnabled
      />
    </Container>
  );
};
