import RadioButton from '@bugbug/core/components/RadioButton';
import { ErrorMessage } from '@bugbug/core/theme/typography';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import * as T from '@bugbug/core/utils/toolbox';
import { useStripe, useElements, CardElement as StripeCardElement } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import useActionState from '~/hooks/useActionState';
import { OrganizationActions } from '~/modules/organization/organization.redux';
import {
  selectActivePaymentMethod,
  selectSubscriptionByTypeAndPeriod,
} from '~/modules/organization/organization.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import * as validators from '~/utils/validators';

import PaymentSummary from './components/PaymentSummary';
import { STRIPE_ERROR_MESSAGE } from './PaymentForm.constants';
import {
  CardElement,
  CardWrapper,
  CardField,
  CardGroup,
  PreviousCard,
  PoweredByStripe,
} from './PaymentForm.styled';

const PaymentFormSchema = Yup.object({
  card: Yup.mixed().required(validators.VALIDATION_MESSAGE.REQUIRED),
});

const PaymentForm = ({
  className = null,
  planType,
  planPeriod,
  onSubmit = T.noop,
  renderSubmit,
  onError = T.noop,
  forceAdd = false,
  updateDefaultCard = false,
  showPaymentSummary = true,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activePaymentMethod = useSelector(selectActivePaymentMethod);
  const planDetails = useSelector(selectSubscriptionByTypeAndPeriod(planType, planPeriod));
  const [isNewCardRequested, setIsNewCardRequested] = useState(forceAdd || !activePaymentMethod.id);

  const stripe = useStripe();
  const elements = useElements();

  const handleOnSubmit = async (values, actions) => {
    if (!stripe || !elements) {
      return;
    }

    analytics.trackEvent(TRACK_EVENT_TYPE.COMPLETE_PAYMENT);
    if (isNewCardRequested) {
      const cardElement = elements.getElement(StripeCardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        const message = STRIPE_ERROR_MESSAGE[error.code] || error.message;
        actions.setFieldError('card', message);
        onError(message);
        return;
      }

      if (updateDefaultCard) {
        dispatch(OrganizationActions.updateDefaultCardRequest(paymentMethod.id));
      } else {
        dispatch(OrganizationActions.subscribeRequest(planDetails.plan, paymentMethod.id));
      }
    } else {
      dispatch(OrganizationActions.subscribeRequest(planDetails.plan, activePaymentMethod.id));
    }
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldError,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      card: forceAdd ? null : activePaymentMethod,
    },
    validationSchema: PaymentFormSchema,
    onSubmit: handleOnSubmit,
  });

  const handleSubmitSuccess = useCallback(() => {
    onSubmit();
    setSubmitting(false);
  }, [onSubmit, setSubmitting]);

  const handleSubmitError = useCallback(
    (requestErrors) => {
      onError(Object.values(requestErrors)[0] || true);
    },
    [onError],
  );

  const subscribeRequestState = useActionState(OrganizationActions.subscribeRequest, {
    onFailure: handleSubmitError,
    onSuccess: handleSubmitSuccess,
  });
  const updateDefaultCardRequestState = useActionState(
    OrganizationActions.updateDefaultCardRequest,
    {
      onFailure: handleSubmitError,
      onSuccess: handleSubmitSuccess,
    },
  );

  const handleCardChange = useCallback(
    (event) => {
      if (event.error) {
        const message = STRIPE_ERROR_MESSAGE[event.error.code];
        setFieldError('card', message);
        return;
      }
      if (!event.complete && event.empty) {
        setFieldError('card', validators.VALIDATION_MESSAGE.REQUIRED);
        return;
      }

      setFieldError('card', null);
      const preparedEvent = {
        target: {
          name: 'card',
          value: event.complete && !event.empty ? true : undefined,
        },
      };
      handleChange(preparedEvent);
      handleBlur(preparedEvent);
    },
    [setFieldError, handleChange, handleBlur],
  );

  const handleUsePrevCard = useCallback(() => {
    if (activePaymentMethod.id) {
      setFieldValue('card', activePaymentMethod);
    }
    setIsNewCardRequested(false);
  }, [activePaymentMethod, setFieldValue]);

  const handleUseNewCard = useCallback(() => {
    setFieldValue('card', null);
    setFieldTouched('card', false);
    setIsNewCardRequested(true);
  }, [setFieldValue, setFieldTouched]);

  const renderCardSwitch = renderWhenTrue(() => (
    <CardGroup aria-label="Card switch">
      <PreviousCard>
        <RadioButton onChange={handleUsePrevCard} checked={!isNewCardRequested}>
          {t(
            'accountSettings.billingAddress.form.card.previous.label',
            'Use previously added card - {{ brand }} xxxx xxxx xxxx {{ last4 }}',
            { brand: activePaymentMethod.brand, last4: activePaymentMethod.last4 },
          )}
        </RadioButton>
        <span>
          {t(
            'accountSettings.billingAddress.form.card.previous.details',
            'Expires {{ expMonth }}/{{ expYear }}',
            {
              expMonth: activePaymentMethod.expMonth,
              expYear: activePaymentMethod.expYear,
            },
          )}
        </span>
      </PreviousCard>
      <RadioButton onChange={handleUseNewCard} checked={isNewCardRequested}>
        {t('accountSettings.billingAddress.form.card.new.label', 'Add a new card')}
      </RadioButton>
    </CardGroup>
  ));

  const renderCardField = renderWhenTrue(() => (
    <CardField aria-label="New card field">
      <CardWrapper invalid={touched.card && !!errors.card}>
        <CardElement onChange={handleCardChange} />
      </CardWrapper>
      {touched.card && errors.card && <ErrorMessage>{errors.card}</ErrorMessage>}
    </CardField>
  ));

  const isSubmitDisabled = !stripe || !elements;
  const isLoading =
    subscribeRequestState.isLoading || updateDefaultCardRequestState.isLoading || isSubmitting;

  return (
    <form className={className} noValidate data-testid="PaymentForm" onSubmit={handleSubmit}>
      {renderCardSwitch(!forceAdd && !!activePaymentMethod.id)}
      {renderCardField(forceAdd || !activePaymentMethod.id || isNewCardRequested)}
      {showPaymentSummary && (
        <PaymentSummary
          priceWithTax={planDetails.priceWithTax}
          planPeriod={planDetails.planPeriod}
          taxPercent={planDetails.taxPercent}
        />
      )}
      {renderSubmit({
        submitProps: { pending: isLoading, disabled: isSubmitDisabled },
        stripeElement: <PoweredByStripe />,
      })}
    </form>
  );
};

export default PaymentForm;
