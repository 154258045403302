import { RUN_STATUS } from '@bugbug/core/constants/status';
import * as T from '@bugbug/core/utils/toolbox';
import compareVersions from 'compare-versions';

import config from '~/modules/config';
import i18n from '~/translations';

export const isFinalStatus = (status) =>
  [RUN_STATUS.FAILED, RUN_STATUS.PASSED, RUN_STATUS.STOPPED].includes(status);

export const getFileNameFromUrl = (url = '') => {
  if (!url.startsWith('http')) {
    return url;
  }
  const nameUrl = new URL(url);
  const urlWithoutQuery = `${nameUrl.origin}${nameUrl.pathname}`;
  return decodeURI(`${urlWithoutQuery.split('/').at(-1)}`.replace(/\?/g, ''));
};

export const getPageScreenshotUrl = (screenshotPath?: string) => {
  if (screenshotPath) {
    return config.MEDIA_URL + screenshotPath;
  }
  return '';
};

export const getScreenshotUrl = (url?: string) => {
  if (!url) {
    return '';
  }

  if (url.startsWith('data') || url.startsWith('http')) {
    return url;
  }

  return config.MEDIA_URL + url;
};

export const convertListToObject = T.indexBy(({ id }) => id);

export const getSelectedPartialsLabel = (stepsLength?: number, groupsLength?: number) => {
  const partials: string[] = [];

  if (groupsLength) {
    partials.push(
      i18n.t('default.selected.groups', {
        defaultValue_one: '{{count}} group',
        defaultValue_other: '{{count}} groups',
        count: groupsLength,
      }),
    );
  }

  if (stepsLength) {
    partials.push(
      i18n.t('default.selected.steps', {
        defaultValue_one: '{{count}} step',
        defaultValue_other: '{{count}} steps',
        count: stepsLength,
      }),
    );
  }

  return partials.join(i18n.t('default.selected.separator', ' and '));
};

export const getWholeNumberOrDecimal = (value: number | string) => Number(Number(value).toFixed(2));

export const hasAtMostVersion = (currentVersion: string, expectedVersion: string): boolean =>
  // if current version is lower than / equal to requested
  !!currentVersion && compareVersions(currentVersion, expectedVersion) !== 1;

export const hasAtLeastVersion = ({ current, expected }: { current?: string; expected: string }) =>
  current ? compareVersions(current, expected) >= 0 : false;

export const isNewPlaybackSupported = (currentVersion?: string) =>
  hasAtLeastVersion({
    current: currentVersion,
    expected: '9.0.0',
  });
