import { createApi } from '@reduxjs/toolkit/query/react';

import type { Project } from '@bugbug/core/types/projects';
import type { Schedule } from '@bugbug/core/types/schedules';
import { axiosBaseQuery } from '~/services/api/baseQuery';

const TAG = 'Schedules';
export const schedulesApi = createApi({
  reducerPath: 'schedulesApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/schedulers/' }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getSchedules: builder.query<
      Schedule[],
      { projectId: Project['id']; query?: string; ordering?: string }
    >({
      query: (params) => ({
        params,
        url: '',
        method: 'get',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: TAG, id } as const)), TAG]
          : [{ type: TAG, id: 'LIST' }],
    }),

    getSchedule: builder.query<Schedule, Schedule['id']>({
      query: (id) => ({
        url: `${id}/`,
        method: 'get',
      }),
      providesTags: (result) =>
        result ? [{ type: TAG, id: result.id } as const, TAG] : [{ type: TAG, id: 'LIST' }],
    }),

    createOrUpdateSchedule: builder.mutation<Schedule, { data; id?: string }>({
      query: ({ data, id }) => ({
        url: id ? `${id}/` : '',
        method: id ? 'PUT' : 'POST',
        data,
      }),
      invalidatesTags: [TAG],
    }),

    partialUpdateSchedule: builder.mutation<
      Schedule,
      { data: Partial<Schedule>; id: Schedule['id'] }
    >({
      query: ({ data, id }) => ({
        url: `${id}/`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [TAG],
    }),

    deleteSchedule: builder.mutation<void, string>({
      query: (id) => ({
        url: `${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG],
    }),

    cloneSchedule: builder.mutation<Schedule, string>({
      query: (id) => ({
        url: `${id}/clone/`,
        method: 'POST',
      }),
      invalidatesTags: [TAG],
    }),

    runSchedule: builder.mutation<Schedule, Pick<Schedule, 'id' | 'runProfileId'>>({
      query: ({ id, runProfileId }) => ({
        url: `${id}/run-now/`,
        method: 'POST',
        params: { runProfileId },
      }),
      invalidatesTags: [TAG],
    }),
  }),
});

export const {
  useGetSchedulesQuery,
  useGetScheduleQuery,
  useCreateOrUpdateScheduleMutation,
  useDeleteScheduleMutation,
  useCloneScheduleMutation,
  usePartialUpdateScheduleMutation,
  useRunScheduleMutation,
} = schedulesApi;
