import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { NativeIntegrationProps, ThirdPartyIntegrationProps } from './Integration.types';

import { PaidFeature } from '~/components/PaidFeatureGuard';
import { INTEGRATION_LOGO } from '~/constants/integrations';
import useAppRoutes from '~/hooks/useAppRoutes';
import analytics from '~/services/analytics';

import * as S from '../Integrations.styled';
import IntegrationStatus from '../IntegrationStatus';

export type IntegrationProps = NativeIntegrationProps | ThirdPartyIntegrationProps;

const Integration = ({
  type,
  width = 100,
  height = 35,
  description,
  isNative,
  docsUrl,
}: IntegrationProps) => {
  const { t } = useTranslation();
  const {
    params: { projectId },
    push,
  } = useAppRoutes('integrations');

  const Logo = INTEGRATION_LOGO[type];

  const handleClick = useCallback(() => {
    if (isNative) {
      push('integration', { type });
    } else {
      analytics.trackEvent('documentation_clicked', {
        documentation_name: type,
      });
      window.open(docsUrl, '_blank');
    }
  }, [docsUrl, isNative, type, push]);

  return (
    <S.Integration role="listitem" aria-label={type}>
      <Logo width={width} height={height} />
      <p>{description}</p>
      <S.IntegrationActions>
        {isNative ? (
          <>
            <IntegrationStatus type={type} projectId={projectId} />
            <PaidFeature feature="integrations">
              {(isDisabled) => (
                <S.IntegrationButton
                  bordered
                  disabled={isDisabled}
                  onClick={handleClick}
                  variant="primary"
                >
                  {t('integration.buttonManage', 'Manage')}
                </S.IntegrationButton>
              )}
            </PaidFeature>
          </>
        ) : (
          <S.IntegrationButton variant="primary" bordered onClick={handleClick}>
            {t('integration.buttonDocs', 'Read docs')}
          </S.IntegrationButton>
        )}
      </S.IntegrationActions>
    </S.Integration>
  );
};

export default Integration;
