// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore as configureStoreBase } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { path } from 'ramda';
import reduxCatch from 'redux-catch';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { alertsApi } from '~/modules/alerts/alerts.api';
import { componentsApi } from '~/modules/components/components.api';
import { CoreActions } from '~/modules/core/core.redux';
import history from '~/utils/history';

import { coreApi } from './core/core.api';
import { eventsApi } from './events/events.api';
import { groupsApi } from './groups/groups.api';
import { integrationsApi } from './integrations/integrations.api';
import { projectsApi } from './projects/projects.api';
import createReducer from './reducers';
import rootSaga from './sagas';
import { schedulesApi } from './schedules/schedules.api';
import { stepsApi } from './steps/steps.api';
import { suitesApi } from './suites/suites.api';
import { testsApi } from './tests/tests.api';
import { userApi } from './user/user.api';
import { websocketPrefix } from './websocket/websocket.redux';

const sagaMiddleware = createSagaMiddleware({
  onError(error) {
    Sentry.captureException(error);
  },
});

const errorCatchMiddleware = reduxCatch((error, getState, lastAction, dispatch) => {
  Sentry.captureException(error);
  dispatch(CoreActions.error());
});

export const persistedReducer = createReducer(history);

// eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-explicit-any
const hasExternalReduxDevToolsExt = !!(window as any).__REDUX_DEVTOOLS_EXTENSION__;

const configureStore = () => {
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    errorCatchMiddleware,
    thunk,
    alertsApi.middleware,
    schedulesApi.middleware,
    suitesApi.middleware,
    componentsApi.middleware,
    coreApi.middleware,
    integrationsApi.middleware,
    testsApi.middleware,
    groupsApi.middleware,
    projectsApi.middleware,
    stepsApi.middleware,
    userApi.middleware,
    eventsApi.middleware,
  ];

  if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
      collapsed: true,
    });
    middlewares.push(logger);
  }

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: () => null,
    actionTransformer: (action) => {
      if (action.type.startsWith(websocketPrefix)) {
        return {
          type: action.command || path(['data', 'command'], action) || action.type,
          calledViaWebsocket: true,
        };
      }
      return { type: action.type, error: action.error };
    },
  });

  const enhancers = [sentryReduxEnhancer];

  if (hasExternalReduxDevToolsExt) {
    // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-explicit-any
    enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = configureStoreBase({
    reducer: persistedReducer,
    middleware: middlewares,
    enhancers,
    devTools: process.env.NODE_ENV === 'development' && !hasExternalReduxDevToolsExt,
  });

  sagaMiddleware.run(rootSaga);

  setupListeners(store.dispatch);

  return store;
};

export default configureStore;
