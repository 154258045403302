import { CommonActions } from '@bugbug/core/actions/actions';
import { omit } from '@bugbug/core/utils/toolbox';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { Component } from '@bugbug/core/types/components';
import type { Group } from '@bugbug/core/types/groups';
import type { Step } from '@bugbug/core/types/steps';
import type { Test, TestRun } from '@bugbug/core/types/tests';
import { axiosBaseQuery } from '~/services/api/baseQuery';

import { TestActions } from '../test/test.redux';
import { TestRunActions } from '../testRun/testRun.redux';

const TAG = 'Test' as const;
export const testsApi = createApi({
  reducerPath: 'testsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/tests/' }),
  tagTypes: [TAG],

  endpoints: (builder) => ({
    // GET /tests/<testId>/
    getTest: builder.query<Test, { id: Test['id']; testRunId?: TestRun['id'] }>({
      query: ({ id, testRunId }) => ({
        url: `${id}/`,
        method: 'GET',
        params: {
          testRunId,
          expand: 'test_run',
        },
      }),
      providesTags: (result) => {
        if (!result) {
          return [{ type: TAG, id: 'ELEMENT' }];
        }
        if (result.testRun) {
          return [{ type: TAG, id: result.id, testRunId: result.testRun.id }];
        }
        return [{ type: TAG, id: result.id }];
      },
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data.testRun) {
          dispatch(
            TestRunActions.getSingleSuccess({
              ...data.testRun,
              testArchive: omit(['testRun'], data),
            }),
          );
        } else {
          dispatch(TestRunActions.clearSingle());
        }

        dispatch(TestActions.getSingleSuccess(data));
        dispatch(
          CommonActions.setIsSingleLoading({
            isLoading: false,
            testId: id,
          }),
        );
      },
    }),

    // POST /tests/<testId>/unlink-component/
    unlinkComponent: builder.mutation<Group, { testId: Test['id']; componentId: Component['id'] }>({
      query: ({ testId, componentId }) => ({
        url: `${testId}/unlink-component/`,
        method: 'POST',
        data: {
          groupId: componentId,
        },
      }),
      invalidatesTags: (_, __, arg) => [{ type: TAG, id: arg.testId }],
    }),

    // POST /tests/<testId>/paste-steps/
    pasteSteps: builder.mutation<
      { group: Group; newStepsIds: Step['id'][] },
      { testId: Test['id']; groupId: Group['id']; atIndex: number; stepsIds: Step['id'][] }
    >({
      query: ({ testId, stepsIds, groupId, atIndex }) => ({
        url: `${testId}/paste-steps/`,
        method: 'POST',
        data: {
          groupId,
          atIndex,
          stepsIds,
        },
      }),
      invalidatesTags: (_, __, arg) => [{ type: TAG, id: arg.testId }],
    }),
  }),
});

export const { useUnlinkComponentMutation, usePasteStepsMutation, useLazyGetTestQuery } = testsApi;
