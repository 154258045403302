import ButtonBase from '@bugbug/core/components/Button';
import styled from 'styled-components';

import GitHubSVG from '~/images/github-logo.svg?react';
import GoogleSVG from '~/images/google-logo.svg?react';

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;

  button {
    margin: 0;
  }
`;

export const SocialButton = styled(ButtonBase).attrs({
  bordered: true,
  noContentWrapper: true,
})`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.colors.text.normal};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const GoogleLogo = styled(GoogleSVG)`
  height: 18px;
  width: 18px;
`;

export const GitHubLogo = styled(GitHubSVG)`
  height: 18px;
  width: 18px;
`;
