import { TimeZoneProvider } from '@bugbug/core/hooks/useTimezone';
import { theme } from '@bugbug/core/theme';
import { COLOR } from '@bugbug/core/theme/colors';
import { THEME_MODE } from '@bugbug/core/theme/modes';
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
  THEME_ID,
  StyledEngineProvider,
} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import { useEvent, useMount } from 'react-use';
import { ThemeProvider } from 'styled-components';

import { ErrorBoundary } from '~/components/ErrorBoundary/ErrorBoundary';
import Mixpanel from '~/components/Mixpanel';
import RuntimeModals from '~/components/RuntimeModals';
import SubscriptionErrorModals from '~/components/SubscriptionErrorModals';
import { ToastContainer } from '~/components/Toast';
import { ModalsProvider } from '~/hooks/useModal';
import {
  ExtensionActions,
  GET_INCOGNITO_WINDOWS_STATUS_RESULT,
  UPDATE_EXTENSION_SETTINGS,
} from '~/modules/extension/extension.redux';
import { selectImpersonateTokenFromUrl } from '~/modules/misc.selectors';
import {
  selectCurrentOrganizationId,
  selectOrganizations,
} from '~/modules/organization/organization.selectors';
import { selectProjectTimezone } from '~/modules/project/project.selectors';
import { useAppSelector } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import {
  selectIsUserImpersonated,
  selectIsUserLoggedIn,
  selectUserToken,
} from '~/modules/user/user.selectors';
import { WebsocketActions } from '~/modules/websocket/websocket.redux';
import toasts from '~/services/toasts';
import { hasCookie, setCookie } from '~/utils/cookies';
import { reloadIfVersionChanged } from '~/utils/meta';

import { AppContainer, AppContent } from './App.styled';
import Routes from './Routes';

const materialTheme = createTheme({
  palette: {
    primary: {
      main: COLOR.PRIMARY_LIGHT,
    },
  },
  typography: {
    fontFamily: ['NeueFrutiger'],
    fontSize: 13,
    letterSpacing: '-0.01em',
    color: COLOR.DARK_GRAY,
  },
});

const App = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const isLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const impersonateToken = useAppSelector(selectImpersonateTokenFromUrl);
  const token = useAppSelector(selectUserToken);
  const currentOrganizationId = useAppSelector(selectCurrentOrganizationId);
  const organizations = useAppSelector(selectOrganizations);
  const isUserImpersonated = useAppSelector(selectIsUserImpersonated);
  const isImpersonated = isUserImpersonated || !!impersonateToken;
  const isUserOrganization = Boolean(organizations[currentOrganizationId]);
  const projectTimezone = useAppSelector(selectProjectTimezone);

  const handleBeforeUnload = useCallback(() => {
    dispatch(ExtensionActions.unregisterWebAppSessionRequested());
    dispatch(WebsocketActions.closeRequested());
  }, [dispatch]);

  const handleOffline = useCallback(() => {
    dispatch(WebsocketActions.closeRequested());
    dispatch(ExtensionActions.unregisterWebAppSessionRequested());
    toasts.core.showOfflineModeInfo();
  }, [dispatch]);

  const handleOnline = useCallback(async () => {
    if (isLoggedIn && token && currentOrganizationId) {
      dispatch(ExtensionActions.registerWebAppSessionRequested());
      dispatch(WebsocketActions.connectRequested(token, currentOrganizationId));
    }
  }, [dispatch, isLoggedIn, token, currentOrganizationId]);

  const handleMessage = useCallback(
    (event) => {
      const updateSettings = () => {
        if (!isLoggedIn) {
          return;
        }
        dispatch(ExtensionActions.updateSettingsRequested(event.data.settings));
      };

      const setIncognitoWindowsStatus = () => {
        if (!isLoggedIn) {
          return;
        }
        if (event.data.hasIncognitoWindows) {
          dispatch(ExtensionActions.getIncognitoWindowsStatusSuccess(event.data.meta));
        } else {
          dispatch(ExtensionActions.getIncognitoWindowsStatusFailure(event.data.meta));
        }
      };

      const handleEvent = {
        [GET_INCOGNITO_WINDOWS_STATUS_RESULT]: setIncognitoWindowsStatus,
        [UPDATE_EXTENSION_SETTINGS]: updateSettings,
        DISPATCH_IN_WEBAPP: () => dispatch(event.data.action),
      }[event.data.type];

      handleEvent?.();
    },
    [dispatch, isLoggedIn],
  );

  useMount(() => {
    const isDirectVisit = !document.referrer && !window.location.search;
    const visitData = JSON.stringify({
      date: new Date().toISOString(),
      referrer: isDirectVisit ? 'direct' : document.referrer,
      search: isDirectVisit ? 'direct' : window.location.search,
      on: `${window.location.origin}${window.location.pathname}`,
    });

    if (!hasCookie('bb_first_seen')) {
      setCookie({ name: 'bb_first_seen', value: visitData });
    }
    setCookie({ name: 'bb_last_seen', value: visitData });
  });

  useEffect(() => {
    if (impersonateToken) {
      dispatch(
        UserActions.loginSuccess({
          token: impersonateToken,
          isImpersonated: true,
        }),
      );
    }
  }, [dispatch, impersonateToken]);

  useEvent('beforeunload', handleBeforeUnload);
  useEvent('message', handleMessage);
  useEvent('offline', handleOffline);
  useEvent('online', handleOnline);
  useEvent('vite:preloadError', () => {
    reloadIfVersionChanged();
  });

  useEffect(() => {
    if (isLoggedIn && token) {
      dispatch(ExtensionActions.connectWithWebappRequested());
      dispatch(ExtensionActions.registerWebAppSessionRequested());
      batch(() => {
        dispatch(UserActions.getSingleRequest());
        dispatch(UserActions.startListeningForLogout());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isImpersonated, token, dispatch]);

  useEffect(() => {
    if (isLoggedIn && token && currentOrganizationId && isUserOrganization) {
      dispatch(WebsocketActions.reconnectRequested(token, currentOrganizationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, token, dispatch, currentOrganizationId, isUserOrganization]);

  const themeWithMode = useMemo(() => ({ ...theme, mode: THEME_MODE.LIGHT }), []);

  return (
    <ThemeProvider theme={themeWithMode}>
      <MaterialThemeProvider theme={{ [THEME_ID]: materialTheme }}>
        <StyledEngineProvider>
          <AppContainer>
            <Helmet
              defaultTitle={t('app.defaultTitle', 'BugBug.io')}
              titleTemplate={t('app.titleTemplate', '%s - BugBug.io')}
            />
            <ToastContainer />
            <Sentry.ErrorBoundary fallback={ErrorBoundary}>
              <AppContent>
                <TimeZoneProvider value={projectTimezone}>
                  <ModalsProvider>
                    <Mixpanel />
                    <Routes isLoggedIn={isLoggedIn} />
                    <SubscriptionErrorModals />
                    <RuntimeModals />
                  </ModalsProvider>
                </TimeZoneProvider>
              </AppContent>
            </Sentry.ErrorBoundary>
          </AppContainer>
        </StyledEngineProvider>
      </MaterialThemeProvider>
    </ThemeProvider>
  );
};

export default App;
