import Icon from '@bugbug/core/components/Icon';
import TooltipBase from '@bugbug/core/components/Tooltip';
import styled from 'styled-components';

import StepNameBase from '~/components/StepName';

export const StepName = styled(StepNameBase)`
  flex: none;
  margin-right: 4px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  width: 100%;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.text.normal};
  display: flex;
  align-items: center;
  margin-right: 10px;
  opacity: 0.6;

  svg {
    height: 100%;
  }
`;

export const Tooltip = styled(TooltipBase)`
  max-width: 200px;
`;

export const SleepIcon = styled(Icon).attrs(() => ({
  name: 'sleep',
}))`
  margin-left: 8px;
  margin-top: -2px;
  flex: none;
  color: ${({ theme }) => theme.colors.primarySoft};
`;
