import Loader from '@bugbug/core/components/Loader';
import { isEmpty } from 'ramda';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useFirstMountState, useMount } from 'react-use';

import SideMenu from '~/components/Menu';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import { QUERY_ACTION } from '~/modules/constans';
import { ProfileActions } from '~/modules/profile/profile.redux';
import { ProjectActions } from '~/modules/project/project.redux';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { useAppDispatch } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import { VariableActions } from '~/modules/variable/variable.redux';
import AlertsList from '~/views/AlertsList';
import ComponentDetails from '~/views/ComponentDetails';
import ComponentsList from '~/views/ComponentsList';
import Forbidden from '~/views/Forbidden';
import Integrations from '~/views/Integrations';
import NotFound from '~/views/NotFound';
import ProjectSettings from '~/views/ProjectSettings';
import RunsHistory from '~/views/RunsHistory';
import SchedulesList from '~/views/SchedulesList';
import SuiteRunDetails from '~/views/SuiteRunDetails';
import { SuitesList } from '~/views/SuitesList/SuitesList';
import TestDetails from '~/views/TestDetails';
import TestRunDetails from '~/views/TestRunDetails';
import TestsList from '~/views/TestsList';
import urls, { reverse } from '~/views/urls';
import Variables from '~/views/Variables';

import { Container, LoaderWrapper } from './Project.styled';

const ProjectRoutes = memo(({ projectId, projectSlug, organizationId }) => {
  const testsListUrl = reverse(urls.testsList, { projectId, projectSlug, organizationId });
  const testRunsListUrl = reverse(urls.testRunsList, { projectId, projectSlug, organizationId });
  const schedulesListUrl = reverse(urls.schedulesList, { projectId, projectSlug, organizationId });
  const builtInVariablesUrl = reverse(urls.builtInVariables, {
    projectId,
    projectSlug,
    organizationId,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(UserActions.refreshAnalyticsGlobals());
    return () => {
      // for case when user navigates non project pages
      dispatch(UserActions.refreshAnalyticsGlobals());
    };
  }, [projectId, dispatch]);

  useEffect(() => {
    dispatch(VariableActions.getListRequest());
    dispatch(ProfileActions.getListRequest());
  }, [dispatch]);

  return (
    <Container>
      <SideMenu />
      <Switch>
        <Route exact path={urls.test} component={TestDetails} />
        <Route path={urls.testsList} component={TestsList} />
        <Route path={urls.suitesList} component={SuitesList} />
        <Route path={urls.testRun} component={TestRunDetails} />
        <Route path={urls.suiteRun} component={SuiteRunDetails} />
        <Route exact path={urls.testRunsList} component={RunsHistory} />
        <Route exact path={urls.suiteRunsList} component={RunsHistory} />
        <Route path={urls.alerts} component={AlertsList} />
        <Route path={urls.projectSettings} component={ProjectSettings} />
        <Route path={urls.schedulesList} component={SchedulesList} />
        <Route path={urls.schedulesList} render={() => <Redirect to={schedulesListUrl} />} />
        <Route path={urls.runsHistory} render={() => <Redirect to={testRunsListUrl} />} />
        <Route exact path={urls.project} render={() => <Redirect to={testsListUrl} />} />
        <Route
          path={[
            urls.profiles,
            urls.profile,
            urls.newProfile,
            urls.customVariables,
            urls.builtInVariables,
          ]}
          component={Variables}
        />
        <Route path={urls.variables} render={() => <Redirect to={builtInVariablesUrl} />} />
        <Route path={urls.component} component={ComponentDetails} />
        <Route path={urls.componentsList} component={ComponentsList} />
        <Route path={urls.integrations} component={Integrations} />
      </Switch>
    </Container>
  );
});

ProjectRoutes.displayName = 'ProjectRoutes';

const Project = (props) => {
  const { match } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryParams = useQueryString();
  const history = useHistory();
  const modal = useModal();
  const project = useSelector(selectSingleProject);
  const isFirstMount = useFirstMountState();
  const appRoute = useAppRoutes('project');
  const { organizationId } = appRoute.params;

  const singleProjectState = useActionState(ProjectActions.getSingleRequest, {
    reset: false,
  });

  const { projectId, projectSlug } = match.params;

  const loadProject = useCallback(() => {
    dispatch(ProjectActions.getSingleRequest(projectId));
    dispatch(ProfileActions.getListRequest('', projectId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useMount(() => {
    if (queryParams.action === QUERY_ACTION.INSTALL_EXTENSION) {
      modal.show('install_extension', undefined);
    }
  });

  useEffect(() => {
    if (
      !isFirstMount &&
      !singleProjectState.isLoading &&
      queryParams.action === QUERY_ACTION.SHOW_SUITE_DETAILS &&
      queryParams.suiteId
    ) {
      appRoute.replace('suite', { suiteId: queryParams.suiteId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.action, queryParams.suiteId, isFirstMount, singleProjectState.isLoading]);

  useEffect(loadProject, [loadProject]);

  useEffect(
    () => () => {
      singleProjectState.reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId],
  );

  const goToProject = useCallback(() => {
    history.push(reverse(urls.projectsList, { organizationId }));
  }, [history, organizationId]);
  const buttonLabel = t('project.errorPage.button', 'Back to all projects');

  const Routes = useMemo(
    () => (
      <ProjectRoutes
        projectId={projectId}
        projectSlug={projectSlug}
        organizationId={organizationId}
      />
    ),
    [projectId, projectSlug, organizationId],
  );

  if (singleProjectState.hasInternalServerError) {
    return (
      <ServerErrorInfo
        isVisible
        errorText={t(
          'project.errorPage.serverErrorMessage',
          'Something went wrong while fetching the project details.',
        )}
        onRetry={loadProject}
      />
    );
  }

  if (singleProjectState.isForbidden) {
    return (
      <Forbidden
        message={t(
          'project.errorPage.forbiddenMessage',
          "This project is locked because you've reached the limit of projects in your plan. To unlock it you need to upgrade your plan.",
        )}
        buttonLabel={buttonLabel}
        onButtonClick={goToProject}
      />
    );
  }

  if (singleProjectState.isNotFound) {
    return (
      <NotFound
        message={t(
          'project.errorPage.notFoundMessage',
          "The project you've tried to reach does not exist.",
        )}
        buttonLabel={buttonLabel}
        onButtonClick={goToProject}
      />
    );
  }

  if (isEmpty(project)) {
    return (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Helmet
        titleTemplate={t('project.pageTitle', '{{name}} / %s - bugbug.io', {
          name: project.name,
          interpolation: { escapeValue: false },
        })}
      />
      {Routes}
    </>
  );
};

export default Project;
