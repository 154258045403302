import { css } from 'styled-components';

import type { Theme } from './theme.types';
import type { FlattenInterpolation, ThemeProps, ThemedCssFunction } from 'styled-components';

export type Breakpoint = 'desktop' | 'desktopFull' | 'desktopWide' | 'mobile' | 'tablet';

export type ScreenWidth = 1920 | 1280 | 1024 | 768 | 320;

export type MediaQuery = {
  [key in Breakpoint]: (
    ...args: Parameters<ThemedCssFunction<Theme>>
  ) => FlattenInterpolation<ThemeProps<Theme>>;
};

export const sizes: Record<Breakpoint, ScreenWidth> = {
  desktopFull: 1920,
  desktopWide: 1280,
  desktop: 1024,
  tablet: 768,
  mobile: 320,
};

export const media: MediaQuery = (Object.keys(sizes) as Breakpoint[]).reduce(
  (queries, breakpoint) => {
    queries[breakpoint] = (...args) => css`
      @media (min-width: ${sizes[breakpoint]}px) {
        ${css(...args)}
      }
    `;
    return queries;
  },
  {} as MediaQuery,
);
