import CopyButton from '@bugbug/core/components/CopyButton';
import RadioButton from '@bugbug/core/components/RadioButton';
import Tooltip from '@bugbug/core/components/Tooltip';
import {
  createEmptySelectorsGroup,
  getFullSelectorPathFromPreset,
  normalizeSelectorsPreset,
} from '@bugbug/core/utils/selectors';
import { useFormikContext } from 'formik';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { MouseStep, SelectorsPreset } from '@bugbug/core/types/steps';
import type { Maybe } from '@bugbug/core/types/utils';
import FormField from '~/components/FormField';

import { ComputedValue } from '../Interaction/Interaction.styled';

import { CustomSelectorsPreset } from './CustomSelectorsPreset/CustomSelectorsPreset';
import * as S from './ElementSelectorBuilder.styled';
import { SuggestedSelectorsPresets } from './SuggestedSelectorsPresets/SuggestedSelectorsPresets';

export interface ElementSelectorBuilderProps {
  name?: 'selectorsPresets' | 'dndDropSelectorsPresets';
  label?: string;
  context?: 'component' | 'test' | 'testRun';
  disabled?: boolean;
  relationDisabled?: boolean;
  computedValue?: Maybe<string>;
}

export const ElementSelectorBuilder = (props: ElementSelectorBuilderProps) => {
  const {
    name = 'selectorsPresets',
    label,
    context,
    computedValue,
    relationDisabled,
    disabled,
  } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'elementSelectorBuilder' });

  const formik = useFormikContext<MouseStep>();

  const selectorsPresets: SelectorsPreset[] = formik.values[name];
  const activePresetIndex = selectorsPresets.findIndex((preset) => preset.isActive);
  const activePreset = selectorsPresets[activePresetIndex] ?? selectorsPresets[0];
  const activePresetFieldName = `${name}[${activePresetIndex}]`;
  const isCustomPresetActive = activePreset?.isCustom;

  const { suggested, custom } = useMemo(
    () =>
      selectorsPresets.reduce<{ suggested: SelectorsPreset[]; custom: SelectorsPreset[] }>(
        (acc, preset) => {
          if (preset.isCustom) {
            acc.custom.push(preset);
          } else {
            acc.suggested.push(preset);
          }

          return acc;
        },
        { suggested: [], custom: [] },
      ),
    [selectorsPresets],
  );
  const recentlyActiveSuggestedPresetId = useRef(
    suggested.find((preset) => preset.isActive)?.id ?? suggested[0]?.id,
  );

  const updatePresets = (updatedPresets: SelectorsPreset[]) => {
    formik.handleChange({ target: { name, value: updatedPresets } });
  };

  const setPresetWithIndexAsActive = (presetIndex) => {
    const presets = selectorsPresets.map((preset, index) => ({
      ...preset,
      isActive: index === presetIndex,
    }));
    updatePresets(presets);
  };

  const handleCustomizeSuggestedPreset = (groupIndex?: number) => {
    const isInitiatedInGroup = groupIndex !== undefined;
    const currentCustomPresetIndex = selectorsPresets.findIndex((preset) => preset.isCustom);
    const hasCustomPresent = currentCustomPresetIndex !== -1;
    const shouldCreateNewCustomPreset = !hasCustomPresent || isInitiatedInGroup;

    if (shouldCreateNewCustomPreset) {
      const { id: _, ...basePreset } = structuredClone(activePreset);
      const newPreset = normalizeSelectorsPreset({
        ...basePreset,
        isActive: true,
        isCustom: true,
      });

      // Set customXPath as active for group where customization was initiated
      if (groupIndex !== undefined) {
        newPreset.selectorsGroups[groupIndex].selectors = newPreset.selectorsGroups[
          groupIndex
        ].selectors.map((selector) => ({
          ...selector,
          isActive: selector.type === 'customXPath',
        }));
      }

      // Set new custom preset as active, deactivate all other presets
      const nonCustomPresets = selectorsPresets
        .filter((preset) => !preset.isCustom)
        .map((preset) => ({
          ...preset,
          isActive: false,
        }));

      updatePresets([...nonCustomPresets, newPreset]);
    } else {
      const updatedPresets = selectorsPresets.map((preset) => ({
        ...preset,
        isActive: preset.isCustom,
      }));
      updatePresets(updatedPresets);
    }
  };

  const handleTabChange = (presetType: 'suggested' | 'custom') => {
    if (presetType === 'suggested') {
      setPresetWithIndexAsActive(
        selectorsPresets.findIndex(
          (preset) => preset.id === recentlyActiveSuggestedPresetId.current,
        ),
      );
    }

    if (presetType === 'custom') {
      handleCustomizeSuggestedPreset();
    }
  };

  const handleUpdateCustomPreset = (updatedPreset: SelectorsPreset) => {
    const updatedPresets = selectorsPresets.map((preset) =>
      preset.isCustom ? updatedPreset : preset,
    );
    updatePresets(updatedPresets);
  };

  const handleClearAllClick = () => {
    handleUpdateCustomPreset({
      ...activePreset,
      selectorsGroups: [createEmptySelectorsGroup('customXPath')],
    });
  };

  const handleUpdateSuggestedPresets = (updatedPreset: SelectorsPreset) => {
    const updatedPresetsWithActive = selectorsPresets.map((preset) => {
      if (preset.id === updatedPreset.id) {
        return updatedPreset;
      }

      return {
        ...preset,
        isActive: false,
      };
    });

    recentlyActiveSuggestedPresetId.current = updatedPreset.id;
    updatePresets(updatedPresetsWithActive);
  };

  const fullSelectorPath = getFullSelectorPathFromPreset(activePreset);

  return (
    <FormField
      label={label || t('label', 'Element selector')}
      labelId={activePreset?.id}
      data-testid="ElementSelectorBuilder"
    >
      <S.HeaderActions aria-labelledby={activePreset?.id}>
        <Tooltip
          content={t('copyButton.tooltip', 'Copy full selector to clipboard')}
          anchor="top-end"
        >
          <CopyButton value={fullSelectorPath} small />
        </Tooltip>
      </S.HeaderActions>
      {!!suggested.length && context === 'test' && (
        <S.PresetTypes>
          <S.Options>
            <RadioButton
              name={`${name}-suggested`}
              onChange={() => handleTabChange('suggested')}
              checked={!isCustomPresetActive}
            >
              {t('tabs.suggested.label', 'Suggested')}
            </RadioButton>
            <RadioButton
              name={`${name}-custom`}
              onChange={() => handleTabChange('custom')}
              checked={isCustomPresetActive}
            >
              {t('tabs.custom.label', 'Custom')}
            </RadioButton>
          </S.Options>
          {isCustomPresetActive && (
            <S.ClearButton onClick={handleClearAllClick}>
              {t('clearAll', 'Clear all')}
            </S.ClearButton>
          )}
        </S.PresetTypes>
      )}
      {!isCustomPresetActive && (
        <SuggestedSelectorsPresets
          name={activePresetFieldName}
          presets={suggested}
          context={context}
          onChange={handleUpdateSuggestedPresets}
          onCustomize={handleCustomizeSuggestedPreset}
        />
      )}
      {isCustomPresetActive && (
        <CustomSelectorsPreset
          name={activePresetFieldName}
          preset={custom[0]}
          onChange={handleUpdateCustomPreset}
          context={context}
          relationDisabled={relationDisabled}
          disabled={disabled}
        />
      )}
      {computedValue && (
        <S.ComputedSelectorValue
          labelId={`${activePreset?.id}-computed`}
          label={
            context === 'testRun'
              ? t('computed.thisComputedSelector', 'This test run selector')
              : t('computed.lastComputedSelector', 'Last test run selector')
          }
        >
          <S.HeaderActions aria-labelledby={`${activePreset?.id}-computed`}>
            <Tooltip
              content={t('computed.copyButton.tooltip', 'Copy this selector to clipboard')}
              anchor="top-end"
            >
              <CopyButton value={computedValue} small />
            </Tooltip>
          </S.HeaderActions>
          <ComputedValue>{computedValue}</ComputedValue>
        </S.ComputedSelectorValue>
      )}
    </FormField>
  );
};
