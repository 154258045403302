import { useTranslation } from 'react-i18next';

import type { Variable } from '@bugbug/core/types/variables';
import GridItem from '~/components/GridItem';
import { VARIABLE_TYPE } from '~/constants/variables';
import useAppRoutes from '~/hooks/useAppRoutes';
import useCopyHandler from '~/hooks/useCopyHandler';
import useModal from '~/hooks/useModal';

import * as S from './VariableItem.styled';

interface VariableItemProps {
  variable: Variable;
}

const VariableItem = ({ variable }: VariableItemProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const handleCopy = useCopyHandler(`{{${variable.key}}}`);
  const { push } = useAppRoutes('variables');

  const isDefault = variable.isDefault || variable.isSystem;

  const handleClick = () => {
    push(isDefault ? 'builtInVariable' : 'customVariable', { variableId: variable.id });
  };

  const handleDeleteClick = () => {
    modal.show('delete_variable', { id: variable.id });
  };

  const actions = [
    {
      label: t('variableItem.actions.copyName', 'Copy key'),
      onClick: handleCopy,
      iconName: 'copy' as const,
    },
    {
      label: t('variableItem.actions.delete', 'Delete'),
      onClick: handleDeleteClick,
      iconName: 'delete' as const,
      disabled: variable.isDefault || variable.isSystem,
      danger: !(variable.isDefault || variable.isSystem),
    },
  ];

  const getDescription = () => {
    const variableValue = variable.value || t('variableItem.empty', '(empty)');
    const renderedValue =
      variable.type === VARIABLE_TYPE.EVALUATE
        ? t('variableItem.codeDescription', 'Click to open JavaScript code')
        : t('variableItem.defaultValue', 'Default value: {{- value}}', { value: variableValue });

    return (
      <>
        {!variable.isSystem && <S.Description>{renderedValue}</S.Description>}
        {variable.description && <S.Description>{variable.description}</S.Description>}
      </>
    );
  };

  return (
    <GridItem
      key={variable.id}
      title={variable.key}
      description={getDescription()}
      onClick={handleClick}
      actions={actions}
    />
  );
};

export default VariableItem;
