import Tooltip from '@bugbug/core/components/Tooltip';
import { useTranslation } from 'react-i18next';

import type { Test, TestRun } from '@bugbug/core/types/tests';
import useTestRunner from '~/hooks/useTestRunner';
import { useIsNewPlaybackSupported } from '~/modules/test/test.hooks';

import { useTestRunnerActionsState } from './TestHeaderActions.hooks';
import * as S from './TestHeaderActions.styled';

interface PauseButtonsProps {
  testId: Test['id'];
  testRunId?: TestRun['id'];
}

export const PauseButtons = ({ testId, testRunId }: PauseButtonsProps) => {
  const { t } = useTranslation();
  const testRunner = useTestRunner({ id: testId }, { testRunId });
  const isNewPlaybackSupported = useIsNewPlaybackSupported();
  const runnerActionsState = useTestRunnerActionsState();

  const handleStop = async () => {
    if (isNewPlaybackSupported) {
      testRunner.stopWithConfirmation();
    } else {
      testRunner.stop();
    }
  };

  const handleRecord = async () => {
    try {
      runnerActionsState.setPendingAction('record');
      await testRunner.resumeRecording();
    } catch (error) {
      console.error('Error while resuming recording', error);
      runnerActionsState.resetPendingAction();
    }
  };

  const handleResumeRunning = async () => {
    try {
      runnerActionsState.setPendingAction('resume');
      await testRunner.resume();
    } catch (error) {
      console.error('Error while resuming running', error);
      runnerActionsState.resetPendingAction();
    }
  };

  const handleRunNextStep = async () => {
    try {
      runnerActionsState.setPendingAction('runNextStep');
      await testRunner.runNextStep();
    } catch (error) {
      console.error('Error while running next step', error);
      runnerActionsState.resetPendingAction();
    }
  };

  return (
    <S.CombinedButtonsContainer>
      <S.RecordButton
        {...runnerActionsState.state.stop}
        onClick={handleStop}
        iconName="stop"
        roundCorners="left"
      >
        {t('testDetails.testActions.stopButton', 'Stop')}
      </S.RecordButton>
      {isNewPlaybackSupported && (
        <>
          <S.CombinedButtonsSeparator disabled={runnerActionsState.isLoading} />
          <S.CombinedButton
            {...runnerActionsState.state.record}
            onClick={handleRecord}
            iconName="dot"
          >
            {t('testDetails.testActions.recordButton', 'Record')}
          </S.CombinedButton>
        </>
      )}
      <S.CombinedButtonsSeparator disabled={runnerActionsState.isLoading} />
      <S.CombinedButton
        {...runnerActionsState.state.resume}
        onClick={handleResumeRunning}
        iconName="play"
      >
        {t('testDetails.testActions.resumeButton', 'Resume')}
      </S.CombinedButton>
      <S.CombinedButtonsSeparator disabled={runnerActionsState.isLoading} />
      <Tooltip
        offset={4}
        anchor="top-end"
        content={t('testDetails.tooltip.runNextStepAndPause', 'Run next step and pause')}
      >
        <S.NextStepButton
          {...runnerActionsState.state.runNextStep}
          onClick={handleRunNextStep}
          iconName="next"
          roundCorners="right"
        />
      </Tooltip>
    </S.CombinedButtonsContainer>
  );
};
