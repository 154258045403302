import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

import PublicPage from '~/components/PublicPage';
import useActionState from '~/hooks/useActionState';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { COMPANY_ROLES, USER_SOURCES } from '~/modules/user/user.constants';
import { UserActions } from '~/modules/user/user.redux';
import { ProfileSetupSchema } from '~/modules/user/user.schemas';
import { selectIsInvited, selectOnboardingData } from '~/modules/user/user.selectors';
import analytics, { TRACK_EVENT_ARG_TYPE, TRACK_EVENT_ARG_VALUE } from '~/services/analytics';

import {
  OnboardingTitle,
  OnboardingSubtitle,
  ContinueButton,
  OnboardingCard,
  OnboardingForm,
  OnboardingBox,
  FormField,
} from '../Onboarding/Onboarding.styled';

import { COMPANY_ROLE_LABEL, USER_SOURCE_LABEL } from './ProfileSetup.constants';
import { ProfileLogo } from './ProfileSetup.styled';

export const ProfileSetup = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const initialProfileData = useAppSelector(selectOnboardingData) as {
    firstName: string;
    lastName: string;
    organization: {
      companyName: string;
    };
    role?: string;
    source?: string;
  };
  const isInvitedUser = useAppSelector(selectIsInvited);

  const handleOnSubmit = useCallback(
    (values) => {
      dispatch(
        UserActions.updateOnboardingProfileRequest({
          ...values,
          isInvitedUser,
        }),
      );
    },
    [dispatch, isInvitedUser],
  );

  const {
    errors,
    handleBlur,
    handleChange,
    isSubmitting,
    touched,
    handleSubmit,
    values,
    setErrors,
    setSubmitting,
  } = useFormik({
    initialValues: {
      role: '',
      source: '',
      ...initialProfileData,
      organization: {
        ...initialProfileData.organization,
      },
    },
    validationSchema: ProfileSetupSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    analytics.trackEvent('sign_up_profile_created', {
      [TRACK_EVENT_ARG_TYPE.FIRST_NAME]: values.firstName,
      [TRACK_EVENT_ARG_TYPE.LAST_NAME]: values.lastName,
      [TRACK_EVENT_ARG_TYPE.POSITION]: COMPANY_ROLE_LABEL[values.role],
      [TRACK_EVENT_ARG_TYPE.USER_TYPE]: isInvitedUser
        ? TRACK_EVENT_ARG_VALUE.USER_TYPE_INVITED
        : TRACK_EVENT_ARG_VALUE.USER_TYPE_OWNER,
      [TRACK_EVENT_ARG_TYPE.COMPANY_NAME]: values.organization.companyName,
      [TRACK_EVENT_ARG_TYPE.USER_SOURCE]: USER_SOURCE_LABEL[values.source],
    });
    setSubmitting(false);
  }, [
    isInvitedUser,
    setSubmitting,
    values.firstName,
    values.lastName,
    values.organization.companyName,
    values.role,
    values.source,
  ]);

  const { isLoading } = useActionState(UserActions.updateOnboardingProfileRequest, {
    onSuccess: handleSuccess,
    onFailure: handleFailure,
  });

  useMount(() => {
    analytics.trackEvent('sign_up_profile_viewed');
  });

  const organizationErrors = errors.organization || {};

  return (
    <PublicPage data-testid="ProfileSetup">
      <OnboardingBox>
        <OnboardingCard>
          <div>
            <ProfileLogo />
            <OnboardingTitle>
              {t('onboarding.profile.title', 'Tell us about yourself')}
            </OnboardingTitle>
            <OnboardingSubtitle>
              {t(
                'onboarding.profile.subtitle',
                'We need to know a little bit more to tailor BugBug to your needs.',
              )}
            </OnboardingSubtitle>
          </div>
          <OnboardingForm onSubmit={handleSubmit}>
            <FormField label={t('onboarding.profile.firstName', 'First name')}>
              <Input
                name="firstName"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                error={errors.firstName}
              />
            </FormField>
            <FormField label={t('onboarding.profile.lastName', 'Last name')}>
              <Input
                name="lastName"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                error={errors.lastName}
              />
            </FormField>
            <FormField label={t('onboarding.profile.job.label', 'Job position')}>
              <Select
                name="role"
                fullWidth
                placeholder={t('onboarding.profile.job.placeholder', 'Select...')}
                onChange={handleChange}
                value={values.role}
                error={touched.role && errors.role}
                maxOptionsHeight={180}
              >
                {COMPANY_ROLES.map((roleType) => (
                  <SelectOption key={roleType} value={roleType}>
                    {COMPANY_ROLE_LABEL[roleType]}
                  </SelectOption>
                ))}
              </Select>
            </FormField>
            {!isInvitedUser && (
              <>
                <FormField label={t('onboarding.profile.companyName.label', 'Company name')}>
                  <Input
                    name="organization.companyName"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.organization.companyName}
                    error={organizationErrors.companyName}
                  />
                </FormField>
                <FormField
                  label={t('onboarding.profile.userSource.label', 'How did you hear about us?')}
                >
                  <Select
                    name="source"
                    fullWidth
                    placeholder={t('onboarding.profile.userSource.placeholder', 'Select...')}
                    onChange={handleChange}
                    value={values.source}
                    error={touched.source && errors.source}
                    maxOptionsHeight={180}
                  >
                    {USER_SOURCES.map((source) => (
                      <SelectOption key={source} value={source}>
                        {USER_SOURCE_LABEL[source]}
                      </SelectOption>
                    ))}
                  </Select>
                </FormField>
              </>
            )}
            <ContinueButton type="submit" disabled={isSubmitting} pending={isLoading}>
              {t('onboarding.profile.submit', 'Continue')}
            </ContinueButton>
          </OnboardingForm>
        </OnboardingCard>
      </OnboardingBox>
    </PublicPage>
  );
};
