import * as T from '@bugbug/core/utils/toolbox';
import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store';

import type { Grouping } from '@bugbug/core/types/groups';
import type { Test } from '@bugbug/core/types/tests';

const selectDomain = (state: RootState) => state.uiState;

export const selectUIStateForComponent = T.memoize((componentName: string) =>
  createSelector(selectDomain, T.pathOr({}, ['components', componentName])),
);

export const selectIntegrationTrigger = createSelector(selectDomain, T.prop('integrationTrigger'));

export const selectCollapsedGroups = T.memoize((testId: Test['id']) =>
  createSelector(selectDomain, T.pathOr([], ['collapsedGroups', testId])),
);

export const selectIsGroupCollapsed = T.memoize(
  (groupId: Grouping['id'], testId: Test['id']) =>
    createSelector(selectCollapsedGroups(testId), (groupsIds: Grouping['id'][]) =>
      groupsIds.includes(groupId),
    ),
  T.joinAllArgs,
);

export const selectRecentlySelectedTabs = createSelector(
  selectDomain,
  (s) => s.recentlySelectedTabs,
);

export const selectProjectSettingsTab = createSelector(
  selectRecentlySelectedTabs,
  (s) => s.projectSettings,
);

export const selectRunsHistoryTab = createSelector(
  selectRecentlySelectedTabs,
  (s) => s.runsHistory,
);

export const selectVariablesTab = createSelector(selectRecentlySelectedTabs, (s) => s.variables);

export const selectPlaybackState = createSelector(selectDomain, (uiState) => uiState.playback);

export const selectPlaybackCursorPosition = createSelector(
  selectDomain,
  (uiState) => uiState.playback.cursorPosition,
);

export const selectPlaybackSelectedPositions = createSelector(
  selectDomain,
  (uiState) => uiState.playback.selectedPositions,
);

export const selectIsDraggingPlaybackCursor = createSelector(
  selectDomain,
  (uiState) => uiState.playback.isDraggingCursor,
);

export const selectSessionsIds = createSelector(selectDomain, (uiState) => uiState.sessionsIds);

export const selectRecentlyAddedStepsIds = createSelector(
  selectDomain,
  (uiState) => uiState.recentlyAddedStepsIds,
);

export const selectDraggedStepId = createSelector(selectDomain, (uiState) => uiState.draggedStepId);

export const selectIgnoreStopTestAlert = createSelector(
  selectDomain,
  (uiState) => uiState.ignoreStopTestAlert,
);

export const selectPendingAction = createSelector(selectDomain, (uiState) => uiState.pendingAction);

export const selectCheckedSteps = createSelector(selectDomain, (uiState) => uiState.checkedSteps);

export const selectIsStepChecked = T.memoize(
  (stepId: string, groupId: Grouping['id']) =>
    createSelector(selectCheckedSteps, (checkedSteps) =>
      checkedSteps.some(
        (checkedStep) => checkedStep.id === stepId && checkedStep.groupId === groupId,
      ),
    ),
  T.joinAllArgs,
);

export const selectLastFocusedStepCheckbox = createSelector(
  selectDomain,
  (uiState) => uiState.lastFocusedStepIdCheckbox,
);

export const selectHighlightEditAndRewindMode = createSelector(
  selectDomain,
  (uiState) => uiState.highlightEditAndRewindMode,
);
