import Loader from '@bugbug/core/components/Loader';
import Tooltip from '@bugbug/core/components/Tooltip';
import { STEP_TYPE } from '@bugbug/core/constants/steps';
import { isFailedStatus } from '@bugbug/core/types/base';
import { joinAllArgs } from '@bugbug/core/utils/toolbox';
import { Formik, useFormikContext } from 'formik';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { useUpdateEffect } from 'react-use';
import * as Yup from 'yup';

import type { TemporaryStep } from '~/modules/steps/steps.types';

import type { Step } from '@bugbug/core/types/steps';
import type { StepRun, TestRun } from '@bugbug/core/types/tests';
import type { SideEffect } from '@bugbug/core/types/utils';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useLeavePagePrompt from '~/hooks/useLeavePagePrompt';
import useModal from '~/hooks/useModal';
import { selectIsFreePlan } from '~/modules/organization/organization.selectors';
import { TAG, stepsApi, useGetStepDetailsQuery } from '~/modules/steps/steps.api';
import { selectTemporaryStep } from '~/modules/steps/steps.selectors';
import { isTemporaryStep } from '~/modules/steps/steps.types';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectStepRun } from '~/modules/testRun/testRun.selectors';
import { addProtocolWhenNotExists } from '~/utils/url';

import StepRunMetadata from '../StepRunMetadata';

import { STEP_SCHEMA, STEP_UNSAVED_CHANGES } from './StepDetails.constants';
import { getInitialValues } from './StepDetails.helpers';
import * as S from './StepDetails.styled';
import { StepDetailsEditor } from './StepDetailsEditor';

interface StepDetailsProps {
  stepId: Step['id'];
  onClose: SideEffect<void>;
  context: 'component' | 'test' | 'testRun';
  testRunId?: TestRun['id'];
  readOnly?: boolean;
}

export const StepDetails = memo(
  ({ context = 'test', onClose, stepId, testRunId, readOnly }: StepDetailsProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isFreePlan = useAppSelector(selectIsFreePlan) as boolean;
    const tempStep = useAppSelector(selectTemporaryStep);

    const { data, isLoading, isError, refetch, error } = useGetStepDetailsQuery(
      {
        id: stepId,
        testRunId,
      },
      {
        skip: !stepId || tempStep?.id === stepId,
      },
    );

    const step = tempStep ?? data;
    const isTemporary = !!step && isTemporaryStep(step);
    const { groupId, atIndex } = isTemporary
      ? step
      : { groupId: step?.groupId, atIndex: undefined };
    const getValidationSchema = useCallback(
      () => Yup.lazy((values) => STEP_SCHEMA[values.type] || STEP_SCHEMA.DEFAULT),
      [],
    );

    const handleClose = useCallback(() => {
      if (isTemporary) {
        dispatch(TestActions.cancelTemporaryStep(stepId, groupId));
      }

      onClose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, onClose, groupId, stepId]);

    const handleSubmit = useCallback(
      (values, { resetForm }) => {
        const schema = STEP_SCHEMA[values.type] || STEP_SCHEMA.DEFAULT;
        const newValues = schema.cast({ ...values, url: addProtocolWhenNotExists(values.url) });
        if (isTemporary) {
          dispatch(
            TestActions.saveTemporaryStepRequest(stepId, groupId, newValues, atIndex, {
              reqId: stepId,
            }),
          );
          return;
        }

        dispatch(
          TestActions.saveStepSettingsRequest(stepId, groupId, newValues, {
            reqId: stepId,
          }),
        );

        resetForm({ values: newValues });
      },
      [groupId, isTemporary, atIndex, stepId, dispatch],
    );

    const initialValues = useMemo(() => (step ? getInitialValues<typeof step>(step) : {}), [step]);

    if (!isTemporary) {
      if (isLoading) {
        return (
          <S.LoaderContainer>
            <Loader size="large" />
            <span>{t('loading', 'Gathering step details...')}</span>
          </S.LoaderContainer>
        );
      }

      if (isError || !step) {
        console.error('Error fetching step details', stepId, isError, error);
        return <ServerErrorInfo isVisible onRetry={refetch} />;
      }
    }

    const isNotEditable =
      isFreePlan &&
      ([STEP_TYPE.EXECUTE, STEP_TYPE.SET_LOCAL_VARIABLE] as Step['type'][]).includes(step.type);
    const isReadOnly = readOnly || isNotEditable;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getValidationSchema}
        onSubmit={handleSubmit}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <StepDetailsContent
          context={context}
          step={step!}
          testRunId={testRunId}
          onClose={handleClose}
          temporary={isTemporary}
          readOnly={isReadOnly}
        />
      </Formik>
    );
  },
);

interface StepDetailsContentProps extends Omit<StepDetailsProps, 'stepId'> {
  step: Step | TemporaryStep;
  temporary: boolean;
}

const StepDetailsContent = ({
  context,
  step,
  onClose,
  readOnly,
  temporary,
  testRunId,
}: StepDetailsContentProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLFormElement>(null);
  const modal = useModal();
  const dispatch = useAppDispatch();

  const { setSubmitting, setErrors, handleSubmit, isSubmitting, dirty } = useFormikContext();

  useLeavePagePrompt(dirty, STEP_UNSAVED_CHANGES);

  const handleFailure = useCallback(
    (errors) => {
      setSubmitting(false);
      if (errors) {
        setErrors(errors);
      }
    },
    [setSubmitting, setErrors],
  );

  const invalidateStep = useCallback(
    (stepId, runId) =>
      dispatch(stepsApi.util.invalidateTags([{ type: TAG, id: joinAllArgs(stepId, runId) }])),
    [dispatch],
  );

  const handleSaveSuccess = useCallback(() => {
    /*
      TODO: This is a temporary solution to invalidate the step after saving.
      It should be removed after migrating step update to RTK
    */
    invalidateStep(step.id, testRunId);
    setSubmitting(false);
  }, [invalidateStep, step.id, testRunId, setSubmitting]);

  const handleCreateSuccess = useCallback(() => {
    setSubmitting(false);
    onClose();
  }, [setSubmitting, onClose]);

  const saveStepRequestState = useActionState(TestActions.saveStepSettingsRequest, {
    onFailure: handleFailure,
    onSuccess: handleSaveSuccess,
    reqId: step.id,
  });

  const createStepRequestState = useActionState(TestActions.saveTemporaryStepRequest, {
    onFailure: handleFailure,
    onSuccess: handleCreateSuccess,
    reqId: step.id,
  });

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        (dirty || temporary) &&
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        // is not a ariakit backdrop
        !document.querySelector('[data-backdrop]')?.contains(event.target) &&
        // is not a ariakit modal
        !document.querySelector('[data-dialog]')?.contains(event.target) &&
        // is not a modal
        !document.querySelector('.backdrop')?.contains(event.target) &&
        // is not a dropdown
        !document.querySelector('#layers')?.contains(event.target) &&
        // test step side panel resizer
        !document.querySelector('.Resizer')?.contains(event.target)
      ) {
        event.preventDefault();
        event.stopPropagation();
        modal.show('unsaved_changes', {
          onSave: handleSubmit,
          onDiscard: onClose,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSubmit, onClose, dirty, temporary],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, true);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, true);
    };
  }, [handleOutsideClick]);

  useUpdateEffect(() => {
    setSubmitting(false);
  }, [step.id]);

  const handleClick = useCallback(() => handleSubmit(), [handleSubmit]);

  const isPrimaryActionPending =
    isSubmitting || saveStepRequestState.isLoading || createStepRequestState.isLoading;

  const stepRun = useAppSelector(selectStepRun(step.id)) as unknown as StepRun | undefined;

  return (
    <S.Container data-testid="StepDetailsContent" ref={containerRef}>
      <Prompt message={STEP_UNSAVED_CHANGES} when={!!temporary || dirty} />
      <S.Header>
        <S.TitleContainer>
          <S.Title as="h2">{t('stepDetails.header.title', 'Step details')}</S.Title>
          <S.ActionButton onClick={onClose} disabled={isSubmitting} bordered>
            {t('default.button.cancel')}
          </S.ActionButton>
          {!readOnly && (
            <>
              <S.ActionButton
                key={step.id}
                onClick={handleClick}
                pending={isPrimaryActionPending}
                disabled={isPrimaryActionPending}
                succeeded={saveStepRequestState.isSuccess || createStepRequestState.isSuccess}
              >
                {temporary ? t('default.button.create') : t('default.button.save')}
              </S.ActionButton>
              <Tooltip
                anchor="bottom-end"
                content={
                  readOnly || temporary
                    ? t(
                        'stepDetails.moreActions.temporaryStepTooltip',
                        'You need to create this step until actions are available',
                      )
                    : undefined
                }
              >
                <S.MoreActions
                  stepId={step.id}
                  groupId={step.groupId}
                  disabled={readOnly || temporary}
                  active={step.isActive}
                  bordered
                />
              </Tooltip>
            </>
          )}
        </S.TitleContainer>
        <StepRunMetadata stepId={step.id} />
      </S.Header>
      <S.PaidFeatureBanner messageFor={step.type} />
      <StepDetailsEditor
        step={step}
        context={context}
        readOnly={readOnly}
        failedStep={!!stepRun && isFailedStatus(stepRun.status)}
      />
    </S.Container>
  );
};

StepDetailsContent.displayName = 'StepDetailsContent';

StepDetails.displayName = 'StepDetails';
