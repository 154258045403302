import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { BADGE_VARIANT } from './Badge.constants';
import * as S from './Badge.styled';

const Badge = ({ className, value, children, variant, limit, hidden = false }) => {
  const badgeValue = useCallback(() => {
    if (limit && value > limit) {
      return `${limit}+`;
    }

    return value;
  }, [value, limit]);

  return (
    <S.BadgeContainer className={className} data-testid="Badge">
      <S.BadgeContent>{children}</S.BadgeContent>

      {!hidden && (
        <S.BadgeItem variant={variant} value={value}>
          {badgeValue()}
        </S.BadgeItem>
      )}
    </S.BadgeContainer>
  );
};

Badge.defaultProps = {
  className: null,
  variant: BADGE_VARIANT.DEFAULT,
  limit: null,
};

Badge.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]).isRequired,
  variant: PropTypes.oneOf(Object.values(BADGE_VARIANT)),
  limit: PropTypes.number,
};

export default Badge;
