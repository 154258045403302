import { createApi } from '@reduxjs/toolkit/query/react';

import type { Project } from '@bugbug/core/types/projects';
import type { StepRun } from '@bugbug/core/types/tests';
import type { User } from '@bugbug/core/types/users';
import { axiosBaseQuery } from '~/services/api/baseQuery';
import urls, { reverse } from '~/views/urls';

import config from '../config';

const TAG = 'Core' as const;
export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [TAG],

  endpoints: (builder) => ({
    createSupportTicket: builder.mutation<
      void,
      {
        description: string;
        userAllowedToRunTests: boolean;
        project: Project;
        user: User;
        failedStepRun: StepRun;
        organizationId: string;
      }
    >({
      query: ({
        description,
        userAllowedToRunTests,
        organizationId,
        project,
        failedStepRun,
        user,
      }) => {
        const { stepId, testRunId, id: stepRunId, testId } = failedStepRun;
        const viewUrl = reverse(urls.testRun, {
          organizationId,
          projectId: project.id,
          projectSlug: project.slug,
          testRunId,
        });

        const name = [user.firstName, user.lastName].filter(Boolean).join(' ') || 'Unknown';

        const subject = `BugBug support request (testRunId: ${testRunId})`;
        const url = `${config.WEBAPP_URL}${viewUrl}`;

        let fullMessage = '';
        fullMessage += `Test run url: ${url}\n`;
        fullMessage += `Can the support team run a test: ${userAllowedToRunTests ? 'yes' : 'no'}\n`;
        fullMessage += `Test id: ${testId}\n`;
        fullMessage += `Test run id: ${testRunId}\n\n`;
        fullMessage += `Message body:\n ${description}`;

        return {
          url: '/tickets/',
          method: 'POST',
          data: {
            subject,
            name,
            description: fullMessage.replace(/\n/g, '<br>'),
            email: user.email,
            type: 'Support request',
            status: 2,
            priority: 1,
            custom_fields: {
              cf_userid: user.id,
              cf_url: url,
              cf_testrunid: testRunId,
              cf_steprunid: stepRunId,
              cf_stepid: stepId,
              cf_organizationid: organizationId,
              cf_projectid: project.id,
              cf_userallowedtoruntests: userAllowedToRunTests,
            },
          },
        };
      },
    }),
  }),
});

export const { useCreateSupportTicketMutation } = coreApi;
