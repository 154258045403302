import memoize from 'lodash.memoize';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Content, Header } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import {
  selectCurrentOrganization,
  selectOrganizationsList,
} from '~/modules/organization/organization.selectors';
import analytics, { TRACK_EVENT_ARG_TYPE, TRACK_EVENT_TYPE } from '~/services/analytics';
import urls, { reverse } from '~/views/urls';

import * as S from './SwitchOrganizationModal.styled';

const trackOrganizationSwitch = memoize((organizationName) => () => {
  analytics.trackEvent(TRACK_EVENT_TYPE.CHANGE_ORGANIZATION, {
    [TRACK_EVENT_ARG_TYPE.NEXT_ORGANIZATION_NAME]: organizationName,
  });
});
const SwitchOrganizationModal = () => {
  const { t } = useTranslation();
  const modal = useModal();
  const history = useHistory();
  const organizations = useSelector(selectOrganizationsList);
  const currentOrganization = useSelector(selectCurrentOrganization);

  const handleResultClick = useCallback(
    (organizationId, name) => () => {
      trackOrganizationSwitch(name);
      history.push(reverse(urls.projectsList, { organizationId }));
      modal.hide();
    },
    [history, modal],
  );

  const handleCreateOrganization = useCallback(() => {
    history.push(urls.newOrganization);
    modal.hide();
  }, [modal, history]);

  return (
    <S.Container data-testid="SwitchOrganizationModal">
      <Header>{t('switchOrganizationModal.title', 'Switch organization')}</Header>
      <Content>
        <S.Results data-testid="SwitchOrganizationModal.Results">
          {organizations.map(({ name, id }, index) => (
            <S.Result
              value={index}
              tabIndex={index + 1}
              key={id}
              onClick={id === currentOrganization.id ? undefined : handleResultClick(id, name)}
              disabled={id === currentOrganization.id}
            >
              {name}
            </S.Result>
          ))}
        </S.Results>

        <S.Button onClick={handleCreateOrganization}>
          <S.CreateIcon />
          {t('switchOrganizationModal.create', 'New organization')}
        </S.Button>
      </Content>
    </S.Container>
  );
};

export default SwitchOrganizationModal;
