import { DropdownItem, DROPDOWN_VARIANT, DROPDOWN_ANCHOR } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type React from 'react';
import type { Row } from 'react-table';

import type { DropdownAction } from '@bugbug/core/components/Dropdown';
import type { Component } from '@bugbug/core/types/components';
import { VisibleOnRowHover } from '~/components/Table';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal/useModal';

import * as S from './ComponentRowActions.styled';

interface ComponentRowActionsProps {
  row: Row<Component>;
}

const ComponentRowActions = ({ row }: ComponentRowActionsProps) => {
  const { t } = useTranslation();
  const { params } = useAppRoutes('component');
  const modal = useModal();
  const [areActionsLocked, setAreActionsLocked] = useState<boolean>(false);

  const component = row.original;
  const { testsCount } = component;

  const relatedTestsLabel =
    testsCount === 1
      ? t('componentRowActions.showRelatedTests.singleTest.label', 'Show 1 related test')
      : t(
          'componentRowActions.showRelatedTests.multipleTests.label',
          'Show {{testsCount}} related tests',
          { testsCount },
        );

  const handleClone = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    () => modal.show('clone_component', { component }),
    [modal, component],
  );

  const handleRename = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    () => modal.show('edit_component', { component }),
    [modal, component],
  );

  const handleShowRelatedTests = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    () => modal.show('show_component_related_tests', { params, component }),
    [component, modal, params],
  );

  const handleDeleteComponent = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    () => modal.show('delete_component', { data: component }),
    [component, modal],
  );

  const actions = useMemo<DropdownAction[]>(
    () => [
      {
        name: t('componentRowActions.clone.label', 'Duplicate'),
        onClick: handleClone,
        iconName: 'clone',
        dataTestId: 'TestRowActions.CloneButton',
      },
      {
        name: t('componentRowActions.rename.label', 'Rename'),
        onClick: handleRename,
        iconName: 'edit',
        dataTestId: 'TestRowActions.RenameButton',
      },
      {
        name: relatedTestsLabel,
        onClick: handleShowRelatedTests,
        iconName: 'formatListBulleted',
        dataTestId: 'TestRowActions.RenameButton',
        disabled: testsCount === 0,
      },
      {
        name: t('componentRowActions.delete.label', 'Delete'),
        onClick: handleDeleteComponent,
        iconName: 'delete',
        dataTestId: 'TestRowActions.DeleteButton',
        danger: true,
      },
    ],
    [
      t,
      handleClone,
      handleDeleteComponent,
      handleRename,
      handleShowRelatedTests,
      relatedTestsLabel,
      testsCount,
    ],
  );

  const lockActionButtons = useCallback(() => setAreActionsLocked(true), []);
  const unlockActionButtons = useCallback(() => setAreActionsLocked(false), []);

  return (
    <S.Container data-testid="ComponentRowActions">
      <VisibleOnRowHover force={areActionsLocked}>
        <S.Dropdown
          variant={DROPDOWN_VARIANT.ICON}
          anchor={DROPDOWN_ANCHOR.BOTTOM_END}
          iconName="more"
          onOpen={lockActionButtons}
          onClose={unlockActionButtons}
        >
          {actions.map(({ danger, name, onClick, iconName, dataTestId, disabled }) => (
            <DropdownItem
              danger={danger}
              data-testid={dataTestId}
              disabled={disabled}
              key={name}
              onClick={onClick}
            >
              <Icon name={iconName} /> {name}
            </DropdownItem>
          ))}
        </S.Dropdown>
      </VisibleOnRowHover>
    </S.Container>
  );
};
export default ComponentRowActions;
