import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Profile } from '@bugbug/core/types/profiles';
import type { Route } from '@bugbug/core/types/routes';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { selectProfile } from '~/modules/profile/profile.selectors';
import { useAppSelector } from '~/modules/store';

const ProfilesModalRoute = () => {
  const { t } = useTranslation();

  const modal = useModal();
  const { params, push } = useAppRoutes('profile');

  const { profileId } = params;

  const profile = useAppSelector(selectProfile(profileId)) as unknown as Profile | undefined;

  const handleHide = useCallback(
    (route: Route = 'profiles') => {
      push(route);
    },
    [push],
  );

  useEffect(() => {
    if (!profileId) return;

    if (profileId === 'new') {
      modal.show('edit_profile', {}, { onHide: handleHide });
      return;
    }

    if (!profile) return;
    modal.show('edit_profile', { profile }, { onHide: handleHide });
  }, [handleHide, modal, t, profile, profileId]);

  return null;
};

export { ProfilesModalRoute };
