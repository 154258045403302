import Tooltip from '@bugbug/core/components/Tooltip';
import { noop } from '@bugbug/core/utils/toolbox';
import { useState, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';

import type React from 'react';

import localStorage from '~/services/localStorage';

import { TRANSITION_TIME } from './CollapsibleSection.constants';
import * as S from './CollapsibleSection.styled';

interface CollapsibleSectionProps {
  className?: string;
  label?: string;
  children?: React.ReactNode;
  defaultExpanded?: boolean;
  helperText?: string;
  'data-testid'?: string;
  onEntered?: () => void;
  expandedCacheName?: string;
}

export interface CollapsibleSectionRef {
  open: () => void;
}

export const CollapsibleSection = forwardRef<CollapsibleSectionRef, CollapsibleSectionProps>(
  (props, ref) => {
    const {
      className,
      label,
      children,
      'data-testid': dataTestId = 'CollapsibleSection',
      defaultExpanded,
      helperText,
      onEntered = noop,
      expandedCacheName = '',
    } = props;
    const cacheKey = `section.${expandedCacheName.toLowerCase()}.expanded`;

    const cachedExpanded = useMemo(
      () => localStorage.getUserItem(cacheKey) ?? defaultExpanded,
      [defaultExpanded, cacheKey],
    );
    const [expanded, setExpanded] = useState(cachedExpanded);

    const handleChange = useCallback(
      (event, newExpanded) => {
        setExpanded(newExpanded);
        localStorage.setUserItem(cacheKey, newExpanded);
      },
      [cacheKey],
    );

    useImperativeHandle(
      ref,
      () => ({
        open: () => handleChange(null, true),
      }),
      [handleChange],
    );

    const transitionProps = useMemo(
      () => ({
        onEntered,
        timeout: TRANSITION_TIME,
      }),
      [onEntered],
    );

    return (
      <S.Container
        className={className}
        expanded={expanded}
        data-testid={dataTestId}
        onChange={handleChange}
        TransitionProps={transitionProps}
      >
        <S.Header>
          <S.Expander active={expanded} />
          {label}
          {helperText && (
            <Tooltip content={helperText}>
              <S.HelperIcon />
            </Tooltip>
          )}
        </S.Header>
        <S.Content>{children}</S.Content>
      </S.Container>
    );
  },
);

CollapsibleSection.displayName = 'CollapsibleSection';
