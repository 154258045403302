import * as R from 'ramda';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Component } from '@bugbug/core/types/components';
import type { SideEffect } from '@bugbug/core/types/utils';
import { ConfirmModal } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import { useCloneComponentMutation } from '~/modules/components/components.api';

interface CloneComponentProps {
  component: Component;
  onCloneSuccess?: SideEffect<Component['id']>;
}

const CloneComponentModal = ({
  component,
  onCloneSuccess = R.always(undefined),
}: CloneComponentProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const [handleClone, { data: newComponent, isError, isLoading, isSuccess }] =
    useCloneComponentMutation();

  useEffect(() => {
    if (isSuccess && newComponent) {
      onCloneSuccess(newComponent.id);
      modal.hide();
    }
  }, [isSuccess, modal, newComponent, onCloneSuccess]);

  const handleCloneConfirm = useCallback(() => {
    handleClone(component.id);
  }, [component, handleClone]);

  return (
    <ConfirmModal
      hasInternalServerError={isError}
      internalServerErrorMessage={t('cloneComponentModal.error.cloneError', 'Duplicating failed.')}
      onCancel={modal.hide}
      onConfirm={handleCloneConfirm}
      pending={isLoading}
      title={t('cloneComponentModal.title', 'Do you want to duplicate this component?')}
    />
  );
};

export default CloneComponentModal;
