import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDragLines = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 5, height: 15, viewBox: "0 0 5 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { y: 0.5, width: 5, height: 2, rx: 1, fill: "#AAAAAA" }),
  /* @__PURE__ */ jsx("rect", { y: 4.5, width: 5, height: 2, rx: 1, fill: "#AAAAAA" }),
  /* @__PURE__ */ jsx("rect", { y: 8.5, width: 5, height: 2, rx: 1, fill: "#AAAAAA" }),
  /* @__PURE__ */ jsx("rect", { y: 12.5, width: 5, height: 2, rx: 1, fill: "#AAAAAA" })
] });
const ForwardRef = forwardRef(SvgDragLines);
export default ForwardRef;
