import { noop } from '@bugbug/core/utils/toolbox';
import { useTransition } from '@react-spring/web';
import { useMountedState } from 'react-use';

import type { CSSProperties } from 'styled-components';

import type { SideEffect } from '@bugbug/core/types/utils';

import { getActiveState, STATE_COMPONENT_MAP } from './EmptyState.constants';
import * as S from './EmptyState.styled';

interface EmptyStateProps {
  className?: string;
  isVisible: boolean;
  isRecording: boolean;
  isFirstRecording: boolean;
  onRecordClick: SideEffect;
  onNewStepClick: SideEffect;
  onInsertComponentClick: SideEffect;
  onPasteStepsClick: SideEffect;
  disabledPasting: boolean;
  initialRecordingUrl: string;
}

export const EmptyState = ({
  isVisible,
  isRecording,
  isFirstRecording,
  disabledPasting,
  initialRecordingUrl,
  onPasteStepsClick,
  onRecordClick = noop,
  onNewStepClick = noop,
  onInsertComponentClick = noop,
}: EmptyStateProps) => {
  const activeState = getActiveState({ isVisible, isRecording });
  const isMounted = useMountedState();

  const transition = useTransition(activeState, {
    from: { position: 'absolute', opacity: Number(!isMounted()) },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transition((styles, item, t, i) => {
    const Component = STATE_COMPONENT_MAP[item];
    const isComponentVisible = activeState === item;

    return (
      <S.StateWrapper
        key={i}
        style={styles as unknown as CSSProperties}
        data-testid="EmptyStateWrapper"
      >
        <Component
          isFirstRecording={isFirstRecording}
          isVisible={isComponentVisible}
          onRecordClick={onRecordClick}
          onNewStepClick={onNewStepClick}
          onInsertComponentClick={onInsertComponentClick}
          onPasteStepsClick={onPasteStepsClick}
          initialRecordingUrl={initialRecordingUrl}
          disabledPasting={disabledPasting}
        />
      </S.StateWrapper>
    );
  });
};
