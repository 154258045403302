import Icon from '@bugbug/core/components/Icon';
import { isCustomSelector } from '@bugbug/core/types/steps';
import { createSelectorsGroup, getActiveSelectorFromGroup } from '@bugbug/core/utils/selectors';
import { useTranslation } from 'react-i18next';

import type { Selector, SelectorsGroup, SelectorsPreset } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';

import { SelectorsGroup as SelectorsGroupComponent } from '../ElementSelectorBuilder.styled';
import { SelectorsGroupField } from '../SelectorsGroupField/SelectorsGroupField';

import * as S from './SuggestedSelectorsPresets.styled';

interface SuggestedSelectorsPresetsProps {
  presets: SelectorsPreset[];
  name: string;
  context?: 'component' | 'test' | 'testRun';
  onChange: SideEffect<SelectorsPreset>;
  onCustomize: SideEffect<number>;
}

interface InitialSelector extends Selector {
  presetId: SelectorsPreset['id'];
  showThenFind: number;
}

export const SuggestedSelectorsPresets = ({
  name,
  presets,
  context,
  onChange,
  onCustomize,
}: SuggestedSelectorsPresetsProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'elementSelectorBuilder.suggestedSelectorsPresets',
  });

  const activePreset = presets.find((preset) => preset.isActive) ?? presets[0];

  // It's starting point so selected selector defines which preset is active
  const initialGroup = createSelectorsGroup({
    selectors: presets
      .filter(({ isCustom }) => !isCustom)
      .flatMap((preset) =>
        preset.selectorsGroups[0].selectors.map((selector) => ({
          ...selector,
          presetId: preset.id,
          showThenFind: preset.selectorsGroups.length > 1 && !isCustomSelector(selector),
        })),
      ),
  });

  const handleInitialGroupChange = (updatedGroup: SelectorsGroup) => {
    const activeSelector = getActiveSelectorFromGroup(updatedGroup) as InitialSelector;
    const relatedPreset = presets.find(({ id }) => id === activeSelector.presetId);

    if (!relatedPreset) {
      throw new Error('Related preset does not exists on the list');
    }

    onChange({
      ...relatedPreset,
      selectorsGroups: relatedPreset.selectorsGroups.toSpliced(0, 1, updatedGroup),
    });
  };

  const handleGroupChange = (index: number) => (updatedGroup: SelectorsGroup) => {
    onChange({
      ...activePreset,
      selectorsGroups: activePreset.selectorsGroups.toSpliced(index, 1, updatedGroup),
    });
  };

  const handleCustomizeClick = (index: number) => () => onCustomize(index);

  const renderSelector = (selectorName: string, selector: Selector) => (
    <>
      {selectorName}
      {(selector as InitialSelector).showThenFind && (
        <S.OptionThenFind>
          <Icon name="cornerDownRight" />
          {t('optionThenFind', 'then find...')}
        </S.OptionThenFind>
      )}
    </>
  );

  return (
    <>
      <SelectorsGroupComponent first>
        <SelectorsGroupField
          key="initial"
          name="initialSelector"
          value={initialGroup}
          onChange={handleInitialGroupChange}
          onCustomize={handleCustomizeClick(0)}
          renderSelector={renderSelector}
          context={context}
          selectOnly
          first
        />
      </SelectorsGroupComponent>
      {activePreset.selectorsGroups.map((selectorsGroup, index) =>
        index === 0 ? undefined : (
          <SelectorsGroupComponent key={selectorsGroup.id}>
            <SelectorsGroupField
              name={`${name}.selectorsGroups[${index}]`}
              value={selectorsGroup}
              onChange={handleGroupChange(index)}
              onCustomize={handleCustomizeClick(index)}
              renderSelector={renderSelector}
              context={context}
              selectOnly
            />
          </SelectorsGroupComponent>
        ),
      )}
    </>
  );
};
