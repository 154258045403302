/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { pick } from 'ramda';
import React from 'react';

import i18n from '~/translations';

import ScreenSizeCell from './components/ScreenSizeCell';
import SelectionCell from './components/SelectionCell';
import SelectionIconCell from './components/SelectionIconCell';
import { HeaderSelectionCell, Expander } from './Table.styled';

export const PREDEFINED_COLUMN = {
  SELECTION: {
    id: 'selection',
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <HeaderSelectionCell {...getToggleAllRowsSelectedProps()} />
    ),
    Cell: ({ row, ...props }) => <SelectionCell {...row.getToggleRowSelectedProps()} {...props} />,
    width: 32,
    maxWidth: 32,
  },
  SELECTION_ICON: {
    id: 'selection',
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <SelectionIconCell {...getToggleAllRowsSelectedProps()} />
    ),
    Cell: ({ row, ...props }) => (
      <SelectionIconCell {...row.getToggleRowSelectedProps()} {...props} />
    ),
    width: 36,
    maxWidth: 36,
  },
  EXPANDER: {
    id: 'expander',
    Cell: ({ row }) => (
      <Expander {...row.getToggleRowExpandedProps()} data-testid="Table.Expander" />
    ),
    width: 30,
    maxWidth: 30,
  },
  SCREEN: {
    Header: i18n.t('table.predefinedColumns.screenSize', 'Screen'),
    accessor: pick(['screenSizeType', 'browserWidth', 'browserHeight']),
    id: 'screen_size_type',
    Cell: ScreenSizeCell,
    maxWidth: 100,
  },
};
