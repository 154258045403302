import memoize from 'lodash.memoize';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DropdownPicker from '~/components/DropdownPicker';
import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';
import { selectCurrentOrganization } from '~/modules/organization/organization.selectors';
import { ProjectActions } from '~/modules/project/project.redux';
import { selectProjectList, selectSingleProject } from '~/modules/project/project.selectors';
import analytics, { TRACK_EVENT_ARG_TYPE, TRACK_EVENT_TYPE } from '~/services/analytics';
import urls, { reverse } from '~/views/urls';

const trackProjectSwitch = memoize((projectName) => () => {
  analytics.trackEvent(TRACK_EVENT_TYPE.OPEN_PROJECT, {
    [TRACK_EVENT_ARG_TYPE.NEXT_PROJECT_NAME]: projectName,
  });
});

const ProjectSwitch = () => {
  const { t } = useTranslation();
  const modal = useModal();
  const projects = useSelector(selectProjectList);
  const dispatch = useDispatch();
  const currentProject = useSelector(selectSingleProject);
  const history = useHistory();

  const currentOrganization = useSelector(selectCurrentOrganization);

  const handleCreateProject = useCallback(() => {
    modal.show(MODAL_TYPE.PROJECT);
  }, [modal]);

  useEffect(() => {
    dispatch(ProjectActions.getListRequest());
  }, [dispatch]);

  const items = useMemo(
    () =>
      projects.map((project) => ({
        ...project,
        onClick: () => {
          history.push(
            reverse(urls.project, {
              projectId: project.id,
              projectSlug: project.slug,
              organizationId: currentOrganization.id,
            }),
          );
          trackProjectSwitch(project.name);
        },
      })),
    [projects, currentOrganization.id, history],
  );

  if (!currentProject?.id) {
    return null;
  }

  return (
    <DropdownPicker
      value={currentProject?.id ? currentProject : undefined}
      items={items}
      onCreateClick={handleCreateProject}
      onCreateLabel={t('projectSwitch.create', 'Create project')}
    />
  );
};

export default ProjectSwitch;
