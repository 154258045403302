import styled from 'styled-components';

import ProfileSVG from '~/images/profile-logo.svg?react';

export const ProfileLogo = styled(ProfileSVG)`
  height: 32px;
  width: 32px;
  display: block;
  margin: 0 auto 20px auto;
`;
