import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { IconName } from '@bugbug/core/types/icons';
import type { Step } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';
import useAppRoutes from '~/hooks/useAppRoutes';
import useCopyHandler from '~/hooks/useCopyHandler';
import { useCopyPasteSteps } from '~/hooks/useCopyPasteSteps';
import useModal from '~/hooks/useModal';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectTestIsRecording } from '~/modules/test/test.selectors';
import analytics from '~/services/analytics';

import { Dropdown, Content } from './TestStepMoreActions.styled';

interface TestStepMoreActionsProps {
  className?: string;
  stepId: Step['id'];
  groupId: Step['groupId'];
  runResultEnabled?: boolean;
  active?: Step['isActive'];
  disabled?: boolean;
  bordered?: boolean;
  onOpen?: SideEffect;
  onClose?: SideEffect;
  onDelete?: SideEffect;
}

export const TestStepMoreActions = ({
  className,
  stepId,
  groupId,
  active = false,
  disabled = false,
  onOpen,
  onClose,
  bordered = false,
  runResultEnabled = false,
  onDelete,
}: TestStepMoreActionsProps) => {
  const dispatch = useAppDispatch();
  const modal = useModal();
  const appRoute = useAppRoutes('test');
  const { testId } = appRoute.params;
  const { t } = useTranslation();
  const { copySteps } = useCopyPasteSteps();
  const isRecording = useAppSelector(selectTestIsRecording);

  const handleCopyLinkToStep = useCopyHandler(
    runResultEnabled
      ? appRoute.getAbsoluteRouteUrl('testRun', {}, { stepId, groupId })
      : appRoute.getAbsoluteRouteUrl('test', { testId }, { stepId, groupId }),
    {
      success: t('testStepActions.copyLinkToStep.copyLinkToStepMsg.success', 'Link to step copied'),
      failure: t(
        'testStepActions.copyLinkToStep.copyLinkToStepMsg.failure',
        'Error when copying link to step',
      ),
    },
  );

  const openDeleteModal = useCallback(
    () =>
      modal.show('delete_steps', {
        testId,
        stepsIds: [stepId],
        onSuccess: onDelete,
      }),
    [modal, testId, stepId, onDelete],
  );

  const handleClone = useCallback(() => {
    analytics.trackEvent('step_duplicated');
    dispatch(TestActions.cloneStepRequest(stepId));
  }, [dispatch, stepId]);

  const handleCopy = useCallback(() => {
    copySteps([stepId]);
  }, [stepId, copySteps]);

  const handleToggleActive = useCallback(
    () => dispatch(TestActions.toggleStepActiveRequest(stepId)),
    [dispatch, stepId],
  );

  const actions = useMemo<
    {
      name: string;
      onClick: SideEffect;
      iconName: IconName;
      hidden?: boolean;
    }[]
  >(
    () =>
      runResultEnabled
        ? [
            {
              name: t('testStepActions.copyLinkToStep.label', 'Copy link to step'),
              onClick: handleCopyLinkToStep,
              iconName: 'link2',
            },
          ]
        : [
            {
              name: t('testStepActions.clone.label', 'Duplicate'),
              onClick: handleClone,
              iconName: 'clone',
            },
            {
              name: t('testStepActions.copy.label', 'Copy step'),
              onClick: handleCopy,
              iconName: 'copy',
            },
            {
              name: t('testStepActions.copyLinkToStep.label', 'Copy link to step'),
              onClick: handleCopyLinkToStep,
              iconName: 'link2',
            },
            {
              name: active
                ? t('testStepActions.setInactive.label', 'Deactivate step')
                : t('testStepActions.setActive.label', 'Activate step'),
              onClick: handleToggleActive,
              iconName: active ? 'layersClear' : 'layers',
            },
            {
              name: t('testStepActions.remove.label', 'Delete'),
              onClick: openDeleteModal,
              iconName: 'delete',
              hidden: isRecording,
            },
          ],
    [
      t,
      handleClone,
      handleCopy,
      handleCopyLinkToStep,
      active,
      handleToggleActive,
      openDeleteModal,
      isRecording,
      runResultEnabled,
    ],
  );

  return (
    <Content className={className}>
      <Dropdown
        anchor="bottom-end"
        iconName="more"
        onOpen={onOpen}
        onClose={onClose}
        disabled={disabled}
        hideExpander
        rounded
        bordered={bordered}
        condensed
      >
        {actions
          .filter((a) => !a.hidden)
          .map(({ name, onClick, iconName }) => (
            <DropdownItem key={name} onClick={onClick}>
              <Icon name={iconName} /> {name}
            </DropdownItem>
          ))}
      </Dropdown>
    </Content>
  );
};
