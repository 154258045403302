import Button from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';

import type { FallbackRender } from '@sentry/react';

import i18n from '~/translations';

import * as S from './ErrorBoundary.styled';

const handleRefresh = () => {
  window.location.reload();
};

export const ErrorBoundary: FallbackRender = ({ eventId }) => (
  <S.Container aria-label={i18n.t('errorBoundary.label', 'Unhandled error')}>
    <Icon name="errorTriangle" />
    <S.Message>
      {i18n.t(
        'errorBoundary.message',
        'An unexpected, critical error has occured. Information has been sent to administrators.',
      )}
      <S.ErrorId>
        {i18n.t('errorBoundary.errorId', 'Error ID')}: {eventId ?? 'unknown'}
      </S.ErrorId>
      <Button onClick={handleRefresh}>{i18n.t('errorBoundary.button', 'Refresh the page')}</Button>
    </S.Message>
  </S.Container>
);
