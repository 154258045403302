import Dropdown, {
  DROPDOWN_VARIANT,
  ToggleButton,
  DROPDOWN_ANCHOR,
} from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 12px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

export const PlaceKeeper = styled.nav`
  position: absolute;
  left: 0;
  right: 0;
  height: 12px;
  transition: opacity 300ms ease;
  margin-top: -6px;
  z-index: 2;
  cursor: pointer;
`;

export const Line = styled(animated.span)`
  display: block;
  width: 100%;
  height: 2px;
  position: relative;
  top: 4px;
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const PausedRecordingLine = styled(animated.div)`
  height: 2px;
  width: 100%;
  position: relative;
  top: 4px;
  background-image: linear-gradient(
    to left,
    ${({ theme }) => theme.colors.accent} 4px,
    transparent 0%
  );
  background-position: bottom;
  background-size: 8px 2px;
  background-repeat: repeat-x;
`;

export const LineWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.common.transparent};
`;

export const Menu = styled(Dropdown).attrs(() => ({
  variant: DROPDOWN_VARIANT.PRIMARY,
  anchor: DROPDOWN_ANCHOR.BOTTOM_CENTER,
}))<{ paused: boolean }>`
  background-color: ${({ theme }) => theme.colors.common.white};
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.common.white};

  ${ToggleButton} {
    position: relative;
    top: -1px;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    padding: 0;
    display: flex;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.common.white};

    ${({ paused, theme }) =>
      css`
        color: ${paused ? theme.colors.status.paused : theme.colors.primary};
        border-color: currentColor;
      `}

    z-index: 5;
    pointer-events: none;
    border-width: 1px;
  }

  svg {
    font-size: 14px;
  }
`;

export const Dot = styled(Icon).attrs(() => ({
  name: 'dot',
}))`
  color: ${({ theme }) => theme.colors.primary};

  && {
    font-size: 16px;
  }
`;

export const PlaybackIcon = styled(Icon).attrs(() => ({
  name: 'play',
}))`
  color: ${({ theme }) => theme.colors.status.paused};
`;

export const Paste = styled(Icon).attrs(() => ({
  name: 'paste',
}))`
  && {
    max-height: 18px;
  }
`;

export const OptionsSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border.light};
`;
