import { createAction } from '@reduxjs/toolkit';

import type { Group } from '@bugbug/core/types/groups';
import type { PlaybackCursorPosition } from '@bugbug/core/types/playback';
import type { Step } from '@bugbug/core/types/steps';
import type { Test } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';

const testUpdated = createAction<{
  testId: Test['id'];
  steps: {
    added: Step[];
    modified: Partial<Step>[];
    removed: Step['id'][];
  };
  group: Group;
}>('backend/testUpdated');

const sessionPauseRequested = createAction<Omit<PlaybackCursorPosition, 'runningStep'>>(
  'backend/sessionPauseRequested',
);

const recordingStarted = createAction<{
  intoGroupId: Group['id'];
  afterStepId: Maybe<Step['id']>;
}>('backend/recordingStarted');

export const BackendActions = {
  testUpdated,
  sessionPauseRequested,
  recordingStarted,
} as const;
