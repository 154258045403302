import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import SearchInput from '@bugbug/core/components/SearchInput';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Badge, { BADGE_LIMIT } from '~/components/Badge';
import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';
import { SuiteRunActions } from '~/modules/suiteRun/suiteRun.redux';
import { selectAddedSuiteRuns } from '~/modules/suiteRun/suiteRun.selectors';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import { selectAddedTestRuns } from '~/modules/testRun/testRun.selectors';
import { TAB } from '~/views/RunsHistory/RunsHistory.constants';

import { Container, Button, Menu } from './RunsHistoryActions.styled';

const RunsHistoryActions = ({ className, query, type, onRefresh, onQueryChange, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const addedTestsRuns = useSelector(selectAddedTestRuns);
  const addedSuiteRuns = useSelector(selectAddedSuiteRuns);
  const isTestsTab = type === TAB.TESTS;
  const addedRuns = isTestsTab ? addedTestsRuns : addedSuiteRuns;

  const handleDeleteAllSuiteRuns = useCallback(
    () => modal.show(MODAL_TYPE.DELETE_SUITE_RUNS, { onDelete }),
    [modal, onDelete],
  );

  const handleDeleteAllTestRuns = useCallback(
    () => modal.show(MODAL_TYPE.DELETE_TEST_RUNS, { onDelete }),
    [modal, onDelete],
  );

  const handleStopAllTests = useCallback(() => {
    dispatch(TestRunActions.stopRequest());
  }, [dispatch]);

  const handleStopAllSuites = useCallback(() => {
    dispatch(SuiteRunActions.stopRequest());
  }, [dispatch]);

  const renderSuiteRunsMenuItems = renderWhenTrue(() => (
    <>
      <DropdownItem onClick={handleStopAllSuites}>
        <Icon name="stop" /> {t('runsHistoryActions.suiteRuns.stopAll', 'Stop all suites')}
      </DropdownItem>
      <DropdownItem danger onClick={handleDeleteAllSuiteRuns}>
        <Icon name="delete" /> {t('runsHistoryActions.suiteRuns.clearAll', 'Clear all history')}
      </DropdownItem>
    </>
  ));

  const renderTestRunsMenuItems = renderWhenTrue(() => (
    <>
      <DropdownItem onClick={handleStopAllTests}>
        <Icon name="stop" /> {t('runsHistoryActions.testRuns.stopAll', 'Stop all tests')}
      </DropdownItem>
      <DropdownItem danger onClick={handleDeleteAllTestRuns}>
        <Icon name="delete" /> {t('runsHistoryActions.testRuns.clearAll', 'Clear all history')}
      </DropdownItem>
    </>
  ));

  return (
    <Container className={className} data-testid="RunsHistoryActions">
      <SearchInput value={query} onChange={onQueryChange} />
      <Badge value={addedRuns.length} hidden={!addedRuns.length} limit={BADGE_LIMIT.DEFAULT}>
        <Button
          data-testid="RunsHistoryActions.RefreshButton"
          iconName="refresh"
          onClick={onRefresh}
        >
          {t('runsHistoryActions.testRuns.refresh', 'Refresh')}
        </Button>
      </Badge>
      <Menu data-testid="RunsHistoryActions.Menu" iconName="more" hideExpander rounded condensed>
        {renderSuiteRunsMenuItems(!isTestsTab)}
        {renderTestRunsMenuItems(isTestsTab)}
      </Menu>
    </Container>
  );
};

RunsHistoryActions.defaultProps = {
  className: null,
  query: '',
};

RunsHistoryActions.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default RunsHistoryActions;
