import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrueOtherwise } from '@bugbug/core/utils/rendering';
import React, { useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'react-use';

import ProjectItem from '~/components/ProjectItem';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import { ONBOARDING_STEP, QUERY_ACTION } from '~/modules/constans';
import { OrganizationActions } from '~/modules/organization/organization.redux';
import {
  selectIsTrialActive,
  selectIsTrialEnded,
} from '~/modules/organization/organization.selectors';
import { ProfileActions } from '~/modules/profile/profile.redux';
import { ProjectActions } from '~/modules/project/project.redux';
import { selectProjectList } from '~/modules/project/project.selectors';
import { useAppSelector } from '~/modules/store';
import { SuiteActions } from '~/modules/suite/suite.redux';
import { SuiteRunActions } from '~/modules/suiteRun/suiteRun.redux';
import { TestActions } from '~/modules/test/test.redux';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import { UserActions } from '~/modules/user/user.redux';
import { selectCurrentOnboardingStep, selectIsInvited } from '~/modules/user/user.selectors';
import analytics, { TRACK_EVENT_ARG_TYPE, TRACK_EVENT_ARG_VALUE } from '~/services/analytics';
import ProjectDetails from '~/views/ProjectDetails';
import urls, { reverse } from '~/views/urls';

import {
  AddIcon,
  Container,
  LoaderWrapper,
  NewProjectContent,
  NewProjectItem,
  NewProjectText,
  ProjectItemsContainer,
  Projects,
} from './ProjectList.styled';

const ProjectList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const { organizationId } = useParams();
  const history = useHistory();
  const queryParams = useQueryString();
  const projects = useAppSelector(selectProjectList);
  const isTrialEnded = useAppSelector(selectIsTrialEnded);
  const isTrialActive = useAppSelector(selectIsTrialActive);
  const isInvitedUser = useAppSelector(selectIsInvited);
  const onboardingStep = useAppSelector(selectCurrentOnboardingStep);
  const appRoutes = useAppRoutes('projectsList');

  useEffect(() => {
    dispatch(OrganizationActions.switchOrganizationRequest(organizationId));
  }, [dispatch, organizationId]);

  const { isSuccess, isFailure, hasInternalServerError } = useActionState(
    ProjectActions.getListRequest,
    {
      reset: false,
    },
  );

  const handleCreateProject = useCallback(() => {
    appRoutes.push('projectsNew');
  }, [appRoutes]);

  const loadProjects = useCallback(() => {
    dispatch(ProjectActions.getListRequest());
  }, [dispatch]);

  const handleStartTrial = useCallback(() => modal.show('start_trial'), [modal]);

  useMount(() => {
    if (!isTrialEnded && !isTrialActive && queryParams.action === QUERY_ACTION.START_TRIAL) {
      handleStartTrial();
      history.replace(reverse(urls.projectsList, { organizationId }));
    }
  });

  useUpdateEffect(() => {
    if (isSuccess && !projects.length) {
      handleCreateProject();
    }
  }, [projects, isSuccess]);

  useEffect(() => {
    if (!isInvitedUser || onboardingStep !== ONBOARDING_STEP.WIZARD_COMPLETED || !organizationId) {
      return;
    }

    modal.show('onboarding', undefined, {
      stacked: true,
      onCloseButtonClick: () => {
        analytics.trackEvent('app_entry_modal_clicked', {
          [TRACK_EVENT_ARG_TYPE.ORGANIZATION_ID]: organizationId,
          [TRACK_EVENT_ARG_TYPE.CONTEXT]: 'onboarding',
          [TRACK_EVENT_ARG_TYPE.USER_TYPE]: isInvitedUser
            ? TRACK_EVENT_ARG_VALUE.USER_TYPE_INVITED
            : TRACK_EVENT_ARG_VALUE.USER_TYPE_OWNER,
          [TRACK_EVENT_ARG_TYPE.BUTTON_CLICK]: 'not_now',
        });
        dispatch(UserActions.selectInitialModalOptionRequest({ option: 'not_now' }));
      },
    });
  }, [isInvitedUser, modal, onboardingStep, organizationId, dispatch]);

  useEffect(() => {
    batch(() => {
      dispatch(ProjectActions.reset());
      dispatch(TestRunActions.reset());
      dispatch(SuiteRunActions.reset());
      dispatch(TestActions.reset());
      dispatch(SuiteActions.reset());
      dispatch(ProfileActions.reset());
    });
    loadProjects();
  }, [dispatch, organizationId, loadProjects]);

  const renderProjects = renderWhenTrueOtherwise(
    () => (
      <ProjectItemsContainer>
        <NewProjectItem
          type="button"
          onClick={handleCreateProject}
          data-testid="ProjectList.NewProjectButton"
        >
          <NewProjectContent>
            <AddIcon />
            <NewProjectText>{t('projectList.newProject', 'New project')}</NewProjectText>
          </NewProjectContent>
        </NewProjectItem>

        {projects.map((project) => (
          <ProjectItem project={project} key={project.id} />
        ))}
      </ProjectItemsContainer>
    ),
    () => (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    ),
  )(isSuccess || isFailure);

  return (
    <Container
      data-testid="ProjectList"
      title={t('projectList.title', 'Your projects')}
      centeredContent
    >
      <Helmet title={t('projectsList.pageTitle', 'Projects')} />
      <Projects>
        <ServerErrorInfo isVisible={hasInternalServerError} onRetry={loadProjects} />
        {renderProjects}
      </Projects>
      <Switch>
        <Route path={urls.projectsNew} component={ProjectDetails} exact />
        <Route path={urls.projectsEdit} component={ProjectDetails} exact />
      </Switch>
    </Container>
  );
};

export default ProjectList;
