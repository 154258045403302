import type { URLString, UUID } from './aliases';
import type { Maybe } from './utils';
import type { WaitingCondition } from './waitingConditions';

import { STEP_TYPE } from '../constants/steps';

// Base Step type
export interface BaseStep {
  type: StepType;
  id: string;
  isActive: boolean;
  order: number;
  sleep: Maybe<number>;
  runTimeout: Maybe<number>;
  continueOnFailure: boolean;
  frameLocation: string;
  waitingConditions: WaitingCondition[];
  created: string;
  group: string;
  groupId: string;
  groupName: string;
  isTargetDocument: boolean;
  modified: string;
  screenshot: string;
  screenshotData: string;
  user: string;
  tabNo: number;
  windowNo: number;
}

// Utility types
type Selectors = {
  selectorsPresets: SelectorsPreset[];
  tagName: string;
  tagAttributes: Record<string, string>;
  width: number;
  height: number;
};
type Value = { value: string };

export interface StepParamsByType {
  click: ClickStep;
  dblClick: DblClickStep;
  rightClick: RightClickStep;
  hover: HoverStep;
  mouseDown: MouseDownStep;
  mouseUp: MouseUpStep;
  mouseMove: MouseMoveStep;
  dragAndDrop: DragAndDropStep;
  scroll: ScrollStep;
  type: TypeStep;
  change: ChangeStep;
  clear: ClearStep;
  select: SelectStep;
  assert: AssertStep;
  goto: GotoStep;
  newTab: NewTabStep;
  closeTab: CloseTabStep;
  reloadPage: ReloadPageStep;
  execute: ExecuteStep;
  answerPrompt: AnswerPromptStep;
  setLocalVariable: SetLocalVariableStep;
  switchContext: SwitchContextStep;
  uploadFile: UploadFileStep;
}

export type Step =
  | MouseStep
  | ScrollStep
  | DragAndDropStep
  | InputStep
  | AssertStep
  | NavigationStep
  | AdvancedStep;

/*
  Mouse steps
*/
export type MouseStep =
  | ClickStep
  | DblClickStep
  | RightClickStep
  | HoverStep
  | MouseDownStep
  | MouseUpStep
  | MouseMoveStep
  | DragAndDropStep;
export type ClickStep = AbstractMouseStep<'click'>;
export type DblClickStep = AbstractMouseStep<'dblClick'>;
export type RightClickStep = AbstractMouseStep<'rightClick'>;
export type HoverStep = AbstractMouseStep<'hover'>;
export type MouseDownStep = AbstractMouseStep<'mouseDown'>;
export type MouseUpStep = AbstractMouseStep<'mouseUp'>;
export type MouseMoveStep = AbstractMouseStep<'mouseMove'>;
export type DragAndDropStep = DragAndDropCoordsStep | DragAndDropElementStep;

export interface DragAndDropCoordsStep extends AbstractMouseStep<'dragAndDrop'> {
  dndDragOn: 'coords';
  dndDragX: number;
  dndDragY: number;
  dndDropOn: 'coords';
  dndDropX: number;
  dndDropY: number;
  dndDropInteractionPosition: InteractionPosition;
}

export interface DragAndDropElementStep extends AbstractMouseStep<'dragAndDrop'> {
  type: 'dragAndDrop';
  dndDragOn: 'element';
  dndDropOn: 'element';
  dndDropInteractionPosition: InteractionPosition;
  dndDropSelectorsPresets: SelectorsPreset[];
}

interface AbstractMouseStep<
  T extends StepType,
  TInteraction extends InteractionPosition = InteractionPosition,
> extends BaseStep,
    Selectors {
  type: T;
  interactionPosition: TInteraction;
  clientX: 'custom' extends TInteraction ? number : never;
  clientY: 'custom' extends TInteraction ? number : never;
}

export type ScrollStep =
  | ScrollUntilNextStepElementVisibleView
  | ScrollElementIntoViewStep
  | ScrollToEdgeStep
  | ScrollToCoordsStep;

export type ScrollUntilNextStepElementVisibleView = AbstractScrollStep<
  ScrollInside,
  'untilNextStepElementIsVisible'
>;

export type ScrollElementIntoViewStep = AbstractScrollStep<'element', 'elementIntoView'>;

export interface ScrollToEdgeStep extends AbstractScrollStep<ScrollInside, 'edge'> {
  scrollEdge: InteractionPosition;
}

export interface ScrollToCoordsStep extends AbstractScrollStep<ScrollInside, 'coords'> {
  scrollX: number;
  scrollY: number;
}

interface AbstractScrollStep<TScrollInside extends ScrollInside, TScrollTo extends ScrollTo>
  extends BaseStep,
    Selectors {
  type: 'scroll';
  scrollTo: TScrollTo;
  scrollEdge: InteractionPosition;
  scrollDirection: ScrollDirection;
  scrollInside: TScrollInside;
}

export type ScrollInside = 'element' | 'window';

export type ScrollTo = 'coords' | 'edge' | 'elementIntoView' | 'untilNextStepElementIsVisible';

export type ScrollDirection = 'down' | 'left' | 'right' | 'up';

export interface ScrollPosition {
  scrollX: number;
  scrollY: number;
}

/*
  Input steps
*/
export type InputStep = TypeStep | ChangeStep | UploadFileStep | ClearStep | SelectStep;

export type TypeStep = AbstractInputStep<'type'>;
export type ChangeStep = AbstractInputStep<'change'>;
export type ClearStep = AbstractInputStep<'clear'> & { value: '' };
export type UploadFileStep = AbstractInputStep<'uploadFile'>;
export type SelectStep = AbstractInputStep<'select'> & {
  selectIsMultiple: boolean;
  selectType: 'index' | 'value' | 'text';
};

interface AbstractInputStep<T extends InputStepType> extends BaseStep, Selectors, Value {
  type: T;
  hasSecretValue: T extends 'type' ? boolean : T extends 'change' ? boolean : never;
}
type InputStepType = 'type' | 'change' | 'clear' | 'select' | 'uploadFile';

/*
  Assert steps
*/
export type AssertStep =
  | AssertElementVisibleStep
  | AssertElementNotVisibleStep
  | AssertElementHasTextStep
  | AssertValueStep
  | AssertCheckedStep
  | AssertNotCheckedStep
  | AssertPageDoesNotShowTextStep
  | AssertPageShowsTextStep
  | AssertPageTitleStep
  | AssertPageUrlIsStep
  | AssertDownloadStartedStep
  | AssertCountStep
  | AssertExistStep
  | AssertNotExistStep
  | AssertCustomJSStep
  | AssertVariableValueStep;

// Element assertions
export type AssertElementVisibleStep = AbstractAssertStep<'visible'>;
export type AssertElementNotVisibleStep = AbstractAssertStep<'notVisible'>;
export type AssertElementHasTextStep = AbstractAssertStep<'textContent'>;
// Form assertions
export type AssertValueStep = AbstractAssertStep<'value'>;
export type AssertCheckedStep = AbstractAssertStep<'checked'>;
export type AssertNotCheckedStep = AbstractAssertStep<'notChecked'>;
// Page assertions
type AssertPageStep =
  | AssertPageDoesNotShowTextStep
  | AssertPageShowsTextStep
  | AssertPageTitleStep
  | AssertPageUrlIsStep
  | AssertDownloadStartedStep;
export type AssertPageDoesNotShowTextStep = AbstractAssertPageStep<'pageDoesNotShowText'>;
export type AssertPageShowsTextStep = AbstractAssertPageStep<'pageShowsText'>;
export type AssertPageTitleStep = AbstractAssertPageStep<'pageTitle'>;
export type AssertPageUrlIsStep = AbstractAssertPageStep<'pageUrlIs'>;
export type AssertDownloadStartedStep = AbstractAssertPageStep<'downloadStarted'>;
// Advanced assertions
export type AssertCountStep = AbstractAssertStep<'count'>;
export type AssertExistStep = AbstractAssertStep<'exist'>;
export type AssertNotExistStep = AbstractAssertStep<'notExist'>;
export type AssertCustomJSStep = AbstractAssertStep<'customJavaScript'> & {
  assertionJavaScript: string;
};
export interface AssertVariableValueStep extends AbstractAssertPageStep<'variableValue'> {
  assertionVariableName: string;
}

type AbstractAssertPageStep<T extends AssertionProperty> = Omit<
  AbstractAssertStep<T>,
  keyof Selectors
>;

interface AbstractAssertStep<T extends AssertionProperty> extends BaseStep, Selectors {
  type: 'assert';
  assertionProperty: T;
  assertionType: AssertionType;
  assertionExpectedValue: T extends 'count' ? number : string;
}

/*
  Navigation steps
*/
type NavigationStep = GotoStep | NewTabStep | CloseTabStep | ReloadPageStep;

export interface GotoStep extends BaseStep {
  type: 'goto';
  url: URLString;
  username: string;
  password: string;
}

export interface NewTabStep extends BaseStep {
  type: 'newTab';
  url: URLString;
  username: string;
  password: string;
}

export interface CloseTabStep extends BaseStep {
  type: 'closeTab';
}

export interface ReloadPageStep extends BaseStep {
  type: 'reloadPage';
}

/*
  Advanced steps
*/
export type AdvancedStep =
  | ExecuteStep
  | AnswerPromptStep
  | SetLocalVariableStep
  | SwitchContextStep;

export type LocalVariableSource = 'element' | 'evaluate' | 'value';
interface AbstractSetLocalVariableStep<TSource extends LocalVariableSource> extends BaseStep {
  type: 'setLocalVariable';
  localVariableSource: TSource;
  localVariableName: string;
}

export type SetLocalVariableStep =
  | SetLocalElementVariableStep
  | SetLocalEvaluateVariableStep
  | SetLocalValueVariableStep;

export type SetLocalElementVariableStep = AbstractSetLocalVariableStep<'element'> & Selectors;
export type SetLocalEvaluateVariableStep = AbstractSetLocalVariableStep<'evaluate'> & {
  code: string;
};
export type SetLocalValueVariableStep = AbstractSetLocalVariableStep<'value'> & Value;

export interface SwitchContextStep extends BaseStep, Selectors {
  type: 'switchContext';
  tabNo: number;
}

// Other Steps
export interface ExecuteStep extends BaseStep {
  type: 'execute';
  code: string;
}

export interface AnswerPromptStep extends BaseStep, Value {
  type: 'answerPrompt';
}

// Utility types
export type AssertionProperty =
  | AssertionDocument
  | AssertionTrueFalse
  | 'count'
  | 'textContent'
  | 'value';

export type AssertionDocument =
  | 'pageDoesNotShowText'
  | 'pageShowsText'
  | 'pageTitle'
  | 'pageUrlIs'
  | 'downloadStarted';

export type AssertionTrueFalse =
  | 'checked'
  | 'customJavaScript'
  | 'exist'
  | 'notChecked'
  | 'notExist'
  | 'notVisible'
  | 'visible'
  | 'variableValue';

export type AssertionType =
  | 'equal'
  | 'notEqual'
  | 'contain'
  | 'notContain'
  | 'match'
  | 'notMatch'
  | 'greaterThan'
  | 'lessThan'
  | 'any';

export type InteractionPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'middleLeft'
  | 'middleCenter'
  | 'middleRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight'
  | 'smart'
  | 'custom';

export type StepType =
  | 'answerPrompt'
  | 'assert'
  | 'change'
  | 'clear'
  | 'click'
  | 'closeTab'
  | 'dblClick'
  | 'dragAndDrop'
  | 'execute'
  | 'goto'
  | 'hover'
  | 'mouseDown'
  | 'mouseUp'
  | 'mouseMove'
  | 'newTab'
  | 'reloadPage'
  | 'rightClick'
  | 'scroll'
  | 'select'
  | 'setLocalVariable'
  | 'switchContext'
  | 'type'
  | 'uploadFile';

export interface SelectorsPreset {
  id: UUID;
  isActive: boolean;
  isCustom: boolean;
  selectorsGroups: SelectorsGroup[];
}

export interface SelectorsGroup {
  id: UUID;
  relation: 'descendant' | 'ancestor' | 'sibling';
  selectors: Selector[];
}

export type SelectorCustomType = 'customXPath' | 'customCSS';

export interface Selector {
  id: UUID;
  type: 'XPath' | 'CSS' | SelectorCustomType;
  // TODO: This field name should change to `value` to be more generic in the next iteration
  selector: string;
  isActive: boolean;
}

export interface CustomSelector extends Selector {
  type: SelectorCustomType;
}

// Type guards
export const isCustomSelector = (selector: Selector): selector is CustomSelector =>
  selector.type === 'customXPath' || selector.type === 'customCSS';

export const isSupportedStepType = (type: Step['type']): type is Step['type'] =>
  Object.values(STEP_TYPE).includes(type);

export const isStepWithSelectors = (
  step: Step,
): step is (Step & Selectors) | DragAndDropElementStep => {
  const allowedStepTypes: StepType[] = [
    'assert',
    'change',
    'clear',
    'click',
    'dblClick',
    'uploadFile',
    'dragAndDrop',
    'hover',
    'mouseDown',
    'mouseUp',
    'rightClick',
    'scroll',
    'select',
    'setLocalVariable',
    'switchContext',
    'type',
  ];

  if (allowedStepTypes.includes(step.type)) {
    if (
      (step.type === 'assert' && isAssertPageStep(step)) ||
      (step.type === 'setLocalVariable' && step.localVariableSource !== 'element') ||
      (step.type === 'scroll' && step.scrollInside === 'window')
    ) {
      return false;
    }

    return true;
  }

  return false;
};

export const isAssertPageStep = (step: Step): step is AssertPageStep =>
  step.type === 'assert' &&
  (
    [
      'pageDoesNotShowText',
      'pageTitle',
      'pageShowsText',
      'pageUrlIs',
      'downloadStarted',
      'variableValue',
    ] as AssertionProperty[]
  ).includes(step.assertionProperty);

export const isAssertTrueFalseStep = (step: Step): step is AssertStep =>
  step.type === 'assert' &&
  (
    [
      'customJavaScript',
      'visible',
      'notVisible',
      'exist',
      'notExist',
      'checked',
      'notChecked',
    ] as AssertionProperty[]
  ).includes(step.assertionProperty);

export const isStepWithSecretValue = (step: Step): step is Step & { hasSecretValue: true } =>
  ('hasSecretValue' in step && step.hasSecretValue) ||
  ('tagAttributes' in step && step.tagAttributes.type === 'password');

export const isStepWithValue = (step: Step): step is Step & Value => 'value' in step;

export const isScrollStep = (step: Step): step is ScrollStep => step.type === 'scroll';

export const isStepWithElementInteraction = (
  step: Step,
): step is Step & {
  interactionPosition: InteractionPosition;
  clientX?: number;
  clientY?: number;
} =>
  ('selectorsPresets' in step && !!step.selectorsPresets) ||
  ('dndSelectorsPresets' in step && !!step.dndSelectorsPresets);

export const isStepWithCoords = (
  step: Step,
): step is Step & { clientX: number; clientY: number } => {
  if (!isStepWithElementInteraction(step)) return false;

  const interactionPositionWithCoords: InteractionPosition[] = ['custom', 'smart'];
  return (
    'interactionPosition' in step &&
    interactionPositionWithCoords.includes(step.interactionPosition)
  );
};

export const isBasicAuthStep = (step: Step): step is GotoStep | NewTabStep =>
  step.type === 'goto' || step.type === 'newTab';
