import { EmptyStateNoRecording } from '../EmptyStateNoRecording/EmptyStateNoRecording';
import EmptyStateRecording from '../EmptyStateRecording';

type EmptyStateVariant = 'recording' | 'visible' | 'hidden';

export const STATE_COMPONENT_MAP = {
  recording: EmptyStateRecording,
  visible: EmptyStateNoRecording,
  hidden: () => null,
} as const;

export const getActiveState = ({
  isRecording,
  isVisible,
}: {
  isRecording?: boolean;
  isVisible?: boolean;
}): EmptyStateVariant => {
  if (!isVisible) return 'hidden';
  if (isRecording) return 'recording';
  return 'visible';
};
