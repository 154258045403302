import type { Maybe } from '@bugbug/core/types/utils';
import { isNewPlaybackSupported } from '~/utils/misc';

import { selectExtensionSettings } from '../extension/extension.selectors';
import { useAppSelector } from '../store';

export const useIsNewPlaybackSupported = () => {
  const settings = useAppSelector(selectExtensionSettings) as Maybe<{ version: string }>;

  return isNewPlaybackSupported(settings?.version) as boolean;
};
