import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgProfileLogo = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 32, height: 33, viewBox: "0 0 32 33", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { y: 9.64258, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 4.57031, y: 5.07227, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 2.28516, y: 7.35742, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 9.14453, y: 0.5, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 6.85547, y: 2.78516, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 11.4297, y: 0.5, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 13.7148, y: 0.5, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 16, y: 0.5, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 18.2852, y: 0.5, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 20.5703, y: 0.5, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 22.8555, y: 2.78516, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 25.1445, y: 5.07227, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 27.4297, y: 7.35742, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7148, y: 9.64258, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7148, y: 11.9277, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7148, y: 14.2148, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7148, y: 16.5, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7148, y: 18.7852, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 29.7148, y: 21.0723, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 25.1445, y: 25.6426, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 27.4297, y: 23.3574, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.8555, y: 27.9277, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 20.5703, y: 30.2148, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 18.2852, y: 30.2148, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 16, y: 30.2148, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 13.7148, y: 30.2148, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 11.4297, y: 30.2148, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 9.14453, y: 30.2148, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 4.57031, y: 25.6426, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 6.85547, y: 27.9277, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 6.85547, y: 23.3574, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 22.8555, y: 23.3574, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 20.5703, y: 21.0723, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 9.14453, y: 21.0723, width: 2.28571, height: 2.28571, fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { x: 13.7148, y: 18.7852, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 16, y: 18.7852, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 18.2852, y: 16.5, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 11.4297, y: 16.5, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 9.14453, y: 14.2148, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 9.14453, y: 11.9277, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 11.4297, y: 9.64258, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 13.7148, y: 7.35742, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 16, y: 7.35742, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 18.2852, y: 9.64258, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 20.5703, y: 11.9277, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { x: 20.5703, y: 14.2148, width: 2.28571, height: 2.28571, fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 2.28516 25.6426)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 0 21.0723)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 0 23.3574)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 0 18.7852)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 0 16.5)", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("rect", { width: 2.28571, height: 2.28571, transform: "matrix(1 0 0 -1 0 14.2148)", fill: "#521EC9" })
] });
const ForwardRef = forwardRef(SvgProfileLogo);
export default ForwardRef;
