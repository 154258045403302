import type { ReactNode } from 'react';

import * as S from './TutorialSection.styled';

export const TutorialSection = ({
  description,
  icon,
  button,
}: {
  description: string;
  icon: ReactNode;
  button: ReactNode;
}) => (
  <S.Container>
    {icon}
    <S.Description>{description}</S.Description>
    {button}
  </S.Container>
);
