import Icon from '@bugbug/core/components/Icon';
import { LoaderFlexContainer as LoaderFlexContainerBase } from '@bugbug/core/components/Loader';
import styled, { createGlobalStyle } from 'styled-components';

import { Backdrop, Content as ContentBase } from '~/components/modals/Modal';
import { Container as ModalContainer } from '~/components/modals/Modal/Modal.styled';
import StepperBase from '~/components/Stepper';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackdropOverrides = createGlobalStyle`
  ${Backdrop} {
    padding-top: 8vh;
  }
`;

export const ModalWrapperOverrides = createGlobalStyle<{ width: string }>`
 ${ModalContainer} {
    min-width: ${(props) => props.width};
    max-width: ${(props) => props.width};
  }
`;

export const BadgeLink = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

export const Description = styled.p`
  margin: 0;
  text-align: center;
  line-height: 1.5;
`;

export const Stepper = styled(StepperBase)`
  && {
    max-width: 450px;
    width: 100%;
    height: auto;
    padding: 16px 0 20px;
    margin-bottom: 26px;
  }
`;

export const Content = styled(ContentBase)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 33px;
`;

export const LinkIcon = styled(Icon).attrs(() => ({
  name: 'externalLink',
}))`
  width: 18px;
  margin-bottom: 2px;
  margin-left: 4px;
`;

export const Image = styled.img`
  align-self: center;
  overflow: hidden;
  margin: 0 30px;
`;

export const LoaderFlexContainer = styled(LoaderFlexContainerBase)`
  margin-top: 35px;

  p {
    margin-top: 16px;
  }
`;

export const StepDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
