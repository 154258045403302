import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

import type { Route } from '@bugbug/core/types/routes';
import type { SideEffect } from '@bugbug/core/types/utils';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { useGetAlertsQuery } from '~/modules/alerts/alerts.api';
import analytics from '~/services/analytics';
import { isNotFoundError } from '~/services/api/utils';
import toasts from '~/services/toasts';

const AlertDetails = () => {
  const { t } = useTranslation();
  const modal = useModal();
  const appRoutes = useAppRoutes('alert');
  const { alertId, projectId } = appRoutes.params;
  const isNew = alertId === 'new';
  const { data, isSuccess, error } = useGetAlertsQuery(projectId, {
    selectFromResult: ({ data: alerts, ...rest }) => ({
      ...rest,
      data: alerts?.find((alert) => alert.id === alertId),
    }),
  });

  const handleHide = useCallback<SideEffect<Route>>(
    (route: Route = 'alerts') => {
      appRoutes.push(route);
    },
    [appRoutes],
  );

  useMount(() => {
    if (!isNew) {
      analytics.trackEvent('alert_opened');
    }
  });

  useEffect(() => {
    if (isSuccess || isNew) {
      modal.show('edit_alert', { data }, { onHide: handleHide });
    }
    if (error) {
      if (isNotFoundError(error)) {
        appRoutes.replace('notFound');
        return;
      }
      toasts.showError({
        content: t('alertDetails.fetchError', 'Loading alert details failed'),
      });
    }
  }, [isSuccess, isNew, data, handleHide, modal, error, t, appRoutes]);

  return null;
};

export default AlertDetails;
