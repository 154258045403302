import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgTrialPerkFree = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 61, height: 61, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { x: 0.5, y: 0.0214844, width: 60, height: 60, rx: 30, fill: "white" }),
  /* @__PURE__ */ jsx("path", { d: "M13.3382 20.5957L28.342 20.5945L28.3501 22.7223L31.4386 22.7228L31.4352 20.6036L42.5922 20.5973L45.9881 20.5979C46.444 20.5975 47.0717 20.542 47.504 20.6022C47.6045 20.8047 47.6038 22.3605 47.5216 22.5923C47.5133 22.6155 47.5033 22.6351 47.4917 22.6564L47.4824 22.673C47.4764 22.6842 47.4691 22.695 47.4621 22.7057L49.1184 22.7137C49.186 22.8046 49.185 22.9126 49.1903 23.0212C49.2109 23.4649 49.189 23.9115 49.184 24.3553C49.1773 24.9611 49.1817 25.5673 49.182 26.1731L49.184 27.4891C49.1847 27.7242 49.2086 27.9931 49.1707 28.2242C49.1637 28.2663 49.1484 28.3059 49.1291 28.3438C48.5919 28.3506 48.0551 28.3472 47.518 28.3444C47.6102 28.5999 47.5792 33.4305 47.519 33.5485C47.511 33.5638 47.507 33.569 47.498 33.5819C48.0295 33.5812 48.5616 33.5863 49.0928 33.5791C49.148 33.6645 49.151 33.752 49.1574 33.8505C49.2073 34.6102 49.16 35.3776 49.1604 36.1385C49.1604 36.5975 49.2446 37.4016 49.1441 37.8077C49.1334 37.8517 49.1154 37.8884 49.0901 37.9259C48.5793 37.9151 48.0488 37.8847 47.5393 37.9305C47.6125 38.0669 47.6095 38.4663 47.5669 38.6114C47.5612 38.6309 47.5523 38.6498 47.5429 38.6679C47.6381 38.8654 47.6172 39.8213 47.5323 40.0367C47.5233 40.0593 47.5117 40.0749 47.4967 40.0937L29.1495 40.0927L23.6002 40.092C22.6645 40.0923 21.7237 40.0711 20.7888 40.0951C19.9422 40.1192 19.0892 40.0981 18.2422 40.0957C16.6205 40.0547 14.9885 40.0958 13.3655 40.0932L13.3629 38.8154C13.3594 38.5093 13.362 38.2029 13.3617 37.8968C12.83 37.8845 12.2981 37.89 11.7663 37.8929L11.755 27.973C11.7542 26.2247 11.7696 24.4746 11.75 22.7265L13.3423 22.7257L13.3382 20.5957Z", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("path", { d: "M16.7812 31.9902C17.5922 32.064 18.6068 32.0052 19.4374 32.0024C19.4384 32.3075 19.4273 32.6111 19.4143 32.9158C18.5352 32.9347 17.6622 32.8849 16.7823 32.926L16.7812 31.9902Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M26.9652 37.873L27.0564 37.8968C27.29 37.9673 29.525 37.9108 29.9375 37.9098C29.9612 38.2088 29.954 38.4965 29.9417 38.7955C28.9345 38.8209 27.9303 38.8069 26.9232 38.8029C26.9197 38.5779 26.858 38.0549 26.9652 37.873Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M22.1538 37.8945L23.5228 37.895L25.4427 37.8959C25.4658 38.1997 25.4532 38.5065 25.4483 38.8109L22.1409 38.8116C22.125 38.5046 22.1001 38.1991 22.1538 37.8945Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M13.7868 37.8655C14.0875 37.8624 14.3894 37.8536 14.6894 37.8775C15.3806 37.8947 16.0735 37.884 16.7649 37.8825C16.8548 38.113 16.7742 38.5285 16.7769 38.7821C15.643 38.8456 14.4905 38.7961 13.3534 38.8051C13.3499 38.499 13.3525 38.1926 13.3522 37.8864C13.5015 37.8917 13.6388 37.8925 13.7868 37.8655Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M34.8516 31.9824L38.4738 31.9825C38.5723 32.0692 38.5826 32.1806 38.5916 32.3047C38.6026 32.4584 38.5996 32.694 38.523 32.8303C37.9469 32.9039 37.0697 32.8467 36.4607 32.8482C35.9515 32.8495 35.3671 32.9311 34.8695 32.8409C34.8845 32.8092 34.8955 32.7812 34.9025 32.7467C34.9451 32.5333 34.9747 32.1716 34.8516 31.9824Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M43.4565 28.6565C44.2702 28.6524 45.0832 28.6488 45.8966 28.6587C46.05 28.8391 46.0197 29.3448 46.0014 29.5747C45.9901 29.717 45.9898 29.8332 45.8912 29.9413C45.6287 29.9104 45.3311 29.9491 45.0642 29.9509C44.5228 29.9545 43.9783 29.949 43.4375 29.9293C43.4531 29.9079 43.4665 29.8875 43.4761 29.8623C43.57 29.6132 43.5633 28.898 43.4565 28.6565Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M16.7896 28.6426L19.4164 28.6328C19.4137 29.0569 19.4017 29.4832 19.4212 29.9069L16.805 29.9079C16.7814 29.4884 16.7915 29.0629 16.7896 28.6426Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M38.4551 26.1445C38.528 26.1838 38.548 26.1819 38.5719 26.2639C38.6259 26.4492 38.6292 26.9278 38.525 27.0921C38.517 27.1048 38.5124 27.1105 38.5037 27.1218C37.2903 27.1278 36.0623 27.1917 34.8516 27.1188C34.8539 27.1153 34.8562 27.1119 34.8586 27.1085C34.8785 27.0797 34.8962 27.0504 34.9045 27.0162C34.9627 26.7786 34.9767 26.3582 34.8579 26.1466L38.4551 26.1445Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M47.4775 26.1387C47.5508 26.1799 47.5661 26.1786 47.5897 26.2626C47.6399 26.4421 47.6369 26.9223 47.5438 27.0825C47.5315 27.1038 47.5258 27.1082 47.5085 27.1283C46.1556 27.1265 44.7968 27.1531 43.4453 27.1181C43.4809 27.0695 43.5079 27.0187 43.5189 26.9586C43.5578 26.7433 43.5791 26.3468 43.4533 26.1577C44.7802 26.1579 46.156 26.2564 47.4775 26.1387Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M34.8477 34.604C36.0604 34.6043 37.2751 34.5889 38.4875 34.605C38.6326 34.7864 38.602 35.3703 38.576 35.5998C38.566 35.6876 38.5537 35.7581 38.4852 35.8195L34.855 35.8196C34.8949 35.7679 34.9129 35.7144 34.9232 35.6504C34.9615 35.4111 34.9871 34.7996 34.8477 34.604Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M34.8594 28.6562L38.4986 28.6577C38.657 28.8818 38.6244 29.4912 38.5784 29.7614C38.5645 29.843 38.5438 29.873 38.4806 29.928C38.2975 29.8714 35.3533 29.9231 34.862 29.9226C34.9016 29.87 34.9229 29.8105 34.9323 29.7454C34.9642 29.5198 34.9792 28.821 34.8594 28.6562Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M43.4492 34.6017C44.7964 34.5976 46.1429 34.5698 47.4898 34.607C47.6389 34.8222 47.5959 35.411 47.5447 35.6659C47.5337 35.721 47.5194 35.7461 47.4794 35.7849C46.1442 35.756 44.8011 35.7853 43.4652 35.7884C43.4782 35.7699 43.4865 35.7519 43.4935 35.7303C43.58 35.4666 43.5717 34.8474 43.4492 34.6017Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M46.2245 37.921C46.6572 37.9294 47.0911 37.9304 47.5224 37.8965L47.5501 37.9293C47.6233 38.0656 47.6203 38.465 47.5777 38.6101C47.572 38.6297 47.5631 38.6486 47.5537 38.6667C47.5454 38.7356 47.5434 38.7447 47.4985 38.7973C47.2935 38.8619 46.1872 38.8129 45.8831 38.8129L41.2744 38.8138L41.2834 38.7878C41.3553 38.5746 41.3786 38.1376 41.2734 37.9316C42.4822 37.8832 43.7082 37.917 44.9189 37.92C45.3516 37.921 45.7929 37.9432 46.2245 37.921Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M32.7774 37.904C33.375 37.902 38.416 37.9377 38.5055 37.8965L38.5857 37.9475C38.6064 37.99 38.6214 38.033 38.629 38.0798C38.6613 38.2788 38.6706 38.6365 38.5461 38.8064L31.4902 38.8068C31.4662 38.5139 31.4739 38.2133 31.4688 37.9193C31.6297 37.9181 31.7888 37.911 31.9493 37.9007C32.2252 37.9001 32.5016 37.8967 32.7774 37.904Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M18.2539 33.6094L20.8023 33.6223L20.8023 40.0855C19.9557 40.1096 19.1027 40.0885 18.2556 40.0861L18.2539 33.6094Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M40.0234 22.7129L47.4815 22.7143C47.5008 22.7463 47.5171 22.7762 47.5271 22.8126C47.5983 23.0771 47.608 23.7496 47.4689 23.986C45.3985 24.0107 43.3262 23.9879 41.2555 23.9864C41.2794 24.0279 41.2917 24.0632 41.2974 24.1106C41.3859 24.828 41.3313 25.6097 41.3297 26.3329L41.3144 30.7074L41.33 35.7525C41.3317 36.1995 41.3962 37.4386 41.3094 37.7708C41.2991 37.811 41.2784 37.8475 41.2565 37.8825C40.8881 37.9783 40.4205 37.9094 40.0414 37.8889C40.0431 37.8847 40.0451 37.8806 40.0467 37.8764C40.0491 37.8694 40.0514 37.8623 40.0537 37.8553C40.0564 37.8481 40.059 37.8412 40.0617 37.834C40.1669 37.5195 40.093 36.7036 40.0923 36.3325L40.091 33.613C40.0903 30.3421 40.1339 27.0658 40.0927 23.7958C40.0893 23.5369 40.1246 22.923 40.0234 22.7129Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M31.4688 22.7141L36.3274 22.7146L37.7365 22.7148C37.9941 22.7149 38.272 22.6944 38.5269 22.7297C38.6537 22.9184 38.6064 23.3789 38.5958 23.6015C38.5895 23.7293 38.5971 23.8957 38.4956 23.9882L32.7695 23.9749L32.7699 33.4114C32.7701 34.9033 32.8149 36.4079 32.7761 37.8982C32.5003 37.8909 32.224 37.8943 31.9481 37.8949C31.7942 37.8848 31.6368 37.89 31.4825 37.8886L31.4688 22.7141Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M13.3828 22.7286C15.8558 22.7308 18.3296 22.6962 20.8023 22.7291L20.8111 23.9864L14.7073 23.9627L14.7086 33.2407L14.7066 36.1348C14.7051 36.7165 14.6879 37.2972 14.7116 37.8788C14.4115 37.8549 14.1096 37.8637 13.8089 37.8668L13.3828 37.8629C13.4194 35.7895 13.3895 33.718 13.3848 31.6446L13.3828 22.7286Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M22.1548 22.7131L28.3408 22.7129L28.3392 23.9655L29.9305 23.9673C29.9326 24.35 29.9266 24.733 29.9236 25.1158L28.3473 25.1161L28.3472 23.9726C26.7418 23.9779 25.1317 23.9994 23.5268 23.9727C23.4656 25.3807 23.5195 26.8106 23.5199 28.2213C23.5207 31.4428 23.5492 34.6688 23.5136 37.8898L22.1445 37.8894C22.2202 37.3362 22.166 36.7659 22.1617 36.209L22.1547 33.3465L22.1548 22.7131Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M14.6878 23.9629L20.7917 23.9866L20.8045 26.1682L19.4191 26.1681C19.4047 26.5509 19.3953 26.9311 19.4018 27.3141L17.6605 27.3157C17.3657 27.3161 17.0535 27.2977 16.7606 27.3251L16.7638 28.6437C16.7658 29.064 16.7557 29.4896 16.7792 29.9091L19.3954 29.9081C19.3935 30.5969 19.3545 31.2882 19.4009 31.976L17.5727 31.9729C17.319 31.9731 17.0069 31.9411 16.7593 31.986L16.7604 32.9218L16.759 36.0524C16.7555 36.6539 16.71 37.2874 16.7675 37.884C16.0761 37.8855 15.3833 37.8962 14.6921 37.879C14.6685 37.2974 14.6856 36.7167 14.6871 36.1349L14.6891 33.2409L14.6878 23.9629Z", fill: "#FECE1D" }),
  /* @__PURE__ */ jsx("path", { d: "M16.7845 26.166L19.4411 26.1669C19.4267 26.5496 19.4173 26.9299 19.4237 27.3129L17.6824 27.3145C17.3877 27.3149 17.0754 27.2964 16.7826 27.3239C16.7791 26.938 16.7836 26.5518 16.7845 26.166Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M32.7734 23.9727L38.4995 23.9859C38.5188 24.0066 38.5551 24.0357 38.5684 24.0593C38.647 24.2002 38.5997 25.3272 38.5927 25.5512C38.587 25.7264 38.589 25.912 38.5478 26.0831L38.4742 26.1443L34.877 26.1463C34.9958 26.3579 34.9818 26.7783 34.9236 27.0159C34.9152 27.0501 34.8976 27.0795 34.8776 27.1082C34.8753 27.1116 34.873 27.1151 34.8707 27.1185C34.9868 27.3871 34.9994 28.3428 34.8999 28.6072C34.893 28.6258 34.885 28.6405 34.8746 28.6574C34.9945 28.8221 34.9795 29.521 34.9475 29.7466C34.9382 29.8116 34.9169 29.8712 34.8773 29.9238C35.3685 29.9242 38.3128 29.8726 38.4959 29.9291C38.5175 29.9504 38.5574 29.9804 38.5721 30.005C38.6453 30.127 38.622 30.8883 38.6223 31.0752C38.623 31.2811 38.6679 31.8135 38.5208 31.9508C38.5128 31.9581 38.5045 31.9647 38.4965 31.9717L34.8743 31.9716C34.9975 32.1607 34.9678 32.5225 34.9252 32.7359C34.9182 32.7704 34.9073 32.7984 34.8923 32.8301C34.9901 33.0967 34.9898 34.3413 34.8846 34.5625C34.876 34.5811 34.8753 34.5809 34.8637 34.6009C35.0031 34.7964 34.9775 35.4079 34.9392 35.6472C34.9289 35.7112 34.9109 35.7647 34.871 35.8164L38.5012 35.8163C38.5198 35.8382 38.5538 35.8684 38.5661 35.8929C38.6576 36.0719 38.6113 37.4097 38.5894 37.6749C38.5821 37.7623 38.5757 37.8261 38.5082 37.8884C38.4187 37.9296 33.3777 37.8939 32.78 37.8959C32.8188 36.4057 32.774 34.901 32.7738 33.4091L32.7734 23.9727Z", fill: "#FECE1D" }),
  /* @__PURE__ */ jsx("path", { d: "M47.2134 24C47.2337 24.0212 47.2713 24.0505 47.2856 24.0748C47.3665 24.2138 47.3219 24.9157 47.3186 25.1194C47.3152 25.319 47.3485 26.0176 47.2314 26.1434C47.2281 26.1469 47.2244 26.1498 47.2207 26.1531C45.8992 26.2707 44.5234 26.1723 43.1965 26.1721C43.3223 26.3612 43.301 26.7577 43.2621 26.973C43.2511 27.0331 43.2241 27.0838 43.1885 27.1325C43.3276 27.398 43.3156 28.3002 43.2288 28.59C43.2205 28.6179 43.2098 28.6445 43.1985 28.6712C43.3053 28.9128 43.312 29.628 43.2181 29.8771C43.2085 29.9022 43.1952 29.9226 43.1795 29.9441C43.7203 29.9638 44.2648 29.9692 44.8063 29.9656C45.0732 29.9638 45.3707 29.9252 45.6333 29.9561C45.827 30.1993 45.7557 31.4559 45.7148 31.7898C45.7075 31.8493 45.6945 31.8533 45.6536 31.8911C45.6809 31.9076 45.7308 31.9259 45.7381 31.9571C45.7867 32.1649 45.804 32.545 45.6912 32.7326C45.6519 32.7974 45.5937 32.8388 45.5204 32.8564C45.3717 32.8919 43.488 32.8516 43.1975 32.841C43.3273 33.1298 43.3266 34.2478 43.2198 34.5523C43.2118 34.5753 43.2018 34.5969 43.1898 34.618C43.3123 34.8638 43.3206 35.483 43.2341 35.7467C43.2271 35.7683 43.2188 35.7862 43.2058 35.8048C44.5417 35.8017 45.8849 35.7724 47.2201 35.8013C47.2407 35.823 47.278 35.8527 47.2926 35.8776C47.3818 36.0315 47.3296 37.3064 47.3236 37.5538C47.3209 37.6694 47.3296 37.797 47.277 37.9027C46.8457 37.9366 46.4117 37.9356 45.979 37.9273C45.7228 37.8482 45.0083 37.9028 44.7117 37.903C43.4817 37.9042 42.228 37.9546 41.001 37.8966C41.023 37.8616 41.0436 37.825 41.0539 37.7848C41.1408 37.4527 41.0762 36.2135 41.0745 35.7665L41.0589 30.7215L41.0742 26.3469C41.0759 25.6237 41.1305 24.8421 41.0419 24.1246C41.0363 24.0772 41.024 24.0419 41 24.0005C43.0707 24.002 45.1431 24.0247 47.2134 24Z", fill: "#FECE1D" }),
  /* @__PURE__ */ jsx("path", { d: "M45.9119 31.873C45.9392 31.8896 45.9891 31.9079 45.9964 31.9391C46.045 32.1469 46.0623 32.527 45.9495 32.7146C45.9102 32.7794 45.852 32.8208 45.7788 32.8383C45.63 32.8738 43.7463 32.8336 43.4558 32.823C43.4904 32.775 43.513 32.7237 43.5224 32.6649C43.5593 32.4337 43.5773 32.0775 43.4375 31.8815C44.2615 31.8875 45.0899 31.9571 45.9119 31.873Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M23.537 23.9707C25.142 23.9974 26.752 23.976 28.3574 23.9707L28.3575 25.1141L29.9339 25.1138L29.9344 29.8138C29.9337 30.5385 29.8667 31.3099 29.9384 32.0288C29.9474 32.485 29.9551 32.9384 29.9315 33.3943C29.9234 33.8012 29.9211 34.2079 29.9203 34.615C29.9164 35.7015 29.8589 36.8053 29.9235 37.8893L28.3459 37.8876C27.9211 37.8869 27.4811 37.8621 27.0582 37.898L26.967 37.8742C26.9172 37.5792 26.9695 37.2393 26.9727 36.9391C26.9766 36.575 26.9608 36.2094 26.9506 35.8455C26.9162 35.4431 26.9287 35.0361 26.9303 34.6325L25.4192 34.6347L25.4172 36.677C25.4157 37.0597 25.3652 37.5178 25.4437 37.8888L23.5238 37.8879C23.5595 34.6668 23.5309 31.4408 23.5301 28.2194C23.5298 26.8087 23.4758 25.3788 23.537 23.9707Z", fill: "#FECE1D" }),
  /* @__PURE__ */ jsx("path", { d: "M25.4219 26.1559C25.926 26.156 26.432 26.1481 26.9358 26.1665C26.9364 26.5338 26.9246 26.9068 26.9405 27.2735C26.6679 27.333 25.7354 27.3028 25.4241 27.2779L25.4219 26.1559Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M25.4219 33.4453C25.9255 33.4621 26.4304 33.4569 26.9342 33.4577L26.9338 34.6433L25.4227 34.6456L25.4219 33.4453Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M29.9313 33.3945C29.9233 33.8015 29.921 34.2082 29.9202 34.6152L28.3516 34.6078C28.3607 34.2049 28.3641 33.8028 28.3612 33.3998L29.9313 33.3945Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M28.3555 32.0273L29.9364 32.0284C29.9454 32.4846 29.9531 32.938 29.9295 33.3939L28.3594 33.3992L28.3555 32.0273Z", fill: "#501ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M26.9503 35.8455C27.4124 35.8188 27.894 35.7832 28.3531 35.854L28.3456 37.8876C27.9208 37.8868 27.4808 37.8621 27.0579 37.8979L26.9667 37.8742C26.9169 37.5791 26.9692 37.2392 26.9724 36.9391C26.9763 36.575 26.9605 36.2094 26.9503 35.8455Z", fill: "#FEFEFE" }),
  /* @__PURE__ */ jsx("path", { d: "M26.9383 27.2715C26.9838 28.1421 26.9342 29.0222 26.9492 29.8943L25.4376 29.8999C25.4551 29.0273 25.4278 28.1489 25.4219 27.2758C25.7331 27.3007 26.6656 27.331 26.9383 27.2715Z", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("path", { d: "M26.9291 26.1672C27.4001 26.172 27.8713 26.1677 28.3423 26.166L28.3424 31.0544H25.4338C25.4334 30.671 25.4252 30.2858 25.4331 29.9025L26.9448 29.897C26.9298 29.0248 26.9794 28.1447 26.9339 27.2741C26.918 26.9075 26.9297 26.5345 26.9291 26.1672Z", fill: "#FEFEFE" })
] });
const ForwardRef = forwardRef(SvgTrialPerkFree);
export default ForwardRef;
