import ButtonBase from '@bugbug/core/components/Button';
import styled, { createGlobalStyle } from 'styled-components';

import { Backdrop } from '../Modal';

import CryingFaceLogo from '~/images/crying-face.svg?react';
import PerkIncludedSVG from '~/images/perk-included.svg?react';
import PerLockedkSVG from '~/images/perk-locked.svg?react';

export const Container = styled.div`
  width: 590px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 35px 35px 35px;
`;

export const TrialExpiredLogo = styled(CryingFaceLogo)`
  width: 32px;
  height: 32px;
  margin-bottom: 12px;
`;

export const PlansContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  min-height: 380px;
  margin-top: 30px;
  margin-bottom: 55px;
`;

export const Plan = styled.div`
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.border.semi};
  padding: 20px;
  min-height: 380px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const PlanTitle = styled.p`
  margin: 0;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const PlanSubtitle = styled.p`
  margin: 0;
`;

export const PlanPrice = styled.p`
  margin-top: 4px;
  margin-bottom: 14px;
`;

export const PlanPriceAmount = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
`;

export const PlanPriceSufix = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 14px;
`;

export const PlanPerk = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const PlanPerksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PerkIncludedIcon = styled(PerkIncludedSVG)`
  width: 24px;
  height: 24px;
`;

export const PerkLockedIcon = styled(PerLockedkSVG)`
  width: 24px;
  height: 24px;
`;

export const PlanButton = styled(ButtonBase)`
  margin: 0;
  margin-top: auto;
  width: 100%;

  div {
    max-width: fit-content;
  }
`;

export const BrandsList = styled.div`
  display: flex;
  gap: 10px;
  max-height: 36px;
  width: 100%;
  margin-top: 10px;
  gap: 24px;
  justify-content: center;
`;

export const BrandLogo = styled.img`
  height: auto;
  max-width: 90px;
  object-fit: contain;
  object-position: center;
`;

export const BackdropOverrides = createGlobalStyle`
  ${Backdrop} {
    padding-top: 8vh;
  }
`;
