import { isRunningStatus } from '@bugbug/core/types/base';
import { useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMount, useUnmount } from 'react-use';

import type { TestRunRef } from './components/TestRuns';

import type { SuiteRun } from '@bugbug/core/types/suites';
import type { TestRun } from '@bugbug/core/types/tests';
import { PrintableHeader } from '~/components/PrintableDocument';
import useActionState from '~/hooks/useActionState';
import { useBackUrl } from '~/hooks/useBackUrl';
import { SuiteRunActions } from '~/modules/suiteRun/suiteRun.redux';
import { selectSingleSuiteRun } from '~/modules/suiteRun/suiteRun.selectors';

import Header from './components/Header';
import Loader from './components/Loader';
import TestRuns from './components/TestRuns';
import * as S from './SuiteRunDetails.styled';

interface RouteParams {
  suiteRunId: SuiteRun['id'];
}

const SuiteRunDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { suiteRunId } = useParams<RouteParams>();
  const { isLoading, reset } = useActionState(SuiteRunActions.getSingleRequest, { reset: false });
  const backUrl = useBackUrl('suiteRunsList');

  // Suite run
  const suiteRun = useSelector(selectSingleSuiteRun);
  const { suiteName } = suiteRun;
  const isRunning = isRunningStatus(suiteRun?.status);

  // Tests
  const testsTableRef = useRef<TestRunRef>(null);
  const [selectedTests, setSelectedTests] = useState<TestRun['id'][]>([]);

  useMount(() => {
    dispatch(SuiteRunActions.getSingleRequest(suiteRunId));
  });

  useUnmount(() => {
    reset?.();
    dispatch(SuiteRunActions.clearSingle());
  });

  const handleDeselectAll = useCallback(() => {
    if (testsTableRef.current) {
      testsTableRef.current.toggleAllRowsSelected(false);
    }
  }, [testsTableRef]);

  return (
    <S.Container
      backUrl={backUrl}
      data-testid="SuiteRunDetails"
      title={<Header selectedTests={selectedTests} onDeselect={handleDeselectAll} />}
      withBackButton
      wideContent
    >
      <Helmet
        title={t('suiteRunDetails.pageTitle', 'Suite runs / {{- suiteName }}', { suiteName })}
      />
      <PrintableHeader
        title={t('suiteRunDetails.print.title', 'Report on Automated Test Suite Results')}
        subTitle={t('suiteRunDetails.print.subTitle', '"{{suiteName}}"', {
          suiteName,
        })}
      />
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          {!isRunning && (
            <S.PrintableContent>
              <TestRuns disabledWindowing />
            </S.PrintableContent>
          )}
          <TestRuns ref={testsTableRef} onSelectionChange={setSelectedTests} />
        </>
      )}
    </S.Container>
  );
};

export default SuiteRunDetails;
