import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentOrganization } from '~/modules/organization/organization.selectors';
import { selectUserName } from '~/modules/user/user.selectors';

import * as S from './AccountInfo.styled';

const AccountInfo = ({ className }) => {
  const username = useSelector(selectUserName);
  const currentOrganization = useSelector(selectCurrentOrganization);

  return (
    <S.Container className={className} data-testid="AccountInfo">
      <S.Organization>{currentOrganization.name}</S.Organization>
      <S.Username>{username}</S.Username>
    </S.Container>
  );
};

AccountInfo.defaultProps = {
  className: null,
};

AccountInfo.propTypes = {
  className: PropTypes.string,
};

export default AccountInfo;
