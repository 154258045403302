import { useInView } from '@react-spring/web';
import PropTypes from 'prop-types';
import { memo, useCallback, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import useUnmount from 'react-use/lib/useUnmount';
import { useDebouncedCallback } from 'use-debounce';

import { DRAG_DROP_ITEM_TYPE } from '~/components/Table';
import useQueryString from '~/hooks/useQueryString';
import { usePrefetchSteps } from '~/modules/steps/steps.api';
import { StepsActions } from '~/modules/steps/steps.redux';
import { useAppSelector } from '~/modules/store';
import { selectGroup, selectGroupStepsList } from '~/modules/test/test.selectors';
import { selectSingleTestRunId } from '~/modules/testRun/testRun.selectors';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import {
  selectIsDraggingPlaybackCursor,
  selectIsGroupCollapsed,
} from '~/modules/uiState/uiState.selectors';

import GroupEmptyState from '../GroupEmptyState';
import { RunningGroupPlaybackCursor } from '../PlaybackCursor/PlaybackCursor';
import { useRowActiveBorder } from '../RowActiveBorder/RowActiveBorderContext';
import Step from '../Step';

import { DropState } from './DropState';
import { GROUP_HEADER_HEIGHT } from './StepsGroup.constants';
import { GroupContainer, StepsContainer } from './StepsGroup.styled';
import { StepsGroupHeader } from './StepsGroupHeader';
import { StepsListPlaceholder } from './StepsListPlaceholder';

const StepsGroup = memo(
  forwardRef(({ testId, groupId, readOnly, index, runResultEnabled }, ref) => {
    const dispatch = useDispatch();
    const containerRef = useRef();
    const [viewRef, inView] = useInView({
      rootMargin: `0px -400px 500px 0px`,
      amount: 'any',
    });
    const group = useAppSelector(selectGroup(runResultEnabled, groupId));
    const steps = useAppSelector(selectGroupStepsList(groupId, runResultEnabled));
    const isCollapsed = useAppSelector(selectIsGroupCollapsed(groupId, testId));
    const isDraggingPlaybackCursor = useAppSelector(selectIsDraggingPlaybackCursor);
    const testRunId = useAppSelector(selectSingleTestRunId);
    const query = useQueryString();
    const isExpanded = !isCollapsed;
    const prefetchStepDetails = usePrefetchSteps('getStepDetails');
    const scrollTimeoutRef = useRef();

    const getContainerTop = useCallback(() => containerRef.current?.offsetTop || 0, []);

    const getGroupOffset = useCallback(
      () => getContainerTop() + GROUP_HEADER_HEIGHT,
      [getContainerTop],
    );

    const bottomOffset = useMemo(() => {
      if (isExpanded && steps && steps.length > 0) {
        return {
          row: { id: steps[0].id, groupId: group.id },
          testsCount: group.testsCount,
          isComponent: group.isComponent,
          index: 0,
          disabledGroupOptions: true,
        };
      }
      if (!isExpanded) {
        return {
          row: group,
          isComponent: group.isComponent,
          disabledSplitting: true,
          disabledStepOptions: true,
          index,
        };
      }

      return null;
    }, [group, index, isExpanded, steps]);

    const activeBorderProps = useMemo(
      () => ({
        modifiers: {
          x: 2,
          y: 2,
          height: !isExpanded ? 2 : 0,
          fixed: !index,
        },
        props: {
          top: {
            row: group,
            isComponent: group?.isComponent,
            disabledSplitting: true,
            disabledRunAndStop: true,
            disabledStepOptions: true,
            index: index - 1,
          },
          bottom: bottomOffset,
        },
      }),
      [bottomOffset, group, index, isExpanded],
    );

    const { onItemEnter, onItemLeave } = useRowActiveBorder(activeBorderProps, getContainerTop);

    const handleToggleExpanded = useCallback(() => {
      dispatch(UIStateActions.toggleCollapsedGroup({ testId, collapsedGroupId: groupId }));
    }, [dispatch, groupId, testId]);

    const handleGroupSelectionChange = useCallback(
      (isSelected) => {
        dispatch(
          UIStateActions.setCheckedSteps({
            steps: group.steps.map((stepId) => ({ id: stepId, groupId })),
            checked: isSelected,
          }),
        );
      },
      [dispatch, group.steps, groupId],
    );

    const handleStepDelete = useCallback(() => {
      dispatch(UIStateActions.resetCheckedSteps());
      dispatch(StepsActions.resetCurrentStepId());
    }, [dispatch]);

    const handlerStepClick = useCallback((stepId) => {
      dispatch(StepsActions.toggleCurrentStepId(stepId));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStepPrefetch = useDebouncedCallback(
      (stepId) => {
        prefetchStepDetails(
          {
            id: stepId,
            // We need to pass testRunId to get the correct step result for historical steps
            testRunId: runResultEnabled ? testRunId : undefined,
          },
          { ifOlderThan: 30 },
        );
      },
      300,
      { leading: false, trailing: true },
    );

    const getStepElement = useCallback(
      (queriedStepId) => {
        const stepIndex = group.steps.findIndex((stepId) => stepId === queriedStepId);
        return containerRef.current?.querySelector(`[aria-rowindex="${stepIndex}"]`);
      },
      [group.steps],
    );

    useImperativeHandle(
      ref,
      () => ({
        groupId,
        goToStep: (stepId, position, expandGroup = true, immediate = false) => {
          if (expandGroup) {
            dispatch(UIStateActions.forgetCollapsedGroup({ testId, collapsedGroupId: groupId }));
          }

          scrollTimeoutRef.current = setTimeout(() => {
            const scrollConfig = {
              behavior: immediate ? 'auto' : 'smooth',
              block: position || 'end',
              inline: 'nearest',
            };

            if (!containerRef.current && !expandGroup) {
              // Group is collapsed
              containerRef.current?.getElementById(groupId)?.scrollIntoView?.(scrollConfig);
            } else if (!group.steps.length) {
              // Group does not have steps
              containerRef.current?.scrollIntoView(scrollConfig);
            } else {
              /*
                We cannot use scrollIntoView directly on the step element
                because a step placeholder could be rendered instead.
              */
              const stepElement = getStepElement(stepId);
              const scrollableParent = containerRef.current.parentElement.parentElement;
              if (!stepElement || !scrollableParent) {
                return;
              }

              const stepRect = stepElement.getBoundingClientRect();
              const scrollableParentRect = scrollableParent.getBoundingClientRect();
              const stepBottom = stepRect.bottom - scrollableParentRect.top;
              const targetScroll =
                scrollableParent.scrollTop + stepBottom - scrollableParentRect.height / 2;

              scrollableParent.scrollTo({
                top: targetScroll,
                ...scrollConfig,
              });
            }
          }, 0);
        },
        getStepElement,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [groupId, group.steps, getStepElement],
    );

    useUnmount(() => {
      clearTimeout(scrollTimeoutRef.current);
    });

    const StepsList = useMemo(
      () =>
        steps.map((step, stepIndex) => (
          <Step
            key={step.id}
            index={stepIndex}
            groupIndex={index}
            groupId={groupId}
            stepId={step.id}
            readOnly={readOnly}
            runResultEnabled={runResultEnabled}
            onClick={handlerStepClick}
            onHover={handleStepPrefetch}
            onDelete={handleStepDelete}
            getGroupOffset={getGroupOffset}
          />
        )),
      [
        steps,
        groupId,
        index,
        runResultEnabled,
        readOnly,
        handlerStepClick,
        handleStepPrefetch,
        handleStepDelete,
        getGroupOffset,
      ],
    );

    return (
      <Draggable
        disableInteractiveElementBlocking
        draggableId={groupId}
        key={groupId}
        index={index}
        isDragDisabled={readOnly}
      >
        {({ dragHandleProps, innerRef, draggableProps }, snapshot) => (
          <GroupContainer
            ref={(groupRef) => {
              innerRef(groupRef);
              containerRef.current = groupRef;
              viewRef.current = groupRef;
            }}
            {...draggableProps}
            dragged={snapshot.isDragging}
            isDraggingPlaybackCursor={isDraggingPlaybackCursor}
            data-testid="GroupContainer"
          >
            <StepsGroupHeader
              testId={testId}
              group={group}
              dragHandleProps={dragHandleProps}
              onSelectionChange={handleGroupSelectionChange}
              onExpandClick={handleToggleExpanded}
              readOnly={readOnly}
              runResultEnabled={runResultEnabled}
              expanded={isExpanded}
              onEnter={onItemEnter}
              onLeave={onItemLeave}
            />
            {isExpanded && (
              <StepsContainer>
                <RunningGroupPlaybackCursor
                  testId={testId}
                  groupId={groupId}
                  testRunId={query.testRunId}
                />
                <Droppable droppableId={groupId} type={DRAG_DROP_ITEM_TYPE.STEP}>
                  {(providedSteps, state) => (
                    <div ref={providedSteps.innerRef}>
                      {inView || index === 0 ? (
                        StepsList
                      ) : (
                        <StepsListPlaceholder rowsNumber={steps.length} />
                      )}
                      {!!steps.length && providedSteps.placeholder}
                      {!state.isDraggingOver && !steps.length && (
                        <GroupEmptyState
                          testId={testId}
                          group={group}
                          getGroupOffset={getGroupOffset}
                          index={index}
                          readOnly={readOnly}
                        />
                      )}
                      {state.isDraggingOver && !steps.length && <DropState />}
                    </div>
                  )}
                </Droppable>
              </StepsContainer>
            )}
          </GroupContainer>
        )}
      </Draggable>
    );
  }),
);

StepsGroup.displayName = 'StepsGroup';

StepsGroup.defaultProps = {
  readOnly: false,
  runResultEnabled: false,
  onSelectionChange: Function.prototype,
};

StepsGroup.propTypes = {
  groupId: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onSelectionChange: PropTypes.func,
  readOnly: PropTypes.bool,
  runResultEnabled: PropTypes.bool,
};

export default StepsGroup;
