import type { Modal } from './modals.types';

/**
 * @deprecated, now we using type Modal
 */
export const MODAL_TYPE: Record<string, Modal> = {
  UPGRADE_PLAN: 'upgrade_plan',
  PROJECT: 'project',
  CLONE_ALERT: 'clone_alert',
  CLONE_GROUP: 'clone_group',
  CLONE_SCHEDULE: 'clone_schedule',
  CLONE_TEST: 'clone_test',
  DELETE_PROJECT: 'delete_project',
  DELETE_GROUP: 'delete_group',
  DELETE_ALERT: 'delete_alert',
  DELETE_SCHEDULES: 'delete_schedule',
  DELETE_SUITE_RUNS: 'delete_suite_runs',
  DELETE_TEST_RUNS: 'delete_test_runs',
  DELETE_SUITES: 'delete_suites',
  DELETE_TESTS: 'delete_tests',
  DOWNGRADE_PLAN: 'downgrade_plan',
  EDIT_GROUP: 'edit_group',
  SCHEDULE: 'schedule',
  EDIT_SUITE: 'edit_suite',
  SUITE_DETAILS: 'suite_details',
  EDIT_TEST: 'edit_test',
  INVITE_USER: 'invite_user',
  RUN_METADATA: 'run_metadata',
  SUITE_CLI_COMMANDS: 'suite_cli_commands',
  TEST_CLI_COMMANDS: 'test_cli_commands',
  TEST_SETTINGS: 'test_settings',
  CREATE_STEP: 'create_step',
  EDIT_STEP: 'edit_step',
  DELETE_STEPS: 'delete_steps',
  SUBSCRIPTION_CANCEL: 'subscription_cancel',
  UPDATE_CARD: 'update_card',
  INSERT_COMPONENT: 'insert_component',
  SWITCH_ORGANIZATION: 'switch_organization',
  CHANGE_USER_ROLE: 'change_user_role',
  DELETE_USER: 'delete_user',
  DELETE_UNCONFIRMED_STEPS: 'delete_unconfirmed_steps',
  START_TRIAL: 'start_trial',
  CREATE_COMPONENT_INFO: 'create_component_info',
  CREATE_ORGANIZATION_INFO: 'create_organization_info',
  CREATE_PROJECT_INFO: 'create_project_info',
  RECORD_TEST_INFO: 'record_test_info',
  INSTALL_EXTENSION: 'install_extension',
  RUN_TEST_CLOUD_INFO: 'run_test_cloud_info',
  CREATE_SCHEDULER_INFO: 'create_scheduler_info',
  DELETE_TEST_PARTIALS: 'delete_test_partials',
  DELETE_ALL_GROUPS: 'delete_all_groups',
  SCHEDULE_NOTFICATIONS_MODAL: 'schedule_notfications_modal',
  GROUP_SPLIT_CONFIRMATION: 'group_split_confirmation',
  DELETE_VARIABLE: 'delete_variable',
  DELETE_PROFILE: 'delete_profile',
  EDIT_ALERT: 'edit_alert',
  EDIT_PROFILE: 'edit_profile',
  EDIT_VARIABLE: 'edit_variable',
  RUN_WITH_PROFILE: 'run_with_profile',
  UNSAVED_CHANGES: 'unsaved_changes',
  UNAVAILABLE_STEP: 'unavailable_step',
  UNCONFIRMED_STEPS: 'unconfirmed_steps',
  INCOGNITO_TABS_CLOSE: 'incognito_tabs_close',
  SUPPORT_REQUEST_CONFIRMATION: 'support_request_confirmation',
  SUPPORT_REQUEST_SUCCESS: 'support_request_success',
  UPDATE_AVAILABLE: 'update_available',
  DOWNLOAD_PDF_FILE: 'download_pdf_file',
  STOP_TEST_SESSION: 'stop_test_session',
};
