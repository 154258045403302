import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { ModalSize } from '../modals.types';

import type { SideEffect } from '@bugbug/core/types/utils';
import useModal from '~/hooks/useModal/useModal';

import { Container, Backdrop, CloseButton } from './Modal.styled';

export interface ModalProps {
  className?: string;
  children: React.ReactNode;
  size?: ModalSize;
  closeButtonHidden?: boolean;
  onCloseButtonClick?: SideEffect;
}

const Modal = ({
  className,
  children,
  size = 'normal',
  closeButtonHidden = false,
  onCloseButtonClick,
}: ModalProps) => {
  const modal = useModal();
  const { t } = useTranslation();

  const handleClick = useCallback((event) => event.stopPropagation(), []);

  return (
    <Backdrop data-testid="Backdrop" size={size}>
      <Container
        role="dialog"
        className={className}
        data-testid="Modal"
        onClick={handleClick}
        size={size}
      >
        {!closeButtonHidden && (
          <CloseButton
            aria-label={t('modal.closeButton', 'Close')}
            data-testid="Modal.CloseButton"
            onClick={onCloseButtonClick ?? modal.hide}
            iconName="close"
          />
        )}
        {children}
      </Container>
    </Backdrop>
  );
};

export default Modal;
