import api from './common';

export const addUsers = (id, data) => api.post(`/organizations/${id}/users/`, data);

export const updateUserRole = (id, data) =>
  api.patch(`/organizations/${id}/users/update-role/`, data);

export const updateOrganizationName = (id, data) =>
  api.patch(`/organizations/${id}/update-name/`, data);

export const removeUser = (id, email) =>
  api.delete(`/organizations/${id}/users/delete/`, { data: { email } });

export const getUsersList = (id, params) => api.get(`/organizations/${id}/users/`, { params });

export const create = (data) => api.post(`/organizations/`, data);

export const updateOrganization = (id, data) => api.patch(`/organizations/${id}/`, data);

export const getInvoices = () => api.get('/invoices/');

export const addSubscription = (data) => api.post('/subscription/', data);

export const getSubscriptions = () => api.get('/subscriptions/');

export const getSubscription = () => api.get('/subscription/');

export const cancelSubscription = () => api.delete('/subscription/');

export const startTrial = () => api.post('/subscriptions/start-trial/');

export const updateDefaultCard = (data) => api.post('/cards/', data);
