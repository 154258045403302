import { prop, propEq } from 'ramda';
import { memo, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { useFirstMountState } from 'react-use';

import { NavBar } from '~/components/NavBar/NavBar';
import PrivateRoute from '~/components/PrivateRoute';
import useModal from '~/hooks/useModal';
import { ONBOARDING_STEP } from '~/modules/constans';
import { OrganizationActions } from '~/modules/organization/organization.redux';
import {
  selectCurrentOrganization,
  selectIsFreePlan,
  selectIsTrialActive,
  selectIsTrialEnded,
  selectOrganizationsList,
} from '~/modules/organization/organization.selectors';
import { useAppSelector } from '~/modules/store';
import { useSetUserFlagsMutation } from '~/modules/user/user.api';
import {
  selectCurrentOnboardingStep,
  selectHasExpiringTrialModalShown,
  selectOnboardingData,
} from '~/modules/user/user.selectors';
import { loadLazy } from '~/utils/rendering';
import AppLoader from '~/views/AppLoader';
import Forbidden from '~/views/Forbidden';
import urls, { reverse, shouldHideNavigation } from '~/views/urls';

const AccountSettings = loadLazy(() => import('~/views/AccountSettings'));
const OrganizationSettings = loadLazy(() => import('~/views/OrganizationSettings'));
const NewOrganization = loadLazy(() => import('~/views/NewOrganization'));
const Project = loadLazy(() => import('~/views/Project'));
const ProjectList = loadLazy(() => import('~/views/ProjectList'));

const OrganizationsRoutes = memo(({ organizationId }) => {
  const redirectToProjectList = useCallback(
    () => <Redirect to={reverse(urls.projectsList, { organizationId })} />,
    [organizationId],
  );

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <PrivateRoute path={urls.newOrganization} component={NewOrganization} exact />
        <PrivateRoute path={urls.project} component={Project} />
        <PrivateRoute path={urls.projectsList} component={ProjectList} />
        <PrivateRoute path={urls.accountSettings} component={AccountSettings} />
        <PrivateRoute path={urls.account} render={() => <Redirect to={urls.accountGeneral} />} />
        <PrivateRoute path={urls.organizationSettings} component={OrganizationSettings} />
        <Route exact path={urls.organizations} render={redirectToProjectList} />
        <Route
          exact
          path={reverse(urls.organization, { organizationId })}
          render={redirectToProjectList}
        />
      </Switch>
    </Suspense>
  );
});

OrganizationsRoutes.displayName = 'OrganizationsRoutes';

const Organizations = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const user = useSelector(selectOnboardingData);
  const onboardingStep = useSelector(selectCurrentOnboardingStep);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const organizationsList = useSelector(selectOrganizationsList);
  const hiddenNavigation = shouldHideNavigation(location.pathname);
  const isFirstMount = useFirstMountState();
  const [hasAccessToOrganization, setHasAccessToOrganization] = useState(true);

  const viewportScale = useMemo(() => (window.innerWidth / 1024).toFixed(2), []);
  const modal = useModal();

  const isFreePlan = useAppSelector(selectIsFreePlan);
  const isTrialActive = useAppSelector(selectIsTrialActive);
  const organizationId = prop('id', currentOrganization);
  const hasExpiringTrialModalShown = useAppSelector(selectHasExpiringTrialModalShown);
  const isTrialEnded = useAppSelector(selectIsTrialEnded);
  const [setUserFlags] = useSetUserFlagsMutation();

  useEffect(() => {
    if (
      !organizationId ||
      !isFreePlan ||
      isTrialActive ||
      hasExpiringTrialModalShown !== null ||
      !isTrialEnded
    ) {
      return;
    }

    setUserFlags({ expiringTrialModalShown: new Date().toISOString() });

    // In this case we don't want to show two modals in a row, so we dismiss the one with expired trial
    if (onboardingStep === ONBOARDING_STEP.WIZARD_COMPLETED) return;

    modal.show('trial_expired', undefined, {
      closeButtonHidden: true,
      stacked: true,
    });
  }, [
    hasExpiringTrialModalShown,
    isFreePlan,
    isTrialActive,
    isTrialEnded,
    modal,
    organizationId,
    setUserFlags,
    onboardingStep,
  ]);

  useEffect(() => {
    const win = window;

    if (typeof win.Featurebase !== 'function') {
      win.Featurebase = function anonymous() {
        // eslint-disable-next-line prefer-rest-params
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }

    win.Featurebase('initialize_changelog_widget', {
      organization: 'bugbug',
      placement: 'right',
      theme: 'light',
      fullscreenPopup: true,
      usersName: user.firstName,
    });
  }, [user]);

  useEffect(() => {
    if (params.organizationId && organizationId && params.organizationId !== organizationId) {
      if (organizationsList.find(propEq('id', params.organizationId))) {
        setHasAccessToOrganization(true);
        dispatch(OrganizationActions.switchOrganizationSuccess(params.organizationId));
      } else {
        setHasAccessToOrganization(false);
      }
    }
  }, [dispatch, params.organizationId, organizationId, organizationsList]);

  if (!hiddenNavigation && !currentOrganization) {
    return isFirstMount ? <AppLoader /> : null;
  }

  if (!hasAccessToOrganization) {
    return (
      <Forbidden
        message={t(
          'organizations.errorPage.forbiddenMessage',
          'You do not have access to this organization. Contact the administrator to receive the access.',
        )}
      />
    );
  }

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content={`width=1024, initial-scale=${viewportScale}, user-scalable=yes`}
        />
        <script src="https://do.featurebase.app/js/sdk.js" id="featurebase-sdk" />
      </Helmet>
      {!hiddenNavigation && <NavBar />}
      <OrganizationsRoutes organizationId={organizationId} />
    </>
  );
};

export default Organizations;
