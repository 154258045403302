import type { RtkErrorResponse } from './utils';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosError } from 'axios';

import type { APIError } from '@bugbug/core/types/api';
import { extractErrorLabelsAndParams } from '~/utils/apiErrorHandler';

import apiInstance from './common';

export const axiosBaseQuery =
  (
    {
      baseUrl,
    }: {
      baseUrl: string;
    } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    RtkErrorResponse
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await apiInstance({
        url: baseUrl + url,
        method,
        data,
        params,
      });
      return {
        data: result.data,
        meta: {
          request: result.request,
          response: {
            status: result.status,
            statusText: result.statusText,
          },
        },
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError<APIError[] | APIError>;
      if (!err?.isAxiosError || err?.code?.includes('ERR_NOCK_NO_MATCH')) {
        console.error(method, baseUrl + url, JSON.stringify(params), err);
        console.warn(
          'Suggested checks:\n',
          '- Check if request path in mockRequest call has trailing slack\n',
        );
      }

      return {
        error: {
          status: err.response?.status,
          data: extractErrorLabelsAndParams(
            err?.isAxiosError
              ? err
              : [{ nonFieldErrors: [{ code: 'invalid', message: err.message }] }],
          ),
        },
      };
    }
  };
