import Loader, { LoaderFlexContainer } from '@bugbug/core/components/Loader';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import * as T from '@bugbug/core/utils/toolbox';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { EditAlertFormValues } from '../../EditAlertFormValues.types';

import type { TeamsIntegrationMember } from '@bugbug/core/types/integrations';
import FormField from '~/components/FormField';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useGetMembersListQuery } from '~/modules/integrations/integrations.api';
import { isBadRequestError, isInternalServerError } from '~/services/api/utils';

import EditAlertModalActionTrigger from '../EditAlertModalActionTrigger';
import { Description } from '../EditAlertModalThenContent.styled';

import * as S from './EditAlertModalActionTeams.styled';

export const EditAlertModalActionTeamsForm = () => {
  const { t } = useTranslation();
  const { getFieldProps, getFieldMeta, setFieldValue } = useFormikContext<EditAlertFormValues>();
  const { params } = useAppRoutes('alert');

  const {
    data = [],
    error,
    refetch,
    isLoading,
    isSuccess,
  } = useGetMembersListQuery({
    type: 'teams',
    projectId: params.projectId,
  });

  const teamNameFieldProps = getFieldProps('teamName');
  const nameFieldProps = getFieldProps('name');
  const channelsByTeams = useMemo(
    () => T.groupBy('teamName', data as TeamsIntegrationMember[]),
    [data],
  );
  const channels = useMemo(
    () => channelsByTeams[teamNameFieldProps.value] || [],
    [channelsByTeams, teamNameFieldProps.value],
  );
  const teams = Object.keys(channelsByTeams);

  const handleChannelChange = (event) => {
    const memberOnTheList = channels.find(({ name }) => name === event.target.value);

    // if statement is defined here just to avoid TS error;
    if (memberOnTheList) {
      setFieldValue('channelId', memberOnTheList.id, true);
      setFieldValue('name', memberOnTheList.name, true);
    }

    nameFieldProps.onChange(event);
  };

  useEffect(() => {
    if (channels.length) {
      setFieldValue('channelId', channels[0].id, true);
      setFieldValue('name', channels[0].name, true);
    }
  }, [channels, setFieldValue, teamNameFieldProps.value]);

  return (
    <>
      {(isLoading || error) && (
        <S.PendingStateContainer>
          {/* Loading state */}
          {isLoading && (
            <LoaderFlexContainer>
              <Loader />
              <p>{t('editAlertModal.slack.loadingData', 'Loading Slack data...')}</p>
            </LoaderFlexContainer>
          )}
          {/* Error state */}
          <ServerErrorInfo
            isVisible={isInternalServerError(error) || isBadRequestError(error)}
            onRetry={refetch}
          />
        </S.PendingStateContainer>
      )}

      {/* Loaded without errors */}
      {isSuccess && (
        <>
          <Description>
            <Trans i18nKey="editAlertModal.teams.description">
              You can only use the channels that were previously connected to the project in the
              Microsoft Teams.
            </Trans>
          </Description>
          <FormField
            label={t('editAlertModal.teams.fields.teamName.label', 'Team name')}
            labelId="teamName"
          >
            <Select
              {...getFieldMeta('teamName')}
              {...teamNameFieldProps}
              placeholder={t('editAlertModal.teams.fields.teamName.placeholder', 'Choose a team')}
              aria-labelledby="teamName"
              fullWidth
            >
              {teams.map((teamName) => (
                <SelectOption key={teamName} value={teamName} />
              ))}
            </Select>
          </FormField>
          <FormField
            label={t('editAlertModal.teams.fields.channelName.label', 'Channel name')}
            labelId="channelId"
          >
            <Select
              {...getFieldMeta('name')}
              {...getFieldProps('name')}
              onChange={handleChannelChange}
              aria-labelledby="channelId"
              placeholder={t(
                'editAlertModal.teams.fields.channelName.placeholder',
                'Choose a channel',
              )}
              fullWidth
            >
              {channels.map(({ name }) => (
                <SelectOption key={name} value={name}>
                  {name}
                </SelectOption>
              ))}
            </Select>
          </FormField>
          <EditAlertModalActionTrigger />
        </>
      )}
    </>
  );
};
