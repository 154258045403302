import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgTrialPerkLock = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 61, height: 61, viewBox: "0 0 61 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { width: 60, height: 60, rx: 30, transform: "matrix(-1 0 0 1 60.5 0.0214844)", fill: "white" }),
  /* @__PURE__ */ jsx("path", { d: "M34.8672 12.828C34.4951 12.8108 32.9327 12.6667 32.7291 12.8267C32.6418 13.0158 32.7332 14.0985 32.6236 14.5426C33.3652 14.5434 34.1282 14.5073 34.8672 14.561V12.828Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M21.5861 27.9062L19.53 27.9083L19.5272 29.97L21.5325 29.9722C21.5303 30.1209 21.4867 30.4271 21.5553 30.5575C21.5604 30.3754 21.5262 30.164 21.5814 29.9901L21.6641 29.9617L21.6071 29.9465C21.4415 29.7095 21.5535 28.2818 21.5861 27.9062Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M41.4556 45.5305C41.4329 45.4892 41.4413 45.5095 41.4265 45.4567C41.4228 45.4432 41.4194 45.4296 41.4158 45.416L41.3898 45.4738C40.7218 45.5893 40.0207 45.5075 39.3469 45.4882C39.2811 46.208 39.3748 46.9569 39.3327 47.6843C40.0396 47.6752 40.7468 47.6802 41.4537 47.6813C41.4686 46.9656 41.4559 46.2465 41.4556 45.5305Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M21.5581 45.4937C20.8831 45.4479 20.2019 45.4567 19.5254 45.4512C19.5269 45.7544 19.5492 46.0741 19.5035 46.3741L19.497 47.7089L21.5679 47.7024C21.5554 46.9776 21.5228 46.2401 21.582 45.5174L21.5581 45.4937Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M26.0458 14.582C25.3173 14.6077 24.58 14.5899 23.8506 14.5927C23.8616 15.2144 23.7714 16.1686 23.8551 16.7381L26.0491 16.7386C26.0692 16.0222 26.0522 15.2988 26.0458 14.582Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M34.8613 14.5645L34.8862 16.737L37.1015 16.7386L37.1016 14.5913C36.3564 14.578 35.6047 14.6168 34.8613 14.5645Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M39.3108 16.7392C38.5806 16.6905 37.8335 16.7285 37.1017 16.7364C37.2035 17.6564 37.138 18.632 37.1342 19.5583L37.1493 23.4717C37.8428 23.4988 38.6357 23.5459 39.3203 23.4581L39.3108 16.7392Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M34.8668 12.3555L26.0604 12.3557C26.0591 13.0154 26.0417 13.6795 26.0648 14.3387C26.0617 14.4326 26.0417 14.471 26.1016 14.5487C26.7032 14.5837 27.3188 14.553 27.9216 14.5498C28.8055 14.5452 29.6914 14.557 30.5749 14.5379L32.6236 14.541C32.7332 14.097 32.6418 13.0143 32.7291 12.8251C32.9327 12.6651 34.4951 12.8092 34.8672 12.8264L34.8668 12.3555Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M30.4505 12.7991C28.9783 12.9116 27.5445 12.7765 26.0744 12.7923C26.0746 13.3032 26.1029 13.8311 26.0602 14.3397C26.0571 14.4336 26.0371 14.472 26.0971 14.5497C26.6986 14.5847 27.3142 14.554 27.917 14.5508C28.8009 14.5462 29.6868 14.558 30.5703 14.5389C30.395 13.9826 30.4509 13.3762 30.4505 12.7991Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M23.7617 16.7832L21.628 16.7842L21.6304 23.9791C21.6301 25.268 21.6806 26.5852 21.6044 27.8707C21.774 27.8094 22.1352 27.8721 22.3256 27.88C22.8037 27.8998 23.2806 27.8716 23.7579 27.8455V19.2561C23.7622 18.4325 23.7435 17.6064 23.7617 16.7832Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M19.5191 29.9707L19.5229 39.3495C19.5224 40.6989 19.486 42.0519 19.5041 43.4008C20.1683 43.3058 20.8646 43.3599 21.5327 43.383C21.4944 42.0836 21.5257 40.7749 21.5256 39.4744C21.5256 38.7464 21.4598 37.8997 21.5502 37.1822C21.5759 36.4435 21.5533 35.6964 21.5533 34.9569L21.5471 30.5582C21.4786 30.4278 21.5222 30.1216 21.5244 29.9728L19.5191 29.9707Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M37.1152 27.8393L28.229 27.8403L25.3636 27.8405C24.8324 27.8402 24.2952 27.8223 23.7647 27.8472C23.2874 27.8732 22.8105 27.9014 22.3325 27.8816C22.1421 27.8737 21.7809 27.811 21.6113 27.8723L21.5808 27.9095C21.5483 28.285 21.4363 29.7127 21.6019 29.9497L21.6588 29.965C22.4741 29.9223 23.2975 29.9447 24.114 29.9439L29.0956 29.9702C31.7736 29.9869 34.4652 29.8963 37.1406 29.9832C37.0347 29.559 37.1119 28.3397 37.1152 27.8393Z", fill: "#9A12F6" }),
  /* @__PURE__ */ jsx("path", { d: "M39.7043 30.0605L29.1973 30.0681L25.8092 30.0692C25.1811 30.0676 24.5459 29.9352 23.9187 30.1185C23.3543 30.3106 22.7905 30.5184 22.2252 30.3722C22.0001 30.3141 21.573 29.8522 21.3724 30.304L21.3365 30.578C21.298 33.3451 21.1655 43.8663 21.3613 45.6128L21.4287 45.7254C22.3926 45.4111 23.3662 45.5756 24.3317 45.57L30.222 45.7641C33.3884 45.8868 36.5709 45.2191 39.7344 45.8593C39.6091 42.7336 39.7005 33.7485 39.7043 30.0605Z", fill: "white" }),
  /* @__PURE__ */ jsx("path", { d: "M39.3516 45.4896C38.6097 45.4964 37.8525 45.4632 37.1125 45.5003C36.4543 45.565 35.7588 45.5245 35.0962 45.5252L31.4059 45.5278L25.0696 45.5303C23.9073 45.5301 22.7395 45.5528 21.5778 45.5202C21.5186 46.243 21.5512 46.9805 21.5637 47.7052L33.1006 47.6848C35.1787 47.682 37.2595 47.658 39.3373 47.6857C39.3794 46.9582 39.2858 46.2094 39.3516 45.4896Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M41.4473 27.8301L37.1108 27.8369C37.1076 28.3374 37.0303 29.5566 37.1363 29.9808L37.1504 30.0218C37.2227 30.9961 37.146 31.9651 37.1444 32.9392L37.1445 38.9623L37.1492 43.1759C37.1526 43.9249 37.2496 44.7649 37.1081 45.5001C37.8482 45.463 38.6053 45.4962 39.3472 45.4894C40.021 45.5086 40.722 45.5905 41.3901 45.475L41.416 45.4172C41.4196 45.4307 41.4231 45.4444 41.4268 45.4579C41.4415 45.5107 41.4332 45.4904 41.4559 45.5317C41.4352 44.9286 41.4598 44.3185 41.4609 43.7148L41.455 40.3086L41.4473 27.8301Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M39.3592 30.0107C38.9376 30.0181 38.5149 30.0287 38.0933 30.0234C37.7864 30.0196 37.4583 29.9836 37.1539 30.0201C37.2263 30.9944 37.1495 31.9634 37.148 32.9375L37.148 38.9607L37.1527 43.1743C37.1561 43.9233 37.2531 44.7632 37.1117 45.4985C37.8517 45.4614 38.6089 45.4945 39.3507 45.4878C39.3929 45.0213 39.356 44.5244 39.361 44.0545L39.375 40.5807L39.3592 30.0107Z", fill: "#CEC2C1" }),
  /* @__PURE__ */ jsx("path", { d: "M21.5509 37.1816C21.4605 37.8991 21.5263 38.7458 21.5263 39.4738C21.5264 40.7743 21.4951 42.0831 21.5334 43.3824C20.8653 43.3593 20.169 43.3052 19.5048 43.4002C19.5068 44.3908 19.4732 45.3863 19.4971 46.376C19.5427 46.076 19.5204 45.7562 19.519 45.453C20.1954 45.4586 20.8767 45.4498 21.5517 45.4955C21.5918 44.5008 21.5545 43.4891 21.5552 42.4923L21.5509 37.1816Z", fill: "#511ECA" }),
  /* @__PURE__ */ jsx("path", { d: "M30.4766 38.8716C29.7491 38.8441 29.0274 38.9156 28.3025 38.8955L28.3249 41.0126C29.0446 41.0172 29.7648 41.0176 30.4844 41.0337C30.4603 40.3143 30.4755 39.5915 30.4766 38.8716Z", fill: "#587BFE" }),
  /* @__PURE__ */ jsx("path", { d: "M32.6787 38.8262C32.3538 39.0305 30.9037 38.9127 30.4769 38.8694C30.4758 39.5893 30.4606 40.3121 30.4846 41.0316L32.6592 41.0373C32.6798 40.2998 32.6813 39.5638 32.6787 38.8262Z", fill: "#0549E8" }),
  /* @__PURE__ */ jsx("path", { d: "M30.4761 34.4805C29.7802 34.481 28.9814 34.5371 28.3005 34.4881L28.3023 36.6854L30.4766 36.6857L30.4761 34.4805Z", fill: "#9AAFFE" }),
  /* @__PURE__ */ jsx("path", { d: "M32.6797 34.4866C31.946 34.4731 31.2124 34.4955 30.4789 34.4805L30.4793 36.6857C31.0242 36.6841 32.0583 36.6146 32.5459 36.7152C32.5555 36.7172 32.565 36.7195 32.5746 36.7217L32.5854 36.7091L32.6415 36.7369C32.6755 36.6586 32.6645 36.5216 32.6678 36.4337L32.6797 34.4866Z", fill: "#587BFE" }),
  /* @__PURE__ */ jsx("path", { d: "M32.6822 38.8245C32.6632 38.0278 32.713 37.228 32.6681 36.4316C32.6648 36.5196 32.6758 36.6565 32.6418 36.7348L32.5857 36.7071L32.575 36.7196C32.5654 36.7174 32.5558 36.7151 32.5462 36.7131C32.0586 36.6125 31.0245 36.682 30.4797 36.6836L28.3055 36.6833C28.3059 37.4189 28.316 38.1561 28.3062 38.8916C29.0311 38.9118 29.7529 38.8403 30.4804 38.8678C30.9072 38.9111 32.3573 39.0288 32.6822 38.8245Z", fill: "#511ECA" })
] });
const ForwardRef = forwardRef(SvgTrialPerkLock);
export default ForwardRef;
