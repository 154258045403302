import type { RunStatus } from '@bugbug/core/types/base';
import type { RunStatusFlags } from '@bugbug/core/types/tests';

import { RUN_STATUS_ICON, RUN_STATUS_LABEL } from '../../constants/status';
import { isRecordingOrPausedStatus, isRunningStatus } from '../../types/base';
import { getExtendedRunStatus } from '../../utils/status';
import Icon from '../Icon';
import Loader from '../Loader';

import { STATUS_TOOLTIP } from './StatusBadge.constants';
import { Container, IconContainer, RelativeTime, Label, Tooltip } from './StatusBadge.styled';

interface StatusBadgeProps {
  className?: string;
  date?: string;
  status?: RunStatus;
  extended?: boolean;
  warning?: boolean;
  hiddenStatusLabel?: boolean;
  ignoredFailed?: boolean;
  autoRetried?: boolean;
  newStepAdded?: boolean;
  errorCode?: string;
}

export const StatusBadge = ({
  className,
  status,
  date,
  extended,
  warning,
  ignoredFailed,
  autoRetried,
  hiddenStatusLabel,
  newStepAdded,
  errorCode,
}: StatusBadgeProps) => {
  if (!status) {
    return null;
  }

  const isActive = isRecordingOrPausedStatus(status) || isRunningStatus(status);
  const isQueued = status === 'queued';

  const getFinalStatus = () => {
    if (newStepAdded) {
      return 'just-edited';
    }

    if (autoRetried) {
      return 'failed-retried';
    }

    const statusFlags: Omit<RunStatusFlags, 'forceFailed'> = {
      status,
      warning: !!warning,
      ignoredFailed: !!ignoredFailed,
    };

    return getExtendedRunStatus(statusFlags);
  };

  const finalStatus = getFinalStatus();
  const label = RUN_STATUS_LABEL[finalStatus];
  const tooltipContent = STATUS_TOOLTIP[finalStatus] ?? errorCode;

  return (
    <Tooltip content={tooltipContent}>
      <Container data-testid="StatusBadge" className={className} status={finalStatus}>
        <IconContainer title={!errorCode ? finalStatus : undefined}>
          {!(extended && isActive) ? (
            <Icon name={RUN_STATUS_ICON[finalStatus] || 'smallDot'} />
          ) : (
            <Loader size="small" stopped={isQueued} />
          )}
        </IconContainer>
        <Label
          visible={(extended || isRecordingOrPausedStatus(status)) && !hiddenStatusLabel && !!label}
        >
          {label}
          {isActive && !isRecordingOrPausedStatus(status) ? '...' : ''}
        </Label>
        {!((isActive || autoRetried) && extended) && !!date && (
          <RelativeTime date={date as string} noTooltip={!!errorCode} />
        )}
      </Container>
    </Tooltip>
  );
};
