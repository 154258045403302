import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { MouseEventHandler } from 'react';

import type { Group } from '@bugbug/core/types/groups';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { useSwitchToComponentMutation } from '~/modules/groups/groups.api';
import { useAppDispatch } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import analytics from '~/services/analytics';
import { getExtractedErrorParams, isBadRequestError } from '~/services/api/utils';

import * as S from './ToggleComponent.styled';

interface MakeComponentProps {
  groupId: Group['id'];
}

const MakeComponent = ({ groupId }: MakeComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const modal = useModal();
  const [handleSwitchToComponent, switchToComponentRequest] = useSwitchToComponentMutation();
  const appRoutes = useAppRoutes('test');

  useEffect(() => {
    if (isBadRequestError(switchToComponentRequest.error)) {
      const params = getExtractedErrorParams(switchToComponentRequest.error);

      if (params.componentNameNotUnique) {
        modal.show(
          'invalid_component_name',
          {
            data: {
              ...appRoutes.params,
              name: params.componentNameNotUnique.name,
              componentId: params.componentNameNotUnique.groupId,
            },
          },
          {
            onHide: switchToComponentRequest.reset,
          },
        );
      }
    }

    /*
      To keep compatibility with old API/store (test.sagas)
      Remove after migration to RTK Toolkit.
    */
    if (switchToComponentRequest.isSuccess) {
      dispatch(TestActions.setGroupAsComponentSuccess(groupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    groupId,
    modal,
    switchToComponentRequest.error,
    switchToComponentRequest.isSuccess,
  ]);

  const handleMakeComponentClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    analytics.trackEvent('component_made');
    handleSwitchToComponent(groupId);
  };

  if (switchToComponentRequest.isLoading) {
    return (
      <S.Wrapper>
        <S.Container data-testid="PendingComponent" disabled>
          <S.Loader />
          {t('makeComponent.create.pending.label', 'Creating...')}
        </S.Container>
      </S.Wrapper>
    );
  }

  const tooltipContent = t(
    'makeComponent.create.tooltip',
    'Make this group reusable in multiple tests',
  );
  return (
    <S.Wrapper>
      <S.Tooltip content={tooltipContent}>
        <S.Container data-testid="MakeComponent" ref={buttonRef} onClick={handleMakeComponentClick}>
          {t('makeComponent.create.label', '❖ Make component')}
        </S.Container>
      </S.Tooltip>
    </S.Wrapper>
  );
};

export default MakeComponent;
