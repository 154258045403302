import { useImperativeHandle, forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { SideEffect } from '@bugbug/core/types/utils';
import { useAppDispatch } from '~/modules/store';
import { AUTH_PROVIDER } from '~/modules/user/user.constants';
import { UserActions } from '~/modules/user/user.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

import { Separator } from '../Separator/Separator';

import * as S from './AuthProviders.styled';

export interface AuthProvidersRef {
  loginUsingGoogle: SideEffect;
  loginUsingGithub: SideEffect;
}

export const AuthProviders = forwardRef<AuthProvidersRef>((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const loginUsingProvider = useCallback(
    (provider) => {
      analytics.trackEvent(TRACK_EVENT_TYPE.START_SIGN_UP, { provider });
      dispatch(UserActions.getSocialAuthUrlRequest({ provider }));
    },
    [dispatch],
  );

  const githubLogin = useCallback(
    () => loginUsingProvider(AUTH_PROVIDER.GITHUB),
    [loginUsingProvider],
  );

  const googleLogin = useCallback(
    () => loginUsingProvider(AUTH_PROVIDER.GOOGLE),
    [loginUsingProvider],
  );

  useImperativeHandle(
    ref,
    () => ({
      loginUsingGoogle: googleLogin,
      loginUsingGithub: githubLogin,
    }),
    [googleLogin, githubLogin],
  );

  return (
    <>
      <Separator>{t('authProviders.separator', 'or')}</Separator>
      <div>
        <S.Container data-testid="AuthProviders">
          <S.SocialButton onClick={googleLogin}>
            <S.GoogleLogo />
            {t('authProviders.google.label', 'Continue with Google')}
          </S.SocialButton>
          <S.SocialButton onClick={githubLogin}>
            <S.GitHubLogo />
            {t('authProviders.github.label', 'Continue with GitHub')}
          </S.SocialButton>
        </S.Container>
      </div>
    </>
  );
});

AuthProviders.displayName = 'AuthProviders';
