import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgEmailLogo = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 49, height: 36, viewBox: "0 0 49 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M3.11674 0.0234375L31.39 0.0284689L40.3193 0.040773C41.8433 0.0442214 43.3731 0.081872 44.8966 0.0441263C44.8952 0.589808 44.8112 2.82845 44.937 3.14915C45.9674 3.11942 47 3.14757 48.0308 3.13033L48.0286 6.33275C46.9833 6.32035 45.9373 6.33135 44.892 6.33563L44.9031 3.16145C43.9349 3.11756 42.9388 3.13079 41.9699 3.16416C40.6768 3.22679 39.3498 3.1636 38.052 3.16145L30.1852 3.15456L6.08849 3.15857C5.10692 3.15689 4.12469 3.14738 3.1433 3.16546C3.14806 4.2489 3.14647 5.33337 3.16641 6.41672L0.0585938 6.41886L0.0623211 3.15148L3.11786 3.16183L3.11674 0.0234375Z", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("path", { d: "M41.9701 3.16539C42.939 3.13203 43.9351 3.11879 44.9033 3.16269L44.8922 6.33687C45.9375 6.33258 46.9835 6.32159 48.0288 6.33398L48.024 26.3497L48.0027 32.7647L44.8974 32.7522L44.9015 35.9219L20.3689 35.9161C14.6369 35.9018 8.90089 35.8221 3.16951 35.8881L3.16541 32.8305C2.12465 32.8471 1.08157 32.8355 0.0405294 32.8377L0.0454684 28.5379C0.0159242 27.8246 0.0416474 27.0978 0.0412746 26.3837L0.0422075 22.0514L0.0539498 9.40574L0.0587964 6.4201L3.16662 6.41795C3.14667 5.33461 3.14826 4.25014 3.14351 3.1667C4.12489 3.14862 5.10712 3.15813 6.08869 3.1598C6.09121 4.24063 6.11404 5.32556 6.07779 6.40574L6.04852 6.42028L3.1641 6.41851C3.16801 7.25367 3.19346 8.09507 3.16159 8.92949C3.20949 9.99168 3.1861 11.0634 3.18507 12.1269L3.18153 17.3086L3.1819 31.2919L3.19262 32.7911C6.97269 32.7175 10.7597 32.7672 14.5407 32.7633L35.7362 32.7565C38.7765 32.7587 41.8235 32.8144 44.8628 32.7646L44.8623 26.3514L44.8879 6.36921L42.2199 6.36064L42.0728 6.36427L41.9712 6.34796L41.9701 3.16539Z", fill: "#521EC9" }),
  /* @__PURE__ */ jsx("path", { d: "M6.08726 3.16026L30.184 3.15625L38.0508 3.16314C39.3486 3.16529 40.6756 3.22848 41.9687 3.16585L41.9698 6.34842L42.0714 6.36473L42.2185 6.36109L44.8865 6.36966L44.8609 26.3519L44.8614 32.7651C41.8221 32.8149 38.775 32.7591 35.7348 32.757L14.5393 32.7638C10.7582 32.7677 6.97126 32.7179 3.19119 32.7916L3.18047 31.2924L3.1801 17.309L3.18364 12.1273C3.18467 11.0638 3.20806 9.99213 3.16016 8.92994C3.19203 8.09553 3.16658 7.25413 3.16267 6.41897L6.04709 6.42074L6.07636 6.4062C6.11261 5.32602 6.08978 4.24109 6.08726 3.16026Z", fill: "white" }),
  /* @__PURE__ */ jsx("path", { d: "M25.6153 20.168L28.9977 20.1711C29.0076 21.2702 28.9995 22.3692 28.9929 23.4683L25.661 23.473C25.6632 24.5848 25.6776 25.6984 25.662 26.81L22.3809 26.818C22.3703 25.7151 22.3088 24.5779 22.3859 23.4781L25.6291 23.4727C25.5985 22.3733 25.617 21.268 25.6153 20.168Z", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("path", { d: "M32.349 13.4727C33.4532 13.5138 34.5669 13.4903 35.6722 13.4936L35.6754 16.8487L34.5923 16.8411L32.3714 16.845L32.3802 20.1697L28.9992 20.1708C28.9605 19.0595 28.9807 17.939 28.9727 16.8266C29.9386 16.8331 31.4402 16.9325 32.3518 16.8056C32.3281 15.696 32.3462 14.5826 32.349 13.4727Z", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("path", { d: "M15.5898 16.8125C16.738 16.851 17.8907 16.8451 19.0395 16.8371L19.0313 20.1676L22.4018 20.1684C22.4003 21.2606 22.456 22.3901 22.3875 23.4778L18.9974 23.4868L19.009 20.2022L15.5918 20.2119L15.5898 16.8125Z", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("path", { d: "M41.9721 6.34766L42.0737 6.36397C42.1023 7.60231 42.1078 8.83999 42.1096 10.0786C41.2124 10.0828 39.7406 10.1785 38.9137 10.0572C38.7902 11.1059 38.897 12.3453 38.8889 13.4174C37.8416 13.4205 36.7192 13.348 35.681 13.4626C35.5602 13.0871 35.6485 10.6598 35.6458 10.0486C36.1388 10.0473 38.6067 10.1493 38.878 9.99864C38.8049 8.79712 38.8557 7.5613 38.8517 6.35595C39.8917 6.36453 40.9321 6.35297 41.9721 6.34766Z", fill: "#9A32F2" }),
  /* @__PURE__ */ jsx("path", { d: "M6 6.02451L6.02926 6.00997C7.0223 6.04856 8.08841 6.08071 9.078 6C9.22218 6.22778 9.11677 9.10931 9.11164 9.61203L12.292 9.60979L12.2776 12.994C13.3635 13.0101 14.4646 13.0264 15.5497 12.984L15.5418 16.4166L12.2356 16.4163L12.2455 13.0082L9.06756 13.0081L9.07614 9.61091L6.0014 9.61073L6 6.02451Z", fill: "#9A32F2" })
] });
const ForwardRef = forwardRef(SvgEmailLogo);
export default ForwardRef;
