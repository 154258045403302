import useAppRoutes from '~/hooks/useAppRoutes';
import { selectIsIntegrationsActiveInProject } from '~/modules/integrations/integrations.selectors';
import { selectIsFreePlan } from '~/modules/organization/organization.selectors';
import { useAppSelector } from '~/modules/store';

import * as S from './EditAlertModalActionSlack.styled';
import EditAlertModalActionSlackAuth from './EditAlertModalActionSlackAuth';
import EditAlertModalActionSlackForm from './EditAlertModalActionSlackForm';

const EditAlertModalActionSlack = () => {
  const { params } = useAppRoutes('alert');
  const isActive = useAppSelector(selectIsIntegrationsActiveInProject(params.projectId, 'slack'));
  const isFreePlan = useAppSelector(selectIsFreePlan) as boolean;

  if (isFreePlan) {
    return <S.UpgradePlan centeredContent={false} />;
  }

  return isActive ? <EditAlertModalActionSlackForm /> : <EditAlertModalActionSlackAuth />;
};

export default EditAlertModalActionSlack;
