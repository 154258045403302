import { isRunningStatus } from '@bugbug/core/types/base';
import { last } from '@bugbug/core/utils/toolbox';
import { forwardRef } from 'react';

import type { DropdownRef } from '@bugbug/core/components/Dropdown';
import type { Test } from '@bugbug/core/types/tests';
import useTestRunner from '~/hooks/useTestRunner';
import { useAppSelector } from '~/modules/store';
import { useIsNewPlaybackSupported } from '~/modules/test/test.hooks';
import {
  selectIsTestRunPaused,
  selectSingleTest,
  selectSortedSteps,
  selectTestGroups,
  selectTestIsRecording,
} from '~/modules/test/test.selectors';

import * as S from '../RowActiveBorder.styled';

import { CommonActionsMenu } from './CommonActionsMenu';
import { IdleStateMenu } from './IdleStateMenu';
import { RecordingAndPauseMenu } from './RecordingAndPauseMenu';

export type RowActiveBorderMenuRef = DropdownRef;

interface RowActiveBorderMenuProps {
  row: {
    id: string;
    groupId?: string;
    steps?: string[];
  };
  index: number;
  disabledGroupOptions: boolean;
  disabledSplitting: boolean;
  disabledStepOptions: boolean;
  disabledRunAndStop: boolean;
  testsCount: number;
  componentMode: boolean;
  onToggle?: () => void;
}

export const RowActiveBorderMenu = forwardRef<RowActiveBorderMenuRef, RowActiveBorderMenuProps>(
  (
    {
      row,
      index,
      disabledGroupOptions,
      disabledRunAndStop,
      disabledSplitting,
      disabledStepOptions,
      testsCount,
      componentMode,
      onToggle,
    },
    ref,
  ) => {
    const test = useAppSelector(selectSingleTest) as Test & { groups: string[] };
    const testRunner = useTestRunner(test);
    const isPaused = useAppSelector(selectIsTestRunPaused);
    const groups = useAppSelector(selectTestGroups);

    const groupId = row.groupId || test?.groups?.[index];
    const isStepRow = !!row.groupId;
    const atIndex = isStepRow ? index : row.steps?.length ?? 0;
    const status = test?.testRun?.status;
    const isRunning = !!status && isRunningStatus(status) && status !== 'queued';
    const isRecording = useAppSelector(selectTestIsRecording);

    const sortedSteps = useAppSelector(selectSortedSteps);
    const groupIndex = test.groups.indexOf(groupId);
    const prevGroupIndex = groupIndex - 1;
    const prevGroupId = test.groups[prevGroupIndex];

    const stepIndexAtGroup = atIndex - 1;
    const afterStepId = groups[groupId]?.steps[stepIndexAtGroup] ?? null;
    const lastStepIdFromPreviousGroup = last(groups[prevGroupId]?.steps) ?? null;
    const afterStepIdOrLastStepIdFromPreviousGroup =
      afterStepId ?? lastStepIdFromPreviousGroup ?? null;

    const stepIndex = sortedSteps.findIndex((s) => s.id === afterStepId);
    const step = sortedSteps[stepIndex];

    const nextStepIndex =
      sortedSteps.findIndex((s) => s.id === afterStepIdOrLastStepIdFromPreviousGroup) + 1;
    const nextStep = sortedSteps[nextStepIndex] ?? groups[groupId]?.steps[0] ?? null;

    const previousStepId =
      afterStepId ?? sortedSteps.findLast((s) => test.groups.indexOf(s.groupId) < groupIndex)?.id;

    const isNewPlaybackSupported = useIsNewPlaybackSupported();
    const disablePlaybackOptions =
      isNewPlaybackSupported && nextStep && !nextStep.isActive && !step?.isActive;

    return (
      <S.Menu
        ref={ref}
        iconName="add"
        hideExpander
        onOpen={onToggle}
        onClose={onToggle}
        data-testid="RowActiveBorder.Menu"
        paused={isPaused}
      >
        {isRecording || isPaused ? (
          <RecordingAndPauseMenu
            onResumeLocalRunner={testRunner.resumeLocalRunner}
            groupId={groupId}
            groupIndex={groupIndex}
            nextStepIndex={nextStepIndex}
            afterStepId={afterStepId}
            paused={isPaused}
            running={isRunning}
            disabledStepOptions={disabledStepOptions}
            disablePlaybackOptions={disablePlaybackOptions}
          />
        ) : (
          <IdleStateMenu
            onRecord={testRunner.record}
            onStartLocalRunner={testRunner.startLocalRunner}
            groupId={groupId}
            groupIndex={groupIndex}
            atIndex={atIndex}
            afterStepId={afterStepId}
            previousStepId={previousStepId}
            running={isRunning}
            newPlaybackSupported={isNewPlaybackSupported}
            disabledStepOptions={disabledStepOptions}
            disablePlaybackOptions={disablePlaybackOptions}
            disabledRunAndStop={disabledRunAndStop}
            disabledGroupOptions={disabledGroupOptions}
            afterStepIdOrLastStepIdFromPreviousGroup={afterStepIdOrLastStepIdFromPreviousGroup}
          />
        )}
        <S.OptionsSeparator />
        <CommonActionsMenu
          testId={test.id}
          groupId={groupId}
          disabledGroupOptions={disabledGroupOptions}
          disabledStepOptions={disabledStepOptions}
          disabledSplitting={disabledSplitting}
          testsCount={testsCount}
          index={index}
          atIndex={atIndex}
          nextGroupIndex={Math.min(index + 1, test?.groups?.length || 0)}
          componentMode={componentMode}
        />
      </S.Menu>
    );
  },
);

RowActiveBorderMenu.displayName = 'RowActiveBorderMenu';
