import { noop } from '@bugbug/core/utils/toolbox';

import type { AssertionProperty, Step } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';
import StepName from '~/components/StepName';
import useModal from '~/hooks/useModal';

import { Container, ExpandIcon } from './StepTypePickerInput.styled';

interface StepTypePickerInputProps {
  'aria-labelledby'?: string;
  className?: string;
  name?: string;
  value?: Step['type'];
  readOnly?: boolean;
  assertionPropertyValue?: AssertionProperty;
  assertionVariantValue?: string;
  onChange?: SideEffect<{
    target: {
      name?: string;
      value: Step['type'];
      assertionProperty: AssertionProperty;
    };
  }>;
}

export const StepTypePickerInput = (props: StepTypePickerInputProps) => {
  const {
    'aria-labelledby': ariaLabelledBy,
    className,
    name,
    value,
    assertionPropertyValue,
    assertionVariantValue,
    onChange = noop,
    readOnly,
  } = props;
  const modal = useModal();

  const handleChange = (stepType: Step['type'], assertionProperty: AssertionProperty) => {
    onChange({
      target: { name, value: stepType, assertionProperty },
    });
  };

  const handleClick = () =>
    modal.showWide('edit_step', {
      stepType: value,
      assertionProperty: assertionPropertyValue,
      assertionVariant: assertionVariantValue,
      onStepChange: handleChange,
    });

  return (
    <Container
      className={className}
      data-testid="StepTypePickerInput"
      readOnly={readOnly}
      disabled={readOnly}
      aria-labelledby={ariaLabelledBy}
      onFocus={handleClick}
      onClick={handleClick}
    >
      <StepName type={value} assertType={assertionPropertyValue} />
      <ExpandIcon />
    </Container>
  );
};
