import { Portal } from '@ariakit/react';
import { createEmptySelectorsGroup } from '@bugbug/core/utils/selectors';
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import type { MouseEventHandler } from 'react';

import type { SelectorsPreset, SelectorsGroup } from '@bugbug/core/types/steps';
import type { SideEffect, Maybe } from '@bugbug/core/types/utils';

import * as S from './CustomSelectorsPreset.styled';
import { DraggableCustomGroup } from './DraggableCustomGroup';

interface CustomSelectorsPresetProps {
  name: string;
  preset: SelectorsPreset;
  onChange: SideEffect<SelectorsPreset>;
  relationDisabled?: boolean;
  context?: 'component' | 'test' | 'testRun';
  disabled?: boolean;
}

export const CustomSelectorsPreset = ({
  name,
  preset,
  onChange,
  relationDisabled,
  disabled,
}: CustomSelectorsPresetProps) => {
  const thenFindButtonRef = useRef<HTMLButtonElement>(null);
  const autoScrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [draggedGroupId, setDraggedGroupId] = useState<Maybe<string>>(null);
  const draggedGroupIndex = preset.selectorsGroups.findIndex(({ id }) => id === draggedGroupId);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'elementSelectorBuilder.customSelectorsPreset',
  });

  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(
    () => () => {
      if (autoScrollTimeout.current) {
        clearTimeout(autoScrollTimeout.current);
      }
    },
    [],
  );

  const updateSelectorsGroups = (selectorsGroups: SelectorsGroup[]) => {
    onChange({
      ...preset,
      selectorsGroups,
    });
  };

  const handleGroupChange = (index: number) => (value: SelectorsGroup) => {
    updateSelectorsGroups(preset.selectorsGroups.toSpliced(index, 1, value));
  };

  const handleGroupDelete = (index: number) => () => {
    updateSelectorsGroups(preset.selectorsGroups.toSpliced(index, 1));
  };

  const handleThenFindClick: MouseEventHandler<HTMLButtonElement> = () => {
    updateSelectorsGroups([...preset.selectorsGroups, createEmptySelectorsGroup('customXPath')]);

    autoScrollTimeout.current = setTimeout(() => {
      if (thenFindButtonRef.current) {
        const container = thenFindButtonRef.current.parentElement!;
        const lastInput = [...container.querySelectorAll<HTMLTextAreaElement>('textarea')].at(-1);
        lastInput?.focus();

        // @ts-expect-error TS doesn't know about scrollIntoViewIfNeeded
        thenFindButtonRef.current.scrollIntoViewIfNeeded(false);
      }
    }, 0);
  };

  const handleGroupDragStart: SideEffect<DragStartEvent> = (event) => {
    if (!event.active) return;
    setDraggedGroupId(event.active.id as string);
  };

  const handleGroupOrderChange: SideEffect<DragEndEvent> = (event) => {
    setDraggedGroupId(null);

    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      const activeIndex = preset.selectorsGroups.findIndex(({ id }) => id === active.id);
      const overIndex = preset.selectorsGroups.findIndex(({ id }) => id === over.id);
      if ([overIndex, activeIndex].includes(-1)) return;

      const groups = arrayMove(preset.selectorsGroups, activeIndex, overIndex);
      // We need to be sure that the first group has a descendant relation after position switch
      groups[0] = {
        ...groups[0],
        relation: 'descendant',
      };

      updateSelectorsGroups(groups);
    }
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={handleGroupDragStart}
        onDragEnd={handleGroupOrderChange}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext
          items={preset.selectorsGroups.map(({ id }) => id)}
          strategy={verticalListSortingStrategy}
        >
          {preset.selectorsGroups.map((selectorsGroup, index, allGroups) => (
            <DraggableCustomGroup
              // eslint-disable-next-line react/no-array-index-key
              name={`${name}.selectorsGroups[${index}]`}
              key={selectorsGroup.id}
              id={selectorsGroup.id}
              value={selectorsGroup}
              onChange={handleGroupChange(index)}
              onDelete={
                index === 0 && allGroups.length === 1 ? undefined : handleGroupDelete(index)
              }
              first={index === 0}
              relationDisabled={relationDisabled}
              disabled={disabled}
            />
          ))}
        </SortableContext>
        {draggedGroupIndex !== null && (
          <Portal>
            <DragOverlay>
              <DraggableCustomGroup
                id="dragged-group"
                value={preset.selectorsGroups[draggedGroupIndex]}
                disabled
                first={draggedGroupIndex === 0}
              />
            </DragOverlay>
          </Portal>
        )}
      </DndContext>
      {!disabled && (
        <S.ThenFindButton
          variant="default"
          onClick={handleThenFindClick}
          iconName="add"
          ref={thenFindButtonRef}
        >
          {t('addSelector', 'Then find')}
        </S.ThenFindButton>
      )}
    </>
  );
};
