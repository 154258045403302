import { CheckmarkContainer } from '@bugbug/core/components/Checkbox/Checkbox.styled';
import Icon from '@bugbug/core/components/Icon';
import mixins from '@bugbug/core/theme/mixins';
import styled, { css } from 'styled-components';

import { DragHandler as DragHandlerBase } from '~/components/DragHandler/DragHandler';
import { Container as ImageContainer } from '~/components/ImageWithPreview/ImageWithPreview.styled';
import { tableRowStyles } from '~/components/Table';

import { Container as MoreActionsContainer } from './MoreActions/MoreActions.styled';

export const DragHandler = styled(DragHandlerBase)`
  opacity: 0;
  flex: none;
`;

export const DragPlaceholder = styled.div`
  height: 100%;
  width: 16px;
  flex: none;
`;

export const Container = styled.div`
  ${mixins.layout.flexCenter};
  ${tableRowStyles};
  justify-content: flex-start;
  height: 52px;
  cursor: pointer;
  position: relative;
  border-top-color: ${({ theme }) => theme.colors.neutrals[94]};
  border-bottom-color: ${({ theme }) => theme.colors.common.transparent};
  transition: background-color 200ms ease-in-out, box-shadow 200ms ease-in-out;

  ${ImageContainer} {
    filter: saturate(0%);
    opacity: 0.56;
    transition: filter 150ms, opacity 150ms;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: none;
      width: 100%;
      height: 100%;
      opacity: 0.35;
      pointer-events: none;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.highlightLight};

    ${DragHandler} {
      opacity: 1;
    }

    ${MoreActionsContainer} {
      display: flex;
    }
  }

  ${({ active, theme }) =>
    !active &&
    css`
      background-image: repeating-linear-gradient(
        135deg,
        #f2f2f2 9%,
        #f2f2f2 11%,
        ${theme.colors.common.white} 0,
        ${theme.colors.common.white} 13%
      );
    `}

  &[aria-selected='true'] {
    border-radius: 5px !important;
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.colors.primarySoft};
    background: ${({ theme }) => theme.colors.common.muted};

    ${({ active, theme }) =>
      !active &&
      css`
        background-image: repeating-linear-gradient(
          135deg,
          ${theme.colors.common.muted} 9%,
          ${theme.colors.common.muted} 11%,
          ${theme.colors.common.white} 0,
          ${theme.colors.common.white} 13%
        );
      `}
  }

  &:hover,
  &[aria-selected='true'] {
    ${ImageContainer} {
      filter: none;
      opacity: 1;

      &:after {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.common.black};
      }
    }
  }
`;

// Data sections
const baseSectionStyles = css`
  ${mixins.layout.flexCenter};
  justify-content: flex-start;
  box-sizing: border-box;
  word-break: break-all;
  flex: 0 1 auto;
  padding: 2px 6px;
  position: relative;
  min-width: 0px;
  min-height: 100%;
`;

export const CheckboxSection = styled.section`
  ${baseSectionStyles};
  padding: 2px 7px 2px 5px;

  ${CheckmarkContainer} {
    width: 18px;
    height: 18px;
  }
`;

export const NameSection = styled.section`
  ${baseSectionStyles};
  min-width: 240px;
  width: 43%;
  max-width: 9.0072e15px;
`;

export const ScreenshotSection = styled.section`
  ${baseSectionStyles};
  flex: 200 0 auto;
  width: 200px;
  max-width: 9.0072e15px;
`;

export const StatusSection = styled.section`
  ${baseSectionStyles};
  flex: 20 0 auto;
  width: 20px;
  max-width: 30px;
`;

export const MoreSection = styled.section`
  ${baseSectionStyles};
  flex: 55 0 auto;
  min-width: 0px;
  width: 55px;
  max-width: 55px;
`;

export const AddIconWrapper = styled.div`
  position: absolute;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  padding: 0;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.common.white};
  border: 2px solid ${({ theme }) => theme.colors.border.normal};
  top: -12px;
  right: 0;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition-delay: 50ms;

  ${({ bottom }) =>
    bottom &&
    css`
      bottom: -12px;
      top: unset;
    `}

  ${Container}:hover & {
    opacity: 1;
    transition-delay: 0ms;
  }
`;

export const AddIcon = styled(Icon).attrs(() => ({
  name: 'add',
}))`
  font-size: 18px !important;
  fill: ${({ theme }) => theme.colors.border.normal} !important;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  position: relative;

  &:last-child ${Container} {
    border-radius: 0 0 5px 5px;
  }
`;
