import Button from '@bugbug/core/components/Button';
import styled from 'styled-components';

import FormFieldBase from '~/components/FormField';

export const FieldsRow = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
`;

export const FormField = styled(FormFieldBase)`
  flex: 1;
  padding: 0;

  &:first-child {
    flex: 0 1 212px;
  }
`;

export const RemoveRowButton = styled(Button)`
  width: 40px;
`;

export const RemoveRowPlaceholder = styled.div`
  width: 40px;
`;

export const SelectOptionDescription = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 12px;
  margin-top: 2px;
`;
