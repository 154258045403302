import Icon from '@bugbug/core/components/Icon';
import { STEP_TYPE } from '@bugbug/core/constants/steps';
import { always, cond, equals, T } from 'ramda';
import { useCallback, useState } from 'react';

import StepName from '~/components/StepName';
import Tabs, { Tab } from '~/components/Tabs';
import { STEP_TYPE_ICON_NAME, STEP_TYPE_LABEL, ASSERTION_PROPERTY } from '~/constants/step';
import useModal from '~/hooks/useModal';
import { selectIsFreePlan } from '~/modules/organization/organization.selectors';
import { useAppSelector } from '~/modules/store';

import { PaidFeature } from '../PaidFeatureGuard';

import { TABS, TAB, STEP_TYPES, ASSERTION_TYPES } from './StepTypePicker.constants';
import * as S from './StepTypePicker.styled';

const StepTypePicker = ({
  className = '',
  initialTab = TAB.ACTIONS,
  stepType = null,
  assertionProperty = null,
  onChange,
}) => {
  const modal = useModal();
  const isFreePlan = useAppSelector(selectIsFreePlan);
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const handleTabChange = useCallback((e, tab) => setSelectedTab(tab), []);

  const renderTab = (data) => {
    const { id, label } = data;
    return <Tab key={id} label={label} value={id} />;
  };

  const renderSectionItemButton = (type, isDisabled, groupLabel) => {
    const label = STEP_TYPE_LABEL[type];
    const iconName = STEP_TYPE_ICON_NAME[type];
    const itemAssertionProperty = ASSERTION_PROPERTY[type]?.value;
    const itemStepType = itemAssertionProperty ? STEP_TYPE.ASSERT : type;
    const selected = stepType === itemStepType && assertionProperty === itemAssertionProperty;

    return (
      <S.SectionItem
        selected={selected}
        key={label}
        onClick={() =>
          onChange(groupLabel.toLocaleLowerCase(), itemStepType, itemAssertionProperty)
        }
        disabled={isDisabled}
      >
        <S.SectionItemIconWrapper disabled={isDisabled}>
          <Icon name={iconName} />
        </S.SectionItemIconWrapper>
        <S.SectionItemLabel disabled={isDisabled}>
          <StepName type={type} assertType={itemAssertionProperty} />
        </S.SectionItemLabel>
      </S.SectionItem>
    );
  };

  const renderSectionItem = (type, label) => {
    const isDisabled =
      isFreePlan && [STEP_TYPE.EXECUTE, STEP_TYPE.SET_LOCAL_VARIABLE].includes(type);

    const button = renderSectionItemButton(type, isDisabled, label);

    return isDisabled ? (
      <PaidFeature key={type} feature={type} onRedirect={modal.hide}>
        {() => button}
      </PaidFeature>
    ) : (
      button
    );
  };

  const renderSections = (tabId, sections) => () =>
    sections.map(({ label, items }) => (
      <S.Section key={label}>
        <S.SectionTitle>{label}</S.SectionTitle>
        <S.SectionItems>{items.map((item) => renderSectionItem(item, label))}</S.SectionItems>
      </S.Section>
    ));

  const renderTabContent = cond([
    [equals(TAB.ACTIONS), renderSections(TAB.ACTIONS, STEP_TYPES)],
    [equals(TAB.ASSERTIONS), renderSections(TAB.ASSERTIONS, ASSERTION_TYPES)],
    [T, always(null)],
  ]);

  return (
    <S.Container className={className} data-testid="StepTypePicker">
      <Tabs value={selectedTab} onChange={handleTabChange}>
        {TABS.map(renderTab)}
      </Tabs>
      <S.SectionsWrapper>{renderTabContent(selectedTab)}</S.SectionsWrapper>
    </S.Container>
  );
};

export default StepTypePicker;
