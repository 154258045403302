import { useState, useEffect, useRef, useMemo, forwardRef } from 'react';

import type { ButtonProps } from '../../Button';

import { noop } from '../../../../utils/toolbox';

import {
  Container,
  IconWrapper,
  LoaderWrapper,
  Loader,
  SuccessIcon,
  Content,
  Wrapper,
  SuccessBackground,
} from './ActionButton.styled';

export interface ActionButtonProps extends ButtonProps {
  pending?: boolean;
  succeeded?: boolean | null;
}

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ pending = false, succeeded = null, children, ...buttonProps }, ref) => {
    const wasPending = useRef(false);
    const loaderVariant = buttonProps.bordered ? 'dark' : 'light';
    const [succeededDelayed, setSucceededDelayed] = useState(false);

    useEffect(() => {
      if (succeeded && wasPending.current) {
        setSucceededDelayed(true);
        const timeout = setTimeout(() => setSucceededDelayed(false), 2000);
        return () => clearTimeout(timeout);
      }

      if (pending) {
        setSucceededDelayed(false);
        wasPending.current = true;
      }

      return noop;
    }, [succeeded, pending]);

    const hasIcon = !!buttonProps.iconName;

    const actionStateFlags = useMemo(
      () => ({ pending, success: succeededDelayed, iconVisible: hasIcon }),
      [pending, succeededDelayed, hasIcon],
    );

    return (
      <Container
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
        {...actionStateFlags}
        noContentWrapper
        disabled={buttonProps.disabled || pending}
        ref={ref}
      >
        <SuccessBackground bordered={buttonProps.bordered} />
        <Wrapper>
          <LoaderWrapper {...actionStateFlags}>
            <Loader variant={loaderVariant} />
          </LoaderWrapper>
          <IconWrapper {...actionStateFlags}>
            <SuccessIcon />
          </IconWrapper>
          <Content {...actionStateFlags}>{children}</Content>
        </Wrapper>
      </Container>
    );
  },
);

ActionButton.displayName = 'ActionButton';
