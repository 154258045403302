import type { ExtendedRunStatus, StepRunFinalStatusFlags } from '../types/base';
import type { RunStatusFlags, RunErrorFlags } from '../types/tests';

// Return the final status of a finished test/step/suite run based on its flags
export const getExtendedRunStatus = (
  flags: Omit<RunStatusFlags, 'forceFailed'>,
): ExtendedRunStatus => {
  switch (true) {
    case flags.status === 'passed' && !flags.warning:
      return 'passed';

    case flags.status === 'passed' && flags.warning:
      return 'passed-with-issues';

    case flags.status === 'failed' && flags.ignoredFailed:
      return 'failed-ignored';

    default:
      return flags.status;
  }
};

export const getStepRunForceFailedStatusData = (
  errorParams?: RunErrorFlags,
): StepRunFinalStatusFlags => ({
  ...getStepRunStatusData('failed', errorParams),
  forceFailed: true,
});

export const getStepRunStatusData = (
  finalStatus: Exclude<ExtendedRunStatus, 'just-edited'>,
  errorParams?: RunErrorFlags,
): StepRunFinalStatusFlags => {
  const errorFlags: RunErrorFlags = {
    error: null,
    errorCode: null,
    errorId: '',
    ...(errorParams || {}),
  };

  switch (finalStatus) {
    case 'passed':
    case 'passed-with-issues':
      return {
        ...errorFlags,
        status: 'passed',
        warning: finalStatus === 'passed-with-issues',
        ignoredFailed: false,
        forceFailed: false,
      };

    case 'failed':
    case 'failed-ignored':
    case 'failed-retried':
      return {
        ...errorFlags,
        status: 'failed',
        warning: false,
        ignoredFailed: finalStatus === 'failed-ignored',
        forceFailed: false,
      };

    case 'error':
      return {
        ...errorFlags,
        status: 'error',
        warning: false,
        ignoredFailed: false,
        forceFailed: true,
      };

    default:
      return {
        ...errorFlags,
        status: finalStatus,
        warning: false,
        ignoredFailed: false,
        forceFailed: false,
      };
  }
};
