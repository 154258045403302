import { RUN_STATUS } from '@bugbug/core/constants/status';
import { isEndStatus } from '@bugbug/core/types/base';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';
import { selectSingleTest } from '~/modules/test/test.selectors';
import { selectSingleTestRunTestArchive, selectTestRun } from '~/modules/testRun/testRun.selectors';
import { selectUserData } from '~/modules/user/user.selectors';

import { RunMetaData } from './TestRunMetadata.styled';

const TestRunMetadata = ({ testRunId, onErrorClick, variant = 'testRun' }) => {
  const modal = useModal();
  const { t } = useTranslation();

  const data = useSelector(selectTestRun(testRunId));
  const test = useSelector(selectSingleTest);
  const testArchive = useSelector(selectSingleTestRunTestArchive);
  const user = useSelector(selectUserData);
  const isTestRunAuthor = data?.user?.id === user?.id;
  const isRunning = data?.status === RUN_STATUS.RUNNING;

  const handleShowMore = useCallback(
    () =>
      modal.show(MODAL_TYPE.RUN_METADATA, {
        runData: {
          ...data,
          testRunId: {
            label: t('testRunMetadata.testRunId', 'Test Run ID'),
            value: testRunId,
          },
          running: isRunning,
        },
        generalData: {
          ...{ ...test, ...testArchive },
          id: {
            label: t('testRunMetadata.testId', 'Test ID'),
            value: { ...test, ...testArchive }?.id,
          },
        },
      }),
    [data, isRunning, modal, t, test, testArchive, testRunId],
  );

  const screenSize = useMemo(
    () => ({
      type: data?.screenSizeType,
      width: data?.browserWidth,
      height: data?.browserHeight,
    }),
    [data],
  );

  const shouldRenderLatestStatus =
    (variant === 'testDetails' && isTestRunAuthor) || !isEndStatus(data?.firstRunStatus);

  return (
    <RunMetaData
      id={data?.sequence}
      created={data?.created}
      started={data?.started}
      runMode={data?.runMode}
      duration={data?.rawDuration}
      profileName={data?.profileName}
      status={shouldRenderLatestStatus ? data?.status : data?.firstRunStatus ?? data?.status}
      newStepAdded={variant === 'testDetails' ? data?.newStepAdded : false}
      autoRetried={data?.isAutoRetried}
      screenSize={screenSize}
      errorCode={data?.errorCode}
      onErrorClick={onErrorClick}
      onDetailsClick={handleShowMore}
      showMilliseconds
    />
  );
};

TestRunMetadata.defaultProps = {
  testRunId: null,
};

TestRunMetadata.propTypes = {
  testRunId: PropTypes.string,
  onErrorClick: PropTypes.func,
};

export default TestRunMetadata;
