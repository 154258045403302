import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type useTestRunner from '~/hooks/useTestRunner';

import type { Test } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';
import useModal from '~/hooks/useModal';
import { useAppSelector } from '~/modules/store';
import { selectSingleTest } from '~/modules/test/test.selectors';

import * as S from '../RowActiveBorder.styled';

interface IdleStateMenuProps {
  groupId: string;
  groupIndex: number;
  atIndex: number;
  afterStepId: Maybe<string>;
  previousStepId: Maybe<string>;
  running: boolean;
  afterStepIdOrLastStepIdFromPreviousGroup: Maybe<string>;
  disabledStepOptions: boolean;
  disablePlaybackOptions: boolean;
  disabledRunAndStop: boolean;
  disabledGroupOptions: boolean;
  newPlaybackSupported;
  onRecord: ReturnType<typeof useTestRunner>['record'];
  onStartLocalRunner: ReturnType<typeof useTestRunner>['startLocalRunner'];
}

export const IdleStateMenu = ({
  groupId,
  groupIndex,
  atIndex,
  afterStepId,
  previousStepId,
  running: isRunning,
  newPlaybackSupported: isNewPlaybackSupported,
  afterStepIdOrLastStepIdFromPreviousGroup,
  disabledStepOptions,
  disablePlaybackOptions,
  disabledRunAndStop,
  disabledGroupOptions,
  onRecord,
  onStartLocalRunner,
}: IdleStateMenuProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const test = useAppSelector(selectSingleTest) as Test;

  const disableRunAndPauseHere = isNewPlaybackSupported && afterStepId === null && groupIndex === 0;

  const handleRecordFromHere = useCallback(() => {
    if (isNewPlaybackSupported) {
      if (groupIndex === 0 && afterStepId === null) {
        onRecord({
          url: '',
          groupId,
        });
      } else {
        onStartLocalRunner({
          startFromStepId: null,
          stopAfterStepId: previousStepId,
          stopAction: 'record',
          record: {
            startAfterStepId: afterStepId,
            intoGroupId: groupId,
          },
          withRedirect: true,
        });
      }
    } else {
      onStartLocalRunner({
        unconfirmedRelatedGroupId: groupId,
        unconfirmedIndex: atIndex,
        withRedirect: true,
        withRecording: true,
        stopWhenUnconfirmedGroups: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atIndex, groupIndex, groupId, previousStepId, onStartLocalRunner, isNewPlaybackSupported]);

  const handleRunAndPauseHere = useCallback(() => {
    if (isNewPlaybackSupported) {
      onStartLocalRunner({
        startFromStepId: null,
        stopAfterStepId: previousStepId,
        stopAction: 'pause',
        pause: {
          afterStepId,
          atGroupId: groupId,
        },

        // todo: remove deprecated fields after full migration to new recording
        withRedirect: true,
      });
    } else {
      onStartLocalRunner({
        stopAtStepId: afterStepIdOrLastStepIdFromPreviousGroup,
        withRedirect: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isNewPlaybackSupported,
    afterStepId,
    groupId,
    test.groups,
    groupIndex,
    previousStepId,
    afterStepIdOrLastStepIdFromPreviousGroup,
  ]);

  const handleNewTestFromHere = useCallback(
    () =>
      modal.show('edit_test', {
        group: { id: groupId, testId: test.id },
        screenSizeType: test.screenSizeType,
        name: null,
      }),
    [modal, groupId, test.id, test.screenSizeType],
  );

  return (
    <>
      <DropdownItem
        data-testid="RowActiveBorder.RecordFromHere"
        onClick={handleRecordFromHere}
        disabled={disabledStepOptions || isRunning || disablePlaybackOptions}
      >
        <S.Dot />
        {t('rowActiveBorder.option.recordFromHere', 'Record from here')}
      </DropdownItem>
      <DropdownItem
        data-testid="RowActiveBorder.RunAndStopHere"
        onClick={handleRunAndPauseHere}
        disabled={
          disabledStepOptions ||
          disabledRunAndStop ||
          isRunning ||
          disablePlaybackOptions ||
          disableRunAndPauseHere
        }
      >
        <Icon name="play" />
        {isNewPlaybackSupported
          ? t('rowActiveBorder.option.runAndPauseHere', 'Run and pause here')
          : t('rowActiveBorder.option.runAndStopHere', 'Run and stop here')}
      </DropdownItem>
      <DropdownItem
        data-testid="RowActiveBorder.NewTestFromHere"
        onClick={handleNewTestFromHere}
        disabled={disabledGroupOptions}
      >
        <Icon name="clone" />
        {t('rowActiveBorder.option.newTestFromHere', 'New test from here')}
      </DropdownItem>
    </>
  );
};
