import type { ISODate, URLString, UUID } from './aliases';
import type { BrowserLanguage, Timezone } from './geography';
import type { UserCurrentRole } from './organizations';
import type { Maybe } from './utils';
import type { WaitingConditionType } from './waitingConditions';

export type FeatureFlag =
  | 'cloudAcceptInsecureCerts'
  | 'componentList'
  | 'runLogs'
  | 'schedulerEveryFiveMinutes'
  | 'schedulerEveryMinute'
  | 'detectPasswordManagers'
  | IntegrationFeatureFlag;

export type IntegrationFeatureFlag = 'slackIntegration' | 'teamsIntegration';

export type ProjectFeatureFlags = Record<FeatureFlag, boolean>;

export interface Project {
  created: ISODate;
  currentUserRole: UserCurrentRole;
  featureFlags: ProjectFeatureFlags;
  homepageUrl: URLString;
  id: UUID;
  isActive: boolean;
  isDraft: boolean;
  lastActivity: ISODate;
  name: string;
  order: number;
  screenshot: URLString;
  settings: ProjectSettings;
  slug: string;
}

export interface ProjectWithOrganization extends Pick<Project, 'id' | 'name'> {
  organizationName: string;
  hasPaidSubscription: boolean;
}

export interface ProjectSettings {
  runLogs: ProjectSettingsRunLogs;
  apiToken: string;
  browserCustomHeaders: unknown[];
  browserHeight: number;
  browserLanguage: BrowserLanguage;
  browserWidth: number;
  closeWindowsOnSuccessfulTestRun: boolean;
  id: UUID;
  incognitoMode: boolean;
  mobileBrowserHeight: number;
  mobileBrowserWidth: number;
  projectId: UUID;
  runTimeoutLocal: number;
  runTimeoutServer: number;
  selectorMethods: ProjectSelectorMethod[];
  sleep: number;
  timezone: Timezone;
  userAgent: Maybe<string>;
  waitingConditions: ProjectWaitingCondition[];
}

export interface ProjectSelectorMethod {
  extraValue: Maybe<string>;
  isActive: boolean;
  name: SelectorMethodType | AttributeSelectorMethodType | CustomSelectorMethodType;
  order: number;
}

type SelectorMethodType = 'elementFullXPath' | 'elementText';

type AttributeSelectorMethodType =
  | 'elementClassName'
  | 'elementCustomAttributes'
  | 'elementHref'
  | 'elementId'
  | 'elementName'
  | 'elementPlaceholder';

// Extension only
type CustomSelectorMethodType = 'parentElementCustomAttributes';

export const PROJECT_SETTINGS_TAB = [
  'general',
  'browser',
  'screen-sizes',
  'selectors',
  'waiting-conditions',
] as const;

export type ProjectSettingsTab = (typeof PROJECT_SETTINGS_TAB)[number];

export type ProjectSettingsRunLogs = 'disabled' | 'consoleLogs' | 'fullBugBugDebugging';

interface ProjectWaitingCondition {
  expected: Maybe<string>;
  isActive: boolean;
  timeout: Maybe<number>;
  type: WaitingConditionType;
}
