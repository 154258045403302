import { noop } from '@bugbug/core/utils/toolbox';
import { forwardRef } from 'react';

import type { StepsBaseListRef } from '../StepsBaseList/StepsBaseList.types';
import type { Group } from 'mixpanel-browser';

import type { Step } from '@bugbug/core/types/steps';
import type { Test, TestRun } from '@bugbug/core/types/tests';
import { useAppSelector } from '~/modules/store';
import { selectSingleTest, selectTestIsRecording } from '~/modules/test/test.selectors';

import StepsBaseList from '../StepsBaseList';

interface StepsListProps {
  testRunId?: string;
  onSelectionChange?: (
    group: Group,
    selectedSteps: Step['id'][],
    shouldDeselectGroup?: boolean,
  ) => void;
}

export const StepsList = forwardRef<StepsBaseListRef, StepsListProps>(
  ({ testRunId = null, onSelectionChange = noop }, ref) => {
    const isReadOnly = useAppSelector(selectTestIsRecording) as boolean;
    const test = useAppSelector(selectSingleTest) as Test;
    const lastResult = (test?.testRun || {}) as TestRun;

    return (
      <StepsBaseList
        ref={ref}
        // @ts-expect-error Invalid type. It's not a bug. It'll be auto-fixed after migration StepsBaseList to TypeScript
        test={test}
        testRunId={lastResult.id || testRunId}
        onSelectionChange={onSelectionChange}
        readOnly={isReadOnly}
      />
    );
  },
);

StepsList.displayName = 'StepsList';
