import Button from '@bugbug/core/components/Button';
import styled from 'styled-components';

export const RowsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  min-height: 146px;
  margin-bottom: 4px;
  align-items: flex-start;
`;

export const AddRowButton = styled(Button)`
  padding: 6px;
`;
