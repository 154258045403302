const common = {
  VERSION: process.env.REACT_APP_VERSION,
  SENTRY_DSN: 'https://80cb88d5006c4a3b8f8a040fd85085f8@o346063.ingest.sentry.io/5412004',
  STRIPE_KEY: 'pk_test_LQaEkTstOHkLP7evSEi0wKpS009RFjCS7G',
  APP_GTM_ID: 'GTM-TGHM4Q2',
  APP_GA_ID: 'G-TB500R05QR',
  APP_GTM_HASH: 'fc899816-0ce2-4da5-a543-a281a675ada1',
  MIXPANEL_TOKEN: '',
  MIXPANEL_URL: '/mx',
} as const;

const localhost = {
  ...common,
  HOME_PAGE: 'http://localhost:3000/',
  WEBAPP_URL: 'http://localhost:3000',
  API_HOST: 'http://localhost',
  API_WS_URL: 'ws://localhost/ws/webapp/',
  API_REST_URL: 'http://localhost/rest',
  MEDIA_URL: 'http://localhost',
  EXTENSION_URL: 'http://localhost/testrevolution_extension.zip',
  TEAMS_APP_URL: 'https://teams.microsoft.com/l/app/APP-ID?source=app-details-dialog',
  ENV: 'development',
  SENTRY_DSN: '',
  // Enable if required for development or testing
  // eslint-disable-next-line etc/no-commented-out-code
  // MIXPANEL_TOKEN: 'da582e7075db6d16e6e4cf2d9258a441',
} as const;

const testing = {
  ...common,
  HOME_PAGE: 'https://app-test.bugbug.io',
  WEBAPP_URL: 'https://app-test.bugbug.io',
  API_HOST: 'https://app-test.bugbug.io',
  API_WS_URL: 'wss://app-test.bugbug.io/ws/webapp/',
  API_REST_URL: 'https://app-test.bugbug.io/rest',
  // full path from CDN
  MEDIA_URL: '',
  EXTENSION_URL:
    'https://docs.google.com/document/d/1Qac1ZCY7bAcg1P2uuszeGGTXUelr3uerY5OtV76CvFI/edit',
  TEAMS_APP_URL:
    'https://teams.microsoft.com/l/app/APP-ID?source=7e0d79b2-a4e7-48ef-98bc-7018e1e3e2ed',
  ENV: 'testing',
  MIXPANEL_TOKEN: '130d40fcadce8d35545dbe6346c743a7',
} as const;

const staging = {
  ...common,
  HOME_PAGE: 'https://stage.bugbug.io',
  WEBAPP_URL: 'https://stage.bugbug.io',
  API_HOST: 'https://stage.bugbug.io',
  API_WS_URL: 'wss://stage.bugbug.io/ws/webapp/',
  API_REST_URL: 'https://stage.bugbug.io/rest',
  // full path from CDN
  MEDIA_URL: '',
  EXTENSION_URL: 'https://stage.bugbug.io/media/extension.zip',
  TEAMS_APP_URL:
    'https://teams.microsoft.com/l/app/APP-ID?source=7e0d79b2-a4e7-48ef-98bc-7018e1e3e2ed',
  ENV: 'staging',
  MIXPANEL_TOKEN: '130d40fcadce8d35545dbe6346c743a7',
} as const;

const qa = {
  ...common,
  HOME_PAGE: 'https://qa.bugbug.io',
  WEBAPP_URL: 'https://qa.bugbug.io',
  API_HOST: 'https://qa.bugbug.io',
  API_WS_URL: 'wss://qa.bugbug.io/ws/webapp/',
  API_REST_URL: 'https://qa.bugbug.io/rest',
  // full path from CDN
  MEDIA_URL: '',
  TEAMS_APP_URL:
    'https://teams.microsoft.com/l/app/APP-ID?source=7e0d79b2-a4e7-48ef-98bc-7018e1e3e2ed',
  EXTENSION_URL: 'https://qa.bugbug.io/media/extension.zip',
  ENV: 'staging',
} as const;

const production = {
  ...common,
  HOME_PAGE: 'https://bugbug.io',
  WEBAPP_URL: 'https://app.bugbug.io',
  API_HOST: 'https://app.bugbug.io',
  API_WS_URL: 'wss://app.bugbug.io/ws/webapp/',
  API_REST_URL: 'https://app.bugbug.io/rest',
  // full path from CDN
  MEDIA_URL: '',
  TEAMS_APP_URL:
    'https://teams.microsoft.com/l/app/APP-ID?source=d745dc4d-af9a-4094-906c-1b7245fc17de',
  EXTENSION_URL:
    'https://chrome.google.com/webstore/detail/bugbug/oiedehaafceacbnnmindilfblafincjb',
  ENV: 'production',
  STRIPE_KEY: 'pk_live_fov5HKsyHg3g6OmDlM41vZvn00KtSbdQ3T',
  MIXPANEL_TOKEN: '5c1cbacd96d36f8d55516328c14190a1',
  APP_GTM_ID: process.env.REACT_APP_GTM_ID,
  APP_GA_ID: process.env.REACT_APP_GA_ID,
  APP_GTM_HASH: process.env.REACT_APP_GTM_HASH,
  APP_HOSTNAME: process.env.REACT_APP_HOSTNAME,
} as const;

export default {
  localhost,
  testing,
  staging,
  production,
  qa,
};
