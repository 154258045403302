import { isMatch } from '@bugbug/core/utils/toolbox';

export const waitForPostMessage = (expectedEvent: object) =>
  new Promise<void>((resolve) => {
    const onMessage = (incomingEvent: MessageEvent) => {
      if (isMatch(expectedEvent, incomingEvent.data)) {
        window.removeEventListener('message', onMessage);
        resolve();
      }
    };
    window.addEventListener('message', onMessage);
  });
