import styled from 'styled-components';

import { Content as ContentBase } from '~/components/modals/Modal';

export const Container = styled.div`
  width: 515px;
`;

export const Content = styled(ContentBase)`
  display: flex;
  padding-top: 10px;

  p {
    margin: 0;
  }
`;

export const ModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
