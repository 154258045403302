import { ActionButton as ActionButtonBase, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import mixins from '@bugbug/core/theme/mixins';
import { H1 } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 27px;
`;

export const Title = styled(H1).attrs(() => ({ as: 'h2' }))`
  ${mixins.textEllipsis};
  flex: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButton = styled(ActionButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  margin-left: 8px;
  justify-content: center;
  width: 74px;

  & > div {
    flex: none;
  }
`;
