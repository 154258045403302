import Loader from '@bugbug/core/components/Loader';
import { H1 } from '@bugbug/core/theme/typography';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import memoize from 'lodash.memoize';
import { prop } from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router';
import { useMount, useUpdateEffect } from 'react-use';

import type { SortByDropdownOrderBy } from '~/components/SortByDropdown/SortByDropdown.types';
import type { SimpleTableRef } from '~/components/Table';

import type { Maybe } from '@bugbug/core/types/utils';
import Badge, { BADGE_VARIANT } from '~/components/Badge';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import { SortByDropdown } from '~/components/SortByDropdown/SortByDropdown';
import { SimpleTable } from '~/components/Table';
import useActionState from '~/hooks/useActionState';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import { useRecentSort } from '~/hooks/useRecentSort';
import { ONBOARDING_STEP } from '~/modules/constans';
import { useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectHasUsedVariousScreenSizes, selectTestsList } from '~/modules/test/test.selectors';
import { selectCurrentOnboardingStep } from '~/modules/user/user.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import urls, { reverse } from '~/views/urls';

import SelectedTestsActions from './components/SelectedTestsActions';
import TestsActions from './components/TestsActions';
import { TestsListEmptyState } from './components/TestsListEmptyState/TestsListEmptyState';
import { TestsModalRoute } from './components/TestsModalRoute';
import {
  TEST_LIST_COLUMNS,
  TEST_LIST_COLUMNS_WITHOUT_SCREEN,
  TEST_LIST_SORT_BY_OPTIONS,
  TEST_LIST_SORT_BY_OPTIONS_WITHOUT_SCREEN,
} from './TestsList.constants';
import * as S from './TestsList.styled';

const defaultSort = {
  sortBy: 'created',
  desc: true,
};

const TestsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef<Maybe<SimpleTableRef>>(null);
  const containerRef = useRef<Maybe<HTMLDivElement>>(null);
  const testsActionsRef = useRef();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const queryString = useQueryString();
  const location = useLocation();

  const testListState = useActionState(TestActions.getListRequest, { reset: true });
  const tests = useAppSelector(selectTestsList);

  const hasUsedVariousScreenSizes = useAppSelector(selectHasUsedVariousScreenSizes);
  const onboardingStep = useAppSelector(selectCurrentOnboardingStep);
  const appRoutes = useAppRoutes('testsList');
  const modal = useModal();
  const { projectId, projectSlug, organizationId } = appRoutes.params;

  const tableColumns = hasUsedVariousScreenSizes
    ? TEST_LIST_COLUMNS
    : TEST_LIST_COLUMNS_WITHOUT_SCREEN;

  const { updateRecentSort, ...recentSort } = useRecentSort({
    cacheName: 'testsList',
    defaultConfig: defaultSort,
    columns: hasUsedVariousScreenSizes
      ? TEST_LIST_SORT_BY_OPTIONS
      : TEST_LIST_SORT_BY_OPTIONS_WITHOUT_SCREEN,
  });

  const handleDataUpdate = useCallback(() => {
    dispatch(TestActions.getListRequest(queryString.query, recentSort.sortBy, recentSort.desc));
    updateRecentSort({ ...recentSort, query: queryString.query });
  }, [dispatch, updateRecentSort, queryString.query, recentSort]);

  const handleSortChange = useCallback(
    (sortBy: string, orderBy: SortByDropdownOrderBy) => {
      dispatch(TestActions.getListRequest(queryString.query, sortBy, orderBy === 'desc'));
      updateRecentSort({ sortBy, desc: orderBy === 'desc', query: queryString.query });
    },
    [updateRecentSort, dispatch, queryString.query],
  );

  useUpdateEffect(() => {
    handleDataUpdate();
  }, [queryString.query]);

  const fetchInitialData = useCallback(() => {
    handleDataUpdate();
  }, [handleDataUpdate]);

  const handleDeselectAll = useCallback(() => {
    tableRef.current?.toggleAllRowsSelected(false);
  }, []);

  useActionState(TestActions.cloneRequest, { onSuccess: fetchInitialData });
  useActionState(TestActions.removeRequest, { onSuccess: fetchInitialData });

  useMount(() => {
    fetchInitialData();
  });

  useEffect(() => {
    if (
      testListState.isSuccess &&
      !tests.length &&
      !queryString.query &&
      onboardingStep === ONBOARDING_STEP.WIZARD_COMPLETED
    ) {
      modal.show('onboarding', undefined, {
        closeButtonHidden: true,
        stacked: true,
      });
    }
    // appRoutes.replace is not a stable reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tests, testListState.isSuccess, onboardingStep]);

  const renderLoader = renderWhenTrue(() => (
    <S.LoaderContainer>
      <Loader size="large" />
    </S.LoaderContainer>
  ));

  const EmptyState = useMemo(() => {
    if (testListState.hasInternalServerError || testListState.hasBadRequestError) {
      return (
        <ServerErrorInfo
          isVisible={testListState.hasInternalServerError || testListState.hasBadRequestError}
          onRetry={handleDataUpdate}
          hideRetryButton={testListState.hasBadRequestError}
        />
      );
    }

    if (testListState.isLoading) {
      return <S.TableEmptyState>{renderLoader(true)}</S.TableEmptyState>;
    }

    return <TestsListEmptyState />;
  }, [
    testListState.hasInternalServerError,
    testListState.hasBadRequestError,
    testListState.isLoading,
    handleDataUpdate,
    renderLoader,
  ]);

  const renderEmptyState = renderWhenTrue(() => () => EmptyState);

  const Title = useMemo(
    () => (
      <S.TitleContainer data-testid="TestsList.Header">
        <Badge value={tests.length} hidden={!tests.length} variant={BADGE_VARIANT.COUNTER}>
          <H1>{t('testsList.title', 'Tests')}</H1>
        </Badge>
        <S.SortAndFilters>
          <SortByDropdown
            onChange={handleSortChange}
            configuration={recentSort}
            defaultConfiguration={defaultSort}
            items={
              hasUsedVariousScreenSizes
                ? TEST_LIST_SORT_BY_OPTIONS
                : TEST_LIST_SORT_BY_OPTIONS_WITHOUT_SCREEN
            }
          />
        </S.SortAndFilters>
        <S.Actions>
          {selectedRowIds.length ? (
            <SelectedTestsActions testsIds={selectedRowIds} onDeselect={handleDeselectAll} />
          ) : (
            <TestsActions
              ref={testsActionsRef}
              // @ts-expect-error TestActions has missing typings
              onDelete={handleDeselectAll}
            />
          )}
        </S.Actions>
      </S.TitleContainer>
    ),
    [
      t,
      recentSort,
      selectedRowIds,
      handleDeselectAll,
      handleSortChange,
      tests.length,
      hasUsedVariousScreenSizes,
    ],
  );

  const handleTrackOpen = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.OPEN_TEST);
  }, []);

  const getExtraRowProp = memoize((row) => {
    const pathname = reverse(urls.test, {
      projectId,
      projectSlug,
      organizationId,
      testId: row.id,
    });

    return {
      to: {
        pathname,
        state: { backUrl: `${location.pathname}${location.search}` },
      },
      onClick: handleTrackOpen,
    };
  }, prop('id'));

  return (
    <S.Container ref={containerRef}>
      <Helmet title={t('testsList.pageTitle', 'Tests')} />
      <S.ListContainer title={Title} data-testid="TestsList" wideContent>
        <SimpleTable
          ref={tableRef}
          columns={tableColumns}
          data={tests}
          onSelectionChange={setSelectedRowIds}
          getRowProps={getExtraRowProp}
          emptyStateText={t(
            'testsList.noSearchResults.label',
            'No tests were found that match your search criteria.',
          )}
          renderEmptyState={renderEmptyState(
            (testListState.isLoading ||
              testListState.hasInternalServerError ||
              tests.length === 0) &&
              !queryString.query,
          )}
        />
      </S.ListContainer>
      <Switch>
        <Route path={urls.testsListEdit} component={TestsModalRoute} />
      </Switch>
    </S.Container>
  );
};

export default TestsList;
