import Loader from '@bugbug/core/components/Loader';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMount, useUnmount } from 'react-use';

import type { StepTypePickerType } from '~/components/StepTypePicker/StepTypePicker.constants';

import type { AssertionProperty, AssertionType, Step } from '@bugbug/core/types/steps';
import { Header } from '~/components/modals/Modal';
import StepTypePicker from '~/components/StepTypePicker';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';
import analytics from '~/services/analytics';

import * as S from './CreateStepModal.styled';

interface CreateStepModalProps {
  className?: string;
  initialTab: StepTypePickerType;
  atIndex?: number;
  groupId?: string;
  testId: string;
}

const CreateStepModal = (
  { className, initialTab, testId, groupId, atIndex }: CreateStepModalProps = {
    className: '',
    groupId: '',
    testId: '',
    atIndex: 0,
    initialTab: 'actions',
  },
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();

  const handleSuccess = useCallback(() => modal.hide(), [modal]);

  const { isLoading, reset } = useActionState(TestActions.createTemporaryStepRequest, {
    reset: false,
    onSuccess: handleSuccess,
  });

  useMount(() => {
    analytics.trackEvent('new_step_creation_started');
  });

  useUnmount(() => {
    reset();
  });

  const handleChange = useCallback(
    (groupType: AssertionType, stepType: Step['type'], assertionProperty: AssertionProperty) => {
      const isAssert = stepType === 'assert';

      analytics.trackEvent(isAssert ? 'new_assertion_added' : 'new_step_manually_added', {
        test_id: testId,
        ...(isAssert
          ? { assertion_type: groupType, assertion_name: assertionProperty }
          : {
              action_type: groupType,
              action_name: stepType,
            }),
      });

      dispatch(
        TestActions.createTemporaryStepRequest(
          testId,
          groupId,
          stepType,
          atIndex,
          isAssert
            ? {
                assertionProperty,
              }
            : undefined,
        ),
      );
    },
    [atIndex, dispatch, groupId, testId],
  );

  return (
    <S.Container className={className} data-testid="CreateStepModal">
      <Header>{t('createStepModal.title', 'Add a new test step')}</Header>
      <S.Content>
        {isLoading && (
          <S.LoaderWrapper>
            <Loader />
          </S.LoaderWrapper>
        )}
        <StepTypePicker initialTab={initialTab} onChange={handleChange} />
      </S.Content>
    </S.Container>
  );
};

export default CreateStepModal;
