import { createApi } from '@reduxjs/toolkit/query/react';

import type { SendEventBody } from '@bugbug/core/types/events';
import { axiosBaseQuery } from '~/services/api/baseQuery';

const TAG = 'Events';

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/events/' }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    sendEvent: builder.mutation<void, SendEventBody>({
      query: (data) => ({
        url: '',
        method: 'POST',
        data: {
          ...data,
          eventData: {
            ...data.eventData,
            context: 'webapp',
          },
        },
      }),
    }),
  }),
});

export const { useSendEventMutation } = eventsApi;
