import { ActionButton } from '@bugbug/core/components/Button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';

import type { Profile } from '@bugbug/core/types/profiles';
import type { Test, TestRun } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';
import ProfilePicker from '~/components/ProfilePicker';
import { TutorialTooltip } from '~/components/TutorialTooltip/TutorialTooltip';
import useModal from '~/hooks/useModal';
import useTestRunner from '~/hooks/useTestRunner';
import { selectProfile } from '~/modules/profile/profile.selectors';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectTestProfileId } from '~/modules/test/test.selectors';
import { selectHasNotStartedTest, selectIsInvited } from '~/modules/user/user.selectors';

import { useTestRunnerActionsState } from './TestHeaderActions.hooks';
import * as S from './TestHeaderActions.styled';

interface IdleButtonsProps {
  testId: Test['id'];
  testRunId?: TestRun['id'];
}

const IdleButtons = ({ testId, testRunId }: IdleButtonsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'testDetails.testActions' });
  const dispatch = useAppDispatch();
  const profileId = useAppSelector(selectTestProfileId);
  const currentProfile = useAppSelector(selectProfile(profileId)) as unknown as Profile;
  const hasNotStartedTest = useAppSelector(selectHasNotStartedTest);
  const testRunner = useTestRunner({ id: testId }, { testRunId });
  const [showRunTooltip, setShowRunTooltip] = useState(hasNotStartedTest);
  const isInvited = useAppSelector(selectIsInvited);
  const runnerActionsState = useTestRunnerActionsState();
  const modal = useModal();

  useUpdateEffect(() => {
    if (!hasNotStartedTest) {
      setShowRunTooltip(false);
    }
  }, [hasNotStartedTest]);

  const handleProfileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(TestActions.updateRequest(testId, { runProfileId: event.target.value }));
  };

  const handleRun: React.MouseEventHandler<HTMLButtonElement> = () => {
    testRunner.start({ withDebug: true, withRedirect: true, stopWhenUnconfirmedGroups: true });
  };

  const handleRunLocal: React.MouseEventHandler<HTMLButtonElement> = () => {
    testRunner.startLocalRunner({
      withDebug: true,
      withRedirect: true,
      stopWhenUnconfirmedGroups: true,
      startFromStepId: null,
      stopAfterStepId: null,
      stopAction: 'finish',
    });
  };

  const [highlightedElement, setHighlightedElement] = useState<Maybe<HTMLElement>>(null);

  return (
    <>
      <ProfilePicker
        value={currentProfile}
        onChange={handleProfileChange}
        container="testHeader"
        disabled={runnerActionsState.isLoading}
      />
      <ActionButton
        {...runnerActionsState.state.runLocal}
        onClick={handleRunLocal}
        iconName="play"
        ref={setHighlightedElement}
        variant="primary"
      >
        {t('runButton', 'Run')}
      </ActionButton>
      {showRunTooltip && !modal.isOpen && !isInvited && (
        <TutorialTooltip highlightedElement={highlightedElement}>
          <S.TooltipContainer>
            <S.TooltipTitle>{t('tooltip.title', 'Run your test')}</S.TooltipTitle>
            <S.TooltipSubtitle>
              {t('tooltip.subtitle', 'Click here and see how BugBug repeats your recored steps.')}
            </S.TooltipSubtitle>
          </S.TooltipContainer>
        </TutorialTooltip>
      )}
      <ActionButton
        {...runnerActionsState.state.runCloud}
        onClick={handleRun}
        iconName="cloudRun"
        variant="primary"
        bordered
      >
        {t('runInCloudButton', 'Run in cloud')}
      </ActionButton>
    </>
  );
};

export default IdleButtons;
