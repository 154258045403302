import Icon from '@bugbug/core/components/Icon';
import styled, { css } from 'styled-components';

export const Expander = styled(Icon).attrs(() => ({
  name: 'chevronRight',
}))`
  color: ${({ theme }) => theme.colors.neutrals['80']};
  transition: all 250ms ease-in-out !important;
`;

export const ExpanderContainer = styled.span<{ active?: boolean }>`
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      ${Expander} {
        transform: rotate(90deg);
      }
    `}
`;
