/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/display-name */
import Link from '@bugbug/core/components/Link';
import UnorganizedList from '@bugbug/core/components/UnorganizedList';
import VariableName from '@bugbug/core/components/VariableName';
import { STEP_RUN_ERROR_CODE } from '@bugbug/core/constants/errors';
import { noop } from '@bugbug/core/utils/toolbox';
import { Trans } from 'react-i18next';

import OrganizedList from '~/components/OrganizedList';
import i18n from '~/translations';
import { getWholeNumberOrDecimal } from '~/utils/misc';

import ErrorTips from './ErrorTips';

const email = i18n.t('default.email');
const emailUrl = `mailto:${i18n.t('default.email')}`;

const renderCriticalError = () => (
  <Trans i18nKey="errorDetails.errorMessage.criticalError" values={{ email }}>
    Critical error.
    <br />
    If this problem occurs again, please get in touch with us at{' '}
    <Link to={emailUrl}>
      <span>{{ email }}</span>
    </Link>
  </Trans>
);

const renderTimeoutError = (step) => {
  const { runTimeout } = step;
  return (
    <Trans i18nKey="errorDetails.errorMessage.timeout">
      Couldn't complete this step within the {{ runTimeout }} seconds time limit.
      <br />
      Usually it's a sign that something went wrong. In very rare cases you should increase the step
      running timeout in step settings below.
    </Trans>
  );
};

// We have to use that helper because of issue in Trans component related to unescaping values
// eslint-disable-next-line react/prop-types
const UnescapedValue = ({ children, value }) => (
  /* eslint-disable-next-line react/no-danger */
  <span dangerouslySetInnerHTML={{ __html: value || children }} />
);

export const getDescription = (stepRun, step) => {
  const errorCodeToMessage = {
    [STEP_RUN_ERROR_CODE.INTERACTION_POSITION_OUT_OF_VIEWPORT]: () => {
      const { windowInnerWidth, windowInnerHeight } = stepRun;
      const interactionPositionX = getWholeNumberOrDecimal(stepRun.interactionPositionX);
      const interactionPositionY = getWholeNumberOrDecimal(stepRun.interactionPositionY);
      /* eslint-disable prettier/prettier */
      return (
        <Trans i18nKey="errorDetails.errorMessage.interactionPositionOutOfViewport">
          Incorrect interaction position.
          <br />
          Could not reach coordinates (x: {{ interactionPositionX }}, y: {{ interactionPositionY }})
          in the current viewport size (width: {{ windowInnerWidth }}, height:{' '}
          {{ windowInnerHeight }}).
          <ErrorTips>
            <UnorganizedList>
              <li>
                Check the selector, perhaps it incorrectly points to a large container instead of
                its child element
              </li>
              <li>
                Check the scroll coordinates in previous steps and make sure that the element is
                fully scrolled to view
              </li>
              <li>Check the interaction position settings</li>
            </UnorganizedList>
          </ErrorTips>
        </Trans>
      );
      /* eslint-enable prettier/prettier */
    },
    [STEP_RUN_ERROR_CODE.SELECTOR_REQUIRED]: () =>
      i18n.t(
        'errorDetails.errorMessage.selectorRequired',
        'This step has an empty selector. You need to enter a selector or re-record this step again.',
      ),
    [STEP_RUN_ERROR_CODE.UNCHANGABLE_ELEMENT]: () => (
      <Trans i18nKey="errorDetails.errorMessage.unchangableElement">
        Wrong type of element.
        <br />
        Expected a form input, textarea, select, checkbox or radio. Only these types of elements
        have a value that can be changed. Check your selector.
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.UNRECOGNIZED_STEP_TYPE]: () => (
      <Trans i18nKey="errorDetails.errorMessage.unrecognizedStepType">
        Something went wrong.
        <br />
        Unsupported step type. Please get in touch with us at{' '}
        <Link to={emailUrl}>
          <span>{{ email }}</span>
        </Link>
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.NEXT_STEP_ELEMENT_REQUIRED]: () => (
      <Trans i18nKey="errorDetails.errorMessage.nextStepElementRequired">
        Element from the next step was not found.
        <br />
        This step is set to "Scroll until element from next step is visible" so the next step needs
        to have a correct selector.
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.NEXT_ACTIVE_STEP_WITH_ELEMENT_REQUIRED]: () => (
      <Trans i18nKey="errorDetails.errorMessage.nextActiveStepWithElementRequired">
        This step is set to "Scroll until element from next step is visible" so the next step needs
        to have a related element.
        <ErrorTips>
          What to do?
          <OrganizedList>
            <li>Check what is the type of the next step</li>
            <li>Make sure that it is set to a type that has an element.</li>
          </OrganizedList>
        </ErrorTips>
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.WINDOW_OR_TAB_DOES_NOT_EXIST]: () => {
      const { tabNo } = step;

      return (
        <Trans i18nKey="errorDetails.errorMessage.windowOrTabDoesNotExist">
          Tab number {{ tabNo }} does not exist.
          <ErrorTips>
            What to do?
            <OrganizedList>
              <li>Take a look at the previous steps and find a "Switch context" step.</li>
              <li>
                Record this step again by using "record from here" or manually update the tab
                number.
              </li>
            </OrganizedList>
            <br />
            Please note that the default tab number is 0.
          </ErrorTips>
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.TAB_CLOSED]: () =>
      i18n.t(
        'errorDetails.errorMessage.tabClosed',
        'Test interrupted. You closed the tab while the test was running.',
      ),
    [STEP_RUN_ERROR_CODE.MISSING_GOTO_STEP]: () =>
      i18n.t(
        'errorDetails.errorMessage.missingGotoStep',
        'You need to add a "goto" step at the very beginning of the test. Every test should begin with a navigation to a URL.',
      ),
    [STEP_RUN_ERROR_CODE.EXTENSION_DISCONNECTED]: () =>
      i18n.t(
        'errorDetails.errorMessage.extensionDisconnected',
        'Your BugBug extension has been disconnected. Check your internet connection or try again.',
      ),
    [STEP_RUN_ERROR_CODE.INITIALIZATION_ERROR]: () =>
      i18n.t(
        'errorDetails.errorMessage.initializationError',
        'Unexpected error occurred while initializing test run.',
      ),
    [STEP_RUN_ERROR_CODE.STEP_RUN_INITIALIZATION_ERROR]: () =>
      i18n.t(
        'errorDetails.errorMessage.stepInitializationError',
        'Unexpected error occurred while initializing step run.',
      ),
    [STEP_RUN_ERROR_CODE.FRAME_LOADS_TOO_LONG]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.frameLoadTooLong">
          BugBug waited for a requested frame <UnescapedValue>{{ error }}</UnescapedValue> until it
          is ready, but loading took too much time.
          <ErrorTips>
            What to do?
            <OrganizedList>
              <li>Take a look at the previous steps and find a "Switch context" step.</li>
              <li>
                Make sure it uses a correct selector to a correct iframe.
                <br />
                Tip: you can also re-record some steps again: remove the "switch context" step and
                the following iframe steps, then use "record from here".
              </li>
            </OrganizedList>
          </ErrorTips>
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.FRAME_DOES_NOT_EXIST]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.frameDoesNotExist">
          This step was supposed to be executed in an iframe{' '}
          <UnescapedValue>{{ error }}</UnescapedValue> but the iframe was not found.
          <ErrorTips>
            What to do?
            <OrganizedList>
              <li>Take a look at the previous steps and find a "Switch context" step.</li>
              <li>
                Make sure it uses a correct selector to a correct iframe.
                <br />
                Tip: you can also re-record some steps again: remove the "switch context" step and
                the following iframe steps, then use "record from here".
              </li>
            </OrganizedList>
          </ErrorTips>
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.ELEMENT_DOES_NOT_EXIST]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.elementWithSelectorDoesNotExist">
          Element not found for the selector "<UnescapedValue>{{ error }}</UnescapedValue>".
          <ErrorTips>
            What to do?
            <UnorganizedList>
              <li>
                Ensure selected selector is stable eg. it does not contain any dynamic content.
              </li>
              <li>Try to change the selector.</li>
              <li>Re-record this step.</li>
            </UnorganizedList>
          </ErrorTips>
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.PROMPT_DOES_NOT_EXIST]: () => (
      <Trans i18nKey="errorDetails.errorMessage.promptDoesNotExist">
        Prompt not found.
        <ErrorTips>
          What to do?
          <UnorganizedList>
            <li>Ensure the prompt has been opened in a previous step.</li>
            <li>Don't close the prompt manually when a test is running.</li>
          </UnorganizedList>
        </ErrorTips>
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.ELEMENT_REMOVED]: () => (
      <Trans i18nKey="errorDetails.errorMessage.elementRemove">
        Element was found but unexpectedly removed during the step execution.
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.UNHANDLED_PROMPT]: () => (
      <Trans i18nKey="errorDetails.errorMessage.unhandledPrompt">
        Execution of this step was blocked by unhandled prompt.
        <ErrorTips>
          What to do?
          <UnorganizedList>
            <li>Ensure the prompt has been closed in a previous step.</li>
            <li>Record or add manually "Answer prompt" step to close the prompt</li>
          </UnorganizedList>
        </ErrorTips>
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.TIMEOUT]: () => renderTimeoutError(step),
    [STEP_RUN_ERROR_CODE.EXTENSION_DOES_NOT_RESPONSE]: () => renderTimeoutError(step),
    [STEP_RUN_ERROR_CODE.WINDOW_MINIMIZED]: () => (
      <Trans i18nKey="errorDetails.errorMessage.windowMinimized">
        The window with the running test has been minimized
        <ErrorTips>
          What to do?
          <p>
            Don't minimize the window when a test is running because the browser cannot execute
            steps in this state.
          </p>
        </ErrorTips>
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.UNEXPECTED_WINDOW_STATE_CHANGE]: () => (
      <Trans i18nKey="errorDetails.errorMessage.unexpectedWindowStateChange">
        The window with the running test has been minimized or lost focus by other user actions.
        <ErrorTips>
          What to do?
          <UnorganizedList>
            <li>Avoid minimizing the window while a test is running.</li>
            <li>
              If you are using a MacOS, avoid switching from space with pending test run to another.
            </li>
          </UnorganizedList>
        </ErrorTips>
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.EVENT_DISPATCHED_ON_INVALID_ELEMENT]: () => (
      <Trans i18nKey="errorDetails.errorMessage.eventDispatchedOnInvalidElement">
        BugBug found the element, but could not click it. Some other element interrupted the click.
        Check the screenshots and analyze where the cursor is located.
        <br />
        <br />
        Investigate why the target element could not be clicked. Add additional test steps using
        "record from here" to make sure the clickable element is accessible.
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.EVENT_DISPATCH_FAILED]: () => (
      <Trans i18nKey="errorDetails.errorMessage.eventDispatchFailed">
        BugBug found the element, dispatch the requested event, but could not resolve the event
        correctly.
        <br />
        <br />
        This usually occurs when an incorrect event type is used, such as using "click" instead of
        "double-click".
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.TYPED_TEXT_DIFFERENT_THAN_EXPECTED]: () => (
      <Trans i18nKey="errorDetails.errorMessage.typedTextDifferentThanExpected" values={{ email }}>
        The typing step was completed, but the end result was not as expected. Rerun the test and
        remember not to interact with the page during execution.
        <br />
        If this problem occurs again, please get in touch with us at{' '}
        <Link to={emailUrl}>
          <span>{{ email }}</span>
        </Link>
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.FAILED_WAITING_CONDITIONS]: () => {
      const { runTimeout } = step;
      return (
        <Trans i18nKey="errorDetails.errorMessage.failedWaitingConditions">
          Couldn't complete this step within the {{ runTimeout }} seconds time limit.
          <br />
          Usually it's a sign that something went wrong. In very rare cases you should increase the
          step running timeout in step settings below.
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.RUNTIME_ERROR]: () => renderCriticalError(),
    [STEP_RUN_ERROR_CODE.INTERNAL_ERROR]: () => renderCriticalError(),
    [STEP_RUN_ERROR_CODE.INVALID_MOUSE_INPUT_PARAMS]: () => renderCriticalError(),
    [STEP_RUN_ERROR_CODE.FILE_DOES_NOT_EXIST]: () => renderCriticalError(),
    [STEP_RUN_ERROR_CODE.INVALID_ELEMENT_SELECTOR]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.invalidElementSelector">
          Provided element selector "<UnescapedValue>{{ error }}</UnescapedValue>" is invalid.
          <br />
          You need to fix a selector or re-record this step.
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.VALUE_COMPUTING_ERROR]: () => {
      const value = '{{variable}}';
      /* eslint-disable prettier/prettier */
      return (
        <Trans i18nKey="errorDetails.errorMessage.valueComputingError" values={{ value }}>
          There was an error while parsing variables.
          <br />
          If you are going to use variables, make sure you use double braces like this:{' '}
          <UnescapedValue value={value} />
        </Trans>
      );
      /* eslint-enable prettier/prettier */
    },
    [STEP_RUN_ERROR_CODE.VARIABLE_NESTING_LIMIT_EXCEEDED]: () => (
      <Trans i18nKey="errorDetails.errorMessage.variableNestingLimitExceeded">
        There was an error parsing variables.
        <br />
        The variable you used contains too many nesting levels.
        <br />
        The maximum nesting level is 3.
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.EXTENSION_DISCONNECTED_ERROR]: () => (
      <Trans i18nKey="errorDetails.errorMessage.extensionDisconnectedError">
        Your BugBug extension has been disconnected.
        <br />
        Check your internet connection or try again.
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.CODE_EXECUTION_ERROR]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.codeExecutionError">
          Your JavaScript code has an error:
          <br />"<UnescapedValue value={error} />"
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.INVALID_EXECUTION_RESULT]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.invalidExecutionResult">
          Your JavaScript function should return a boolean value.
          <br />
          Returned: "<UnescapedValue value={error} />"
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.INVALID_URL]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.invalidUrl">
          Provided URL is not valid: "<UnescapedValue>{{ error }}</UnescapedValue>"
          <ErrorTips>
            What to do?
            <UnorganizedList>
              <li>Check if url starts with: "http://" or "https://"</li>
            </UnorganizedList>
          </ErrorTips>
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.INVALID_DATA_FORMAT]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.invalidDataFormat">
          Provided <UnescapedValue>{{ error }}</UnescapedValue> has not valid format.
          <ErrorTips>
            What to do?
            <UnorganizedList>
              <li>
                You need to update a value in the <UnescapedValue>{{ error }}</UnescapedValue> field
                or rerecord this step.
              </li>
            </UnorganizedList>
          </ErrorTips>
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.INVALID_OPTION_INDEX]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.invalidOptionIndex">
          Provided option index ("<UnescapedValue>{{ error }}</UnescapedValue>") is not a number.
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.MISSING_OPTION_VALUE]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.missingOptionValue">
          Option with provided value ("<UnescapedValue>{{ error }}</UnescapedValue>") does not exist
          on available options list.
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.MISSING_OPTION_TEXT]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.missingOptionText">
          Option with provided text ("<UnescapedValue>{{ error }}</UnescapedValue>") does not exist
          on available options list.
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.MISSING_OPTION_INDEX]: () => {
      const { error } = stepRun;
      return (
        <Trans i18nKey="errorDetails.errorMessage.missingOptionIndex">
          Option with provided index ("<UnescapedValue>{{ error }}</UnescapedValue>") does not exist
          on available options list.
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.INVALID_FIELD_VALUE]: () => {
      const { error: currentValue, computedValue } = stepRun;
      const { value } = step;
      const expectedValue = computedValue || value;

      return (
        <Trans i18nKey="errorDetails.errorMessage.invalidFieldValue">
          Could not verify that the value in the form was changed properly.
          <br />
          <br />
          We changed the value in the form to "{{ expectedValue }}", but afterwards the value showed
          "{{ currentValue }}".
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.DEBUGGER_DETACHED]: () => (
      <Trans i18nKey="errorDetails.errorMessage.debuggerDetached">
        You've probably clicked "Cancel" in the Chrome toolbar saying "BugBug is debugging your
        browser". Try to run the test again.
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.UNSUPPORTED_BROWSER]: () => (
      <Trans i18nKey="errorDetails.errorMessage.unsupportedBrowser">
        You are using an outdated and unsupported browser. <br /> We suggest updating it to the most
        recent version.
      </Trans>
    ),
    [STEP_RUN_ERROR_CODE.VARIABLE_DOES_NOT_EXIST]: () => {
      const { error: variable } = stepRun;

      return (
        <Trans i18nKey="errorDetails.errorMessage.variableDoesNotExist">
          There was an error while parsing variables.
          <br />
          <br />
          Variable <VariableName>{{ variable }}</VariableName> does not exist. Make sure that you
          use a proper variable name.
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.SELECT_OPTION_FAILED]: () => {
      const { error: selectedOptions } = stepRun;

      return (
        <Trans i18nKey="errorDetails.errorMessage.selectOptionFailed">
          Could not verify that the select value was changed correctly.
          <br />
          <br />
          We selected the required options in the select field, but during verification, the
          selected value appears to differ from what was expected: "
          <UnescapedValue>{{ selectedOptions }}</UnescapedValue>".
        </Trans>
      );
    },
    [STEP_RUN_ERROR_CODE.MULTIPLE_OPTIONS_WITH_VALUE]: () => {
      const { error: values } = stepRun;

      return (
        <Trans i18nKey="errorDetails.errorMessage.multipleOptionsWithValue">
          Could not verify which option should be selected.
          <br />
          <br />
          We detected multiple select options, which reference to the same value: "
          <UnescapedValue>{{ values }}</UnescapedValue>".
        </Trans>
      );
    },
  };

  const errorContentRenderer = errorCodeToMessage[stepRun.errorCode];
  const renderContent =
    errorContentRenderer ?? (stepRun.timeout ? errorCodeToMessage.TIMEOUT : noop);
  return renderContent();
};
