import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  SortByConfiguration,
  SortByDropdownItemType,
  SortByDropdownOrderBy,
} from './SortByDropdown.types';

import { SORT_BY_DROPDOWN_ORDER_BY_OPTIONS } from './SortByDropdown.constants';
import * as S from './SortByDropdown.styled';
import { SortByDropdownItem } from './SortByDropdownItem';

interface SortByDropdownProps {
  items: SortByDropdownItemType[];

  onChange: (sortBy: string, orderBy: SortByDropdownOrderBy) => void;
  defaultConfiguration: SortByConfiguration;
  configuration: SortByConfiguration;
}

export const SortByDropdown = ({
  items,
  configuration,
  defaultConfiguration,
  onChange,
  ...dropdownProps
}: SortByDropdownProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'sortByDropdown' });
  const [orderBy, setOrderBy] = useState<SortByDropdownOrderBy>(
    configuration.desc ? 'desc' : 'asc',
  );
  const [sortBy, setSortBy] = useState<string>(configuration.sortBy);

  const handleOnClickSortBy = (newSortBy: string) => {
    onChange(newSortBy, orderBy);
    setSortBy(newSortBy);
  };

  const handleOnClickOrderBy = (newOrderBy: string) => {
    onChange(sortBy, newOrderBy as SortByDropdownOrderBy);
    setOrderBy(newOrderBy as SortByDropdownOrderBy);
  };

  const handleReset = () => {
    setSortBy(defaultConfiguration.sortBy);
    setOrderBy(defaultConfiguration.desc ? 'desc' : 'asc');
    onChange(defaultConfiguration.sortBy, defaultConfiguration.desc ? 'desc' : 'asc');
  };

  const showResetButton =
    sortBy !== defaultConfiguration.sortBy ||
    orderBy !== (defaultConfiguration.desc ? 'desc' : 'asc');

  const selectedValue = items.find((item) => item.id === sortBy);

  const Label = useMemo(
    () => (
      <S.LabelContainer>
        <S.SortIconContainer inverted={orderBy === 'desc'}>
          <S.SortIcon />
        </S.SortIconContainer>
        {selectedValue?.name}
      </S.LabelContainer>
    ),
    [orderBy, selectedValue],
  );

  const sortedItems = useMemo(() => items.sort((a, b) => a.name.localeCompare(b.name)), [items]);

  return (
    <S.Dropdown {...dropdownProps} label={Label} data-testid="SortByDropdown">
      <S.OptionsContainer>
        <S.Header>
          {t('header.sortBy', 'Sort by')}
          {showResetButton && (
            <S.ResetButton onClick={handleReset}>{t('header.reset', 'Reset')}</S.ResetButton>
          )}
        </S.Header>
        {sortedItems.map((item) => (
          <SortByDropdownItem
            key={item.id}
            id={item.id}
            checked={sortBy === item.id}
            name={item.name}
            onClick={handleOnClickSortBy}
          />
        ))}
      </S.OptionsContainer>
      <S.Separator />
      <S.Header>{t('header.orderBy', 'Order')}</S.Header>
      {SORT_BY_DROPDOWN_ORDER_BY_OPTIONS.map((item) => (
        <SortByDropdownItem
          key={item.id}
          id={item.id}
          checked={orderBy === item.id}
          name={item.name}
          onClick={handleOnClickOrderBy}
        />
      ))}
    </S.Dropdown>
  );
};
