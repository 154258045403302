import { FIELD_NAMES as ELEMENT_SELECTOR_FIELD_NAMES } from '../ElementSelectorBuilder/SelectorsGroupField/SelectorsGroupField.constants';
import { FIELD_NAMES as EXECUTE_ACTION_FIELD_NAMES } from '../ExecuteActionFields';
import { FIELD_NAMES as SCROLL_ACTION_FIELD_NAMES } from '../ScrollActionFields/ScrollActionFields.constants';

export const FIELD_NAMES = {
  ASSERTION_TYPE: 'assertionType',
  ASSERTION_EXPECTED_VALUE: 'assertionExpectedValue',
  TYPE: 'type',
  URL: 'url',
  USERNAME: 'username',
  PASSWORD: 'password',
  VALUE: 'value',
  TAB_NO: 'tabNo',
  INTERACTION_POSITION: 'interactionPosition',
  CONTINUE_ON_FAILURE: 'continueOnFailure',
  ASSERTION_PROPERTY: 'assertionProperty',
  ASSERTION_JAVASCRIPT: 'assertionJavaScript',
  ASSERTION_VARIABLE_NAME: 'assertionVariableName',
  SELECT_IS_MULTIPLE: 'selectIsMultiple',
  MOUSE_X: 'clientX',
  MOUSE_Y: 'clientY',
  HAS_SECRET_CONTENT: 'hasSecretValue',
  ...ELEMENT_SELECTOR_FIELD_NAMES,
} as const;

export const FIELD_NAMES_VALUES = Object.values({
  ...ELEMENT_SELECTOR_FIELD_NAMES,
  ...SCROLL_ACTION_FIELD_NAMES,
  ...EXECUTE_ACTION_FIELD_NAMES,
  ...FIELD_NAMES,
});
