import { v4 as uuid } from 'uuid';

import type { CSSSelector, XPath } from '../types/aliases';
import type { Selector, SelectorCustomType, SelectorsGroup, SelectorsPreset } from '../types/steps';

const isCustomTagWithNamespace = (tagName): boolean => tagName.includes(':');

export const createSelector = (selector?: Partial<Selector>): Selector => ({
  id: uuid(),
  isActive: false,
  type: 'XPath',
  selector: '',
  ...selector,
});

export const createSelectorsGroup = (selectorsGroup?: Partial<SelectorsGroup>): SelectorsGroup => ({
  id: uuid(),
  relation: 'descendant',
  selectors: [],
  ...selectorsGroup,
});

export const createSelectorsPreset = (
  selectorsPreset?: Partial<SelectorsPreset>,
): SelectorsPreset => ({
  id: uuid(),
  isActive: false,
  isCustom: false,
  selectorsGroups: [],
  ...selectorsPreset,
});

const createCustomSelectors = (activeSelectorType?: Selector['type']) =>
  (['customXPath', 'customCSS'] as SelectorCustomType[]).map((type) =>
    createSelector({ type, isActive: type === activeSelectorType }),
  );

export const createEmptySelectorsGroup = (activeSelectorType?: Selector['type']) =>
  createSelectorsGroup({
    selectors: [...createCustomSelectors(activeSelectorType)],
  });

/**
 * Normalize selectors group by adding missing built-in selectors
 * e.g. accessible or custom selectors
 */
const normalizeSelectorsGroup = (group: SelectorsGroup): SelectorsGroup => {
  const buildInSelectors = createEmptySelectorsGroup().selectors;
  const currentSelectorTypes = new Set(group.selectors.map((selector) => selector.type));
  const additionalSelectors = buildInSelectors.filter(
    (selector) => !currentSelectorTypes.has(selector.type),
  );
  const normalizedSelectors = [...group.selectors, ...additionalSelectors];
  return { ...group, selectors: normalizedSelectors };
};

/**
 * Normalizes selectors preset by adding missing built-in selectors
 * e.g. accessible or custom selectors
 */
export const normalizeSelectorsPreset = (
  preset: Partial<SelectorsPreset> = {},
): SelectorsPreset => {
  const selectorsPreset = createSelectorsPreset(preset);
  selectorsPreset.selectorsGroups = selectorsPreset.selectorsGroups.map(normalizeSelectorsGroup);

  return selectorsPreset;
};

export const createEmptyCustomSelectorsPreset = (): SelectorsPreset =>
  normalizeSelectorsPreset({
    isActive: true,
    isCustom: true,
    selectorsGroups: [createEmptySelectorsGroup('customXPath')],
  });

/**
 * Extracts active selector from selectors group
 * If no active selector found, return the first selector.
 */
export const getActiveSelectorFromGroup = (
  group: SelectorsGroup,
): SelectorsGroup['selectors'][number] =>
  group.selectors.find((selector) => selector.isActive) ?? group.selectors[0];

/**
 * Extracts text value of active selector from selectors group
 * If no active selector found, return the first selector.
 */
export const getSelectorPathFromGroup = (group: SelectorsGroup) =>
  getActiveSelectorFromGroup(group)?.selector;

const getFullSelectorPathFromGroups = (groups: SelectorsGroup[]): Selector['selector'] =>
  groups
    .map((group) => getSelectorPathFromGroup(group))
    .filter(Boolean)
    .join('');

export const getActiveSelectorsPreset = (presets: SelectorsPreset[]): SelectorsPreset | undefined =>
  presets.find((preset) => preset.isActive) ?? presets[0];

/**
 * Generates full selector as text value from selectors preset
 */
export const getFullSelectorPathFromPreset = (preset: SelectorsPreset): Selector['selector'] =>
  getFullSelectorPathFromGroups(preset.selectorsGroups);

/**
 * Generates full selector as text value from selectors presets
 */
export const getFullSelectorPathFromPresets = (presets: SelectorsPreset[]): Selector['selector'] =>
  getFullSelectorPathFromGroups(getActiveSelectorsPreset(presets)?.selectorsGroups ?? []);

/**
 * Heals XPath selector if it contains some wrong patterns
 * eg. by replacing namespaced tags with wildcard
 */
export const healXPathSelectorIfNeeded = (selector: XPath) => {
  const NAMESPACED_TAG_PATTERN = /[/(parent::)]?[aA-Z-]+:[A-Z-\]]+/g;
  if (NAMESPACED_TAG_PATTERN.test(selector)) {
    let transformedSelector = selector;
    console.debug('Converting namespaced tag names started', selector);
    selector
      .match(NAMESPACED_TAG_PATTERN)
      // sort to avoid replacing at the beginning tags that are part of other tags
      ?.sort((a, b) => (b.includes(a) ? 1 : -1))
      .forEach((namespacedSelector) => {
        const namespacedTag = namespacedSelector.replace(/^[/:]/, '');
        if (isCustomTagWithNamespace(namespacedTag)) {
          transformedSelector = transformedSelector.replaceAll(
            namespacedTag,
            `*[local-name()="${namespacedTag.toLowerCase()}"]`,
          );
        }
      });
    console.debug('Converting namespaced tag names finished', transformedSelector);
    return transformedSelector;
  }

  return selector;
};

export const isXPathSelectorText = (selector: string): selector is XPath => {
  if (typeof selector !== 'string') {
    return false;
  }

  return selector.startsWith('/') || selector.startsWith('./') || selector.startsWith('(');
};

export const validateXPathSelectorText = (selector: XPath) => {
  const allowedStarts = ['//', '../', '/HTML', '(//'];
  if (allowedStarts.every((start) => !selector.startsWith(start))) {
    throw new Error('This XPath selector should start with "//", "../" , "(//" or "/HTML"');
  }

  try {
    const healedSelector = healXPathSelectorIfNeeded(selector);
    document.evaluate(
      healedSelector,
      document,
      null,
      window.XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
      null,
    );
  } catch (error) {
    throw new Error('This is not a valid XPath selector');
  }

  return true;
};

export const validateCSSSelectorText = (selector: CSSSelector) => {
  try {
    document.querySelector(selector);
    return true;
  } catch (error) {
    throw new Error('This is not a valid CSS selector');
  }
};
