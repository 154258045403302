import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import type { FormikValues } from 'formik';

interface UseFormikContextWithTabsErrorsProps {
  fieldGroups: Record<string, string[]>;
}

export const useFormikContextWithTabsErrors = <T extends FormikValues>({
  fieldGroups,
}: UseFormikContextWithTabsErrorsProps) => {
  const groupsNames = useMemo(() => Object.keys(fieldGroups), [fieldGroups]);
  const fields = useMemo(() => Object.values(fieldGroups), [fieldGroups]);
  const formik = useFormikContext<T>();

  const groupsWithErrors = useMemo(() => {
    const errorsFieldsKeys = Object.keys(formik.errors);

    // Find intersecting error fields for each group
    const groupsErrors = fields.map((groupFields) =>
      groupFields.filter((field) => errorsFieldsKeys.includes(field)),
    );

    // Map groups to their names if they have errors, null otherwise
    return groupsErrors.reduce((names, errorsInGroup, index) => {
      if (errorsInGroup.length > 0) {
        return [...names, groupsNames[index]];
      }
      return names;
    }, []);
  }, [fields, formik.errors, groupsNames]);

  return {
    ...formik,
    groupsWithErrors,
  };
};
