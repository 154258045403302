import Link from '@bugbug/core/components/Link';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { ReactNode } from 'react';
import type { AuthProvidersRef } from '~/components/AuthProviders/AuthProviders';

import { AuthProviders } from '~/components/AuthProviders/AuthProviders';
import urls from '~/views/urls';

import {
  OnboardingBox,
  OnboardingTitle,
  OnboardingSubtitle,
} from '../Onboarding/Onboarding.styled';

import * as S from './AuthLayout.styled';

interface AuthLayoutProps {
  title: ReactNode;
  subTitle: ReactNode;
  redirectInfo?: ReactNode;
  form: ReactNode;
}

export const AuthLayout = forwardRef<AuthProvidersRef, AuthLayoutProps>(
  ({ title, subTitle, redirectInfo, form }, ref) => {
    const { t } = useTranslation(undefined, { keyPrefix: 'auth' });

    return (
      <OnboardingBox>
        <S.AuthCard>
          <div>
            <OnboardingTitle>{title}</OnboardingTitle>
            <OnboardingSubtitle>{subTitle}</OnboardingSubtitle>
          </div>
          {form}
          <AuthProviders ref={ref} />
          {redirectInfo && <S.RedirectText>{redirectInfo}</S.RedirectText>}
        </S.AuthCard>
        <S.TermsAndConditions>
          {t('termsAndConditionDesc', 'By continuing you accept the ')}
          <Link to={urls.termsAndConditions}>
            {t('termsAndConditionLink', 'terms and conditions')}
          </Link>
        </S.TermsAndConditions>
      </OnboardingBox>
    );
  },
);

AuthLayout.displayName = 'AuthLayout';
