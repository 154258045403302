import { identity } from '@bugbug/core/utils/toolbox';

import type { Column } from 'react-table';
import type { Data } from '~/components/Table';

import i18n from '~/translations';

import { ComponentStepName } from '../ComponentStepName/ComponentStepName';
import ImageCell from '../ImageCell';

export const STEPS_COLUMNS: Column<Data>[] = [
  {
    Header: i18n.t('componentDetails.table.columns.action', 'Action') as string,
    accessor: identity,
    id: 'action',
    Cell: ComponentStepName,
    minWidth: 240,
    width: '43%',
  },
  {
    Header: i18n.t('componentDetails.table.columns.screenshot', 'Screenshot') as string,
    accessor: identity,
    Cell: ImageCell,
    id: 'screenshot',
  },
];
