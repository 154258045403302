import type { CodeProps } from './Code';
import type { ActiveModal } from '~/hooks/useModal';

import useModal from '~/hooks/useModal/useModal';

import { Code } from './Code';
import * as S from './ExpandableCode.styled';

export type ExpandableCodeProps = CodeProps;

export const ExpandableCode = (props: ExpandableCodeProps) => {
  const modal = useModal();

  const handleOpen = () => {
    modal.showFullscreen('code_editor', props as unknown as ActiveModal['props'], {
      closeButtonHidden: true,
      stacked: true,
    });
  };

  return (
    <Code {...props}>
      <S.FullScreenIconButton onClick={handleOpen} />
    </Code>
  );
};
