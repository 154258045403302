import { useRef, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PaidFeatureName } from '@bugbug/core/types/paidFeatures';
import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import { selectProjectSlug, selectSingleProjectId } from '~/modules/project/project.selectors';
import { useAppSelector } from '~/modules/store';
import urls, { reverse } from '~/views/urls';

import { PaidFeatureBanner } from '../PaidFeatureGuard';

import * as S from './Page.styled';

interface PageProps {
  className?: string;
  'data-testid'?: string;
  children: React.ReactNode;
  title?: React.ReactNode;
  centeredContent?: boolean;
  withBackButton?: boolean;
  wideContent?: boolean;
  noHeader?: boolean;
  backUrl?: string;
  HeaderContent?: React.ReactNode;
  paidFeatureBanner?: Extract<
    PaidFeatureName,
    'alerts' | 'integrations' | 'schedules' | 'variables'
  >;
}

const Page = memo<PageProps>((props) => {
  const {
    className,
    'data-testid': dataTestId = 'Page',
    children,
    title = '',
    centeredContent = false,
    withBackButton = false,
    backUrl = urls.home,
    noHeader,
    HeaderContent,
    paidFeatureBanner,
    wideContent,
  } = props;
  const { t } = useTranslation();
  const ref = useRef(null);
  const projectId = useAppSelector(selectSingleProjectId);
  const projectSlug = useAppSelector(selectProjectSlug);
  const organizationId = useAppSelector(selectCurrentOrganizationId);

  const BackButton = useMemo(() => {
    if (!centeredContent) {
      return <S.GoBackButton defaultUrl={backUrl} />;
    }

    const label = projectSlug
      ? t('page.goBackButton.projectLabel', 'Back to project')
      : t('page.goBackButton.defaultLabel', 'Back to projects');
    const url = projectSlug ? urls.project : urls.projectsList;

    return (
      <S.GoBackLink to={reverse(url, { projectSlug, projectId, organizationId })}>
        <S.GoBackIcon />
        <S.GoBackLinkText>{label}</S.GoBackLinkText>
      </S.GoBackLink>
    );
  }, [backUrl, centeredContent, organizationId, projectId, projectSlug, t]);

  return (
    <S.Container className={className} data-testid={dataTestId} ref={ref}>
      <S.ContentWrapper>
        <S.Content
          centeredContent={centeredContent}
          withBackButton={withBackButton}
          wideContent={wideContent}
        >
          {paidFeatureBanner && <PaidFeatureBanner messageFor={paidFeatureBanner} fullInfo />}
          <S.HeaderWrapper>
            {withBackButton && BackButton}
            {!noHeader && (
              <S.Header
                centeredContent={centeredContent}
                title={title}
                noBorder={withBackButton && centeredContent}
              />
            )}
            {HeaderContent}
          </S.HeaderWrapper>
          {children}
        </S.Content>
      </S.ContentWrapper>
    </S.Container>
  );
});

Page.displayName = 'Page';

export default Page;
