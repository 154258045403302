import { ActionButton } from '@bugbug/core/components/Button';
import styled from 'styled-components';

import Confetti from '~/images/confetti.svg?react';

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.border.semi};
`;

export const Container = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConfettiIcon = styled(Confetti)`
  width: 52px;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  text-align: center;
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 24px;
  justify-content: end;
  width: 100%;

  button {
    margin: 0;
  }
`;

export const SendButton = styled(ActionButton)`
  svg {
    color: white;
  }
`;
