import styled, { css } from 'styled-components';

export const Container = styled.div<{ force?: boolean }>`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 5px;
  display: none;

  ${({ force }) =>
    force &&
    css`
      display: flex !;
    `}
`;
