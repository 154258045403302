import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Schedule } from '@bugbug/core/types/schedules';
import { ConfirmModal } from '~/components/modals/Modal';
import useModal from '~/hooks/useModal';
import { useCloneScheduleMutation } from '~/modules/schedules/schedules.api';
import analytics from '~/services/analytics';

interface CloneScheduleModalProps {
  id: Schedule['id'];
}

const CloneScheduleModal = ({ id }: CloneScheduleModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const [cloneSchedule, { isSuccess, isLoading, isError }] = useCloneScheduleMutation();

  useEffect(() => {
    if (isSuccess) {
      modal.hide();
    }
  }, [isSuccess, modal]);

  const handleCloneConfirm = useCallback(() => {
    analytics.trackEvent('schedule_duplicated');
    cloneSchedule(id);
  }, [cloneSchedule, id]);

  return (
    <ConfirmModal
      hasInternalServerError={isError}
      title={t('cloneScheduleModal.title', 'Do you want to duplicate this schedule?')}
      internalServerErrorMessage={t('cloneScheduleModal.errorMessage', 'Duplicating failed')}
      pending={isLoading}
      onConfirm={handleCloneConfirm}
      onCancel={modal.hide}
    />
  );
};

export default CloneScheduleModal;
