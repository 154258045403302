import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { MouseEventHandler } from 'react';

import type { Component } from '@bugbug/core/types/components';
import type { Test } from '@bugbug/core/types/tests';
import { useAppDispatch } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { useUnlinkComponentMutation } from '~/modules/tests/tests.api';

import * as S from './ToggleComponent.styled';

interface UnlinkComponentProps {
  testId: Test['id'];
  componentId: Component['id'];
}

const UnlinkComponent = ({ testId, componentId }: UnlinkComponentProps) => {
  const dispatch = useAppDispatch();
  const [handleUnlinkComponent, unlinkComponentRequest] = useUnlinkComponentMutation();
  const { t } = useTranslation();
  const tooltipContent = t(
    'makeComponent.infoComponent.tooltip',
    'All changes you make in this group will be reflected in all instances of this component.',
  );

  useEffect(() => {
    /*
      To keep compatibility with old API/store (test.sagas)
      Remove after migration to RTK Toolkit.
    */
    if (unlinkComponentRequest.isSuccess) {
      dispatch(TestActions.unlinkComponentSuccess(componentId, unlinkComponentRequest.data));
    }
  }, [dispatch, componentId, unlinkComponentRequest]);

  const handleUnlinkComponentClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    handleUnlinkComponent({ testId, componentId });
  };

  if (unlinkComponentRequest.isLoading) {
    return (
      <S.Wrapper>
        <S.Container data-testid="PendingComponent" disabled>
          <S.Loader />
          {t('makeComponent.unlink.pending.label', 'Unlinking...')}
        </S.Container>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.Tooltip content={tooltipContent}>
        <S.Container data-testid="UnlinkComponent" onClick={handleUnlinkComponentClick} component>
          {t('makeComponent.unlink.label', '❖ Component')}
        </S.Container>
      </S.Tooltip>
    </S.Wrapper>
  );
};

export default UnlinkComponent;
