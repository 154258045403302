import DropdownBase, {
  DROPDOWN_ANCHOR,
  ExpandIcon,
  ToggleButton,
} from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import styled, { css } from 'styled-components';

export const Dropdown = styled(DropdownBase).attrs(() => ({
  anchor: DROPDOWN_ANCHOR.BOTTOM_START,
}))`
  max-width: max-content;
  height: 100%;

  ${ToggleButton} {
    border-width: 1px;
    border-radius: 25px;

    height: 27px;
    padding: 1px 7px 0 6px;
  }

  ${ExpandIcon} {
    margin-left: 6px;
    height: 18px;
    width: 18px;
    font-size: 20px;
    margin-right: -3px;
    color: ${({ theme }) => theme.colors.text.normal};
  }
`;

export const OptionsContainer = styled.div`
  min-width: 200px;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow: auto;
`;

export const IconPlaceholder = styled.div`
  min-width: 20px;
  height: 20px;
  margin-right: 6px;
  float: left;
`;

export const ItemCheckIcon = styled(Icon).attrs(() => ({
  name: 'check',
}))`
  color: ${({ theme }) => theme.colors.text.subtle};
  top: 0;
  margin-right: 6px !important;
`;

export const Header = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.text.secondaryAllCaps};
  padding: 8px 16px 2px 16px;
  text-transform: uppercase;
  display: flex;
`;

export const ResetButton = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: auto;
  text-transform: capitalize;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.text.normal};
`;

export const SortIconContainer = styled.div<{ inverted: boolean }>`
  display: flex;
  align-items: center;

  ${({ inverted }) =>
    inverted &&
    css`
      transform: scaleY(-1);
    `}
`;

export const SortIcon = styled(Icon).attrs(() => ({
  name: 'sort',
}))`
  && {
    color: ${({ theme }) => theme.colors.border.dark};
    width: 16px !important;
    height: 16px !important;
    margin-right: 4px;
  }
`;

export const Separator = styled.div`
  margin: 0 0 3px 0;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.border.medium};
  height: 1px;
`;
