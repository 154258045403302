import type { SortByDropdownItemType, SortByDropdownOrderBy } from './SortByDropdown.types';

import i18n from '~/translations';

export const SORT_BY_DROPDOWN_ORDER_BY_OPTIONS: SortByDropdownItemType<SortByDropdownOrderBy>[] = [
  {
    id: 'asc',
    name: i18n.t('sortByDropdown.orderBy.asc', 'Ascending'),
  },
  {
    id: 'desc',
    name: i18n.t('sortByDropdown.orderBy.desc', 'Descending'),
  },
];
