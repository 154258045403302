import { pick, pipe, mergeDeepRight } from 'ramda';

import type { SortByDropdownItemType } from '~/components/SortByDropdown/SortByDropdown.types';

import * as TablePartials from '~/components/Table';
import i18n from '~/translations';

import { getDuration } from '../../RunsHistory.helpers';
import IndexedNameCell from '../IndexedNameCell';
import MethodCell from '../MethodCell';
import ProfileCell from '../ProfileCell';
import RunByCell from '../RunByCell';
import SuiteRunRowActions from '../SuiteRunRowActions';

export const SUITES_HISTORY_COLUMN_ID = {
  NAME: 'name',
  DURATION: 'duration',
  METHOD: 'runMode',
  RESULT: 'passedCount',
  STATUS: 'status',
  ACTIONS: 'actions',
  PROFILE: 'profile',
  RUN_BY: 'runBy',
};

export const SUITES_HISTORY_COLUMNS = [
  TablePartials.PREDEFINED_COLUMN.SELECTION,
  {
    Header: i18n.t('suitesHistory.table.columns.name', 'Name'),
    accessor: 'suiteName',
    id: SUITES_HISTORY_COLUMN_ID.NAME,
    Cell: IndexedNameCell,
    width: '100%',
    minWidth: 250,
  },
  {
    Header: i18n.t('suitesHistory.table.columns.duration', 'Duration'),
    accessor: getDuration,
    id: SUITES_HISTORY_COLUMN_ID.DURATION,
    Cell: TablePartials.TextCell,
    width: 90,
  },
  {
    Header: i18n.t('suitesHistory.table.columns.profile', 'Profile'),
    accessor: 'profileName',
    id: SUITES_HISTORY_COLUMN_ID.PROFILE,
    Cell: ProfileCell,
    width: 90,
  },
  {
    Header: i18n.t('suitesHistory.table.columns.method', 'Method'),
    accessor: 'runMode',
    id: SUITES_HISTORY_COLUMN_ID.METHOD,
    Cell: MethodCell,
    width: 95,
  },
  {
    Header: i18n.t('suitesHistory.table.columns.runBy', 'Run by'),
    accessor: pick(['user', 'triggeredBy']),
    id: SUITES_HISTORY_COLUMN_ID.RUN_BY,
    Cell: RunByCell,
    width: '100%',
    minWidth: 120,
  },
  {
    Header: i18n.t('suitesHistory.table.columns.result.label', 'Result'),
    accessor: ({ passedCount, totalCount }) =>
      i18n.t('suitesHistory.table.columns.result.value', '{{passed}} / {{total}} passed', {
        passed: passedCount,
        total: totalCount,
      }),
    id: SUITES_HISTORY_COLUMN_ID.RESULT,
    Cell: TablePartials.TextCell,
    width: 120,
  },
  {
    Header: i18n.t('suitesHistory.table.columns.status', 'Status'),
    accessor: pipe(pick(['started', 'status']), mergeDeepRight({ extended: true })),
    Cell: TablePartials.StatusCell,
    id: SUITES_HISTORY_COLUMN_ID.STATUS,
    width: 120,
  },
  {
    Header: i18n.t('suitesHistory.table.columns.actions', ''),
    Cell: SuiteRunRowActions,
    id: SUITES_HISTORY_COLUMN_ID.ACTIONS,
    width: 75,
  },
];

export const SUITES_HISTORY_SORT_BY: SortByDropdownItemType[] = [
  { name: i18n.t('suitesHistory.table.columns.created', 'Suites date'), id: 'created' },
  { name: i18n.t('suitesHistory.table.columns.name', 'Name'), id: SUITES_HISTORY_COLUMN_ID.NAME },
  {
    name: i18n.t('suitesHistory.table.columns.duration', 'Duration'),
    id: SUITES_HISTORY_COLUMN_ID.DURATION,
  },
  {
    name: i18n.t('suitesHistory.table.columns.profile', 'Profile'),
    id: SUITES_HISTORY_COLUMN_ID.PROFILE,
  },
  {
    name: i18n.t('suitesHistory.table.columns.method', 'Method'),
    id: SUITES_HISTORY_COLUMN_ID.METHOD,
  },

  {
    name: i18n.t('suitesHistory.table.columns.runBy', 'Run by'),
    id: SUITES_HISTORY_COLUMN_ID.RUN_BY,
  },

  {
    name: i18n.t('suitesHistory.table.columns.result.label', 'Result'),
    id: SUITES_HISTORY_COLUMN_ID.RESULT,
  },
  {
    name: i18n.t('suitesHistory.table.columns.status', 'Status'),
    id: SUITES_HISTORY_COLUMN_ID.STATUS,
  },
];
