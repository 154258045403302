import type { Selector } from '@bugbug/core/types/steps';
import i18n from '~/translations';

export const getSelectorOptionName = (selector: Selector): string => {
  switch (selector.type) {
    case 'customXPath':
      return i18n.t('elementQuery.elementSelector.option.customXPath', 'Use custom XPath locator');
    case 'customCSS':
      return i18n.t('elementQuery.elementSelector.option.customCSS', 'Use custom CSS selector');
    default:
      return selector.selector;
  }
};
