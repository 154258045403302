import mixins from '@bugbug/core/theme/mixins';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background.primary99};
    color: ${theme.colors.brand};
  `}

  ${({ theme }) => theme.media.tablet`
    ${mixins.layout.flexColumnCenter};
  `}

  text-align: center;
  flex: 1;

  blockquote {
    font-style: normal;
    padding: 0 30px 30px;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 100px;
      margin-bottom: 20px;
    }

    p {
      ${mixins.font.medium};
      font-size: 18px;
    }
  }
`;

export const BenefitsList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 80px;

  li {
    ${mixins.font.medium};
    ${mixins.layout.flexCenter};
    justify-content: flex-start;
    margin-bottom: 10px;
    gap: 10px;

    svg {
      width: 24px;
    }
  }
`;

export const BrandsList = styled.div`
  display: grid;
  gap: 30px;
  margin-top: 12px;
  flex-wrap: wrap;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
  align-items: center;

  img {
    max-width: 100px;
  }

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${({ theme }) => theme.media.desktopWide`
    grid-template-columns: repeat(3, 1fr);
  `}
`;
