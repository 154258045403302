import envs from './envs';

const { testing, production, staging, qa, localhost } = envs;

const getConfig = () => {
  const { hostname } = document.location;
  if (hostname.includes('app-test.')) {
    return testing;
  }
  if (hostname.includes('stage.')) {
    return staging;
  }
  if (hostname.includes('qa.')) {
    return qa;
  }

  if (hostname.includes('local-app.')) {
    return localhost;
  }

  if (hostname.includes('app.')) {
    return production;
  }
  return localhost;
};

export default getConfig();
