/* eslint-disable react/no-unescaped-entities */
import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Link from '@bugbug/core/components/Link';
import Loader from '@bugbug/core/components/Loader';
import { omit } from 'ramda';
import { useCallback, useLayoutEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import { Step, StepLabel } from '~/components/Stepper';
import useExtensionState from '~/hooks/useExtensionState';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import { useTrackCustomPageView } from '~/hooks/useTrackCustomPageView';
import config from '~/modules/config';
import { QUERY_ACTION } from '~/modules/constans';
import { ExtensionActions } from '~/modules/extension/extension.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import { setCookie } from '~/utils/cookies';
import { OnboardingTitle } from '~/views/Onboarding/Onboarding.styled';
import { reverse } from '~/views/urls';

import { ExtensionBadge } from './ExtensionBadge';
import { ASSETS, EXTENSION_STEPS, EXTENSION_STEPS_LIST } from './MissingExtensionModal.constants';
import * as S from './MissingExtensionModal.styled';

const MissingExtensionModal = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'missingExtensionModal' });
  const modal = useModal();
  const { isConnected, hasIncognitoAccess, isPending } = useExtensionState();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = useQueryString();
  const [active, setActive] = useState<number | null>(0);

  useMount(() => {
    history.replace(
      reverse(
        history.location.pathname,
        {},
        {
          ...queryParams,
          action: QUERY_ACTION.INSTALL_EXTENSION,
        },
      ),
    );
  });

  const setActionInitiatorCookie = useCallback(() => {
    setCookie({
      name: 'bugbugExtSetupInitiator',
      value: window.location.href,
      expiresInHours: 0.5,
    });
  }, []);

  const trackCustomPageView = useTrackCustomPageView();

  useLayoutEffect(() => {
    if (!isPending && isConnected && hasIncognitoAccess) {
      setActive(EXTENSION_STEPS.SUCCESS.id);
      analytics.trackEvent(TRACK_EVENT_TYPE.EXTENSION_SUCCESS);
      trackCustomPageView('Ready Extension Page');
    } else if (!isPending && isConnected && !hasIncognitoAccess) {
      setActive(EXTENSION_STEPS.INCOGNITO.id);
      analytics.trackEvent(TRACK_EVENT_TYPE.MODAL_VIEW_EXT_INCOGNITO);
      trackCustomPageView('Enable Incognito Page');
    } else if (!isPending) {
      setActive(EXTENSION_STEPS.INSTALL.id);
      analytics.trackEvent(TRACK_EVENT_TYPE.MODAL_VIEW_EXT_INSTALL);
      trackCustomPageView('Install Extension Page');
    } else {
      setActive(null);
    }
  }, [isConnected, isPending, hasIncognitoAccess, setActionInitiatorCookie, trackCustomPageView]);

  useUpdateEffect(() => {
    if (
      !isPending &&
      active !== null &&
      [EXTENSION_STEPS.INCOGNITO.id, EXTENSION_STEPS.INSTALL.id].includes(active)
    ) {
      setActionInitiatorCookie();
    }
  }, [active, isPending]);

  useUnmount(() => {
    // @ts-expect-error missing types
    history.replace(reverse(history.location.pathname, {}, omit(['action'], queryParams)));
  });

  const handleGoToStore = () => {
    window.open(config.EXTENSION_URL);
  };

  const handleGoToSettings = () => {
    dispatch(ExtensionActions.openSettingsRequested());
  };

  const stepToWidth = {
    [EXTENSION_STEPS.INSTALL.id]: '625px',
    [EXTENSION_STEPS.INCOGNITO.id]: '800px',
    [EXTENSION_STEPS.SUCCESS.id]: '550px',
  };

  return (
    <>
      <S.BackdropOverrides />
      <S.ModalWrapperOverrides width={stepToWidth[active ?? EXTENSION_STEPS.INSTALL.id]} />
      <S.Container aria-label={t('ariaLabel', 'Install the extension modal')}>
        <S.Content>
          {!isPending ? (
            <>
              {active !== null && (
                <S.Stepper activeStep={active}>
                  {EXTENSION_STEPS_LIST.map((step) => (
                    <Step key={step.label}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  ))}
                </S.Stepper>
              )}
              {active === EXTENSION_STEPS.INSTALL.id && (
                <S.StepDetails>
                  <S.BadgeLink href={config.EXTENSION_URL} rel="noreferrer" target="_blank">
                    <ExtensionBadge />
                  </S.BadgeLink>
                  <div>
                    <OnboardingTitle>
                      {t('step.install.title', 'You need to install the BugBug Chrome extension')}
                    </OnboardingTitle>
                    <S.Description>
                      <Trans i18nKey="missingExtensionModal.step.install.description">
                        To record or run tests, please install the BugBug Chrome extension.
                        <br />
                        After installing return to this tab.
                      </Trans>
                    </S.Description>
                  </div>
                  <Button variant={BUTTON_VARIANT.PRIMARY} onClick={handleGoToStore}>
                    {t('step.install.button', 'Go to Chrome Web Store')}
                    <S.LinkIcon />
                  </Button>
                </S.StepDetails>
              )}
              {active === EXTENSION_STEPS.INCOGNITO.id && (
                <S.StepDetails>
                  <div>
                    <OnboardingTitle>
                      {t(
                        'step.incognito.title',
                        'Please enable "Allow in incognito" in the extension details',
                      )}
                    </OnboardingTitle>
                    <S.Description>
                      <Trans i18nKey="missingExtensionModal.step.incognito.description">
                        BugBug runs tests in an incognito window to safely clear cookies, cache and
                        session before each test. <br />
                        Go to BugBug <Link onClick={handleGoToSettings}>extension settings</Link>,
                        scroll down and enable "Allow in incognito".
                      </Trans>
                    </S.Description>
                  </div>
                  <Button variant={BUTTON_VARIANT.PRIMARY} onClick={handleGoToSettings}>
                    {t('step.incognito.button', 'Go to extension settings')}
                    <S.LinkIcon />
                  </Button>
                  <S.Image src={ASSETS.IMAGE.src} alt={ASSETS.IMAGE.alt} />
                </S.StepDetails>
              )}
              {active === EXTENSION_STEPS.SUCCESS.id && (
                <S.StepDetails>
                  <ExtensionBadge installed />
                  <div>
                    <OnboardingTitle>
                      {t('step.success.title', 'BugBug Extension is ready')}
                    </OnboardingTitle>
                    <S.Description>
                      {t('step.success.description', 'Now you can record and run automated tests.')}
                    </S.Description>
                  </div>
                  <Button variant={BUTTON_VARIANT.PRIMARY} onClick={modal.hide}>
                    {t('step.success.button', 'Continue')}
                  </Button>
                </S.StepDetails>
              )}
            </>
          ) : (
            <S.LoaderFlexContainer>
              <Loader />
              <p>
                {!isConnected
                  ? t('loader.waitingForExt', 'Waiting for browser extension')
                  : t('loader.waitForServer', 'The extension is trying to connect to the server')}
              </p>
            </S.LoaderFlexContainer>
          )}
        </S.Content>
      </S.Container>
    </>
  );
};

export default MissingExtensionModal;
