import type { Column } from 'react-table';
import type { SortByDropdownItemType } from '~/components/SortByDropdown/SortByDropdown.types';

import type { RunStatus } from '@bugbug/core/types/base';
import * as TablePartials from '~/components/Table';
import i18n from '~/translations';
import { sortByDate } from '~/utils/sorting';

import TestRowActions from './components/TestRowActions';

export const TEST_LIST_COLUMN_ID = {
  NAME: 'name',
  LAST_RUN: 'last_result',
  ACTIONS: 'actions',
};

export const TEST_LIST_COLUMNS = [
  TablePartials.PREDEFINED_COLUMN.SELECTION,
  {
    Header: i18n.t('testList.table.columns.name', 'Name'),
    accessor: 'name',
    id: TEST_LIST_COLUMN_ID.NAME,
    Cell: TablePartials.TextCell,
  },
  TablePartials.PREDEFINED_COLUMN.SCREEN,
  {
    Header: i18n.t('testList.table.columns.lastRun', 'Last result'),
    accessor: (
      test: TablePartials.Data & {
        previousResult?: unknown;
        lastResult?: unknown;
        lastUnfinishedUserRun?: { status: RunStatus };
      },
    ) =>
      test.previousResult ||
      (test.lastUnfinishedUserRun?.status === 'paused'
        ? test.lastUnfinishedUserRun
        : test.lastResult) ||
      {},
    id: TEST_LIST_COLUMN_ID.LAST_RUN,
    Cell: TablePartials.StatusCell,
    sortType: sortByDate,
    minWidth: 80,
    maxWidth: 160,
  },
  {
    Header: i18n.t('testList.table.columns.actions', 'Actions'),
    Cell: TestRowActions,
    id: TEST_LIST_COLUMN_ID.ACTIONS,
    maxWidth: 85,
  },
] as Column<TablePartials.Data>[];

export const TEST_LIST_COLUMNS_WITHOUT_SCREEN = TEST_LIST_COLUMNS.filter(
  (column) => column !== TablePartials.PREDEFINED_COLUMN.SCREEN,
);

export const TEST_LIST_SORT_BY_OPTIONS_WITHOUT_SCREEN: SortByDropdownItemType[] = [
  {
    name: i18n.t('testList.table.columns.created', 'Date created'),
    id: 'created',
  },
  {
    name: i18n.t('testList.table.columns.name', 'Name'),
    id: TEST_LIST_COLUMN_ID.NAME,
  },
  {
    name: i18n.t('testList.table.columns.lastRun', 'Last result'),
    id: TEST_LIST_COLUMN_ID.LAST_RUN,
  },
];

export const TEST_LIST_SORT_BY_OPTIONS: SortByDropdownItemType[] = [
  ...TEST_LIST_SORT_BY_OPTIONS_WITHOUT_SCREEN,
  {
    name: i18n.t('table.predefinedColumns.screenSize', 'Screen'),
    id: 'screen_size_type',
  },
];
