import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import useAppRoutes from '~/hooks/useAppRoutes';
import { OnboardingSubtitle } from '~/views/Onboarding/Onboarding.styled';

import * as S from './TestsListEmptyState.styled';

export const TestsListEmptyState = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'testsList.emptyState' });
  const { push, getRouteUrl } = useAppRoutes('testsList');
  const location = useLocation();

  const createNewTest = () => push('newTest');

  if (getRouteUrl('newTest') === location.pathname) {
    return null;
  }

  return (
    <S.EmptyStateBox>
      <S.EmptyStateCard>
        <div>
          <S.ArrowDownIcon />
          <S.EmptyStateTitle>{t('title', 'Create your first test')}</S.EmptyStateTitle>
          <OnboardingSubtitle>
            {t(
              'subtitle-part-1',
              "It looks like you don't have any automated tests yet. No problem! ",
            )}
            <br />
            {t('subtitle-part-2', 'Just create your first test to get started.')}
          </OnboardingSubtitle>
        </div>
        <S.CreateTestButton variant="primary" iconName="addCircle" onClick={createNewTest}>
          {t('button', 'New test')}
        </S.CreateTestButton>
      </S.EmptyStateCard>
    </S.EmptyStateBox>
  );
};
