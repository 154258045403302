import { LoaderFlexContainer } from '@bugbug/core/components/Loader';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import Page, { Content, Header, ContentWrapper } from '~/components/Page';
import { SimpleTable, TableBody, TableEventCatcher } from '~/components/Table';

import { StepRow as StepRowBase } from '../StepRow/StepRow';

export const Container = styled(Page)`
  flex: 1;
  position: relative;
  z-index: 2;
  background-color: ${COLOR.WHITE};
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  height: 100%;

  ${Header} {
    margin-bottom: 0;
    padding-right: 30px;
  }

  ${Content} {
    padding-right: 0;
    padding-bottom: 0;
  }

  ${ContentWrapper} {
    min-width: 735px;
    overflow: hidden;
  }
`;

export const LoaderContainer = styled(LoaderFlexContainer)`
  height: 100%;
`;

export const StepsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
  padding-right: 30px;
`;

export const Border = styled.div`
  border: solid 2px ${COLOR.GRAY_9};
  border-radius: 7px;
`;

export const StepRow = styled(StepRowBase)``;

export const Table = styled(SimpleTable)`
  overflow: visible;

  ${StepRow} {
    border-left: solid 2px ${COLOR.GRAY_9};
    border-radius: 0;
    border-right: solid 2px ${COLOR.GRAY_9};
  }

  ${StepRow}:first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-top: solid 2px ${COLOR.GRAY_9};
  }

  ${StepRow}:last-child {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-bottom: solid 2px ${COLOR.GRAY_9};
  }

  ${TableBody} {
    border-bottom: none;
  }

  ${TableBody}, ${TableEventCatcher} {
    overflow: visible;
  }
`;
